import BigNumber from 'bignumber.js';

import { getChainGas } from '#/api/chain-gas';

import { logEvent, logException } from '#/features/logging/logging';
import { BridgedToken } from '#/features/paraclear';
import { SystemConfigView } from '#/features/system/system-config-context';
import { WalletClient } from '#/features/wallet/common/wallet-client';
import { prepareErrorMessage } from '#/features/wallet/ethereum';
import EthereumWallet from '#/features/wallets/ethereum/wallet';

import { fromQuantums } from '#/utils/quantums';
import { AsyncResult } from '#/utils/types';

import * as EthereumService from './services/ethereum-service';
import * as LayerswapService from './services/layerswap-service';
import * as StarknetService from './services/starknet-service';

import type { Config as WagmiConfig } from 'wagmi';
import type { WalletState } from '#/features/wallet/wallet-context';
import type { ParaclearProvider } from '#/features/wallets/paraclear/provider';

const USDC_INCREASE_ALLOWANCE_AMOUNT = new BigNumber('100000');

export interface DepositService {
  fetchEthereumBalance(
    token: BridgedToken,
  ): AsyncResult<{ readonly balance: BigNumber }>;
  fetchEthereumBridgeAllowance(
    token: BridgedToken,
  ): AsyncResult<{ readonly allowance: BigNumber }>;
  increaseEthereumBridgeAllowance(
    token: BridgedToken,
    amount: BigNumber,
  ): AsyncResult<{ readonly allowance: BigNumber }>;
  depositToEthereumBridge(
    token: BridgedToken,
    amount: BigNumber,
  ): AsyncResult<void>;
  depositSingleStep(token: BridgedToken, amount: BigNumber): AsyncResult<void>;
  fetchDepositFee(
    bridge: string,
    amount: string,
    sourceChain: string,
    destinationChain: string,
  ): AsyncResult<{ readonly fee: BigNumber }>;
  depositViaLayerswap(
    token: BridgedToken,
    amount: BigNumber,
    chainId: string,
  ): AsyncResult<void>;
}

// WIP Through the strategy pattern will select the service
// to be used based on the form state (bridge and chain).
export function createService(
  walletState: WalletState,
  paraclearProvider: ParaclearProvider,
  systemConfig: SystemConfigView,
  wagmiConfig: WagmiConfig,
  walletClient: WalletClient,
): DepositService {
  return {
    async fetchEthereumBalance(token: BridgedToken) {
      if (walletState.baseAccount == null) {
        throw new Error(
          'fetchEthereumBalance called before address is available',
        );
      }

      if (walletState.baseAccount.type !== 'ethereum') {
        throw new Error('fetchEthereumBalance called for non Ethereum address');
      }

      try {
        const balance = await EthereumWallet.getErc20Balance(
          wagmiConfig,
          token.l1TokenAddress,
        );

        const usdcBalance = fromQuantums(balance, token.decimals);

        return { ok: true, data: { balance: usdcBalance } };
      } catch (err) {
        const description = 'Failed to request balance';
        const { message, isException } = prepareErrorMessage(description, err);
        if (isException) {
          const error = new Error(description, { cause: err });
          logException(error);
          return { ok: false, error, reason: message };
        }
        logEvent(message);
        return { ok: false, error: null, reason: message };
      }
    },

    async fetchEthereumBridgeAllowance(token: BridgedToken) {
      if (walletState.baseAccount == null) {
        throw new Error(
          'fetchEthereumBridgeAllowance called before address is available',
        );
      }

      if (walletState.baseAccount.type !== 'ethereum') {
        throw new Error(
          'fetchEthereumBridgeAllowance called for non Ethereum address',
        );
      }

      try {
        const allowance = await EthereumService.readAllowance(
          token,
          walletState.baseAccount.address,
          wagmiConfig,
        );
        const usdcAllowance = fromQuantums(allowance, token.decimals);

        return { ok: true, data: { allowance: usdcAllowance } };
      } catch (err) {
        const description =
          'Failed to request available ethereum bridge allowance';
        const { message, isException } = prepareErrorMessage(description, err);
        if (isException) {
          const error = new Error(description, { cause: err });
          logException(error);
          return { ok: false, error, reason: message };
        }
        logEvent(message);
        return { ok: false, error: null, reason: message };
      }
    },

    async increaseEthereumBridgeAllowance(
      token: BridgedToken,
      amount: BigNumber,
    ) {
      if (walletState.baseAccount == null) {
        throw new Error(
          'increaseEthereumBridgeAllowance called before address is available',
        );
      }

      if (walletState.baseAccount.type !== 'ethereum') {
        throw new Error(
          'increaseEthereumBridgeAllowance called for non Ethereum address',
        );
      }

      try {
        await EthereumService.requestAllowanceIncrease(
          token,
          walletState.baseAccount.address,
          BigNumber.max(amount, USDC_INCREASE_ALLOWANCE_AMOUNT).toString(),
          wagmiConfig,
        );
        const allowance = await EthereumService.readAllowance(
          token,
          walletState.baseAccount.address,
          wagmiConfig,
        );
        const usdcAllowance = fromQuantums(allowance, token.decimals);
        return { ok: true, data: { allowance: usdcAllowance } };
      } catch (err) {
        const description = 'Failed to increase Ethereum bridge allowance';
        const { message, isException } = prepareErrorMessage(description, err);
        if (isException) {
          const error = new Error(description, { cause: err });
          logException(error);
          return { ok: false, error, reason: message };
        }
        logEvent(message);
        return { ok: false, error: null, reason: message };
      }
    },

    async depositToEthereumBridge(token: BridgedToken, amount: BigNumber) {
      if (walletState.baseAccount == null) {
        throw new Error(
          'depositToEthereumBridge called before address available',
        );
      }

      if (walletState.baseAccount.type !== 'ethereum') {
        throw new Error(
          'depositToEthereumBridge called for non Ethereum address',
        );
      }

      if (walletState.paradexAddress == null) {
        throw new Error(
          'depositToEthereumBridge called before paradexAddress available',
        );
      }

      try {
        await EthereumService.requestDeposit(
          amount,
          walletState.baseAccount.address,
          walletState.paradexAddress,
          token,
          paraclearProvider,
          wagmiConfig,
        );
      } catch (err) {
        const description = 'Failed to deposit funds to Starknet';
        const { message, isException } = prepareErrorMessage(description, err);
        if (isException) {
          const error = new Error(description, { cause: err });
          logException(error);
          return { ok: false, error, reason: message };
        }
        logEvent(message);
        return { ok: false, error: null, reason: message };
      }

      return { ok: true, data: undefined };
    },

    async depositSingleStep(token: BridgedToken, amount: BigNumber) {
      if (walletState.baseAccount == null) {
        throw new Error(
          'Deposit (single step) called before address available',
        );
      }

      if (walletState.baseAccount.type !== 'ethereum') {
        throw new Error(
          'Deposit (single step) called for non Ethereum address',
        );
      }

      if (walletState.paradexAddress == null) {
        throw new Error(
          'Deposit (single step) called before paradexAddress available',
        );
      }

      try {
        await EthereumService.requestDepositSingleStep(
          amount,
          walletState.baseAccount.address,
          walletState.paradexAddress,
          token,
          paraclearProvider,
          systemConfig,
          wagmiConfig,
        );
      } catch (err) {
        const description =
          'Failed to deposit funds to Starknet with single step';
        const { message, isException } = prepareErrorMessage(description, err);
        if (isException) {
          const error = new Error(description, { cause: err });
          logException(error);
          return { ok: false, error, reason: message };
        }
        logEvent(message);
        return { ok: false, error: null, reason: message };
      }

      return { ok: true, data: undefined };
    },

    async fetchDepositFee(
      bridge: string,
      amount: string,
      sourceChain: string,
      destinationChain: string,
    ): AsyncResult<{ readonly fee: BigNumber }> {
      const chainGas = await getChainGas({
        source_chain: sourceChain,
        destination_chain: destinationChain,
        bridge,
        amount,
      });

      if (!chainGas.ok) {
        return { ok: false, error: chainGas.error, reason: chainGas.message };
      }

      return { ok: true, data: { fee: BigNumber(chainGas.data.fee_usdc) } };
    },

    async depositViaLayerswap(
      token: BridgedToken,
      amount: BigNumber,
      chainId: string,
    ) {
      if (walletState.baseAccount == null) {
        throw new Error(
          'depositToEthereumBridge called before address available',
        );
      }

      if (token.symbol !== 'USDC') {
        throw new Error('Only USDC is supported via Layerswap');
      }

      if (
        walletState.baseAccount.type !== 'ethereum' &&
        walletState.baseAccount.type !== 'starknet'
      ) {
        throw new Error(
          'depositToEthereumBridge called for non Ethereum or non Starknet address',
        );
      }

      if (walletState.paradexAddress == null) {
        throw new Error(
          'depositToEthereumBridge called before paradexAddress available',
        );
      }

      let response;
      try {
        response = await LayerswapService.submitSwapRequest(
          amount.toString(),
          "PARADEX_MAINNET",
          chainId,
          walletState.baseAccount.address,
          walletState.paradexAddress,
        );
      } catch (err) {
        const description = 'Failed to submit swap request to Layerswap';
        const { message, isException } = prepareErrorMessage(description, err);
        if (isException) {
          const error = new Error(description, { cause: err });
          logException(error);
          return { ok: false, error, reason: message };
        }
        logEvent(message);
        return { ok: false, error: null, reason: message };
      }

      if (!response.ok) {
        return {
          ok: false,
          error: response.error,
          reason: `Layerswap: ${response.message}`,
        };
      }

      if (walletState.baseAccount.type === 'ethereum') {
        try {
          await EthereumService.requestDepositLayerswap(
            wagmiConfig,
            response.data.to_address as `0x${string}`,
            response.data.call_data as `0x${string}`,
          );
        } catch (err) {
          const description = 'Failed to deposit funds via Layerswap bridge';
          const { message, isException } = prepareErrorMessage(
            description,
            err,
          );
          if (isException) {
            const error = new Error(description, { cause: err });
            logException(error);
            return { ok: false, error, reason: message };
          }
          logEvent(message);
          return { ok: false, error: null, reason: message };
        }
      }

      if (walletState.baseAccount.type === 'starknet') {
        try {
          await StarknetService.requestDepositLayerswap(
            walletClient,
            response.data.call_data,
          );
        } catch (err) {
          const description = 'Failed to deposit funds via Layerswap bridge';
          const { message, isException } = prepareErrorMessage(
            description,
            err,
          );
          if (isException) {
            const error = new Error(description, { cause: err });
            logException(error);
            return { ok: false, error, reason: message };
          }
          logEvent(message);
          return { ok: false, error: null, reason: message };
        }
      }

      return { ok: true, data: undefined };
    },
  };
}
