import type { SupportedChainId } from '#/features/bridging/types';

export interface Chain {
  readonly id: SupportedChainId;
  readonly name: string;
}

// NOTE: If a chain gets delisted, transactins for that chain will still
// exist, which would cause the transfers table to error out. To avoid this,
// delisting should be done with caution.
const CHAINS: readonly Chain[] = [
  { id: 'ARBITRUM', name: 'Arbitrum One' },
  { id: 'AVALANCHE', name: 'Avalanche' },
  { id: 'BASE', name: 'Base' },
  { id: 'BSC', name: 'BNB Smart Chain' },
  { id: 'ETHEREUM', name: 'Ethereum' },
  { id: 'LINEA', name: 'Linea' },
  { id: 'MANTA', name: 'Manta Pacific' },
  { id: 'MANTLE', name: 'Mantle' },
  { id: 'MODE', name: 'Mode' },
  { id: 'OPTIMISM', name: 'Optimism' },
  { id: 'POLYGON', name: 'Polygon' },
  { id: 'SCROLL', name: 'Scroll' },
  { id: 'SOLANA', name: 'Solana' },
  { id: 'STARKNET', name: 'Starknet' },
  { id: 'ZKSYNC', name: 'zkSync Era' },
  { id: 'RHINO', name: 'rhino.fi' },
];

/**
 * Returns all chains.
 */
export function getAllChains(): readonly Chain[] {
  return CHAINS;
}

/**
 * Finds a chain by its ID.
 */
export function findChain(id: string): Chain | undefined {
  return CHAINS.find((chain) => chain.id === id);
}
