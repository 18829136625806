import { msToDate } from '#/utils/date';
import getQueryString from '#/utils/getQueryString';

import { AsyncResp, BaseReq, requestApi } from './fetch-api';
import { UnixTimeMs } from './types';

export interface RawLiquidation {
  readonly id: string;
  readonly created_at: UnixTimeMs;
}

export interface Liquidation extends Omit<RawLiquidation, 'created_at'> {
  readonly created_at: Date;
}

interface LiquidationsReq extends BaseReq {
  readonly from: UnixTimeMs;
  readonly to?: UnixTimeMs;
}

interface RawLiquidationsResp {
  readonly results: readonly RawLiquidation[];
}

interface LiquidationsResp {
  readonly results: readonly Liquidation[];
}

export async function getLiquidations(
  req: LiquidationsReq,
): AsyncResp<LiquidationsResp> {
  const { signal, from, to } = req;

  const query = getQueryString([
    ['from', from],
    ['to', to],
  ]);

  const resp = await requestApi<RawLiquidationsResp>({
    signal,
    method: 'GET',
    url: `/liquidations${query}`,
  });

  if (!resp.ok) {
    return resp;
  }

  return {
    ...resp,
    data: {
      ...resp.data,
      results: resp.data.results.map(processLiquidation),
    },
  };
}

function processLiquidation(liquidation: RawLiquidation): Liquidation {
  return {
    ...liquidation,
    created_at: msToDate(liquidation.created_at),
  };
}
