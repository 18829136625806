import BigNumber from 'bignumber.js';

import { WITHDRAW_AMOUNT_SCALE } from '#/features/vaults/withdraw/config';

import type { VaultAccountSummary } from '#/api/vaults-account-summary';

/**
 * Check if the token amount is greater than or equal to the minimum withdrawable amount.
 * The minimum withdrawable amount is determined by the maximum precision allowed for withdraws.
 */
export function hasWithdrawableAmount(
  vaultAccountSummary: VaultAccountSummary,
) {
  const minimumWithdrawableAmount = new BigNumber(10).pow(
    -WITHDRAW_AMOUNT_SCALE,
  );
  const vtokenAmount = vaultAccountSummary.vtoken_amount;
  return vtokenAmount.isGreaterThanOrEqualTo(minimumWithdrawableAmount);
}
