import { STARK_KEY_SIGNATURE_VERSION } from '#/features/wallet/chain';
import { BaseAccount } from '#/features/wallet/common/base-account';

export const KEY_PREFIX = 'paradex';

export function getLastMarginRetrievalCheckStorageKey() {
  return getStorageKey(`last-margin-retrieval-check`);
}

export function getPreferredLanguageStorageKey() {
  return getStorageKey(`preferred-language`);
}

export function getParaclearWalletSeedStorageKey(chainId: number) {
  return getStorageKey(
    `starknet-wallet-seed-${STARK_KEY_SIGNATURE_VERSION}-${chainId}`,
  );
}

export function getParaclearWalletSeedStorageListKey(chainId: number) {
  return getStorageKey(
    `starknet-wallet-seed-${STARK_KEY_SIGNATURE_VERSION}-${chainId}-list`,
  );
}

export function getConnectedWalletSeedStorageKey(baseAccount: BaseAccount) {
  return baseAccount.type === 'ethereum'
    ? STORAGE_KEY_ETHEREUM_WALLET_ADDRESS
    : STORAGE_KEY_PUBLIC_STARKNET_WALLET_ADDRESS;
}

export const STORAGE_KEY_LAST_USED_SUBACCOUNT =
  getStorageKey(`last-used-subaccount`);

export const STORAGE_KEY_ETHEREUM_WALLET_ADDRESS = getStorageKey(
  `ethereum-wallet-address`,
);

export const STORAGE_KEY_ETHEREUM_WALLET_ADDRESS_LIST = getStorageKey(
  `ethereum-wallet-address-list`,
);

export const STORAGE_KEY_PUBLIC_STARKNET_WALLET_ADDRESS = getStorageKey(
  `public-starknet-wallet-address`,
);

export const STORAGE_KEY_SESSION_EXPIRES_AT =
  getStorageKey(`session-expires-at`);

export const STORAGE_KEY_SESSION_STARTED_AT =
  getStorageKey(`session-started-at`);

export const STORAGE_KEY_LAST_WALLET_KIND = getStorageKey(`last-wallet-kind`);

/**
 * Name of the last connected wallet. Only used for Ethereum
 * wallets, but can be later extended to other wallets.
 */
export const STORAGE_KEY_LAST_WALLET_NAME = getStorageKey(`last-wallet-name`);

export const STORAGE_LAST_USED_CONNECTOR = 'wagmi.recentConnectorId';

export const TRADING_VIEW_SETTINGS = getStorageKey(
  'trading-view-user-settings',
);

export const getUserPreferencesStorageKey = (userId: string | null) => {
  return getUserStorageKey('user-preferences', userId);
};

export const getIsBaseAccountCompliantStorageKey = (
  baseAccount: BaseAccount,
) => {
  return getStorageKey(
    `is-${baseAccount.type}-wallet-compliant-${baseAccount.address}`,
  );
};

/**
 * Long-term storage key to check if an account has onboarded before.
 * This is used to skip the onboarding request for users that have
 * already onboarded before. It should be persisted even after the
 * user logs out.
 */
export const getIsOnboardedStorageKey = (baseAccount: BaseAccount) => {
  return getStorageKey(
    `is-${baseAccount.type}-account-onboarded-${baseAccount.address}`,
  );
};

export const getMarketingBannerCloseAttemptsStorageKey = (version?: number) => {
  const key = 'banner-marketing-close-attempts';
  return getStorageKey(version != null ? `${key}-${version}` : key);
};

export function getUserStorageKey(keyId: string, userId: string | null) {
  const keySuffix = userId ?? 'unauthenticated';
  const fullKey = getStorageKey(`${keyId}-${keySuffix}`);
  return fullKey;
}

export function getTwitterStorageKey(userId: string | null) {
  return getUserStorageKey('twitter', userId);
}

export function getDiscordStorageKey(userId: string | null) {
  return getUserStorageKey('discord', userId);
}

export function getOrderBookOrderBookCurrencyStorageKey(userId: string | null) {
  return getUserStorageKey('order-book-currency-display', userId);
}

export function getFundingRatesTransformStorageKey(userId: string | null) {
  return getUserStorageKey('funding-rates-transform', userId);
}

function getStorageKey(keyId: string) {
  return `${KEY_PREFIX}-${keyId}`;
}
