import { Maybe } from '#/utils/types';

import { AsyncResp, BaseReq, requestApi } from './fetch-api';

interface GetConfigReq extends BaseReq {}

type Metadata = {
  /** @example "12.251.66.255" */
  ip?: string;
  /** @example "US" */
  country?: string;
  /** @example "TX" */
  region?: string;
};

type SuccessResponse = Maybe<Metadata>;

interface ErrorResponse {
  error?: 'GEO_IP_BLOCK';
  message?: string;
  data?: Metadata;
}

export async function getGeo(
  req: GetConfigReq,
): AsyncResp<SuccessResponse, ErrorResponse> {
  const { signal } = req;

  const resp = await requestApi<SuccessResponse, ErrorResponse>({
    signal,
    method: 'GET',
    url: `/geo`,
  });

  return resp;
}
