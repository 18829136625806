import { Dispatch, SetStateAction, useEffect, useRef } from 'react';

import { AsyncResp } from '#/api/fetch-api';
import { ParadexNotification } from '#/api/ws/ws';

import { logException } from '#/features/logging/logging';
import { useParadexWebSocket } from '#/features/ws/ws-context';

import { isAbortError } from '#/utils/errors';
import noop from '#/utils/noop';
import { sleep } from '#/utils/sleep';
import { AsyncResult, Result } from '#/utils/types';

export const RETRY_INTERVAL = 3_000; // update live-connection.cy.ts when changing

export interface LiveConnectionBaseState {
  readonly status: 'loading' | 'error' | 'connected' | 'disconnected';
  readonly loadRetryCount?: number;
  readonly error: string;
}

interface Options<
  EntityResp,
  EntityLiveConnectionState extends LiveConnectionBaseState,
  EntityWSNotification extends ParadexNotification,
> {
  setState: Dispatch<SetStateAction<EntityLiveConnectionState>>;
  channel: string;
  isReady?: boolean;
  fetchAll: (signal: AbortSignal) => AsyncResp<EntityResp>;
  setAll: (data: EntityResp) => void;
  resetAll: () => void;
  applyWSNotification: (notif: EntityWSNotification) => void;
  useWS?: boolean;
}

export default function useLiveConnection<
  EntityResp,
  EntityLiveConnectionState extends LiveConnectionBaseState,
  EntityWSNotification extends ParadexNotification,
>(
  options: Options<EntityResp, EntityLiveConnectionState, EntityWSNotification>,
): void {
  const {
    setState,
    channel,
    isReady = true,
    fetchAll,
    setAll,
    resetAll,
    applyWSNotification,
    useWS = true, // WIP: remove when WS API become fully available
  } = options;

  const ws = useParadexWebSocket();
  const subscribeRetryCount = useRef(0);

  useEffect(() => {
    if (!isReady) return;
    if (ws == null) return;

    let controller: AbortController | undefined;
    let clearQueuingHandler = noop;
    let clearNotificationHandler = noop;
    let isSubscribed = false;

    const isNotificationFromCorrectChannel = (
      notification: ParadexNotification,
    ): notification is EntityWSNotification => notification.channel === channel;

    /**
     * Subscribes to the WebSocket channel.
     * Subscribe requests are idempotent. Subscribing to an already
     * subscribed channel will not result in an error and has no effects.
     * @returns Promise that resolves when the subscription is confirmed.
     */
    const subscribe = async (signal: AbortSignal) => {
      const response = ws.request({
        method: 'subscribe',
        params: { channel },
        signal,
      });
      isSubscribed = true;
      return response;
    };

    /**
     * Unsubscribes from the WebSocket channel only if subscribed.
     * Unsubscribe requests are NOT idempotent. Unsubscribing from
     * an already unsubscribed channel will result in an error.
     */
    const unsubscribe = (signal?: AbortSignal) => {
      if (!isSubscribed) return;
      isSubscribed = false;
      ws.request({ method: 'unsubscribe', params: { channel }, signal })
        .then((resp) => {
          if (resp.error != null)
            throw new Error(`Failed unsubscribing from '${channel}' channel`, {
              cause: resp.error,
            });
        })
        .catch((err) => {
          if (isAbortError(err as Error)) return;
          const message = `Error unsubscribing from WebSocket channel='${channel}'`;
          logException(new Error(message, { cause: err }));
        });
    };

    const startQueuingNotifications = (queue: EntityWSNotification[]) => {
      clearQueuingHandler = ws.addNotificationHandler((notif) => {
        if (isNotificationFromCorrectChannel(notif)) {
          queue.push(notif);
        }
      });
      return clearQueuingHandler;
    };

    const startApplyingNotificationsToStore = () => {
      clearNotificationHandler = ws.addNotificationHandler((notif) => {
        if (isNotificationFromCorrectChannel(notif)) {
          applyWSNotification(notif);
        }
      });
    };

    const openIfReady = () => {
      if (useWS && ws.readyState !== WebSocket.OPEN) {
        return;
      }

      handleOpen();
    };

    /**
     * Stops processing and cleans up pending requests and notifications.
     */
    const cleanUp = () => {
      controller?.abort();
      clearQueuingHandler();
      clearNotificationHandler();
    };

    const sync = async (signal: AbortSignal): AsyncResult<void> => {
      setLoading();

      if (useWS) {
        const subResp = await subscribe(signal);
        if (signal.aborted) return { ok: true, data: undefined };
        if (subResp.error != null) {
          const message = `Failed subscribing to '${channel}' channel`;
          const error = new Error(message, { cause: subResp.error });
          return { ok: false, error };
        }
      }

      const notificationQueue: EntityWSNotification[] = [];
      const stopQueuing = startQueuingNotifications(notificationQueue);

      const fetchResp = await fetchAll(signal);
      if (signal.aborted) return { ok: true, data: undefined };
      if (!fetchResp.ok) {
        const message = `Failed fetching initial data for live connection, channel='${channel}'`;
        const error = new Error(message, { cause: fetchResp.error });
        return { ok: false, error };
      }
      setAll(fetchResp.data);

      stopQueuing();
      notificationQueue.forEach((notif) => {
        applyWSNotification(notif);
      });
      startApplyingNotificationsToStore();

      setConnected();
      return { ok: true, data: undefined };
    };

    const MAX_RETRY = 8;

    const handleOpen = () => {
      const handle = async () => {
        while (subscribeRetryCount.current <= MAX_RETRY) {
          controller?.abort();
          controller = new AbortController();
          let res: Result<void> = { ok: false, error: null };
          try {
            res = await sync(controller.signal);
            if (res.ok) {
              setRetryCount(0);
              subscribeRetryCount.current = 0;
              break;
            }
            const isLastAttempt = subscribeRetryCount.current === MAX_RETRY;
            if (isLastAttempt) {
              const message = `Could not load data after ${MAX_RETRY} attempts, channel='${channel}'`;
              const error = new Error(message, { cause: res.error });
              logException(error);
              setFailed(`Could not load data. Try reloading the page.`);
            } else {
              setFailed(`Could not load data. Retrying...`);
              await sleep(RETRY_INTERVAL);
            }
          } catch (_cause) {
            const cause = _cause as Error;
            if (isAbortError(cause)) break;
            const message = `Failed opening live connection for channel ${channel}, attempt #${subscribeRetryCount.current}`;
            const error = new Error(message, { cause });
            logException(error);
            setFailed(`Could not load data. Try reloading the page.`);
            break;
          } finally {
            subscribeRetryCount.current += 1;
            setRetryCount(subscribeRetryCount.current);
          }
        }
        if (subscribeRetryCount.current > MAX_RETRY) {
          const message = `Stopped retrying to subscribe to '${channel}' after '${MAX_RETRY}' failed attempts. Full page reload is required.`;
          const error = new Error(message);
          logException(error);
        }
      };

      handle().catch((cause: Error) => {
        const message = `Unexpected error opening live connection for channel='${channel}'`;
        const error = new Error(message, { cause });
        logException(error);
      });
    };

    /**
     * Handles WebSocket connection closed.
     * Sets the status as "disconnected".
     * Not considered as a failure but as a temporary
     * connection issue that can be recovered.
     */
    const handleClose = () => {
      setDisconnected();
    };

    /**
     * Handles WebSocket connection closed due to error.
     * Sets the status as "disconnected" with informational-only error message.
     * Not considered as a failure but as a temporary
     * connection issue that can be recovered.
     */
    const handleError = () => {
      setDisconnected('Connection error');
    };

    openIfReady();
    if (useWS) {
      ws.addEventListener('open', handleOpen);
      ws.addEventListener('close', handleClose);
      ws.addEventListener('error', handleError);
    }

    return () => {
      ws.removeEventListener('open', handleOpen);
      ws.removeEventListener('close', handleClose);
      ws.removeEventListener('error', handleError);
      cleanUp();
      resetAll();
      if (useWS && ws.readyState === WebSocket.OPEN) {
        unsubscribe(controller?.signal);
      }
    };

    function setLoading() {
      setState((state) => ({ ...state, status: 'loading', error: '' }));
    }

    function setRetryCount(loadRetryCount: number) {
      setState((state) => ({ ...state, loadRetryCount }));
    }

    function setConnected() {
      setState((state) => ({ ...state, status: 'connected', error: '' }));
    }

    function setFailed(error: string) {
      cleanUp();
      setState((state) => ({ ...state, status: 'error', error }));
    }

    function setDisconnected(error = '') {
      cleanUp();
      setState((state) => ({ ...state, status: 'disconnected', error }));
    }
  }, [
    setState,
    ws,
    isReady,
    channel,
    applyWSNotification,
    resetAll,
    fetchAll,
    setAll,
    useWS,
  ]);
}
