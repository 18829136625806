import BigNumber from'bignumber.js';import*as OrderFn from"../../order/functions";import*as Reducer from"./reducers";import{positionId}from"../positions/open-positions-store-context";import{leverageToImfBase}from"../../../utils/margin";import{INITIAL_STATE}from"./form-state";import*as OrderBuilderFn from"./functions";export function createActions(setState,activeMarket,activeMarketSummary,openPositionsStore,openOrdersStore,marketsSummaryStore,marketsStore,activeAccountView,walletView,maxSlippage,marginConfigsView,activeMarginAccountView){const activeAccount=activeMarginAccountView.account;const actions={updateAsset(nextAsset){setState(state=>{if(activeMarketSummary==null||activeMarket==null){return{...state,asset:nextAsset};}const price=OrderBuilderFn.getPriceByOrderType(state,activeMarket,activeMarketSummary);if(price==null)return{...state,asset:nextAsset};return Reducer.assetUpdate(state,nextAsset,activeMarket,price);});},updateAmount(amount){setState(state=>{const amountUpdatedState=Reducer.amountUpdate(state,amount,activeMarket);if(!state.isReduceOnly)return amountUpdatedState;if(activeMarket==null)return amountUpdatedState;if(activeAccount==null)return amountUpdatedState;/**
         * Reduce only:
         * Update leverage percentage based on amount/position.size percentage
         */const position=openPositionsStore.get(positionId(activeMarket.symbol,activeAccount));const amountPercentage=OrderBuilderFn.getAmountPositionPercentage(position,amountUpdatedState,activeMarket,activeMarketSummary);if(amountPercentage==null)return amountUpdatedState;return{...amountUpdatedState,leverage:amountPercentage};});},updateAmountFromCurrentPosition(){if(activeMarket==null)return;if(activeAccount==null)return;const position=openPositionsStore.get(positionId(activeMarket.symbol,activeAccount));const amount=position?.size.abs().toString()??'0';setState(formState=>{const nextState={...formState,asset:activeMarket.base_currency};return Reducer.amountUpdate(nextState,amount,activeMarket);});},updateAmountBasedOnLeverage(){setState(formState=>{if(!walletView.isSignedIn){return formState;}if(activeAccount==null)return formState;if(activeMarket==null){throw new Error('activeMarket is not defined');}if(activeMarketSummary==null){throw new Error('activeMarketSummary is not defined');}const position=openPositionsStore.get(positionId(activeMarket.symbol,activeAccount));const accountImfBase=leverageToImfBase(marginConfigsView.activeMarketConfig?.leverage??null);if(!formState.isReduceOnly||position==null){return OrderBuilderFn.getAmountBasedOnMarginLeverage({formState,openOrdersStore,openPositionsStore,activeMarket,activeMarketSummary,marketsSummaryStore,marketsStore,activeAccountView,maxSlippage,accountImfBase});}// Calculate amount using position size as base
const leveragePercent=formState.leverage.dividedBy(100);const price=OrderBuilderFn.getPriceByOrderType(formState,activeMarket,activeMarketSummary);if(price==null||formState.asset==null)return formState;const maxAmount=OrderBuilderFn.getPositionAmountForCurrentAsset(formState.asset,price,position,activeMarket);const amountBasedOnPositionSize=maxAmount.abs().times(leveragePercent);return Reducer.amountUpdate(formState,amountBasedOnPositionSize.toString(),activeMarket);});},updateLeverage(amount){const parsed=new BigNumber(amount);const sanitized=parsed.isFinite()?parsed:BigNumber(0);const withinBounds=BigNumber.max(0,BigNumber.min(sanitized,100));setState(state=>({...state,leverage:withinBounds}));},updateLimitPrice(limitPrice){const parsed=new BigNumber(limitPrice);const sanitized=parsed.isFinite()?parsed:null;setState(state=>OrderFn.isLimitOrder({type:state.orderType})?{...state,limitPrice:sanitized}:state);},updateTriggerPrice(triggerPrice){const parsed=new BigNumber(triggerPrice);const sanitized=parsed.isFinite()?parsed:null;setState(state=>OrderFn.isStopOrder({type:state.orderType})?{...state,triggerPrice:sanitized}:state);},updateType(type){setState(state=>({...state,formType:type,orderType:OrderBuilderFn.getOrderTypeByForm(type)}));actions.updateAmountBasedOnLeverage();},updateSide(side){setState(state=>({...state,side}));actions.updateAmountBasedOnLeverage();},updateIsPostOnly(isPostOnly){setState(state=>({...state,isPostOnly}));},/**
     * When selecting 'Reduce Only', if user has a position:
     * - Set amount to position size
     * - Set form side to the opposite of the position
     */updateIsReduceOnly(isReduceOnly){setState(state=>{if(!isReduceOnly||activeMarket==null||activeAccount==null){return{...state,leverage:BigNumber(0),isReduceOnly};}const position=openPositionsStore.get(positionId(activeMarket.symbol,activeAccount));if(position==null)return{...state,isReduceOnly};const reduceSide=position.side==='LONG'?'SELL':'BUY';const reduceAmount=position.size.abs();/**
         * Keep previous amount and update leverage percentage with amount/position.size
         * when amount is empty or smaller than position
         */const amountPositionRatio=OrderBuilderFn.getAmountPositionPercentage(position,state,activeMarket,activeMarketSummary);if(state.amount!=null&&amountPositionRatio!=null&&amountPositionRatio.isLessThan(100)){return{...state,isReduceOnly:true,side:reduceSide,leverage:amountPositionRatio,amount:state.amount};}return{...state,asset:activeMarket.base_currency,isReduceOnly:true,side:reduceSide,leverage:BigNumber(100),amount:reduceAmount};});},updateTimeInForce(timeInForce){setState(state=>({...state,timeInForce}));},reset(){setState(({amount,asset,isPostOnly,isReduceOnly,limitPrice,side,timeInForce,triggerPrice,leverage,orderType,formType})=>{const isReduceOnlyFullFill=isReduceOnly&&leverage.eq(100);return{...INITIAL_STATE,leverage,amount,asset,isPostOnly,isReduceOnly,limitPrice,side,timeInForce,triggerPrice,orderType,formType,...(isReduceOnlyFullFill&&timeInForce!=='IOC'&&{isReduceOnly:false,amount:null,leverage:BigNumber(0)})};});}};return actions;}