import { useAccountView } from '#/features/account/account-context';
import { useWalletView } from '#/features/wallet/wallet-context';
import { useWithdrawModalActions } from '#/features/withdraw/modal-context';

/**
 * Hook to be consumed by UI components to trigger withdraw flow
 */
export function useWithdrawAction() {
  const withdrawModalActions = useWithdrawModalActions();
  const walletView = useWalletView();
  const accountView = useAccountView();

  const isDisabled = !walletView.isSignedIn || accountView.isBeingLiquidated;

  const execute = () => {
    withdrawModalActions.open();
  };

  return { execute, isDisabled };
}
