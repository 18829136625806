import { getUnixTime } from 'date-fns';

import { postAuth } from '#/api/account';
import * as auth from '#/api/auth';

import { useAuthActions, useAuthState } from '#/features/auth/auth-context';
import * as ParadexSubAccount from '#/features/storage/paradex-sub-account';
import {
  STORAGE_KEY_SESSION_EXPIRES_AT,
  STORAGE_KEY_SESSION_STARTED_AT,
} from '#/features/storage/storage';
import { useSystemConfig } from '#/features/system/system-config-context';
import { logGeoMetadata } from '#/features/system/utils';
import { buildAuthTypedData } from '#/features/wallet/chain-l2';
import { useWalletState } from '#/features/wallet/wallet-context';
import ParaclearWallet from '#/features/wallets/paraclear/wallet';
import { useParadexWebSocket } from '#/features/ws/ws-context';

import { setStorageItem } from '#/utils/localStorage';

import { trackAccountSwitchStep } from '../logging/datadog/user-session-tracking';

import type { Address } from '#/features/wallets/paraclear/wallet';

const SEVEN_DAYS_MS = 7 * 24 * 60 * 60 * 1000;

/**
 * Switch to a sub-account or back to the main account.
 * Does not support switching to a different main account.
 */
export function useSwitchAccount() {
  const [authState, setAuthState] = useAuthState();
  const authActions = useAuthActions();
  const [_walletState, setWalletState] = useWalletState();

  const ws = useParadexWebSocket();
  const config = useSystemConfig();

  // 1. Perform Onboarding
  // 2. Authenticate the account
  // 3. Update authentication and wallet stores
  // 4. Reauthenticate WebSocket connection
  return async (accountAddress: Address): Promise<void> => {
    const originalAddress = ParaclearWallet.getAddress();

    if (ws == null) {
      throw new Error('Cannot switch accounts when WS is not available.');
    }

    trackAccountSwitchStep('started');
    const expiresAt = getUnixTime(Date.now() + SEVEN_DAYS_MS);
    try {
      ParaclearWallet.switchActiveAccount(accountAddress);

      const authRequest = {
        method: 'POST',
        path: '/v1/auth',
        body: '',
        timestamp: getUnixTime(new Date()),
        expiration: expiresAt,
      };
      const authRequestTypedData = buildAuthTypedData(
        authRequest,
        config.starknet.chainId,
      );
      const authSignature = ParaclearWallet.signTypedData(authRequestTypedData);

      logGeoMetadata('Auth request');
      const httpAuthResponse = await postAuth({
        paradexAddress: ParaclearWallet.getAddress(),
        signature: authSignature,
        createdAt: authRequest.timestamp,
        expiresAt: authRequest.expiration,
      });

      if (!httpAuthResponse.ok) {
        trackAccountSwitchStep('failed');
        const message = `Failed to retrieve auth JWT token for account: ${httpAuthResponse.message}`;
        throw new Error(message, { cause: httpAuthResponse.error });
      }

      const mainAccount = ParaclearWallet.getMainAccount();
      const isMainAccount = mainAccount.address === accountAddress;

      // 3. Update Stores
      authActions.reset({ shouldForgetUser: false });

      // 3.1. Auth store
      auth.setAuthToken(httpAuthResponse.data.jwt_token);
      setAuthState((prevAuthState) => ({
        token: httpAuthResponse.data.jwt_token,
        signedRequest: { request: authRequest, signature: authSignature },
        error: null,
        walletSeed: null,
        baseAccount: ParaclearWallet.getBaseAccount(),
        mainAccount: mainAccount.baseAccount,
        rememberMe: prevAuthState.rememberMe,
      }));

      // 3.2.Store or reset sub-account, so the session is recovered
      if (isMainAccount) {
        ParadexSubAccount.reset();
      } else if (authState.rememberMe) {
        ParadexSubAccount.set({
          address: accountAddress,
        });
      }
      setStorageItem(STORAGE_KEY_SESSION_EXPIRES_AT, expiresAt);
      setStorageItem(STORAGE_KEY_SESSION_STARTED_AT, Date.now());
      // 3.3. Wallet store
      setWalletState((walletState) => ({
        step: 'wallet_connected_idle',
        baseAccount: ParaclearWallet.getBaseAccount(),
        paradexAddress: ParaclearWallet.getAddress(),
        isOnboarded: true,
        referralCode: null,
        isSessionWalletActive: walletState.isSessionWalletActive,
        isRequestInProgress: walletState.isRequestInProgress,
        isWalletConnected: walletState.isWalletConnected,
        walletKind: walletState.walletKind,
        walletName: walletState.walletName,
        error: '',
      }));
      trackAccountSwitchStep('success');
      // 4. Re-authenticate WebSocket connection
      // The WebSocket connection should be fully re-created and re-authenticated
      // for the new account. Full WS recreation is required as an open WS
      // connection can not be 'un-authenticated', only closed.
      ws.reconnect();
    } catch (cause: unknown) {
      ParaclearWallet.switchActiveAccount(originalAddress);
      throw cause;
    }
  };
}
