import { AccountProfile } from '#/api/account';
import { AsyncResp, BaseReq, requestApi } from '#/api/fetch-api';

interface TwitterAuthReq extends BaseReq {}

export interface TwitterAuth {
  readonly url: string;
  readonly state: string;
  readonly code_verifier: string;
}

export interface LinkTwitterProfileReq extends BaseReq {
  readonly code: string;
  readonly code_verifier: string;
}

export interface UnlinkTwitterProfileReq extends BaseReq {}

export async function getTwitterAuth(
  req: TwitterAuthReq,
): AsyncResp<TwitterAuth> {
  const { signal } = req;
  const resp = await requestApi<TwitterAuth>({
    signal,
    method: 'GET',
    url: '/campaigns/public/twitter/authorize',
  });

  return resp;
}

export async function linkTwitterProfile(
  req: LinkTwitterProfileReq,
): AsyncResp<AccountProfile> {
  const { signal, ...body } = req;
  const resp = await requestApi<AccountProfile>({
    signal,
    method: 'POST',
    url: '/campaigns/private/twitter/link',
    body,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return resp;
}

export async function unlinkTwitterProfile(
  req: UnlinkTwitterProfileReq,
): AsyncResp<AccountProfile> {
  const { signal } = req;
  const resp = await requestApi<AccountProfile>({
    signal,
    method: 'DELETE',
    url: '/campaigns/private/twitter/unlink',
  });

  return resp;
}
