/* eslint-disable no-else-return */
import BigNumber from 'bignumber.js';
import { Maybe } from 'yup';

import { Market, PerpetualMarket } from '#/api/markets';
import { MarketSummary } from '#/api/markets-summary';
import { OpenOrder, OrderSide } from '#/api/orders';
import { OpenPosition } from '#/api/positions';

import { accountImfWithCap } from '#/utils/margin';

import {
  limitOrderLoss,
  marketOrderLoss,
  stopLimitOrderLoss,
  stopMarketOrderLoss,
} from './calculate_open_loss';

export type SimplifiedOpenOrder = Pick<
  OpenOrder,
  | 'size'
  | 'side'
  | 'type'
  | 'price'
  | 'flags'
  | 'remaining_size'
  | 'status'
  | 'trigger_price'
>;

/**
 * Adapted from
 * @see Paradex Pepe Calculator
 */
export default function calculate_margin_requirement(
  margin_check: 'Initial' | 'Maintenance',
  openPosition: Maybe<Pick<OpenPosition, 'market' | 'size' | 'side'>>,
  marketSummary: MarketSummary,
  market: PerpetualMarket,
  openOrders: SimplifiedOpenOrder[],
  profileMaxSlippage: BigNumber | null,
  accountImfBase: BigNumber | null,
): BigNumber {
  const openNotional = calcOpenNotional(
    openOrders,
    openPosition,
    market,
    marketSummary,
    margin_check === 'Initial',
  );

  const openLoss = calculate_open_loss(
    openPosition,
    openOrders,
    marketSummary,
    market,
    profileMaxSlippage,
  );
  const feeProvision = calculate_fee_provision(
    margin_check,
    openPosition,
    marketSummary,
    openOrders,
  );

  // update this to option margin requirement calculation
  if (market.delta1_cross_margin_params == null) {
    return BigNumber(0);
  }

  const { imf_base } = market.delta1_cross_margin_params;
  const { mmf_factor } = market.delta1_cross_margin_params;
  // use account imf base if available, otherwise use market imf base
  const IMF = accountImfWithCap(imf_base, accountImfBase);

  if (margin_check === 'Initial') {
    const initial_margin_requirement = openNotional
      .multipliedBy(IMF)
      .plus(openLoss)
      .plus(feeProvision);

    return initial_margin_requirement;
  }

  const MMF = mmf_factor.times(IMF);

  const maintenance_margin_requirement = openNotional
    .times(MMF)
    .plus(feeProvision);

  return maintenance_margin_requirement;
}

function calculate_fee_provision(
  margin_check: 'Initial' | 'Maintenance',
  assetOpenPosition: Maybe<Pick<OpenPosition, 'size'>>,
  marketSummary: MarketSummary,
  openOrders: SimplifiedOpenOrder[],
) {
  const fee_rate = BigNumber.max(0.0003, -0.00005);
  const position = assetOpenPosition;

  if (marketSummary.mark_price == null) {
    throw new Error(`calculate_fee_provision: 'mark_price' is not available`);
  }

  const positionSize = position?.size ?? BigNumber(0);
  const open_quantity =
    margin_check === 'Maintenance'
      ? positionSize.absoluteValue()
      : positionSize
          .absoluteValue()
          .plus(total_order_size('BUY', openOrders))
          .plus(total_order_size('SELL', openOrders));

  const notional = open_quantity.multipliedBy(marketSummary.mark_price);
  const trading_fee = fee_rate.multipliedBy(notional);

  return trading_fee;
}

export function total_order_size(
  side: OrderSide,
  openOrders: SimplifiedOpenOrder[],
): BigNumber {
  const oneSideOrders = openOrders.filter((order) => order.side === side);

  const totalSize = oneSideOrders.reduce(
    (acc, order) => acc.plus(order.remaining_size),
    BigNumber(0),
  );

  return totalSize;
}

function calcOpenNotional(
  openOrders: SimplifiedOpenOrder[],
  openPosition: Maybe<Pick<OpenPosition, 'market' | 'size' | 'side'>>,
  market: Market,
  marketSummary: MarketSummary,
  include_orders = true,
): BigNumber {
  let notionalSum = BigNumber(0);
  const position = openPosition ?? {
    size: BigNumber(0),
    side: 'LONG',
    market: market.symbol,
  };

  const openBuySize = calcOpenSize('BUY', openOrders, position);
  const openSellSize = calcOpenSize('SELL', openOrders, position);
  const openSize = include_orders
    ? BigNumber.maximum(openBuySize, openSellSize)
    : position.size.abs();

  switch (market.asset_kind) {
    case 'PERP_OPTION': {
      if (marketSummary.underlying_price == null) return BigNumber(NaN);
      if (marketSummary.delta == null) return BigNumber(NaN);
      const openNotional = openSize
        .times(marketSummary.underlying_price)
        .times(marketSummary.delta.abs());
      notionalSum = notionalSum.plus(openNotional);
      break;
    }
    case 'PERP': {
      if (marketSummary.mark_price == null) return BigNumber(NaN);
      const openNotional = openSize.times(marketSummary.mark_price);
      notionalSum = notionalSum.plus(openNotional);
      break;
    }
    // no default
  }
  return notionalSum;
}

function calcOpenSize(
  side: OrderSide,
  openOrders: SimplifiedOpenOrder[],
  openPosition: Pick<OpenPosition, 'size' | 'side'>,
) {
  const positionSize =
    openPosition.side === 'SHORT'
      ? openPosition.size.abs().negated()
      : openPosition.size;

  const filteredOrders = openOrders.filter(
    (order) =>
      (order.status === 'OPEN' || order.status === 'UNTRIGGERED') &&
      order.side === side,
  );

  // On position side, we only consider non-reduce-only orders
  let ordersSizeSum = BigNumber.sum(
    0,
    ...filteredOrders
      .filter((order) => !order.flags.includes('REDUCE_ONLY'))
      .map((order) => order.remaining_size),
  );

  // On opposite side, we consider reduce-only orders capped by position size
  const isOppositeSide =
    (side === 'SELL' && openPosition.side === 'LONG') ||
    (side === 'BUY' && openPosition.side === 'SHORT');
  if (isOppositeSide) {
    const reduceOnlyOrdersSizeSum = BigNumber.sum(
      0,
      ...filteredOrders
        .filter((order) => order.flags.includes('REDUCE_ONLY'))
        .map((order) => order.remaining_size),
    );

    ordersSizeSum = ordersSizeSum.plus(
      BigNumber.minimum(reduceOnlyOrdersSizeSum, openPosition.size.abs()),
    );
  }

  return side === 'SELL'
    ? BigNumber.maximum(0, ordersSizeSum.minus(positionSize))
    : BigNumber.maximum(0, ordersSizeSum.plus(positionSize));
}

export function calculate_open_loss(
  openPosition: Maybe<Pick<OpenPosition, 'market' | 'size' | 'side'>>,
  openOrders: SimplifiedOpenOrder[],
  marketSummary: MarketSummary,
  market: Market,
  profileMaxSlippage: BigNumber | null,
) {
  return BigNumber.max(
    calculate_open_loss_by_side(
      'BUY',
      openPosition,
      openOrders,
      marketSummary,
      market,
      profileMaxSlippage,
    ),
    calculate_open_loss_by_side(
      'SELL',
      openPosition,
      openOrders,
      marketSummary,
      market,
      profileMaxSlippage,
    ),
  );
}

function calculate_open_loss_by_side(
  side: OrderSide,
  assetOpenPosition: Maybe<Pick<OpenPosition, 'market' | 'size' | 'side'>>,
  orders: SimplifiedOpenOrder[],
  marketSummary: MarketSummary,
  market: Market,
  profileMaxSlippage: BigNumber | null,
): BigNumber {
  // Get the orders for the given side
  const oneSideOrders = orders.filter((order) => order.side === side);

  // calculate open loss of non-reduce-only orders
  const lossBySide = oneSideOrders
    .filter((order) => !order.flags.includes('REDUCE_ONLY'))
    .reduce(
      (sum, order) =>
        sum.plus(
          order_open_loss(order, marketSummary, market, profileMaxSlippage),
        ),
      BigNumber(0),
    );

  const isOppositeSide =
    assetOpenPosition != null &&
    ((side === 'SELL' && assetOpenPosition.side === 'LONG') ||
      (side === 'BUY' && assetOpenPosition.side === 'SHORT'));

  // calculate capped open loss of reduce only orders on the offsetting side
  if (isOppositeSide) {
    const reduceOnlyOrders = oneSideOrders.filter((order) =>
      order.flags.includes('REDUCE_ONLY'),
    );

    // Calculate and sort losses in descending order
    const sortedLosses = reduceOnlyOrders
      .map((order) => ({
        loss: order_open_loss(order, marketSummary, market, profileMaxSlippage),
        size: order.remaining_size,
      }))
      .sort((a, b) => b.loss.minus(a.loss).toNumber());

    // Sum losses up to position size
    let remainingPositionSize = assetOpenPosition.size;
    const reduceOnlyLoss = sortedLosses.reduce((sum, { loss, size }) => {
      if (remainingPositionSize.lte(0)) {
        return sum;
      }
      const sizeToConsider = BigNumber.min(size, remainingPositionSize);
      remainingPositionSize = remainingPositionSize.minus(sizeToConsider);
      return sum.plus(loss.times(sizeToConsider).div(size));
    }, BigNumber(0));

    return lossBySide.plus(reduceOnlyLoss);
  }

  return lossBySide;
}

export function order_open_loss(
  order: SimplifiedOpenOrder,
  marketSummary: MarketSummary,
  market: Market,
  profileMaxSlippage: BigNumber | null,
): BigNumber {
  const { price_bands_width: marketMaxSlippage = BigNumber(0.01) } = market;

  // in this case we need to use the market max slippage
  // to estimate worst case scenario for loss
  const maxSlippage =
    profileMaxSlippage == null || profileMaxSlippage.isZero()
      ? marketMaxSlippage
      : BigNumber.max(profileMaxSlippage, marketMaxSlippage);

  if (marketSummary.mark_price == null) {
    throw new Error(
      `order_open_loss: 'mark_price' is null for market='${marketSummary.symbol}'`,
    );
  }

  switch (order.type) {
    case 'MARKET':
      return marketOrderLoss(order, maxSlippage, marketSummary);
    case 'STOP_MARKET':
    case 'STOP_LOSS_MARKET':
    case 'TAKE_PROFIT_MARKET':
      return stopMarketOrderLoss(order, maxSlippage);
    case 'STOP_LIMIT':
    case 'STOP_LOSS_LIMIT':
    case 'TAKE_PROFIT_LIMIT':
      return stopLimitOrderLoss(order);
    case 'LIMIT':
      return limitOrderLoss(order, marketSummary);
    //no default
  }
}
