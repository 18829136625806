import { logException } from '#/features/logging/logging';

export type EventHandler<T> = (event: T) => Promise<void> | void;

export type ClearSubscription = () => void;

export interface Topic<T> {
  readonly publish: (event: T) => void;
  readonly subscribe: (handler: EventHandler<T>) => ClearSubscription;
  readonly unsubscribe: (handler: EventHandler<T>) => void;
  readonly getSubscribersSize: () => number;
}

export default function createTopic<T>(name: string): Topic<T> {
  const subscribers = new Set<EventHandler<T>>();

  const handlerErrorLogger = (cause: unknown) => {
    const message = `Event handler threw on topic '${name}'`;
    logException(new Error(message, { cause }));
  };

  /**
   * Publish an event to this topic
   */
  function publish(event: T) {
    for (const handler of subscribers) {
      (async () => handler(event))().catch(handlerErrorLogger);
    }
  }

  /**
   * @returns Function that when called cancels the subscription
   */
  function subscribe(handler: EventHandler<T>) {
    subscribers.add(handler);
    return () => {
      subscribers.delete(handler);
    };
  }

  /**
   * @return Whether the handler subscription was found and removed
   */
  function unsubscribe(handler: EventHandler<T>) {
    return subscribers.delete(handler);
  }

  function getSubscribersSize() {
    return subscribers.size;
  }
  return { publish, subscribe, unsubscribe, getSubscribersSize };
}
