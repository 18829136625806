import { adapter } from '#/features/favorite-markets/state';

import type { Dispatch, SetStateAction } from 'react';
import type { FavoriteMarketsState } from '#/features/favorite-markets/state';

export interface FavoriteMarketsActions {
  readonly addFavoriteMarket: (symbol: string) => void;
  readonly removeFavoriteMarket: (symbol: string) => void;
}

export function createActions(
  setState: Dispatch<SetStateAction<FavoriteMarketsState>>,
): FavoriteMarketsActions {
  function addFavoriteMarket(symbol: string): void {
    setState((state) => ({
      ...state,
      favoriteMarkets: adapter.addOne(state.favoriteMarkets, {
        symbol,
        timestamp: Date.now(),
      }),
    }));
  }

  function removeFavoriteMarket(symbol: string): void {
    setState((state) => ({
      ...state,
      favoriteMarkets: adapter.removeOne(state.favoriteMarkets, symbol),
    }));
  }

  return {
    addFavoriteMarket,
    removeFavoriteMarket,
  };
}
