import BigNumber from 'bignumber.js';

import { AsyncResp, BaseReq, requestApi } from './fetch-api';
import { Market } from './markets';

export type MarginType = 'CROSS' | 'ISOLATED';

export interface AccountMarginConfigRaw {
  readonly market: Market['symbol'];
  readonly leverage: number;
  readonly margin_type: MarginType;
  readonly isolated_margin_leverage: number;
}

export interface GetAccountMarginConfigRespRaw {
  readonly account: string;
  readonly configs: AccountMarginConfigRaw[];
}

export interface MarginConfig {
  readonly market: Market['symbol'];
  readonly leverage: BigNumber;
  readonly margin_type: MarginType;
  readonly isolated_margin_leverage: BigNumber;
}

export interface GetAccountMarginConfigResp {
  readonly configs: MarginConfig[];
}

interface GetMarginConfigReq extends BaseReq {}

export interface SetAccountMarginConfigRespRaw {
  readonly account: string;
  readonly market: Market['symbol'];
  readonly leverage: number;
  readonly margin_type: MarginType;
}

export interface SetAccountMarginConfigResp
  extends Omit<SetAccountMarginConfigRespRaw, 'leverage'> {
  readonly leverage: BigNumber;
}

export interface UpdateMargiConfignReq extends BaseReq {
  readonly market: Market['symbol'];
  readonly leverage: number;
  readonly margin_type: MarginType;
  readonly on_behalf_of_account?: string;
}

export async function getMarginConfigs(
  req: GetMarginConfigReq,
): AsyncResp<GetAccountMarginConfigResp> {
  const { signal } = req;

  const resp = await requestApi<GetAccountMarginConfigRespRaw>({
    signal,
    method: 'GET',
    url: `/account/margin`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: { configs: resp.data.configs.map(processAccountMarginConfig) },
  };
}

export async function setMargin(
  req: UpdateMargiConfignReq,
): AsyncResp<SetAccountMarginConfigResp> {
  const { signal, market, ...body } = req;

  const resp = await requestApi<SetAccountMarginConfigRespRaw>({
    signal,
    method: 'POST',
    url: `/account/margin/${market}`,
    body,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: {
      ...resp.data,
      leverage: new BigNumber(resp.data.leverage),
    },
  };
}

export function processAccountMarginConfig(
  entry: AccountMarginConfigRaw,
): MarginConfig {
  return {
    market: entry.market,
    leverage: new BigNumber(entry.leverage),
    margin_type: entry.margin_type,
    isolated_margin_leverage: new BigNumber(entry.isolated_margin_leverage),
  };
}
