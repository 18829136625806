import { lighten } from 'polished';
import { css } from 'styled-components';

import { c7A35CB, cCC38FF, cDE7FFF, cFDD34C, cFDDD78, cFFCB23 } from '#/colors';

export const MARKET_INFO_AREA_HEIGHT = '56px';

export const FAVORITES_BAR_HEIGHT = '38px';

export const colorUnderline = css`
  opacity: 1;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: -4px;
    right: -4px;
    height: 2px;
    background: ${cCC38FF};
    border-radius: 1px;
  }
`;

export const colorUnderlineOrderBuilder = css`
  ${colorUnderline}
  opacity: 1;
  position: relative;

  &:after {
    left: 0;
    right: 0;
  }
`;

export const pointsTextGradient = css`
  background: linear-gradient(
    180deg,
    ${cFDDD78} 11%,
    ${cFDD34C} 55%,
    ${cFFCB23} 85%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const textGradient = css`
  background: linear-gradient(180deg, ${cDE7FFF} 0%, ${cCC38FF} 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const buttonGradient = css`
  background: linear-gradient(180deg, ${cCC38FF} 0%, ${c7A35CB} 100%);
`;

export const buttonGradientHover = css`
  background: linear-gradient(
    180deg,
    ${lighten(0.02, cCC38FF)} 0%,
    ${lighten(0.02, c7A35CB)} 100%
  );
`;
