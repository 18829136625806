import { PriceKind } from '#/components/perpetuals/trading-view/charting';

import getQueryString from '#/utils/getQueryString';

import { ResolutionString } from '#/vendor/charting_library';

import { AsyncResp, BaseReq, requestApi } from './fetch-api';

export interface PriceSource {
  readonly id: PriceKind;
  readonly name: string;
}

export interface RawTradingViewSymbol {
  readonly ticker: string;
  readonly name: string;
  readonly description: string;
  readonly type: string;
  readonly session: string;
  readonly timezone: string;
  readonly exchange: string;
  readonly minmov: number;
  readonly pricescale: number;
  readonly has_intraday: boolean;
  readonly has_weekly_and_monthly: boolean;
  readonly supported_resolutions: readonly ResolutionString[];
  readonly visible_plot_sets: string;
  readonly volume_precision: number;
  readonly has_empty_bars: boolean;
  readonly price_source_id: PriceKind;
  readonly price_sources: readonly PriceSource[];
}

export interface TradingViewSymbol extends RawTradingViewSymbol {}

interface TradingViewSymbolReq extends BaseReq {
  readonly symbol: string;
}

export async function getTradingViewSymbol(
  req: TradingViewSymbolReq,
): AsyncResp<TradingViewSymbol> {
  const { signal, symbol } = req;

  const queryString = getQueryString([
    ['symbol', symbol],
    ['request_source', 'paradex-ui'],
  ]);

  const resp = await requestApi<RawTradingViewSymbol>({
    signal,
    method: 'GET',
    url: `/tradingview/symbols${queryString}`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: processTradingViewSymbol(resp.data),
  };
}

function processTradingViewSymbol(
  symbol: RawTradingViewSymbol,
): TradingViewSymbol {
  return symbol;
}
