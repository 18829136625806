/* eslint-disable @typescript-eslint/no-base-to-string */
import {
  RumActionEventDomainContext,
  RumErrorEventDomainContext,
  RumEvent,
  RumFetchResourceEventDomainContext,
  RumLongTaskEventDomainContext,
  RumOtherResourceEventDomainContext,
  RumViewEventDomainContext,
  RumXhrResourceEventDomainContext,
} from '@datadog/browser-rum';

import { logException } from '#/features/logging/logging';

import maskString from '#/utils/maskString';

type Context =
  | RumViewEventDomainContext
  | RumActionEventDomainContext
  | RumFetchResourceEventDomainContext
  | RumXhrResourceEventDomainContext
  | RumOtherResourceEventDomainContext
  | RumErrorEventDomainContext
  | RumLongTaskEventDomainContext;

interface BaseBody {
  signature?: string;
  signature_timestamp?: number;
}

/**
 * Captures POST order requests and enriches context with body
 * @returns
 */
export function enrichPostOrderReq(event: RumEvent, context: Context) {
  const isResourceFetch =
    event.type === 'resource' && event.resource.type === 'fetch';
  if (!isResourceFetch) return;

  const isOrderPost =
    event.resource.method === 'POST' && /order/iu.test(event.resource.url);
  if (!isOrderPost) return;

  if (!('requestInit' in context) || context.requestInit?.body == null) return;
  if (event.context == null) return;

  try {
    const body = JSON.parse(context.requestInit.body.toString()) as BaseBody;
    const maskedBody = {
      ...body,
      ...(body.signature != null && {
        signature: maskString(body.signature, 5, 5),
      }),
      ...(body.signature_timestamp != null && {
        signature: maskString(body.signature_timestamp.toString(), 5, 5),
      }),
    };
    event.context.requestBody = maskedBody;
  } catch (error) {
    logException('Failed to parse order POST body', { cause: error });
  }
}
