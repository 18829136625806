import { Keys } from "./en";

const translations: Record<Keys, string> = {
  "Display trade history on chart": "在图表上显示交易历史",
  "Add markets to Favorites in the Markets Browser":
    "在市场浏览器中添加收藏市场",
  "Log in": "登录",
  "Export embedded wallet": "导出嵌入式钱包",
  "Email or Social": "电子邮件或社交",
  "Failed to load balances": "无法加载余额",
  "No balances": "没有余额",
  "Verified Operator": "已验证操作员",
  "Strategy": "策略",
  "Multi-Strategy": "多策略",
  "Master Vault": "主保管库",
  "Master Vault Details": "主保管库详情",
  "Annualized": "年化",
  "Depositors": "存款人",
  "No trade history": "无交易历史",
  "Unwinding Progress": "清算进度",
  "What are Vaults?": "保管库是什么？",
  "APR calculated as the Annualised 30-day Return":
    "APR 以年化 30 天回报率计算",
  "APR": "APR",
  "Initializing": "初始化中",
  "Awaiting Deposit": "等待存款",
  "Vault Age": "保管库年龄",
  "30D Return": "30天回报",
  "{{field}} must be greater than the minimum initial deposit of {{minInitialDeposit}}":
    "{{field}} 必须大于最小初始存款 {{minInitialDeposit}}",
  "Minimum deposit": "最小存款",
  "Withdrawal amount exceeds available vault shares":
    "提现金额超过可用保管库份额",
  "Deposit would exceed maximum vault TVL of {{maxTVL}}":
    "存款将超过保管库的最大TVL {{maxTVL}}",
  "{{field}} is required": "{{field}} 是必填项",
  "Lockup Remaining": "剩余锁定期",
  "{{days}}D": "{{days}}天",
  "<1>{{days}}</1> D": "<1>{{days}}</1>天",
  "<1>{{hours}}</1> H": "<1>{{hours}}</1>时",
  "<1>{{minutes}}</1> M": "<1>{{minutes}}</1>分",
  "Vault settings submitted": "保管库设置已提交",
  "Vault settings updated": "保管库设置已更新",
  "Vault Settings": "保管库设置",
  "Vault not found": "保管库未找到",
  "The owner receives a {{profitShare}} profit share.":
    "所有者将获得 {{profitShare}} 的利润分成。",
  "This vault is entirely owned by the community.":
    "这个保管库完全由社区拥有。",
  "Withdraw from Vault submitted": "从保管库提取已提交",
  "Deposit to Vault submitted": "存入保管库已提交",
  "Unknown error. Try again.": "未知错误。请重试。",
  "Vault Deposit": "保管库存款",
  "Vault Withdrawal": "保管库提款",
  "No Lockup": "无锁定期",
  "Lockup period is not over yet": "锁定期尚未结束",
  "Protocol": "协议",
  "24H": "24小时",
  "7D": "7天",
  "30D": "30天",
  "All": "全部",
  "You have no investments in vaults": "您在保管库中没有投资",
  "Total Value": "总价值",
  "Investments Made": "已进行的投资",
  "Operator": "操作员",
  "Manage Vault": "管理保管库",
  "Switch to the operator account": "切换到操作员账户",
  "Active": "活跃",
  "My Vaults": "我的保管库",
  "Closed": "已关闭",
  "Deposited": "已存入",
  "Lifetime ROI": "ROI 总和",
  "Owners Equity": "所有者权益",
  "Top Trending": "热门趋势",
  "Owner Share": "所有者份额",
  "Vault Details": "保管库详情",
  "Contract": "合约",
  "Withdraw from Vault": "从保管库提取",
  "Withdraw from Vault successful": "从保管库提取成功",
  "Failed to withdraw from Vault": "从保管库提取失败",
  "Name": "名称",
  "Deposit": "存款",
  "Tokens": "代币",
  "Avg. Entry Price": "平均入场价格",
  "Current Price": "当前价格",
  "P&L": "盈亏",
  "My Vault Deposit": "我的保管库存款",
  "Vaults": "保管库",
  "Create": "创建",
  "Create My Vault": "创建我的保管库",
  "Vault Name": "保管库名称",
  "Vault Description": "保管库描述",
  "Token Symbol": "代币符号",
  "Deposit Amount": "存款金额",
  "Vault description will be visible publicly and you will not be able to edit this later.":
    "保管库描述将公开可见，之后您将无法编辑。",
  "Once created, deposit at least {{minDeposit}} USDC to activate the vault. At this moment, only the Max TVL can be changed later.":
    "一旦创建，存入至少{{minDeposit}} USDC以激活保管库。此时，只有最大TVL可以稍后更改。",
  "Create Vault": "创建保管库",
  "Profit Share": "利润分享",
  "Lockup Period (Days)": "锁定期（天）",
  "Vault was successfully created and is being initialized":
    "保管库创建成功，正在初始化",
  "Failed to create a vault": "创建保管库失败",
  "Maximum TVL": "最大 TVL",
  "No limit": "无限制",
  "Vault Address": "保管库地址",
  "Deposit to Vault successful": "存入保管库成功",
  "Failed to deposit to Vault": "存入保管库失败",
  "Deposit to Vault": "存入保管库",
  "Vault": "保管库",
  "Owner": "所有者",
  "Age": "年龄",
  "Failed to load vaults": "加载保管库失败",
  "No vaults available": "没有可用的保管库",
  "Performance": "表现",
  "Vault Value": "保管库价值",
  "My Deposit": "我的存款",
  "Total P&L": "总盈亏",
  "Protocol Vaults": "协议保管库",
  "User Vaults": "用户保管库",
  "No Data": "无数据",
  "Last Month Return": "上月回报",
  "TVL": "TVL",
  "Total Earnings": "总收益",
  "About": "关于",
  "Vault Stats": "保管库统计",
  "My Stats": "我的统计",
  "Vault Contract": "保管库合约",
  "Description": "描述",
  "Max Drawdown": "最大回撤",
  "Volume": "交易量",
  "All-Time P&L": "历史总盈亏",
  "Days": "天数",
  "Lockup Period": "锁定期",
  "Search Vaults": "搜索保管库",
  "Blog": "博客",
  "Block Explorer": "区块浏览器",
  "Close Vault": "关闭保管库",
  "Close Vault successful": "关闭保管库成功",
  "You must close all open positions to close your Vault. Closed vaults cannot be reopened.":
    "您必须关闭所有未平仓头寸才能关闭您的保管库。已关闭的保管库无法重新打开。",
  "Close My Vault": "关闭我的保管库",
  "Failed to Close the Vault": "关闭保管库失败",
  "Only the Main Account can manage the vault": "只有主账户可以管理保管库",
  "Allocate Collateral": "分配抵押品",
  "Switch to Main Account": "切换到主账户",
  "Switch to Account": "切换到账户",
  "You are currently trading in the {{market}} market.":
    "您目前在{{market}}市场进行交易。",
  "To start trading on Isolated Market you must Allocate Collateral from your Main Account and switch to this Market Account.":
    "要开始在隔离市场交易，您必须从主账户分配抵押品并切换到此市场账户。",
  "To trade in this market, switch to the Main Account first.":
    "要在此市场进行交易，请先切换到主账户。",
  "This account can only trade in the {{market}} market.":
    "此账户只能在{{market}}市场进行交易。",
  "Isolated Market": "隔离市场",
  "This feature currently is still in Beta. Backup your Paradex Private Key for additional safety.":
    "此功能目前仍处于测试阶段。为额外的安全性，请备份您的Paradex私钥。",
  "Deposit failed": "存款失败",
  "Deposit in progress": "存款进行中",
  "External Account": "外部账户",
  "Tx Hashes": "交易哈希",
  "Continue to {{bridge}}": "继续前往{{bridge}}",
  "By clicking Continue to {{bridge}}, you will be sent to {{bridge}} website to bridge your funds out of Paradex.":
    "点击继续前往{{bridge}}，您将被引导到{{bridge}}网站以将您的资金从Paradex转移出去。",
  "By clicking Continue to {{bridge}}, you will be sent to {{bridge}} website to bridge your funds to Paradex.":
    "点击继续前往{{bridge}}，您将被引导到{{bridge}}网站以将您的资金转移到Paradex。",
  "Refer": "推荐",
  "Address": "地址",
  "Date Joined": "加入日期",
  "Referral Code": "推荐码",
  "Volume Traded": "交易量",
  "Bridge": "桥",
  "Withdraw to": "提取至",
  "Deposit from": "存入自",
  "Position is already closed": "位置已经关闭",
  "Your Paradex L2 Address is NOT a Public Starknet Address. If you try to send funds to this address on Public Starknet via a cross chain bridge you risk losing your funds. <1>Read more</1>.":
    "您的Paradex L2地址不是公共Starknet地址。如果您尝试通过跨链桥将资金发送到公共Starknet上的此地址，您将面临失去资金的风险。<1>阅读更多</1>。",
  "{{chain}} Address": "{{chain}}地址",
  "Copy Paradex Private Key": "复制Paradex私钥",
  "Your account will be liquidated if Margin Ratio reaches 100%":
    "如果保证金比率达到100%，您的账户将被清算",
  "Liquidation": "清算",
  "Fee": "费用",
  "Maker": "制造商",
  "Maker Score": "制造商得分",
  "Maker XP Share": "制造商XP占比",
  "Fee XP Share": "手续费XP占比",
  "Fee XP": "手续费XP",
  "Maker XP": "制造商XP",
  "Liquidation {{side}} Fill": "{{side}} 强平",
  "Settings": "设置",
  "Market Score": "市场得分",
  "Season {{season}} (coming soon)": "赛季 {{season}} (即将推出)",
  "Confirm Buy": "确认购买",
  "Confirm Sell": "确认出售",
  "Other XP": "其他XP",
  "Estimated Receivable Amount": "预计可收到的金额",
  "Socialized Loss active. Click to learn more.":
    "社会化损失已激活。点击了解更多。",
  "Analytics": "分析",
  "Sign In to see your account XP stats": "登录以查看您的账户XP统计",
  "<1>Clock Is Out Of Sync</1> The clock on your device is out of sync. This may cause issues with the application. Please make sure your device clock is set to be auto. For more details please <2>see documentation</2>":
    "<1>设备时钟不同步</1> 您的设备时钟不同步。这可能会导致应用程序出现问题。请确保您的设备时钟设置为自动。更多详情请<2>查看文档</2>",
  "Failed to fetch max withdrawable amount": "无法获取最大可提取金额",
  "It is not possible to withdraw more than your Max Withdrawable Amount.":
    "不可提取超过您的最大可提取金额。",
  "Max Withdrawable Amount": "最大可提取金额",
  "XP Explainer": "XP说明",
  "Pool": "等级",
  "Quote Quality": "报价质量",
  "Create Your Public Username": "创建您的公共用户名",
  "Edit Your Public Username": "编辑您的公共用户名",
  "You have been logged out.": "您已登出。",
  "Maker Volume Score": "制造商成交量得分",
  "Fee Score": "手续费评分",
  "Score Share": "积分占比",
  "Instrument Share": "合约占比",
  "XP": "XP",
  "Total XP": "总XP",
  "24h Change": "24小时变化",
  "Leaderboard": "排行榜",
  "Cancel": "取消",
  "By connecting a wallet, you agree to <1>Paradex Terms of Service</1> and represent and warrant to Paradex that you are not a <2>Restricted Person</2>.":
    "通过连接钱包，您同意<1>Paradex服务条款</1>，并向Paradex保证您不是<2>受限制的人员</2>。",
  "Positions": "位置",
  "Open Orders": "未结订单",
  "Trade History": "交易历史",
  "Order History": "订单历史",
  "Funding Payments": "资金费率支付",
  "Portfolio": "投资组合",
  "Trade": "交易",
  "Build": "构建",
  "Referrals Explainer": "推荐说明",
  "How It Works": "它是如何工作的",
  "My Referrals": "我的推荐",
  "Your Referral Code": "您的推荐代码",
  "Affiliate Program": "联盟计划",
  "Enrolled": "已注册",
  "Traders Referred": "已推荐的交易者",
  "Referral Rewards": "推荐奖励",
  "Referral XP": "推荐XP",
  "Invite Friends": "邀请朋友",
  "Share": "分享",
  "Earn": "赚",
  "Give": "给予",
  "Share your code to invite friends to join Paradex":
    "分享您的代码以邀请朋友加入Paradex",
  "<1>{{amount}}</1> Fees": "<1>{{amount}}</1> 手续费",
  "You receive {{amount}} of the referred user's fees":
    "您将获得被推荐用户费用的{{amount}}",
  "<1>{{amount}}</1> XP": "<1>{{amount}}</1> XP",
  "You receive {{amount}} of the referred user's XP":
    "您将获得被推荐用户的{{amount}}XP",
  "<1>{{amount}}</1> Discount": "<1>{{amount}}</1> 折扣",
  "Your friend receives {{amount}} discount on fees":
    "您的朋友在费用上享受<折{{amount}}折扣",
  "Create and share your custom referral code": "创建并分享您的自定义推荐代码",
  "If you have a strong social media following (<1>{{followers}}+</1>), you may be eligible for our <2>VIP Affiliate Program</2> with higher commissions and XP.":
    "如果您拥有强大的社交媒体关注者（<1>{{followers}}+</1>），您可能有资格参加我们的VIP联盟计划。",
  "You can track our <1>Affiliate Leaderboard</1> to see how you stack up.":
    "您可以查看我们的<1>联盟排行榜</1>来了解您的排名情况。",
  "Apply": "申请",
  "Log in to see your Referral History.": "登录以查看您的推荐历史。",
  "Welcome to Paradex": "欢迎来到 Paradex",
  "Congrats! The referral code you used is now active! It means you can start enjoying a discount on your trading fees!":
    "恭喜！您使用的推荐码现在已激活！这意味着您可以开始享受交易费用折扣！",
  "Congrats! The referral code <1>{{codeProvided}}</1> used is now active!":
    "恭喜！使用的推荐码<1>{{codeProvided}}</1>现在已激活！",
  "Referral code not activated. The code can only be applied once during the initial onboarding.":
    "推荐码未激活。该代码只能在初始登记期间应用一次。",
  "Referral code <1>{{codeProvided}}</1> not activated. You have already onboarded with <1>{{referredBy}}</1>.":
    "推荐码<1>{{codeProvided}}</1>未激活。您已经与<1>{{referredBy}}</1>登记。",
  "Referral Code Applied": "推荐码已应用",
  "You will receive <1>{{userAmount}}</1> of referred user's <1>fees</1> <2>and</2> <1>{{userAmountPoints}} XP</1>. They will receive a <1>{{friendAmount}}</1> discount.":
    "您将获得被推荐用户的<1>费用</1> <2>和</2> <1>{{userAmountPoints}}XP</1>。他们将获得<1>{{friendAmount}}</1>折扣。",
  "Community": "社区",
  "Environments": "环境",
  "Language": "语言",
  "System Status": "系统状态",
  "Docs": "文档",
  "Code Samples": "代码示例",
  "More": "更多",
  "Stats": "统计",
  "Join Discord": "加入Discord",
  "Notifications": "通知",
  "All caught up!": "全部更新！",
  "You have no new notifications": "您没有新的通知",
  "Connect Wallet": "连接钱包",
  "WalletConnect is taking too long to open. Please refresh the page and try again.":
    "WalletConnect 打开时间太长。请刷新页面重试。",
  "Unexpected error opening WalletConnect modal. Please refresh the page and try again.":
    "打开 WalletConnect 模态窗口时发生意外错误。请刷新页面重试。",
  "Connect your Wallet": "连接您的钱包",
  "Link Wallet": "链接钱包",
  "You will receive a signature request. Signing is free and will not send a transaction.":
    "您将收到一个签名请求。签名是免费的，不会发送交易。",
  "Generate Paradex Chain wallet": "生成Paradex链钱包",
  "Generating Paradex Chain wallet": "正在生成Paradex链钱包",
  "Paradex Chain wallet generated": "Paradex链钱包已生成",
  "Verify that you own this wallet": "验证您拥有此钱包",
  "Remember Me": "记住我",
  "Only use 'Remember Me' if you are using a secure device you own. Selecting this option can expose your information to  others if you are accessing this wallet from a public device.":
    "只有在使用您自己的安全设备时才使用'记住我'。如果您从公共设备访问此钱包，选择此选项可能会将您的信息暴露给其他人。",
  "Continue": "继续",
  "Disconnect Wallet": "断开钱包",
  "Disconnect": "断开",
  "Account": "账户",
  "Username": "用户名",
  "Edit": "编辑",
  "Your username is now hidden. It is not visible to other users.":
    "您的用户名现在已隐藏。其他用户看不到。",
  "Your username will be visible to other users unless you choose to hide it. You can always change it later.":
    "除非您选择隐藏，否则您的用户名将对其他用户可见。您随时可以更改。",
  "Hide my Username": "隐藏我的用户名",
  "Save": "保存",
  "Username is required": "需要用户名",
  "Username must be between 5 and 20 characters": "用户名必须在5到20个字符之间",
  "Username can only contain letters and numbers": "用户名只能包含字母和数字",
  "Wallets": "钱包",
  "Paradex Block Explorer": "Paradex区块浏览器",
  "Log out": "登出",
  "No markets available": "没有可用的市场",
  "Last Price": "最后价格",
  "Open Interest": "未平仓合约",
  "24h Volume": "24小时交易量",
  "Mark Price": "标记价格",
  "Spot Price": "现货价格",
  "Eight hour funding rate that is continuously accrued on positions and settled upon any trade. If the funding rate is positive, longs pay shorts. If negative, shorts pay longs.":
    "持仓期间持续累积的8小时资金费率，在任何交易发生时结算。如果资金费率为正，多头方支付给空头方；如果为负，空头方支付给多头方。",
  "8h Funding": "8h资金费率",
  "Limit": "限价",
  "Market": "市场",
  "Stop Limit": "止损限价",
  "Stop Market": "止损市价",
  "Scaled Order": "分级订单",
  "Trigger Price": "触发价格",
  "Input Price": "输入价格",
  "Position Mark Price": "仓位标记价格",
  "Amount": "数量",
  "Reduce Only": "仅减少",
  "Reduce Only will increase position": "仅减少将增加仓位",
  "Your order stays open until it is filled or you decide to cancel.":
    "您的订单将保持开放，直到被执行或您决定取消。",
  "Your order gets filled right away, partially or fully, and whatever is left gets canceled instantly.":
    "您的订单将立即被部分或全部执行，剩余的部分将立即被取消。",
  "Conditional limit order that serves to add liquidity to the order book as a Maker order.":
    "条件限价单，用于作为Maker订单向订单簿添加流动性。",
  "Currently the fees are fixed and uniform across the platform. Maker {{makerFee}} / Taker {{takerFee}}":
    "目前，平台上的费用是固定和统一的。Maker {{makerFee}} / Taker {{takerFee}}",
  "Value": "价值",
  "Buy": "买入",
  "BUY": "买入",
  "LONG": "做多",
  "Sell": "卖出",
  "SELL": "卖出",
  "SHORT": "做空",
  "Order Value": "订单价值",
  "Current Position": "当前持仓",
  "Account Value": "账户价值",
  "The total value of your account at current mark prices":
    "您的账户在当前标记价格下的总价值",
  "Unrealized P&L": "未实现的盈亏",
  "Free Collateral": "可用抵押品",
  "Account Leverage": "账户杠杆",
  "Margin": "保证金",
  "Margin Ratio": "保证金比率",
  "Initial Margin": "初始保证金",
  "Maintenance Margin": "维持保证金",
  "Order Book": "订单簿",
  "Trades": "交易",
  "Price": "价格",
  "Size": "大小",
  "Total": "总计",
  "Time": "时间",
  "Spread": "差价",
  "Side": "方向",
  "Entry Price": "入场价格",
  "Liquidation Price": "强平价格",
  "Unrealized Funding": "未实现资金费率",
  "Close Position": "平仓",
  "Market Close": "市场关闭",
  "Close Long": "平多仓",
  "Close Short": "平空仓",
  "Type": "类型",
  "Filled": "已成交",
  "Triggered": "触发",
  "Instruction": "指令",
  "Client Order Id": "客户订单ID",
  "Status": "状态",
  "NEW": "新的",
  "OPEN": "开放的",
  "UNTRIGGERED": "未触发",
  "CLOSED": "已关闭",
  "Pending": "待定",
  "Complete": "完成",
  "Completed": "完成",
  "Failed": "失败",
  "Bust": "被拒绝",
  "Value / Fee": "价值 / 费用",
  "Liquidity": "流动性",
  "Tx Hash": "交易哈希",
  "Trade Id": "交易 ID",
  "Remaining": "剩余",
  "Cancel Reason": "取消原因",
  "User Canceled": "用户已取消",
  "Not Enough Margin": "保证金不足",
  "Empty Market": "空市场",
  "Post Only": "仅发布",
  "Post Only Would Cross": "仅发布将交叉",
  "Remaining IOC Cancel": "剩余的IOC取消",
  "Unexpected Failure": "意外失败",
  "Deleverage": "去杠杆",
  "In Liquidation": "在清算中",
  "Self Trade": "自我交易",
  "Asset Unavailable": "资产不可用",
  "Asset Expired": "资产已过期",
  "Order Type Invalid": "订单类型无效",
  "Price Not Available": "价格不可用",
  "Expired": "已过期",
  "Exceeds Max Slippage": "超出最大滑点",
  "Timeout": "超时",
  "Order Exceeds Position Limit": "订单超出位置限制",
  "Order Id": "订单ID",
  "Payment": "支付",
  "Cancel All": "全部取消",
  "{{orderSize}} will be closed at Market price":
    "{{orderSize}}将以市场价格关闭",
  "Wallet": "钱包",
  "Deposit_verb": "存款",
  "Deposit_noun": "存款",
  "Withdraw": "取款",
  "Withdrawal": "取款",
  "Transfers": "转账",
  "Transfer": "转账",
  "Collateral": "抵押品",
  "Asset": "资产",
  "Wallet Balance": "钱包余额",
  "Actions": "操作",
  "Action": "操作",
  "External Tx Hash": "外部交易哈希",
  "Withdraw to Wallet": "提款到钱包",
  "Deposit to Paradex": "存款到Paradex",
  "You have no balance in your Ethereum wallet. Deposit some {{asset}} and try again.":
    "您的以太坊钱包没有余额。存入一些{{asset}}然后再试。",
  "It is not possible to deposit more than your current balance.":
    "不能存入超过您当前余额的金额。",
  "Available": "可用",
  "Initiate a deposit from your Ethereum wallet to the Paradex bridge. These transactions":
    "从您的以太坊钱包发起一笔存款到Paradex桥。这些交易",
  "Initiate a deposit from your Ethereum wallet to the Paradex bridge. These transactions may take several minutes depending on network conditions. For more information check out our <1>docs</1>.":
    "从您的以太坊钱包发起一笔存款到Paradex桥。这些交易可能需要几分钟的时间，具体取决于网络状况。欲了解更多信息，请查看我们的<1>文档</1>。",
  "read more": "阅读更多",
  "Initiate Deposit to Bridge": "发起存款到桥",
  "Enable USDC on Paradex": "在Paradex上启用USDC",
  "In order to deposit you must allow Paradex access to at least {{amount}}.":
    "为了存款，您必须允许Paradex访问至少{{amount}}。",
  "Enable USDC": "启用USDC",
  "Withdraw initiated": "已发起提款",
  "Withdrawals are a two-step process. First initiate a withdrawal from Paradex":
    "提款是一个两步的过程。首先从Paradex发起提款",
  "Withdrawals are a two-step process. First initiate a withdrawal from Paradex to the Paradex Bridge. This first step can take 5-16 hours (10 on average). Second, initiate your withdrawal from the Paradex Bridge to your Ethereum wallet via the Deposits / Withdrawals table. For more information check out our <1>docs</1>.":
    "提款是一个两步的过程。首先从Paradex向Paradex桥发起提款。这第一步可能需要5-16小时 (平均 10)。其次，通过存款/提款表格从Paradex桥向您的以太坊钱包发起提款。有关更多信息，请查看我们的<1>文档</1>。",
  "Initiate Withdrawal to Bridge": "发起提款到桥",
  "You have no funds to withdraw. It is not possible to withdraw more than your free collateral.":
    "您没有可提款的资金。不可能提取超过您的可用抵押品。",
  "It is not possible to withdraw more than your free collateral.":
    "不可能提取超过您的可用抵押品。",
  "You have no collateral": "您没有抵押品",
  "You have no open positions": "您没有持仓",
  "You have no open orders": "您没有未完成的订单",
  "You have no trades": "您未有交易",
  "You have no orders": "您未有订单历史",
  "You have no funding payments": "您未有资金费率支付",
  "You have no transfers": "您未有转账历史",
  "Account in liquidation": "账户正在强平中",
  "Account is being liquidated. Any open positions will be reduced. Trading now is disabled.":
    "账户正在被清算。任何开放的头寸将被减少。现在禁止交易。",
  "Account liquidated": "账户已清算",
  "Your account was liquidated on {{createdAt}}. Any open positions were reduced.":
    "您的账户在{{createdAt}}被清算。任何开放的头寸已被减少。",
  "Deposit to Paradex successful": "向 Paradex 存款成功",
  "Deposit to Paradex failed": "向 Paradex 存款失败",
  "Unexpected error. Try again.": "意外错误。请再试一次。",
  "Deposit initiated": "已发起存款",
  "Deposit to bridge initiated": "已启动向桥存款",
  "Deposit to Paradex available": "可以向 Paradex 存款",
  "Go to Deposits": "前往存款",
  "Deposit to Paradex in progress": "正在向 Paradex 存款",
  "Transfer successful": "转账成功",
  "Limit {{side}} Order Fill": "限价 {{side}} 订单填充",
  "{{type}} {{side}} Order Submitted": "{{type}} {{side}} 订单已提交",
  "{{type}} {{side}} Order Canceled": "{{type}} {{side}} 订单已取消",
  "Reason": "原因",
  "Market {{side}} Order Fill": "市场 {{side}} 订单填充",
  "Trade Busted On-Chain": "链上交易破产",
  "Fill id": "填充 id",
  "Withdraw to wallet failed": "提款到钱包失败",
  "Withdrawal to bridge initiated": "已启动向桥提款",
  "Withdrawal error. Try again.": "提款错误。请再试一次。",
  "Withdrawal to bridge in progress": "正在向桥提款",
  "Withdrawal to wallet initiated": "已启动向钱包提款",
  "Withdrawal to bridge failed": "向桥提款失败",
  "Transaction error. Try again.": "交易错误。请再试一次。",
  "Withdrawal to wallet available": "可以提款到钱包",
  "Go to Withdrawals": "前往提款",
  "Withdrawal to wallet successful": "提款到钱包成功",
  "Session expired, please log in again.": "会话已过期，请再次登录。",
  "Failed to submit order": "提交订单失败",
  "Your username preferences were updated!": "您的用户名偏好已更新！",
  "Unexpected error updating username preferences":
    "更新用户名偏好时出现意外错误",
  "This username already exists. Please choose a different username and try again.":
    "此用户名已存在。请选择一个不同的用户名并再试一次。",
  "Validation Error": "验证错误",
  "Binding Error": "绑定错误",
  "Internal Error": "内部错误",
  "Not Found": "未找到",
  "Service Unavailable": "服务不可用",
  "Invalid Request Parameter": "请求参数无效",
  "Order Id Not Found": "未找到订单ID",
  "Order Is Closed": "订单已关闭",
  "Order Is Not Open Yet": "订单尚未开放",
  "Client Order Id Not Found": "未找到客户订单ID",
  "Duplicated Client Id": "客户ID重复",
  "Invalid Price Precision": "价格精度无效",
  "Invalid Token": "无效的令牌",
  "Invalid Ethereum Address": "以太坊地址无效",
  "Blocked Ethereum Address": "以太坊地址被阻止",
  "Invalid Ethereum Signature": "以太坊签名无效",
  "Invalid Starknet Address": "Starknet地址无效",
  "Invalid Starknet Signature": "Starknet签名无效",
  "Starknet Signature Verification Failed": "Starknet签名验证失败",
  "Bad Starknet Request": "错误的Starknet请求",
  "Ethereum Signer Mismatch": "以太坊签名者不匹配",
  "Ethereum Hash Mismatch": "以太坊哈希不匹配",
  "Not Onboarded": "未登记",
  "Invalid Timestamp": "无效的时间戳",
  "Invalid Signature Expiration": "无效的签名过期",
  "Account Not Found": "找不到账户",
  "Invalid Order Signature": "无效的订单签名",
  "Public Key Invalid": "公钥无效",
  "Unauthorized Ethereum Address": "未授权的以太坊地址",
  "Ethereum Address Already Onboarded": "以太坊地址已登记",
  "Market Not Found": "找不到市场",
  "Allowlist Entry Not Found": "找不到白名单条目",
  "Username In Use": "用户名已被使用",
  "Service access restricted in your region": "您所在地区的服务访问受限",
  "No Access Allowed": "不允许访问",
  "You do not have access to the platform. Please join our Discord to get access.":
    "您无权访问该平台。请加入我们的Discord以获取访问权限。",
  "{{actualChainName}} Wallet Connected": "以太坊钱包已连接",
  'Incorrect network selected "{{actualChainName}}". Please change the network to "{{requiredChainName}}" (id={{requiredChainId}}) in your wallet and retry.':
    '选择的网络"{{actualChainName}}"不正确。请在您的钱包中将网络更改为"{{requiredChainName}}"（id={{requiredChainId}}）并重试。',
  "Choose Wallet": "选择钱包",
  "Starknet Wallet": "Starknet钱包",
  "Switch to {{env}}": "切换到{{env}}",
  "Search": "搜索",
  "League": "联赛",
  "Switch Account": "切换账户",
  "Rank": "排名",
  "24h XP": "24小时XP",
  "XP Per Day": "每日XP",
  "Season Volume": "赛季交易量",
  "Lifetime Volume": "终身交易量",
  "Pre-Season": "季前赛",
  "Earn More XP": "赚取更多XP",
  "XP Boost": "XP加成",
  "XP Drop": "XP下降",
  "Trader": "交易员",
  "Total Share": "总份额",
  "Maker Share": "制造商份额",
  "Maker Volume Share": "制造商成交量份额",
  "Fee Share": "费用份额",
  "Fee Volume Share": "费用成交量份额",
  "No data available": "没有可用数据",
  "Refer & Earn": "推荐并赚取",
  "Hours": "小时",
  "Minutes": "分钟",
  "Time/Duration": "时间/持续时间",
  "Runtime": "运行时间",
  "Frequency": "频率",
  "Number of Orders": "订单数量",
  "Runtime should not be empty": "运行时间不能为空",
  "Runtime should be less than or equal to 24 hours":
    "运行时间应小于或等于24小时",
  "Inactive": "不活跃",
  "Avg. Price": "平均价格",
  "The minimum required amount for {{numOrders}} orders is {{totalSize}} {{currency}}.":
    "{{numOrders}}笔订单的最低要求金额为{{totalSize}} {{currency}}。",
  "Take Profit / Stop Loss: Set take profit and stop loss price levels to automate trade actions. When the Mark Price will reach the price you set, it will send your order to the matching engine.":
    "止盈/止损：设定止盈和止损价格水平以自动执行交易操作。当标记价格达到您设定的价格时，您的订单将发送到匹配引擎。",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount with an estimated profit of <3 />.":
    "当标记价格达到 <1 /> 时，将触发 <2 /> 订单以估计的利润 <3 /> 关闭所选金额。",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount with an estimated loss of <3 />.":
    "当标记价格达到 <1 /> 时，将触发 <2 /> 订单以估计的亏损 <3 /> 关闭所选金额。",
  "Edit TP/SL For This Position": "编辑此仓位的止盈/止损",
  "Take Profit": "止盈",
  "Profit": "盈利",
  "Stop Loss": "止损",
  "Loss": "亏损",
  "Confirm": "确认",
  "Take Profit Limit": "限价止盈",
  "Take Profit Market": "市价止盈",
  "Stop Loss Limit": "限价止损",
  "Stop Loss Market": "市价止损",
  "Funding": "资金",
  "Unwinding": "平仓",
  "Cancel TP/SL orders": "取消TP/SL订单",
  "TP/SL orders submitted successfully.": "TP/SL订单已成功提交。",
  "Pre-Season + All Seasons": "季前赛 + 所有赛季",
  "Season {{season}}": "赛季 {{season}}",
  "XP Season {{season}}": "赛季 {{season}} XP",
  "Slippage": "滑点",
  "Max Slippage": "最大滑点",
  "Max slippage defines the largest acceptable price deviation from the mark price.":
    "最大滑点定义了标记价格中可接受的最大价格偏差。",
  "During execution, the order can be canceled if the price exceeds the slippage limit or falls outside the Market <1>Price Band</1>.":
    "在执行过程中，如果价格超出滑点限制或超出市场<1>价格区间</1>，订单可能会被取消。",
  "Update Max Slippage": "更新最大滑点",
  "The Maximum Slippage allowed for {{symbol}} is {{price_band_width}}.":
    "{{symbol}}的最大允许滑点是{{price_band_width}}。",
  "Mark IV": "Mark IV",
  "Delta": "Delta",
  "Failed to load positions": "加载仓位失败",
  "No open positions": "没有开放仓位",
  "learn more": "了解更多",
  "Learn More": "了解更多",
  "Search Markets": "搜索市场",
  "Futures": "期货",
  "Options": "期权",
  "OI": "未平仓合约",
  "My Profile": "我的账户",
  "Addresses": "地址",
  "Chart": "图表",
  "ROI": "ROI",
  "Sharpe": "Sharpe",
  "Max Loss": "最大损失",
  "Total Volume": "总交易量",
  "My NFTs": "我的NFT",
  "My XP": "我的XP",
  "Balances": "余额",
  "Other": "其他",
  "Paradex XP: Pre-Season": "Paradex XP: 季前赛",
  "Paradex XP: Season {{season}}": "Paradex XP: 赛季 {{season}}",
  "Season {{season}} XP": "赛季 {{season}} XP",
  "Refer your friends to earn 10%": "推荐朋友并赚取10%",
  "Refer Friends and Earn 10%": "推荐朋友并赚取10%",
  "Invest in Paradex Vault & Earn 3X Boost": "投资Paradex Vault并赚取3X Boost",
  "Avg. Daily XP": "平均每日XP",
  "Lifetime XP": "终身XP",
  "My Rank": "我的排名",
  "What is XP?": "XP是什么？",
  "XP History": "XP历史",
  "Last Week XP": "上周XP",
  "TVL XP": "TVL XP",
  "OI XP": "OI XP",
  "Liquidation XP": "Liquidation XP",
  "Vaults XP": "Vaults XP",
  "Week": "周",
  "Season": "赛季",
  "Last updated: {{timestamp}}": "最后更新: {{timestamp}}",
  "Xperience Points (XP)": "经验点 (XP)",
  "Log in to see your XP balance": "登录以查看您的XP余额",
  "Log in to see where you stand": "登录以查看您所在的位置",
  "Connect your X account to join Pro League": "连接您的X账户以加入职业联赛",
  "Avg. Fill Price": "平均成交价",
  "Spot": "现货",
  "Derivatives": "衍生品",
  "P&L Chart": "P&L图表",
  "Account Value Chart": "账户价值图表",
  "Funding History": "资金历史",
  "Manage Funds": "管理资金",
  "Profile": "账户",
  "Community XP": "社区XP",
  "XP Share": "XP份额",
  "Next XP Drop in": "下一个XP下降",
  "In Progress": "进行中",
  "{{field}} must be greater than {{value}}": "{{field}}必须大于{{value}}",
  "{{field}} must be less than {{value}}": "{{field}}必须小于{{value}}",
  "Enter a valid Take Profit or Stop Loss price": "请输入有效的止盈或止损价格",
  "Take Profit and Stop Loss prices cannot be the same":
    "止盈价和止损价不能相同",
  "Breakeven Price": "损益平衡价格",
  "Automatic Withdrawal": "自动提现",
  "Estimated Gas Fee": "预计Gas费",
  "Auto Withdrawal": "自动提现",
  "Withdrawing...": "提现中...",
  "Paradex Bridge withdrawals can take between 5-16 hours to complete":
    "Paradex Bridge提现可能需要5-16小时完成",
  "Socialized Loss": "社会化损失",
  "Receivable Amount": "可收金额",
  "Auto withdrawing...": "自动提现中...",
  "Manage Twitter Connection": "管理Twitter连接",
  "Unlock additional functionality.": "解锁更多功能。",
  "Please note this connection can't be used as a login method to Paradex.":
    "请注意，此连接不能用作Paradex的登录方式。",
  "Toast Notifications": "Toast通知",
  "Display toast notifications": "显示Toast通知",
  "Average Entry Price": "平均入场价",
  "Display average entry price line on chart": "在图表上显示平均入场价线",
  "Display liquidation price line on chart": "在图表上显示清算价格线",
  "Display open order lines on chart": "在图表上显示开放订单线",
  "Failed to disconnect Twitter": "无法断开Twitter连接",
  "Failed to authorize Twitter": "无法授权Twitter",
  "Twitter account linked successfully!": "Twitter账户已成功连接！",
  "Failed to link twitter account": "无法连接Twitter账户",
  "Fees": "费用",
  "Taker pays {{takerFee}} in fees. Maker receives {{makerRebate}} in rebates.":
    "Taker支付{{takerFee}}的费用。Maker收到{{makerRebate}}的回扣。",
  "Manage Discord Connection": "管理Discord连接",
  "Failed to authorize Discord": "无法授权Discord",
  "Discord account linked successfully!": "Discord账户已成功连接！",
  "Failed to link Discord account": "无法连接Discord账户",
  "Failed to disconnect Discord": "无法断开Discord连接",
  "Connect": "连接",
  "Unrealized P&L from current vault token balance. This doesn't account for owner profit share or slippage from positions unwind.":
    "当前vault token余额的未实现P&L。这不包括所有者利润分享或从仓位解开引起的滑点。",
  "Show P&L Amount": "显示P&L金额",
  "Receivable amount must be positive": "可收金额必须为正",
  "Wallet Address Change Detected": "检测到钱包地址更改",
  "You can proceed with connecting your Paradex account to this wallet address or continue using your current connection.":
    "您可以继续将Paradex帐户连接到此钱包地址，或继续使用当前连接。",
  "Connect To New Wallet": "连接到新钱包",
  "Keep Using Current Wallet": "继续使用当前钱包",
  "Connect Current Wallet": "连接当前钱包",
  "The account is not connected. Please make sure that <1>{{walletAddress}}</1> is connected and is the active account in your <2>{{walletName}}</2> wallet.":
    "账户未连接。请确保<1>{{walletAddress}}</1>已连接并且是您的<2>{{walletName}}</2>钱包中的活跃账户。",
  "Reconnecting": "重新连接",
  "There is a request in progress. Please proceed in your wallet.":
    "请求正在进行中。请在您的钱包中继续。",
  "Modify Order": "修改订单",
  "Realized P&L": "已实现盈亏",
  "Close All": "全部关闭",
  "Close All Positions": "全部仓位",
  "Market Close All Positions": "市场关闭全部仓位",
  "Close {{percent}}% of All Positions": "全部仓位{{percent}}%关闭",
  "Failed to close all positions": "无法关闭全部仓位",
  "Number of Positions": "仓位数量",
  "Markets": "市场",
  "Position Value (Closing)": "仓位价值（关闭）",
  "{{count}} positions will be rounded to the nearest order increment":
    "{{count}}仓位将四舍五入到最近的订单增量",
  "You will earn Bonus XP on your referral activity":
    "您将在推荐活动中获得 XP 奖励",
  "Token Price": "代币价格",
  "Vault Token Price Chart": "Vault代币价格图表",
  "Market Close Orders Submitted": "市场关闭所有订单",
  "This profile is already linked to a different Paradex account. Disconnect socials from existing account and try again.":
    "此個人資料已與其他 Paradex 帳戶連結。請先從現有帳戶中解除社群帳號後再重試。",
  "XP Drops every Friday": "XP 每週五下降",
  "No XP Data Available": "沒有XP數據",
  "XP data is available only for the Main Account.": "XP數據僅適用於主帳戶。",
  "Please switch to your Main Account to view your XP.":
    "請切換到您的主要帳戶以查看您的XP。",
  "Referrals Not Available": "沒有Referrals",
  "Referrals are available only for the Main Account.":
    "Referrals僅適用於主帳戶。",
  "Please switch to your Main Account to view your Referrals.":
    "請切換到您的主要帳戶以查看您的Referrals。",
  "Deposit initiated via Layerswap": "存款通过Layerswap发起",
  "Position History": "持仓历史",
  "Avg. Close Price": "平均平仓价格",
  "Max Size": "最大规模",
  "Closed Size": "平仓规模",
  "Opened": "已开仓",
  "You have no positions": "您没有持仓",
  "Destination Address": "目标地址",
  "Withdrawal via Layerswap initiated": "通过Layerswap发起提现",
  "Withdrawal via Layerswap failed": "通过Layerswap发起提现失败",
  "Market Share": "市场份额",
  "Tier": "等级",
  "Avg Maker Volume": "平均Maker成交量",
  "Liquidity Score": "流动性评分",
  "Reward Share": "奖励份额",
  "Enter amount": "输入金额",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount at <3 /> with an estimated profit of <4 />.":
    "当标记价格达到<1 />时，将触发<2 />订单以<3 />关闭选定数量，预计利润为<4 />。",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount at <3 /> with an estimated loss of <4 />.":
    "当标记价格达到<1 />时，将触发<2 />订单以<3 />关闭选定数量，预计亏损为<4 />。",
  "Limit Price": "限价",
  "Delete": "删除",
  "Use other bridges from the list above to withdraw funds much faster.":
    "使用上方列表中的其他桥接方式可更快提现资金。",
  "Paradex Chain Monitor": "Paradex链监控",
  "OTM Amount": "虚值数量",
  "Leverage": "杠杆",
  "Strikes": "行权价",
  "Strike": "行权价",
  "Change": "变化",
  "Dist. to Spot": "距现货",
  "Bid": "买入",
  "Mark": "标记",
  "Ask": "卖出",
  "IV": "隐含波动率",
  "Dist %": "距离 %",
  "Call": "看涨",
  "Put": "看跌",
  "Mark Price / IV": "标记价格 / 隐含波动率",
  "8h F-Funding": "8小时资金费率",
  "No Strikes Available": "无可用行权价",
  "Attributes": "属性",
  "Why no Expiries?": "为什么没有到期日？",
  "{{multiplier}}x Boost": "{{multiplier}}倍提升",
  "Like perpetual futures, perpetual options have no expiration, offering continuous exposure to the underlying asset with built-in downside protection. This gives users the flexibility to hold their position indefinitely and decide the optimal time to close it based on their strategy":
    "与永续期货类似，永续期权没有到期日，提供对标的资产的持续敞口，并内置下行保护。这使用户可以灵活地无限期持有头寸，并根据其策略决定最佳平仓时机",
  "Implied Volatility used to calculate the option's Mark Price":
    "用于计算期权标记价格的隐含波动率",
  "Distance between the Strike Price and the underlying Spot Price":
    "行权价与标的现货价格之间的距离",
  "Eight hour funding that is continuously accrued on positions and settled upon any trade. If the funding rate is positive, longs pay shorts. If negative, shorts pay longs":
    "在头寸上持续累积并在任何交易时结算的八小时资金费率。如果资金费率为正，多头支付空头。如果为负，空头支付多头",
  "The Leverage Ratio (Lambda) measures an option's effective leverage by indicating how much the option's value changes, in percentage terms, for a 1% change in the spot price of the underlying asset. For example, if an option has a Lambda of 50 and the spot price increases by 1%, the option's value would increase by 50%":
    "杠杆比率（Lambda）通过指示标的资产现货价格变动1%时期权价值的百分比变化来衡量期权的有效杠杆。例如，如果期权的Lambda为50，现货价格上涨1%，期权价值将上涨50%",
  "Option delta measures how much the price of an option is expected to change for a $1 change in the price of the underlying asset. For example, a delta of 0.5 means the option price will move $0.50 for every $1 move in the underlying":
    "期权delta衡量标的资产价格变动1美元时期权价格的预期变动。例如，delta为0.5意味着标的资产每变动1美元，期权价格将变动0.50美元",
  "Eight hour funding rate of the perpetual future on the same underlying asset":
    "同一标的资产永续期货的八小时资金费率",
  "Having trouble? Try signing out and connecting again.":
    "遇到问题？尝试登出并重新连接。",
  "API Reference": "API参考",
  "XP Distribution occurs once per week on Friday at ~12 PM UTC":
    "XP分发每周五UTC时间12点左右进行一次",
  "What are Perpetual Options?": "什么是永续期权？",
  "Delta represents the increase in option price based on a $1 increase in the underlying spot price.\n\nFor example, a delta of 0.50 means the price of the option is expected to increase by $0.50 for a $1 increase in the underlying spot price.":
    "Delta表示标的现货价格上涨1美元时期权价格的增加。\n\n例如，delta为0.50意味着当标的现货价格上涨1美元时，期权价格预计将上涨0.50美元。",
  "Leverage(Lambda) represents the percentage change in option price for a 1% change in the underlying spot price.\n\nFor example, a lambda of 10 means the option price is expected to increase by 10% if the underlying spot price increases by 1%.":
    "杠杆（Lambda）表示标的现货价格变动1%时期权价格的百分比变化。\n\n例如，lambda为10意味着如果标的现货价格上涨1%，期权价格预计将上涨10%。",
  "Eight hour options funding rate that is continuously accrued on positions and settled upon any trade. The funding rate for perpetual options reflects both the cost of leverage and the cost of insurance against adverse price moves. \n\nThe funding period of perpetual options is 24 hours. This means that the option premium will be continuously paid out over a 24h period. 24h funding rate = 3 x 8h funding rate":
    "8小时期权资金费率在持仓期间持续累积，并在任何交易时结算。永续期权的资金费率反映了杠杆成本和对不利价格变动的保险成本。\n\n永续期权的资金周期为24小时。这意味着期权费将在24小时内持续支付。24小时资金费率 = 3 x 8小时资金费率",
  "Risk": "风险",
  "Vega": "Vega",
  "Gamma": "Gamma",
  "Estimated Funding P&L for the next 8 hours based on current funding rates":
    "基于当前资金费率估算的未来8小时资金盈亏",
  "Vega represents the increase in option price based on a 1% increase in the implied volatility.\n\nFor example, a Vega of 5 means the price of the option is expected to increase by $5 for a 1% increase in the implied volatility.":
    "Vega表示基于隐含波动率上涨1%时期权价格的增加。\n\n例如，Vega为5意味着当隐含波动率上涨1%时，期权价格预计将上涨5美元。",
  "Adjust Leverage": "调整杠杆",
  "Leverage updated successfully": "杠杆更新成功",
  "Failed to update leverage": "更新杠杆失败",
  "Adjust leverage parameters for selected market. Leverage will also increase on your open positions.":
    "调整所选市场的杠杆参数。您的未平仓头寸的杠杆也将增加。",
  "Please note that changing leverage will also apply for open positions and open orders on this market.":
    "请注意，更改杠杆也将适用于此市场的未平仓头寸和未完成订单。",
  "Selecting higher leverage could result in Deleveraging where some of your open orders would be cancelled.":
    "选择更高的杠杆可能会导致去杠杆化，其中一些未完成的订单将被取消。",
  "disabled": "已禁用",
  "Margin Mode": "保证金模式",
  "Manage your risk on individual positions by restricting the amount of margin allocated to each. If the margin ratio of an isolated position reaches 100%, the position will be liquidated. Margin can be added or removed to individual positions in this mode.":
    "通过限制分配给每个头寸的保证金金额来管理个别头寸的风险。如果隔离头寸的保证金比率达到100%，该头寸将被清算。在此模式下，可以添加或移除个别头寸的保证金。",
  "Margin mode updated successfully": "保证金模式更新成功",
  "Failed to update margin mode": "更新保证金模式失败",
  "Favorites": "收藏",
  "Trending": "热门",
  "Losers": "下跌",
  "Gainers": "上涨",
  "New Listing": "新上市",
  "Developers": "开发者",
  "Developer Mode": "开发者模式",
  "Enable developer mode": "启用开发者模式",
  "For external use": "用于外部使用",
  "Warning: Changing transfer behavior": "警告: 更改传输行为",
  "USDC will be transferred to account contract instead of Paradex. Amount won't be visible or available for use within Paradex, but can be used with external applications.":
    "USDC将被转移到账户合约而不是Paradex。金额不会在Paradex中可见或可用，但可以与外部应用程序一起使用。",
  "Affiliate Dashboard": "合作伙伴仪表盘",
};

export default translations;
