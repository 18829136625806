import BigNumber from 'bignumber.js';

import { FeatureFlags } from '#/features/feature-flags';
import * as ParadexEmbedded from '#/features/integrations/paradex-embedded';
import {
  BaseAccount,
  getMultichainAddress,
} from '#/features/wallet/common/base-account';
import { StarknetSignature } from '#/features/wallets/paraclear/wallet';

import { msToDate } from '#/utils/date';
import { DecimalOrEmptyString, Nullable } from '#/utils/types';

import { AsyncResp, BaseReq, requestApi } from './fetch-api';
import { UnixTime, UnixTimeMs } from './types';

import type { Hex } from 'viem';

export type AccountStatus =
  | 'ACTIVE'
  | 'DELEVERAGE'
  | 'UNHEALTHY'
  | 'LIQUIDATION';

export interface RawAccount {
  /** @example '0xbBbBBBBbbBBBbbbBbbBbbbbBBbBbbbbBbBbbBBbB' */
  readonly account: string;
  readonly status: AccountStatus;
  /** @example '0' */
  readonly account_value: string;
  /** @example '0' */
  readonly maintenance_margin_requirement: string;
  /** @example '0' */
  readonly margin_cushion: string;
  /** @example '0' */
  readonly free_collateral: string;
  /** @example '0' */
  readonly total_collateral: string;
  /** @example '0' */
  readonly portfolio_change_percentage: string;
  /** @example '0' */
  readonly portfolio_change: string;
  /** @example '0' */
  readonly initial_margin_requirement: string;
  readonly updated_at: UnixTimeMs;
  /** @example 'USDC' */
  readonly settlement_asset: string;
}

export interface Account
  extends Omit<
    RawAccount,
    | 'updated_at'
    | 'total_collateral'
    | 'free_collateral'
    | 'account_value'
    | 'portfolio_change'
    | 'portfolio_change_percentage'
    | 'initial_margin_requirement'
    | 'maintenance_margin_requirement'
  > {
  readonly updated_at: Date;
  readonly total_collateral: BigNumber;
  readonly free_collateral: BigNumber;
  readonly account_value: BigNumber;
  readonly portfolio_change: BigNumber;
  readonly portfolio_change_percentage: BigNumber;
  readonly initial_margin_requirement: BigNumber;
  readonly maintenance_margin_requirement: BigNumber;
}

interface GetAccountReq extends BaseReq {}

export async function getAccount(req: GetAccountReq): AsyncResp<Account> {
  const { signal } = req;

  const resp = await requestApi<RawAccount>({
    signal,
    method: 'GET',
    url: `/account`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: processAccount(resp.data),
  };
}

export interface PostOnboardingReq extends BaseReq {
  readonly paradexPublicKey: string;
  readonly derivationPath: string | null;
  readonly paradexAddress: string;
  readonly baseAccount: BaseAccount;
  readonly signature: StarknetSignature;
  readonly parentUserToken: ParadexEmbedded.Token;
  readonly referralCode?: string;
  /**
   * If this account is associated with an isolated
   * market, the market symbol must be passed.
   * @example 'COLDSTORAGE-USD-PERP'
   */
  readonly isolatedMarket?: string;
}

function getAddressHeader(baseAccount: BaseAccount): Record<string, string> {
  // WIP: Update Ethereum account header to the generic parent account
  // header after API is stable.
  switch (baseAccount.type) {
    case 'ethereum':
      return { 'PARADEX-ETHEREUM-ACCOUNT': `${baseAccount.address}` };
    case 'starknet':
    case 'paradex':
      return { 'PARADEX-PARENT-ACCOUNT': getMultichainAddress(baseAccount) };
    // no default
  }
}

export async function postOnboarding(req: PostOnboardingReq): AsyncResp<null> {
  const {
    signal,
    paradexAddress,
    baseAccount,
    paradexPublicKey,
    derivationPath,
    signature,
    parentUserToken,
    referralCode,
    isolatedMarket,
  } = req;

  const shouldSubmitDerivationPath = FeatureFlags.getBooleanValue(
    'submit-derivation-path-during-onboarding',
    false,
  );

  const resp = await requestApi<null>({
    signal,
    method: 'POST',
    url: `/onboarding`,
    headers: {
      ...getAddressHeader(baseAccount),
      'PARADEX-STARKNET-ACCOUNT': paradexAddress,
      ...(shouldSubmitDerivationPath && derivationPath != null
        ? { 'PARADEX-STARKNET-ACCOUNT-DERIVATION-PATH': derivationPath }
        : {}),
      'PARADEX-STARKNET-SIGNATURE': JSON.stringify([
        signature.r.toString(),
        signature.s.toString(),
      ]),
      ...(isolatedMarket != null && {
        'PARADEX-ISOLATED-MARKET-ACCOUNT': isolatedMarket,
      }),
      ...(parentUserToken != null && {
        Authorization: `Bearer ${parentUserToken}`,
      }),
    },
    body: {
      public_key: paradexPublicKey,
      ...(referralCode != null ? { referral_code: referralCode } : {}),
    },
  });

  return resp;
}

interface PostAuthReq extends BaseReq {
  readonly paradexAddress: string;
  readonly signature: StarknetSignature;
  /** Timestamp in seconds of when the request was signed */
  readonly createdAt: UnixTime;
  /** Timestamp in seconds used to change the default signature expiration time */
  readonly expiresAt: UnixTime;
}

export interface PostAuthResp {
  readonly jwt_token: string;
}
export async function postAuth(req: PostAuthReq): AsyncResp<PostAuthResp> {
  const { signal, paradexAddress, signature, createdAt, expiresAt } = req;

  const resp = await requestApi<PostAuthResp>({
    signal,
    method: 'POST',
    url: `/auth`,
    headers: {
      'PARADEX-STARKNET-ACCOUNT': paradexAddress,
      'PARADEX-STARKNET-SIGNATURE': JSON.stringify([
        signature.r.toString(),
        signature.s.toString(),
      ]),
      'PARADEX-TIMESTAMP': createdAt.toFixed(),
      'PARADEX-SIGNATURE-EXPIRATION': expiresAt.toFixed(),
      'PARADEX-AUTHORIZE-ISOLATED-MARKETS': 'true',
    },
  });

  return resp;
}

export interface RawReferral {
  readonly name: string;
  readonly referral_type: 'Referral' | 'Affiliate';
  readonly minimum_volume: string;
  readonly discount_rate: string;
  readonly commission_rate: string;
  readonly discount_volume_cap: string;
  readonly commission_volume_cap: string;
  readonly points_bonus_rate: string;
  readonly points_bonus_volume_cap: string;
}

export interface RawAccountProfile {
  /**
   * Custom username created by the user.
   * Empty string when user hasn't picked one yet.
   */
  readonly username: string;
  /** Whether the user wants to keep its username private */
  readonly is_username_private: boolean;
  /** User's own referral code */
  readonly referral_code: string;
  /** Referral code used during initial account onboarding */
  readonly referred_by?: string;
  readonly twitter?: TwitterProfile;
  readonly discord?: DiscordProfile;
  readonly nfts?: Nft[];
  readonly max_slippage?: DecimalOrEmptyString;
  readonly referral?: RawReferral;
}

interface TwitterProfile {
  readonly id: string;
  readonly username: string;
  readonly image_url: string;
}

interface DiscordProfile {
  readonly id: string;
  readonly username: string;
  readonly image_url: string | null;
}

export interface Nft {
  readonly id: string;
  readonly name: string;
  readonly image_url: string;
  readonly collection_address: string;
  readonly collection_name: string;
}

export interface Referral {
  readonly name: string;
  readonly referral_type: 'Referral' | 'Affiliate';
  readonly minimum_volume: string;
  readonly discount_rate: BigNumber;
  readonly commission_rate: BigNumber;
  readonly discount_volume_cap: BigNumber;
  readonly commission_volume_cap: BigNumber;
  readonly points_bonus_rate: BigNumber;
  readonly points_bonus_volume_cap: BigNumber;
}

export interface AccountProfile
  extends Omit<
    RawAccountProfile,
    'referred_by' | 'twitter' | 'discord' | 'max_slippage' | 'referral'
  > {
  readonly referred_by: string | null;
  readonly twitter: Nullable<TwitterProfile>;
  readonly discord: Nullable<DiscordProfile>;
  readonly max_slippage: BigNumber | null;
  readonly referral: Nullable<Referral>;
}

interface GetAccountProfileReq extends BaseReq {}

export async function getAccountProfile(
  req: GetAccountProfileReq,
): AsyncResp<AccountProfile> {
  const { signal } = req;

  const resp = await requestApi<RawAccountProfile>({
    signal,
    method: 'GET',
    url: `/account/profile`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: processAccountProfile(resp.data),
  };
}

export interface UpdateAccountProfileUsernameReq extends BaseReq {
  readonly username: string;
  readonly is_username_private: boolean;
}

export interface UpdateAccountProfileReferralCodeReq extends BaseReq {
  readonly referral_code: string;
}

export async function updateAccountProfileUsername(
  req: UpdateAccountProfileUsernameReq,
): AsyncResp<AccountProfile> {
  const { signal, ...body } = req;

  const resp = await requestApi<RawAccountProfile>({
    signal,
    method: 'POST',
    url: `/account/profile/username`,
    body,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: processAccountProfile(resp.data),
  };
}

export async function updateAccountProfileReferralCode(
  req: UpdateAccountProfileReferralCodeReq,
): AsyncResp<AccountProfile> {
  const { signal, ...body } = req;

  const resp = await requestApi<RawAccountProfile>({
    signal,
    method: 'POST',
    url: `/account/profile/referral_code`,
    body,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: processAccountProfile(resp.data),
  };
}

export async function updateProfileMaxSlippage(
  req: UpdateMaxSlippageReq,
): AsyncResp<AccountProfile> {
  const { signal, ...body } = req;

  const resp = await requestApi<RawAccountProfile>({
    signal,
    method: 'POST',
    url: `/account/profile/max_slippage`,
    body,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: processAccountProfile(resp.data),
  };
}

export interface UpdateMaxSlippageReq extends BaseReq {
  readonly max_slippage: string;
}

export type SubAccountKind =
  | 'subaccount'
  | 'isolated'
  | 'vault_operator'
  | 'vault_sub_operator';

export interface RawSubAccount {
  /** Paradex address for this sub-account */
  readonly account: Hex;
  readonly created_at: UnixTimeMs;
  readonly kind: SubAccountKind;
  readonly public_key: Hex;
  readonly derivation_path: string;
  /**
   * Account type and Paradex address for the parent account
   * @example 'prdx:0x4692dbe6b0c64deabf2cffc80e4e15d9aa4a0bdd68093bbb80fc0f6430e76a0'
   */
  readonly parent_account: string;
  /**
   * Isolated market for the account.
   * Empty if not an isolated market account.
   * @example 'COLDSTORAGE-USD-PERP'
   */
  readonly isolated_market: string;
  readonly username: string;
}

export interface SubAccount
  extends Omit<
    RawSubAccount,
    'isolated_market' | 'created_at' | 'derivation_path'
  > {
  readonly derivation_path: Nullable<string>;
  readonly isolated_market: Nullable<string>;
  readonly created_at: Date;
}

export interface SubAccountsResp {
  readonly results: readonly SubAccount[];
}

interface RawSubAccountsResp {
  readonly results: readonly RawSubAccount[];
}

interface GetSubAccountsReq extends BaseReq {}

export async function getSubAccounts(
  req: GetSubAccountsReq,
): AsyncResp<SubAccountsResp> {
  const { signal } = req;

  const resp = await requestApi<RawSubAccountsResp>({
    signal,
    method: 'GET',
    url: `/account/subaccounts`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: {
      ...resp.data,
      results: resp.data.results.map(processSubAccount),
    },
  };
}

export function processAccount(account: RawAccount): Account {
  return {
    ...account,
    updated_at: msToDate(account.updated_at),
    total_collateral: new BigNumber(account.total_collateral),
    free_collateral: new BigNumber(account.free_collateral),
    account_value: new BigNumber(account.account_value),
    portfolio_change: new BigNumber(account.portfolio_change),
    portfolio_change_percentage: new BigNumber(
      account.portfolio_change_percentage,
    ),
    initial_margin_requirement: new BigNumber(
      account.initial_margin_requirement,
    ),
    maintenance_margin_requirement: new BigNumber(
      account.maintenance_margin_requirement,
    ),
  };
}

export function processAccountProfile(
  accountProfile: RawAccountProfile,
): AccountProfile {
  return {
    ...accountProfile,
    max_slippage:
      accountProfile.max_slippage !== '' && accountProfile.max_slippage != null
        ? new BigNumber(accountProfile.max_slippage)
        : null,
    referred_by:
      accountProfile.referred_by === ''
        ? null
        : accountProfile.referred_by ?? null,
    // WIP remove once API is available
    username: accountProfile.username ?? '', // eslint-disable-line @typescript-eslint/no-unnecessary-condition
    is_username_private: accountProfile.is_username_private ?? false, // eslint-disable-line @typescript-eslint/no-unnecessary-condition
    referral_code: accountProfile.referral_code ?? '', // eslint-disable-line @typescript-eslint/no-unnecessary-condition
    twitter: accountProfile.twitter ?? null,
    discord: accountProfile.discord ?? null,
    nfts: accountProfile.nfts ?? [],
    referral: processReferral(accountProfile.referral ?? null),
  };
}

function processReferral(referral: Nullable<RawReferral>): Nullable<Referral> {
  if (referral == null) return null;
  return {
    ...referral,
    discount_rate: new BigNumber(referral.discount_rate),
    commission_rate: new BigNumber(referral.commission_rate),
    discount_volume_cap: new BigNumber(referral.discount_volume_cap),
    commission_volume_cap: new BigNumber(referral.commission_volume_cap),
    points_bonus_rate: new BigNumber(referral.points_bonus_rate),
    points_bonus_volume_cap: new BigNumber(referral.points_bonus_volume_cap),
  };
}

export function processSubAccount(subAccount: RawSubAccount): SubAccount {
  return {
    ...subAccount,
    derivation_path:
      subAccount.derivation_path === '' ? null : subAccount.derivation_path,
    isolated_market:
      subAccount.isolated_market === '' ? null : subAccount.isolated_market,
    created_at: msToDate(subAccount.created_at),
    username: subAccount.username,
  };
}
