import { useEffect } from 'react';
import { nanoid } from 'nanoid';

import { useAuthActions, useAuthState } from '#/features/auth/auth-context';
import { logEvent, logException } from '#/features/logging/logging';
import { useParadexWebSocket } from '#/features/ws/ws-context';

export function useJwtTokenRefreshHandler(refreshIntervalMs: number) {
  const [authState] = useAuthState();
  const authActions = useAuthActions();
  const ws = useParadexWebSocket();

  useEffect(() => {
    const effectId = nanoid(5);
    logEvent(`JWT Refresh ${effectId} :: Effect :: Start`);
    if (authState.token == null) {
      logEvent(
        `JWT Refresh ${effectId} :: Effect :: No JWT token available, early exit from effect`,
        { effectId },
      );
      return;
    }

    const fetchJwtToken = async () => {
      if (ws == null) {
        logEvent(
          `JWT Refresh ${effectId} :: Interval :: WS instance is null, skipping JTW refresh`,
          { effectId },
        );
        return;
      }
      if (ws.readyState !== WebSocket.OPEN) {
        logEvent(
          `JWT Refresh ${effectId} :: Interval :: WS instance is not open, skipping JTW refresh`,
          { effectId },
        );
        return;
      }

      const result = await authActions.performAuth();
      if (!result.ok && result.error != null) {
        const error = new Error('Failed refreshing JWT', {
          cause: result.error,
        });
        logException(error, { effectId });
      }
      if (result.ok) {
        logEvent(
          `JWT Refresh ${effectId} :: Interval :: Successfully refreshed JWT`,
          { effectId },
        );
      }
    };

    logEvent(
      `JWT Refresh ${effectId} :: Interval :: Scheduling interval every '${refreshIntervalMs}ms'`,
      { effectId },
    );
    const intervalId = setInterval(() => {
      fetchJwtToken().catch((err) => {
        const message = 'Unexpected Error in useJwtTokenRefresher';
        logException(new Error(message, { cause: err }), { effectId });
      });
    }, refreshIntervalMs);

    return () => {
      logEvent(`JWT Refresh ${effectId} :: Interval :: Clearing interval`, {
        effectId,
      });
      clearInterval(intervalId);
      logEvent(`JWT Refresh ${effectId} :: Effect :: End`, { effectId });
    };
  }, [authActions, authState.token, refreshIntervalMs, ws]);
}
