/* eslint-disable prefer-destructuring */
/* eslint-disable radix */
/* eslint-disable guard-for-in */
/* eslint-disable object-shorthand */
/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/promise-function-async */
import { logEvent } from '#/features/logging/logging';

import {
  LibrarySymbolInfo,
  SubscribeBarsCallback,
} from '#/vendor/charting_library/datafeed-api';

import { getErrorMessage, logMessage } from './helpers';
import { GetBarsResult, HistoryProvider } from './history-provider';

interface DataSubscriber {
  symbolInfo: LibrarySymbolInfo;
  resolution: string;
  lastBarTime: number | null;
  listener: SubscribeBarsCallback;
}

interface DataSubscribers {
  [guid: string]: DataSubscriber;
}

/** Min amount of extra bars to request in addition to the most recent bar */
const MIN_COUNTBACK = 1;

/** Number of minutes in a day */
const MINUTES_IN_A_DAY = 1440;

/**
 * Max amount of extra bars to request in addition to the most recent bar.
 * The API accepts any number of countBack, but would timeout if the number
 * is too large.
 */
const MAX_COUNTBACK = MINUTES_IN_A_DAY;

export class DataPulseProvider {
  private readonly _subscribers: DataSubscribers = {};
  private _requestsPending: number = 0;
  private readonly _historyProvider: HistoryProvider;

  public constructor(
    historyProvider: HistoryProvider,
    updateFrequency: number,
  ) {
    this._historyProvider = historyProvider;
    setInterval(this._updateData.bind(this), updateFrequency);
  }

  public subscribeBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: string,
    newDataCallback: SubscribeBarsCallback,
    listenerGuid: string,
  ): void {
    if (this._subscribers.hasOwnProperty(listenerGuid)) {
      logMessage(
        `DataPulseProvider: already has subscriber with id=${listenerGuid}`,
      );
      return;
    }

    this._subscribers[listenerGuid] = {
      lastBarTime: null,
      listener: newDataCallback,
      resolution: resolution,
      symbolInfo: symbolInfo,
    };

    logMessage(
      `DataPulseProvider: subscribed for #${listenerGuid} - {${symbolInfo.name}, ${resolution}}`,
    );
  }

  public unsubscribeBars(listenerGuid: string): void {
    delete this._subscribers[listenerGuid];
    logMessage(`DataPulseProvider: unsubscribed for #${listenerGuid}`);
  }

  private _updateData(): void {
    if (this._requestsPending > 0) {
      return;
    }

    this._requestsPending = 0;
    for (const listenerGuid in this._subscribers) {
      // tslint:disable-line:forin
      this._requestsPending += 1;
      this._updateDataForSubscriber(listenerGuid)
        .then(() => {
          this._requestsPending -= 1;
          logMessage(
            `DataPulseProvider: data for #${listenerGuid} updated successfully, pending=${this._requestsPending}`,
          );
        })
        .catch((reason?: string | Error) => {
          this._requestsPending -= 1;
          logMessage(
            `DataPulseProvider: data for #${listenerGuid} updated with error=${getErrorMessage(
              reason,
            )}, pending=${this._requestsPending}`,
          );
        });
    }
  }

  private _updateDataForSubscriber(listenerGuid: string): Promise<void> {
    const subscriptionRecord = this._subscribers[listenerGuid]!;

    const rangeEndTimeSeconds = parseInt((Date.now() / 1000).toString());

    const resolutionSeconds = periodLengthSeconds(
      subscriptionRecord.resolution,
      1,
    );

    const rangeStartTimeSeconds = rangeEndTimeSeconds - resolutionSeconds;

    /**
     * Number of extra bars to load in addition to most recent one,
     * based on how many bars are missing since the last received bar.
     */
    const gapBarsCount = (function computeGapBarsCount() {
      if (subscriptionRecord.lastBarTime === null) return 0;
      const lastBarTimeSeconds = subscriptionRecord.lastBarTime / 1000;
      const gapSeconds = rangeEndTimeSeconds - lastBarTimeSeconds;
      const _gapBarsCount = Math.ceil(gapSeconds / resolutionSeconds);
      return _gapBarsCount;
    })();

    const countBack = Math.max(
      Math.min(gapBarsCount, MAX_COUNTBACK),
      MIN_COUNTBACK,
    );

    if (gapBarsCount > MIN_COUNTBACK) {
      logEvent('TradingView bar max countback fetch', {
        gapBarsCount,
        maxCountBack: MAX_COUNTBACK,
        requestedCountBack: countBack,
        resolution: subscriptionRecord.resolution,
        symbol: subscriptionRecord.symbolInfo.name,
      });
    }

    return this._historyProvider
      .getBars(subscriptionRecord.symbolInfo, subscriptionRecord.resolution, {
        from: rangeStartTimeSeconds,
        to: rangeEndTimeSeconds,
        countBack: countBack,
        firstDataRequest: false,
      })
      .then((result: GetBarsResult) => {
        this._onSubscriberDataReceived(listenerGuid, result);
      });
  }

  private _onSubscriberDataReceived(
    listenerGuid: string,
    result: GetBarsResult,
  ): void {
    // means the subscription was cancelled while waiting for data
    if (!this._subscribers.hasOwnProperty(listenerGuid)) {
      logMessage(
        `DataPulseProvider: Data comes for already unsubscribed subscription #${listenerGuid}`,
      );
      return;
    }

    const bars = result.bars;
    if (bars.length === 0) {
      return;
    }

    const lastIncomingBar = bars[bars.length - 1]!;
    const subscriptionRecord = this._subscribers[listenerGuid]!;

    /**
     * When last incoming bar is older than the last known bar,
     * it means that the response is unexpected and should be ignored.
     * All responses should come strictly ordered and contain at least
     * one known bar or a new bar.
     */
    const containsUnexpectedData =
      subscriptionRecord.lastBarTime !== null &&
      lastIncomingBar.time < subscriptionRecord.lastBarTime;

    if (containsUnexpectedData) {
      return;
    }

    // When this is the very first update only the last bar
    // can be published, otherwise we get a time violation.
    if (subscriptionRecord.lastBarTime == null) {
      subscriptionRecord.listener(lastIncomingBar);
    } else {
      for (const bar of bars) {
        // Publish if the current bar is the latest known bar
        // or if the current bar is not yet known.
        if (bar.time >= subscriptionRecord.lastBarTime) {
          subscriptionRecord.listener(bar);
        }
      }
    }

    subscriptionRecord.lastBarTime = lastIncomingBar.time;
  }
}

function periodLengthSeconds(
  resolution: string,
  requiredPeriodsCount: number,
): number {
  let daysCount = 0;

  if (resolution === 'D' || resolution === '1D') {
    daysCount = requiredPeriodsCount;
  } else if (resolution === 'M' || resolution === '1M') {
    daysCount = 31 * requiredPeriodsCount;
  } else if (resolution === 'W' || resolution === '1W') {
    daysCount = 7 * requiredPeriodsCount;
  } else {
    daysCount = (requiredPeriodsCount * parseInt(resolution)) / (24 * 60);
  }

  return daysCount * 24 * 60 * 60;
}
