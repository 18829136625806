import BigNumber from 'bignumber.js';

export function breakevenPrice(
  size: BigNumber,
  averageEntryPriceUsd: BigNumber,
  unrealizedFundingPnl: BigNumber,
  realizedPositionalPnl: BigNumber,
  usdcMarkPrice: BigNumber,
) {
  if (size.isZero()) {
    return new BigNumber(0);
  }
  // breakeven_price = average_entry_price_usd - (realized_positional_pnl * usdc_mark_price + unrealized_funding_pnl) / size
  const pnlSum = realizedPositionalPnl
    .times(usdcMarkPrice)
    .plus(unrealizedFundingPnl);
  const breakevenPriceValue = averageEntryPriceUsd.minus(pnlSum.div(size));
  return breakevenPriceValue.isNegative()
    ? new BigNumber(0)
    : breakevenPriceValue;
}
