import BigNumber from 'bignumber.js';

import { logEvent } from '#/features/logging/logging';

/**
 * Calculates the price of a perpetual option using the Black-Scholes model
 * @source https://github.com/tradeparadigm/mono/blob/release/paradex-1.82.0/api/paradex/oracle-service-v2/option/option_bs_model.go
 * @param S - Spot price
 * @param K - Strike price
 * @param impliedVariance - Volatility squared (σ²)
 * @param d1FundingRate - D1 funding rate
 * @param fundingPeriodYears - Funding period in years
 * @param futureFundingPeriodYears - Future funding period in years
 * @param optionType - Option type ('CALL' or 'PUT')
 * @returns The calculated option price
 */
export default function perpBsPrice(
  S: BigNumber,
  K: BigNumber,
  impliedVariance: BigNumber,
  d1FundingRate: BigNumber,
  fundingPeriodYears: BigNumber,
  futureFundingPeriodYears: BigNumber,
  optionType: 'CALL' | 'PUT',
): BigNumber {
  // Input validation
  if (
    S.isZero() ||
    K.isZero() ||
    impliedVariance.isZero() ||
    fundingPeriodYears.isZero()
  ) {
    logEvent('perpBsPrice :: S, K, or fundingPeriodYears should not be zero', {
      S: S.toString(),
      K: K.toString(),
      fundingPeriodYears: fundingPeriodYears.toString(),
      impliedVariance: impliedVariance.toString(),
    });
    return new BigNumber(0);
  }

  const r = interestRateFromD1FundingRate(
    d1FundingRate,
    futureFundingPeriodYears,
  );
  const p = pFunc(r, impliedVariance);
  const q = qFunc(r, impliedVariance);
  const u = uFunc(p, impliedVariance, fundingPeriodYears);
  const w = wFunc(q, r, impliedVariance, fundingPeriodYears);
  const A = aFunc(S, K, u, p);
  const B = bFunc(S, K, w, q, r, fundingPeriodYears);

  const discountedStrike = K.div(
    new BigNumber(1).plus(r.times(fundingPeriodYears)),
  );

  if (S.gte(K)) {
    if (optionType === 'CALL') {
      return S.times(A).minus(K.times(B)).plus(S.minus(discountedStrike));
    }
    return S.times(A).minus(K.times(B));
  }

  if (optionType === 'CALL') {
    return S.times(A).minus(K.times(B));
  }
  return S.times(A).minus(K.times(B)).minus(S.minus(discountedStrike));
}

function interestRateFromD1FundingRate(
  d1FundingRate: BigNumber,
  futureFundingPeriodYears: BigNumber,
): BigNumber {
  if (futureFundingPeriodYears.isZero()) {
    logEvent('perpBsPrice :: futureFundingPeriodYears should not be zero', {
      d1FundingRate: d1FundingRate.toString(),
      futureFundingPeriodYears: futureFundingPeriodYears.toString(),
    });
    return new BigNumber(0);
  }
  return d1FundingRate.div(
    new BigNumber(1).plus(d1FundingRate).times(futureFundingPeriodYears),
  );
}

function pFunc(r: BigNumber, impliedVariance: BigNumber): BigNumber {
  if (impliedVariance.isZero()) {
    logEvent(
      'perpBsPrice :: impliedVariance cannot be zero to avoid division by zero',
    );
    return new BigNumber(0);
  }
  return new BigNumber(1).plus(r.times(2).div(impliedVariance));
}

function qFunc(r: BigNumber, impliedVariance: BigNumber): BigNumber {
  if (impliedVariance.isZero()) {
    logEvent(
      'perpBsPrice :: impliedVariance cannot be zero to avoid division by zero',
    );
    return new BigNumber(0);
  }
  return new BigNumber(1).minus(r.times(2).div(impliedVariance));
}

function uFunc(
  p: BigNumber,
  impliedVariance: BigNumber,
  fundingPeriodYears: BigNumber,
): BigNumber {
  if (p.isZero() || impliedVariance.times(fundingPeriodYears).isZero()) {
    logEvent(
      'perpBsPrice :: Invalid input parameters in uFunc to avoid division by zero',
    );
    return new BigNumber(0);
  }
  const inner = p
    .pow(2)
    .plus(new BigNumber(8).div(impliedVariance.times(fundingPeriodYears)));
  return new BigNumber(Math.sqrt(inner.toNumber())).div(p);
}

function wFunc(
  q: BigNumber,
  r: BigNumber,
  impliedVariance: BigNumber,
  fundingPeriodYears: BigNumber,
): BigNumber {
  if (q.isZero() || impliedVariance.times(fundingPeriodYears).isZero()) {
    logEvent(
      'perpBsPrice :: Invalid input parameters in wFunc to avoid division by zero',
    );
    return new BigNumber(0);
  }
  const inner = q
    .pow(2)
    .plus(
      new BigNumber(8)
        .times(new BigNumber(1).plus(r.times(fundingPeriodYears)))
        .div(impliedVariance.times(fundingPeriodYears)),
    );
  return new BigNumber(Math.sqrt(inner.toNumber())).times(-1).div(q);
}

function aFunc(
  S: BigNumber,
  K: BigNumber,
  u: BigNumber,
  p: BigNumber,
): BigNumber {
  const ratio = S.div(K).toNumber();
  if (S.gte(K)) {
    const exponent = -0.5 * (1 + u.toNumber()) * p.toNumber();
    const powerResult = ratio ** exponent;
    return new BigNumber(0.5)
      .times(new BigNumber(u.toNumber() ** -1).minus(1))
      .times(new BigNumber(powerResult));
  }
  const exponent = -0.5 * (1 - u.toNumber()) * p.toNumber();
  const powerResult = ratio ** exponent;
  return new BigNumber(0.5)
    .times(new BigNumber(u.toNumber() ** -1).plus(1))
    .times(new BigNumber(powerResult));
}

function bFunc(
  S: BigNumber,
  K: BigNumber,
  w: BigNumber,
  q: BigNumber,
  r: BigNumber,
  fundingPeriodYears: BigNumber,
): BigNumber {
  const ratio = S.div(K).toNumber();
  const denominator = new BigNumber(2).times(
    new BigNumber(1).plus(r.times(fundingPeriodYears)),
  );

  if (S.gte(K)) {
    const exponent = 0.5 * (1 + w.toNumber()) * q.toNumber();
    const powerResult = ratio ** exponent;
    return new BigNumber(powerResult)
      .div(denominator)
      .times(new BigNumber(w.toNumber() ** -1).minus(1));
  }
  const exponent = 0.5 * (1 - w.toNumber()) * q.toNumber();
  const powerResult = ratio ** exponent;
  return new BigNumber(powerResult)
    .div(denominator)
    .times(new BigNumber(w.toNumber() ** -1).plus(1));
}
