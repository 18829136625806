import { useCloseVaultModalActions } from '#/features/vaults/close/modal-context';

/**
 * Hook to be consumed by UI components to trigger Close Vault flow
 */
export function useCloseVaultAction() {
  const modalActions = useCloseVaultModalActions();

  const execute = () => {
    // Prevents focus fighting with the modal
    setTimeout(() => {
      modalActions.open();
    }, 0);
  };

  return { execute };
}
