import BigNumber from 'bignumber.js';
import * as Starknet from 'starknet';

import { BridgedToken } from '#/features/paraclear';
import { type ParaclearProvider } from '#/features/wallets/paraclear/provider';
import ParaclearWallet from '#/features/wallets/paraclear/wallet';

import { fromQuantums, toQuantums } from '#/utils/quantums';
import toIntString from '#/utils/toIntString';

import type { ParaclearContract } from '#/features/paraclear';

/**
 * Issues a batched transaction to withdraw funds from Paraclear
 * and initiate withdraw on the ERC20 Token bridge on StarkNet.
 *
 * Only waits for the transaction to be sent to StarkNet.
 *
 * @param amount Amount to be withdrawn.
 * @param token Token to be withdrawn.
 * @param l1Recipient Address of the recipient account in Ethereum.
 *
 * @returns Transaction hash
 */
export async function requestWithdraw(
  amount: BigNumber,
  l1Recipient: string,
  token: BridgedToken,
  paraclear: ParaclearContract,
  provider: ParaclearProvider,
  l2RelayerAddress: string,
  isAutoWithdraw: boolean,
  withdrawFee: BigNumber,
): Promise<string> {
  const tokenAddress = toIntString(token.l2TokenAddress);

  const paraclearAmount = toQuantums(amount, paraclear.decimals);
  const paraclearTransaction = {
    contractAddress: paraclear.address,
    entrypoint: 'withdraw',
    calldata: [tokenAddress, paraclearAmount],
  };

  const l2BridgeVersion = await ParaclearWallet.getL2BridgeVersion(
    provider,
    token,
  );

  const res = await ParaclearWallet.callContract(provider, {
    contractAddress: paraclear.address,
    entrypoint: 'getSocializedLossFactor',
  });
  const socializedLossFactor = fromQuantums(
    toIntString(res[0] ?? 0),
    paraclear.decimals,
  );

  const fee = isAutoWithdraw ? withdrawFee : 0;
  const receivableAmount = amount
    .times(BigNumber(1).minus(socializedLossFactor))
    .minus(fee);

  const relayerAmount = toQuantums(withdrawFee, token.decimals);
  const relayerTransaction = {
    contractAddress: token.l2TokenAddress,
    entrypoint: 'transfer',
    calldata: [l2RelayerAddress, relayerAmount, '0'],
  };

  const bridgeAmount = toQuantums(receivableAmount, token.decimals);

  const bridgeTransaction = (() => {
    switch (l2BridgeVersion) {
      case 1:
        return {
          contractAddress: token.l2BridgeAddress,
          entrypoint: 'initiate_withdraw',
          calldata: [toIntString(l1Recipient), bridgeAmount, '0'],
        };
      case 2:
        return {
          contractAddress: token.l2BridgeAddress,
          entrypoint: 'initiate_withdraw',
          calldata: [{ address: toIntString(l1Recipient) }, bridgeAmount, '0'],
        };
      // no default
    }
  })();

  const transactionBatch = isAutoWithdraw
    ? [paraclearTransaction, bridgeTransaction, relayerTransaction]
    : [paraclearTransaction, bridgeTransaction];

  const response = await ParaclearWallet.executeTransaction(
    provider,
    transactionBatch,
  );
  return response.transaction_hash;
}

/**
 * Sends a batched transaction to withdraw funds from Paraclear
 * and initiate withdraw via Layerswap.
 *
 * @param calldata Calldata to be sent to StarkNet.
 *
 * @returns Transaction hash
 */
export async function withdrawViaLayerswap(
  provider: ParaclearProvider,
  calldata: string,
): Promise<string> {
  const transactions = JSON.parse(calldata) as Starknet.Call[];
  const response = await ParaclearWallet.executeTransaction(
    provider,
    transactions,
  );
  return response.transaction_hash;
}
