const NO_TOKEN = '';
const authState = { token: NO_TOKEN };

export function getAuthToken(): string {
  return authState.token;
}

export function setAuthToken(token: string) {
  authState.token = token;
}

export function resetAuthToken() {
  authState.token = NO_TOKEN;
}
