import { Maybe } from '#/utils/types';

export const CAUSES_DEPTH = 5;
/**
 * Prepares a detailed error message string for logging.
 * This function formats the error message to include the main error
 * and any nested causes of the error.
 *
 * Additionally, this formatting makes Sentry issues more readable and unique.
 */
export const flatErrorMessage = (
  message: string,
  error: Maybe<Error> | unknown,
): string => {
  try {
    if (error == null) {
      return `${message} Error argument was undefined error='${String(error)}'`;
    }

    let logMessage = `${message} ${String(error)}`;

    for (
      let cause = (error as Maybe<Error>)?.cause, i = 0;
      cause != null && i <= CAUSES_DEPTH;
      cause = (cause as Maybe<Error>)?.cause, i++
    ) {
      logMessage += `\nCaused by: ${(cause as Maybe<Error>)?.message}`;
    }

    return logMessage;
  } catch (err) {
    return `Unexpected Error: failed to prepare the error message for the input: error=${String(
      error,
    )}. Caused by ${String(err)}`;
  }
};
