import BigNumber from 'bignumber.js';

import { PointsLeaderboardEntry } from '#/api/points';

import {
  TWITTER_IMG_SIZES,
  twitterHandle,
  twitterImgSrcSet,
  twitterProfileUrl,
} from '#/components/twitter/utils';

import { TwitterProfileView } from '#/features/account/account-profile-views';

import { formatPercent } from '#/utils/percent';
import { FormattedValue, Nullable } from '#/utils/types';
import { PollingConnectionBaseState } from '#/utils/usePollingConnection';

export interface Store extends PollingConnectionBaseState {
  readonly data: Nullable<PointsLeaderboardEntry[]>;
}

export interface PointsLeaderboardView {
  readonly isLoading: boolean;
  readonly error: string | null;
  readonly data: PointsLeaderboardEntryView[];
}

export interface PointsLeaderboardEntryView
  extends Pick<
    PointsLeaderboardEntry,
    'rank' | 'username' | 'is_user_account'
  > {
  readonly total_points: FormattedValue<Nullable<BigNumber>>;
  readonly total_share: FormattedValue<Nullable<BigNumber>>;
  readonly twitter: Nullable<TwitterProfileView>;
  readonly isUserAccount: boolean;
}

export function createView(state: Store): PointsLeaderboardView {
  const { status, error, data } = state;
  if (data == null) {
    return {
      isLoading: false,
      error: null,
      data: [],
    };
  }

  return {
    isLoading: status === 'loading',
    error: error === '' ? null : error,
    data: data.map(entryView),
  };
}

function entryView(entry: PointsLeaderboardEntry): PointsLeaderboardEntryView {
  return {
    rank: entry.rank,
    username: entry.username,
    is_user_account: entry.is_user_account,
    total_points: {
      value: entry.total_points,
      formatted: entry.total_points?.toFormat(0) ?? '0',
    },
    total_share: {
      value: entry.total_share,
      formatted: formatPercent(entry.total_share ?? new BigNumber(0)),
    },
    twitter: twitterView(entry),
    isUserAccount: entry.is_user_account,
  };
}

function twitterView(
  entry: PointsLeaderboardEntry,
): Nullable<TwitterProfileView> {
  const { twitter_username, twitter_profile_image_url } = entry;
  if (twitter_username == null) return null;

  return {
    id: null,
    handle: twitterHandle(twitter_username),
    imageUrl: twitter_profile_image_url ?? '',
    url: twitterProfileUrl(twitter_username),
    imageSrcSet: twitterImgSrcSet(twitter_profile_image_url ?? ''),
    imageSizes: TWITTER_IMG_SIZES,
  };
}
