import BigNumber from 'bignumber.js';

import { Order } from '#/api/orders';

import { Maybe } from '#/utils/types';

export function isNewOrder(order: Pick<Order, 'status'>): boolean {
  return order.status === 'NEW';
}

export function isClosedOrder(order: Pick<Order, 'status'>): boolean {
  return order.status === 'CLOSED';
}

/**
 * There are two types of TPSL orders:
 * - Position TPSL: have size 0, because it will always close the full position
 * - Order TPSL: have size of the placed order
 *
 * This function checks if is a Position TPSL order
 */
export function isPositionTpslOrder(
  order: Pick<Order, 'type' | 'size'>,
): boolean {
  const isTpSlOrder = isTakeProfitOrder(order) || isStopLossOrder(order);
  return isTpSlOrder && order.size.isZero();
}

export function isCancelledOrder(
  order: Pick<Order, 'status' | 'remaining_size'>,
): boolean {
  return order.status === 'CLOSED' && order.remaining_size.isGreaterThan(0);
}

export function hadSomeFill(
  order: Pick<Order, 'size' | 'remaining_size'>,
): boolean {
  return order.remaining_size.isLessThan(order.size);
}

export function isMarketOrder(order: Pick<Order, 'type'>): boolean {
  return (
    order.type === 'MARKET' ||
    order.type === 'STOP_MARKET' ||
    order.type === 'TAKE_PROFIT_MARKET' ||
    order.type === 'STOP_LOSS_MARKET'
  );
}

export function isLimitOrder(order: Pick<Order, 'type'>): boolean {
  return (
    order.type === 'LIMIT' ||
    order.type === 'STOP_LIMIT' ||
    order.type === 'TAKE_PROFIT_LIMIT' ||
    order.type === 'STOP_LOSS_LIMIT'
  );
}

export function isStopOrder(order: Pick<Order, 'type'>): boolean {
  return order.type === 'STOP_MARKET' || order.type === 'STOP_LIMIT';
}

export function isTakeProfitOrder(order: Pick<Order, 'type'>): boolean {
  return (
    order.type === 'TAKE_PROFIT_LIMIT' || order.type === 'TAKE_PROFIT_MARKET'
  );
}

export function isStopLossOrder(order: Pick<Order, 'type'>): boolean {
  return order.type === 'STOP_LOSS_LIMIT' || order.type === 'STOP_LOSS_MARKET';
}

export function isTriggered(order: Pick<Order, 'type' | 'status'>): boolean {
  return isStopOrder(order) && order.status === 'OPEN';
}

export function isModifiedOrderSuccess(
  order: Pick<Order, 'request_info'>,
): boolean {
  return (
    Boolean(order.request_info) &&
    order.request_info?.request_type === 'MODIFY_ORDER' &&
    order.request_info.status === 'SUCCESS'
  );
}

export function isModifiedOrderRejected(
  order: Pick<Order, 'request_info'>,
): boolean {
  return (
    Boolean(order.request_info) &&
    order.request_info?.request_type === 'MODIFY_ORDER' &&
    order.request_info.status === 'REJECTED'
  );
}

export function filledSize(
  order: Pick<Order, 'size' | 'remaining_size'>,
): BigNumber {
  return order.size.minus(order.remaining_size);
}

export function typeToText(type: Order['type']) {
  return ORDER_TYPE_TEXT_MAP[type];
}

export const ORDER_TYPE_TEXT_MAP = {
  LIMIT: 'Limit',
  MARKET: 'Market',
  STOP_LIMIT: 'Stop Limit',
  STOP_MARKET: 'Stop Market',
  TAKE_PROFIT_LIMIT: 'Take Profit Limit',
  TAKE_PROFIT_MARKET: 'Take Profit Market',
  STOP_LOSS_LIMIT: 'Stop Loss Limit',
  STOP_LOSS_MARKET: 'Stop Loss Market',
} as const;

export function sideToText(side: Order['side']) {
  return ORDER_SIDE_TEXT_MAP[side];
}

export function oppositeSide(side: Order['side']) {
  return side === 'BUY' ? 'SELL' : 'BUY';
}

const ORDER_SIDE_TEXT_MAP = {
  BUY: 'Buy',
  SELL: 'Sell',
} as const;

export function convertAmountToBaseAsset(
  amount: BigNumber,
  price: BigNumber,
): BigNumber {
  return amount.dividedBy(price);
}
export function isTpSlPriceValid(
  price: Maybe<BigNumber>,
  entryPrice: Maybe<BigNumber>,
  side: Maybe<Order['side']>,
  type: 'profit' | 'loss',
) {
  if (side == null) return false;
  if (entryPrice == null || entryPrice.isNaN()) return false;
  if (price == null || price.isNaN()) return false;

  switch (true) {
    case side === 'BUY' && type === 'profit':
    case side === 'SELL' && type === 'loss':
      return price.isGreaterThan(entryPrice);
    case side === 'BUY' && type === 'loss':
    case side === 'SELL' && type === 'profit':
      return price.isLessThan(entryPrice);
    default:
      return false;
  }
}

export function calcOrderTpSlPrice(
  side: Order['side'],
  percent: BigNumber,
  entryPrice: Maybe<BigNumber>,
  leverage: Maybe<BigNumber>,
  type: 'profit' | 'loss',
) {
  if (entryPrice == null || leverage == null) return null;
  const plusPercent = entryPrice.plus(
    entryPrice.times(percent).div(100).div(leverage),
  );
  const minusPercent = entryPrice.minus(
    entryPrice.times(percent).div(100).div(leverage),
  );

  switch (true) {
    case type === 'profit' && side === 'BUY':
    case type === 'loss' && side === 'SELL':
      return plusPercent;
    case type === 'profit' && side === 'SELL':
    case type === 'loss' && side === 'BUY':
      return minusPercent;
    default:
      return null;
  }
}
