import { Abortable } from '#/api/types';

import { AsyncResult } from '#/utils/types';

export default function actionOnboardAndAuthenticate(
  ...params: Parameters<typeof onboardAndAuthenticate>
) {
  return async (options?: Abortable) =>
    onboardAndAuthenticate(params[0], params[1], params[2], options);
}

async function onboardAndAuthenticate(
  initializeParaclearWallet: () => AsyncResult<void>,
  performOnboarding: () => AsyncResult<void>,
  performAuth: (options?: Abortable) => AsyncResult<void>,
  options: Abortable = {},
): AsyncResult<void> {
  const initializeParaclearWalletResult = await initializeParaclearWallet();

  if (!initializeParaclearWalletResult.ok) {
    return {
      ok: false,
      error: initializeParaclearWalletResult.error,
      reason: 'failed_to_initialize_paraclear_wallet',
    } as const;
  }

  const onboardingResult = await performOnboarding();
  if (!onboardingResult.ok) {
    return {
      ok: false,
      error: onboardingResult.error,
      reason: 'failed_to_onboard',
    } as const;
  }

  const authResult = await performAuth(options);
  if (!authResult.ok) {
    return {
      ok: false,
      error: authResult.error,
      reason: 'failed_to_authenticate',
    } as const;
  }

  return { ok: true, data: undefined } as const;
}
