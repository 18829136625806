import { nanoid } from 'nanoid';

import {
  AccountIsBeingLiquidated,
  AccountWasLiquidated,
} from '#/components/common/notifications/account';
import {
  DepositFailed,
  DepositRequestLayerswapSent,
  DepositRequestReady,
  DepositRequestSent,
  DepositSingleStepRequestSent,
  DepositSuccessful,
  DepositToParaclearInProgress,
  DepositUnexpectedError,
} from '#/components/common/notifications/deposit';
import {
  LimitOrderFill,
  LiquidationFill,
} from '#/components/common/notifications/fills';
import {
  CancelledLimitOrder,
  CancelledMarketOrder,
  FilledMarketOrder,
  ModifyOrderInfo,
  ModifyOrderRejected,
  ModifyOrderSuccess,
  SubmittedAlgoOrder,
  SubmittedOrder,
} from '#/components/common/notifications/orders';
import { TradeBusted } from '#/components/common/notifications/trade-busts';
import { TransferSuccessful } from '#/components/common/notifications/transfer';
import {
  VaultDepositFailure,
  VaultDepositRequestSent,
  VaultDepositSuccess,
  VaultSettingsRequestSent,
  VaultWithdrawalFailure,
  VaultWithdrawalRequestSent,
  VaultWithdrawalSuccess,
} from '#/components/common/notifications/vaults';
import {
  AutoWithdrawProcessing,
  InitiateWithdrawRequestFailed,
  InitiateWithdrawRequestSent,
  InitiateWithdrawToBridgeRequestInProgress,
  WithdrawRequestReady,
  WithdrawSuccessful,
  WithdrawToWalletRequestFailed,
  WithdrawToWalletRequestSent,
  WithdrawUnexpectedError,
  WithdrawViaLayerswapFailed,
  WithdrawViaLayerswapSent,
} from '#/components/common/notifications/withdraw';
import { ToastOptions } from '#/components/common/toast';

import { NotificationEntryType, NotificationPropsByType } from './types';

type NotificationsMap = {
  [Type in NotificationEntryType]: {
    readonly getId: (
      kind: Type,
      props: NotificationPropsByType[Type],
    ) => string;
    readonly Component: React.ComponentType<NotificationPropsByType[Type]>;
    readonly toastOptions: ToastOptions;
  };
};

export const NOTIFICATIONS_MAP: NotificationsMap = {
  'deposit-successful': {
    getId: (kind, props) => `${kind}-${props.id}`,
    Component: DepositSuccessful,
    toastOptions: {},
  },
  'deposit-failed': {
    getId: (kind, _props) => `${kind}-${nanoid()}`,
    Component: DepositFailed,
    toastOptions: {},
  },
  'deposit-unexpected-error': {
    getId: (kind, _props) => `${kind}-${nanoid()}`,
    Component: DepositUnexpectedError,
    toastOptions: {},
  },
  'deposit-request-sent': {
    getId: (kind, _props) => `${kind}-${nanoid()}`,
    Component: DepositRequestSent,
    toastOptions: {},
  },
  'deposit-request-single-step-sent': {
    getId: (kind, _props) => `${kind}-${nanoid()}`,
    Component: DepositSingleStepRequestSent,
    toastOptions: { autoClose: false },
  },
  'deposit-request-ready': {
    getId: (kind, props) => `${kind}-${props.id}`,
    Component: DepositRequestReady,
    toastOptions: { autoClose: false },
  },
  'deposit-to-paraclear-in-progress': {
    getId: (kind, _props) => `${kind}-${nanoid()}`,
    Component: DepositToParaclearInProgress,
    toastOptions: {},
  },
  'deposit-request-layerswap-sent': {
    getId: (kind, _props) => `${kind}-${nanoid()}`,
    Component: DepositRequestLayerswapSent,
    toastOptions: {},
  },
  'transfer-successful': {
    getId: (kind, props) => `${kind}-${props.id}`,
    Component: TransferSuccessful,
    toastOptions: { autoClose: false },
  },
  'withdraw-unexpected-error': {
    getId: (kind, _props) => `${kind}-${nanoid()}`,
    Component: WithdrawUnexpectedError,
    toastOptions: {},
  },
  'initiate-withdraw-request-sent': {
    getId: (kind, _props) => `${kind}-${nanoid()}`,
    Component: InitiateWithdrawRequestSent,
    toastOptions: {},
  },
  'initiate-withdraw-to-bridge-request-in-progress': {
    getId: (kind, props) => `${kind}-${props.id}`,
    Component: InitiateWithdrawToBridgeRequestInProgress,
    toastOptions: {},
  },
  'initiate-withdraw-request-failed': {
    getId: (kind, _props) => `${kind}-${nanoid()}`,
    Component: InitiateWithdrawRequestFailed,
    toastOptions: {},
  },
  'withdraw-via-layerswap-sent': {
    getId: (kind, _props) => `${kind}-${nanoid()}`,
    Component: WithdrawViaLayerswapSent,
    toastOptions: {},
  },
  'withdraw-via-layerswap-failed': {
    getId: (kind, _props) => `${kind}-${nanoid()}`,
    Component: WithdrawViaLayerswapFailed,
    toastOptions: {},
  },
  'withdraw-request-ready': {
    getId: (kind, props) => `${kind}-${props.id}`,
    Component: WithdrawRequestReady,
    toastOptions: { autoClose: false },
  },
  'auto-withdraw-processing': {
    getId: (kind, props) => `${kind}-${props.id}`,
    Component: AutoWithdrawProcessing,
    toastOptions: { autoClose: false },
  },
  'withdraw-to-wallet-request-sent': {
    getId: (kind, _props) => `${kind}-${nanoid()}`,
    Component: WithdrawToWalletRequestSent,
    toastOptions: {},
  },
  'withdraw-to-wallet-request-failed': {
    getId: (kind, _props) => `${kind}-${nanoid()}`,
    Component: WithdrawToWalletRequestFailed,
    toastOptions: {},
  },
  'withdraw-successful': {
    getId: (kind, props) => `${kind}-${props.id}`,
    Component: WithdrawSuccessful,
    toastOptions: {},
  },
  'filled-market-order': {
    getId: (kind, props) => `${kind}-${props.order.id}`,
    Component: FilledMarketOrder,
    toastOptions: {},
  },
  'limit-order-fill': {
    getId: (kind, props) => `${kind}-${props.fill.id}`,
    Component: LimitOrderFill,
    toastOptions: {},
  },
  'liquidation-fill': {
    getId: (kind, props) => `${kind}-${props.fill.id}`,
    Component: LiquidationFill,
    toastOptions: {},
  },
  'trade-bust': {
    getId: (kind, props) => `${kind}-${props.bust.busted_fill_id}`,
    Component: TradeBusted,
    toastOptions: { autoClose: false },
  },
  'account-is-being-liquidated': {
    getId: (kind, _props) => `${kind}-${nanoid()}`,
    Component: AccountIsBeingLiquidated,
    toastOptions: {},
  },
  'account-was-liquidated': {
    getId: (kind, props) => `${kind}-${props.liquidation.id}`,
    Component: AccountWasLiquidated,
    toastOptions: { autoClose: false },
  },
  'cancelled-market-order': {
    getId: (kind, props) => `${kind}-${props.order.id}`,
    Component: CancelledMarketOrder,
    toastOptions: {},
  },
  'submitted-order': {
    getId: (kind, props) => `${kind}-${props.order.id}`,
    Component: SubmittedOrder,
    toastOptions: {},
  },
  'submitted-algo-order': {
    getId: (kind, props) => `${kind}-${props.order.id}`,
    Component: SubmittedAlgoOrder,
    toastOptions: {},
  },
  'cancelled-limit-order': {
    getId: (kind, props) => `${kind}-${props.order.id}`,
    Component: CancelledLimitOrder,
    toastOptions: {},
  },
  'modify-order-success': {
    getId: (kind, props) =>
      props.order.request_info != null
        ? `${kind}-${props.order.request_info.id}-${props.order.request_info.status}`
        : `${kind}-${nanoid()}`,
    Component: ModifyOrderSuccess,
    toastOptions: {},
  },
  'modify-order-info': {
    getId: (kind, props) =>
      props.order.request_info != null
        ? `${kind}-${props.order.request_info.id}-${props.order.request_info.status}`
        : `${kind}-${nanoid()}`,
    Component: ModifyOrderInfo,
    toastOptions: {},
  },
  'modify-order-rejected': {
    getId: (kind, props) =>
      props.order.request_info != null
        ? `${kind}-${props.order.request_info.id}-${props.order.request_info.status}`
        : `${kind}-${nanoid()}`,
    Component: ModifyOrderRejected,
    toastOptions: {},
  },
  'vault-deposit-request-sent': {
    getId: (kind, _props) => `${kind}-${nanoid()}`,
    Component: VaultDepositRequestSent,
    toastOptions: {},
  },
  'vault-deposit-success': {
    getId: (kind, _props) => `${kind}-${nanoid()}`,
    Component: VaultDepositSuccess,
    toastOptions: {},
  },
  'vault-deposit-failure': {
    getId: (kind, _props) => `${kind}-${nanoid()}`,
    Component: VaultDepositFailure,
    toastOptions: {},
  },
  'vault-withdrawal-request-sent': {
    getId: (kind, _props) => `${kind}-${nanoid()}`,
    Component: VaultWithdrawalRequestSent,
    toastOptions: {},
  },
  'vault-withdrawal-success': {
    getId: (kind, _props) => `${kind}-${nanoid()}`,
    Component: VaultWithdrawalSuccess,
    toastOptions: {},
  },
  'vault-withdrawal-failure': {
    getId: (kind, _props) => `${kind}-${nanoid()}`,
    Component: VaultWithdrawalFailure,
    toastOptions: {},
  },
  'vault-settings-request-sent': {
    getId: (kind, _props) => `${kind}-${nanoid()}`,
    Component: VaultSettingsRequestSent,
    toastOptions: {},
  },
};
