import BigNumber from 'bignumber.js';

import { msToDate } from '#/utils/date';
import getQueryString from '#/utils/getQueryString';

import {
  AsyncResp,
  BasePaginatedReq,
  PaginatedRespData,
  requestApi,
} from './fetch-api';
import { Market } from './markets';
import { Order } from './orders';
import { UnixTimeMs } from './types';

export type FillSide = 'BUY' | 'SELL';
export type FillType = 'FILL' | 'LIQUIDATION' | 'TRANSFER';

type FillLiquidity = 'TAKER' | 'MAKER';

export interface RawFill {
  readonly id: string;
  readonly side: FillSide;
  readonly liquidity: FillLiquidity;
  readonly market: string;
  readonly order_id: Order['id'];
  readonly price: string;
  readonly fee: string;
  readonly fee_currency: string;
  readonly size: string;
  readonly created_at: UnixTimeMs;
  readonly fill_type: FillType;
  readonly realized_pnl: string;
  readonly account: string;
  readonly underlying_price: string;
}

export interface Fill
  extends Omit<
    RawFill,
    | 'id'
    | 'price'
    | 'size'
    | 'fee'
    | 'created_at'
    | 'realized_pnl'
    | 'underlying_price'
  > {
  /* a non-unique ID as received from Cloud API */
  readonly rawId: RawFill['id'];
  /* a derived unique ID */
  readonly id: `${FillType}-${RawFill['id']}`;
  readonly created_at: Date;
  readonly price: BigNumber;
  readonly size: BigNumber;
  readonly fee: BigNumber;
  readonly realized_pnl: BigNumber | null;
  readonly underlying_price: BigNumber;
}

interface FillsReq extends BasePaginatedReq {
  readonly market?: Market['symbol'];
}

export interface RawFillsResp extends PaginatedRespData<RawFill> {}

export interface FillsResp extends PaginatedRespData<Fill> {}

export async function getFills(req: FillsReq): AsyncResp<FillsResp> {
  const { signal, market, cursor, page_size } = req;

  const query = getQueryString([
    ['market', market],
    ['cursor', cursor],
    ['page_size', page_size],
  ]);

  const resp = await requestApi<RawFillsResp>({
    signal,
    method: 'GET',
    url: `/fills${query}`,
  });

  if (!resp.ok) {
    return resp;
  }

  return {
    ...resp,
    data: {
      ...resp.data,
      results: resp.data.results.map(processFill),
    },
  };
}

export function processFill(fill: RawFill): Fill {
  return {
    ...fill,
    rawId: fill.id,
    id: `${fill.fill_type}-${fill.id}`,
    created_at: msToDate(fill.created_at),
    price: new BigNumber(fill.price),
    size: new BigNumber(fill.size),
    fee: new BigNumber(fill.fee),
    realized_pnl: new BigNumber(fill.realized_pnl),
    underlying_price: new BigNumber(fill.underlying_price),
  };
}
