import { getErrorMessage } from '#/utils/error';
import { isUserRejectedRequestError } from '#/utils/errors';

export interface EthereumProviderRpcError extends Error {
  readonly code: number;
  readonly data?: unknown;
}

interface PreparedErrorMessage {
  readonly message: string;
  readonly isException: boolean;
}

export function prepareErrorMessage(
  step: string,
  err: unknown,
): PreparedErrorMessage {
  if (isUserRejectedRequestError(err)) {
    const message = `${step}: User rejected transaction`;
    return { message, isException: false };
  }

  const reason = getErrorMessage(err);
  const message = `${step}: ${String(reason)}`;

  return { message, isException: true };
}
