import { constants } from 'starknet';

// WIP: These Public Starknet Chain IDs are different from Private
// Paraclear Starknet should be provided from the API config in the future.
export function getStarknetChainIdFromEthChainId(
  chainId: number,
): constants.NetworkName {
  switch (chainId) {
    case 1:
      return constants.NetworkName.SN_MAIN;
    case 11155111:
    default:
      return constants.NetworkName.SN_SEPOLIA;
  }
}
