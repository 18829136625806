import { CallbackError, createInstance } from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';

import { logException } from '#/features/logging/logging';
import { getPreferredLanguageStorageKey } from '#/features/storage/storage';

import { getStorageItem } from '#/utils/localStorage';

import { Locale, resources, supportedLocales } from './translations';

import type { TranslationResources } from './translations/en';

// docs: https://www.i18next.com/overview/typescript
declare module 'i18next' {
  interface CustomTypeOptions {
    resources: { translation: TranslationResources };
  }
}

const DEFAULT_LANGUAGE: Locale = 'en';

const i18nInstance = createInstance({
  debug: "prod" === 'development',
  supportedLngs: supportedLocales,
  lng: getPreferredLanguage(),
  fallbackLng: false, // We use approach similar to `GNU gettext` where missing keys are written in plain English and just used as fallback
  interpolation: {
    escapeValue: false, // Escaping(XSS prevention) is handled by React
  },
  saveMissing: true, // Required for `missingKey` handler to be called
} as const);
i18nInstance
  .use(
    resourcesToBackend(async (language, _namespace, callback) => {
      try {
        const resource = await resources[language as Locale].translation();
        callback(null, resource.default);
      } catch (error: unknown) {
        callback(error as CallbackError, null);
      }
    }),
  )
  .init()
  .catch((cause: unknown) => {
    const message = 'Unknown error initializing i18next';
    const error = new Error(message, { cause });
    logException(error);
  });
i18nInstance.on('missingKey', (lngs, namespace, key) => {
  if ("prod" === 'test-runner') {
    // Jest won't load keys and will always fallback to English.
    // Errors should not be raised in that case.
    return;
  }
  const message = 'Language translation missing';
  const error = new Error(message);
  logException(error, { lngs, namespace, key });
});

function getPreferredLanguage() {
  const preferredLanguage = getStorageItem<Locale>(
    getPreferredLanguageStorageKey(),
  );

  if (preferredLanguage == null) {
    return DEFAULT_LANGUAGE;
  }
  if (!supportedLocales.includes(preferredLanguage)) {
    return DEFAULT_LANGUAGE;
  }
  return preferredLanguage;
}

export type { Locale } from './translations';
export { supportedLocales } from './translations';
export { I18nextProvider } from 'react-i18next';
export { i18nInstance };
