interface FieldWithError {
  readonly error: string;
}

export default function fieldErrorsView(
  fields: readonly FieldWithError[],
): string {
  return fields
    .map((field) => field.error)
    .filter((str) => str.length > 0)
    .join(' ');
}
