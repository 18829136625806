import BigNumber from 'bignumber.js';

export function calcWithdrawableAmount({
  cloudBalance,
  chainBalance,
  freeCollateral,
  assetMarkPrice,
  hasOpenOrders,
  hasOpenPositions,
  conservativeAssetPriceFactor,
}: {
  readonly cloudBalance: BigNumber;
  readonly chainBalance: BigNumber;
  readonly freeCollateral: BigNumber;
  readonly assetMarkPrice: BigNumber;
  readonly hasOpenOrders: boolean;
  readonly hasOpenPositions: boolean;
  readonly conservativeAssetPriceFactor: number;
}): BigNumber {
  if (!hasOpenOrders && !hasOpenPositions) {
    // To avoid discrepancies between the cloud and paraclear balances, which
    // could lead to a failed withdrawal attempt, we take the minimum of the two
    return BigNumber.minimum(cloudBalance, chainBalance);
  }

  const conservativeAssetPrice = assetMarkPrice.multipliedBy(
    conservativeAssetPriceFactor,
  );
  const conservativeFreeCollateral = freeCollateral.dividedBy(
    conservativeAssetPrice,
  );

  return BigNumber.minimum(
    conservativeFreeCollateral,
    cloudBalance,
    chainBalance,
  );
}
