/* eslint-disable no-else-return */
import BigNumber from 'bignumber.js';
import { Maybe } from 'yup';

import { OpenPosition } from '#/api/positions';

import { MarketsStoreView } from '#/features/perpetuals/market-info/markets-context';
import { MarketsSummaryStoreView } from '#/features/perpetuals/market-info/markets-summary-context';
import * as PositionFn from '#/features/perpetuals/positions/functions';
import { OpenPositionsStore } from '#/features/perpetuals/positions/open-positions-store-context';
import { OpenOrdersStoreView } from '#/features/perpetuals/trade-overview/open-orders-context';

import { accountImfWithCap } from '#/utils/margin';

import calculate_fee_provision from './calculate_fee_provision';
import { calculate_open_loss } from './calculate_open_loss';

/**
 * Adapted from
 * @see Paradex Pepe Calculator
 */
export default function calculate_margin_requirement(
  margin_check: 'Initial' | 'Maintenance',
  asset: string, // e.g. 'SOL-USD-PERP'
  assetOpenPosition: Maybe<Pick<OpenPosition, 'market' | 'size' | 'side'>>,
  marketsSummaryStore: MarketsSummaryStoreView,
  marketsStore: MarketsStoreView,
  openOrdersStore: OpenOrdersStoreView,
  marginAccount: string,
  profileMaxSlippage: BigNumber | null,
  accountImfBase: BigNumber | null,
): BigNumber {
  const marketSummary = marketsSummaryStore.getMarketSummary(asset);
  const market = marketsStore.getMarket(asset);
  const assetOpenOrders = openOrdersStore
    .getAll()
    .filter(
      (order) => order.market === asset && order.account === marginAccount,
    );

  if (marketSummary == null) {
    throw new Error(
      `calculate_margin_requirement: asset='${asset}' Market Summary is not available`,
    );
  }
  if (market == null) {
    throw new Error(
      `calculate_margin_requirement: asset='${asset}' Market is not available`,
    );
  }

  const openPositions = assetOpenPosition == null ? [] : [assetOpenPosition];
  const openNotional = PositionFn.calcOpenNotional(
    assetOpenOrders,
    openPositions,
    marketsStore.getMarket,
    marketsSummaryStore.getMarketSummary,
    margin_check === 'Initial',
  );

  const openLoss = calculate_open_loss(
    asset,
    marginAccount,
    assetOpenPosition,
    openOrdersStore,
    marketsSummaryStore,
    marketsStore,
    profileMaxSlippage,
  );
  const feeProvision = calculate_fee_provision(
    asset,
    marginAccount,
    margin_check,
    assetOpenPosition,
    marketsSummaryStore,
    openOrdersStore,
  );

  // update this to option margin requirement calculation
  if (market.delta1_cross_margin_params == null) {
    return BigNumber(0);
  }

  const { imf_base } = market.delta1_cross_margin_params;
  const { mmf_factor } = market.delta1_cross_margin_params;
  // use account imf base if available, otherwise use market imf base
  const IMF = accountImfWithCap(imf_base, accountImfBase);

  if (margin_check === 'Initial') {
    const initial_margin_requirement = openNotional
      .multipliedBy(IMF)
      .plus(openLoss)
      .plus(feeProvision);

    return initial_margin_requirement;
  }

  const MMF = mmf_factor.times(IMF);

  const maintenance_margin_requirement = openNotional
    .times(MMF)
    .plus(feeProvision);

  return maintenance_margin_requirement;
}

export function calc_margin_excluding_asset(
  margin_check: 'Initial' | 'Maintenance',
  asset: string, // e.g. 'SOL-USD-PERP'
  marketsSummaryStore: MarketsSummaryStoreView,
  marketsStore: MarketsStoreView,
  openOrdersStore: OpenOrdersStoreView,
  openPositionsStore: OpenPositionsStore,
  marginAccount: string,
  profileMaxSlippage: BigNumber | null,
  accountImfBase: BigNumber | null,
): BigNumber {
  // change this to calculate based on positions + estimated asset
  const mr_total = openPositionsStore.getAll().reduce((acc, pos) => {
    if (pos.market === asset) return acc;
    if (pos.account !== marginAccount) return acc;
    const mr = calculate_margin_requirement(
      margin_check,
      pos.market,
      pos,
      marketsSummaryStore,
      marketsStore,
      openOrdersStore,
      marginAccount,
      profileMaxSlippage,
      accountImfBase,
    );
    return acc.plus(mr);
  }, BigNumber(0));

  return mr_total;
}
