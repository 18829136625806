import BigNumber from 'bignumber.js';

import { AsyncResp, BaseReq, requestApi } from '#/api/fetch-api';

import { msToDate } from '#/utils/date';
import getQueryString from '#/utils/getQueryString';
import { DecimalString } from '#/utils/types';

import { UnixTimeMs } from './types';

import type { DecimalOrEmptyString } from '#/utils/types';

export interface RawVaultAccountSummary {
  /** Contract address of the vault */
  readonly address: string;
  /** Amount deposited on the vault by the user in USD. */
  readonly deposited_amount: DecimalString;
  /**
   * Unix timestamp of when the user joined the vault.
   * 0 if the user has never joined the vault.
   */
  readonly created_at: UnixTimeMs;
  /** Total P&L realized by the user in USD. */
  readonly total_pnl: DecimalString;
  /** Total ROI realized by the user in percentage, i.e. 10 means 10%. '' means not available */
  readonly total_roi: DecimalOrEmptyString;
  /** Amount of vault tokens owned by the user */
  readonly vtoken_amount: DecimalString;
}

export interface VaultAccountSummary
  extends Omit<
    RawVaultAccountSummary,
    | 'deposited_amount'
    | 'created_at'
    | 'total_pnl'
    | 'total_roi'
    | 'vtoken_amount'
  > {
  /** Amount deposited on the vault by the user in USD. */
  readonly deposited_amount: BigNumber;
  /** Unix timestamp of when the user joined the vault. */
  readonly created_at: Date | null;
  /** Total P&L realized by the user in USD. */
  readonly total_pnl: BigNumber;
  /** Lifetime ROI realized by the user in percentage, i.e. 10 means 10%. '' means not available */
  readonly total_roi: BigNumber | null;
  /** Amount of vault tokens owned by the user */
  readonly vtoken_amount: BigNumber;
}

export interface VaultAccountSummaryReq extends BaseReq {
  readonly address: string;
}

export interface RawVaultAccountSummaryResp {
  readonly results: readonly [RawVaultAccountSummary] | [];
}

export type VaultAccountSummaryResp = VaultAccountSummary | null;

export async function getVaultAccountSummary(
  req: VaultAccountSummaryReq,
): AsyncResp<VaultAccountSummaryResp> {
  const { signal } = req;

  const queryString = getQueryString([['address', req.address]]);

  const resp = await requestApi<RawVaultAccountSummaryResp>({
    signal,
    method: 'GET',
    url: `/vaults/account-summary${queryString}`,
  });

  if (!resp.ok) return resp;

  const vaultAccountSummary = resp.data.results.find(
    (v) => v.address === req.address,
  );

  if (vaultAccountSummary == null) {
    return { ...resp, data: null };
  }

  return {
    ...resp,
    data: processVaultAccountSummary(vaultAccountSummary),
  };
}

export interface VaultsAccountSummaryReq extends BaseReq {}

export interface RawVaultsAccountSummaryResp {
  readonly results: readonly RawVaultAccountSummary[];
}

export interface VaultsAccountSummaryResp {
  readonly results: readonly VaultAccountSummary[];
}

export async function getVaultsAccountSummary(
  req: VaultsAccountSummaryReq,
): AsyncResp<VaultsAccountSummaryResp> {
  const { signal } = req;

  const resp = await requestApi<RawVaultAccountSummaryResp>({
    signal,
    method: 'GET',
    url: `/vaults/account-summary`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: {
      ...resp.data,
      results: resp.data.results.map(processVaultAccountSummary),
    },
  };
}

function processVaultAccountSummary(
  vaultAccountSummary: RawVaultAccountSummary,
): VaultAccountSummary {
  return {
    address: vaultAccountSummary.address,
    deposited_amount: new BigNumber(vaultAccountSummary.deposited_amount),
    created_at:
      vaultAccountSummary.created_at > 0
        ? msToDate(vaultAccountSummary.created_at)
        : null,
    total_pnl: new BigNumber(vaultAccountSummary.total_pnl),
    total_roi:
      vaultAccountSummary.total_roi === ''
        ? null
        : new BigNumber(vaultAccountSummary.total_roi),
    vtoken_amount: new BigNumber(vaultAccountSummary.vtoken_amount),
  };
}
