import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import { AsyncResp } from '#/api/fetch-api';

import { logException } from '#/features/logging/logging';
import { useAccountChange } from '#/features/wallet/wallet-context';

import { isAbortError } from '#/utils/errors';
import { useInterval } from '#/utils/useInterval';

import { sleepWithJitter } from './sleep';

export interface PollingConnectionBaseState {
  readonly status: 'loading' | 'refetching' | 'error' | 'connected';
  readonly error: string | null;
}

interface Options<
  EntityResp,
  EntityLiveConnectionState extends PollingConnectionBaseState,
> {
  readonly setState: Dispatch<SetStateAction<EntityLiveConnectionState>>;
  readonly isReady?: boolean;
  readonly fetchAll: (signal: AbortSignal) => AsyncResp<EntityResp>;
  readonly setAll: (data: EntityResp) => void;
  readonly resetAll: () => void;
  readonly refetchInterval: number;
  readonly retryOnFail?: {
    /* Interval between retries in ms */
    readonly interval: number;
    /* Number of retries until failure */
    readonly steps: number;
  };
  /** Forces an immediate refetch when the key changes */
  readonly refetchKey?: string;
}

export default function usePollingConnection<
  EntityResp,
  EntityLiveConnectionState extends PollingConnectionBaseState,
>(options: Options<EntityResp, EntityLiveConnectionState>): void {
  const {
    setState,
    isReady = true,
    fetchAll,
    setAll,
    resetAll,
    refetchInterval,
    retryOnFail,
    refetchKey,
  } = options;

  const isInitialFetch = useRef(true);

  const getInitialRetryCount = useCallback(() => {
    return retryOnFail?.steps == null || retryOnFail.steps < 0
      ? 0
      : retryOnFail.steps;
  }, [retryOnFail?.steps]);
  const retryCountRef = useRef(getInitialRetryCount());

  const fetchIfReady = useCallback(() => {
    if (!isReady) {
      return;
    }

    const controller = new AbortController();
    const cleanUp = () => {
      isInitialFetch.current = true;
      controller.abort();
      retryCountRef.current = getInitialRetryCount();
    };
    const sync = async (signal: AbortSignal): Promise<void> => {
      setLoading();

      const fetchResp = await fetchAll(signal);
      if (signal.aborted) return;
      if (!fetchResp.ok) {
        if (retryCountRef.current > 0) {
          retryCountRef.current -= 1;
          await sleepWithJitter(retryOnFail?.interval ?? 0, [25, 100]);
          sync(signal).catch(onFetchError);
          return;
        }
        const message = 'Failed to fetch data';
        const error = new Error(message, { cause: fetchResp.error });
        throw error;
      }
      setAll(fetchResp.data);

      setConnected();
    };

    sync(controller.signal).catch(onFetchError);

    return () => {
      cleanUp();
      resetAll();
    };

    function onFetchError(cause: Error) {
      if (isAbortError(cause)) return;

      const message = 'Could not load data. Retrying…';
      const error = new Error(message, { cause });
      setFailed(message);
      logException(error);
    }

    function setLoading() {
      const status = isInitialFetch.current ? 'loading' : 'refetching';
      setState((state) => ({ ...state, status, error: '' }));
    }

    function setConnected() {
      isInitialFetch.current = false;
      setState((state) => ({ ...state, status: 'connected', error: '' }));
    }

    function setFailed(error: string) {
      cleanUp();
      resetAll();
      setState((state) => ({ ...state, status: 'error', error }));
    }
  }, [
    isReady,
    fetchAll,
    setAll,
    getInitialRetryCount,
    retryOnFail?.interval,
    resetAll,
    setState,
  ]);

  useInterval(fetchIfReady, isReady ? refetchInterval : null, true, refetchKey);
  useHandleAccountChange({ fetchIfReady, resetAll });
}

export function useHandleAccountChange({
  fetchIfReady,
  resetAll,
}: {
  fetchIfReady: () => void;
  resetAll: () => void;
}) {
  const accountChange = useAccountChange();

  const resetAllRef = useRef(resetAll);
  useEffect(() => {
    resetAllRef.current = resetAll;
  }, [resetAll]);

  const fetchIfReadyRef = useRef(fetchIfReady);
  useEffect(() => {
    fetchIfReadyRef.current = fetchIfReady;
  }, [fetchIfReady]);

  useEffect(() => {
    if (accountChange == null) return;
    switch (accountChange.kind) {
      case 'sign-out':
        resetAllRef.current();
        fetchIfReadyRef.current();
        break;
      case 'switch':
        fetchIfReadyRef.current();
        break;
      case 'sign-in':
        break;
      // no default
    }
  }, [accountChange]);
}
