import BigNumber from 'bignumber.js';

import { msToDate } from '#/utils/date';
import getQueryString from '#/utils/getQueryString';

import { AsyncResp, BaseReq, requestApi } from './fetch-api';
import { UnixTimeMs } from './types';

import type {
  DecimalOrEmptyString,
  DecimalString,
  Nullable,
} from '#/utils/types';

export interface RawMarketSummary {
  /** @example 'BTC-USD-PERP' */
  readonly symbol: string;
  /** @example '1234242234.23' */
  readonly volume_24h: DecimalString;
  /** @example '1234242234.23' */
  readonly total_volume: DecimalString;
  /** @example '40000.33' */
  readonly bid: DecimalOrEmptyString;
  /** @example '41000.33' */
  readonly ask: DecimalOrEmptyString;
  /** @example '40500.33' */
  readonly mark_price: DecimalOrEmptyString;
  /** @example '40600.33' */
  readonly last_traded_price: DecimalOrEmptyString;
  /**
   * Implied Volatility.
   * Only present when the market kind is OPTION and PERP_OPTION
   * Not present when the market kind is not OPTION or PERP_OPTION
   * Empty string when the value is not ready on cloud
   * @example '0.8'
   */
  readonly mark_iv?: DecimalOrEmptyString;
  /**
   * Delta.
   * Only present when the market kind is OPTION and PERP_OPTION
   * Not present when the market kind is not OPTION or PERP_OPTION
   * Empty string when the value is not ready on cloud
   * @example '0.5'
   */
  readonly delta?: DecimalOrEmptyString;
  readonly bid_iv?: DecimalOrEmptyString;
  readonly ask_iv?: DecimalOrEmptyString;
  readonly last_iv?: DecimalOrEmptyString;
  readonly future_funding_rate?: DecimalOrEmptyString;
  readonly price_change_rate_24h: DecimalOrEmptyString;
  readonly last_updated_at: UnixTimeMs;
  readonly created_at: UnixTimeMs;
  readonly open_interest: DecimalString;
  readonly underlying_price: DecimalOrEmptyString;
  readonly funding_rate: DecimalOrEmptyString;
  readonly greeks?: {
    readonly delta: DecimalString;
    readonly vega: DecimalString;
    readonly gamma: DecimalString;
  };
}

export interface MarketSummary
  extends Omit<
    RawMarketSummary,
    | 'volume_24h'
    | 'total_volume'
    | 'bid'
    | 'ask'
    | 'mark_price'
    | 'mark_iv'
    | 'delta'
    | 'last_traded_price'
    | 'price_change_rate_24h'
    | 'last_updated_at'
    | 'created_at'
    | 'open_interest'
    | 'underlying_price'
    | 'funding_rate'
    | 'bid_iv'
    | 'ask_iv'
    | 'last_iv'
    | 'future_funding_rate'
    | 'greeks'
  > {
  readonly volume_24h: BigNumber;
  readonly total_volume: BigNumber;
  readonly bid: BigNumber | null;
  readonly ask: BigNumber | null;
  readonly mark_price: BigNumber | null;
  readonly mark_iv: BigNumber | null;
  readonly delta: BigNumber | null;
  readonly last_traded_price: BigNumber | null;
  readonly price_change_rate_24h: BigNumber | null;
  readonly last_updated_at: Date;
  readonly created_at: Date;
  readonly open_interest: BigNumber;
  readonly underlying_price: BigNumber | null;
  readonly funding_rate: BigNumber | null;
  readonly bid_iv: BigNumber | null;
  readonly ask_iv: BigNumber | null;
  readonly last_iv: BigNumber | null;
  readonly future_funding_rate: BigNumber | null;
  readonly greeks: Nullable<{
    readonly delta: BigNumber;
    readonly vega: BigNumber;
    readonly gamma: BigNumber;
  }>;
}

export interface PerpetualMarketSummary extends MarketSummary {}

interface GetMarketsSummaryRespRaw {
  readonly results: readonly RawMarketSummary[];
}
export interface GetMarketsSummaryResp {
  readonly results: readonly MarketSummary[];
}

interface GetMarketsReq extends BaseReq {
  readonly market: string;
}

export async function getMarketsSummary(
  req: GetMarketsReq,
): AsyncResp<GetMarketsSummaryResp> {
  const { signal, market } = req;

  const query = getQueryString([['market', market]]);

  const resp = await requestApi<GetMarketsSummaryRespRaw>({
    signal,
    method: 'GET',
    url: `/markets/summary${query}`,
  });

  if (!resp.ok) {
    return resp;
  }

  return {
    ...resp,
    data: {
      results: resp.data.results.map(processMarketSummary),
    },
  };
}

export function processMarketSummary(summary: RawMarketSummary): MarketSummary {
  return {
    ...summary,
    volume_24h: new BigNumber(summary.volume_24h),
    total_volume: new BigNumber(summary.total_volume),
    bid: summary.bid !== '' ? new BigNumber(summary.bid) : null,
    ask: summary.ask !== '' ? new BigNumber(summary.ask) : null,
    mark_price:
      summary.mark_price !== '' ? new BigNumber(summary.mark_price) : null,
    mark_iv:
      summary.mark_iv !== undefined && summary.mark_iv !== ''
        ? new BigNumber(summary.mark_iv)
        : null,
    delta:
      summary.delta !== undefined && summary.delta !== ''
        ? new BigNumber(summary.delta)
        : null,
    last_traded_price:
      summary.last_traded_price !== ''
        ? new BigNumber(summary.last_traded_price)
        : null,
    price_change_rate_24h:
      summary.price_change_rate_24h !== ''
        ? new BigNumber(summary.price_change_rate_24h)
        : null,
    last_updated_at: msToDate(summary.last_updated_at),
    created_at: msToDate(summary.created_at),
    open_interest: new BigNumber(summary.open_interest),
    underlying_price:
      summary.underlying_price !== ''
        ? new BigNumber(summary.underlying_price)
        : null,
    funding_rate:
      summary.funding_rate !== '' ? new BigNumber(summary.funding_rate) : null,
    bid_iv:
      summary.bid_iv != null && summary.bid_iv !== ''
        ? new BigNumber(summary.bid_iv)
        : null,
    ask_iv:
      summary.ask_iv != null && summary.ask_iv !== ''
        ? new BigNumber(summary.ask_iv)
        : null,
    last_iv:
      summary.last_iv != null && summary.last_iv !== ''
        ? new BigNumber(summary.last_iv)
        : null,
    future_funding_rate:
      summary.future_funding_rate != null && summary.future_funding_rate !== ''
        ? new BigNumber(summary.future_funding_rate)
        : null,
    greeks:
      summary.greeks != null
        ? {
            delta: new BigNumber(summary.greeks.delta),
            vega: new BigNumber(summary.greeks.vega),
            gamma: new BigNumber(summary.greeks.gamma),
          }
        : null,
  };
}
