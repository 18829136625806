/* eslint-disable no-else-return */
import BigNumber from 'bignumber.js';

import { OpenPosition } from '#/api/positions';

import { MarketsSummaryStoreView } from '#/features/perpetuals/market-info/markets-summary-context';
import { OpenOrdersStoreView } from '#/features/perpetuals/trade-overview/open-orders-context';

import { Maybe } from '#/utils/types';

import total_order_size from './total_order_size';

/**
 * Adapted from
 * @see https://github.com/tradeparadigm/mono/blob/release/paradex-1.29.3/api/paradex/prototype/asset/synthetic_assets/synthetic_asset.py#L189
 */
export default function calculate_fee_provision(
  asset: string, // e.g. 'SOL-USD-PERP'
  account: string,
  margin_check: 'Initial' | 'Maintenance',
  assetOpenPosition: Maybe<Pick<OpenPosition, 'size'>>,
  marketsSummaryStore: MarketsSummaryStoreView,
  openOrdersStore: OpenOrdersStoreView,
) {
  const fee_rate = BigNumber.max(0.0003, -0.00005);
  const marketSummary = marketsSummaryStore.getMarketSummary(asset);
  const position = assetOpenPosition;

  if (marketSummary == null) {
    throw new Error(
      `calculate_fee_provision: asset='${asset}' Market Summary is not available`,
    );
  }
  if (marketSummary.mark_price == null) {
    throw new Error(
      `calculate_fee_provision: asset='${asset}' 'mark_price' is not available`,
    );
  }

  const positionSize = position?.size ?? BigNumber(0);
  const open_quantity =
    margin_check === 'Maintenance'
      ? positionSize.absoluteValue()
      : positionSize
          .absoluteValue()
          .plus(total_order_size(asset, account, 'BUY', openOrdersStore))
          .plus(total_order_size(asset, account, 'SELL', openOrdersStore));

  const notional = open_quantity.multipliedBy(marketSummary.mark_price);
  const trading_fee = fee_rate.multipliedBy(notional);

  return trading_fee;
}
