import BigNumber from 'bignumber.js';

import { AsyncResp, BaseReq, requestApi } from '#/api/fetch-api';

import { StarknetSignature } from '#/features/wallets/paraclear/wallet';

import { msToDate } from '#/utils/date';
import getQueryString from '#/utils/getQueryString';

import { UnixTimeMs } from './types';

interface PostVaultReq extends BaseReq {
  readonly parentAccount: `0x${string}`;
  readonly vaultOperatorAccount: string;
  readonly vaultOperatorAccountDerivationPath: string;
  readonly vaultOperatorPublicKey: string;
  readonly vaultOperatorSignature: StarknetSignature;
  readonly lockupPeriod: number;
  readonly profitShare: number;
  readonly maxTvl: number | null;
  readonly name: string;
  readonly description: string;
}

export type PostVaultResp = null;

export async function postVaults(req: PostVaultReq): AsyncResp<PostVaultResp> {
  const {
    signal,
    parentAccount,
    vaultOperatorAccount,
    vaultOperatorAccountDerivationPath,
    vaultOperatorSignature,
    lockupPeriod,
    profitShare,
    maxTvl,
    name,
    description,
    vaultOperatorPublicKey,
  } = req;

  const resp = await requestApi<PostVaultResp>({
    signal,
    method: 'POST',
    url: `/vaults`,
    headers: {
      'PARADEX-PARENT-ACCOUNT': `prdx:${parentAccount}`,
      'PARADEX-STARKNET-ACCOUNT': vaultOperatorAccount,
      'PARADEX-STARKNET-ACCOUNT-DERIVATION-PATH':
        vaultOperatorAccountDerivationPath,
      'PARADEX-STARKNET-SIGNATURE': JSON.stringify([
        vaultOperatorSignature.r.toString(),
        vaultOperatorSignature.s.toString(),
      ]),
    },
    body: {
      deposit_tx_signature: '0x1_not_implemented', // WIP: not implemented on the Cloud
      lockup_period: lockupPeriod,
      profit_share: profitShare,
      max_tvl: maxTvl,
      name,
      public_key: vaultOperatorPublicKey,
      description,
    },
  });

  return resp;
}

export type VaultStatus = 'INITIALIZING' | 'ACTIVE' | 'CLOSED' | 'FAILED';

export type VaultKind = 'protocol' | 'user' | 'vtf';

export interface RawVault {
  readonly kind: VaultKind;
  readonly address: string;
  readonly description: string;
  readonly operator_account: string;
  readonly owner_account: string;
  readonly token_address: string;
  readonly name: string;
  readonly created_at: UnixTimeMs;
  readonly last_updated_at: UnixTimeMs;
  readonly status: VaultStatus;
  readonly profit_share: number;
  readonly lockup_period: number;
  readonly max_tvl: number;
  /**
   * According to discussions, this may not be present when
   * fetching list of vaults, only when fetching a single vault.
   */
  readonly strategies?: readonly RawVaultStrategy[];
}

export interface RawVaultStrategy {
  readonly address: string;
  readonly name: string;
}

export interface Vault
  extends Omit<
    RawVault,
    | 'created_at'
    | 'last_updated_at'
    | 'profit_share'
    | 'lockup_period'
    | 'max_tvl'
    | 'strategies'
  > {
  readonly created_at: Date;
  readonly last_updated_at: Date;
  readonly profit_share: BigNumber;
  readonly lockup_period: BigNumber;
  readonly max_tvl: BigNumber;
  readonly strategies?: readonly VaultStrategy[];
}

export interface VaultStrategy extends RawVaultStrategy {}

interface VaultsReq extends BaseReq {}

export interface RawVaultsResp {
  readonly results: readonly RawVault[];
}

export interface VaultsResp {
  readonly results: readonly Vault[];
}

export async function getVaults(req: VaultsReq): AsyncResp<VaultsResp> {
  const { signal } = req;

  const resp = await requestApi<RawVaultsResp>({
    signal,
    method: 'GET',
    url: `/vaults`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: {
      ...resp.data,
      results: resp.data.results.map(processVault),
    },
  };
}

export interface VaultReq extends BaseReq {
  readonly address: string;
}

export interface RawVaultResp {
  readonly results: readonly [RawVault] | [];
}

export type VaultResp = Vault | null;

export async function getVault(req: VaultReq): AsyncResp<VaultResp> {
  const { signal } = req;

  const queryString = getQueryString([['address', req.address]]);

  const resp = await requestApi<RawVaultResp>({
    signal,
    method: 'GET',
    url: `/vaults${queryString}`,
  });

  if (!resp.ok) return resp;

  const vault = resp.data.results.find((v) => v.address === req.address);

  if (vault == null) {
    return { ...resp, data: null };
  }

  return {
    ...resp,
    data: processVault(vault),
  };
}

export function processVault(vault: RawVault): Vault {
  const isVtfVault =
    typeof "0x18d0d42c220a07dda1588cb9ff67dfe5a1c0e092a2e8e4284af00c3556c4f95, 0x229479cb5bc69c46951023986fff8a6d757e29fc6822694440a91617c347955, 0x64f34c5a4e7cda3d237a8a71fa0c1aa4f7f7c25e20c67f43a0f60977ba5f4e8, 0x24226b6de55ae8007d09f046f435b059709ebe1a5b50bc7665920e620289b76, 0x3740189b04563c38e06397f6f5414e38e5cebebc684b33ac4fcc844037364d2, 0x6e67391f1ee201410776f255414754307c37f690e4a5666d639a1648d63f65c,0x7413d654a75bfcbdf28725f71c45f11d968c02d272cdbc2a7914de9f2e10b5a,0x5222a6fc5fe1d94410d78851c4f6f257fe8e17595bf641d06a32d03ce1f0b27,0x49ac81c80f5ea12860a480e7c4747e53971b677ddc2d16f70ec144510cabdcd,0x10d14b2e4ca711857678191134a7eda184f77eb1409d20cf87944465ff47aab,0x43a9f9b6cffaaa31690f048c9d12bbb43857a2afeebb7716045e694fb6a475f,0x4640073dc3176bbac55f5c63ca7009dbe494530fcdc5703e91ebf81a9238abe" === 'string' &&
    vault.address !== '' &&
    "0x18d0d42c220a07dda1588cb9ff67dfe5a1c0e092a2e8e4284af00c3556c4f95, 0x229479cb5bc69c46951023986fff8a6d757e29fc6822694440a91617c347955, 0x64f34c5a4e7cda3d237a8a71fa0c1aa4f7f7c25e20c67f43a0f60977ba5f4e8, 0x24226b6de55ae8007d09f046f435b059709ebe1a5b50bc7665920e620289b76, 0x3740189b04563c38e06397f6f5414e38e5cebebc684b33ac4fcc844037364d2, 0x6e67391f1ee201410776f255414754307c37f690e4a5666d639a1648d63f65c,0x7413d654a75bfcbdf28725f71c45f11d968c02d272cdbc2a7914de9f2e10b5a,0x5222a6fc5fe1d94410d78851c4f6f257fe8e17595bf641d06a32d03ce1f0b27,0x49ac81c80f5ea12860a480e7c4747e53971b677ddc2d16f70ec144510cabdcd,0x10d14b2e4ca711857678191134a7eda184f77eb1409d20cf87944465ff47aab,0x43a9f9b6cffaaa31690f048c9d12bbb43857a2afeebb7716045e694fb6a475f,0x4640073dc3176bbac55f5c63ca7009dbe494530fcdc5703e91ebf81a9238abe".includes(vault.address);
  return {
    ...vault,
    created_at: msToDate(vault.created_at),
    last_updated_at: msToDate(vault.last_updated_at),
    profit_share: new BigNumber(vault.profit_share),
    lockup_period: new BigNumber(vault.lockup_period),
    max_tvl: new BigNumber(vault.max_tvl),
    kind: isVtfVault ? 'vtf' : vault.kind,
  };
}
