import {
  bridgeSupportsChain,
  findBridge,
  getAllBridges,
  supportsChainFilter,
} from '#/features/bridging/bridges';
import { findChain } from '#/features/bridging/chains';

export interface ChainUpdateState {
  readonly bridge: string;
  readonly chain: string | null;
}

/**
 * Updates the chain and bridge based on the selected chain.
 * If the selected chain is not supported by the current bridge,
 * the bridge will be updated to the first bridge that supports
 * the selected chain.
 */
export const chainUpdate =
  (chainId: string) =>
  <T extends ChainUpdateState>(state: T): T => {
    const nextChain = findChain(chainId);

    if (nextChain == null) {
      throw new Error(`Invalid chain: ${chainId}`);
    }

    const currentBridge = findBridge(state.bridge);

    if (currentBridge == null) {
      throw new Error(`Invalid bridge: ${state.bridge}`);
    }

    if (bridgeSupportsChain(currentBridge, nextChain.id)) {
      return {
        ...state,
        chain: nextChain.id,
      };
    }

    const nextBridge = getAllBridges().find(supportsChainFilter(nextChain.id));

    if (nextBridge == null) {
      throw new Error(`No bridge supports chain: ${nextChain.id}`);
    }

    return {
      ...state,
      chain: nextChain.id,
      bridge: nextBridge.config.id,
    };
  };

export interface BridgeUpdateState {
  readonly chain: string | null;
}

/**
 * Updates the bridge based on the selected bridge.
 * If the selected bridge does not support the selected chain,
 * the bridge will not update as it would be an invalid state.
 * If the chain is not set, allow setting the bridge anyway.
 */
export const bridgeUpdate =
  (bridgeId: string) =>
  <T extends BridgeUpdateState>(state: T): T => {
    const nextBridge = findBridge(bridgeId);

    if (nextBridge == null) {
      throw new Error(`Invalid bridge: ${bridgeId}`);
    }

    if (state.chain == null) {
      return {
        ...state,
        bridge: nextBridge.config.id,
      };
    }

    const currentChain = findChain(state.chain);

    if (currentChain == null) {
      throw new Error(`Invalid chain: ${state.chain}`);
    }

    if (bridgeSupportsChain(nextBridge, currentChain.id)) {
      return {
        ...state,
        bridge: nextBridge.config.id,
      };
    }

    return state;
  };
