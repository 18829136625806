import BigNumber from 'bignumber.js';

import { PointsHistoryEntry } from '#/api/points';

import { formatPercent } from '#/utils/percent';
import { FormattedValue, Nullable } from '#/utils/types';
import { PollingConnectionBaseState } from '#/utils/usePollingConnection';

export interface Store extends PollingConnectionBaseState {
  readonly data: Nullable<PointsHistoryEntry[]>;
}

export interface PointsHistoryView {
  readonly isLoading: boolean;
  readonly error: string | null;
  readonly data: PointsHistoryEntryView[];
  readonly lastWeekTotalPoints: string;
}

export interface PointsHistoryEntryView {
  readonly season: string;
  readonly week: string;
  readonly points: {
    readonly total: FormattedValue<BigNumber>;
    readonly total_share: FormattedValue<BigNumber>;
  };
}

export function createView(state: Store): PointsHistoryView {
  const { status, error, data } = state;
  if (data == null) {
    return {
      isLoading: false,
      error: null,
      data: [],
      lastWeekTotalPoints: '0',
    };
  }

  return {
    isLoading: status === 'loading',
    error: error === '' ? null : error,
    data: data.map(entryView),
    lastWeekTotalPoints: data[data.length - 1]?.points.total.toFormat(0) ?? '0',
  };
}

function entryView(entry: PointsHistoryEntry): PointsHistoryEntryView {
  const { season, week, points } = entry;
  return {
    season,
    week: week.toString(),
    points: {
      total: {
        value: points.total,
        formatted: points.total.toFormat(0),
      },
      total_share: {
        value: points.total_share,
        formatted: formatPercent(points.total_share),
      },
    },
  };
}
