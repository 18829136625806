import { adapter } from '#/features/balance-chain/state';

import type { BalanceChain } from '#/api/balances-chain';
import type { BalancesChainState } from '#/features/balance-chain/state';

const selectors = adapter.getSelectors();

export interface BalancesChainStore {
  readonly status: BalancesChainState['status'];
  readonly error: BalancesChainState['error'];
  readonly getBalance: (token: BalanceChain['token']) => BalanceChain | null;
  readonly getAllBalances: () => BalanceChain[];
}

export function createStore(store: BalancesChainState): BalancesChainStore {
  return {
    status: store.status,
    error: store.error,
    getAllBalances: () => selectors.selectAll(store.balances),
    getBalance: (token) => selectors.selectById(store.balances, token) ?? null,
  };
}
