import BigNumber from 'bignumber.js';
import { Address, erc20Abi } from 'viem';
import * as WagmiActions from 'wagmi/actions';

import type { Config as WagmiConfig } from 'wagmi';

export type EthereumAddress = Address;
export type EthereumSignature = string;

interface Account {
  address: EthereumAddress;
}

export interface EthereumWallet {
  readContract: typeof WagmiActions.readContract;
  simulateContract: typeof WagmiActions.simulateContract;
  getIsConnected(wagmiConfig: WagmiConfig): boolean;
  getAddress(wagmiConfig: WagmiConfig): Account['address'];
  getErc20Balance(
    wagmiConfig: WagmiConfig,
    erc20TokenAddress: EthereumAddress,
  ): Promise<BigNumber>;
  writeContract: typeof WagmiActions.writeContract;
  waitForTransaction: typeof WagmiActions.waitForTransactionReceipt;
  signMessage: typeof WagmiActions.signMessage;
  signTypedData: typeof WagmiActions.signTypedData;
  switchChainId(wagmiConfig: WagmiConfig, chainId: number): Promise<void>;
  disconnect: typeof WagmiActions.disconnect;
}

function getIsConnected(wagmiConfig: WagmiConfig): boolean {
  const { isConnected } = WagmiActions.getAccount(wagmiConfig);
  return isConnected;
}

function getAddress(wagmiConfig: WagmiConfig): Account['address'] {
  const { address, isConnected } = WagmiActions.getAccount(wagmiConfig);

  if (address == null) {
    throw new Error(`EthereumWallet is not initialized`);
  }

  if (!isConnected) {
    throw new Error(`EthereumWallet is not connected`);
  }

  return address;
}

async function switchChainId(wagmiConfig: WagmiConfig, chainId: number) {
  const { chain } = WagmiActions.getAccount(wagmiConfig);
  if (chain?.id === chainId) return;

  await WagmiActions.switchChain(wagmiConfig, { chainId });
}

async function getErc20Balance(
  wagmiConfig: WagmiConfig,
  erc20TokenAddress: EthereumAddress,
): Promise<BigNumber> {
  const owner = getAddress(wagmiConfig);
  const result = await WagmiActions.readContract(wagmiConfig, {
    address: erc20TokenAddress,
    abi: erc20Abi,
    functionName: 'balanceOf',
    args: [owner],
  });
  const balance = BigNumber(result.toString());
  if (!balance.isFinite()) {
    throw new TypeError('Result of ERC20 Balance is not a numeric value');
  }

  return balance;
}

const wallet: EthereumWallet = {
  readContract: WagmiActions.readContract,
  // WIP: given we plan to use `simulateContract` every time we call `writeContract`
  //        it may make sense to abstract `simulateContract` call and move it into `EthereumWallet.writeContract()` call
  //        i.e. I will probably be adding `EthereumWallet.writeContract()` function that will call both `WagmiActions.simulateContract` and `WagmiActions.writeContract`
  simulateContract: WagmiActions.simulateContract,
  getIsConnected,
  getAddress,
  getErc20Balance,
  writeContract: WagmiActions.writeContract,
  waitForTransaction: WagmiActions.waitForTransaction,
  signMessage: WagmiActions.signMessage,
  signTypedData: WagmiActions.signTypedData,
  switchChainId,
  disconnect: WagmiActions.disconnect,
};

export default wallet;
