import { Address } from 'viem';

export type AccountType = 'ethereum' | 'starknet' | 'paradex';

export interface BaseAccount {
  readonly address: Address;
  readonly type: AccountType;
}

const MultichainPrefix: Record<BaseAccount['type'], string> = {
  ethereum: 'eth',
  starknet: 'strk',
  paradex: 'prdx',
};

export function getMultichainAddress(account: BaseAccount): string {
  return `${MultichainPrefix[account.type]}:${account.address}`;
}

export function getBaseAccountTypeLabel(account: BaseAccount): string {
  switch (account.type) {
    case 'ethereum':
      return 'Ethereum';
    case 'starknet':
      return 'Starknet';
    case 'paradex':
      return 'Paradex';
    default:
      throw new Error('Invalid account type');
  }
}
