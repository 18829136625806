/* eslint-disable prefer-template, require-unicode-regexp, @typescript-eslint/strict-boolean-expressions */
import * as u from '@noble/curves/abstract/utils';

import type { Hex } from '@noble/curves/abstract/utils';

/**
 * Due to the current version of `starknet.js` does not export `normPrivKey` function.
 * Copied as-is from @source https://github.com/paulmillr/scure-starknet/blob/1.0.0/index.ts#L69-L71
 *
 * @returns a normalized private key
 */
export function normPrivKey(privKey: Hex): string {
  return u.bytesToHex(ensureBytes(privKey)).padStart(64, '0');
}

function ensureBytes(hex: Hex): Uint8Array {
  return u.ensureBytes('', typeof hex === 'string' ? hex0xToBytes(hex) : hex);
}

function hex0xToBytes(hex: string): Uint8Array {
  if (typeof hex === 'string') {
    hex = strip0x(hex); // allow 0x prefix
    if (hex.length & 1) hex = '0' + hex; // allow unpadded hex
  }
  return u.hexToBytes(hex);
}

function strip0x(hex: string) {
  return hex.replace(/^0x/i, '');
}
