import { AsyncResp, BaseReq, requestApi } from '#/api/fetch-api';

import { msToDate } from '#/utils/date';

import { UnixTimeMs } from './types';

export interface RawLayerswapSwap {
  readonly id: string;
  readonly created_at: UnixTimeMs;
  readonly to_address: string;
  readonly call_data: string;
}

export interface LayerswapSwap {
  readonly id: string;
  readonly created_at: Date;
  readonly to_address: string;
  readonly call_data: string;
}

export interface LayerswapSwapReq extends BaseReq {
  readonly amount: string;
  readonly destination_chain: string;
  readonly source_chain: string;
  readonly source_address: string;
  readonly destination_address: string;
}

export async function createLayerswapSwap(
  req: LayerswapSwapReq,
): AsyncResp<LayerswapSwap> {
  const { signal } = req;

  const resp = await requestApi<RawLayerswapSwap>({
    signal,
    method: 'POST',
    url: `/bridges/layerswap/swaps`,
    body: req,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: processLayerswapSwap(resp.data),
  };
}

function processLayerswapSwap(data: RawLayerswapSwap): LayerswapSwap {
  return {
    ...data,
    created_at: msToDate(data.created_at),
  };
}
