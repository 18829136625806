import { useCallback } from 'react';
import * as Starknet from 'starknet';

import { useSystemConfig } from '#/features/system/system-config-context';

import { logEvent } from './logging/logging';

export function useBuildParadexTxUrl() {
  const config = useSystemConfig();
  return useCallback(
    (txHash: string) => paradexTxUrl(config.starknet.explorerUrl, txHash),
    [config],
  );
}

export function useDebugParadexTx() {
  const config = useSystemConfig();
  return useCallback(
    (txDescription: string, txHash: string) => {
      debugParadexTx(txDescription, config.starknet.explorerUrl, txHash);
    },
    [config],
  );
}

export function debugParadexTx(
  txDescription: string,
  explorerUrl: string,
  txHash: string,
) {
  const txUrl = paradexTxUrl(explorerUrl, txHash);
  logEvent('Paradex Transaction', { txDescription, txUrl });
}

/**
 * Utility function to check if contract exists in the Paradex Chain.
 * @returns Returns the contract class hash if the contract is deployed.
 * @throws {Error} Throws an error if the contract is not deployed.
 */
export async function isContractDeployed(
  provider: Starknet.RpcProvider,
  contractAddress: string,
) {
  return provider.getClassHashAt(contractAddress);
}

export function useBuildParadexContractUrl() {
  const config = useSystemConfig();
  return useCallback(
    (contractAddress: string) =>
      paradexContractUrl(config.starknet.explorerUrl, contractAddress),
    [config],
  );
}

function paradexTxUrl(explorerUrl: string, txHash: string): string {
  return `${explorerUrl}tx/${txHash}`;
}

function paradexContractUrl(
  explorerUrl: string,
  contractAddress: string,
): string {
  return `${explorerUrl}contract/${contractAddress}`;
}
