import BigNumber from 'bignumber.js';
import * as Starknet from 'starknet';

import { logEvent } from '#/features/logging/logging';
import { BridgedToken } from '#/features/paraclear';
import { BaseAccount } from '#/features/wallet/common/base-account';
import {
  getDerivationPath,
  getDerivationPathIndex,
  isKnownDerivationPathFormat,
} from '#/features/wallets/paraclear/derivation';
import getAccContractAddressAndCallData from '#/features/wallets/paraclear/utils/getAccContractAddressAndCallData';
import intNoise from '#/features/wallets/paraclear/utils/intNoise';

import createTopic from '#/utils/createTopic';
import { sortDateAscending } from '#/utils/date';
import toIntString from '#/utils/toIntString';

import {
  getStarknetKeypairFromSignature,
  getSubAccountStarknetKeypair,
} from './key-derivation';
import { ParaclearProvider } from './provider';

export type Address = `0x${string}`;
export type StarknetSignature = Starknet.WeierstrassSignatureType;

export type TypedData = Starknet.TypedData;

type Account = MainAccount | SubAccount;

interface MainAccount {
  readonly address: Address;
  /** Main account derived different from sub-accounts, thus derivation path is not available */
  readonly derivation: null;
  readonly level: 'main-account';
  readonly publicKey: string;
  readonly privateKey: string;
  readonly baseAccount: {
    readonly type: 'ethereum' | 'starknet';
    readonly address: Address;
  };
}

interface Derivation {
  /** @example "m/44'/9004'/0'/0/23" */
  readonly path: string;
  /**
   * @example the 23 given the path "m/44'/9004'/0'/0/23"
   * @example the null given derivation path is of unknown format
   */
  readonly index: number | null;
}

interface SubAccount {
  readonly address: Address;
  readonly derivation: Derivation;
  readonly level: 'sub-account';
  readonly publicKey: string;
  readonly privateKey: string;
  readonly baseAccount: {
    readonly type: 'paradex';
    readonly address: Address;
  };
}

/**
 * Public interface for an account that
 * never exposes the private key.
 */
export interface ParadexAccount {
  readonly address: Address;
  readonly level: 'main-account' | 'sub-account';
  readonly derivation: Derivation | null;
  readonly publicKey: string;
  readonly baseAccount: BaseAccount;
  readonly isConnected: boolean;
}

interface State {
  account: null | {
    /**
     * Index of the active account in the `account` array.
     * @example 0, 1, 2, 3, … where the idx=0 is the main account
     */
    activeIdx: number;
    list: [MainAccount, ...SubAccount[]];
  };
}

interface ParaclearWallet {
  getMainAccount(): ParadexAccount;
  onAccountsChange(
    callback: (event: {
      readonly accounts: ReadonlyArray<ParadexAccount>;
    }) => void,
  ): () => void;
  upsertSubAccount(newAccount: SubAccount): void;
  initializeMainAccount(
    baseAccount: BaseAccount,
    baseSignature: string,
    accountClassHash: string,
    accountProxyClassHash: string,
  ): Promise<void>;
  initializeNextSubAccount(
    accountClassHash: string,
    accountProxyClassHash: string,
  ): Promise<ParadexAccount>;
  generateNextSubAccount(
    accountClassHash: string,
    accountProxyClassHash: string,
  ): Promise<SubAccount>;
  recoverSubAccounts(
    subAccounts: ReadonlyArray<{
      readonly account: string;
      readonly derivation_path: string | null;
      readonly created_at: Date;
    }>,
    accountClassHash: string,
    accountProxyClassHash: string,
  ): Promise<void>;
  switchActiveAccount(address: Address): void;
  getActiveAccount(): ParadexAccount;
  getAddress(): Account['address'];
  /**
   * @returns Ethereum address used to derive the Starknet address.
   */
  getBaseAccount(): Account['baseAccount'];
  getPublicKey(): Account['publicKey'];
  getDerivation(): Account['derivation'];
  /**
   * Get the private key of the account. This is intended to be used for
   * displaying the private key to the user. The caller should avoid storing
   * the private key in memory as much as possible.
   *
   * @returns Private key in hex string format, with a leading '0x'.
   */
  getPrivateKey(): Account['privateKey'];
  signTypedData(typedData: TypedData): StarknetSignature;
  signTypedDataAs(address: Address, typedData: TypedData): StarknetSignature;
  callContract(
    provider: ParaclearProvider,
    txnInvocation: Starknet.Call | Starknet.Call[],
    blockIdentifier?: Starknet.BlockIdentifier,
  ): Promise<Starknet.CallContractResponse>;
  simulateTransaction(
    provider: ParaclearProvider,
    txnInvocation: Starknet.Call | Starknet.Call[],
  ): Promise<Starknet.SimulateTransactionResponse>;
  executeTransaction(
    provider: ParaclearProvider,
    txnInvocation: Starknet.Call | Starknet.Call[],
  ): Promise<Starknet.InvokeFunctionResponse>;
  executeTransactionAs(
    provider: ParaclearProvider,
    txnInvocation: Starknet.Call | Starknet.Call[],
    address: Address,
  ): Promise<Starknet.InvokeFunctionResponse>;
  getErc20Allowance(
    provider: ParaclearProvider,
    erc20TokenAddress: string,
    spenderAddress: string,
  ): Promise<BigNumber>;
  getL2BridgeVersion(
    provider: ParaclearProvider,
    token: BridgedToken,
  ): Promise<1 | 2>;
  reset(): void;
}

const INITIAL_STATE: State = {
  account: null,
};

let state: State = { ...INITIAL_STATE };

const wallet: ParaclearWallet = {
  getMainAccount,
  onAccountsChange,
  initializeMainAccount,
  initializeNextSubAccount,
  upsertSubAccount,
  // WIP: to be refactored to do not expose private key outside of this module
  generateNextSubAccount: _generateNextSubAccount,
  recoverSubAccounts,
  switchActiveAccount,
  getActiveAccount,
  getAddress,
  getBaseAccount,
  getPublicKey,
  getPrivateKey,
  getDerivation,
  signTypedData,
  signTypedDataAs,
  callContract,
  simulateTransaction,
  executeTransaction,
  executeTransactionAs,
  getErc20Allowance,
  getL2BridgeVersion,
  reset,
};

function isMainAccountBaseAccount(
  baseAccount: BaseAccount,
): baseAccount is MainAccount['baseAccount'] {
  return baseAccount.type !== 'paradex';
}

async function initializeMainAccount(
  baseAccount: BaseAccount,
  signature: string,
  accountClassHash: string,
  accountProxyClassHash: string,
) {
  if (!isMainAccountBaseAccount(baseAccount)) {
    throw new Error(
      `Initialize Main Account can not be called from Sub-Account (baseAccount.type='${baseAccount.type}')`,
    );
  }

  const [privateKey, publicKey] = await getStarknetKeypairFromSignature(
    signature,
    baseAccount.type,
  );
  const { address } = getAccContractAddressAndCallData(
    accountClassHash,
    accountProxyClassHash,
    `0x${publicKey}`,
  );

  try {
    Starknet.validateAndParseAddress(address);
  } catch (err) {
    throw new Error(
      `initializeAccount(): signer address is invalid: '${address}'`,
    );
  }

  const mainAccount: MainAccount = {
    address: address as `0x${string}`,
    derivation: null,
    level: 'main-account',
    baseAccount,
    privateKey,
    publicKey,
  };

  state.account = {
    activeIdx: 0,
    list: [mainAccount],
  };
  publishAccountsChange();
}

function getMainAccount(): ParadexAccount {
  if (state.account == null) {
    throw new Error(
      `ParaclearWallet should be initialized before calling 'getMainAccount()'`,
    );
  }

  return accountView(state.account.list[0]);
}

function getAccounts(): ReadonlyArray<ParadexAccount> {
  if (state.account == null) {
    throw new Error(
      `ParaclearWallet should be initialized before calling 'getAccounts()'`,
    );
  }

  return state.account.list.map(accountView);
}

function _getAccount(address: Address): MainAccount | SubAccount {
  if (state.account == null) {
    throw new Error(
      `ParaclearWallet should be initialized before calling 'getAccount()'`,
    );
  }

  const account = state.account.list.find((acc) => acc.address === address);
  if (account == null) {
    throw new Error(`Account with address='${address}' not found`);
  }
  return account;
}

function accountView(account: Account): ParadexAccount {
  const activeAccount = _getActiveAccount();

  return {
    address: account.address,
    level: account.level,
    derivation: account.derivation,
    publicKey: account.publicKey,
    baseAccount: account.baseAccount,
    isConnected: account.address === activeAccount.address,
  };
}

function switchActiveAccount(address: Address) {
  if (state.account == null) {
    throw new Error(
      `ParaclearWallet should be initialized before switching active account`,
    );
  }

  const nextAccountIdx = state.account.list.findIndex(
    (account) => account.address === address,
  );

  if (nextAccountIdx === -1) {
    throw new Error(
      `Failed to switch active account to address='${address}'. ` +
        `Account with such address not found in the account ` +
        `list='${JSON.stringify(state.account.list)}'`,
    );
  }

  state.account.activeIdx = nextAccountIdx;
  publishAccountsChange();
}

type AccountsChangeEvent = { readonly accounts: ReadonlyArray<ParadexAccount> };

const accountsChangeTopic =
  createTopic<AccountsChangeEvent>('paradex-accounts');

function onAccountsChange(callback: (event: AccountsChangeEvent) => void) {
  return accountsChangeTopic.subscribe(callback);
}

function publishAccountsChange() {
  try {
    const accounts = getAccounts();
    accountsChangeTopic.publish({ accounts });
  } catch (err) {
    accountsChangeTopic.publish({ accounts: [] });
  }
}

/**
 * Recovers sub-accounts by initializing as many accounts as there are in
 * the provided list. Each account is initialized with the next derivation
 * index and matched with the provided list. If a recovered account is not
 * found in the provided list, an error is thrown. Individual matching is
 * done becuase the order of the accounts in the list is not guaranteed.
 *
 * @param subAccounts - A list of sub-accounts to be recovered.
 * @param accountClassHash - The hash of the account class.
 * @param accountProxyClassHash - The hash of the account proxy class.
 * @throws If the main account is not initialized
 * @throws If a recovered sub-account is not known in the provided list.
 */
async function recoverSubAccounts(
  subAccounts: ReadonlyArray<{
    readonly account: string;
    readonly derivation_path: string | null;
    readonly created_at: Date;
  }>,
  accountClassHash: string,
  accountProxyClassHash: string,
) {
  if (state.account?.list[0] == null) {
    const message = `Main account must be initialized before initializing sub accounts`;
    throw new Error(message);
  }

  logEvent(`Sub-accounts recovery started`, {
    subAccounts: subAccounts.map(({ account, derivation_path }) => ({
      account,
      derivation_path,
    })),
    count: subAccounts.length,
  });

  /* Assert no duplicate addresses exist */
  const uniqueAddresses = new Set(
    subAccounts.map(({ account }) => BigInt(account)),
  );
  const hasDuplicateAddresses = uniqueAddresses.size !== subAccounts.length;
  if (hasDuplicateAddresses) {
    throw new Error(`Duplicate address found during sub-account recovery.`);
  }

  /* Only automatically recover if derivation path is known */
  const subAccountsWithDerivationPath = subAccounts.filter(
    (acc): acc is typeof acc & { readonly derivation_path: string } =>
      acc.derivation_path != null,
  );

  const subAccountsSorted = subAccountsWithDerivationPath
    .slice()
    .sort((a, b) => sortDateAscending(a.created_at, b.created_at));

  const RECOVERY_LIMIT = 100;
  if (subAccountsSorted.length > RECOVERY_LIMIT) {
    logEvent(
      `Sub-accounts recovery limit exceeded. Only '${RECOVERY_LIMIT}' of total '${subAccountsSorted.length}' accounts will be recovered for performance reasons.`,
    );
  }

  const subAccountsSortedLimited = subAccountsSorted.slice(0, RECOVERY_LIMIT);

  for (const subAccount of subAccountsSortedLimited) {
    await _initializeSubAccountFromDerivationPath(
      accountClassHash,
      accountProxyClassHash,
      subAccount.derivation_path,
    );
  }

  const subAccountsRecovered = state.account.list
    .filter((acc) => acc.level === 'sub-account')
    .map(({ address, derivation }) => ({ address, derivation }));
  logEvent(
    `Total of '${subAccountsRecovered.length}' Sub-Accounts initialized`,
    { subAccountsRecovered },
  );
}

async function initializeNextSubAccount(
  accountClassHash: string,
  accountProxyClassHash: string,
): Promise<ParadexAccount> {
  const subAccount = await _initializeNextSubAccount(
    accountClassHash,
    accountProxyClassHash,
  );
  return accountView(subAccount);
}

async function _initializeNextSubAccount(
  accountClassHash: string,
  accountProxyClassHash: string,
): Promise<SubAccount> {
  if (state.account?.list[0] == null) {
    throw new Error(
      `Main account should be initialized before initializing a Sub-Account`,
    );
  }

  const account = await _generateNextSubAccount(
    accountClassHash,
    accountProxyClassHash,
  );

  upsertSubAccount(account);
  return account;
}

async function _initializeSubAccountFromDerivationPath(
  accountClassHash: string,
  accountProxyClassHash: string,
  derivationPath: string,
): Promise<SubAccount> {
  if (state.account?.list[0] == null) {
    throw new Error(
      `Main account should be initialized before initializing a Sub-Account`,
    );
  }

  const account = await _generateSubAccountFromDerivationPath(
    accountClassHash,
    accountProxyClassHash,
    derivationPath,
  );

  upsertSubAccount(account);
  return account;
}

function upsertSubAccount(newAccount: SubAccount) {
  if (state.account?.list[0] == null) {
    throw new Error(
      `Main account should be initialized before adding a Sub-Account`,
    );
  }

  const hasAccount = state.account.list.some(
    (acc) => acc.address === newAccount.address,
  );
  if (hasAccount) {
    state.account.list = state.account.list.map((acc) =>
      acc.address === newAccount.address ? newAccount : acc,
    ) as [MainAccount, ...SubAccount[]];
  } else {
    state.account.list.push(newAccount);
  }
  publishAccountsChange();
}

async function _generateNextSubAccount(
  accountClassHash: string,
  accountProxyClassHash: string,
): Promise<SubAccount> {
  if (state.account?.list[0] == null) {
    throw new Error(
      `Main account should be initialized before generating a Sub-Account`,
    );
  }

  const lastDerivationIndex =
    state.account.list
      .filter((acc) => {
        const isSubAccount = acc.level === 'sub-account';
        return isSubAccount;
      })
      .filter((acc) => {
        const knownDerivationPathFormat = isKnownDerivationPathFormat(
          acc.derivation.path,
        );
        return knownDerivationPathFormat;
      })
      .map((acc) => getDerivationPathIndex(acc.derivation.path))
      .sort((a, b) => a - b)
      .pop() ?? 0;
  const nextDerivationIndex = lastDerivationIndex + 1;
  const derivationPath = getDerivationPath(nextDerivationIndex);

  const account = _generateSubAccountFromDerivationPath(
    accountClassHash,
    accountProxyClassHash,
    derivationPath,
  );

  return account;
}

async function _generateSubAccountFromDerivationPath(
  accountClassHash: string,
  accountProxyClassHash: string,
  derivationPath: string,
): Promise<SubAccount> {
  if (state.account?.list[0] == null) {
    throw new Error(
      `Main account should be initialized before generating a Sub-Account`,
    );
  }

  const mainAccount = state.account.list[0];
  const baseAccountPrivateKey = mainAccount.privateKey;

  const derivationIndex = isKnownDerivationPathFormat(derivationPath)
    ? getDerivationPathIndex(derivationPath)
    : null;
  const subAccKeypair = await getSubAccountStarknetKeypair(
    baseAccountPrivateKey,
    derivationPath,
  );
  const { address } = getAccContractAddressAndCallData(
    accountClassHash,
    accountProxyClassHash,
    `0x${subAccKeypair.publicKey}`,
  );

  const account: SubAccount = {
    derivation: {
      path: derivationPath,
      index: derivationIndex,
    },
    level: 'sub-account',
    address: address as `0x${string}`,
    privateKey: subAccKeypair.privateKey,
    publicKey: subAccKeypair.publicKey, // without 0x prefix
    baseAccount: {
      type: 'paradex',
      address: mainAccount.address,
    },
  };

  try {
    Starknet.validateAndParseAddress(address);
  } catch (err) {
    throw new Error(
      `SubAccount for derivationPath='${account.derivation.path}' generated with an invalid address='${account.address}'. Not saving account.`,
    );
  }

  return account;
}

function getActiveAccount() {
  return accountView(_getActiveAccount());
}

function _getActiveAccount() {
  if (state.account == null) {
    throw new Error(
      `ParaclearWallet should be initialized before calling 'getActiveAccount()'`,
    );
  }

  const { activeIdx, list } = state.account;
  const activeAccount = list[activeIdx];

  if (activeAccount == null) {
    throw new Error(
      `Failed to access the active account idx='${activeIdx}' from the list='${JSON.stringify(
        list,
      )}'`,
    );
  }

  return activeAccount;
}

function getAddress() {
  if (state.account == null) {
    throw new Error(
      `ParaclearWallet should be initialized before calling 'getAddress()'`,
    );
  }

  const activeAccount = _getActiveAccount();
  return activeAccount.address;
}

function getBaseAccount() {
  if (state.account == null) {
    throw new Error(
      `ParaclearWallet should be initialized before calling 'getBaseAccount()'`,
    );
  }
  const activeAccount = _getActiveAccount();
  return activeAccount.baseAccount;
}

function getPublicKey() {
  if (state.account == null) {
    throw new Error(
      `ParaclearWallet should be initialized before calling 'getPublicKey()'`,
    );
  }

  const activeAccount = _getActiveAccount();
  return activeAccount.publicKey;
}

function getDerivation() {
  if (state.account == null) {
    throw new Error(
      `ParaclearWallet should be initialized before calling 'getDerivation()'`,
    );
  }

  const activeAccount = _getActiveAccount();
  return activeAccount.derivation;
}

function getPrivateKey() {
  if (state.account == null) {
    throw new Error(
      `ParaclearWallet should be initialized before calling 'getPrivateKey()'`,
    );
  }
  const activeAccount = _getActiveAccount();
  return `0x${activeAccount.privateKey}`;
}

function signTypedData(typedData: TypedData) {
  if (state.account == null) {
    throw new Error(
      `ParaclearWallet should be initialized before calling 'signTypedData()'`,
    );
  }

  const privateKey = getPrivateKey();
  const msgHash = getMessageHash(typedData);
  const signature = Starknet.ec.starkCurve.sign(msgHash, privateKey);

  return signature;
}

function signTypedDataAs(address: Address, typedData: TypedData) {
  if (state.account == null) {
    throw new Error(
      `ParaclearWallet should be initialized before calling 'signTypedDataAs()'`,
    );
  }

  const signer = state.account.list.find(
    (account) => account.address === address,
  );

  if (signer == null) {
    throw new Error(
      `Failed to sign as account with address='${address}'. ` +
        `Account with such address not found in the account ` +
        `list='${JSON.stringify(state.account.list)}'`,
    );
  }

  const msgHash = Starknet.typedData.getMessageHash(typedData, signer.address);
  const signature = Starknet.ec.starkCurve.sign(msgHash, signer.privateKey);

  return signature;
}

/**
 * Adopted from
 * @source https://github.com/ConsenSys/starknet-snap/blob/starknet-snap-v0.12.0/packages/starknet-snap/src/utils/starknetUtils.ts#L59
 */
async function callContract(
  provider: ParaclearProvider,
  txnInvocation: Starknet.Call,
  blockIdentifier: Starknet.BlockIdentifier = 'latest',
) {
  return provider.callContract(txnInvocation, blockIdentifier);
}

async function simulateTransaction(
  provider: ParaclearProvider,
  txnInvocation: Starknet.Call | Starknet.Call[],
) {
  if (state.account == null) {
    throw new Error(
      `ParaclearWallet should be initialized before calling 'simulateTransaction()'`,
    );
  }

  const activeAccount = _getActiveAccount();

  const privateKey = getPrivateKey();
  const signerAddress = activeAccount.address;
  const account = new Starknet.Account(provider, signerAddress, privateKey);
  const abis = undefined;

  return account.simulateTransaction(
    [{ type: Starknet.TransactionType.INVOKE, payload: txnInvocation }],
    abis,
  );
}

/** Adopted from
 *  @source https://github.com/ConsenSys/starknet-snap/blob/starknet-snap-v0.12.0/packages/starknet-snap/src/utils/starknetUtils.ts#L87
 *  @source https://github.com/ConsenSys/starknet-snap/blob/starknet-snap-v0.12.0/packages/starknet-snap/src/sendTransaction.ts
 */
async function executeTransaction(
  provider: ParaclearProvider,
  txnInvocation: Starknet.Call | Starknet.Call[],
) {
  if (state.account == null) {
    throw new Error(
      `ParaclearWallet should be initialized before calling 'executeTransaction()'`,
    );
  }

  const activeAccount = _getActiveAccount();

  //short term fix for bumping up txn fee
  const MAX_FEE = BigNumber('5e17'); // 5e17 WEI = 0.5 ETH
  const privateKey = getPrivateKey();
  const signerAddress = activeAccount.address;
  const account = new Starknet.Account(provider, signerAddress, privateKey);
  const abis = undefined;
  const maxFee = MAX_FEE.plus(intNoise(10_000)); // ensure unique txn hash on subsequent calls via `intNoise`
  const details: Starknet.UniversalDetails = {
    maxFee: maxFee.toString(),
    blockIdentifier: 'pending',
  };

  return account.execute(txnInvocation, abis, details);
}

async function executeTransactionAs(
  provider: ParaclearProvider,
  txnInvocation: Starknet.Call | Starknet.Call[],
  address: Address,
) {
  if (state.account == null) {
    throw new Error(
      `ParaclearWallet should be initialized before calling 'executeTransaction()'`,
    );
  }

  const asAccount = _getAccount(address);

  //short term fix for bumping up txn fee
  const MAX_FEE = BigNumber('5e17'); // 5e17 WEI = 0.5 ETH
  const privateKey = `0x${asAccount.privateKey}`;
  const signerAddress = asAccount.address;
  const account = new Starknet.Account(provider, signerAddress, privateKey);
  const abis = undefined;
  const maxFee = MAX_FEE.plus(intNoise(10_000)); // ensure unique txn hash on subsequent calls via `intNoise`
  const details: Starknet.UniversalDetails = {
    maxFee: maxFee.toString(),
    blockIdentifier: 'pending',
  };

  return account.execute(txnInvocation, abis, details);
}

async function getErc20Allowance(
  provider: ParaclearProvider,
  erc20TokenAddress: string,
  spenderAddress: string,
): Promise<BigNumber> {
  const owner = toIntString(getAddress());
  const spender = toIntString(spenderAddress);

  const response = await callContract(provider, {
    contractAddress: erc20TokenAddress,
    entrypoint: 'allowance',
    calldata: [owner, spender],
  });

  const [result] = response;
  if (result == null || BigNumber(result).isNaN()) {
    throw new Error(`Unexpected response reading ERC20 allowance: '${result}'`);
  }

  return BigNumber(result);
}

async function getL2BridgeVersion(
  provider: ParaclearProvider,
  token: BridgedToken,
): Promise<1 | 2> {
  const response = await callContract(provider, {
    contractAddress: token.l2BridgeAddress,
    entrypoint: 'get_version',
  });
  const [result] = response;
  const l2BridgeVersion = Number(result);

  if (!Number.isFinite(l2BridgeVersion)) {
    throw new Error(
      `Unexpected response reading L2 Bridge version: result='${l2BridgeVersion}'`,
    );
  }
  if (![1, 2].includes(l2BridgeVersion)) {
    throw new Error(
      `Unsupported L2 Bridge version: l2BridgeVersion='${l2BridgeVersion}'`,
    );
  }

  return l2BridgeVersion as 1 | 2;
}

function getMessageHash(typedData: TypedData) {
  if (state.account == null) {
    throw new Error(
      `ParaclearWallet should be initialized before calling 'getMessageHash()'`,
    );
  }

  const activeAccount = _getActiveAccount();
  return Starknet.typedData.getMessageHash(typedData, activeAccount.address);
}

function reset() {
  state = { ...INITIAL_STATE };
  publishAccountsChange();
}

export default wallet;
