import { useMemo } from 'react';
import * as Starknet from 'starknet';

import { useSystemConfig } from '#/features/system/system-config-context';

export type ParaclearProvider = Starknet.RpcProvider;

export function useParaclearProvider() {
  const config = useSystemConfig();
  const provider = useMemo(
    () =>
      getProvider({
        rpcUrl: config.starknet.rpcUrl,
        chainId: config.starknet.chainId,
      }),
    [config.starknet.chainId, config.starknet.rpcUrl],
  );
  return provider;
}

interface ParaclearProviderOptions {
  readonly rpcUrl: string;
  readonly chainId: string;
}

/**
 * Adapted from Starknet Snap
 * @source https://github.com/ConsenSys/starknet-snap/blob/starknet-snap-v0.12.0/packages/starknet-snap/src/utils/starknetUtils.ts#L40
 */
function getProvider(options: ParaclearProviderOptions): ParaclearProvider {
  const optionsSafe: Starknet.RpcProviderOptions = {
    nodeUrl: options.rpcUrl,
    chainId: options.chainId as Starknet.RpcProviderOptions['chainId'],
  };
  return new Starknet.RpcProvider(optionsSafe);
}
