import BigNumber from 'bignumber.js';

import { msToDate } from '#/utils/date';
import getQueryString from '#/utils/getQueryString';

import { AsyncResp, BaseReq, requestApi } from './fetch-api';

import type { Balance, RawBalance } from '#/api/balances';
import type { Nullable } from '#/utils/types';

type RawVaultBalance = RawBalance;
type VaultBalance = Balance;

export type { RawVaultBalance, VaultBalance };

interface VaultBalancesReq extends BaseReq {
  readonly address: string;
  readonly strategyAddress?: Nullable<string>;
}

export interface RawVaultBalancesResp {
  readonly results: readonly RawVaultBalance[];
}

export interface VaultBalancesResp {
  readonly results: readonly VaultBalance[];
}

export async function getVaultBalances(
  req: VaultBalancesReq,
): AsyncResp<VaultBalancesResp> {
  const { signal } = req;

  const queryString = getQueryString([
    ['address', req.address],
    ['strategy', req.strategyAddress],
  ]);

  const resp = await requestApi<RawVaultBalancesResp>({
    signal,
    method: 'GET',
    url: `/vaults/balance${queryString}`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: { results: resp.data.results.map(processVaultBalance) },
  };
}

export function processVaultBalance(balance: RawVaultBalance): VaultBalance {
  return {
    ...balance,
    last_updated_at: msToDate(balance.last_updated_at),
    size: new BigNumber(balance.size),
  };
}
