export type QueryStringValue = string | number | boolean | null | undefined;

/**
 * @param params Parameters names and their respective values. The
 * parameters with values of `null` or `undefined` are omitted.
 * @returns Query string (with a leading `?` if not empty)
 */
function getQueryString(
  params: ReadonlyArray<readonly [string, QueryStringValue]>,
) {
  const searchParams = new URLSearchParams();
  for (const [name, value] of params) {
    if (value != null) searchParams.append(name, `${value}`);
  }

  const query = searchParams.toString();
  return `${query.length > 0 ? '?' : ''}${query}`;
}

export default getQueryString;
