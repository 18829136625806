import BigNumber from 'bignumber.js';

import { AsyncResp, BaseReq, requestApi } from '#/api/fetch-api';

import getQueryString from '#/utils/getQueryString';
import { DecimalString } from '#/utils/types';

import type { DecimalOrEmptyString } from '#/utils/types';

export interface RawVaultSummary {
  /** Contract address of the vault */
  readonly address: string;
  /** Max all time drawdown realized by the vault as a decimal number, i.e. 0.1 means 10% */
  readonly max_drawdown: DecimalString;
  /** Max drawdown realized by the vault in the last 24 hours as a decimal number, i.e. 0.1 means 10% */
  readonly max_drawdown_24h: DecimalString;
  /** Max drawdown realized by the vault in the last 7 days as a decimal number, i.e. 0.1 means 10% */
  readonly max_drawdown_7d: DecimalString;
  /** Max drawdown realized by the vault in the last 30 days as a decimal number, i.e. 0.1 means 10% */
  readonly max_drawdown_30d: DecimalString;
  /** Vault equity of the owner (% of ownership) as a decimal number, i.e. 0.1 means 10% */
  readonly owner_equity: DecimalString;
  /** Total P&L of the vault in USD */
  readonly total_pnl: DecimalString;
  /** P&L of the vault in the last 24 hours in USD */
  readonly pnl_24h: DecimalOrEmptyString;
  /** P&L of the vault in the last 7 days in USD */
  readonly pnl_7d: DecimalOrEmptyString;
  /** P&L of the vault in the last 30 days in USD */
  readonly pnl_30d: DecimalOrEmptyString;
  /** Lifetime ROI of the vault as a decimal number, i.e. 0.1 means 10%. '' means not available */
  readonly total_roi: DecimalOrEmptyString;
  /** ROI of the vault in the last 24 hours as a decimal number, i.e. 0.1 means 10% */
  readonly roi_24h: DecimalOrEmptyString;
  /** ROI of the vault in the last 7 days as a decimal number, i.e. 0.1 means 10% */
  readonly roi_7d: DecimalOrEmptyString;
  /** ROI of the vault in the last 30 days as a decimal number, i.e. 0.1 means 10% */
  readonly roi_30d: DecimalOrEmptyString;
  /** Total amount of available vault tokens */
  readonly vtoken_supply: DecimalString;
  /** Current value of vault token price in USD */
  readonly vtoken_price: DecimalString;
  /** All time volume traded by the vault in USD */
  readonly volume: DecimalString;
  /** Volume traded by the vault in the last 24 hours in USD */
  readonly volume_24h: DecimalOrEmptyString;
  /** Volume traded by the vault in the last 7 days in USD */
  readonly volume_7d: DecimalOrEmptyString;
  /** Volume traded by the vault in the last 30 days in USD */
  readonly volume_30d: DecimalOrEmptyString;
  /** Last month return of the vault as a decimal number, i.e. 0.1 means 10% */
  readonly last_month_return: DecimalString;
  /** Number of depositors on the vault */
  readonly num_depositors: number;
}

export interface VaultSummary
  extends Omit<
    RawVaultSummary,
    | 'max_drawdown'
    | 'max_drawdown_24h'
    | 'max_drawdown_7d'
    | 'max_drawdown_30d'
    | 'owner_equity'
    | 'total_pnl'
    | 'pnl_24h'
    | 'pnl_7d'
    | 'pnl_30d'
    | 'total_roi'
    | 'roi_24h'
    | 'roi_7d'
    | 'roi_30d'
    | 'vtoken_supply'
    | 'vtoken_price'
    | 'volume'
    | 'volume_24h'
    | 'volume_7d'
    | 'volume_30d'
    | 'last_month_return'
    | 'num_depositors'
  > {
  /** Max all time drawdown realized by the vault as a decimal number, i.e. 0.1 means 10% */
  readonly max_drawdown: BigNumber;
  /** Max drawdown realized by the vault in the last 24 hours as a decimal number, i.e. 0.1 means 10% */
  readonly max_drawdown_24h: BigNumber;
  /** Max drawdown realized by the vault in the last 7 days as a decimal number, i.e. 0.1 means 10% */
  readonly max_drawdown_7d: BigNumber;
  /** Max drawdown realized by the vault in the last 30 days as a decimal number, i.e. 0.1 means 10% */
  readonly max_drawdown_30d: BigNumber;
  /** Vault equity of the owner (% of ownership) as a decimal number, i.e. 0.1 means 10% */
  readonly owner_equity: BigNumber;
  /** Total P&L of the vault in USD */
  readonly total_pnl: BigNumber;
  /** P&L of the vault in the last 24 hours in USD */
  readonly pnl_24h: BigNumber;
  /** P&L of the vault in the last 7 days in USD */
  readonly pnl_7d: BigNumber;
  /** P&L of the vault in the last 30 days in USD */
  readonly pnl_30d: BigNumber;
  /** Lifetime ROI of the vault as a decimal number, i.e. 0.1 means 10%. '' means not available */
  readonly total_roi: BigNumber | null;
  /** ROI of the vault in the last 24 hours as a decimal number, i.e. 0.1 means 10% */
  readonly roi_24h: BigNumber | null;
  /** ROI of the vault in the last 7 days as a decimal number, i.e. 0.1 means 10% */
  readonly roi_7d: BigNumber | null;
  /** ROI of the vault in the last 30 days as a decimal number, i.e. 0.1 means 10% */
  readonly roi_30d: BigNumber | null;
  /** Total amount of available vault tokens */
  readonly vtoken_supply: BigNumber;
  /** Current value of vault token price in USD */
  readonly vtoken_price: BigNumber | null;
  /** All time volume traded by the vault in USD */
  readonly volume: BigNumber;
  /** Volume traded by the vault in the last 24 hours in USD */
  readonly volume_24h: BigNumber;
  /** Volume traded by the vault in the last 7 days in USD */
  readonly volume_7d: BigNumber;
  /** Volume traded by the vault in the last 30 days in USD */
  readonly volume_30d: BigNumber;
  /** Last month return of the vault as a decimal number, i.e. 0.1 means 10% */
  readonly last_month_return: BigNumber;
  /** Number of depositors on the vault */
  readonly num_depositors: BigNumber;
}

export interface VaultSummaryReq extends BaseReq {
  readonly address: string;
}

export interface RawVaultSummaryResp {
  readonly results: readonly [RawVaultSummary] | [];
}

export type VaultSummaryResp = VaultSummary | null;

export async function getVaultSummary(
  req: VaultSummaryReq,
): AsyncResp<VaultSummaryResp> {
  const { signal } = req;

  const queryString = getQueryString([['address', req.address]]);

  const resp = await requestApi<RawVaultSummaryResp>({
    signal,
    method: 'GET',
    url: `/vaults/summary${queryString}`,
  });

  if (!resp.ok) return resp;

  const vaultSummary = resp.data.results.find((v) => v.address === req.address);

  if (vaultSummary == null) {
    return { ...resp, data: null };
  }

  return {
    ...resp,
    data: processVaultSummary(vaultSummary),
  };
}

export interface VaultsSummaryReq extends BaseReq {}

export interface RawVaultsSummaryResp {
  readonly results: readonly RawVaultSummary[];
}

export interface VaultsSummaryResp {
  readonly results: readonly VaultSummary[];
}

export async function getVaultsSummary(
  req: VaultsSummaryReq,
): AsyncResp<VaultsSummaryResp> {
  const { signal } = req;

  const resp = await requestApi<RawVaultSummaryResp>({
    signal,
    method: 'GET',
    url: `/vaults/summary`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: {
      ...resp.data,
      results: resp.data.results.map(processVaultSummary),
    },
  };
}

function processVaultSummary(vaultSummary: RawVaultSummary): VaultSummary {
  return {
    ...vaultSummary,
    max_drawdown: new BigNumber(vaultSummary.max_drawdown),
    max_drawdown_24h: new BigNumber(vaultSummary.max_drawdown_24h),
    max_drawdown_7d: new BigNumber(vaultSummary.max_drawdown_7d),
    max_drawdown_30d: new BigNumber(vaultSummary.max_drawdown_30d),
    owner_equity: new BigNumber(vaultSummary.owner_equity),
    total_pnl: new BigNumber(vaultSummary.total_pnl),
    pnl_24h: new BigNumber(vaultSummary.pnl_24h),
    pnl_7d: new BigNumber(vaultSummary.pnl_7d),
    pnl_30d: new BigNumber(vaultSummary.pnl_30d),
    total_roi:
      vaultSummary.total_roi === ''
        ? null
        : new BigNumber(vaultSummary.total_roi),
    roi_24h:
      vaultSummary.roi_24h === '' ? null : new BigNumber(vaultSummary.roi_24h),
    roi_7d:
      vaultSummary.roi_7d === '' ? null : new BigNumber(vaultSummary.roi_7d),
    roi_30d:
      vaultSummary.roi_30d === '' ? null : new BigNumber(vaultSummary.roi_30d),
    vtoken_supply: new BigNumber(vaultSummary.vtoken_supply),
    vtoken_price:
      vaultSummary.vtoken_price === ''
        ? null
        : new BigNumber(vaultSummary.vtoken_price),
    volume: new BigNumber(vaultSummary.volume),
    volume_24h: new BigNumber(vaultSummary.volume_24h),
    volume_7d: new BigNumber(vaultSummary.volume_7d),
    volume_30d: new BigNumber(vaultSummary.volume_30d),
    last_month_return: new BigNumber(vaultSummary.last_month_return),
    num_depositors: new BigNumber(vaultSummary.num_depositors),
  };
}
