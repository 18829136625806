import { layerswap } from '#/features/bridging/bridges/layerswap';
import { rhinofi } from '#/features/bridging/bridges/rhinofi';

import { starkgate } from './bridges/starkgate';
import { getAllChains } from './chains';
import { Bridge } from './types';

import type { AccountType } from '#/features/wallet/common/base-account';
import type {
  BridgeHybrid,
  BridgeOffsite,
  SupportedChain,
  SupportedWalletKind,
} from './types';

const BRIDGES: readonly Bridge[] = [rhinofi, layerswap, starkgate];

/**
 * Returns all supported bridges.
 */
export function getAllBridges(): readonly Bridge[] {
  return BRIDGES;
}
/**
 * Finds a bridge by its ID.
 */
export function findBridge(id: string): Bridge | undefined {
  return getAllBridges().find((bridge) => bridge.config.id === id);
}

/**
 * Returns a function that checks if a bridge is enabled.
 */
export function isEnabledFilter(enabled: boolean) {
  return (bridge: Bridge) => bridge.config.enabled === enabled;
}

/**
 * Returns all enabled bridges.
 */
export function getEnabledBridges(): readonly Bridge[] {
  return getAllBridges().filter(isEnabledFilter(true));
}

/**
 * Returns a function that checks if a bridge supports a chain.
 */
export function supportsChainFilter(chainId: string) {
  return (bridge: Bridge) => bridgeSupportsChain(bridge, chainId);
}

/**
 * Checks if a bridge supports a chain.
 */
export function bridgeSupportsChain(bridge: Bridge, chainId: string): boolean {
  return bridge.config.supportedChains.some((chain) => chain.id === chainId);
}

/**
 * Returns a function that checks if a bridge supports an account type.
 */
export function supportsAccountTypeFilter(accountType: AccountType) {
  return (bridge: Bridge) => bridgeSupportsAccountType(bridge, accountType);
}

/**
 * Checks if a bridge supports an account type.
 */
function bridgeSupportsAccountType(
  bridge: Bridge,
  accountType: AccountType,
): boolean {
  return bridge.config.supportedAccountTypes.includes(accountType);
}

/**
 * Returns a function that checks if a bridge supports a wallet kind.
 */
export function supportsWalletKindFilter(walletKind: SupportedWalletKind) {
  return (bridge: Bridge) => bridgeSupportsWalletKind(bridge, walletKind);
}

/**
 * Checks if a bridge supports a wallet kind.
 */
function bridgeSupportsWalletKind(
  bridge: Bridge,
  walletKind: SupportedWalletKind,
): boolean {
  if (isHybrid(bridge)) {
    return bridge.config.supportedWalletKindsOnsite.includes(walletKind);
  }
  return bridge.config.supportedWalletKinds.includes(walletKind);
}

/**
 * Returns bridge's supported chains based on the chains available in the app.
 * Note that depending on the environment, the available chains may vary.
 */
export function getSupportedChains(
  bridge: Bridge,
): ReadonlyArray<SupportedChain> {
  const chains = getAllChains();
  return bridge.config.supportedChains.filter((supportedChain) =>
    chains.some((chain) => chain.id === supportedChain.id),
  );
}

export function getAllSupportedChains(): ReadonlyArray<SupportedChain> {
  const chains = getAllChains();
  return BRIDGES.flatMap((bridge) =>
    bridge.config.supportedChains.filter((supportedChain) =>
      chains.some((chain) => chain.id === supportedChain.id),
    ),
  );
}

/**
 * Finds a chain supported by the given bridge by the chain ID.
 */
export function findSupportedChain(
  bridge: Bridge,
  chainId: string,
): SupportedChain | undefined {
  return getSupportedChains(bridge).find((chain) => chain.id === chainId);
}

/**
 * Finds a supported onsite deposit chain for a hybrid bridge.
 */
export function findSupportedOnsiteDepositChain(
  bridge: Bridge,
  accountType: AccountType,
  chainId: string,
): SupportedChain | undefined {
  if (!isHybrid(bridge)) return undefined;
  const chains = bridge.config.supportedOnsiteDepositChains[accountType];
  if (chains.length === 0) return undefined;
  return chains.find((chain) => chain.id === chainId);
}

export function isOffsite(bridge: Bridge): bridge is BridgeOffsite {
  return bridge.config.integrationKind === 'offsite';
}

export function isHybrid(bridge: Bridge): bridge is BridgeHybrid {
  return bridge.config.integrationKind === 'hybrid';
}
