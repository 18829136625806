import { useEffect, useState } from 'react';

import { ParadexNotification } from '#/api/ws/ws';

import { logException } from '#/features/logging/logging';
import { useParadexWebSocket } from '#/features/ws/ws-context';

import { isAbortError } from '#/utils/errors';
import noop from '#/utils/noop';

export interface WSConnectionState {
  readonly status: 'loading' | 'error' | 'done';
  readonly error: string;
}

const INITIAL_STATE: WSConnectionState = {
  status: 'loading',
  error: '',
};

interface Options<EntityWSNotification extends ParadexNotification> {
  channel: string;
  isReady?: boolean;
  handleWSNotification: (notif: EntityWSNotification) => void;
}

export default function useWSListener<
  EntityWSNotification extends ParadexNotification,
>(options: Options<EntityWSNotification>): WSConnectionState {
  const { channel, handleWSNotification, isReady = true } = options;

  const [wsConnectionState, setState] =
    useState<WSConnectionState>(INITIAL_STATE);

  const ws = useParadexWebSocket();

  useEffect(() => {
    if (!isReady) return;
    if (ws == null) return;

    let controller: AbortController | undefined;
    let clearNotificationHandler = noop;
    let isSubscribed = false;

    const isNotificationFromCorrectChannel = (
      notification: ParadexNotification,
    ): notification is EntityWSNotification => notification.channel === channel;

    /**
     * Subscribes to the WebSocket channel.
     * Subscribe requests are idempotent. Subscribing to an already
     * subscribed channel will not result in an error and has no effects.
     * @returns Promise that resolves when the subscription is confirmed.
     */
    const subscribe = async (signal: AbortSignal) => {
      const response = ws.request({
        method: 'subscribe',
        params: { channel },
        signal,
      });
      isSubscribed = true;
      return response;
    };

    /**
     * Unsubscribes from the WebSocket channel only if subscribed.
     * Unsubscribe requests are NOT idempotent. Unsubscribing from
     * an already unsubscribed channel will result in an error.
     */
    const unsubscribe = (signal?: AbortSignal) => {
      if (!isSubscribed) return;
      ws.request({ method: 'unsubscribe', params: { channel }, signal })
        .then((resp) => {
          if (resp.error != null)
            throw new Error(`Failed unsubscribing from '${channel}' channel`, {
              cause: resp.error,
            });
        })
        .catch((err) => {
          if (isAbortError(err as Error)) return;
          const message = `Error unsubscribing from WebSocket channel='${channel}'`;
          logException(new Error(message, { cause: err }));
        });
    };

    const startHandlingNotifications = () => {
      clearNotificationHandler = ws.addNotificationHandler((notif) => {
        if (isNotificationFromCorrectChannel(notif)) {
          handleWSNotification(notif);
        }
      });
    };

    const openIfReady = () => {
      if (ws.readyState === WebSocket.OPEN) {
        handleOpen();
      }
    };

    const cleanUp = () => {
      controller?.abort();
      clearNotificationHandler();
    };

    const setFailed = (error: string) => {
      cleanUp();
      setState({ status: 'error', error });
    };

    const handleOpen = () => {
      controller = new AbortController();

      setState({ status: 'loading', error: '' });

      subscribe(controller.signal)
        .then((subResp) => {
          if (controller!.signal.aborted) {
            return;
          }
          if (subResp.error != null) {
            setFailed(subResp.error.message);
            return;
          }

          startHandlingNotifications();

          setState({ status: 'done', error: '' });
        })
        .catch((error: Error) => {
          if (isAbortError(error)) return;
          setFailed(error.message);
          const message = `Failed subscribing to channel ${channel}`;
          logException(new Error(message, { cause: error }));
        });
    };

    openIfReady();
    ws.addEventListener('open', handleOpen);
    // Note: `useWSListener` won't enter error state when 'Connection to server lost'. WS will keep reconnecting.

    return () => {
      ws.removeEventListener('open', handleOpen);
      cleanUp();
      if (ws.readyState === WebSocket.OPEN) {
        unsubscribe(controller?.signal);
      }
    };
  }, [setState, ws, channel, handleWSNotification, isReady]);

  return wsConnectionState;
}
