import BigNumber from 'bignumber.js';

import { Market } from '#/api/markets';
import { MarketSummary } from '#/api/markets-summary';

import { Maybe } from '#/utils/types';

/**
 * Lambda ƛ (Leverage Ratio): Represents the option’s effective leverage.
 * It is calculated as the dollar delta relative to the option’s mark price.
 */
export function optionLeverageRatio(
  delta: BigNumber,
  spotPrice: BigNumber,
  markPrice: BigNumber,
) {
  return delta.times(spotPrice).dividedBy(markPrice);
}

export function openInterestUsd(
  market: Maybe<Pick<Market, 'asset_kind'>>,
  marketSummary: Maybe<
    Partial<
      Pick<MarketSummary, 'open_interest' | 'mark_price' | 'underlying_price'>
    >
  >,
): BigNumber | null {
  if (marketSummary == null) return null;
  if (market == null) return null;
  if (marketSummary.open_interest == null) return null;

  switch (market.asset_kind) {
    case 'PERP': {
      if (marketSummary.mark_price == null) {
        return null;
      }
      const value = marketSummary.open_interest.times(marketSummary.mark_price);
      return value;
    }
    case 'PERP_OPTION': {
      if (marketSummary.underlying_price == null) return null;
      const value = marketSummary.open_interest.times(
        marketSummary.underlying_price,
      );
      return value;
    }
    // no default
  }
}
