import { adapter } from './state';

import type { SubAccount } from '#/api/account';
import type { SubAccountsState } from './state';

export interface SubAccountsStore {
  readonly fetchStatus: SubAccountsState['fetchStatus'];
  readonly subAccounts: {
    getAll: () => readonly SubAccount[];
    getById: (id: string) => SubAccount | undefined;
    getByIsolatedMarket: (isolatedMarket: string) => SubAccount | undefined;
  };
  readonly recoveryStatus: SubAccountsState['recoveryStatus'];
  readonly error: string;
}

const selectors = adapter.getSelectors();

export function createStore(state: SubAccountsState): SubAccountsStore {
  return {
    fetchStatus: state.fetchStatus,
    subAccounts: {
      getAll: () => selectors.selectAll(state.subAccounts),
      getById: (id: string) => selectors.selectById(state.subAccounts, id),
      getByIsolatedMarket: (isolatedMarket: string) => {
        return selectors
          .selectAll(state.subAccounts)
          .find((subAccount) => subAccount.isolated_market === isolatedMarket);
      },
    },
    recoveryStatus: state.recoveryStatus,
    error: state.error,
  };
}
