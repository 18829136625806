import BigNumber from 'bignumber.js';

import { requestApi } from '#/api/fetch-api';

import type { AsyncResp, BaseReq } from '#/api/fetch-api';
import type { UnixTimeMs } from '#/api/types';
import type { DecimalString } from '#/utils/types';

export interface RawReferral {
  readonly address: string;
  readonly created_at: UnixTimeMs;
  readonly referral_code: string;
  readonly volume_traded: DecimalString;
  readonly referral_rewards: DecimalString;
  readonly referral_points: DecimalString;
}

export interface Referral
  extends Omit<
    RawReferral,
    'created_at' | 'volume_traded' | 'referral_rewards' | 'referral_points'
  > {
  readonly created_at: Date;
  readonly volume_traded: BigNumber;
  readonly referral_rewards: BigNumber;
  readonly referral_points: BigNumber;
}

interface GetReferralsReq extends BaseReq {}

interface RawGetReferralsResp {
  readonly results: readonly RawReferral[];
}

export interface GetReferralsResp {
  readonly results: readonly Referral[];
}

export async function getReferrals(
  req: GetReferralsReq,
): AsyncResp<GetReferralsResp> {
  const { signal } = req;

  const resp = await requestApi<RawGetReferralsResp>({
    signal,
    method: 'GET',
    url: `/referrals/summary`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: {
      ...resp.data,
      results: resp.data.results.map(processReferrals),
    },
  };
}

function processReferrals(entry: RawReferral): Referral {
  return {
    ...entry,
    created_at: new Date(entry.created_at),
    volume_traded: new BigNumber(entry.volume_traded),
    referral_rewards: new BigNumber(entry.referral_rewards),
    referral_points: new BigNumber(entry.referral_points),
  };
}
