import { useLayoutEffect, useRef } from 'react';

export function useScrollToTarget() {
  const containerRef = useRef<HTMLDivElement>(null);
  const targetRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (containerRef.current == null) return;
    if (targetRef.current == null) return;

    const scrollTop =
      targetRef.current.offsetTop -
      containerRef.current.clientHeight / 2 +
      targetRef.current.clientHeight / 2;

    containerRef.current.scrollTop = scrollTop;
  }, []);

  return { containerRef, targetRef };
}
