import BigNumber from 'bignumber.js';

import { Market } from '#/api/markets';

import { logException } from '#/features/logging/logging';

import { Maybe } from '#/utils/types';

import SETTINGS from '#/config/global-settings';

export { positionView } from '#/features/perpetuals/order-builder/shared/view';

export function ordersAmountInQuoteCurrency(
  orders: readonly { price?: Maybe<string>; size: string }[] | null,
): string {
  if (orders == null) {
    return '-';
  }

  const ordersWithPrice = orders.filter(
    (order): order is { price: string; size: string } => order.price != null,
  );
  if (ordersWithPrice.length !== orders.length) {
    logException(
      new Error('Unexpected undefined price in one of the batch orders'),
    );
    return '-';
  }

  const amountInQuoteCurrency = ordersWithPrice.reduce(
    (total, order) =>
      total.plus(new BigNumber(order.size).multipliedBy(order.price)),
    new BigNumber(0),
  );

  if (amountInQuoteCurrency.isNaN()) return '-';

  return `$${amountInQuoteCurrency.toFormat(SETTINGS.fiat.USD.scale)}`;
}
export function orderSummary(
  market: Maybe<Market>,
  draftOrders: readonly { price?: Maybe<string>; size: string }[] | null,
  position: 'start' | 'end',
) {
  if (draftOrders == null) return '-';
  if (market == null) return '-';

  const idx = position === 'start' ? 0 : draftOrders.length - 1;
  const order = draftOrders[idx];

  if (order == null) return '-';
  if (order.price == null) return '-';

  const size = BigNumber(order.size);
  const price = BigNumber(order.price);

  if (size.isNaN() || price.isNaN()) return '-';

  const formatted = `${size.toFormat()} ${
    market.base_currency
  } at $${price.toFormat()}`;
  return formatted;
}
