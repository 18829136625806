import { css } from 'styled-components';

export const BREAKPOINT_MD = 850;

export const MEDIA = {
  md: `all and (min-width: ${BREAKPOINT_MD}px)`,
  sm: `not all and (min-width: ${BREAKPOINT_MD}px)`,
};

export const smVisible = css`
  @media ${MEDIA.md} {
    display: none !important; // display on styled-components must be overridden
  }
`;

export const mdVisible = css`
  @media not ${MEDIA.md} {
    display: none;
  }
`;
