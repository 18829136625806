import BigNumber from 'bignumber.js';

import { AsyncResp } from '#/api/fetch-api';

import { SystemConfigView } from '#/features/system/system-config-context';
import { ParaclearProvider } from '#/features/wallets/paraclear/provider';
import ParaclearWallet from '#/features/wallets/paraclear/wallet';

import { fromQuantums } from '#/utils/quantums';

type HexNumber = `0x${string}`;

interface Req {
  readonly paraclearProvider: ParaclearProvider;
  readonly config: SystemConfigView;
  /**
   * Contract address to query. If not provided, queries
   * against the default Paraclear contract.
   */
  readonly contractAddress?: string;
}

type RespRaw = [HexNumber?];
export type SocializedLossResp = BigNumber;

export async function getSocializedLossFactor(
  req: Req,
): AsyncResp<SocializedLossResp> {
  const { paraclearProvider, config, contractAddress } = req;

  const response = await ParaclearWallet.callContract(paraclearProvider, {
    contractAddress: contractAddress ?? config.paraclear.address,
    entrypoint: 'getSocializedLossFactor',
  });

  const [result] = response as RespRaw;
  if (result == null || BigNumber(result).isNaN()) {
    throw new Error(
      `Unexpected response reading socialized loss on Paraclear: result='${result}'`,
    );
  }

  return {
    ok: true,
    status: 200,
    data: processAccountSocializedLoss(result, config),
  };
}

function processAccountSocializedLoss(
  result: HexNumber,
  config: SystemConfigView,
): BigNumber {
  return fromQuantums(result, config.paraclear.decimals);
}
