import BigNumber from 'bignumber.js';
import * as Starknet from 'starknet';

import {
  SystemConfigView,
  TokenView,
} from '#/features/system/system-config-context';
import { ParaclearProvider } from '#/features/wallets/paraclear/provider';
import ParaclearWallet, { Address } from '#/features/wallets/paraclear/wallet';

import { toQuantums } from '#/utils/quantums';
import { waitForTransaction } from '#/utils/starknet';
import toIntString from '#/utils/toIntString';

import SETTINGS from '#/config/global-settings';

import { logEvent } from '../logging/logging';
import { getSocializedLossFactor } from '../socialized-loss/api';

// Execute internal transfer (within Paraclear)
export async function executeInternalTransferTransaction(
  amount: string | BigNumber,
  systemConfig: SystemConfigView,
  contractAddress: string,
  token: TokenView,
  sender: string,
  recipient: string,
  paraclearProvider: ParaclearProvider,
) {
  const tokenAddress = toIntString(token.l2TokenAddress);
  const amountQuantums = toQuantums(amount, systemConfig.paraclear.decimals);

  // Paraclear transfer - transfer within paraclear
  const paraclearTransfer: Starknet.Call = {
    contractAddress,
    entrypoint: 'transfer',
    calldata: [recipient, tokenAddress, amountQuantums],
  };

  const transactionBatch = [paraclearTransfer];

  logEvent(`${token.symbol} internal transfer transaction batch`, {
    transactionBatch,
  });

  return executeTransactionAndWait(
    paraclearProvider,
    transactionBatch,
    sender as Address,
    token.symbol,
  );
}

// Execute external transfer (to external address)
export async function executeExternalTransferTransaction(
  amount: string | BigNumber,
  systemConfig: SystemConfigView,
  contractAddress: string,
  token: TokenView,
  sender: string,
  recipient: string,
  paraclearProvider: ParaclearProvider,
) {
  const tokenAddress = toIntString(token.l2TokenAddress);
  const amountQuantums = toQuantums(amount, systemConfig.paraclear.decimals);

  // 1. withdraw
  const withdraw: Starknet.Call = {
    contractAddress,
    entrypoint: 'withdraw',
    calldata: [tokenAddress, amountQuantums],
  };

  // Get amount after socialized loss
  const socializedLoss = await (async () => {
    const resp = await getSocializedLossFactor({
      config: systemConfig,
      paraclearProvider,
      contractAddress,
    });
    if (!resp.ok) return BigNumber(0);
    return resp.data;
  })();

  const amountAfterSocializedLoss = calcAmountAfterSocializedLoss(
    amount,
    socializedLoss,
  );

  // 2. Transfer token
  const transferAmount = toQuantums(amountAfterSocializedLoss, token.decimals);
  const transfer: Starknet.Call = {
    contractAddress: token.l2TokenAddress,
    entrypoint: 'transfer',
    calldata: [recipient, transferAmount, '0'],
  };

  const transactionBatch = [withdraw, transfer];

  logEvent(`${token.symbol} external transfer transaction batch`, {
    transactionBatch,
  });

  return executeTransactionAndWait(
    paraclearProvider,
    transactionBatch,
    sender as Address,
    token.symbol,
  );
}

// Helper function to execute transaction and wait for result
async function executeTransactionAndWait(
  paraclearProvider: ParaclearProvider,
  transactionBatch: Starknet.Call[],
  sender: Address,
  tokenSymbol: string,
) {
  const executeResponse = await ParaclearWallet.executeTransactionAs(
    paraclearProvider,
    transactionBatch,
    sender,
  );

  logEvent(`${tokenSymbol} transfer transaction hash`, {
    transaction_hash: executeResponse.transaction_hash,
  });

  const waitResult = await waitForTransaction(
    paraclearProvider,
    executeResponse.transaction_hash,
  );

  if (!waitResult.ok) {
    throw (
      waitResult.error ??
      new Error('Unknown Error, Failed to wait for transaction')
    );
  }

  if (waitResult.data.isRejected()) {
    throw new Error('Transaction rejected');
  }

  if (!waitResult.data.isSuccess()) {
    throw new Error(
      `Transaction not successful execution_status='${String(
        waitResult.data.execution_status,
      )}' transaction_hash='${
        waitResult.data.transaction_hash
      }' revert_reason='${waitResult.data.revert_reason}'`,
    );
  }

  logEvent(`${tokenSymbol} transfer transaction batch accepted`, {
    executeResponse,
    waitResult,
  });

  return executeResponse;
}

export function calcAmountAfterSocializedLoss(
  amount: string | BigNumber,
  socializedLoss: BigNumber,
) {
  return BigNumber(amount)
    .multipliedBy(BigNumber(1).minus(socializedLoss))
    .decimalPlaces(SETTINGS.tokens.USDC.scale, BigNumber.ROUND_DOWN);
}
