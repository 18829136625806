import { useId } from 'react';
import { kebabCase } from 'lodash';

/**
 * Provides a unique id for a given label to use with `htmlFor`
 */
export default function useLabel<T extends string>(label: T): [T, string] {
  const labelId = useLabelId(label);
  return [label, labelId];
}

export function useLabelId(label = 'label'): string {
  return `${kebabCase(label)}-${useId()}`;
}
