/**
 * @example maskString('', 6, 4); // => ''
 * @example maskString('0x1', 6, 4); // => '0x1'
 * @example maskString('0x123456789', 6, 4); // => '0x1234…6789'
 * @example maskString('0x1234567890123456789', 6, 4); // => '0x1234…6789'
 *
 * @param str String to mask
 * @param start How many characters to take from the start of the string
 * @param end How many characters to take from the end of the string
 * @returns Masked string or the original string if it's too short to mask
 */
export default function maskString(str: string, start: number, end: number) {
  const exp = new RegExp(`^(.{${start}}).*(.{${end}})$`, 'iu');
  return str.replace(exp, '$1…$2');
}
