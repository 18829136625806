/**
 * Sets the document title (browser tab) to the given title,
 * appending " | Paradex" to the end.
 *
 * @example
 * setDocumentTitle("Home");
 * // Sets the document title to "Home | Paradex"
 *
 * @param title Title of the current page
 */
export function setDocumentTitle(title: string) {
  document.title = `${title} | Paradex`;
}
