import BigNumber from 'bignumber.js';

import { Market } from '#/api/markets';
import { MarketSummary } from '#/api/markets-summary';
import { OpenOrder, Order } from '#/api/orders';
import { OpenPosition, Position } from '#/api/positions';

import {
  calcOrderTpSlPrice,
  isTpSlPriceValid,
} from '#/features/order/functions';

import { Maybe } from '#/utils/types';

/**
 * Calculates notional value of position
 * @returns Absolute notional value
 */
export function calcNotional(
  position: Pick<Position, 'size'>,
  markPrice: BigNumber,
): BigNumber {
  return position.size.times(markPrice).absoluteValue();
}

/**
 * Computes the side for an order which is
 * opposite to the side of the given position.
 * LONG positions would close with a SELL order.
 * SHORT positions would close with a BUY order.
 */
export function closingOrderSide(
  position: Pick<Position, 'side'>,
): Order['side'] {
  return {
    LONG: 'SELL' as const,
    SHORT: 'BUY' as const,
  }[position.side];
}

/**
 * Generates params to create a market order
 * that is opposite to the given position.
 */
export function closingMarketOrderParams(
  position: Pick<Position, 'market' | 'side' | 'size'>,
): Pick<Order, 'market' | 'side' | 'size'> {
  return {
    market: position.market,
    side: closingOrderSide(position),
    size: position.size.abs(),
  };
}

export function calcPositionValue(
  openPositions: readonly OpenPosition[],
  getMarket: (market: string) => Market | undefined,
  getMarketSummary: (market: string) => MarketSummary | undefined,
): BigNumber {
  const notionals = openPositions.map((position) => {
    const market = getMarket(position.market);
    const marketSummary = getMarketSummary(position.market);

    if (market == null || marketSummary == null) return null;

    switch (market.asset_kind) {
      case 'PERP_OPTION':
        if (marketSummary.underlying_price == null) return null;
        return calcNotional(position, marketSummary.underlying_price);
      case 'PERP':
        if (marketSummary.mark_price == null) return null;
        return calcNotional(position, marketSummary.mark_price);
      default:
        return null;
    }
  });

  if (notionals.some((notional) => notional == null)) return BigNumber(NaN);

  const notionalValues = notionals.filter(
    (notional): notional is BigNumber => notional != null,
  );

  const notionalSum = notionalValues.reduce((acc, notional) => {
    return acc.plus(notional);
  }, BigNumber('0'));

  return notionalSum;
}

/**
 * Derives `Open Sell/Buy Size`
 * see formula at @source https://docs.google.com/spreadsheets/d/1IpMmYL7DVxGm89aJr_KfUy2j5cMdqibA0IsD2gGr6GY/edit#gid=596140446&range=H26:I26
 */
function calcOpenSize(
  side: Order['side'],
  openOrders: readonly OpenOrder[],
  openPosition: Pick<OpenPosition, 'market' | 'size' | 'side'>,
) {
  const positionSize =
    openPosition.side === 'SHORT'
      ? openPosition.size.abs().negated()
      : openPosition.size;

  const filteredOrders = openOrders.filter(
    (order) =>
      (order.status === 'OPEN' || order.status === 'UNTRIGGERED') &&
      order.market === openPosition.market &&
      order.side === side,
  );

  // On position side, we only consider non-reduce-only orders
  let ordersSizeSum = BigNumber.sum(
    0,
    ...filteredOrders
      .filter((order) => !order.flags.includes('REDUCE_ONLY'))
      .map((order) => order.remaining_size),
  );

  // On opposite side, we consider reduce-only orders capped by position size
  const isOppositeSide =
    (side === 'SELL' && openPosition.side === 'LONG') ||
    (side === 'BUY' && openPosition.side === 'SHORT');
  if (isOppositeSide) {
    const reduceOnlyOrdersSizeSum = BigNumber.sum(
      0,
      ...filteredOrders
        .filter((order) => order.flags.includes('REDUCE_ONLY'))
        .map((order) => order.remaining_size),
    );

    ordersSizeSum = ordersSizeSum.plus(
      BigNumber.minimum(reduceOnlyOrdersSizeSum, openPosition.size.abs()),
    );
  }

  return side === 'SELL'
    ? BigNumber.maximum(0, ordersSizeSum.minus(positionSize))
    : BigNumber.maximum(0, ordersSizeSum.plus(positionSize));
}

/**
 * Derives `Open Notional`
 * see formula at @source https://docs.google.com/spreadsheets/d/1IpMmYL7DVxGm89aJr_KfUy2j5cMdqibA0IsD2gGr6GY/edit#gid=596140446&range=C37
 */
export function calcOpenNotional(
  openOrders: readonly OpenOrder[],
  openPositions: readonly Pick<OpenPosition, 'market' | 'size' | 'side'>[],
  getMarket: (market: string) => Market | undefined,
  getMarketSummary: (market: string) => MarketSummary | undefined,
  include_orders = true,
): BigNumber {
  let notionalSum = BigNumber(0);

  for (const position of openPositions) {
    const market = getMarket(position.market);
    const marketSummary = getMarketSummary(position.market);

    // WIP: refactor to return null instead of NaN
    if (market == null || marketSummary == null) return BigNumber(NaN);

    const openBuySize = calcOpenSize('BUY', openOrders, position);
    const openSellSize = calcOpenSize('SELL', openOrders, position);
    const openSize = include_orders
      ? BigNumber.maximum(openBuySize, openSellSize)
      : position.size.abs();

    switch (market.asset_kind) {
      case 'PERP_OPTION': {
        if (marketSummary.underlying_price == null) return BigNumber(NaN);
        if (marketSummary.delta == null) return BigNumber(NaN);
        const openNotional = openSize
          .times(marketSummary.underlying_price)
          .times(marketSummary.delta.abs());
        notionalSum = notionalSum.plus(openNotional);
        break;
      }
      case 'PERP': {
        if (marketSummary.mark_price == null) return BigNumber(NaN);
        const openNotional = openSize.times(marketSummary.mark_price);
        notionalSum = notionalSum.plus(openNotional);
        break;
      }
      // no default
    }
  }
  return notionalSum;
}

/**
 * percent = (price - entryPrice) / entryPrice * leverage * 100
 * Percent is range between 0 and 100
 */
export function calcTpSlPercent(
  price: Maybe<BigNumber>,
  entryPrice: Maybe<BigNumber | string>,
  leverage: Maybe<BigNumber>,
) {
  if (entryPrice == null || price == null || leverage == null) return null;
  return price
    .minus(entryPrice)
    .abs()
    .div(entryPrice)
    .times(leverage)
    .times(100);
}

/**
 * price = entryPrice * (1 + (percent / (leverage * 100)))
 */
export function calcPositionTpSlPrice(
  position: Pick<Position, 'side'>,
  percent: BigNumber,
  entryPrice: Maybe<BigNumber>,
  leverage: Maybe<BigNumber>,
  type: 'profit' | 'loss',
) {
  const side = toOrderSide(position.side);
  return calcOrderTpSlPrice(side, percent, entryPrice, leverage, type);
}

export function toOrderSide(side: Position['side']): Order['side'] {
  return side === 'LONG' ? 'BUY' : 'SELL';
}

export function isPositionTpSlPriceValid(
  price: Maybe<BigNumber>,
  entryPrice: Maybe<BigNumber>,
  positionSide: Maybe<OpenPosition['side']>,
  type: 'profit' | 'loss',
) {
  const side = positionSide != null ? toOrderSide(positionSide) : null;
  return isTpSlPriceValid(price, entryPrice, side, type);
}
