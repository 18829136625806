/* eslint-disable prefer-regex-literals */

/** @example m/44'/9004'/0'/0/1 */
const KNOWN_DERIVATION_PATH_FORMAT = new RegExp(`^m/44'/9004'/0'/0/\\d+$`, 'u');

export const isKnownDerivationPathFormat = (path: string): boolean => {
  return KNOWN_DERIVATION_PATH_FORMAT.test(path);
};

/**
 * @example returns "m/44'/9004'/0'/0/23" for 1
 */
export const getDerivationPath = (index: number): string => {
  if (!Number.isInteger(index)) {
    throw new Error('index for derivation path must be a valid number');
  }
  if (index < 1 || index >= Number.MAX_SAFE_INTEGER) {
    throw new Error(
      `derivation path must be within range 1 to ${Number.MAX_SAFE_INTEGER}`,
    );
  }

  const path = `m/44'/9004'/0'/0/${index}`;

  if (!isKnownDerivationPathFormat(path)) {
    throw new Error(
      `Failed to get derivation path because resulting path='${path}' does not satisfy the known derivation path format`,
    );
  }

  return path;
};

/**
 * @example returns 23 for "m/44'/9004'/0'/0/23"
 */
export const getDerivationPathIndex = (path: string): number => {
  if (!isKnownDerivationPathFormat(path)) {
    throw new Error(
      `Failed to get derivation path index due to unknown format of the derivation path='${path}'`,
    );
  }

  const indexStr = path.split('/').pop();
  if (indexStr == null) {
    throw new Error(
      `Failed to get derivation path index because index is missing at the expected position in the derivation path='${path}'`,
    );
  }

  const index = parseInt(indexStr, 10);
  if (!Number.isInteger(index)) {
    throw new Error(
      `Failed to get derivation path index because parsed index='${indexStr}' is not a valid number`,
    );
  }

  return index;
};
