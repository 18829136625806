import { perEnv } from '#/features/env';
import { FeatureFlags } from '#/features/feature-flags';

import getQueryString from '#/utils/getQueryString';

import type { BridgeHybrid } from '#/features/bridging/types';

export const layerswap: BridgeHybrid = {
  config: {
    id: 'LAYERSWAP',
    name: 'Layerswap',
    icon: 'layerswap',
    url: "https://layerswap.io/app",
    integrationKind: 'hybrid',
    depositMode: 'one-step',
    supportedChains: perEnv({
      prod: [
        { id: 'ARBITRUM', externalId: 'ARBITRUM_MAINNET' },
        { id: 'AVALANCHE', externalId: 'AVAX_MAINNET' },
        { id: 'BASE', externalId: 'BASE_MAINNET' },
        { id: 'BSC', externalId: 'BSC_MAINNET' },
        { id: 'ETHEREUM', externalId: 'ETHEREUM_MAINNET' },
        { id: 'OPTIMISM', externalId: 'OPTIMISM_MAINNET' },
        { id: 'POLYGON', externalId: 'POLYGON_MAINNET' },
        { id: 'SOLANA', externalId: 'SOLANA_MAINNET' },
        { id: 'STARKNET', externalId: 'STARKNET_MAINNET' },
        { id: 'LINEA', externalId: 'LINEA_MAINNET' },
        { id: 'MODE', externalId: 'MODE_MAINNET' },
        { id: 'MANTLE', externalId: 'MANTLE_MAINNET' },
        { id: 'SCROLL', externalId: 'SCROLL_MAINNET' },
        { id: 'ZKSYNC', externalId: 'ZKSYNCERA_MAINNET' },
        { id: 'MANTA', externalId: 'MANTA_MAINNET' },
      ] as const,
      testnet: [
        { id: 'ETHEREUM', externalId: 'ETHEREUM_SEPOLIA' },
        { id: 'STARKNET', externalId: 'STARKNET_SEPOLIA' },
      ] as const,
      local: [
        { id: 'ETHEREUM', externalId: 'ETHEREUM_SEPOLIA' },
        { id: 'STARKNET', externalId: 'STARKNET_SEPOLIA' },
      ] as const,
      fallback: [
        { id: 'ETHEREUM', externalId: 'ETHEREUM_SEPOLIA' },
        { id: 'STARKNET', externalId: 'STARKNET_SEPOLIA' },
      ] as const,
    }),
    supportedAccountTypes: ['ethereum', 'starknet'],
    supportedWalletKinds: ['ethereum-external', 'starknet-external'],
    supportedOnsiteDepositChains: {
      ethereum: perEnv({
        prod: [{ id: 'ETHEREUM', externalId: 'ETHEREUM_MAINNET' }] as const,
        testnet: [{ id: 'ETHEREUM', externalId: 'ETHEREUM_SEPOLIA' }] as const,
        local: [{ id: 'ETHEREUM', externalId: 'ETHEREUM_SEPOLIA' }] as const,
        fallback: [{ id: 'ETHEREUM', externalId: 'ETHEREUM_SEPOLIA' }] as const,
      }),
      starknet: perEnv({
        prod: [{ id: 'STARKNET', externalId: 'STARKNET_MAINNET' }] as const,
        testnet: [{ id: 'STARKNET', externalId: 'STARKNET_SEPOLIA' }] as const,
        local: [{ id: 'STARKNET', externalId: 'STARKNET_SEPOLIA' }] as const,
        fallback: [{ id: 'STARKNET', externalId: 'STARKNET_SEPOLIA' }] as const,
      }),
      paradex: [],
    },
    supportedWalletKindsOnsite: [
      'ethereum-external',
      'starknet-external',
      'ethereum-embedded',
    ],
    enabled: FeatureFlags.getOverridableBooleanValue(
      'layerswap-enabled',
      false,
    ),
  } as const,

  buildDepositUrl: (props: {
    readonly asset: string;
    readonly sourceChainId: string;
    readonly destinationAddress?: string;
    readonly amount: string;
  }): string => {
    const queryString = getQueryString([
      ['appName', "67882b17e60241e6b5d5a21eb39a6e7a"],
      ['from', props.sourceChainId],
      ['fromAsset', props.asset],
      ['to', "PARADEX_MAINNET"],
      ['lockTo', 'true'],
      ['toAsset', props.asset],
      ['lockToAsset', 'true'],
      ['destAddress', props.destinationAddress],
      ['lockAddress', 'true'],
      ['amount', props.amount],
      ['actionButtonText', 'Deposit'],
    ]);

    return `${"https://layerswap.io/app"}/${queryString}`;
  },

  buildWithdrawUrl: (props: {
    readonly asset: string;
    readonly destinationChainId: string;
    readonly amount: string;
  }): string => {
    const queryString = getQueryString([
      ['appName', "67882b17e60241e6b5d5a21eb39a6e7a"],
      ['from', "PARADEX_MAINNET"],
      ['fromAsset', props.asset],
      ['to', props.destinationChainId],
      ['lockTo', 'true'],
      ['toAsset', props.asset],
      ['lockToAsset', 'true'],
      ['amount', props.amount],
      ['actionButtonText', 'Withdraw'],
    ]);

    return `${"https://layerswap.io/app"}/${queryString}`;
  },
};
