import * as ParaclearService from '#/features/deposit/services/paraclear-service';
import { debugParadexTx } from '#/features/paradex-chain';

import type BigNumber from 'bignumber.js';
import type { SystemConfigView } from '#/features/system/system-config-context';
import type { ParaclearProvider } from '#/features/wallets/paraclear/provider';
import type { AsyncResult } from '#/utils/types';

export interface ParaclearDepositService {
  deposit(amount: BigNumber, asset: string): AsyncResult<string, string>;
}

export default function createService(
  config: SystemConfigView,
  paraclearProvider: ParaclearProvider,
): ParaclearDepositService {
  return {
    async deposit(amount, asset) {
      const token = config.getToken(asset);

      const result = await ParaclearService.requestDeposit(
        amount,
        token,
        config.paraclear,
        paraclearProvider,
      );

      const txHash = result.data;

      debugParadexTx('Paraclear Deposit', config.starknet.explorerUrl, txHash);

      return result;
    },
  };
}
