import BigNumber from 'bignumber.js';

import { Market } from '#/api/markets';
import { PerpetualMarketSummary } from '#/api/markets-summary';
import { OrderBookUpdateEntry, PriceSizeTuple } from '#/api/order-book';

import { Maybe } from '#/utils/types';

import { OrderBook } from './types';

export interface OrderBookSpread {
  relative: BigNumber;
}

export function calculateSpread(
  orderBook: OrderBook,
  marketSummary: Maybe<PerpetualMarketSummary>,
  activeMarket: Maybe<Market>,
): OrderBookSpread | null {
  const { asks, bids } = orderBook;
  const underlyingPrice = marketSummary?.underlying_price;

  if (asks.length === 0) return null;
  if (bids.length === 0) return null;
  if (activeMarket == null) return null;

  const maxAsk = asks[asks.length - 1]![0];
  const maxBid = bids[0]![0];

  switch (activeMarket.asset_kind) {
    case 'PERP_OPTION': {
      if (underlyingPrice == null) return null;
      // (ask price - bid price) / spot price
      const relative = maxAsk.minus(maxBid).dividedBy(underlyingPrice);
      return { relative };
    }
    case 'PERP': {
      // 2 * (ask price - bid price) / (ask price + bid price)
      const relative = BigNumber(2)
        .times(maxAsk.minus(maxBid))
        .dividedBy(maxAsk.plus(maxBid));
      return { relative };
    }
    // no default
  }
}

export function isOrderBookEmpty({ asks, bids }: OrderBook): boolean {
  return asks.length === 0 && bids.length === 0;
}

function transformOrderBookEntryToTuple(
  entry: OrderBookUpdateEntry,
): PriceSizeTuple {
  return [entry.price, entry.size];
}

export function parseBids(entries: OrderBookUpdateEntry[]): PriceSizeTuple[] {
  return entries
    .filter((entry) => entry.side === 'BUY')
    .map(transformOrderBookEntryToTuple);
}

export function parseAsks(entries: OrderBookUpdateEntry[]): PriceSizeTuple[] {
  return entries
    .filter((entry) => entry.side === 'SELL')
    .map(transformOrderBookEntryToTuple);
}

export function parseIds(entries: OrderBookUpdateEntry[]): string[] {
  return entries.map((entry) => entry.price.toString());
}
