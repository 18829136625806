import BigNumber from 'bignumber.js';
import * as Yup from 'yup';

import { isStarknetAddress, isWithinRange } from '#/features/vaults/validation';

import { VaultSettingsFormStore } from './form-store';

type FormShape = {
  [K in keyof VaultSettingsFormStore]: Yup.Schema<unknown>;
};

const yupFormSchema = Yup.object().shape<FormShape>({
  asset: Yup.string()
    .required('Asset is required')
    .min(1, 'Asset must be at least 1 character long'),
  vaultAddress: Yup.string()
    .required('Vault Address is required')
    .test(...isStarknetAddress('Vault Address')),
  maxTvl: Yup.mixed<BigNumber>()
    .nullable()
    .test(...isWithinRange('Maximum TVL', 0, Number.MAX_SAFE_INTEGER, true)),
});

const validate = async (formStore: VaultSettingsFormStore) =>
  yupFormSchema.validate(formStore);

/**
 * Yup's validate() handles static validation that is known beforehand.
 * While a set of functions handle more complex validation that depends on live data.
 */
export const formSchema = {
  validate,
};
