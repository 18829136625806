import { msToDate } from '#/utils/date';
import getQueryString from '#/utils/getQueryString';

import {
  AsyncResp,
  BasePaginatedReq,
  PaginatedRespData,
  requestApi,
} from './fetch-api';
import { UnixTimeMs } from './types';

export type TransactionState =
  | 'NOT_RECEIVED'
  | 'RECEIVED'
  | 'ACCEPTED_ON_L2'
  | 'ACCEPTED_ON_L1'
  | 'REJECTED'
  | 'REVERTED';

type TransactionType = 'TRANSACTION_LIQUIDATE' | 'TRANSACTION_FILL';

export interface RawTransaction {
  readonly id: string;
  readonly created_at: UnixTimeMs;
  readonly hash: string;
  readonly state: TransactionState;
  readonly type: TransactionType;
}

export interface Transaction extends Omit<RawTransaction, 'created_at'> {
  readonly created_at: Date;
}

interface TransactionsReq extends BasePaginatedReq {
  readonly start_at?: number | null;
}

export interface RawTransactionsResp
  extends PaginatedRespData<RawTransaction> {}

export interface TransactionsResp extends PaginatedRespData<Transaction> {}

export async function getTransactions(
  req: TransactionsReq,
): AsyncResp<TransactionsResp> {
  const { signal, cursor, page_size, start_at } = req;

  const query = getQueryString([
    ['cursor', cursor],
    ['page_size', page_size],
    ['start_at', start_at],
  ]);

  const resp = await requestApi<RawTransactionsResp>({
    signal,
    method: 'GET',
    url: `/transactions${query}`,
  });

  if (!resp.ok) {
    return resp;
  }

  return {
    ...resp,
    data: {
      ...resp.data,
      results: resp.data.results.map(processTransaction),
    },
  };
}

export function processTransaction(transaction: RawTransaction): Transaction {
  return {
    ...transaction,
    created_at: msToDate(transaction.created_at),
  };
}
