import { Keys } from "./en";

const translations: Record<Keys, string> = {
  "Display trade history on chart": "Отображать историю торгов на графике",
  "Add markets to Favorites in the Markets Browser":
    "Добавьте рынки в избранное в браузере рынков",
  "Log in": "Вход",
  "Export embedded wallet": "Экспорт встроенного кошелька",
  "Email or Social": "Электронная почта или социальные сети",
  "Failed to load balances": "Не удалось загрузить балансы",
  "No balances": "Нет балансов",
  "Verified Operator": "Проверенный оператор",
  "Strategy": "Стратегия",
  "Multi-Strategy": "Мульти-Стратегия",
  "Master Vault": "Основное хранилище",
  "Master Vault Details": "Детали основного хранилища",
  "Annualized": "Годовой",
  "Depositors": "Депоненты",
  "No trade history": "Нет истории торгов",
  "Unwinding Progress": "Прогресс закрытия",
  "What are Vaults?": "Что такое хранилища?",
  "APR calculated as the Annualised 30-day Return":
    "APR рассчитывается как годовая доходность за 30 дней",
  "APR": "APR",
  "Initializing": "Инициализация",
  "Awaiting Deposit": "Ожидание депозита",
  "Vault Age": "Возраст хранилища",
  "30D Return": "30Д Доходность",
  "{{field}} must be greater than the minimum initial deposit of {{minInitialDeposit}}":
    "{{field}} должен быть больше минимального начального депозита {{minInitialDeposit}}",
  "Minimum deposit": "Минимальный депозит",
  "Withdrawal amount exceeds available vault shares":
    "Сумма вывода превышает доступные доли хранилища",
  "Deposit would exceed maximum vault TVL of {{maxTVL}}":
    "Сумма депозита превысит максимальный TVL хранилища {{maxTVL}}",
  "{{field}} is required": "{{field}} обязателен",
  "Lockup Remaining": "Остаток блокировки",
  "{{days}}D": "{{days}}Д",
  "<1>{{days}}</1> D": "<1>{{days}}</1> д",
  "<1>{{hours}}</1> H": "<1>{{hours}}</1> ч",
  "<1>{{minutes}}</1> M": "<1>{{minutes}}</1> м",
  "Vault settings submitted": "Настройки хранилища отправлены",
  "Vault settings updated": "Настройки хранилища обновлены",
  "Vault Settings": "Настройки хранилища",
  "Vault not found": "Хранилище не найдено",
  "The owner receives a {{profitShare}} profit share.":
    "Владелец получает {{profitShare}} от прибыли.",
  "This vault is entirely owned by the community.":
    "Это хранилище полностью принадлежит сообществу.",
  "Withdraw from Vault submitted": "Вывод из хранилища отправлен",
  "Deposit to Vault submitted": "Депозит в хранилище отправлен",
  "Unknown error. Try again.": "Неизвестная ошибка. Попробуйте снова.",
  "Vault Deposit": "Внести депозит в хранилище",
  "Vault Withdrawal": "Вывести из хранилища",
  "No Lockup": "Без блокировки",
  "Lockup period is not over yet": "Период блокировки еще не закончился",
  "Protocol": "Протокол",
  "24H": "24ч",
  "7D": "7д",
  "30D": "30д",
  "All": "Все",
  "You have no investments in vaults": "У вас нет инвестиций в хранилища",
  "Total Value": "Общая стоимость",
  "Investments Made": "Сделанные инвестиции",
  "Operator": "Оператор",
  "Manage Vault": "Управление хранилищем",
  "Switch to the operator account": "Переключиться на аккаунт оператора",
  "Active": "Активные",
  "My Vaults": "Мои Хранилища",
  "Closed": "Закрытые",
  "Deposited": "Внесенные",
  "Lifetime ROI": "ROI за все время",
  "Owners Equity": "Собственный капитал владельцев",
  "Top Trending": "Самые популярные",
  "Owner Share": "Доля владельца",
  "Vault Details": "Детали хранилища",
  "Contract": "Контракт",
  "Withdraw from Vault": "Вывести из хранилища",
  "Withdraw from Vault successful": "Вывод из хранилища успешен",
  "Failed to withdraw from Vault": "Не удалось вывести из хранилища",
  "Name": "Имя",
  "Deposit": "Депозит",
  "Tokens": "Токены",
  "Avg. Entry Price": "Средняя цена входа",
  "Current Price": "Текущая цена",
  "P&L": "Прибыль и убытки",
  "My Vault Deposit": "Мой депозит хранилища",
  "Vaults": "Хранилища",
  "Create": "Создать",
  "Create My Vault": "Создать мое хранилище",
  "Vault Name": "Название хранилища",
  "Vault Description": "Описание хранилища",
  "Token Symbol": "Символ токена",
  "Deposit Amount": "Сумма депозита",
  "Vault description will be visible publicly and you will not be able to edit this later.":
    "Описание хранилища будет видно публично, и вы не сможете отредактировать его позже.",
  "Once created, deposit at least {{minDeposit}} USDC to activate the vault. At this moment, only the Max TVL can be changed later.":
    "После создания внесите по крайней мере {{minDeposit}} USDC, чтобы активировать хранилище. На данный момент единственное значение, которое можно изменить позже, — это максимальный TVL.",
  "Create Vault": "Создать хранилище",
  "Profit Share": "Доля прибыли",
  "Lockup Period (Days)": "Период блокировки (дни)",
  "Vault was successfully created and is being initialized":
    "Хранилище успешно создано и инициализируется",
  "Failed to create a vault": "Не удалось создать хранилище",
  "Maximum TVL": "Максимальный TVL",
  "No limit": "Без ограничений",
  "Vault Address": "Адрес хранилища",
  "Deposit to Vault successful": "Депозит в хранилище успешен",
  "Failed to deposit to Vault": "Не удалось внести депозит в хранилище",
  "Deposit to Vault": "Внести депозит в хранилище",
  "Vault": "Хранилище",
  "Owner": "Владелец",
  "Age": "Возраст",
  "Failed to load vaults": "Не удалось загрузить хранилища",
  "No vaults available": "Нет доступных хранилищ",
  "Performance": "Производительность",
  "Vault Value": "Стоимость хранилища",
  "My Deposit": "Мой депозит",
  "Total P&L": "Общая прибыль и убытки",
  "Protocol Vaults": "Хранилища протокола",
  "User Vaults": "Пользовательские хранилища",
  "No Data": "Нет данных",
  "Last Month Return": "Доходность за прошлый месяц",
  "TVL": "TVL",
  "Total Earnings": "Общий заработок",
  "About": "О нас",
  "Vault Stats": "Статистика хранилища",
  "My Stats": "Моя статистика",
  "Vault Contract": "Контракт хранилища",
  "Description": "Описание",
  "Max Drawdown": "Максимальная просадка",
  "Volume": "Объем",
  "All-Time P&L": "Прибыль и убытки за все время",
  "Days": "Дни",
  "Lockup Period": "Период блокировки",
  "Search Vaults": "Поиск хранилищ",
  "Blog": "Блог",
  "Block Explorer": "Обозреватель блоков",
  "Close Vault": "Закрыть хранилище",
  "Close Vault successful": "Закрытие хранилища успешно",
  "You must close all open positions to close your Vault. Closed vaults cannot be reopened.":
    "Вы должны закрыть все открытые позиции, чтобы закрыть ваше хранилище. Закрытые хранилища нельзя открыть повторно.",
  "Close My Vault": "Закрыть мое хранилище",
  "Failed to Close the Vault": "Не удалось закрыть хранилище",
  "Only the Main Account can manage the vault":
    "Только основной аккаунт может управлять хранилищем",
  "Allocate Collateral": "Распределить залог",
  "Switch to Main Account": "Переключиться на Основной Счет",
  "Switch to Account": "Переключиться на Счет",
  "You are currently trading in the {{market}} market.":
    "Вы в настоящее время торгуете на рынке {{market}}.",
  "To start trading on Isolated Market you must Allocate Collateral from your Main Account and switch to this Market Account.":
    "Чтобы начать торговлю на Изолированном Рынке, вам нужно распределить залог из вашего Основного Счета и переключиться на этот Рынковый Счет.",
  "To trade in this market, switch to the Main Account first.":
    "Для торговли на этом рынке сначала переключитесь на Основной Счет.",
  "This account can only trade in the {{market}} market.":
    "Этот счет может торговать только на рынке {{market}}.",
  "Isolated Market": "Изолированный Рынок",
  "This feature currently is still in Beta. Backup your Paradex Private Key for additional safety.":
    "Эта функция в настоящее время находится в стадии бета-тестирования. Сохраните приватный ключ Paradex для дополнительной безопасности.",
  "Deposit failed": "Депозит не выполнен",
  "Deposit in progress": "Депозит в процессе",
  "External Account": "Внешний счет",
  "Tx Hashes": "Хэши транзакций",
  "Continue to {{bridge}}": "Перейти к {{bridge}}",
  "By clicking Continue to {{bridge}}, you will be sent to {{bridge}} website to bridge your funds out of Paradex.":
    "Нажав на Перейти к {{bridge}}, вы будете перенаправлены на сайт {{bridge}} для перевода ваших средств из Paradex.",
  "By clicking Continue to {{bridge}}, you will be sent to {{bridge}} website to bridge your funds to Paradex.":
    "Нажав на Перейти к {{bridge}}, вы будете перенаправлены на сайт {{bridge}} для перевода ваших средств на Paradex.",
  "Refer": "Рефер",
  "Address": "Адрес",
  "Date Joined": "Дата регистрации",
  "Referral Code": "Реферальный код",
  "Volume Traded": "Объем сделок",
  "Bridge": "Мост",
  "Withdraw to": "Вывод на",
  "Deposit from": "Депозит из",
  "Position is already closed": "Позиция уже закрыта",
  "Your Paradex L2 Address is NOT a Public Starknet Address. If you try to send funds to this address on Public Starknet via a cross chain bridge you risk losing your funds. <1>Read more</1>.":
    "Ваш адрес Paradex L2 НЕ является публичным адресом Starknet. Если вы попытаетесь отправить средства на этот адрес на публичном Starknet через мост, вы рискуете потерять свои средства. <1>Подробнее</1>.",
  "{{chain}} Address": "Адрес {{chain}}",
  "Copy Paradex Private Key": "Скопировать приватный ключ Paradex",
  "Your account will be liquidated if Margin Ratio reaches 100%":
    "Ваш счет будет ликвидирован, если маржинальное отношение достигнет 100%",
  "Liquidation": "Ликвидация",
  "Fee": "Комиссия",
  "Maker": "Мейкер",
  "Maker Score": "Счет мейкера",
  "Maker XP Share": "Доля XP мейкера",
  "Fee XP Share": "Доля XP комиссии",
  "Fee XP": "XP комиссии",
  "Maker XP": "XP мейкера",
  "Liquidation {{side}} Fill": "Ликвидация {{side}}",
  "Settings": "Настройки",
  "Market Score": "Очки рынка",
  "Season {{season}} (coming soon)": "Сезон {{season}} (скоро)",
  "Confirm Buy": "Подтвердить покупку",
  "Confirm Sell": "Подтвердить продажу",
  "Other XP": "Другие очки",
  "Estimated Receivable Amount": "Ожидаемая сумма к получению",
  "Socialized Loss active. Click to learn more.":
    "Активизирован Socialized Loss. Нажмите, чтобы узнать больше.",
  "Analytics": "Аналитика",
  "Sign In to see your account XP stats":
    "Войдите, чтобы увидеть статистику XP вашего аккаунта",
  "<1>Clock Is Out Of Sync</1> The clock on your device is out of sync. This may cause issues with the application. Please make sure your device clock is set to be auto. For more details please <2>see documentation</2>":
    "<1>Часы не синхронизированы</1> Часы на вашем устройстве не синхронизированы. Это может вызвать проблемы с приложением. Пожалуйста, убедитесь, что часы вашего устройства установлены на автоматически. Для получения дополнительной информации, пожалуйста, <2>ознакомьтесь с документацией</2>",
  "Failed to fetch max withdrawable amount":
    "Не удалось получить максимальную сумму вывода",
  "It is not possible to withdraw more than your Max Withdrawable Amount.":
    "Невозможно вывести больше, чем ваша максимальная сумма вывода.",
  "Max Withdrawable Amount": "Максимальная сумма вывода",
  "XP Explainer": "Что такое XP?",
  "Pool": "Уровень",
  "Quote Quality": "Качество предложения",
  "Create Your Public Username": "Создать ваш публичный логин",
  "Edit Your Public Username": "Редактировать ваш публичный логин",
  "You have been logged out.": "Вы вышли из системы.",
  "Maker Volume Score": "Счет объема мейкера",
  "Fee Score": "Очки комиссий",
  "Score Share": "Доля очков",
  "Instrument Share": "Доля инструмента",
  "XP": "XP",
  "Total XP": "Общее количество XP",
  "24h Change": "24ч Изменение",
  "Leaderboard": "Таблица лидеров",
  "Cancel": "Отмена",
  "Portfolio": "Портфель",
  "Trade": "Торговля",
  "Build": "Разработчикам",
  "Referrals Explainer": "Что такое рефералы?",
  "How It Works": "Как это работает",
  "My Referrals": "Мои рефералы",
  "Your Referral Code": "Ваш реферальный код",
  "Affiliate Program": "Партнерская программа",
  "Enrolled": "Зарегистрирован",
  "Traders Referred": "Приглашенные трейдеры",
  "Referral Rewards": "Награды за рефералов",
  "Referral XP": "XP за рефералов",
  "Invite Friends": "Пригласить друзей",
  "Share": "Поделиться",
  "Earn": "Заработать",
  "Give": "Дать",
  "Share your code to invite friends to join Paradex":
    "Поделитесь своим кодом, чтобы пригласить друзей присоединиться к Paradex",
  "<1>{{amount}}</1> Fees": "<1>{{amount}}</1> комиссии",
  "You receive {{amount}} of the referred user's fees":
    "Вы получаете {{amount}} от комиссии рекомендуемого пользователя",
  "<1>{{amount}}</1> XP": "<1>{{amount}}</1> XP",
  "You receive {{amount}} of the referred user's XP":
    "Вы получаете {{amount}} от XP рекомендуемого пользователя",
  "<1>{{amount}}</1> Discount": "Скидка <1>{{amount}}</1>",
  "Your friend receives {{amount}} discount on fees":
    "Ваш друг получает скидку {{amount}} на комиссии",
  "Create and share your custom referral code":
    "Создайте и поделитесь своим индивидуальным реферальным кодом",
  "If you have a strong social media following (<1>{{followers}}+</1>), you may be eligible for our <2>VIP Affiliate Program</2> with higher commissions and XP.":
    "Если у вас сильная социальная медиа-аудитория (<1>{{followers}}+</1>), вы можете быть приглашены в нашу <2>VIP Партнерскую программу</2> с более высокими комиссиями и XP.",
  "You can track our <1>Affiliate Leaderboard</1> to see how you stack up.":
    "Вы можете следить за нашим <1>Таблицей лидеров партнеров</1>, чтобы увидеть, как вы сравниваетесь с другими.",
  "Apply": "Подать заявку",
  "Log in to see your Referral History.":
    "Войдите, чтобы увидеть историю ваших рефералов.",
  "Welcome to Paradex": "Добро пожаловать на Paradex",
  "Congrats! The referral code you used is now active! It means you can start enjoying a discount on your trading fees!":
    "Поздравляем! Используемый вами реферальный код активирован! Это означает, что вы можете начать получать скидку на комиссии за торговлю!",
  "Congrats! The referral code <1>{{codeProvided}}</1> used is now active!":
    "Поздравляем! Используемый вами реферальный код <1>{{codeProvided}}</1> активирован!",
  "Referral code not activated. The code can only be applied once during the initial onboarding.":
    "Реферальный код не активирован. Код можно применить только один раз во время первоначальной регистрации.",
  "Referral code <1>{{codeProvided}}</1> not activated. You have already onboarded with <1>{{referredBy}}</1>.":
    "Реферальный код <1>{{codeProvided}}</1> не активирован. Вы уже зарегистрировались с <1>{{referredBy}}</1>.",
  "Referral Code Applied": "Реферальный код применен",
  "You will receive <1>{{userAmount}}</1> of referred user's <1>fees</1> <2>and</2> <1>{{userAmountPoints}} XP</1>. They will receive a <1>{{friendAmount}}</1> discount.":
    "Вы получите <1>{{userAmount}}</1> от <1>комиссии</1> рекомендуемого пользователя <2>и</2> <1>{{userAmountPoints}} XP</1>. Они получат скидку <1>{{friendAmount}}</1>.",
  "Community": "Сообщество",
  "Environments": "Среды",
  "Language": "Язык",
  "System Status": "Состояние системы",
  "Docs": "Документы",
  "Code Samples": "Примеры кода",
  "More": "Больше",
  "Stats": "Статистика",
  "Join Discord": "Присоединиться к Discord",
  "Notifications": "Уведомления",
  "All caught up!": "Все обновления просмотрены!",
  "You have no new notifications": "У вас нет новых уведомлений",
  "Connect Wallet": "Подключить кошелек",
  "WalletConnect is taking too long to open. Please refresh the page and try again.":
    "WalletConnect занимает слишком много времени для открытия. Пожалуйста, обновите страницу и попробуйте еще раз.",
  "Unexpected error opening WalletConnect modal. Please refresh the page and try again.":
    "Неожиданная ошибка при открытии модального окна WalletConnect. Пожалуйста, обновите страницу и попробуйте еще раз.",
  "Connect your Wallet": "Подключите ваш кошелек",
  "Link Wallet": "Связать кошелек",
  "You will receive a signature request. Signing is free and will not send a transaction.":
    "Вы получите запрос на подпись. Подписание бесплатно и не отправит транзакцию.",
  "Generate Paradex Chain wallet": "Создать кошелек Paradex Chain",
  "Generating Paradex Chain wallet": "Создание кошелька Paradex Chain",
  "Paradex Chain wallet generated": "Кошелек Paradex Chain создан",
  "Verify that you own this wallet":
    "Подтвердить, что вы владеете этим кошельком",
  "Remember Me": "Запомнить меня",
  "Only use 'Remember Me' if you are using a secure device you own. Selecting this option can expose your information to  others if you are accessing this wallet from a public device.":
    'Используйте "Запомнить меня" только если вы используете свое собственное безопасное устройство. Выбор этого параметра может раскрыть вашу информацию другим, если вы получаете доступ к этому кошельку с общедоступного устройства.',
  "Continue": "Продолжить",
  "Disconnect Wallet": "Отключить кошелек",
  "Disconnect": "Отключить",
  "Account": "Аккаунт",
  "Username": "Имя пользователя",
  "Edit": "Редактировать",
  "Your username is now hidden. It is not visible to other users.":
    "Ваше имя пользователя теперь скрыто. Оно не видно другим пользователям.",
  "Your username will be visible to other users unless you choose to hide it. You can always change it later.":
    "Ваше имя пользователя будет видно другим пользователям, если вы не выберете его скрытие. Вы всегда можете изменить его позже.",
  "Hide my Username": "Скрыть мое имя пользователя",
  "Save": "Сохранить",
  "Username is required": "Требуется имя пользователя",
  "Username must be between 5 and 20 characters":
    "Имя пользователя должно быть от 5 до 20 символов",
  "Username can only contain letters and numbers":
    "Имя пользователя может содержать только буквы и цифры",
  "Wallets": "Кошельки",
  "Paradex Block Explorer": "Обозреватель блоков Paradex",
  "Log out": "Выйти",
  "No markets available": "Нет доступных рынков",
  "Last Price": "Последняя цена",
  "Open Interest": "Открытый интерес",
  "24h Volume": "24ч Объем",
  "Mark Price": "Цена Марка",
  "Spot Price": "Спотовая цена",
  "Eight hour funding rate that is continuously accrued on positions and settled upon any trade. If the funding rate is positive, longs pay shorts. If negative, shorts pay longs.":
    "Восьмичасовая ставка финансирования, которая непрерывно начисляется на позиции и фиксируется при любой сделке. Если ставка финансирования положительная, Лонги платят Шортам. Если отрицательная, Шорты платят Лонгам.",
  "8h Funding": "8ч финансирования",
  "Limit": "Лимит",
  "Market": "Рынок",
  "Stop Limit": "Стоп-лимит",
  "Stop Market": "Стоп-маркет",
  "Scaled Order": "Масштабный ордер",
  "Trigger Price": "Цена срабатывания",
  "Input Price": "Цена входа",
  "Position Mark Price": "Цена Марка позиции",
  "Amount": "Количество",
  "Reduce Only": "Только уменьшение",
  "Reduce Only will increase position": '"Только уменьшение" увеличит позицию',
  "Your order stays open until it is filled or you decide to cancel.":
    "Ваш заказ остается открытым, пока его не исполнят или вы не решите отменить.",
  "Your order gets filled right away, partially or fully, and whatever is left gets canceled instantly.":
    "Ваш заказ немедленно исполняется частично или полностью, и все, что остается, мгновенно отменяется.",
  "Conditional limit order that serves to add liquidity to the order book as a Maker order.":
    "Условный лимитный ордер, который служит для добавления ликвидности в стакан заказов в качестве ордера Maker.",
  "Currently the fees are fixed and uniform across the platform. Maker {{makerFee}} / Taker {{takerFee}}":
    "В настоящее время комиссии фиксированы и одинаковы по всей платформе. Мейкер {{makerFee}} / Тейкер {{takerFee}}",
  "Value": "Стоимость",
  "Buy": "Купить",
  "BUY": "КУПИТЬ",
  "LONG": "ЛОНГ",
  "Sell": "Продать",
  "SELL": "ПРОДАТЬ",
  "SHORT": "ШОРТ",
  "Order Value": "Стоимость заказа",
  "Current Position": "Текущая позиция",
  "Account Value": "Стоимость аккаунта",
  "The total value of your account at current mark prices":
    "Общая стоимость вашего аккаунта по текущим ценам",
  "Unrealized P&L": "Нереализованный P&L",
  "Free Collateral": "Свободное обеспечение",
  "Account Leverage": "Плечо аккаунта",
  "Margin": "Маржа",
  "Margin Ratio": "Маржинальное отношение",
  "Initial Margin": "Начальная маржа",
  "Maintenance Margin": "Маржа обслуживания",
  "Order Book": "Книга заказов",
  "Trades": "Сделки",
  "Price": "Цена",
  "Size": "Размер",
  "Total": "Всего",
  "Time": "Время",
  "Spread": "Спред",
  "Positions": "Позиции",
  "Open Orders": "Открытые заказы",
  "Trade History": "История сделок",
  "Order History": "История заказов",
  "Funding Payments": "Платежи по финансированию",
  "Side": "Сторона",
  "Entry Price": "Цена входа",
  "Liquidation Price": "Цена ликвидации",
  "Unrealized Funding": "Нереализованное финансирование",
  "Close Position": "Закрыть позицию",
  "Market Close": "Закрыть по Рынку",
  "Close Long": "Закрыть Лонг",
  "Close Short": "Закрыть Шорт",
  "Type": "Тип",
  "Filled": "Заполнено",
  "Triggered": "Сработало",
  "Instruction": "Инструкция",
  "Client Order Id": "ID заказа клиента",
  "Status": "Статус",
  "NEW": "НОВЫЙ",
  "OPEN": "ОТКРЫТЫЙ",
  "UNTRIGGERED": "Не активировано",
  "CLOSED": "ЗАКРЫТЫЙ",
  "Pending": "В ожидании",
  "Complete": "Завершено",
  "Completed": "Завершено",
  "Failed": "Неудачно",
  "Bust": "Обрушение",
  "Value / Fee": "Стоимость / Комиссия",
  "Liquidity": "Ликвидность",
  "Tx Hash": "Хэш транзакции",
  "Trade Id": "Идентификатор сделки",
  "Remaining": "Остаток",
  "Cancel Reason": "Причина отмены",
  "User Canceled": "Пользователь отменил",
  "Not Enough Margin": "Недостаточная маржа",
  "Empty Market": "Пустой рынок",
  "Post Only": "Только публикация",
  "Post Only Would Cross": "Только публикация пересечет",
  "Remaining IOC Cancel": "Оставшаяся отмена IOC",
  "Unexpected Failure": "Неожиданная ошибка",
  "Deleverage": "Снижение плеча",
  "In Liquidation": "В ликвидации",
  "Self Trade": "Самостоятельная торговля",
  "Asset Unavailable": "Актив недоступен",
  "Asset Expired": "Срок действия актива истек",
  "Order Type Invalid": "Недопустимый тип заказа",
  "Price Not Available": "Цена недоступна",
  "Expired": "Истекший",
  "Exceeds Max Slippage": "Превышает максимальный проскальзывание",
  "Timeout": "Тайм-аут",
  "Order Exceeds Position Limit": "Заказ превышает лимит позиции",
  "Order Id": "Id заказа",
  "Payment": "Оплата",
  "Cancel All": "Отменить все",
  "{{orderSize}} will be closed at Market price":
    "{{orderSize}} будет закрыт по рыночной цене",
  "By connecting a wallet, you agree to <1>Paradex Terms of Service</1> and represent and warrant to Paradex that you are not a <2>Restricted Person</2>.":
    "Подключая кошелек, вы соглашаетесь с <1>Условиями использования Paradex</1> и гарантируете Paradex, что вы не являетесь <2>Ограниченным лицом</2>.",
  "Wallet": "Кошелек",
  "Deposit_verb": "Внести",
  "Deposit_noun": "Депозит",
  "Withdraw": "Вывести",
  "Withdrawal": "Вывод",
  "Transfers": "Переводы",
  "Transfer": "Перевод",
  "Collateral": "Залог",
  "Asset": "Актив",
  "Wallet Balance": "Баланс кошелька",
  "Actions": "Действия",
  "Action": "Действие",
  "External Tx Hash": "Хэш Транзакции Внешнего",
  "Withdraw to Wallet": "Вывести в кошелек",
  "Deposit to Paradex": "Депозит в Paradex",
  "You have no balance in your Ethereum wallet. Deposit some {{asset}} and try again.":
    "У вас нет баланса в вашем кошельке Ethereum. Внесите немного {{asset}} и попробуйте снова.",
  "It is not possible to deposit more than your current balance.":
    "Невозможно внести больше, чем ваш текущий баланс.",
  "Available": "Доступно",
  "Initiate a deposit from your Ethereum wallet to the Paradex bridge. These transactions":
    "Инициируйте депозит из вашего кошелька Ethereum на мост Paradex. Эти транзакции",
  "Initiate a deposit from your Ethereum wallet to the Paradex bridge. These transactions may take several minutes depending on network conditions. For more information check out our <1>docs</1>.":
    "Инициируйте депозит из вашего кошелька Ethereum на мост Paradex. Эти транзакции могут занять несколько минут в зависимости от условий сети. Для получения дополнительной информации ознакомьтесь с нашими <1>документами</1>.",
  "read more": "читать дальше",
  "Initiate Deposit to Bridge": "Инициировать депозит на мост",
  "Enable USDC on Paradex": "Включить USDC на Paradex",
  "In order to deposit you must allow Paradex access to at least {{amount}}.":
    "Чтобы сделать депозит, вы должны разрешить Paradex доступ как минимум к {{amount}}.",
  "Enable USDC": "Включить USDC",
  "Withdraw initiated": "Вывод инициирован",
  "Withdrawals are a two-step process. First initiate a withdrawal from Paradex":
    "Выводы осуществляются в два этапа. Сначала инициируйте вывод из Paradex",
  "Withdrawals are a two-step process. First initiate a withdrawal from Paradex to the Paradex Bridge. This first step can take 5-16 hours (10 on average). Second, initiate your withdrawal from the Paradex Bridge to your Ethereum wallet via the Deposits / Withdrawals table. For more information check out our <1>docs</1>.":
    "Выводы осуществляются в два этапа. Сначала инициируйте вывод из Paradex на мост Paradex. Этот первый шаг может занять 5-16 часов (10 в среднем). Во-вторых, инициируйте вывод с моста Paradex в ваш кошелек Ethereum через таблицу Депозиты / Выводы. Для получения дополнительной информации ознакомьтесь с нашими <1>документами</1>.",
  "Initiate Withdrawal to Bridge": "Инициировать вывод на мост",
  "You have no funds to withdraw. It is not possible to withdraw more than your free collateral.":
    "У вас нет средств для вывода. Невозможно вывести больше, чем ваше свободное обеспечение.",
  "It is not possible to withdraw more than your free collateral.":
    "Невозможно вывести больше, чем ваше свободное обеспечение.",
  "You have no collateral": "У вас нет обеспечения",
  "You have no open positions": "У вас нет открытых позиций",
  "You have no open orders": "У вас нет открытых заказов",
  "You have no trades": "У вас нет сделок",
  "You have no orders": "У вас нет заказов",
  "You have no funding payments": "У вас нет платежей по финансированию",
  "You have no transfers": "У вас нет переводов",
  "Account in liquidation": "Счет в процессе ликвидации",
  "Account is being liquidated. Any open positions will be reduced. Trading now is disabled.":
    "Счет ликвидируется. Все открытые позиции будут уменьшены. Торговля сейчас отключена.",
  "Account liquidated": "Счет ликвидирован",
  "Your account was liquidated on {{createdAt}}. Any open positions were reduced.":
    "Ваш счет был ликвидирован {{createdAt}}. Все открытые позиции были уменьшены.",
  "Deposit to Paradex successful": "Депозит в Paradex успешно выполнен",
  "Deposit to Paradex failed": "Депозит в Paradex не удался",
  "Unexpected error. Try again.": "Неожиданная ошибка. Попробуйте еще раз.",
  "Deposit initiated": "Депозит инициирован",
  "Deposit to bridge initiated": "Инициирован депозит на мост",
  "Deposit to Paradex available": "Доступен депозит в Paradex",
  "Go to Deposits": "Перейти к депозитам",
  "Deposit to Paradex in progress": "Депозит в Paradex в процессе",
  "Transfer successful": "Перевод успешен",
  "Limit {{side}} Order Fill": "Лимит {{side}} Заполнение заказа",
  "{{type}} {{side}} Order Submitted": "{{type}} {{side}} Заказ отправлен",
  "{{type}} {{side}} Order Canceled": "{{type}} {{side}} Заказ отменен",
  "Reason": "Причина",
  "Market {{side}} Order Fill": "Рыночное {{side}} Заполнение заказа",
  "Trade Busted On-Chain": "Сделка сорвана в блокчейне",
  "Fill id": "ID заполнения",
  "Withdraw to wallet failed": "Снятие на кошелек не удалось",
  "Withdrawal to bridge initiated": "Инициирован вывод на мост",
  "Withdrawal error. Try again.": "Ошибка вывода. Попробуйте еще раз.",
  "Withdrawal to bridge in progress": "Вывод на мост в процессе",
  "Withdrawal to wallet initiated": "Инициирован вывод на кошелек",
  "Withdrawal to bridge failed": "Вывод на мост не удался",
  "Transaction error. Try again.": "Ошибка транзакции. Попробуйте еще раз.",
  "Withdrawal to wallet available": "Вывод на кошелек доступен",
  "Go to Withdrawals": "Перейти к выводам",
  "Withdrawal to wallet successful": "Вывод на кошелек успешно выполнен",
  "Session expired, please log in again.":
    "Сессия истекла, пожалуйста, войдите снова.",
  "Failed to submit order": "Не удалось отправить заказ",
  "Your username preferences were updated!":
    "Ваши предпочтения имени пользователя были обновлены!",
  "Unexpected error updating username preferences":
    "Неожиданная ошибка при обновлении предпочтений имени пользователя",
  "This username already exists. Please choose a different username and try again.":
    "Это имя пользователя уже существует. Пожалуйста, выберите другое имя пользователя и попробуйте снова.",
  "Validation Error": "Ошибка валидации",
  "Binding Error": "Ошибка привязки",
  "Internal Error": "Внутренняя ошибка",
  "Not Found": "Не найдено",
  "Service Unavailable": "Сервис недоступен",
  "Invalid Request Parameter": "Неверный параметр запроса",
  "Order Id Not Found": "Id заказа не найден",
  "Order Is Closed": "Заказ закрыт",
  "Order Is Not Open Yet": "Заказ еще не открыт",
  "Client Order Id Not Found": "Id заказа клиента не найден",
  "Duplicated Client Id": "Дублированный Id клиента",
  "Invalid Price Precision": "Недопустимая точность цены",
  "Invalid Token": "Недопустимый токен",
  "Invalid Ethereum Address": "Недопустимый адрес Ethereum",
  "Blocked Ethereum Address": "Заблокированный адрес Ethereum",
  "Invalid Ethereum Signature": "Недопустимая подпись Ethereum",
  "Invalid Starknet Address": "Недопустимый адрес Starknet",
  "Invalid Starknet Signature": "Недопустимая подпись Starknet",
  "Starknet Signature Verification Failed":
    "Не удалось проверить подпись Starknet",
  "Bad Starknet Request": "Плохой запрос Starknet",
  "Ethereum Signer Mismatch": "Несоответствие подписанта Ethereum",
  "Ethereum Hash Mismatch": "Несоответствие хэша Ethereum",
  "Not Onboarded": "Не зарегистрирован",
  "Invalid Timestamp": "Недопустимая метка времени",
  "Invalid Signature Expiration": "Недопустимый срок истечения подписи",
  "Account Not Found": "Счет не найден",
  "Invalid Order Signature": "Недопустимая подпись заказа",
  "Public Key Invalid": "Недействительный открытый ключ",
  "Unauthorized Ethereum Address": "Несанкционированный адрес Ethereum",
  "Ethereum Address Already Onboarded": "Адрес Ethereum уже зарегистрирован",
  "Market Not Found": "Рынок не найден",
  "Allowlist Entry Not Found": "Запись в белом списке не найдена",
  "Username In Use": "Имя пользователя уже используется",
  "Service access restricted in your region":
    "Доступ к сервису ограничен в вашем регионе",
  "No Access Allowed": "Доступ не разрешен",
  "You do not have access to the platform. Please join our Discord to get access.":
    "У вас нет доступа к платформе. Пожалуйста, присоединитесь к нашему Discord, чтобы получить доступ.",
  "{{actualChainName}} Wallet Connected": "Кошелек Ethereum подключен",
  'Incorrect network selected "{{actualChainName}}". Please change the network to "{{requiredChainName}}" (id={{requiredChainId}}) in your wallet and retry.':
    'Выбрана неверная сеть "{{actualChainName}}". Пожалуйста, измените сеть на "{{requiredChainName}}" (id={{requiredChainId}}) в вашем кошельке и попробуйте снова.',
  "Choose Wallet": "Выберите кошелек",
  "Starknet Wallet": "Кошелек Starknet",
  "Switch to {{env}}": "Переключиться на {{env}}",
  "Search": "Поиск",
  "League": "Лига",
  "Switch Account": "Переключить аккаунт",
  "Rank": "Ранг",
  "24h XP": "24ч XP",
  "XP Per Day": "XP в день",
  "Season Volume": "Объем сезона",
  "Lifetime Volume": "Объем за все время",
  "Pre-Season": "Pre-Season",
  "Earn More XP": "Зарабатывайте больше XP",
  "XP Boost": "Буст XP",
  "XP Drop": "Дроп XP",
  "Trader": "Трейдер",
  "Total Share": "Общая доля",
  "Maker Share": "Доля мейкера",
  "Maker Volume Share": "Доля объема мейкера",
  "Fee Share": "Доля комиссии",
  "Fee Volume Share": "Доля объема комиссии",
  "No data available": "Нет доступных данных",
  "Refer & Earn": "Пригласить и заработать",
  "Hours": "Часы",
  "Minutes": "Минуты",
  "Time/Duration": "Время/Продолжительность",
  "Runtime": "Время выполнения",
  "Frequency": "Частота",
  "Number of Orders": "Количество заказов",
  "Runtime should not be empty": "Время выполнения не должно быть пустым",
  "Runtime should be less than or equal to 24 hours":
    "Время выполнения должно быть меньше или равно 24 часам",
  "Inactive": "Неактивный",
  "Avg. Price": "Средняя цена",
  "The minimum required amount for {{numOrders}} orders is {{totalSize}} {{currency}}.":
    "Минимальная требуемая сумма для {{numOrders}} заказов составляет {{totalSize}} {{currency}}.",
  "Take Profit / Stop Loss: Set take profit and stop loss price levels to automate trade actions. When the Mark Price will reach the price you set, it will send your order to the matching engine.":
    "Тейк-профит / Стоп-лосс: Установите уровни цен тейк-профита и стоп-лосса для автоматизации торговых действий. Когда рыночная цена достигнет установленного вами уровня, ваш ордер будет отправлен в систему сопоставления заявок.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount with an estimated profit of <3 />.":
    "Когда рыночная цена достигнет <1 />, будет активирован ордер <2 />, чтобы закрыть выбранную сумму с предполагаемой прибылью <3 />.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount with an estimated loss of <3 />.":
    "Когда рыночная цена достигнет <1 />, будет активирован ордер <2 />, чтобы закрыть выбранную сумму с предполагаемым убытком <3 />.",
  "Edit TP/SL For This Position": "Редактировать TP/SL для этой позиции",
  "Take Profit": "Take Profit",
  "Profit": "Прибыль",
  "Stop Loss": "Stop Loss",
  "Loss": "Убыток",
  "Confirm": "Подтвердить",
  "Take Profit Limit": "Лимитный Take Profit",
  "Take Profit Market": "Рыночный Take Profit",
  "Stop Loss Limit": "Лимитный Stop Loss",
  "Stop Loss Market": "Рыночный Stop Loss",
  "Funding": "Финансирование",
  "Unwinding": "Закрытие позиций",
  "Cancel TP/SL orders": "Отменить ордера TP/SL",
  "TP/SL orders submitted successfully.": "TP/SL ордера отправлены успешно.",
  "Pre-Season + All Seasons": "Предсезон + Все сезоны",
  "Season {{season}}": "Сезон {{season}}",
  "XP Season {{season}}": "Сезон {{season}} XP",
  "Slippage": "Проскальзывание",
  "Max Slippage": "Максимальное проскальзывание",
  "Max slippage defines the largest acceptable price deviation from the mark price.":
    "Максимальное проскальзывание определяет наибольшее допустимое отклонение цены от рыночной цены.",
  "During execution, the order can be canceled if the price exceeds the slippage limit or falls outside the Market <1>Price Band</1>.":
    "Во время исполнения ордер может быть отменён, если цена превысит предел проскальзывания или выйдет за пределы рыночного <1>ценового диапазона</1>.",
  "Update Max Slippage": "Обновить максимальное проскальзывание",
  "The Maximum Slippage allowed for {{symbol}} is {{price_band_width}}.":
    "Максимальное проскальзывание для {{symbol}} составляет {{price_band_width}}.",
  "Mark IV": "Mark IV",
  "Delta": "Дельта",
  "Failed to load positions": "Не удалось загрузить позиции",
  "No open positions": "Нет открытых позиций",
  "learn more": "узнать больше",
  "Learn More": "Узнать Больше",
  "Search Markets": "Поиск Рынков",
  "Futures": "Фьючерсы",
  "Options": "Опционы",
  "OI": "OI",
  "My Profile": "Мой профиль",
  "Addresses": "Адреса",
  "Chart": "График",
  "ROI": "ROI",
  "Sharpe": "Шарп",
  "Max Loss": "Максимальная потеря",
  "Total Volume": "Общий объем",
  "My NFTs": "Мои NFT",
  "My XP": "Мой XP",
  "Balances": "Балансы",
  "Other": "Другие",
  "Paradex XP: Pre-Season": "Paradex XP: Предсезон",
  "Paradex XP: Season {{season}}": "Paradex XP: Сезон {{season}}",
  "Season {{season}} XP": "Сезон {{season}} XP",
  "Refer your friends to earn 10%": "Пригласите друзей и получите 10%",
  "Refer Friends and Earn 10%": "Пригласите друзей и получите 10%",
  "Invest in Paradex Vault & Earn 3X Boost":
    "Инвестируйте в Paradex Vault и получите 3X Boost",
  "Avg. Daily XP": "Средняя суточная XP",
  "Lifetime XP": "Вся жизнь XP",
  "My Rank": "Мой ранг",
  "What is XP?": "Что такое XP?",
  "XP History": "История XP",
  "Last Week XP": "Последняя неделя XP",
  "TVL XP": "TVL XP",
  "OI XP": "OI XP",
  "Liquidation XP": "Liquidation XP",
  "Vaults XP": "Vaults XP",
  "Week": "Неделя",
  "Season": "Сезон",
  "Last updated: {{timestamp}}": "Последнее обновление: {{timestamp}}",
  "Xperience Points (XP)": "Очки опыта (XP)",
  "Log in to see your XP balance": "Войдите, чтобы увидеть ваш баланс XP",
  "Log in to see where you stand": "Войдите, чтобы увидеть, где вы стоите",
  "Connect your X account to join Pro League":
    "Подключите ваш X-кошелек, чтобы присоединиться к Про-Лиге",
  "Avg. Fill Price": "Средняя цена исполнения",
  "Spot": "Спот",
  "Derivatives": "Производные",
  "P&L Chart": "График P&L",
  "Account Value Chart": "График стоимости счета",
  "Funding History": "История финансирования",
  "Manage Funds": "Управление средствами",
  "Profile": "Профиль",
  "Community XP": "Комьюнити XP",
  "XP Share": "Доля XP",
  "Next XP Drop in": "След. XP Drop через",
  "In Progress": "В процессе",
  "{{field}} must be greater than {{value}}":
    "{{field}} должно быть больше {{value}}",
  "{{field}} must be less than {{value}}":
    "{{field}} должно быть меньше {{value}}",
  "Enter a valid Take Profit or Stop Loss price":
    "Введите допустимую цену Take Profit или Stop Loss",
  "Take Profit and Stop Loss prices cannot be the same":
    "Цены Take Profit и Stop Loss не могут быть одинаковыми",
  "Breakeven Price": "Цена безубыточности",
  "Automatic Withdrawal": "Автоматический вывод",
  "Estimated Gas Fee": "Оценка газовой комиссии",
  "Auto Withdrawal": "Автоматический вывод",
  "Withdrawing...": "Вывод в процессе...",
  "Paradex Bridge withdrawals can take between 5-16 hours to complete":
    "Выводы Paradex Bridge могут занять между 5-16 часов для завершения",
  "Socialized Loss": "Социализация убытка",
  "Receivable Amount": "Сумма к получению",
  "Auto withdrawing...": "Автоматический вывод в процессе...",
  "Manage Twitter Connection": "Управление подключением Twitter",
  "Unlock additional functionality.": "Разблокируйте дополнительные функции.",
  "Please note this connection can't be used as a login method to Paradex.":
    "Обратите внимание, что это подключение не может быть использовано в качестве метода входа в Paradex.",
  "Toast Notifications": "Уведомления Toast",
  "Display toast notifications": "Отображать уведомления Toast",
  "Average Entry Price": "Средняя цена входа",
  "Display average entry price line on chart":
    "Отображать линию средней цены входа на графике",
  "Display liquidation price line on chart":
    "Отображать линию цены ликвидации на графике",
  "Display open order lines on chart":
    "Отображать линии открытых ордеров на графике",
  "Failed to disconnect Twitter": "He удалось отключить Twitter",
  "Failed to authorize Twitter": "He удалось авторизовать Twitter",
  "Twitter account linked successfully!": "Аккаунт Twitter подключен успешно!",
  "Failed to link twitter account": "He удалось подключить аккаунт Twitter",
  "Fees": "Комиссия",
  "Taker pays {{takerFee}} in fees. Maker receives {{makerRebate}} in rebates.":
    "Тейкер платит {{takerFee}} в качестве комиссии. Мейкер получает {{makerRebate}} в качестве скидок.",
  "Manage Discord Connection": "Управление подключением Discord",
  "Failed to authorize Discord": "Не удалось авторизовать Discord",
  "Discord account linked successfully!": "Аккаунт Discord подключен успешно!",
  "Failed to link Discord account": "Не удалось подключить аккаунт Discord",
  "Failed to disconnect Discord": "Не удалось отключить Discord",
  "Connect": "Подключить",
  "Unrealized P&L from current vault token balance. This doesn't account for owner profit share or slippage from positions unwind.":
    "Нереализованная прибыль/убыток от текущего баланса токена валюты. Это не учитывает долю прибыли владельца или проскальзывание из-за развертывания позиций.",
  "Show P&L Amount": "Показать P&L",
  "Receivable amount must be positive":
    "Сумма к получению должна быть положительной",
  "Wallet Address Change Detected": "Обнаружено изменение адреса кошелька",
  "You can proceed with connecting your Paradex account to this wallet address or continue using your current connection.":
    "Вы можете продолжить подключение своего аккаунта Paradex к этому адресу кошелька или продолжить использовать текущее подключение.",
  "Connect To New Wallet": "Подключиться к новому кошельку",
  "Keep Using Current Wallet": "Продолжить использование текущего кошелька",
  "Connect Current Wallet": "Подключить текущий кошелек",
  "The account is not connected. Please make sure that <1>{{walletAddress}}</1> is connected and is the active account in your <2>{{walletName}}</2> wallet.":
    "Аккаунт не подключен. Пожалуйста, убедитесь, что <1>{{walletAddress}}</1> подключен и является активным аккаунтом в вашем кошельке <2>{{walletName}}</2>.",
  "Reconnecting": "Повторное подключение",
  "There is a request in progress. Please proceed in your wallet.":
    "Запрос выполняется. Пожалуйста, продолжите в вашем кошельке.",
  "Modify Order": "Изменить заказ",
  "Realized P&L": "Реализованная P&L",
  "Close All": "Закрыть все",
  "Close All Positions": "Закрыть все позиции",
  "Market Close All Positions": "Рынок закрывает все позиции",
  "Close {{percent}}% of All Positions": "Закрыть {{percent}}% всех позиций",
  "Failed to close all positions": "Не удалось закрыть все позиции",
  "Number of Positions": "Количество позиций",
  "Markets": "Рынки",
  "Position Value (Closing)": "Стоимость позиции (закрытие)",
  "{{count}} positions will be rounded to the nearest order increment":
    "{{count}} позиций будет округлено до ближайшего увеличения заказа",
  "You will earn Bonus XP on your referral activity":
    "Вы получите XP Bonus за активность в реферальной программе",
  "Token Price": "Цена токена",
  "Vault Token Price Chart": "График цены токена валюты",
  "Market Close Orders Submitted": "Рыночные заявки на закрытие поданы",
  "This profile is already linked to a different Paradex account. Disconnect socials from existing account and try again.":
    "Этот профиль уже подключен к другому аккаунту Paradex. Отключите социальные сети от вашего текущего аккаунта и попробуйте снова.",
  "XP Drops every Friday": "XP падает каждую пятницу",
  "No XP Data Available": "Нет данных XP",
  "XP data is available only for the Main Account.":
    "Данные XP доступны только для основного аккаунта.",
  "Please switch to your Main Account to view your XP.":
    "Пожалуйста, переключитесь на основной аккаунт, чтобы увидеть ваш XP.",
  "Referrals Not Available": "Рефералы недоступны",
  "Referrals are available only for the Main Account.":
    "Рефералы доступны только для основного аккаунта.",
  "Please switch to your Main Account to view your Referrals.":
    "Пожалуйста, переключитесь на основной аккаунт, чтобы увидеть ваши рефералы.",
  "Deposit initiated via Layerswap": "Депозит инициирован через Layerswap",
  "Position History": "История позиций",
  "Avg. Close Price": "Средняя цена закрытия",
  "Max Size": "Максимальный размер",
  "Closed Size": "Закрытый размер",
  "Opened": "Открыто",
  "You have no positions": "У вас нет позиций",
  "Destination Address": "Адрес назначения",
  "Withdrawal via Layerswap initiated": "Вывод через Layerswap инициирован",
  "Withdrawal via Layerswap failed": "Вывод через Layerswap не удалось",
  "Market Share": "Доля рынка",
  "Tier": "Уровень",
  "Avg Maker Volume": "Средний объем Maker",
  "Liquidity Score": "Счет ликвидности",
  "Reward Share": "Доля вознаграждения",
  "Enter amount": "Введите количество",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount at <3 /> with an estimated profit of <4 />.":
    "Когда рыночная цена достигнет <1 />, будет запущен ордер <2 />, чтобы закрыть выбранное количество по цене <3 /> с предполагаемой прибылью <4 />.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount at <3 /> with an estimated loss of <4 />.":
    "Когда рыночная цена достигнет <1 />, будет запущен ордер <2 />, чтобы закрыть выбранное количество по цене <3 /> с предполагаемым убытком <4 />.",
  "Limit Price": "Лимитная цена",
  "Delete": "Удалить",
  "Use other bridges from the list above to withdraw funds much faster.":
    "Используйте другие мосты из списка выше, чтобы вывести средства намного быстрее.",
  "Paradex Chain Monitor": "Монитор цепи Paradex",
  "OTM Amount": "Сумма OTM",
  "Leverage": "Кредитное плечо",
  "Strikes": "Страйки",
  "Strike": "Страйк",
  "Change": "Изменение",
  "Dist. to Spot": "Расст. до спота",
  "Bid": "Покупка",
  "Mark": "Марк",
  "Ask": "Продажа",
  "IV": "IV",
  "Dist %": "Расст. %",
  "Call": "Колл",
  "Put": "Пут",
  "Mark Price / IV": "Цена Марк / IV",
  "8h F-Funding": "8ч Ф-Финансирование",
  "No Strikes Available": "Нет доступных страйков",
  "Attributes": "Атрибуты",
  "Why no Expiries?": "Почему нет сроков истечения?",
  "{{multiplier}}x Boost": "{{multiplier}}x Буст",
  "Like perpetual futures, perpetual options have no expiration, offering continuous exposure to the underlying asset with built-in downside protection. This gives users the flexibility to hold their position indefinitely and decide the optimal time to close it based on their strategy":
    "Как и бессрочные фьючерсы, бессрочные опционы не имеют срока истечения, предлагая непрерывное воздействие на базовый актив со встроенной защитой от падения. Это дает пользователям гибкость в удержании позиции на неопределенный срок и возможность решить оптимальное время для ее закрытия на основе их стратегии",
  "Implied Volatility used to calculate the option's Mark Price":
    "Подразумеваемая волатильность, используемая для расчета цены Марк опциона",
  "Distance between the Strike Price and the underlying Spot Price":
    "Расстояние между ценой страйка и базовой спотовой ценой",
  "Eight hour funding that is continuously accrued on positions and settled upon any trade. If the funding rate is positive, longs pay shorts. If negative, shorts pay longs":
    "Восьмичасовое финансирование, которое непрерывно начисляется на позиции и рассчитывается при любой сделке. Если ставка финансирования положительная, лонги платят шортам. Если отрицательная, шорты платят лонгам",
  "The Leverage Ratio (Lambda) measures an option's effective leverage by indicating how much the option's value changes, in percentage terms, for a 1% change in the spot price of the underlying asset. For example, if an option has a Lambda of 50 and the spot price increases by 1%, the option's value would increase by 50%":
    "Коэффициент кредитного плеча (Лямбда) измеряет эффективное кредитное плечо опциона, показывая, насколько изменяется стоимость опциона в процентном выражении при изменении спотовой цены базового актива на 1%. Например, если опцион имеет Лямбду 50 и спотовая цена увеличивается на 1%, стоимость опциона увеличится на 50%",
  "Option delta measures how much the price of an option is expected to change for a $1 change in the price of the underlying asset. For example, a delta of 0.5 means the option price will move $0.50 for every $1 move in the underlying":
    "Дельта опциона измеряет, насколько ожидается изменение цены опциона при изменении цены базового актива на $1. Например, дельта 0,5 означает, что цена опциона будет двигаться на $0,50 за каждое движение базового актива на $1",
  "Eight hour funding rate of the perpetual future on the same underlying asset":
    "Восьмичасовая ставка финансирования бессрочного фьючерса на тот же базовый актив",
  "Having trouble? Try signing out and connecting again.":
    "Возникли проблемы? Попробуйте выйти и подключиться снова.",
  "API Reference": "Справочник API",
  "XP Distribution occurs once per week on Friday at ~12 PM UTC":
    "Распределение XP происходит раз в неделю в пятницу в ~12:00 UTC",
  "What are Perpetual Options?": "Что такое бессрочные опционы?",
  "Delta represents the increase in option price based on a $1 increase in the underlying spot price.\n\nFor example, a delta of 0.50 means the price of the option is expected to increase by $0.50 for a $1 increase in the underlying spot price.":
    "Дельта представляет увеличение цены опциона на основе увеличения базовой спотовой цены на $1.\n\nНапример, дельта 0,50 означает, что цена опциона, как ожидается, увеличится на $0,50 при увеличении базовой спотовой цены на $1.",
  "Leverage(Lambda) represents the percentage change in option price for a 1% change in the underlying spot price.\n\nFor example, a lambda of 10 means the option price is expected to increase by 10% if the underlying spot price increases by 1%.":
    "Кредитное плечо(Лямбда) представляет процентное изменение цены опциона при изменении базовой спотовой цены на 1%.\n\nНапример, лямбда 10 означает, что цена опциона, как ожидается, увеличится на 10%, если базовая спотовая цена увеличится на 1%.",
  "Eight hour options funding rate that is continuously accrued on positions and settled upon any trade. The funding rate for perpetual options reflects both the cost of leverage and the cost of insurance against adverse price moves. \n\nThe funding period of perpetual options is 24 hours. This means that the option premium will be continuously paid out over a 24h period. 24h funding rate = 3 x 8h funding rate":
    "Восьмичасовая ставка финансирования опционов, которая непрерывно начисляется на позиции и рассчитывается при любой сделке. Ставка финансирования для бессрочных опционов отражает как стоимость кредитного плеча, так и стоимость страхования от неблагоприятных движений цены. \n\nПериод финансирования бессрочных опционов составляет 24 часа. Это означает, что премия опциона будет непрерывно выплачиваться в течение 24-часового периода. 24-часовая ставка финансирования = 3 x 8-часовая ставка финансирования",
  "Risk": "Риск",
  "Vega": "Вега",
  "Gamma": "Гамма",
  "Estimated Funding P&L for the next 8 hours based on current funding rates":
    "Оценочный P&L финансирования на следующие 8 часов на основе текущих ставок финансирования",
  "Vega represents the increase in option price based on a 1% increase in the implied volatility.\n\nFor example, a Vega of 5 means the price of the option is expected to increase by $5 for a 1% increase in the implied volatility.":
    "Вега представляет увеличение цены опциона на основе увеличения подразумеваемой волатильности на 1%.\n\nНапример, Вега 5 означает, что цена опциона, как ожидается, увеличится на $5 при увеличении подразумеваемой волатильности на 1%.",
  "Adjust Leverage": "Настроить кредитное плечо",
  "Leverage updated successfully": "Кредитное плечо успешно обновлено",
  "Failed to update leverage": "Не удалось обновить кредитное плечо",
  "Adjust leverage parameters for selected market. Leverage will also increase on your open positions.":
    "Настройте параметры кредитного плеча для выбранного рынка. Кредитное плечо также увеличится на ваших открытых позициях.",
  "Please note that changing leverage will also apply for open positions and open orders on this market.":
    "Обратите внимание, что изменение кредитного плеча также применится к открытым позициям и открытым ордерам на этом рынке.",
  "Selecting higher leverage could result in Deleveraging where some of your open orders would be cancelled.":
    "Выбор более высокого кредитного плеча может привести к делевериджу, при котором некоторые ваши открытые ордера будут отменены.",
  "disabled": "отключено",
  "Margin Mode": "Режим маржи",
  "Manage your risk on individual positions by restricting the amount of margin allocated to each. If the margin ratio of an isolated position reaches 100%, the position will be liquidated. Margin can be added or removed to individual positions in this mode.":
    "Управляйте своим риском на отдельных позициях, ограничивая количество маржи, выделенной для каждой. Если коэффициент маржи изолированной позиции достигает 100%, позиция будет ликвидирована. В этом режиме маржа может быть добавлена или удалена для отдельных позиций.",
  "Margin mode updated successfully": "Режим маржи успешно обновлен",
  "Failed to update margin mode": "Не удалось обновить режим маржи",
  "Favorites": "Избранное",
  "Trending": "Тренды",
  "Losers": "Проигравшие",
  "Gainers": "Выигравшие",
  "New Listing": "Новый листинг",
  "Developers": "Разработчики",
  "Developer Mode": "Режим разработчика",
  "Enable developer mode": "Включить режим разработчика",
  "For external use": "Для внешнего использования",
  "Warning: Changing transfer behavior":
    "Предупреждение: Изменение поведения передачи",
  "USDC will be transferred to account contract instead of Paradex. Amount won't be visible or available for use within Paradex, but can be used with external applications.":
    "USDC будет передан в контракт счета вместо Paradex. Сумма не будет видима или доступна для использования в Paradex, но может быть использована с внешними приложениями.",
  "Affiliate Dashboard": "Панель партнерских программ",
};

export default translations;
