import { adapter } from '#/features/paraclear-deposit/state';

import type { ParaclearDepositState } from '#/features/paraclear-deposit/state';

export const reducer = {
  depositStart: (id: string) => (state: ParaclearDepositState) => ({
    ...state,
    deposits: adapter.addOne(state.deposits, {
      transferId: id,
      status: 'PENDING',
    }),
  }),

  depositSuccess: (id: string) => (state: ParaclearDepositState) => ({
    ...state,
    deposits: adapter.updateOne(state.deposits, {
      id,
      changes: { status: 'COMPLETED' },
    }),
  }),

  depositFailure: (id: string) => (state: ParaclearDepositState) => ({
    ...state,
    deposits: adapter.updateOne(state.deposits, {
      id,
      changes: { status: 'FAILED' },
    }),
  }),
};
