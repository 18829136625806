import { useEffect, useRef } from 'react';
import { isAfter, subDays, subHours } from 'date-fns';

import { getLiquidations } from '#/api/liquidations';
import { UnixTimeMs } from '#/api/types';

import { logException } from '#/features/logging/logging';
import { useWalletView } from '#/features/wallet/wallet-context';

import useLocalStorage from '#/utils/useLocalStorage';
import useUserStorageKey from '#/utils/useUserStorageKey';

import { publishNotification } from './topic';

/** Keep showing history for the recent liquidations */
const HOURS_THRESHOLD = 3 * 24;

export function useLiquidationHistoryNotifications() {
  const wasCalledOnce = useRef(false);
  const walletView = useWalletView();
  const [lastLiquidatedAt, setLastLiquidatedAt] =
    useLocalStorage<null | UnixTimeMs>(
      useUserStorageKey('last-liquidated-at'),
      null,
    );

  useEffect(() => {
    if (!walletView.isSignedIn) return;
    if (wasCalledOnce.current) return;

    wasCalledOnce.current = true;
    (async () => {
      const from = subDays(Date.now(), 7).getTime();
      const resp = await getLiquidations({ from });

      if (!resp.ok) {
        const message = 'Failed to retrieve liquidation history';
        logException(new Error(message, { cause: resp.error }));
        return;
      }

      const liquidationsSortedLastToFist = [...resp.data.results].sort(
        (a, b) => b.created_at.getTime() - a.created_at.getTime(),
      );

      displayLatestLiquidationOnce();
      initializeNotificationHistoryWithRecentLiquidations();

      /** Show toast with last liquidation once, no matter how old */
      function displayLatestLiquidationOnce() {
        const lastLiquidation = liquidationsSortedLastToFist[0];

        if (lastLiquidation == null) {
          return;
        }

        if (
          lastLiquidatedAt != null &&
          !isAfter(lastLiquidation.created_at, lastLiquidatedAt)
        ) {
          return;
        }

        setLastLiquidatedAt(lastLiquidation.created_at.getTime());
        publishNotification({
          kind: 'account-was-liquidated',
          props: { liquidation: lastLiquidation },
        });
      }

      /** Initialize Notification History with the last few days of liquidation events */
      function initializeNotificationHistoryWithRecentLiquidations() {
        const recentLiquidations = liquidationsSortedLastToFist.filter(
          (liquidation) =>
            isAfter(
              liquidation.created_at,
              subHours(Date.now(), HOURS_THRESHOLD),
            ),
        );
        recentLiquidations.forEach((liquidation) => {
          publishNotification({
            displayToast: false,
            kind: 'account-was-liquidated',
            props: { liquidation },
          });
        });
      }
    })().catch((cause: unknown) => {
      const message =
        'Unknown error while checking for recent liquidation events';
      const error = new Error(message, { cause });
      logException(error);
    });
  }, [lastLiquidatedAt, setLastLiquidatedAt, walletView.isSignedIn]);
}
