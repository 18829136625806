import { Market } from '#/api/markets';

import * as MarketFn from '#/features/market/functions';

import { OrderFormState } from './form-state';

import type { Maybe } from '#/utils/types';

export function amountDecimalPlacesView(
  state: Pick<OrderFormState, 'asset' | 'amount'>,
  activeMarket: Maybe<Market>,
): number {
  if (activeMarket == null || state.amount == null) {
    return 0;
  }

  if (state.asset === activeMarket.base_currency) {
    return MarketFn.baseCurrencyDecimalPlaces(activeMarket);
  }

  if (state.asset === activeMarket.quote_currency) {
    return MarketFn.quoteCurrencyDecimalPlaces(activeMarket);
  }

  return 0;
}

export function limitPriceDecimalPlacesView(
  state: Pick<OrderFormState, 'limitPrice'>,
  activeMarket: Maybe<Market>,
): number {
  if (state.limitPrice == null) return 0;
  if (activeMarket == null) return 0;

  return MarketFn.quoteCurrencyDecimalPlaces(activeMarket);
}
