import BigNumber from 'bignumber.js';

import { Maybe } from '#/utils/types';

export function sortBigNumbers(a: Maybe<BigNumber>, b: Maybe<BigNumber>) {
  if (a == null) {
    return -1;
  }
  if (b == null) {
    return 1;
  }
  return a.comparedTo(b);
}

export function sortMaybeNumbers(a: Maybe<number>, b: Maybe<number>) {
  if (a == null) {
    return -1;
  }
  if (b == null) {
    return 1;
  }
  return a - b;
}

export function sortDatesDesc(a: Date, b: Date) {
  return b.getTime() - a.getTime();
}
