import { milliseconds } from 'date-fns';

import { VaultSummary } from '#/api/vaults-summary';

import { formatUsd } from '#/utils/fiat';

import type BigNumber from 'bignumber.js';
import type { Vault } from '#/api/vaults';
import type { VaultAccountSummary } from '#/api/vaults-account-summary';
import type { ParadexWallet } from '#/features/wallets/paraclear/wallet-context';

/**
 * Calculate the TVL of a vault in USD.
 * It uses the VToken price, which is denominated in USD.
 */
export function calcVaultTvlUsd(vaultSummary: VaultSummary) {
  const tokenSupply = vaultSummary.vtoken_supply;
  const tokenPrice = vaultSummary.vtoken_price;

  if (tokenPrice == null) return null;
  return tokenSupply.times(tokenPrice);
}

/**
 * Calculate the remaining lockup period in milliseconds.
 */
export function calcRemainingLockup(
  vault: Vault,
  options: {
    /** Average deposit time in milliseconds */
    readonly avgDepositTime: number;
    /** Reference time in milliseconds */
    readonly referenceTime: number;
  },
): number {
  const lockupPeriodMs = vault.lockup_period
    .times(milliseconds({ days: 1 }))
    .toNumber();
  const msSinceDeposit = options.referenceTime - options.avgDepositTime;
  const remainingLockupMs = lockupPeriodMs - msSinceDeposit;

  if (remainingLockupMs <= 0) return 0;

  return remainingLockupMs;
}

export function isVaultInLockupPeriod(
  vault: Vault,
  options: {
    readonly avgDepositTime: number;
    readonly referenceTime: number;
  },
) {
  const remainingLockup = calcRemainingLockup(vault, options);
  return remainingLockup > 0;
}

/**
 * Whether the minimum deposit applies to the current vault.
 * Min deposit applies to the initial deposit from the vault owner.
 */
export function minDepositApplies(
  vault: Vault,
  vaultSummary: VaultSummary,
  paradexWallet: ParadexWallet,
) {
  const isOwner = paradexWallet.account?.address === vault.owner_account;
  if (!isOwner) return false;

  const hasDeposited = vaultSummary.vtoken_supply.gt(0);
  if (hasDeposited) return false;

  return true;
}

export function formatVaultTokenPrice(value: BigNumber) {
  return formatUsd(value, {
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
  });
}

export function calcAvgEntryPrice(
  vaultAccountSummary: Pick<
    VaultAccountSummary,
    'vtoken_amount' | 'deposited_amount'
  >,
): BigNumber | null {
  const tokenAmount = vaultAccountSummary.vtoken_amount;
  if (tokenAmount.isZero()) return null;
  const depositedAmount = vaultAccountSummary.deposited_amount;
  const avgEntryPrice = depositedAmount.div(tokenAmount);
  return avgEntryPrice;
}

/**
 * Calculate P&L of a user's vault deposit.
 */
export function calcPnl(
  vaultSummary: Pick<VaultSummary, 'vtoken_price'>,
  vaultAccountSummary: Pick<
    VaultAccountSummary,
    'vtoken_amount' | 'deposited_amount'
  >,
): BigNumber | null {
  const currentPrice = vaultSummary.vtoken_price;
  if (currentPrice == null) return null;

  const avgEntryPrice = calcAvgEntryPrice(vaultAccountSummary);
  if (avgEntryPrice == null) return null;

  const tokenAmount = vaultAccountSummary.vtoken_amount;

  return calcBasicPnl(avgEntryPrice, currentPrice, tokenAmount);
}

/**
 * Calculate P&L using the following formula:
 * P&L = (Current Price - Avg. Entry Price) * Token Amount
 */
export function calcBasicPnl(
  avgEntryPrice: BigNumber,
  currentPrice: BigNumber,
  amount: BigNumber,
): BigNumber {
  const priceDelta = currentPrice.minus(avgEntryPrice);
  const pnl = amount.times(priceDelta);
  return pnl;
}
