import BigNumber from 'bignumber.js';

import { OrderSide } from '#/api/orders';

import { OpenOrdersStoreView } from '#/features/perpetuals/trade-overview/open-orders-context';

/**
 * Adapted from
 * @see https://github.com/tradeparadigm/mono/blob/release/paradex-1.29.3/api/paradex/prototype/asset/synthetic_assets/synthetic_asset.py#L186
 */
export default function total_order_size(
  market: string,
  account: string,
  side: OrderSide,
  /** should include all of the 'OPEN', 'NEW', 'UNTRIGGERED' */
  openOrdersStore: OpenOrdersStoreView,
): BigNumber {
  const orders = openOrdersStore.getAll();
  const ordersForMarket = orders.filter(
    (order) => order.market === market && order.account === account,
  );
  // Get the orders for the given side
  const oneSideOrders = ordersForMarket.filter((order) => order.side === side);

  const totalSize = oneSideOrders.reduce(
    (acc, order) => acc.plus(order.remaining_size),
    BigNumber(0),
  );

  return totalSize;
}
