import BigNumber from 'bignumber.js';

import { Position } from '#/api/positions';

import { ActiveAccountView } from '#/features/account/account-context';
import { logException } from '#/features/logging/logging';
import * as MarketFn from '#/features/market/functions';
import * as OrderFn from '#/features/order/functions';
import * as BboFn from '#/features/perpetuals/bbo/functions';
import { MarketsStoreView } from '#/features/perpetuals/market-info/markets-context';
import { MarketsSummaryStoreView } from '#/features/perpetuals/market-info/markets-summary-context';
import { OpenPositionsStore } from '#/features/perpetuals/positions/open-positions-store-context';

import { Maybe } from '#/utils/types';

import get_order_size_from_free_collateral_usage from './cross-margin/get_order_size_from_free_collateral_usage';
import { INITIAL_STATE } from './form-state';

import type { Bbo } from '#/api/bbo';
import type { Market, PerpetualMarket } from '#/api/markets';
import type {
  MarketSummary,
  PerpetualMarketSummary,
} from '#/api/markets-summary';
import type { OpenOrdersStoreView } from '#/features/perpetuals/trade-overview/open-orders-context';
import type { OrderFormState } from './form-state';

export function getOrderTypeByForm(formType: OrderFormState['formType']) {
  switch (formType) {
    case 'LIMIT':
    case 'MARKET':
    case 'STOP_LIMIT':
    case 'STOP_MARKET':
      return formType;
    case 'TWAP':
      return 'MARKET';
    case 'SCALED_ORDER':
      return 'LIMIT';
    /* no default */
  }
}
export function getPriceByOrderType(
  state: Pick<OrderFormState, 'orderType' | 'limitPrice' | 'triggerPrice'>,
  market: Market,
  marketSummary: MarketSummary,
): BigNumber | null {
  switch (market.asset_kind) {
    case 'PERP_OPTION':
      switch (state.orderType) {
        case 'LIMIT':
        case 'MARKET':
          return marketSummary.underlying_price ?? null;
        // WIP: Implement STOP_LIMIT and STOP_MARKET for PERP_OPTION
        case 'STOP_LIMIT':
        case 'STOP_MARKET':
        default:
          return null;
      }
    case 'PERP':
      switch (state.orderType) {
        case 'LIMIT':
        case 'STOP_LIMIT':
          return state.limitPrice;
        case 'MARKET':
          return marketSummary.mark_price ?? null;
        case 'STOP_MARKET':
          return state.triggerPrice;
        // no default
      }
    // no default
  }
}

export function convertAmountToQuoteAsset(
  // WIP: investigate refactor to do not call function with potential null `asset`
  asset: string | null,
  amount: BigNumber,
  price: BigNumber,
  market: Market,
): BigNumber {
  if (asset == null) {
    return amount;
  }

  if (asset === market.base_currency) {
    return amount.multipliedBy(price);
  }

  if (asset === market.quote_currency) {
    return amount;
  }

  throw new Error(
    `${asset} does not match market ${market.base_currency}/${market.quote_currency} asset`,
  );
}

export function convertAmountToBaseAsset(
  // WIP: investigate refactor to do not call function with potential null `asset`
  asset: string | null,
  amount: BigNumber,
  price: BigNumber,
  activeMarket: Market,
): BigNumber | null {
  if (asset == null) {
    return amount;
  }

  if (asset === activeMarket.base_currency) {
    return amount;
  }

  if (asset === activeMarket.quote_currency) {
    const amountBaseAsset = amount.dividedBy(price);
    const value = amountBaseAsset.isFinite()
      ? amountBaseAsset.decimalPlaces(
          MarketFn.baseCurrencyDecimalPlaces(activeMarket),
        )
      : null;

    return value;
  }

  throw new Error(
    `${asset} does not match market ${activeMarket.base_currency}/${activeMarket.quote_currency} asset`,
  );
}

export function mid(
  activeMarket: Pick<Market, 'price_tick_size'>,
  bbo: Pick<Bbo, 'ask' | 'bid'>,
): BigNumber | null {
  if (bbo.ask.isZero() || !bbo.ask.isFinite()) return null;
  if (bbo.bid.isZero() || !bbo.bid.isFinite()) return null;

  const bboMid = BboFn.mid(bbo);
  if (!bboMid.isFinite()) return null;

  return MarketFn.roundToQuoteDecimalPlaces(activeMarket, bboMid);
}

export function minAmountInQuoteAsset(
  market: Pick<Market, 'min_notional' | 'price_tick_size' | 'asset_kind'>,
) {
  const valueRaw = market.min_notional;
  const value = MarketFn.roundToQuoteDecimalPlaces(
    market,
    valueRaw,
    BigNumber.ROUND_UP,
  );
  const formatted = value.toFormat(
    MarketFn.quoteCurrencyDecimalPlaces(market),
    BigNumber.ROUND_UP,
  );
  return { value, formatted };
}

export function minAmountInBaseAsset(
  market: Pick<Market, 'min_notional' | 'order_size_increment' | 'asset_kind'>,
  marketSummary: Pick<MarketSummary, 'mark_price' | 'underlying_price'>,
) {
  if (marketSummary.mark_price == null) {
    return null;
  }
  if (marketSummary.underlying_price == null) {
    return null;
  }

  switch (market.asset_kind) {
    case 'PERP_OPTION': {
      const valueRaw = OrderFn.convertAmountToBaseAsset(
        market.min_notional,
        marketSummary.underlying_price,
      );
      const value = MarketFn.roundToBaseDecimalPlaces(
        market,
        valueRaw,
        BigNumber.ROUND_UP,
      );
      const formatted = value.toFormat(
        MarketFn.baseCurrencyDecimalPlaces(market),
        BigNumber.ROUND_UP,
      );
      return { value, formatted };
    }
    case 'PERP': {
      const valueRaw = OrderFn.convertAmountToBaseAsset(
        market.min_notional,
        marketSummary.mark_price,
      );
      const value = MarketFn.roundToBaseDecimalPlaces(
        market,
        valueRaw,
        BigNumber.ROUND_UP,
      );
      const formatted = value.toFormat(
        MarketFn.baseCurrencyDecimalPlaces(market),
        BigNumber.ROUND_UP,
      );
      return { value, formatted };
    }
    // no default
  }
}

export function estimateLimitPriceForLeverageCalculation(
  orderFormState: Pick<
    OrderFormState,
    'limitPrice' | 'side' | 'triggerPrice' | 'orderType'
  >,
  market: PerpetualMarket,
  marketSummary: PerpetualMarketSummary,
): BigNumber | null {
  switch (orderFormState.orderType) {
    case 'LIMIT': {
      return orderFormState.limitPrice;
    }
    case 'STOP_LIMIT': {
      return orderFormState.limitPrice;
    }
    case 'MARKET': {
      if (marketSummary.mark_price == null) return null;
      // mark price * (1 +/- price band)
      return marketSummary.mark_price.multipliedBy(
        orderFormState.side === 'BUY'
          ? BigNumber(1).plus(market.price_bands_width)
          : BigNumber(1).minus(market.price_bands_width),
      );
    }
    case 'STOP_MARKET': {
      if (orderFormState.triggerPrice == null) return null;
      // trigger price * (1 +/- price band)
      return orderFormState.triggerPrice.multipliedBy(
        orderFormState.side === 'BUY'
          ? BigNumber(1).plus(market.price_bands_width)
          : BigNumber(1).minus(market.price_bands_width),
      );
    }
    // no default
  }
}

export function calcPriceBands(
  price: Maybe<BigNumber>,
  activeMarket: Maybe<PerpetualMarket>,
  activeMarketSummary: Maybe<PerpetualMarketSummary>,
) {
  if (price == null) throw new Error('Price required');
  if (activeMarket == null) throw new Error('Active Market required');
  if (activeMarketSummary?.mark_price == null)
    throw new Error(`Mark price required`);

  const minPrice = activeMarketSummary.mark_price.multipliedBy(
    BigNumber(1).minus(activeMarket.price_bands_width),
  );
  const minPriceRounded = MarketFn.roundToQuoteDecimalPlaces(
    activeMarket,
    minPrice,
    BigNumber.ROUND_DOWN,
  );

  const maxPrice = activeMarketSummary.mark_price.multipliedBy(
    BigNumber(1).plus(activeMarket.price_bands_width),
  );
  const maxPriceRounded = MarketFn.roundToQuoteDecimalPlaces(
    activeMarket,
    maxPrice,
    BigNumber.ROUND_UP,
  );

  return {
    min: minPriceRounded,
    max: maxPriceRounded,
  };
}

export function isLeverageDisabled(
  formState: Pick<
    OrderFormState,
    'orderType' | 'limitPrice' | 'triggerPrice' | 'isReduceOnly'
  >,
) {
  switch (formState.orderType) {
    case 'LIMIT':
      if (formState.limitPrice == null) return true;
      break;
    case 'STOP_LIMIT':
      if (formState.limitPrice == null) return true;
      if (formState.triggerPrice == null) return true;
      break;
    case 'STOP_MARKET':
      if (formState.triggerPrice == null) return true;
      break;
    case 'MARKET':
    default:
      return false;
  }

  return false;
}

interface AmountBasedOnCollateralLeverageArgs {
  formState: OrderFormState;
  openOrdersStore: OpenOrdersStoreView;
  openPositionsStore: OpenPositionsStore;
  activeMarket: Market;
  activeMarketSummary: MarketSummary | undefined;
  marketsSummaryStore: MarketsSummaryStoreView;
  marketsStore: MarketsStoreView;
  activeAccountView: ActiveAccountView;
  maxSlippage: BigNumber | null;
  accountImfBase: BigNumber | null;
}

export function getAmountBasedOnMarginLeverage({
  formState,
  openOrdersStore,
  openPositionsStore,
  activeMarket,
  activeMarketSummary,
  marketsSummaryStore,
  marketsStore,
  activeAccountView,
  maxSlippage,
  accountImfBase,
}: AmountBasedOnCollateralLeverageArgs) {
  try {
    const isDisabled = isLeverageDisabled(formState);
    if (isDisabled) {
      return formState;
    }

    if (formState.leverage.isEqualTo(0)) {
      return formState;
    }

    const market = marketsStore.getMarket(activeMarket.symbol);
    const marketSummary = marketsSummaryStore.getMarketSummary(
      activeMarket.symbol,
    );

    if (market == null) {
      throw new Error(
        `Market is not defined for symbol='${activeMarket.symbol}'`,
      );
    }
    if (marketSummary == null) {
      throw new Error(
        `Market Summary is not defined for symbol='${activeMarket.symbol}'`,
      );
    }

    if (marketSummary.mark_price == null) {
      throw new Error(
        `Market Summary mark_price is not defined for symbol='${activeMarket.symbol}'`,
      );
    }

    const leveragePercent = formState.leverage.dividedBy(100);
    const limitPrice = estimateLimitPriceForLeverageCalculation(
      formState,
      market,
      marketSummary,
    );

    if (limitPrice == null) {
      throw new Error(
        `Failed to estimate 'Price' for type='${formState.orderType}' Order`,
      );
    }
    /**
     * WIP:
     *  1. Investigate(and fix?) why calculation for `STOP_MARKET` produces different results
     *      for SELL and BUY, when no open positions and no open orders are present
     */
    const estimatedAmount = get_order_size_from_free_collateral_usage(
      activeMarket.symbol,
      formState.side,
      limitPrice,
      leveragePercent,
      openPositionsStore,
      openOrdersStore,
      marketsSummaryStore,
      marketsStore,
      activeAccountView,
      maxSlippage,
      accountImfBase,
    );

    if (!estimatedAmount.isFinite()) {
      throw new Error(
        'order builder :: getAmountBasedOnMarginLeverage :: estimatedAmount is not finite',
      );
    }

    const amountInCurrentAsset =
      formState.asset === market.quote_currency
        ? convertAmountToQuoteAsset(
            market.base_currency,
            estimatedAmount,
            marketSummary.mark_price,
            market,
          )
        : estimatedAmount;

    if (!amountInCurrentAsset.isFinite()) {
      throw new Error(
        'order builder :: getAmountBasedOnMarginLeverage :: amountInCurrentAsset is not finite',
      );
    }

    const roundedAmountInCurrentAsset = MarketFn.roundAmountByAsset(
      market,
      formState.asset ?? market.base_currency,
      amountInCurrentAsset,
      BigNumber.ROUND_DOWN,
    );

    if (!roundedAmountInCurrentAsset.isFinite()) {
      throw new Error(
        'order builder :: getAmountBasedOnMarginLeverage :: roundedAmountInCurrentAsset is not finite',
      );
    }

    return {
      ...formState,
      amount: roundedAmountInCurrentAsset,
    };
  } catch (cause: unknown) {
    const message =
      'order builder :: getAmountBasedOnMarginLeverage :: Leverage Slider calculation failed';
    const error = new Error(message, { cause });

    const openPositions = openPositionsStore
      .getAll()
      .filter((position) => position.size.abs().gt(0));
    const openOrders = openOrdersStore.getAll();
    const marketSymbols = (() => {
      const set = new Set<string>();
      openPositions.forEach((position) => set.add(position.market));
      openOrders.forEach((order) => set.add(order.market));
      return set;
    })();
    const markets = marketsStore
      .getAllMarkets()
      .filter((market) => marketSymbols.has(market.symbol));
    const marketSummaries = marketsSummaryStore
      .getAll()
      .filter((summary) => marketSymbols.has(summary.symbol));

    const details = {
      input: (() => {
        try {
          return JSON.stringify({
            formState,
            activeMarket,
            activeMarketSummary,
            openPositions,
            openOrders,
            activeAccountView,
            markets,
            marketSummaries,
          });
        } catch (err) {
          const msg = `order builder :: getAmountBasedOnMarginLeverage :: unexpected error serializing error details object err='${String(
            err,
          )}'`;
          logException(new Error(msg));
          return msg;
        }
      })(),
    };
    logException(error, details);

    return {
      ...formState,
      leverage: INITIAL_STATE['leverage'],
      amount: INITIAL_STATE['amount'],
    };
  }
}

export function getPositionAmountForCurrentAsset(
  currentAsset: string,
  price: BigNumber,
  position: Pick<Position, 'size'>,
  market: Maybe<Market>,
) {
  const absSize = position.size.abs();
  if (market == null) return absSize;
  if (market.base_currency === currentAsset) return absSize;

  return convertAmountToQuoteAsset(
    market.base_currency,
    absSize,
    price,
    market,
  );
}

export function getAmountPositionPercentage(
  position: Maybe<Position>,
  state: OrderFormState,
  activeMarket: Maybe<Market>,
  activeMarketSummary: Maybe<MarketSummary>,
) {
  if (activeMarketSummary == null) return null;
  if (activeMarket == null) return null;
  if (state.amount == null) return BigNumber(0);

  const price = getPriceByOrderType(state, activeMarket, activeMarketSummary);
  if (price == null || state.asset == null || position == null) return null;

  const maxAmount = getPositionAmountForCurrentAsset(
    state.asset,
    price,
    position,
    activeMarket,
  );

  const newLeveragePercentage = BigNumber.min(
    state.amount
      .dividedBy(maxAmount)
      .times(100)
      .decimalPlaces(0, BigNumber.ROUND_FLOOR),
    100,
  );

  return newLeveragePercentage;
}
