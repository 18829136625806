import { isKnownDerivationPathFormat } from '#/features/wallets/paraclear/derivation';

import type { SubAccountKind } from '#/api/account';
import type { SubAccountsStore } from '#/features/sub-accounts/store';
import type { VaultsOperatorsStore } from '#/features/vaults/operators/store';
import type { ParadexAccount } from '#/features/wallets/paraclear/wallet';

export type AccountKind = 'main' | SubAccountKind;

export function accountKindView(
  account: ParadexAccount,
  subAccountsStore: SubAccountsStore,
): AccountKind {
  if (account.level === 'main-account') {
    return 'main';
  }
  const subAccount = subAccountsStore.subAccounts.getById(account.address);
  return subAccount?.kind ?? 'subaccount';
}

export function subAccountLabelView(
  account: ParadexAccount,
  subAccountsStore: SubAccountsStore,
  vaultsOperatorsStore: VaultsOperatorsStore,
): string {
  const subAccount = subAccountsStore.subAccounts.getById(account.address);

  switch (subAccount?.kind) {
    case 'isolated':
      return subAccount.isolated_market ?? 'Isolated Market';
    case 'vault_operator': {
      if (account.derivation == null) {
        return `Sub-Account with no derivation path`;
      }

      const vault = vaultsOperatorsStore.get(account.address);
      if (vault != null) {
        return vault.vaultName;
      }

      if (isKnownDerivationPathFormat(account.derivation.path)) {
        return `Sub-Account ${account.derivation.index}`;
      }

      return `Sub-Account path=${account.derivation.path}`;
    }
    case 'vault_sub_operator': {
      if (subAccount.username === '') return 'Vault Strategy';
      return subAccount.username;
    }
    case 'subaccount':
    case undefined:
      if (account.derivation == null) {
        return `Sub-Account with no derivation path`;
      }

      if (isKnownDerivationPathFormat(account.derivation.path)) {
        return `Sub-Account ${account.derivation.index}`;
      }

      return `Sub-Account path=${account.derivation.path}`;
    // no default
  }
}
