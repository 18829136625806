import { Keys } from "./en";

const translations: Record<Keys, string> = {
  "Display trade history on chart": "Hiển thị lịch sử giao dịch trên biểu đồ",
  "Add markets to Favorites in the Markets Browser":
    "Hãy thêm thị trường vào Yêu thích trong Trình duyệt Thị trường",
  "Log in": "Đăng nhập",
  "Export embedded wallet": "Xuất ví tích hợp",
  "Email or Social": "Email hoặc Mạng xã hội",
  "Failed to load balances": "Không tải được số dư",
  "No balances": "Không có số dư",
  "Verified Operator": "Người vận hành được xác thực",
  "Strategy": "Chiến lược",
  "Multi-Strategy": "Chiến lược đa dạng",
  "Master Vault": "Kho Bạc Chính",
  "Master Vault Details": "Chi tiết Kho Bạc Chính",
  "Annualized": "Hàng năm",
  "Depositors": "Người gửi tiền",
  "No trade history": "Không có lịch sử giao dịch",
  "Unwinding Progress": "Tiến độ giải thể",
  "What are Vaults?": "Kho bạc là gì?",
  "APR calculated as the Annualised 30-day Return":
    "APR được tính toán như lợi nhuận hàng năm trong 30 ngày",
  "APR": "APR",
  "Initializing": "Đang khởi tạo",
  "Awaiting Deposit": "Đang chờ gửi tiền",
  "Vault Age": "Tuổi Kho Bạc",
  "30D Return": "Lợi Nhuận 30N",
  "{{field}} must be greater than the minimum initial deposit of {{minInitialDeposit}}":
    "{{field}} phải lớn hơn số tiền gửi tối thiểu {{minInitialDeposit}}",
  "Minimum deposit": "Số tiền gửi tối thiểu",
  "Withdrawal amount exceeds available vault shares":
    "Số tiền rút sẽ vượt quá số phần của kho bạc",
  "Deposit would exceed maximum vault TVL of {{maxTVL}}":
    "Số tiền gửi sẽ vượt quá TVL tối đa của kho bạc {{maxTVL}}",
  "{{field}} is required": "{{field}} là bắt buộc",
  "Lockup Remaining": "Thời gian khóa còn lại",
  "{{days}}D": "{{days}}N",
  "<1>{{days}}</1> D": "<1>{{days}}</1> N",
  "<1>{{hours}}</1> H": "<1>{{hours}}</1> G",
  "<1>{{minutes}}</1> M": "<1>{{minutes}}</1> P",
  "Vault settings submitted": "Cài đặt Kho Bạc đã được gửi",
  "Vault settings updated": "Cài đặt Kho Bạc đã được cập nhật",
  "Vault Settings": "Cài đặt Kho Bạc",
  "Vault not found": "Kho bạc không tồn tại",
  "The owner receives a {{profitShare}} profit share.":
    "Chủ sở hữu nhận được {{profitShare}} lợi nhuận.",
  "This vault is entirely owned by the community.":
    "Kho bạc này hoàn toàn thuộc sở hữu cộng đồng.",
  "Withdraw from Vault submitted": "Rút từ kho bạc đã được gửi",
  "Deposit to Vault submitted": "Gửi vào kho bạc đã được gửi",
  "Unknown error. Try again.": "Lỗi không xác định. Hãy thử lại.",
  "Vault Deposit": "Gửi vào kho bạc",
  "Vault Withdrawal": "Rút từ kho bạc",
  "No Lockup": "Không khóa",
  "Lockup period is not over yet": "Thời gian khóa chưa kết thúc",
  "Protocol": "Giao thức",
  "24H": "24G",
  "7D": "7N",
  "30D": "30N",
  "All": "Tất cả",
  "You have no investments in vaults": "Bạn không có đầu tư nào trong kho bạc",
  "Total Value": "Tổng Giá Trị",
  "Investments Made": "Số Lượng Đầu Tư",
  "Operator": "Người vận hành",
  "Manage Vault": "Quản lý Kho Bạc",
  "Switch to the operator account": "Chuyển sang tài khoản vận hành",
  "Active": "Đang hoạt động",
  "My Vaults": "Kho bạc của tôi",
  "Closed": "Đã đóng",
  "Deposited": "Đã gửi",
  "Lifetime ROI": "ROI trọn đời",
  "Owners Equity": "Vốn chủ sở hữu",
  "Top Trending": "Xu hướng hàng đầu",
  "Owner Share": "Phần Sở Hữu",
  "Vault Details": "Chi Tiết Kho Bạc",
  "Contract": "Hợp Đồng",
  "Withdraw from Vault": "Rút từ Kho Bạc",
  "Withdraw from Vault successful": "Rút từ Kho Bạc thành công",
  "Failed to withdraw from Vault": "Rút từ Kho Bạc thất bại",
  "Name": "Tên",
  "Deposit": "Gửi Tiền",
  "Tokens": "Token",
  "Avg. Entry Price": "Giá Vào Trung Bình",
  "Current Price": "Giá Hiện Tại",
  "P&L": "Lãi & Lỗ",
  "My Vault Deposit": "Tiền Gửi Kho Bạc của Tôi",
  "Vaults": "Kho Bạc",
  "Create": "Tạo",
  "Create My Vault": "Tạo Kho Bạc của Tôi",
  "Vault Name": "Tên Kho Bạc",
  "Vault Description": "Mô Tả Kho Bạc",
  "Token Symbol": "Ký Hiệu Token",
  "Deposit Amount": "Số Tiền Gửi",
  "Vault description will be visible publicly and you will not be able to edit this later.":
    "Mô tả Kho Bạc sẽ được hiển thị công khai và bạn sẽ không thể chỉnh sửa sau này.",
  "Once created, deposit at least {{minDeposit}} USDC to activate the vault. At this moment, only the Max TVL can be changed later.":
    "Một khi được tạo, gửi ít nhất {{minDeposit}} USDC để kích hoạt kho bạc. Tại thời điểm này, chỉ có TVL tối đa có thể được thay đổi sau đó.",
  "Create Vault": "Tạo Kho Bạc",
  "Profit Share": "Chia Sẻ Lợi Nhuận",
  "Lockup Period (Days)": "Thời Gian Khóa (Ngày)",
  "Vault was successfully created and is being initialized":
    "Kho Bạc đã được tạo thành công và đang được khởi tạo",
  "Failed to create a vault": "Tạo kho bạc thất bại",
  "Maximum TVL": "TVL Tối Đa",
  "No limit": "Không giới hạn",
  "Vault Address": "Địa Chỉ Kho Bạc",
  "Deposit to Vault successful": "Gửi tiền vào Kho Bạc thành công",
  "Failed to deposit to Vault": "Gửi tiền vào Kho Bạc thất bại",
  "Deposit to Vault": "Gửi Tiền vào Kho Bạc",
  "Vault": "Kho Bạc",
  "Owner": "Chủ Sở Hữu",
  "Age": "Tuổi",
  "Failed to load vaults": "Tải kho bạc thất bại",
  "No vaults available": "Không có kho bạc nào",
  "Performance": "Hiệu Suất",
  "Vault Value": "Giá Trị Kho Bạc",
  "My Deposit": "Tiền Gửi của Tôi",
  "Total P&L": "Tổng Lãi & Lỗ",
  "Protocol Vaults": "Kho Bạc Giao Thức",
  "User Vaults": "Kho Bạc Người Dùng",
  "No Data": "Không Có Dữ Liệu",
  "Last Month Return": "Lợi Nhuận Tháng Trước",
  "TVL": "TVL",
  "Total Earnings": "Tổng Thu Nhập",
  "About": "Giới Thiệu",
  "Vault Stats": "Thống Kê Kho Bạc",
  "My Stats": "Thống Kê của Tôi",
  "Vault Contract": "Hợp Đồng Kho Bạc",
  "Description": "Mô Tả",
  "Max Drawdown": "Mức Sụt Giảm",
  "Volume": "Khối Lượng",
  "All-Time P&L": "Lãi & Lỗ Mọi Thời Đại",
  "Days": "Ngày",
  "Lockup Period": "Thời Gian Khóa",
  "Search Vaults": "Tìm Kiếm Kho Bạc",
  "Blog": "Blog",
  "Block Explorer": "Trình Duyệt Khối",
  "Close Vault": "Đóng Kho Bạc",
  "Close Vault successful": "Đóng Kho Bạc thành công",
  "You must close all open positions to close your Vault. Closed vaults cannot be reopened.":
    "Bạn phải đóng tất cả các vị thế mở để đóng Kho Bạc của mình. Kho bạc đã đóng không thể mở lại.",
  "Close My Vault": "Đóng Kho Bạc của Tôi",
  "Failed to Close the Vault": "Đóng Kho Bạc Thất Bại",
  "Only the Main Account can manage the vault":
    "Chỉ Tài Khoản Chính mới có thể quản lý kho bạc",
  "Allocate Collateral": "Phân Bổ Tài Sản Thế Chấp",
  "Switch to Main Account": "Chuyển sang Tài Khoản Chính",
  "Switch to Account": "Chuyển sang Tài Khoản",
  "You are currently trading in the {{market}} market.":
    "Bạn hiện đang giao dịch trên thị trường {{market}}.",
  "To start trading on Isolated Market you must Allocate Collateral from your Main Account and switch to this Market Account.":
    "Để bắt đầu giao dịch trên Thị Trường Cô Lập, bạn phải Phân Bổ Tài Sản Thế Chấp từ Tài Khoản Chính của mình và chuyển sang Tài Khoản Thị Trường này.",
  "To trade in this market, switch to the Main Account first.":
    "Để giao dịch trên thị trường này, hãy chuyển sang Tài Khoản Chính trước.",
  "This account can only trade in the {{market}} market.":
    "Tài khoản này chỉ có thể giao dịch trên thị trường {{market}}.",
  "Isolated Market": "Thị Trường Cô Lập",
  "This feature currently is still in Beta. Backup your Paradex Private Key for additional safety.":
    "Tính năng này hiện vẫn đang trong giai đoạn Beta. Sao lưu Khóa Riêng Paradex của bạn để tăng cường an toàn.",
  "Deposit failed": "Gửi tiền thất bại",
  "Deposit in progress": "Đang gửi tiền",
  "External Account": "Tài khoản bên ngoài",
  "Tx Hashes": "Hashes giao dịch",
  "Continue to {{bridge}}": "Tiếp tục tới {{bridge}}",
  "By clicking Continue to {{bridge}}, you will be sent to {{bridge}} website to bridge your funds out of Paradex.":
    "Bằng cách nhấp vào Tiếp tục tới {{bridge}}, bạn sẽ được chuyển đến trang web {{bridge}} để chuyển tiền của bạn ra khỏi Paradex.",
  "By clicking Continue to {{bridge}}, you will be sent to {{bridge}} website to bridge your funds to Paradex.":
    "Bằng cách nhấp vào Tiếp tục tới {{bridge}}, bạn sẽ được chuyển đến trang web {{bridge}} để chuyển tiền của bạn đến Paradex.",
  "Refer": "Giới thiệu",
  "Address": "Địa chỉ",
  "Date Joined": "Ngày tham gia",
  "Referral Code": "Mã giới thiệu",
  "Volume Traded": "Khối lượng giao dịch",
  "Bridge": "Cây cầu",
  "Withdraw to": "Rút vào",
  "Deposit from": "Gửi từ",
  "Position is already closed": "Vị thế đã được đóng",
  "Your Paradex L2 Address is NOT a Public Starknet Address. If you try to send funds to this address on Public Starknet via a cross chain bridge you risk losing your funds. <1>Read more</1>.":
    "Địa chỉ Paradex L2 của bạn KHÔNG phải là Địa chỉ Starknet Công khai. Nếu bạn cố gắng gửi tiền vào địa chỉ này trên Starknet Công khai thông qua một cầu chuyển chuỗi, bạn có nguy cơ mất tiền của mình. <1>Đọc thêm</1>.",
  "{{chain}} Address": "Địa chỉ {{chain}}",
  "Copy Paradex Private Key": "Sao chép Khóa riêng Paradex",
  "Your account will be liquidated if Margin Ratio reaches 100%":
    "Tài khoản của bạn sẽ bị thanh lý nếu Tỷ lệ Margin đạt 100%",
  "Liquidation": "Thanh lý",
  "Fee": "Phí",
  "Maker": "Maker",
  "Maker Score": "Maker Score",
  "Maker XP Share": "Maker XP Share",
  "Fee XP Share": "Fee XP Share",
  "Fee XP": "Fee XP",
  "Maker XP": "Maker XP",
  "Liquidation {{side}} Fill": "Thanh lý {{side}} Điền",
  "Settings": "Cài đặt",
  "Market Score": "Điểm thị trường",
  "Season {{season}} (coming soon)": "Mùa {{season}} (sắp tới)",
  "Confirm Buy": "Xác nhận Mua",
  "Confirm Sell": "Xác nhận Bán",
  "Other XP": "XP khác",
  "Estimated Receivable Amount": "Số tiền nhận dự kiến",
  "Socialized Loss active. Click to learn more.":
    "Lỗ xã hội hóa đang hoạt động. Nhấp để tìm hiểu thêm.",
  "Analytics": "Phân tích",
  "Sign In to see your account XP stats":
    "Đăng nhập để xem thống kê XP của tài khoản của bạn",
  "<1>Clock Is Out Of Sync</1> The clock on your device is out of sync. This may cause issues with the application. Please make sure your device clock is set to be auto. For more details please <2>see documentation</2>":
    "<1>Đồng hồ không đồng bộ</1> Đồng hồ trên thiết bị của bạn không đồng bộ. Điều này có thể gây ra vấn đề với ứng dụng. Vui lòng đảm bảo rằng đồng hồ thiết bị của bạn được đặt để tự động. Để biết thêm chi tiết, vui lòng <2>xem tài liệu</2>",
  "Failed to fetch max withdrawable amount": "Không thể lấy số tiền rút tối đa",
  "It is not possible to withdraw more than your Max Withdrawable Amount.":
    "Không thể rút nhiều hơn Số tiền Rút tối đa của bạn.",
  "Max Withdrawable Amount": "Số tiền Rút tối đa",
  "XP Explainer": "Giải thích XP",
  "Pool": "Cấp",
  "Quote Quality": "Chất lượng báo giá",
  "Create Your Public Username": "Tạo Tên người dùng Công khai của Bạn",
  "Edit Your Public Username": "Chỉnh sửa Tên người dùng Công khai của Bạn",
  "You have been logged out.": "Bạn đã đăng xuất.",
  "Maker Volume Score": "Tỉ số nhà sản xuất",
  "Fee Score": "Điểm Phí",
  "Score Share": "Tỷ lệ Điểm",
  "Instrument Share": "Tỷ lệ Công cụ",
  "XP": "XP",
  "Total XP": "Tổng XP",
  "24h Change": "Thay đổi 24h",
  "Leaderboard": "Bảng xếp hạng",
  "Cancel": "Hủy",
  "By connecting a wallet, you agree to <1>Paradex Terms of Service</1> and represent and warrant to Paradex that you are not a <2>Restricted Person</2>.":
    "Bằng cách kết nối ví, bạn đồng ý với <1>Điều Khoản Dịch Vụ của Paradex</1> và đại diện và bảo đảm cho Paradex rằng bạn không phải là một <2>Người bị hạn chế</2>.",
  "Positions": "Vị trí",
  "Open Orders": "Lệnh chờ",
  "Trade History": "Lịch sử giao dịch",
  "Order History": "Lịch sử đặt lệnh",
  "Funding Payments": "Thanh toán tài trợ",
  "Portfolio": "Danh mục đầu tư",
  "Trade": "Giao dịch",
  "Build": "Xây dựng",
  "Referrals Explainer": "Giải thích giới thiệu",
  "How It Works": "Hoạt động như thế nào",
  "My Referrals": "Giới thiệu của tôi",
  "Your Referral Code": "Mã giới thiệu của bạn",
  "Affiliate Program": "Chương trình liên kết",
  "Enrolled": "Đã đăng ký",
  "Traders Referred": "Người môi giới được giới thiệu",
  "Referral Rewards": "Phần thưởng giới thiệu",
  "Referral XP": "XP giới thiệu",
  "Invite Friends": "Mời bạn bè",
  "Share": "Chia sẻ",
  "Earn": "Kiếm",
  "Give": "Cho đi",
  "Share your code to invite friends to join Paradex":
    "Chia sẻ mã của bạn để mời bạn bè tham gia Paradex",
  "<1>{{amount}}</1> Fees": "<1>{{amount}}</1> Phí",
  "You receive {{amount}} of the referred user's fees":
    "Bạn nhận được {{amount}} phí của người dùng được giới thiệu",
  "<1>{{amount}}</1> XP": "<1>{{amount}}</1> XP",
  "You receive {{amount}} of the referred user's XP":
    "Bạn nhận được {{amount}} XP của người dùng được giới thiệu",
  "<1>{{amount}}</1> Discount": "Giảm giá <1>{{amount}}</1>",
  "Your friend receives {{amount}} discount on fees":
    "Bạn bè của bạn nhận được giảm giá {{amount}} trên phí",
  "Create and share your custom referral code":
    "Tạo và chia sẻ mã giới thiệu tùy chỉnh của bạn",
  "If you have a strong social media following (<1>{{followers}}+</1>), you may be eligible for our <2>VIP Affiliate Program</2> with higher commissions and XP.":
    "Nếu bạn có lượng người theo dõi mạng xã hội mạnh (<1>{{followers}}+</1>), bạn có thể đủ điều kiện tham gia chương trình liên kết VIP của chúng tôi với các khoản hoa hồng và XP cao hơn.",
  "You can track our <1>Affiliate Leaderboard</1> to see how you stack up.":
    "Bạn có thể theo dõi <1>Bảng xếp hạng liên kết</1> của chúng tôi để xem thứ hạng của mình.",
  "Apply": "Áp dụng",
  "Log in to see your Referral History.":
    "Đăng nhập để xem Lịch sử giới thiệu của bạn.",
  "Welcome to Paradex": "Chào mừng bạn đến với Paradex",
  "Congrats! The referral code you used is now active! It means you can start enjoying a discount on your trading fees!":
    "Chúc mừng! Mã giới thiệu bạn đã sử dụng hiện đã hoạt động! Điều đó có nghĩa là bạn có thể bắt đầu tận hưởng giảm giá trên phí giao dịch của mình!",
  "Congrats! The referral code <1>{{codeProvided}}</1> used is now active!":
    "Chúc mừng! Mã giới thiệu <1>{{codeProvided}}</1> đã sử dụng hiện đã hoạt động!",
  "Referral code not activated. The code can only be applied once during the initial onboarding.":
    "Mã giới thiệu không được kích hoạt. Mã chỉ có thể được áp dụng một lần trong quá trình đăng ký ban đầu.",
  "Referral code <1>{{codeProvided}}</1> not activated. You have already onboarded with <1>{{referredBy}}</1>.":
    "Mã giới thiệu <1>{{codeProvided}}</1> không được kích hoạt. Bạn đã tham gia với <1>{{referredBy}}</1>.",
  "Referral Code Applied": "Mã giới thiệu đã áp dụng",
  "You will receive <1>{{userAmount}}</1> of referred user's <1>fees</1> <2>and</2> <1>{{userAmountPoints}} XP</1>. They will receive a <1>{{friendAmount}}</1> discount.":
    "Bạn sẽ nhận được <1>{{userAmount}}</1> phí của người dùng được giới thiệu <1> và </1> <1>{{userAmountPoints}} XP</1>. Họ sẽ nhận được <1>{{friendAmount}}</1> giảm giá.",
  "Community": "Cộng đồng",
  "Environments": "Mạng Lưới",
  "Language": "Ngôn ngữ",
  "System Status": "Tình Trạng Hệ Thống",
  "Docs": "Tài liệu",
  "Code Samples": "Mẫu mã",
  "More": "Thêm",
  "Stats": "Thống kê",
  "Join Discord": "Tham gia Discord",
  "Notifications": "Thông báo",
  "All caught up!": "Đã cập nhật tất cả!",
  "You have no new notifications": "Bạn không có thông báo mới",
  "Connect Wallet": "Kết nối ví",
  "WalletConnect is taking too long to open. Please refresh the page and try again.":
    "WalletConnect mất quá nhiều thời gian để mở. Vui lòng làm mới trang và thử lại.",
  "Unexpected error opening WalletConnect modal. Please refresh the page and try again.":
    "Lỗi không mong muốn khi mở cửa sổ WalletConnect. Vui lòng làm mới trang và thử lại.",
  "Connect your Wallet": "Kết nối ví của bạn",
  "Link Wallet": "Liên kết ví",
  "You will receive a signature request. Signing is free and will not send a transaction.":
    "Bạn sẽ nhận được yêu cầu ký. Việc ký miễn phí và sẽ không gửi giao dịch.",
  "Generate Paradex Chain wallet": "Tạo ví Paradex Chain",
  "Generating Paradex Chain wallet": "Đang tạo ví Paradex Chain",
  "Paradex Chain wallet generated": "Ví Paradex Chain đã được tạo",
  "Verify that you own this wallet": "Xác minh rằng bạn sở hữu ví này",
  "Remember Me": "Nhớ tôi",
  "Only use 'Remember Me' if you are using a secure device you own. Selecting this option can expose your information to  others if you are accessing this wallet from a public device.":
    "Chỉ sử dụng 'Nhớ tôi' nếu bạn đang sử dụng thiết bị an toàn của riêng bạn. Chọn tùy chọn này có thể tiết lộ thông tin của bạn cho người khác nếu bạn đang truy cập ví này từ thiết bị công cộng.",
  "Continue": "Tiếp tục",
  "Disconnect Wallet": "Ngắt kết nối ví",
  "Disconnect": "Ngắt kết nối",
  "Account": "Tài khoản",
  "Username": "Tên người dùng",
  "Edit": "Chỉnh sửa",
  "Your username is now hidden. It is not visible to other users.":
    "Tên người dùng của bạn hiện đã bị ẩn. Nó không hiển thị cho người dùng khác.",
  "Your username will be visible to other users unless you choose to hide it. You can always change it later.":
    "Tên người dùng của bạn sẽ hiển thị cho người dùng khác trừ khi bạn chọn ẩn nó. Bạn luôn có thể thay đổi nó sau.",
  "Hide my Username": "Ẩn tên người dùng của tôi",
  "Save": "Lưu",
  "Username is required": "Yêu cầu tên người dùng",
  "Username must be between 5 and 20 characters":
    "Tên người dùng phải từ 5 đến 20 ký tự",
  "Username can only contain letters and numbers":
    "Tên người dùng chỉ có thể chứa chữ cái và số",
  "Wallets": "Ví",
  "Paradex Block Explorer": "Trình duyệt Block Paradex",
  "Log out": "Đăng xuất",
  "No markets available": "Không có thị trường nào",
  "Last Price": "Giá Hiện Tại",
  "Open Interest": "Mở Vị Thế",
  "24h Volume": "Khối lượng 24h",
  "Mark Price": "Giá đánh dấu",
  "Spot Price": "Giá Thị Trường",
  "Eight hour funding rate that is continuously accrued on positions and settled upon any trade. If the funding rate is positive, longs pay shorts. If negative, shorts pay longs.":
    "Tỷ lệ tài trợ 8 giờ được tích lũy liên tục trên các vị thế và được giải quyết sau mỗi giao dịch. Nếu tỷ lệ tài trợ là dương, người mua trả tiền ngắn. Nếu âm, ngắn trả tiền mua.",
  "8h Funding": "Tỷ lệ tài trợ 8 giờ",
  "Limit": "Giới hạn",
  "Market": "Thị trường",
  "Stop Limit": "Giới Hạn Dừng",
  "Stop Market": "Thị Trường Dừng",
  "Scaled Order": "Đơn hàng tỷ lệ",
  "Trigger Price": "Giá Kích Hoạt",
  "Input Price": "Giá Nhập",
  "Position Mark Price": "Giá Đánh Dấu Vị Thế",
  "Amount": "Số lượng",
  "Reduce Only": "Chỉ Giảm",
  "Reduce Only will increase position": "Chỉ Giảm sẽ tăng vị thế",
  "Your order stays open until it is filled or you decide to cancel.":
    "Đơn đặt hàng của bạn vẫn mở cho đến khi nó được điền hoặc bạn quyết định hủy.",
  "Your order gets filled right away, partially or fully, and whatever is left gets canceled instantly.":
    "Đơn đặt hàng của bạn được điền ngay lập tức, một phần hoặc toàn bộ, và bất cứ thứ gì còn lại sẽ bị hủy ngay lập tức.",
  "Conditional limit order that serves to add liquidity to the order book as a Maker order.":
    "Lệnh giới hạn có điều kiện để thêm thanh khoản vào sổ lệnh dưới dạng lệnh Maker.",
  "Currently the fees are fixed and uniform across the platform. Maker {{makerFee}} / Taker {{takerFee}}":
    "Hiện tại, phí được cố định và đồng nhất trên toàn nền tảng. Maker {{makerFee}} / Taker {{takerFee}}",
  "Value": "Giá trị",
  "Buy": "Mua",
  "BUY": "MUA",
  "LONG": "MUA",
  "Sell": "Bán",
  "SELL": "BÁN",
  "SHORT": "BÁN",
  "Order Value": "Giá trị đặt lệnh",
  "Current Position": "Vị thế hiện tại",
  "Account Value": "Giá trị tài khoản",
  "The total value of your account at current mark prices":
    "Tổng giá trị tài khoản của bạn tại giá đánh dấu hiện tại",
  "Unrealized P&L": "P&L chưa thực hiện",
  "Free Collateral": "Đảm bảo tự do",
  "Account Leverage": "Tài khoản đòn bẩy",
  "Margin": "Lề",
  "Margin Ratio": "Tỷ lệ Margin",
  "Initial Margin": "Lề ban đầu",
  "Maintenance Margin": "Lề bảo dưỡng",
  "Order Book": "Sách đặt hàng",
  "Trades": "Giao dịch",
  "Price": "Giá",
  "Size": "Kích thước",
  "Total": "Tổng",
  "Time": "Thời gian",
  "Spread": "Chênh lệch",
  "Side": "Bên",
  "Entry Price": "Giá vào",
  "Liquidation Price": "Giá thanh lý",
  "Breakeven Price": "Giá cân bằng",
  "Unrealized Funding": "Quỹ chưa thực hiện",
  "Close Position": "Đóng vị trí",
  "Market Close": "Đóng thị trường",
  "Close Long": "Đóng Long",
  "Close Short": "Đóng Short",
  "Type": "Loại",
  "Filled": "Đã điền",
  "Triggered": "Kích hoạt",
  "Instruction": "Hướng dẫn",
  "Client Order Id": "Id đơn hàng của khách hàng",
  "Status": "Trạng thái",
  "NEW": "Mới",
  "OPEN": "Mở",
  "UNTRIGGERED": "Chưa kích hoạt",
  "CLOSED": "Đã đóng",
  "Pending": "Đang chờ",
  "Complete": "Hoàn thành",
  "Completed": "Hoàn thành",
  "Failed": "Thất bại",
  "Bust": "Bị từ chối",
  "Value / Fee": "Giá trị / Phí",
  "Liquidity": "Thanh khoản",
  "Tx Hash": "Hash giao dịch",
  "Trade Id": "ID Giao dịch",
  "Remaining": "Còn lại",
  "Cancel Reason": "Lý do hủy bỏ",
  "User Canceled": "Người dùng đã hủy",
  "Not Enough Margin": "Không đủ lề",
  "Empty Market": "Thị trường trống",
  "Post Only": "Chỉ đăng",
  "Post Only Would Cross": "Chỉ đăng sẽ vượt qua",
  "Remaining IOC Cancel": "Hủy IOC còn lại",
  "Unexpected Failure": "Thất bại không mong đợi",
  "Deleverage": "Gỡ bỏ đòn bẩy",
  "In Liquidation": "Trong quá trình thanh lý",
  "Self Trade": "Tự giao dịch",
  "Asset Unavailable": "Tài sản không khả dụng",
  "Asset Expired": "Tài sản đã hết hạn",
  "Order Type Invalid": "Loại đơn hàng không hợp lệ",
  "Price Not Available": "Giá không khả dụng",
  "Expired": "Đã hết hạn",
  "Exceeds Max Slippage": "Giá vượt quá giới hạn",
  "Timeout": "Hết thời gian",
  "Order Exceeds Position Limit": "Đơn hàng vượt quá giới hạn vị trí",
  "Order Id": "Id đơn hàng",
  "Payment": "Thanh toán",
  "Cancel All": "Hủy tất cả",
  "{{orderSize}} will be closed at Market price":
    "{{orderSize}} sẽ được đóng với giá thị trường",
  "Wallet": "Ví",
  "Deposit_verb": "Gửi tiền",
  "Deposit_noun": "Tiền gửi",
  "Withdraw": "Rút tiền",
  "Withdrawal": "Rút tiền",
  "Transfers": "Chuyển tiền",
  "Transfer": "Chuyển tiền",
  "Collateral": "Tài sản thế chấp",
  "Asset": "Tài sản",
  "Wallet Balance": "Số dư ví",
  "Actions": "Hành động",
  "Action": "Hành động",
  "External Tx Hash": "Hash giao dịch bên ngoài",
  "Withdraw to Wallet": "Rút về ví",
  "Deposit to Paradex": "Gửi tiền vào Paradex",
  "You have no balance in your Ethereum wallet. Deposit some {{asset}} and try again.":
    "Bạn không có số dư trong ví Ethereum của mình. Gửi một số {{asset}} và thử lại.",
  "It is not possible to deposit more than your current balance.":
    "Không thể gửi nhiều hơn số dư hiện tại của bạn.",
  "Available": "Có sẵn",
  "Initiate a deposit from your Ethereum wallet to the Paradex bridge. These transactions":
    "Khởi tạo một giao dịch nạp tiền từ ví Ethereum của bạn đến cầu Paradex. Những giao dịch này",
  "Initiate a deposit from your Ethereum wallet to the Paradex bridge. These transactions may take several minutes depending on network conditions. For more information check out our <1>docs</1>.":
    "Khởi tạo một giao dịch nạp tiền từ ví Ethereum của bạn đến cầu Paradex. Những giao dịch này có thể mất vài phút tùy thuộc vào điều kiện mạng. Để biết thêm thông tin, hãy xem <1>tài liệu</1> của chúng tôi.",
  "read more": "đọc thêm",
  "Initiate Deposit to Bridge": "Khởi tạo gửi tiền đến cầu",
  "Enable USDC on Paradex": "Kích hoạt USDC trên Paradex",
  "In order to deposit you must allow Paradex access to at least {{amount}}.":
    "Để gửi tiền, bạn phải cho phép Paradex truy cập ít nhất {{amount}}.",
  "Enable USDC": "Kích hoạt USDC",
  "Withdraw initiated": "Đã khởi tạo rút tiền",
  "Withdrawals are a two-step process. First initiate a withdrawal from Paradex":
    "Việc rút tiền là một quy trình hai bước. Đầu tiên, khởi tạo rút tiền từ Paradex",
  "Withdrawals are a two-step process. First initiate a withdrawal from Paradex to the Paradex Bridge. This first step can take 5-16 hours (10 on average). Second, initiate your withdrawal from the Paradex Bridge to your Ethereum wallet via the Deposits / Withdrawals table. For more information check out our <1>docs</1>.":
    "Việc rút tiền là một quy trình hai bước. Đầu tiên, khởi tạo rút tiền từ Paradex đến cầu Paradex. Bước đầu tiên này có thể mất từ 5-16 giờ (trung bình 10). Thứ hai, khởi tạo rút tiền của bạn từ cầu Paradex đến ví Ethereum của bạn qua bảng Gửi / Rút tiền. Để biết thêm thông tin, hãy xem <1>tài liệu của chúng tôi</1>.",
  "Initiate Withdrawal to Bridge": "Khởi tạo rút tiền đến cầu",
  "You have no funds to withdraw. It is not possible to withdraw more than your free collateral.":
    "Bạn không có tiền để rút. Không thể rút nhiều hơn tài sản thế chấp tự do của bạn.",
  "It is not possible to withdraw more than your free collateral.":
    "Không thể rút nhiều hơn tài sản thế chấp tự do của bạn.",
  "You have no collateral": "Bạn không có tài sản thế chấp",
  "You have no open positions": "Bạn không có vị trí mở",
  "You have no open orders": "Bạn không có đơn hàng mở",
  "You have no trades": "Bạn không có giao dịch nào",
  "You have no orders": "Bạn không có đơn hàng nào",
  "You have no funding payments": "Bạn không có khoản thanh toán nào",
  "You have no transfers": "Bạn không có chuyển khoản nào",
  "Account in liquidation": "Tài khoản đang trong quá trình thanh lý",
  "Account is being liquidated. Any open positions will be reduced. Trading now is disabled.":
    "Tài khoản đang được thanh lý. Bất kỳ vị trí mở nào cũng sẽ được giảm. Giao dịch hiện đã bị vô hiệu hóa.",
  "Account liquidated": "Tài khoản đã được thanh lý",
  "Your account was liquidated on {{createdAt}}. Any open positions were reduced.":
    "Tài khoản của bạn đã được thanh lý vào {{createdAt}}. Bất kỳ vị trí mở nào cũng đã được giảm.",
  "Deposit to Paradex successful": "Gửi tiền vào Paradex thành công",
  "Deposit to Paradex failed": "Gửi tiền vào Paradex thất bại",
  "Unexpected error. Try again.": "Lỗi không mong đợi. Hãy thử lại.",
  "Deposit initiated": "Đã khởi tạo gửi tiền",
  "Deposit to bridge initiated": "Bắt đầu gửi tiền vào cầu",
  "Deposit to Paradex available": "Có thể gửi tiền vào Paradex",
  "Go to Deposits": "Đi đến Tiền gửi",
  "Deposit to Paradex in progress": "Đang gửi tiền vào Paradex",
  "Transfer successful": "Chuyển khoản thành công",
  "Limit {{side}} Order Fill": "Điền đơn hàng giới hạn {{side}}",
  "{{type}} {{side}} Order Submitted": "Đơn hàng {{type}} {{side}} đã được gửi",
  "{{type}} {{side}} Order Canceled": "Đơn hàng {{type}} {{side}} đã bị hủy",
  "Reason": "Lý do",
  "Market {{side}} Order Fill": "Điền đơn hàng thị trường {{side}}",
  "Trade Busted On-Chain": "Giao dịch bị hủy trên chuỗi",
  "Fill id": "Điền id",
  "Withdraw to wallet failed": "Rút tiền vào ví thất bại",
  "Withdrawal to bridge initiated": "Bắt đầu rút tiền vào cầu",
  "Withdrawal error. Try again.": "Lỗi rút tiền. Hãy thử lại.",
  "Withdrawal to bridge in progress": "Đang rút tiền vào cầu",
  "Withdrawal to wallet initiated": "Bắt đầu rút tiền vào ví",
  "Withdrawal to bridge failed": "Rút tiền vào cầu thất bại",
  "Transaction error. Try again.": "Lỗi giao dịch. Hãy thử lại.",
  "Withdrawal to wallet available": "Có thể rút tiền vào ví",
  "Go to Withdrawals": "Đi đến Rút tiền",
  "Withdrawal to wallet successful": "Rút tiền vào ví thành công",
  "Session expired, please log in again.":
    "Phiên đã hết hạn, vui lòng đăng nhập lại.",
  "Failed to submit order": "Không thể gửi đơn hàng",
  "Your username preferences were updated!":
    "Tùy chọn tên người dùng của bạn đã được cập nhật!",
  "Unexpected error updating username preferences":
    "Lỗi không mong đợi khi cập nhật tùy chọn tên người dùng",
  "This username already exists. Please choose a different username and try again.":
    "Tên người dùng này đã tồn tại. Vui lòng chọn một tên người dùng khác và thử lại.",
  "Validation Error": "Lỗi xác thực",
  "Binding Error": "Lỗi ràng buộc",
  "Internal Error": "Lỗi nội bộ",
  "Not Found": "Không tìm thấy",
  "Service Unavailable": "Dịch vụ không khả dụng",
  "Invalid Request Parameter": "Tham số yêu cầu không hợp lệ",
  "Order Id Not Found": "Không tìm thấy Id đơn hàng",
  "Order Is Closed": "Đơn hàng đã đóng",
  "Order Is Not Open Yet": "Đơn hàng chưa mở",
  "Client Order Id Not Found": "Không tìm thấy Id đơn hàng của khách hàng",
  "Duplicated Client Id": "Id khách hàng bị trùng lặp",
  "Invalid Price Precision": "Độ chính xác giá không hợp lệ",
  "Invalid Token": "Token không hợp lệ",
  "Invalid Ethereum Address": "Địa chỉ Ethereum không hợp lệ",
  "Blocked Ethereum Address": "Địa chỉ Ethereum đã bị chặn",
  "Invalid Ethereum Signature": "Chữ ký Ethereum không hợp lệ",
  "Invalid Starknet Address": "Địa chỉ Starknet không hợp lệ",
  "Invalid Starknet Signature": "Chữ ký Starknet không hợp lệ",
  "Starknet Signature Verification Failed": "Xác minh chữ ký Starknet thất bại",
  "Bad Starknet Request": "Yêu cầu Starknet không hợp lệ",
  "Ethereum Signer Mismatch": "Chữ ký Ethereum không khớp",
  "Ethereum Hash Mismatch": "Hash Ethereum không khớp",
  "Not Onboarded": "Chưa đăng ký",
  "Invalid Timestamp": "Dấu thời gian không hợp lệ",
  "Invalid Signature Expiration": "Hết hạn chữ ký không hợp lệ",
  "Account Not Found": "Không tìm thấy tài khoản",
  "Invalid Order Signature": "Chữ ký đơn hàng không hợp lệ",
  "Public Key Invalid": "Khóa công khai không hợp lệ",
  "Unauthorized Ethereum Address": "Địa chỉ Ethereum không được phép",
  "Ethereum Address Already Onboarded": "Địa chỉ Ethereum đã được đăng ký",
  "Market Not Found": "Không tìm thấy thị trường",
  "Allowlist Entry Not Found": "Không tìm thấy mục trong danh sách cho phép",
  "Username In Use": "Tên người dùng đã được sử dụng",
  "Service access restricted in your region":
    "Quyền truy cập dịch vụ bị hạn chế trong khu vực của bạn",
  "No Access Allowed": "Không Được Phép Truy Cập",
  "You do not have access to the platform. Please join our Discord to get access.":
    "Bạn không có quyền truy cập vào nền tảng. Vui lòng tham gia Discord của chúng tôi để được truy cập.",
  "{{actualChainName}} Wallet Connected": "Ví Ethereum Đã Được Kết Nối",
  'Incorrect network selected "{{actualChainName}}". Please change the network to "{{requiredChainName}}" (id={{requiredChainId}}) in your wallet and retry.':
    'Mạng lưới được chọn "{{actualChainName}}" không chính xác. Vui lòng thay đổi mạng lưới thành "{{requiredChainName}}" (id={{requiredChainId}}) trong ví của bạn và thử lại.',
  "Choose Wallet": "Chọn Ví",
  "Starknet Wallet": "Ví Starknet",
  "Switch to {{env}}": "Chuyển sang {{env}}",
  "Search": "Tìm kiếm",
  "League": "Liên đoàn",
  "Switch Account": "Chuyển tài khoản",
  "Rank": "Xếp hạng",
  "24h XP": "XP 24h",
  "XP Per Day": "XP mỗi ngày",
  "Season Volume": "Khối lượng mùa",
  "Lifetime Volume": "Khối lượng trọn đời",
  "Pre-Season": "Mùa trước",
  "Earn More XP": "Kiếm thêm XP",
  "XP Boost": "Tăng XP",
  "XP Drop": "Rơi XP",
  "Trader": "Người môi giới",
  "Total Share": "Tổng Cổ Phần",
  "Maker Share": "Cổ Phần Nhà Sáng Tạo",
  "Maker Volume Share": "Thị Phần Khối Lượng Nhà Sáng Tạo",
  "Fee Share": "Phần Chia Phí",
  "Fee Volume Share": "Thị Phần Khối Lượng Phí",
  "No data available": "Không có dữ liệu",
  "Refer & Earn": "Giới thiệu & Kiếm",
  "Hours": "Giờ",
  "Minutes": "Phút",
  "Time/Duration": "Thời gian/Thời gian",
  "Runtime": "Thời gian chạy",
  "Frequency": "Tần suất",
  "Number of Orders": "Số lượng đơn hàng",
  "Runtime should not be empty": "Thời gian chạy không được để trống",
  "Runtime should be less than or equal to 24 hours":
    "Thời gian chạy phải nhỏ hơn hoặc bằng 24 giờ",
  "Inactive": "Không hoạt động",
  "Avg. Price": "Giá trung bình",
  "The minimum required amount for {{numOrders}} orders is {{totalSize}} {{currency}}.":
    "Số tiền tối thiểu yêu cầu cho {{numOrders}} đơn hàng là {{totalSize}} {{currency}}.",
  "Take Profit / Stop Loss: Set take profit and stop loss price levels to automate trade actions. When the Mark Price will reach the price you set, it will send your order to the matching engine.":
    "Chốt Lời / Dừng Lỗ: Đặt mức giá chốt lời và dừng lỗ để tự động hóa các hành động giao dịch. Khi Giá Thị Trường đạt đến mức giá bạn đã đặt, lệnh của bạn sẽ được gửi đến hệ thống khớp lệnh.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount with an estimated profit of <3 />.":
    "Khi Giá Thị Trường đạt <1 />, lệnh <2 /> sẽ được kích hoạt để đóng số lượng đã chọn với lợi nhuận ước tính là <3 />.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount with an estimated loss of <3 />.":
    "Khi Giá Thị Trường đạt <1 />, lệnh <2 /> sẽ được kích hoạt để đóng số lượng đã chọn với khoản lỗ ước tính là <3 />.",
  "Edit TP/SL For This Position": "Chỉnh sửa TP/SL cho Vị thế này",
  "Take Profit": "Chốt Lời",
  "Profit": "Lợi nhuận",
  "Stop Loss": "Cắt Lỗ",
  "Loss": "Thua lỗ",
  "Confirm": "Xác nhận",
  "Take Profit Limit": "Chốt Lời Giới Hạn",
  "Take Profit Market": "Chốt Lời Thị Trường",
  "Stop Loss Limit": "Cắt Lỗ Giới Hạn",
  "Stop Loss Market": "Cắt Lỗ Thị Trường",
  "Funding": "Huy động vốn",
  "Unwinding": "Giải thể",
  "Cancel TP/SL orders": "Hủy lệnh TP/SL",
  "TP/SL orders submitted successfully.": "Lệnh TP/SL đã được gửi thành công.",
  "Pre-Season + All Seasons": "Mùa trước + Tất cả các mùa",
  "Season {{season}}": "Mùa {{season}}",
  "XP Season {{season}}": "Mùa {{season}} XP",
  "Slippage": "Độ trượt giá",
  "Max Slippage": "Trượt giá tối đa",
  "Max slippage defines the largest acceptable price deviation from the mark price.":
    "Trượt giá tối đa xác định độ lệch giá lớn nhất chấp nhận được so với giá tham chiếu.",
  "During execution, the order can be canceled if the price exceeds the slippage limit or falls outside the Market <1>Price Band</1>.":
    "Trong quá trình thực hiện, lệnh có thể bị hủy nếu giá vượt quá giới hạn trượt giá hoặc nằm ngoài <1>dải giá</1> của thị trường.",
  "Update Max Slippage": "Cập nhật trượt giá tối đa",
  "The Maximum Slippage allowed for {{symbol}} is {{price_band_width}}.":
    "Độ trượt giá tối đa được phép cho {{symbol}} là {{price_band_width}}.",
  "Mark IV": "Mark IV",
  "Delta": "Delta",
  "Failed to load positions": "Không thể tải vị thế",
  "No open positions": "Không có vị thế mở",
  "learn more": "tìm hiểu thêm",
  "Learn More": "Tìm Hiểu Thêm",
  "Search Markets": "Tìm Kiếm Thị Trường",
  "Futures": "Hợp đồng tương lai",
  "Options": "Quyền chọn",
  "OI": "OI",
  "My Profile": "Hồ sơ của tôi",
  "Addresses": "Địa chỉ",
  "Chart": "Biểu đồ",
  "ROI": "ROI",
  "Sharpe": "Sharpe",
  "Max Loss": "Mất mát tối đa",
  "Total Volume": "Khối lượng tổng",
  "My NFTs": "NFT của tôi",
  "My XP": "XP của tôi",
  "Balances": "Số dư",
  "Other": "Khác",
  "Paradex XP: Pre-Season": "Paradex XP: Mùa trước",
  "Paradex XP: Season {{season}}": "Paradex XP: Mùa {{season}}",
  "Season {{season}} XP": "Mùa {{season}} XP",
  "Refer your friends to earn 10%": "Giới thiệu bạn bè và kiếm 10%",
  "Refer Friends and Earn 10%": "Giới thiệu bạn bè và kiếm 10%",
  "Invest in Paradex Vault & Earn 3X Boost":
    "Đầu tư vào Kho bạc Paradex và kiếm 3X Boost",
  "Avg. Daily XP": "XP trung bình hàng ngày",
  "Lifetime XP": "XP suốt đời",
  "My Rank": "Xếp hạng của tôi",
  "What is XP?": "XP là gì?",
  "XP History": "Lịch sử XP",
  "Last Week XP": "XP tuần trước",
  "TVL XP": "TVL XP",
  "OI XP": "OI XP",
  "Liquidation XP": "Liquidation XP",
  "Vaults XP": "Vaults XP",
  "Week": "Tuần",
  "Season": "Mùa",
  "Last updated: {{timestamp}}": "Cập nhật lần cuối: {{timestamp}}",
  "Xperience Points (XP)": "Điểm Kinh Nghiệm (XP)",
  "Log in to see your XP balance": "Đăng nhập để xem số dư XP của bạn",
  "Log in to see where you stand": "Đăng nhập để xem bạn đang ở đâu",
  "Connect your X account to join Pro League":
    "Kết nối tài khoản X của bạn để tham gia Liên đoàn Pro",
  "Avg. Fill Price": "Giá khớp lệnh trung bình",
  "Spot": "Spot",
  "Derivatives": "Hợp đồng tương lai",
  "P&L Chart": "Biểu đồ P&L",
  "Account Value Chart": "Biểu đồ Giá trị tài khoản",
  "Funding History": "Lịch sử huy động vốn",
  "Manage Funds": "Quản lý tài sản",
  "Profile": "Hồ sơ",
  "Community XP": "XP Cộng đồng",
  "XP Share": "Phần chia XP",
  "Next XP Drop in": "XP Drop tiếp theo trong",
  "In Progress": "Đang tiến hành",
  "{{field}} must be greater than {{value}}":
    "{{field}} phải lớn hơn {{value}}",
  "{{field}} must be less than {{value}}": "{{field}} phải nhỏ hơn {{value}}",
  "Enter a valid Take Profit or Stop Loss price":
    "Nhập giá Take Profit hoặc Stop Loss hợp lệ",
  "Take Profit and Stop Loss prices cannot be the same":
    "Giá Take Profit và Stop Loss không được giống nhau",
  "Automatic Withdrawal": "Rút tiền tự động",
  "Estimated Gas Fee": "Phí Gas dự kiến",
  "Auto Withdrawal": "Rút tiền tự động",
  "Withdrawing...": "Rút tiền đang tiến hành...",
  "Paradex Bridge withdrawals can take between 5-16 hours to complete":
    "Rút tiền Paradex Bridge có thể mất từ 5-16 giờ để hoàn thành",
  "Socialized Loss": "Thua lỗ số học",
  "Receivable Amount": "Số tiền nhận được",
  "Auto withdrawing...": "Rút tiền tự động đang tiến hành...",
  "Manage Twitter Connection": "Quản lý kết nối Twitter",
  "Unlock additional functionality.": "Mở khóa các chức năng bổ sung.",
  "Please note this connection can't be used as a login method to Paradex.":
    "Xin lưu ý kết nối này không thể được sử dụng như một phương thức đăng nhập vào Paradex.",
  "Toast Notifications": "Thông báo Toast",
  "Display toast notifications": "Hiển thị thông báo Toast",
  "Average Entry Price": "Giá vào trung bình",
  "Display average entry price line on chart":
    "Hiển thị đường giá vào trung bình trên biểu đồ",
  "Display liquidation price line on chart":
    "Hiển thị đường giá thanh lý trên biểu đồ",
  "Display open order lines on chart":
    "Hiển thị đường đơn hàng mở trên biểu đồ",
  "Failed to disconnect Twitter": "Không thể ngắt kết nối Twitter",
  "Failed to authorize Twitter": "Không thể xác thực Twitter",
  "Twitter account linked successfully!":
    "Tài khoản Twitter đã kết nối thành công!",
  "Failed to link twitter account": "Không thể kết nối tài khoản Twitter",
  "Fees": "Phí",
  "Taker pays {{takerFee}} in fees. Maker receives {{makerRebate}} in rebates.":
    "Taker trả {{takerFee}} phí. Maker nhận {{makerRebate}} hoàn lại.",
  "Manage Discord Connection": "Quản lý kết nối Discord",
  "Failed to authorize Discord": "Không thể xác thực Discord",
  "Discord account linked successfully!":
    "Tài khoản Discord đã kết nối thành công!",
  "Failed to link Discord account": "Không thể kết nối tài khoản Discord",
  "Failed to disconnect Discord": "Không thể ngắt kết nối Discord",
  "Connect": "Kết nối",
  "Unrealized P&L from current vault token balance. This doesn't account for owner profit share or slippage from positions unwind.":
    "P&L không thực hiện từ số dư token vault hiện tại. Điều này không tính đến phần chia lợi nhuận của chủ sở hữu hoặc sự trượt giá từ việc giải quyết vị thế.",
  "Show P&L Amount": "Hiển thị P&L",
  "Receivable amount must be positive": "Số tiền nhận được phải là số dương",
  "Wallet Address Change Detected": "Phát hiện thay đổi địa chỉ ví",
  "You can proceed with connecting your Paradex account to this wallet address or continue using your current connection.":
    "Bạn có thể tiếp tục kết nối tài khoản Paradex của mình với địa chỉ ví này hoặc tiếp tục sử dụng kết nối hiện tại.",
  "Connect To New Wallet": "Kết nối với ví mới",
  "Keep Using Current Wallet": "Tiếp tục sử dụng ví hiện tại",
  "Connect Current Wallet": "Kết nối ví hiện tại",
  "The account is not connected. Please make sure that <1>{{walletAddress}}</1> is connected and is the active account in your <2>{{walletName}}</2> wallet.":
    "Tài khoản chưa được kết nối. Vui lòng đảm bảo rằng <1>{{walletAddress}}</1> đã được kết nối và là tài khoản đang hoạt động trong ví <2>{{walletName}}</2> của bạn.",
  "Reconnecting": "Đang kết nối lại",
  "There is a request in progress. Please proceed in your wallet.":
    "Có một yêu cầu đang được xử lý. Vui lòng tiếp tục trong ví của bạn.",
  "Modify Order": "Chỉnh sửa đơn hàng",
  "Realized P&L": "Lãi/Lỗ Đã Thực Hiện",
  "Close All": "Đóng tất cả",
  "Close All Positions": "Đóng tất cả vị thế",
  "Market Close All Positions": "Thị trường đóng tất cả vị thế",
  "Close {{percent}}% of All Positions": "Đóng {{percent}}% tất cả vị thế",
  "Failed to close all positions": "Không thể đóng tất cả vị thế",
  "Number of Positions": "Số lượng vị thế",
  "Markets": "Thị trường",
  "Position Value (Closing)": "Giá trị vị thế (Đóng)",
  "{{count}} positions will be rounded to the nearest order increment":
    "{{count}} vị thế sẽ được làm tròn đến số lượng đơn hàng gần nhất",
  "You will earn Bonus XP on your referral activity":
    "Bạn sẽ nhận được XP Bonus cho các hoạt động giới thiệu của mình",
  "Token Price": "Giá Token",
  "Vault Token Price Chart": "Biểu đồ Giá Token Vault",
  "Market Close Orders Submitted": "Thị trường đóng tất cả các đơn hàng",
  "This profile is already linked to a different Paradex account. Disconnect socials from existing account and try again.":
    "Hồ sơ này đã được liên kết với một tài khoản Paradex khác. Hãy hủy kết nối các mạng xã hội khỏi tài khoản hiện tại và thử lại.",
  "XP Drops every Friday": "XP rơi vào mỗi Thứ Sáu",
  "No XP Data Available": "Không có dữ liệu XP",
  "XP data is available only for the Main Account.":
    "Dữ liệu XP chỉ có sẵn cho tài khoản chính.",
  "Please switch to your Main Account to view your XP.":
    "Vui lòng chuyển sang tài khoản chính để xem XP của bạn.",
  "Referrals Not Available": "Không có dữ liệu Referrals",
  "Referrals are available only for the Main Account.":
    "Dữ liệu Referrals chỉ có sẵn cho tài khoản chính.",
  "Please switch to your Main Account to view your Referrals.":
    "Vui lòng chuyển sang tài khoản chính để xem Referrals của bạn.",
  "Deposit initiated via Layerswap": "Gửi tiền được khởi tạo qua Layerswap",
  "Position History": "Lịch sử vị thế",
  "Avg. Close Price": "Giá đóng trung bình",
  "Max Size": "Kích thước tối đa",
  "Closed Size": "Kích thước đã đóng",
  "Opened": "Đã mở",
  "You have no positions": "Bạn không có vị thế nào",
  "Destination Address": "Địa chỉ đích",
  "Withdrawal via Layerswap initiated":
    "Rút tiền qua Layerswap đã được khởi tạo",
  "Withdrawal via Layerswap failed": "Rút tiền qua Layerswap không thành công",
  "Market Share": "Phần thị trường",
  "Tier": "Cấp",
  "Avg Maker Volume": "Khối lượng Maker trung bình",
  "Liquidity Score": "Điểm thanh khoản",
  "Reward Share": "Phần thưởng",
  "Enter amount": "Nhập số tiền",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount at <3 /> with an estimated profit of <4 />.":
    "Khi Giá Thị Trường đạt <1 />, lệnh <2 /> sẽ được kích hoạt để đóng số lượng đã chọn tại <3 /> với lợi nhuận ước tính là <4 />.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount at <3 /> with an estimated loss of <4 />.":
    "Khi Giá Thị Trường đạt <1 />, lệnh <2 /> sẽ được kích hoạt để đóng số lượng đã chọn tại <3 /> với tổn thất ước tính là <4 />.",
  "Limit Price": "Giá Giới Hạn",
  "Delete": "Xóa",
  "Use other bridges from the list above to withdraw funds much faster.":
    "Sử dụng các cầu khác từ danh sách trên để rút tiền nhanh hơn nhiều.",
  "Paradex Chain Monitor": "Màn hình Chuỗi Paradex",
  "OTM Amount": "Số lượng OTM",
  "Leverage": "Đòn bẩy",
  "Strikes": "Giá thực hiện",
  "Strike": "Giá thực hiện",
  "Change": "Thay đổi",
  "Dist. to Spot": "Khoảng cách đến Spot",
  "Bid": "Giá mua",
  "Mark": "Giá đánh dấu",
  "Ask": "Giá bán",
  "IV": "IV",
  "Dist %": "Khoảng cách %",
  "Call": "Call",
  "Put": "Put",
  "Mark Price / IV": "Giá đánh dấu / IV",
  "8h F-Funding": "Tài trợ 8h F",
  "No Strikes Available": "Không có Giá thực hiện",
  "Attributes": "Thuộc tính",
  "Why no Expiries?": "Tại sao không có Thời hạn?",
  "{{multiplier}}x Boost": "Tăng {{multiplier}}x",
  "Like perpetual futures, perpetual options have no expiration, offering continuous exposure to the underlying asset with built-in downside protection. This gives users the flexibility to hold their position indefinitely and decide the optimal time to close it based on their strategy":
    "Giống như hợp đồng tương lai vĩnh viễn, quyền chọn vĩnh viễn không có thời hạn, cung cấp khả năng tiếp xúc liên tục với tài sản cơ sở với bảo vệ giảm giá tích hợp. Điều này cho phép người dùng linh hoạt nắm giữ vị thế của họ vô thời hạn và quyết định thời điểm tối ưu để đóng dựa trên chiến lược của họ",
  "Implied Volatility used to calculate the option's Mark Price":
    "Biến động ngầm định được sử dụng để tính Giá đánh dấu của quyền chọn",
  "Distance between the Strike Price and the underlying Spot Price":
    "Khoảng cách giữa Giá thực hiện và Giá Spot cơ sở",
  "Eight hour funding that is continuously accrued on positions and settled upon any trade. If the funding rate is positive, longs pay shorts. If negative, shorts pay longs":
    "Tài trợ tám giờ được tích lũy liên tục trên các vị thế và được thanh toán khi có bất kỳ giao dịch nào. Nếu tỷ lệ tài trợ dương, vị thế mua trả cho vị thế bán. Nếu âm, vị thế bán trả cho vị thế mua",
  "The Leverage Ratio (Lambda) measures an option's effective leverage by indicating how much the option's value changes, in percentage terms, for a 1% change in the spot price of the underlying asset. For example, if an option has a Lambda of 50 and the spot price increases by 1%, the option's value would increase by 50%":
    "Tỷ lệ Đòn bẩy (Lambda) đo lường đòn bẩy hiệu quả của quyền chọn bằng cách chỉ ra giá trị quyền chọn thay đổi bao nhiêu, theo phần trăm, cho sự thay đổi 1% trong giá spot của tài sản cơ sở. Ví dụ, nếu quyền chọn có Lambda là 50 và giá spot tăng 1%, giá trị quyền chọn sẽ tăng 50%",
  "Option delta measures how much the price of an option is expected to change for a $1 change in the price of the underlying asset. For example, a delta of 0.5 means the option price will move $0.50 for every $1 move in the underlying":
    "Delta quyền chọn đo lường mức độ thay đổi dự kiến của giá quyền chọn cho sự thay đổi $1 trong giá của tài sản cơ sở. Ví dụ, delta 0,5 có nghĩa là giá quyền chọn sẽ di chuyển $0,50 cho mỗi di chuyển $1 trong tài sản cơ sở",
  "Eight hour funding rate of the perpetual future on the same underlying asset":
    "Tỷ lệ tài trợ tám giờ của hợp đồng tương lai vĩnh viễn trên cùng tài sản cơ sở",
  "Having trouble? Try signing out and connecting again.":
    "Gặp sự cố? Hãy thử đăng xuất và kết nối lại.",
  "API Reference": "Tài liệu tham khảo API",
  "XP Distribution occurs once per week on Friday at ~12 PM UTC":
    "Phân phối XP diễn ra một lần mỗi tuần vào thứ Sáu lúc ~12 giờ UTC",
  "What are Perpetual Options?": "Quyền chọn Vĩnh viễn là gì?",
  "Delta represents the increase in option price based on a $1 increase in the underlying spot price.\n\nFor example, a delta of 0.50 means the price of the option is expected to increase by $0.50 for a $1 increase in the underlying spot price.":
    "Delta thể hiện sự tăng giá quyền chọn dựa trên sự tăng $1 trong giá spot cơ sở.\n\nVí dụ, delta 0,50 có nghĩa là giá quyền chọn dự kiến sẽ tăng $0,50 cho sự tăng $1 trong giá spot cơ sở.",
  "Leverage(Lambda) represents the percentage change in option price for a 1% change in the underlying spot price.\n\nFor example, a lambda of 10 means the option price is expected to increase by 10% if the underlying spot price increases by 1%.":
    "Đòn bẩy(Lambda) thể hiện sự thay đổi phần trăm trong giá quyền chọn cho sự thay đổi 1% trong giá spot cơ sở.\n\nVí dụ, lambda 10 có nghĩa là giá quyền chọn dự kiến sẽ tăng 10% nếu giá spot cơ sở tăng 1%.",
  "Eight hour options funding rate that is continuously accrued on positions and settled upon any trade. The funding rate for perpetual options reflects both the cost of leverage and the cost of insurance against adverse price moves. \n\nThe funding period of perpetual options is 24 hours. This means that the option premium will be continuously paid out over a 24h period. 24h funding rate = 3 x 8h funding rate":
    "Tỷ lệ tài trợ quyền chọn tám giờ được tích lũy liên tục trên các vị thế và được thanh toán khi có bất kỳ giao dịch nào. Tỷ lệ tài trợ cho quyền chọn vĩnh viễn phản ánh cả chi phí đòn bẩy và chi phí bảo hiểm chống lại biến động giá bất lợi. \n\nThời gian tài trợ của quyền chọn vĩnh viễn là 24 giờ. Điều này có nghĩa là phí quyền chọn sẽ được trả liên tục trong khoảng thời gian 24 giờ. Tỷ lệ tài trợ 24 giờ = 3 x tỷ lệ tài trợ 8 giờ",
  "Risk": "Rủi ro",
  "Vega": "Vega",
  "Gamma": "Gamma",
  "Estimated Funding P&L for the next 8 hours based on current funding rates":
    "Ước tính Lãi/Lỗ Tài trợ cho 8 giờ tiếp theo dựa trên tỷ lệ tài trợ hiện tại",
  "Vega represents the increase in option price based on a 1% increase in the implied volatility.\n\nFor example, a Vega of 5 means the price of the option is expected to increase by $5 for a 1% increase in the implied volatility.":
    "Vega thể hiện sự tăng giá quyền chọn dựa trên sự tăng 1% trong biến động ngầm định.\n\nVí dụ, Vega 5 có nghĩa là giá quyền chọn dự kiến sẽ tăng $5 cho sự tăng 1% trong biến động ngầm định.",
  "Adjust Leverage": "Điều chỉnh Đòn bẩy",
  "Leverage updated successfully": "Cập nhật Đòn bẩy thành công",
  "Failed to update leverage": "Không thể cập nhật Đòn bẩy",
  "Adjust leverage parameters for selected market. Leverage will also increase on your open positions.":
    "Điều chỉnh thông số đòn bẩy cho thị trường đã chọn. Đòn bẩy cũng sẽ tăng trên các vị thế mở của bạn.",
  "Please note that changing leverage will also apply for open positions and open orders on this market.":
    "Xin lưu ý rằng thay đổi đòn bẩy cũng sẽ áp dụng cho các vị thế mở và lệnh mở trên thị trường này.",
  "Selecting higher leverage could result in Deleveraging where some of your open orders would be cancelled.":
    "Việc chọn đòn bẩy cao hơn có thể dẫn đến Giảm đòn bẩy, trong đó một số lệnh mở của bạn sẽ bị hủy.",
  "disabled": "vô hiệu hóa",
  "Margin Mode": "Chế độ Ký quỹ",
  "Manage your risk on individual positions by restricting the amount of margin allocated to each. If the margin ratio of an isolated position reaches 100%, the position will be liquidated. Margin can be added or removed to individual positions in this mode.":
    "Quản lý rủi ro của bạn trên từng vị thế bằng cách hạn chế số lượng ký quỹ được phân bổ cho mỗi vị thế. Nếu tỷ lệ ký quỹ của một vị thế cô lập đạt 100%, vị thế sẽ bị thanh lý. Ký quỹ có thể được thêm vào hoặc loại bỏ khỏi các vị thế riêng lẻ trong chế độ này.",
  "Margin mode updated successfully": "Cập nhật chế độ Ký quỹ thành công",
  "Failed to update margin mode": "Không thể cập nhật chế độ Ký quỹ",
  "Favorites": "Yêu thích",
  "Trending": "Xu hướng",
  "Losers": "Thua lỗ",
  "Gainers": "Tăng trưởng",
  "New Listing": "Niêm yết mới",
  "Developers": "Nhà phát triển",
  "Developer Mode": "Chế độ Nhà phát triển",
  "Enable developer mode": "Bật chế độ Nhà phát triển",
  "For external use": "Dành cho sử dụng bên ngoài",
  "Warning: Changing transfer behavior":
    "Cảnh báo: Thay đổi hành vi chuyển tiếp",
  "USDC will be transferred to account contract instead of Paradex. Amount won't be visible or available for use within Paradex, but can be used with external applications.":
    "USDC sẽ được chuyển đến hợp đồng tài khoản thay vì Paradex. Số tiền sẽ không hiển thị hoặc khả dụng để sử dụng trong Paradex, nhưng có thể được sử dụng với các ứng dụng bên ngoài.",
  "Affiliate Dashboard": "Bảng điều khiển Afiliate",
};

export default translations;
