import { REFERRAL_ROUTE, VAULTS_ROUTE } from '#/routes';

/**
 * @example
 *  baseUrl();
 *  // 'app.paradex.trade/r/'
 */
export const baseUrl = (): string => {
  const host = new URL("https://app.paradex.trade").hostname;
  return `${host}${REFERRAL_ROUTE}/`;
};

export const baseVaultUrl = (vaultAddress: string): string => {
  return `${"https://app.paradex.trade"}${VAULTS_ROUTE}/${vaultAddress}`;
};

/**
 * @example
 *  referralUrl('cryptodegen');
 *  // 'app.paradex.trade/r/cryptodegen'
 */
export const referralUrl = (code: string): string => {
  return `${baseUrl()}${code}`;
};

/**
 * @example
 *  referralLink('cryptodegen');
 *  // 'https://app.paradex.trade/r/cryptodegen'
 */
export const referralLink = (code: string): string => {
  return `${"https://app.paradex.trade"}${REFERRAL_ROUTE}/${code}`;
};

/**
 * @example
 *  referralQrCodeUrl('cryptodegen');
 *  // 'https://api.prod.paradex.trade/referrrals/qr-code?code=cryptodegen'
 */
export const referralQrCodeUrl = (code: string, size = 64): string => {
  return `${"https://api.prod.paradex.trade/v1"}/referrals/qr-code?code=${code}&size=${size}`;
};
