import BigNumber from 'bignumber.js';

const smallValueFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});
const largeValueFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export default function formatMultiplier(value: BigNumber): string {
  const absValue = value.abs();
  if (absValue.isLessThan(1)) {
    return `${smallValueFormatter.format(absValue.toNumber())}x`;
  }
  return `${largeValueFormatter.format(absValue.toNumber())}x`;
}
