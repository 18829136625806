import * as Yup from 'yup';

import { CloseFormStore } from './form-store';

type FormShape = {
  [K in keyof CloseFormStore]: Yup.Schema<unknown>;
};

const yupFormSchema = Yup.object().shape<FormShape>({
  vaultName: Yup.string().required('Vault Name is required'),
});

const validate = async (formStore: CloseFormStore) =>
  yupFormSchema.validate(formStore);

const validateVaultName = async (expected: string, actual: string) => {
  if (expected !== actual) {
    throw new Yup.ValidationError(
      `Vault name does not match the expected vault name '${expected}'`,
    );
  }
};

/**
 * Yup's validate() handles static validation that is known beforehand.
 * While a set of functions handle more complex validation that depends on live data.
 */
export const formSchema = {
  validate,
  validateVaultName,
};
