import BigNumber from 'bignumber.js';

import L1_ERC20_BRIDGE_ABI from '#/features/abis/L1_ERC20_BRIDGE_ABI';
import { BridgedToken } from '#/features/paraclear';
import EthereumWallet, {
  EthereumAddress,
} from '#/features/wallets/ethereum/wallet';

import { toQuantums } from '#/utils/quantums';

import type { Hash } from 'viem';
import type { Config as WagmiConfig } from 'wagmi';

/**
 * Issues a request to sign a transaction to withdraw
 * funds from Ethereum ERC20 Token Bridge.
 *
 * @param amount Amount to be withdrawn. Must be the same requested in StarkNet.
 * @param l1Recipient Address of the recipient account in Ethereum.
 * @param token Token to be collected.
 *
 * @returns An object containing the transaction hash.
 */

export async function requestWithdraw(
  amount: BigNumber,
  l1Recipient: EthereumAddress,
  token: BridgedToken,
  wagmiConfig: WagmiConfig,
): Promise<Hash> {
  const amountQuantums = toQuantums(amount, token.decimals);

  const { request } = await EthereumWallet.simulateContract(wagmiConfig, {
    address: token.l1BridgeAddress,
    abi: L1_ERC20_BRIDGE_ABI,
    functionName: 'withdraw',
    args: [BigInt(amountQuantums), l1Recipient],
    /* the account to send the transaction from. */
    account: l1Recipient,
  });

  return EthereumWallet.writeContract(wagmiConfig, request);
}
