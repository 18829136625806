import BigNumber from 'bignumber.js';
import * as Yup from 'yup';

import { Market } from '#/api/markets';
import { MarketSummary } from '#/api/markets-summary';
import { OrderSide } from '#/api/orders';

import * as OrderBuilderFn from '#/features/perpetuals/order-builder/functions';

import { Maybe } from '#/utils/types';

import { MAX_TOTAL_ORDERS, MIN_TOTAL_ORDERS } from './config';
import { OrderTimeInForce, ScaledOrderFormStore } from './form-store';

const isBigNumber = (fieldName: string) =>
  [
    fieldName,
    `${fieldName} must be a BigNumber`,
    BigNumber.isBigNumber,
  ] as const;

const isFinite = (fieldName: string) =>
  [
    fieldName,
    `${fieldName} must be finite`,
    (bn: BigNumber) => bn.isFinite(),
  ] as const;

const isGreaterThanZero = (fieldName: string) =>
  [
    fieldName,
    `${fieldName} must be greater than zero`,
    (bn: BigNumber) => bn.isGreaterThan(0),
  ] as const;

const isWithinRange = (fieldName: string, min: number, max: number) =>
  [
    fieldName,
    `${fieldName} must be between ${min} and ${max}`,
    (bn: BigNumber) =>
      bn.isGreaterThanOrEqualTo(min) && bn.isLessThanOrEqualTo(max),
  ] as const;

type FormShape = {
  [K in keyof ScaledOrderFormStore]: Yup.Schema<unknown>;
};

const yupFormSchema = Yup.object().shape<FormShape>({
  side: Yup.mixed<OrderSide>()
    .oneOf(['BUY', 'SELL'])
    .required('Side is required'),
  currency: Yup.string()
    .required('Currency is required')
    .min(1, 'Currency must be at least 1 character long'),
  startPrice: Yup.mixed<BigNumber>()
    .required('Start Price required')
    .test(...isBigNumber('Start Price'))
    .test(...isFinite('Start Price'))
    .test(...isGreaterThanZero('Start Price')),
  endPrice: Yup.mixed<BigNumber>()
    .required('End Price required')
    .test(...isBigNumber('End Price'))
    .test(...isFinite('End Price'))
    .test(...isGreaterThanZero('End Price')),
  amount: Yup.mixed<BigNumber>()
    .required('Size required')
    .test(...isBigNumber('Size'))
    .test(...isFinite('Size'))
    .test(...isGreaterThanZero('Size')),
  leverage: Yup.mixed<BigNumber>()
    .nullable()
    .test('Leverage must be between 0 and 100', (value) => {
      if (value == null) return true;
      return value.isGreaterThanOrEqualTo(0) && value.isLessThanOrEqualTo(100);
    }),
  totalOrders: Yup.mixed<BigNumber>()
    .required('Total Orders required')
    .test(...isBigNumber('Total Orders'))
    .test(...isFinite('Total Orders'))
    .test(...isWithinRange('Total Orders', MIN_TOTAL_ORDERS, MAX_TOTAL_ORDERS)),
  sizeSkew: Yup.mixed<BigNumber>()
    .required('Size Skew required')
    .test(...isBigNumber('Size Skew'))
    .test(...isFinite('Size Skew'))
    .test(...isWithinRange('Size Skew', 0.01, 100.0)),
  isPostOnly: Yup.boolean(),
  isReduceOnly: Yup.boolean(),
  timeInForce: Yup.mixed<OrderTimeInForce>()
    .oneOf(['GTC', 'IOC'])
    .required('Time In Force is required'),
});

const validate = async (formStore: ScaledOrderFormStore) =>
  yupFormSchema.validate(formStore);

const validatePriceBands = (
  formStore: ScaledOrderFormStore,
  activeMarket: Maybe<Market>,
  activeMarketSummary: Maybe<MarketSummary>,
) => {
  if (formStore.startPrice == null) throw new Error('Start Price required');
  if (formStore.endPrice == null) throw new Error('End Price required');

  const priceBand = OrderBuilderFn.calcPriceBands(
    formStore.startPrice,
    activeMarket,
    activeMarketSummary,
  );
  const upperPrice = BigNumber.max(formStore.startPrice, formStore.endPrice);
  const lowerPrice = BigNumber.min(formStore.startPrice, formStore.endPrice);

  if (
    formStore.side === 'BUY' &&
    upperPrice.isGreaterThanOrEqualTo(priceBand.max)
  ) {
    throw new Yup.ValidationError(
      `Upper price outside price bands: must be <= '${priceBand.max.toFormat()}'`,
    );
  }

  if (
    formStore.side === 'SELL' &&
    lowerPrice.isLessThanOrEqualTo(priceBand.min)
  ) {
    throw new Yup.ValidationError(
      `Lower price outside price bands: must be >= '${priceBand.min.toFormat()}'`,
    );
  }
};

/**
 * Yup's validate() handles static validation that is known beforehand.
 * While a set of pure functions handle more complex validation that depends on live data.
 */
export const formSchema = {
  validate,
  validatePriceBands,
};
