import {
  findBridge,
  findSupportedChain,
  getAllBridges,
  isEnabledFilter,
  supportsAccountTypeFilter,
  supportsWalletKindFilter,
} from '#/features/bridging/bridges';
import { logException } from '#/features/logging/logging';

import { SupportedWalletKind } from './types';

import type { AccountType } from '#/features/wallet/common/base-account';

/** Initial value to use for Asset on deposits and withdrawals */
export const initialAsset = 'USDC';

/**
 * Computes the initial bridge.
 * If the default bridge is supported, it's returned.
 * If the default bridge is not supported, the first supported bridge is returned.
 */
export function computeInitialBridge(
  accountType: AccountType,
  walletKind: SupportedWalletKind,
): string {
  const supportedBridges = getAllBridges()
    .filter(isEnabledFilter(true))
    .filter(supportsAccountTypeFilter(accountType))
    .filter(supportsWalletKindFilter(walletKind));

  const defaultBridge = supportedBridges.find(
    (bridge) => bridge.config.id === "STARKGATE",
  );
  if (defaultBridge != null) {
    return defaultBridge.config.id;
  }

  const firstSupportedBridge = supportedBridges.find(
    (bridge) => bridge.config.supportedChains.length > 0,
  );

  if (firstSupportedBridge == null) {
    const message = `Error computing initial bridge: no supported bridges for account type '${accountType}'`;
    throw new Error(message);
  }

  return firstSupportedBridge.config.id;
}

/**
 * Computes the initial chain for the given bridge and chain.
 * If the chain is supported by the bridge, then it's returned.
 * If the chain is not supported by the bridge, the first supported
 * chain is returned and an error is logged.
 */
export function computeInitialChain(
  bridgeId: string,
  accountType: AccountType,
): string | undefined {
  const bridge = findBridge(bridgeId);
  if (bridge == null) {
    const message = `Error computing initial chain: bridge '${bridgeId}' not found`;
    throw new Error(message);
  }

  if (accountType === 'paradex') {
    const message = `Error computing initial chain: base account type '${accountType}' not supported`;
    throw new Error(message);
  }

  const defaultChain = accountType.toUpperCase();
  const supportedChain = findSupportedChain(bridge, defaultChain);

  if (supportedChain != null) {
    return supportedChain.id;
  }

  reportBridgeChainMismatch();
  return undefined;

  function reportBridgeChainMismatch() {
    const message = `Error computing initial chain: bridge '${bridgeId}' does not support chain '${defaultChain}'`;
    const error = new Error(message);
    logException(error, { bridgeId, chainId: defaultChain });
  }
}
