import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';

import { BalanceChain } from '#/api/balances-chain';

import { PollingConnectionBaseState } from '#/utils/usePollingConnection';

export const adapter = createEntityAdapter<BalanceChain, BalanceChain['token']>(
  {
    selectId: (balance) => balance.token,
    sortComparer: (a, b) => b.token.localeCompare(a.token),
  },
);

export interface BalancesChainState extends PollingConnectionBaseState {
  readonly contractAddress: string | undefined;
  readonly balances: EntityState<BalanceChain, BalanceChain['token']>;
}

export const INITIAL_STATE: BalancesChainState = {
  status: 'connected',
  error: '',
  contractAddress: undefined,
  balances: adapter.getInitialState(),
};
