import { Keys } from "./en";

const translations: Record<Keys, string> = {
  "Display trade history on chart":
    "Afficher l'historique des transactions sur le graphique",
  "Add markets to Favorites in the Markets Browser":
    "Ajoutez des marchés aux favoris dans le navigateur de marchés",
  "Log in": "Se connecter",
  "Export embedded wallet": "Exporter le portefeuille intégré",
  "Email or Social": "Email ou Social",
  "Failed to load balances": "Échec du chargement des soldes",
  "No balances": "Aucun solde",
  "Verified Operator": "Opérateur Vérifié",
  "Strategy": "Stratégie",
  "Multi-Strategy": "Multi-Stratégie",
  "Master Vault": "Coffre-fort Principal",
  "Master Vault Details": "Détails du coffre-fort principal",
  "Annualized": "Annualisé",
  "Depositors": "Déposants",
  "No trade history": "Aucun historique de transactions",
  "Unwinding Progress": "Progression du débouclement",
  "What are Vaults?": "C'est quoi les Coffres-forts ?",
  "APR calculated as the Annualised 30-day Return":
    "APR calculé comme la rentabilité annuelle sur 30 jours",
  "APR": "APR",
  "Initializing": "Initialisation",
  "Awaiting Deposit": "En attente de dépôt",
  "Vault Age": "Âge du coffre-fort",
  "30D Return": "Rendement sur 30J",
  "{{field}} must be greater than the minimum initial deposit of {{minInitialDeposit}}":
    "{{field}} doit être supérieur au dépôt initial minimum de {{minInitialDeposit}}",
  "Minimum deposit": "Montant minimum de dépôt",
  "Withdrawal amount exceeds available vault shares":
    "Le montant du retrait dépasse les parts disponibles du coffre-fort",
  "Deposit would exceed maximum vault TVL of {{maxTVL}}":
    "Le dépôt dépasserait la TVL maximale du coffre-fort de {{maxTVL}}",
  "{{field}} is required": "{{field}} est requis",
  "Lockup Remaining": "Restante du verrouillage",
  "{{days}}D": "{{days}}J",
  "<1>{{days}}</1> D": "<1>{{days}}</1> J",
  "<1>{{hours}}</1> H": "<1>{{hours}}</1> H",
  "<1>{{minutes}}</1> M": "<1>{{minutes}}</1> M",
  "Vault settings submitted": "Paramètres du coffre-fort envoyés",
  "Vault settings updated": "Paramètres du coffre-fort actualisés",
  "Vault Settings": "Paramètres du coffre-fort",
  "Vault not found": "Coffre-fort non trouvé",
  "The owner receives a {{profitShare}} profit share.":
    "Le propriétaire reçoit {{profitShare}} des bénéfices.",
  "This vault is entirely owned by the community.":
    "Ce coffre-fort est entièrement propriété de la communauté.",
  "Withdraw from Vault submitted": "Retrait du coffre-fort envoyé",
  "Deposit to Vault submitted": "Dépôt dans le coffre-fort envoyé",
  "Unknown error. Try again.": "Erreur inconnue. Réessayez.",
  "Vault Deposit": "Dépôt de coffre-fort",
  "Vault Withdrawal": "Retrait de coffre-fort",
  "No Lockup": "Sans verrouillage",
  "Lockup period is not over yet":
    "La période de verrouillage n'est pas terminée",
  "Protocol": "Protocole",
  "24H": "24H",
  "7D": "7J",
  "30D": "30J",
  "All": "Tout",
  "You have no investments in vaults":
    "Vous n'avez pas d'investissements dans les coffres-forts",
  "Total Value": "Valeur Totale",
  "Investments Made": "Investissements Réalisés",
  "Operator": "Opérateur",
  "Manage Vault": "Gérer le Coffre",
  "Switch to the operator account": "Passer au compte opérateur",
  "Active": "Actifs",
  "My Vaults": "Mes coffres-forts",
  "Closed": "Fermés",
  "Deposited": "Déposés",
  "Lifetime ROI": "ROI Total",
  "Owners Equity": "Capitaux Propres du Propriétaire",
  "Top Trending": "Tendances Principales",
  "Owner Share": "Part du Propriétaire",
  "Vault Details": "Détails du coffre-fort",
  "Contract": "Contrat",
  "Withdraw from Vault": "Retirer du coffre-fort",
  "Withdraw from Vault successful": "Retrait du coffre-fort réussi",
  "Failed to withdraw from Vault": "Échec du retrait du coffre-fort",
  "Name": "Nom",
  "Deposit": "Dépôt",
  "Tokens": "Jetons",
  "Avg. Entry Price": "Prix d'Entrée Moyen",
  "Current Price": "Prix Actuel",
  "P&L": "P&P",
  "My Vault Deposit": "Mon dépôt de coffre-fort",
  "Vaults": "Coffres-forts",
  "Create": "Créer",
  "Create My Vault": "Créer mon coffre-fort",
  "Vault Name": "Nom du coffre-fort",
  "Vault Description": "Description du coffre-fort",
  "Token Symbol": "Symbole du Jeton",
  "Deposit Amount": "Montant du Dépôt",
  "Vault description will be visible publicly and you will not be able to edit this later.":
    "La description du coffre-fort sera visible publiquement et vous ne pourrez pas la modifier ultérieurement.",
  "Once created, deposit at least {{minDeposit}} USDC to activate the vault. At this moment, only the Max TVL can be changed later.":
    "Une fois créé, déposez au moins {{minDeposit}} USDC pour activer le coffre-fort. À ce moment, seul le TVL maximum peut être modifié ultérieurement.",
  "Create Vault": "Créer un coffre-fort",
  "Profit Share": "Partage des Bénéfices",
  "Lockup Period (Days)": "Période de Blocage (Jours)",
  "Vault was successfully created and is being initialized":
    "Le coffre-fort a été créé avec succès et est en cours d'initialisation",
  "Failed to create a vault": "Échec de la création du coffre-fort",
  "Maximum TVL": "TVL Maximum",
  "No limit": "Pas de limite",
  "Vault Address": "Adresse du coffre-fort",
  "Deposit to Vault successful": "Dépôt dans le coffre-fort réussi",
  "Failed to deposit to Vault": "Échec du dépôt dans le coffre-fort",
  "Deposit to Vault": "Déposer dans le coffre-fort",
  "Vault": "Coffre-fort",
  "Owner": "Propriétaire",
  "Age": "Âge",
  "Failed to load vaults": "Échec du chargement des coffres-forts",
  "No vaults available": "Aucun coffre-fort disponible",
  "Performance": "Performance",
  "Vault Value": "Valeur du coffre-fort",
  "My Deposit": "Mon Dépôt",
  "Total P&L": "P&P Total",
  "Protocol Vaults": "Coffres-forts du protocole",
  "User Vaults": "Coffres-forts utilisateur",
  "No Data": "Aucune Donnée",
  "Last Month Return": "Rendement du Mois Dernier",
  "TVL": "TVL",
  "Total Earnings": "Gains Totaux",
  "About": "À Propos",
  "Vault Stats": "Statistiques du coffre-fort",
  "My Stats": "Mes Statistiques",
  "Vault Contract": "Contrat du coffre-fort",
  "Description": "Description",
  "Max Drawdown": "Drawdown Maximum",
  "Volume": "Volume",
  "All-Time P&L": "P&P de Tous les Temps",
  "Days": "Jours",
  "Lockup Period": "Période de Blocage",
  "Search Vaults": "Rechercher des coffres-forts",
  "Blog": "Blog",
  "Block Explorer": "Explorateur de Blocs",
  "Close Vault": "Fermer le coffre-fort",
  "Close Vault successful": "Fermeture du coffre-fort réussie",
  "You must close all open positions to close your Vault. Closed vaults cannot be reopened.":
    "Vous devez fermer toutes les positions ouvertes pour fermer votre coffre-fort. Les coffres-forts fermés ne peuvent pas être rouverts.",
  "Close My Vault": "Fermer mon coffre-fort",
  "Failed to Close the Vault": "Échec de la fermeture du coffre-fort",
  "Only the Main Account can manage the vault":
    "Seul le compte principal peut gérer le coffre-fort",
  "Allocate Collateral": "Allouer des Garanties",
  "Switch to Main Account": "Passer au Compte Principal",
  "Switch to Account": "Passer au Compte",
  "You are currently trading in the {{market}} market.":
    "Vous tradez actuellement sur le marché {{market}}.",
  "To start trading on Isolated Market you must Allocate Collateral from your Main Account and switch to this Market Account.":
    "Pour commencer à trader sur le Marché Isolé, vous devez Allouer des Garanties depuis votre Compte Principal et passer à ce Compte de Marché.",
  "To trade in this market, switch to the Main Account first.":
    "Pour trader sur ce marché, passez d'abord au Compte Principal.",
  "This account can only trade in the {{market}} market.":
    "Ce compte ne peut trader que sur le marché {{market}}.",
  "Isolated Market": "Marché Isolé",
  "This feature currently is still in Beta. Backup your Paradex Private Key for additional safety.":
    "Cette fonctionnalité est actuellement en version bêta. Sauvegardez votre clé privée Paradex pour une sécurité supplémentaire.",
  "Deposit failed": "Dépôt échoué",
  "Deposit in progress": "Dépôt en cours",
  "External Account": "Compte externe",
  "Tx Hashes": "Hashes de transactions",
  "Continue to {{bridge}}": "Continuer vers {{bridge}}",
  "By clicking Continue to {{bridge}}, you will be sent to {{bridge}} website to bridge your funds out of Paradex.":
    "En cliquant sur Continuer vers {{bridge}}, vous serez redirigé vers le site web de {{bridge}} pour transférer vos fonds depuis Paradex.",
  "By clicking Continue to {{bridge}}, you will be sent to {{bridge}} website to bridge your funds to Paradex.":
    "En cliquant sur Continuer vers {{bridge}}, vous serez redirigé vers le site web de {{bridge}} pour transférer vos fonds vers Paradex.",
  "Refer": "Référer",
  "Address": "Adresse",
  "Date Joined": "Date d'inscription",
  "Referral Code": "Code de parrainage",
  "Volume Traded": "Volume échangé",
  "Bridge": "Pont",
  "Withdraw to": "Retirer vers",
  "Deposit from": "Dépôt depuis",
  "Position is already closed": "La position est déjà fermée",
  "Your Paradex L2 Address is NOT a Public Starknet Address. If you try to send funds to this address on Public Starknet via a cross chain bridge you risk losing your funds. <1>Read more</1>.":
    "Votre adresse Paradex L2 n'est PAS une adresse Starknet publique. Si vous essayez d'envoyer des fonds à cette adresse sur Starknet public via un pont inter-chaînes, vous risquez de perdre vos fonds. <1>Lire plus</1>.",
  "{{chain}} Address": "Adresse {{chain}}",
  "Copy Paradex Private Key": "Copier la clé privée Paradex",
  "Your account will be liquidated if Margin Ratio reaches 100%":
    "Votre compte sera liquidé si le Ratio de Marge atteint 100%",
  "Liquidation": "Liquidation",
  "Fee": "Frais",
  "Maker": "Faiseur",
  "Maker Score": "Score de Faiseur",
  "Maker XP Share": "Part de XP de Faiseur",
  "Fee XP Share": "Part de XP de Frais",
  "Fee XP": "XP de Frais",
  "Maker XP": "XP de Faiseur",
  "Liquidation {{side}} Fill": "Liquidation {{side}} Remplir",
  "Settings": "Paramètres",
  "Market Score": "Score du Marché",
  "Season {{season}} (coming soon)": "Saison {{season}} (bientôt disponible)",
  "Confirm Buy": "Confirmer l'achat",
  "Confirm Sell": "Confirmer la vente",
  "Other XP": "Autres XP",
  "Estimated Receivable Amount": "Montant estimé à recevoir",
  "Socialized Loss active. Click to learn more.":
    "Perte socialisée active. Cliquez pour en savoir plus.",
  "Analytics": "Analytique",
  "Sign In to see your account XP stats":
    "Connectez-vous pour voir les statistiques de vos XP de compte",
  "<1>Clock Is Out Of Sync</1> The clock on your device is out of sync. This may cause issues with the application. Please make sure your device clock is set to be auto. For more details please <2>see documentation</2>":
    "<1>L'horloge est désynchronisée</1> L'horloge de votre appareil est désynchronisée. Cela peut causer des problèmes avec l'application. Assurez-vous que l'horloge de votre appareil est réglée sur auto. Pour plus de détails, veuillez <2>voir la documentation</2>",
  "Failed to fetch max withdrawable amount":
    "Échec de la récupération du montant maximum de retrait",
  "It is not possible to withdraw more than your Max Withdrawable Amount.":
    "Il n'est pas possible de retirer plus que votre montant maximum de retrait.",
  "Max Withdrawable Amount": "Montant maximum de retrait",
  "XP Explainer": "Explicateur de XP",
  "Pool": "Niveau",
  "Quote Quality": "Qualité des cotations",
  "Create Your Public Username": "Créer votre nom d'utilisateur public",
  "Edit Your Public Username": "Modifier votre nom d'utilisateur public",
  "You have been logged out.": "Vous avez été déconnecté.",
  "Maker Volume Score": "Score de volume du Faiseur",
  "Fee Score": "Score de Frais",
  "Score Share": "Part de Score",
  "Instrument Share": "Part d'Instrument",
  "XP": "XP",
  "Total XP": "XP Total",
  "24h Change": "Changement en 24h",
  "Leaderboard": "Leaderboard",
  "Cancel": "Annuler",
  "Portfolio": "Portefeuille",
  "Trade": "Commerce",
  "Build": "Construire",
  "Referrals Explainer": "Explicateur de Parrainages",
  "How It Works": "Comment ça marche",
  "My Referrals": "Mes Parrainages",
  "Your Referral Code": "Votre code de parrainage",
  "Affiliate Program": "Programme d'affiliation",
  "Enrolled": "Inscrit",
  "Traders Referred": "Traders référés",
  "Referral Rewards": "Récompenses de parrainage",
  "Referral XP": "XP de parrainage",
  "Invite Friends": "Inviter des amis",
  "Share": "Partager",
  "Earn": "Gagner",
  "Give": "Donner",
  "Share your code to invite friends to join Paradex":
    "Partagez votre code pour inviter des amis à rejoindre Paradex",
  "<1>{{amount}}</1> Fees": "<1>{{amount}}</1> de frais",
  "You receive {{amount}} of the referred user's fees":
    "Vous recevez {{amount}} des frais de l'utilisateur recommandé",
  "<1>{{amount}}</1> XP": "<1>{{amount}}</1> de XP",
  "You receive {{amount}} of the referred user's XP":
    "Vous recevez {{amount}} de l'XP de l'utilisateur recommandé",
  "<1>{{amount}}</1> Discount": "<1>{{amount}}</1> de réduction",
  "Your friend receives {{amount}} discount on fees":
    "Votre ami reçoit une réduction de {{amount}} sur les frais",
  "Create and share your custom referral code":
    "Créez et partagez votre code de parrainage personnalisé",
  "If you have a strong social media following (<1>{{followers}}+</1>), you may be eligible for our <2>VIP Affiliate Program</2> with higher commissions and XP.":
    "Si vous avez une forte présence sur les réseaux sociaux (<1>{{followers}}+</1>), vous pourriez être éligible à notre programme d'affiliation VIP avec des commissions et des XP plus élevés.",
  "You can track our <1>Affiliate Leaderboard</1> to see how you stack up.":
    "Vous pouvez suivre notre <1>Classement des Affiliés</1> pour voir comment vous vous positionnez.",
  "Apply": "Appliquer",
  "Log in to see your Referral History.":
    "Connectez-vous pour voir votre historique de parrainage.",
  "Welcome to Paradex": "Bienvenue sur Paradex",
  "Congrats! The referral code you used is now active! It means you can start enjoying a discount on your trading fees!":
    "Félicitations! Le code de parrainage que vous avez utilisé est maintenant actif! Cela signifie que vous pouvez commencer à bénéficier d'une réduction sur vos frais de trading!",
  "Congrats! The referral code <1>{{codeProvided}}</1> used is now active!":
    "Félicitations! Le code de parrainage <1>{{codeProvided}}</1> utilisé est maintenant actif!",
  "Referral code not activated. The code can only be applied once during the initial onboarding.":
    "Code de parrainage non activé. Le code ne peut être appliqué qu'une seule fois lors de l'embarquement initial.",
  "Referral code <1>{{codeProvided}}</1> not activated. You have already onboarded with <1>{{referredBy}}</1>.":
    "Code de parrainage <1>{{codeProvided}}</1> non activé. Vous avez déjà embarqué avec <1>{{referredBy}}</1>.",
  "Referral Code Applied": "Code de parrainage appliqué",
  "You will receive <1>{{userAmount}}</1> of referred user's <1>fees</1> <2>and</2> <1>{{userAmountPoints}} XP</1>. They will receive a <1>{{friendAmount}}</1> discount.":
    "Vous recevrez <1>{{userAmount}}</1> des <1>frais</1> de l'utilisateur recommandé <2>et</2> <1>{{userAmountPoints}} XP</1>. Ils recevront une réduction de <1>{{friendAmount}}</1>.",
  "Community": "Communauté",
  "Environments": "Environnements",
  "System Status": "Statut du système",
  "Language": "Langue",
  "Docs": "Docs",
  "Code Samples": "Exemples de code",
  "More": "Plus",
  "Stats": "Statistiques",
  "Join Discord": "Rejoindre Discord",
  "Notifications": "Notifications",
  "All caught up!": "Tout est à jour!",
  "You have no new notifications": "Vous n'avez pas de nouvelles notifications",
  "Connect Wallet": "Connecter le portefeuille",
  "WalletConnect is taking too long to open. Please refresh the page and try again.":
    "WalletConnect met trop de temps à s'ouvrir. Veuillez rafraîchir la page et réessayer.",
  "Unexpected error opening WalletConnect modal. Please refresh the page and try again.":
    "Erreur inattendue lors de l'ouverture du modal WalletConnect. Veuillez rafraîchir la page et réessayer.",
  "Connect your Wallet": "Connectez votre portefeuille",
  "Link Wallet": "Lier le portefeuille",
  "You will receive a signature request. Signing is free and will not send a transaction.":
    "Vous recevrez une demande de signature. La signature est gratuite et n'envoie pas de transaction.",
  "Generate Paradex Chain wallet": "Générer un portefeuille Paradex Chain",
  "Generating Paradex Chain wallet": "Génération du portefeuille Paradex Chain",
  "Paradex Chain wallet generated": "Portefeuille Paradex Chain généré",
  "Verify that you own this wallet":
    "Vérifier que vous possédez ce portefeuille",
  "Remember Me": "Se souvenir de moi",
  "Only use 'Remember Me' if you are using a secure device you own. Selecting this option can expose your information to  others if you are accessing this wallet from a public device.":
    "N'utilisez 'Se souvenir de moi' que si vous utilisez un appareil sécurisé que vous possédez. Sélectionner cette option peut exposer vos informations à d'autres si vous accédez à ce portefeuille depuis un appareil public.",
  "Continue": "Continuer",
  "Disconnect Wallet": "Déconnecter le portefeuille",
  "Disconnect": "Déconnecter",
  "Account": "Compte",
  "Username": "Nom d'utilisateur",
  "Edit": "Modifier",
  "Your username is now hidden. It is not visible to other users.":
    "Votre nom d'utilisateur est maintenant caché. Il n'est pas visible par les autres utilisateurs.",
  "Your username will be visible to other users unless you choose to hide it. You can always change it later.":
    "Votre nom d'utilisateur sera visible par les autres utilisateurs à moins que vous ne choisissiez de le cacher. Vous pouvez toujours le modifier plus tard.",
  "Hide my Username": "Cacher mon nom d'utilisateur",
  "Save": "Enregistrer",
  "Username is required": "Le nom d'utilisateur est requis",
  "Username must be between 5 and 20 characters":
    "Le nom d'utilisateur doit comporter entre 5 et 20 caractères",
  "Username can only contain letters and numbers":
    "Le nom d'utilisateur ne peut contenir que des lettres et des chiffres",
  "Wallets": "Portefeuilles",
  "Paradex Block Explorer": "Explorateur de blocs Paradex",
  "Log out": "Se déconnecter",
  "No markets available": "Aucun marché disponible",
  "Last Price": "Dernier prix",
  "Open Interest": "Intérêt ouvert",
  "24h Volume": "Volume sur 24h",
  "Mark Price": "Prix de marque",
  "Spot Price": "Prix au comptant",
  "Eight hour funding rate that is continuously accrued on positions and settled upon any trade. If the funding rate is positive, longs pay shorts. If negative, shorts pay longs.":
    "Taux de financement de huit heures qui est continuellement accumulé sur les positions et réglé à chaque transaction. Si le taux de financement est positif, les longs paient les shorts. Si négatif, les shorts paient les longs.",
  "8h Funding": "Financement de 8h",
  "Limit": "Limite",
  "Market": "Marché",
  "Stop Limit": "Stop Limite",
  "Stop Market": "Stop Marché",
  "Scaled Order": "Ordre Échelonné",
  "Trigger Price": "Prix de Déclenchement",
  "Input Price": "Prix d'entrée",
  "Position Mark Price": "Prix de marque de la position",
  "Amount": "Montant",
  "Reduce Only": "Réduire uniquement",
  "Reduce Only will increase position":
    "Réduire uniquement augmentera la position",
  "Your order stays open until it is filled or you decide to cancel.":
    "Votre commande reste ouverte jusqu'à ce qu'elle soit exécutée ou que vous décidiez de l'annuler.",
  "Your order gets filled right away, partially or fully, and whatever is left gets canceled instantly.":
    "Votre commande est immédiatement exécutée, partiellement ou totalement, et tout ce qui reste est instantanément annulé.",
  "Conditional limit order that serves to add liquidity to the order book as a Maker order.":
    "Ordre limite conditionnel qui sert à ajouter de la liquidité au carnet d'ordres en tant qu'ordre Maker.",
  "Currently the fees are fixed and uniform across the platform. Maker {{makerFee}} / Taker {{takerFee}}":
    "Actuellement, les frais sont fixes et uniformes sur la plateforme. Faiseur {{makerFee}} / Preneur {{takerFee}}",
  "Value": "Valeur",
  "Buy": "Acheter",
  "BUY": "ACHETER",
  "LONG": "LONG",
  "Sell": "Vendre",
  "SELL": "VENDRE",
  "SHORT": "COURT",
  "Order Value": "Valeur de la commande",
  "Current Position": "Position actuelle",
  "Account Value": "Valeur du compte",
  "The total value of your account at current mark prices":
    "La valeur totale de votre compte aux prix actuels",
  "Unrealized P&L": "P&L non réalisé",
  "Free Collateral": "Collatéral libre",
  "Account Leverage": "Effet de levier du compte",
  "Margin": "Marge",
  "Margin Ratio": "Ratio de marge",
  "Initial Margin": "Marge initiale",
  "Maintenance Margin": "Marge de maintenance",
  "Order Book": "Carnet d'ordres",
  "Trades": "Trades",
  "Price": "Prix",
  "Size": "Taille",
  "Total": "Total",
  "Time": "Temps",
  "Spread": "Spread",
  "Positions": "Positions",
  "Open Orders": "Ordres ouverts",
  "Trade History": "Historique des transactions",
  "Order History": "Historique des ordres",
  "Funding Payments": "Paiements de financement",
  "Side": "Côté",
  "Entry Price": "Prix d'entrée",
  "Liquidation Price": "Prix de Liquidation",
  "Unrealized Funding": "Financement non réalisé",
  "Close Position": "Fermer la position",
  "Market Close": "Clôture du marché",
  "Close Long": "Clôturer Long",
  "Close Short": "Clôturer Short",
  "Type": "Type",
  "Filled": "Rempli",
  "Triggered": "Déclenché",
  "Instruction": "Instruction",
  "Client Order Id": "ID de commande client",
  "Status": "Statut",
  "NEW": "NOUVEAU",
  "OPEN": "OUVERT",
  "UNTRIGGERED": "Non déclenché",
  "CLOSED": "FERMÉ",
  "Pending": "En attente",
  "Complete": "Complet",
  "Completed": "Complété",
  "Failed": "Échoué",
  "Bust": "Éclatement",
  "Value / Fee": "Valeur / Frais",
  "Liquidity": "Liquidité",
  "Tx Hash": "Hash de transaction",
  "Trade Id": "Identifiant de transaction",
  "Remaining": "Restant",
  "Cancel Reason": "Raison d'annulation",
  "User Canceled": "Utilisateur annulé",
  "Not Enough Margin": "Marge insuffisante",
  "Empty Market": "Marché vide",
  "Post Only": "Post Only",
  "Post Only Would Cross": "Post Only croiserait",
  "Remaining IOC Cancel": "Annulation restante IOC",
  "Unexpected Failure": "Échec inattendu",
  "Deleverage": "Désendettement",
  "In Liquidation": "En liquidation",
  "Self Trade": "Auto-négociation",
  "Asset Unavailable": "Actif indisponible",
  "Asset Expired": "Actif expiré",
  "Order Type Invalid": "Type d'ordre invalide",
  "Price Not Available": "Prix non disponible",
  "Expired": "Expiré",
  "Exceeds Max Slippage": "Excede le maximum de décalage",
  "Timeout": "Temps écoulé",
  "Order Exceeds Position Limit": "L'ordre dépasse la limite de position",
  "Order Id": "Id de l'ordre",
  "Payment": "Paiement",
  "Cancel All": "Annuler tout",
  "{{orderSize}} will be closed at Market price":
    "{{orderSize}} sera fermé au prix du marché",
  "By connecting a wallet, you agree to <1>Paradex Terms of Service</1> and represent and warrant to Paradex that you are not a <2>Restricted Person</2>.":
    "En connectant un portefeuille, vous acceptez les <1>Conditions d'utilisation de Paradex</1> et déclarez et garantissez à Paradex que vous n'êtes pas une <2>Personne restreinte</2>.",
  "Wallet": "Portefeuille",
  "Deposit_verb": "Déposer",
  "Deposit_noun": "Dépôt",
  "Withdraw": "Retirer",
  "Withdrawal": "Retrait",
  "Transfers": "Transferts",
  "Transfer": "Transférer",
  "Collateral": "Collatéral",
  "Asset": "Actif",
  "Wallet Balance": "Solde du portefeuille",
  "Actions": "Actions",
  "Action": "Action",
  "External Tx Hash": "Hash de transaction externe",
  "Withdraw to Wallet": "Retirer vers le portefeuille",
  "Deposit to Paradex": "Déposer à Paradex",
  "You have no balance in your Ethereum wallet. Deposit some {{asset}} and try again.":
    "Vous n'avez aucun solde dans votre portefeuille Ethereum. Déposez un peu de {{asset}} et réessayez.",
  "It is not possible to deposit more than your current balance.":
    "Il n'est pas possible de déposer plus que votre solde actuel.",
  "Available": "Disponible",
  "Initiate a deposit from your Ethereum wallet to the Paradex bridge. These transactions":
    "Initier un dépôt depuis votre portefeuille Ethereum vers le pont Paradex. Ces transactions",
  "Initiate a deposit from your Ethereum wallet to the Paradex bridge. These transactions may take several minutes depending on network conditions. For more information check out our <1>docs</1>.":
    "Initier un dépôt depuis votre portefeuille Ethereum vers le pont Paradex. Ces transactions peuvent prendre plusieurs minutes en fonction des conditions du réseau. Pour plus d'informations, consultez nos <1>docs</1>.",
  "read more": "lire plus",
  "Initiate Deposit to Bridge": "Initier le dépôt vers le pont",
  "Enable USDC on Paradex": "Activer USDC sur Paradex",
  "In order to deposit you must allow Paradex access to at least {{amount}}.":
    "Pour déposer, vous devez autoriser l'accès de Paradex à au moins {{amount}}.",
  "Enable USDC": "Activer USDC",
  "Withdraw initiated": "Retrait initié",
  "Withdrawals are a two-step process. First initiate a withdrawal from Paradex":
    "Les retraits sont un processus en deux étapes. Commencez par initier un retrait de Paradex",
  "Withdrawals are a two-step process. First initiate a withdrawal from Paradex to the Paradex Bridge. This first step can take 5-16 hours (10 on average). Second, initiate your withdrawal from the Paradex Bridge to your Ethereum wallet via the Deposits / Withdrawals table. For more information check out our <1>docs</1>.":
    "Les retraits sont un processus en deux étapes. Commencez par initier un retrait de Paradex vers le pont Paradex. Cette première étape peut prendre de 5 à 16 heures (10 en moyenne). Deuxièmement, initiez votre retrait du pont Paradex vers votre portefeuille Ethereum via le tableau Dépôts / Retraits. Pour plus d'informations, consultez nos <1>docs</1>.",
  "Initiate Withdrawal to Bridge": "Initier le retrait vers le pont",
  "You have no funds to withdraw. It is not possible to withdraw more than your free collateral.":
    "Vous n'avez pas de fonds à retirer. Il n'est pas possible de retirer plus que votre collatéral libre.",
  "It is not possible to withdraw more than your free collateral.":
    "Il n'est pas possible de retirer plus que votre collatéral libre.",
  "You have no collateral": "Vous n'avez pas de collatéral",
  "You have no open positions": "Vous n'avez pas de positions ouvertes",
  "You have no open orders": "Vous n'avez pas de commandes ouvertes",
  "You have no trades": "Vous n'avez pas de transactions",
  "You have no orders": "Vous n'avez pas de commandes",
  "You have no funding payments": "Vous n'avez pas de paiements de financement",
  "You have no transfers": "Vous n'avez pas de transferts",
  "Account in liquidation": "Compte en liquidation",
  "Account is being liquidated. Any open positions will be reduced. Trading now is disabled.":
    "Le compte est en cours de liquidation. Toutes les positions ouvertes seront réduites. Le trading est maintenant désactivé.",
  "Account liquidated": "Compte liquidé",
  "Your account was liquidated on {{createdAt}}. Any open positions were reduced.":
    "Votre compte a été liquidé le {{createdAt}}. Toutes les positions ouvertes ont été réduites.",
  "Deposit to Paradex successful": "Dépôt à Paradex réussi",
  "Deposit to Paradex failed": "Échec du dépôt à Paradex",
  "Unexpected error. Try again.": "Erreur inattendue. Réessayez.",
  "Deposit initiated": "Dépôt initié",
  "Deposit to bridge initiated": "Dépôt au pont initié",
  "Deposit to Paradex available": "Dépôt à Paradex disponible",
  "Go to Deposits": "Aller aux dépôts",
  "Deposit to Paradex in progress": "Dépôt à Paradex en cours",
  "Transfer successful": "Transfert réussi",
  "Limit {{side}} Order Fill": "Remplissage de l ordre {{side}} limité",
  "{{type}} {{side}} Order Submitted": "{{type}} {{side}} Commande soumise",
  "{{type}} {{side}} Order Canceled": "{{type}} {{side}} Commande annulée",
  "Reason": "Raison",
  "Market {{side}} Order Fill": "Marché {{side}} Commande remplie",
  "Trade Busted On-Chain": "Échange éclaté sur la chaîne",
  "Fill id": "Remplir id",
  "Withdraw to wallet failed": "Échec du retrait vers le portefeuille",
  "Withdrawal to bridge initiated": "Retrait vers le pont initié",
  "Withdrawal error. Try again.": "Erreur de retrait. Réessayez.",
  "Withdrawal to bridge in progress": "Retrait vers le pont en cours",
  "Withdrawal to wallet initiated": "Retrait vers le portefeuille initié",
  "Withdrawal to bridge failed": "Échec du retrait vers le pont",
  "Transaction error. Try again.": "Erreur de transaction. Réessayez.",
  "Withdrawal to wallet available": "Retrait vers le portefeuille disponible",
  "Go to Withdrawals": "Aller aux retraits",
  "Withdrawal to wallet successful": "Retrait vers le portefeuille réussi",
  "Session expired, please log in again.":
    "La session a expiré, veuillez vous reconnecter.",
  "Failed to submit order": "Échec de la soumission de la commande",
  "Your username preferences were updated!":
    "Vos préférences de nom d'utilisateur ont été mises à jour!",
  "Unexpected error updating username preferences":
    "Erreur inattendue lors de la mise à jour des préférences de nom d'utilisateur",
  "This username already exists. Please choose a different username and try again.":
    "Ce nom d'utilisateur existe déjà. Veuillez choisir un autre nom d'utilisateur et réessayer.",
  "Validation Error": "Erreur de validation",
  "Binding Error": "Erreur de liaison",
  "Internal Error": "Erreur interne",
  "Not Found": "Non trouvé",
  "Service Unavailable": "Service indisponible",
  "Invalid Request Parameter": "Paramètre de demande invalide",
  "Order Id Not Found": "Id de commande non trouvé",
  "Order Is Closed": "La commande est fermée",
  "Order Is Not Open Yet": "La commande n'est pas encore ouverte",
  "Client Order Id Not Found": "Id de commande client non trouvé",
  "Duplicated Client Id": "Id client dupliqué",
  "Invalid Price Precision": "Précision du prix invalide",
  "Invalid Token": "Token invalide",
  "Invalid Ethereum Address": "Adresse Ethereum invalide",
  "Blocked Ethereum Address": "Adresse Ethereum bloquée",
  "Invalid Ethereum Signature": "Signature Ethereum invalide",
  "Invalid Starknet Address": "Adresse Starknet invalide",
  "Invalid Starknet Signature": "Signature Starknet invalide",
  "Starknet Signature Verification Failed":
    "Échec de la vérification de la signature Starknet",
  "Bad Starknet Request": "Mauvaise requête Starknet",
  "Ethereum Signer Mismatch": "Incohérence du signataire Ethereum",
  "Ethereum Hash Mismatch": "Incohérence du hash Ethereum",
  "Not Onboarded": "Non embarqué",
  "Invalid Timestamp": "Horodatage invalide",
  "Invalid Signature Expiration": "Expiration de la signature invalide",
  "Account Not Found": "Compte non trouvé",
  "Invalid Order Signature": "Signature de commande invalide",
  "Public Key Invalid": "Clé publique invalide",
  "Unauthorized Ethereum Address": "Adresse Ethereum non autorisée",
  "Ethereum Address Already Onboarded": "Adresse Ethereum déjà embarquée",
  "Market Not Found": "Marché non trouvé",
  "Allowlist Entry Not Found": "Entrée de liste blanche non trouvée",
  "Username In Use": "Nom d'utilisateur en usage",
  "Service access restricted in your region":
    "Accès au service restreint dans votre région",
  "No Access Allowed": "Aucun accès autorisé",
  "You do not have access to the platform. Please join our Discord to get access.":
    "Vous n'avez pas accès à la plateforme. Veuillez rejoindre notre Discord pour obtenir un accès.",
  "{{actualChainName}} Wallet Connected":
    "Portefeuille {{actualChainName}} connecté",
  'Incorrect network selected "{{actualChainName}}". Please change the network to "{{requiredChainName}}" (id={{requiredChainId}}) in your wallet and retry.':
    'Réseau incorrect sélectionné "{{actualChainName}}". Veuillez changer le réseau à "{{requiredChainName}}" (id={{requiredChainId}}) dans votre portefeuille et réessayer.',
  "Choose Wallet": "Choisir un Portefeuille",
  "Starknet Wallet": "Portefeuille Starknet",
  "Switch to {{env}}": "Passer à {{env}}",
  "Search": "Rechercher",
  "League": "Ligue",
  "Switch Account": "Changer de compte",
  "Rank": "Rang",
  "24h XP": "XP sur 24h",
  "XP Per Day": "XP par jour",
  "Season Volume": "Volume de saison",
  "Lifetime Volume": "Volume à vie",
  "Pre-Season": "Pré-Saison",
  "Earn More XP": "Gagner plus de XP",
  "XP Boost": "Boost de XP",
  "XP Drop": "Chute de XP",
  "Trader": "Trader",
  "Total Share": "Part totale",
  "Maker Share": "Part de Faiseur",
  "Maker Volume Share": "Part de Volume de Faiseur",
  "Fee Share": "Part de Frais",
  "Fee Volume Share": "Part de Volume de Frais",
  "No data available": "Aucune donnée disponible",
  "Refer & Earn": "Référer et Gagner",
  "Hours": "Heures",
  "Minutes": "Minutes",
  "Time/Duration": "Temps/Durée",
  "Runtime": "Durée d'exécution",
  "Frequency": "Fréquence",
  "Number of Orders": "Nombre d'ordres",
  "Runtime should not be empty": "La durée d'exécution ne doit pas être vide",
  "Runtime should be less than or equal to 24 hours":
    "La durée d'exécution doit être inférieure ou égale à 24 heures",
  "Inactive": "Inactif",
  "Avg. Price": "Prix moyen",
  "The minimum required amount for {{numOrders}} orders is {{totalSize}} {{currency}}.":
    "Le montant minimum requis pour {{numOrders}} commandes est de {{totalSize}} {{currency}}.",
  "Take Profit / Stop Loss: Set take profit and stop loss price levels to automate trade actions. When the Mark Price will reach the price you set, it will send your order to the matching engine.":
    "Take Profit / Stop Loss : Définissez des niveaux de prix de prise de profit et de stop loss pour automatiser les actions de trading. Lorsque le prix de référence atteindra le prix que vous avez fixé, il enverra votre ordre au moteur de correspondance.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount with an estimated profit of <3 />.":
    "Lorsque le prix de marquage atteint <1 />, cela déclenchera l'ordre <2 /> pour fermer le montant sélectionné avec un profit estimé de <3 />.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount with an estimated loss of <3 />.":
    "Lorsque le prix de marquage atteint <1 />, cela déclenchera l'ordre <2 /> pour fermer le montant sélectionné avec une perte estimée de <3 />.",
  "Edit TP/SL For This Position": "Modifier TP/SL pour cette position",
  "Take Profit": "Take Profit",
  "Profit": "Profit",
  "Stop Loss": "Stop Loss",
  "Loss": "Perte",
  "Confirm": "Confirmer",
  "Take Profit Limit": "Limite de Take Profit",
  "Take Profit Market": "Take Profit au Marché",
  "Stop Loss Limit": "Limite de Stop Loss",
  "Stop Loss Market": "Stop Loss au Marché",
  "Funding": "Financement",
  "Unwinding": "Dénouement",
  "Cancel TP/SL orders": "Annuler les ordres TP/SL",
  "TP/SL orders submitted successfully.":
    "Les ordres TP/SL ont été soumis avec succès.",
  "Pre-Season + All Seasons": "Pré-Saison + Toutes les saisons",
  "Season {{season}}": "Saison {{season}}",
  "XP Season {{season}}": "XP Saison {{season}}",
  "Slippage": "Glissement",
  "Max Slippage": "Glissement maximal",
  "Max slippage defines the largest acceptable price deviation from the mark price.":
    "Le glissement maximal définit la plus grande déviation de prix acceptable par rapport au prix de référence.",
  "During execution, the order can be canceled if the price exceeds the slippage limit or falls outside the Market <1>Price Band</1>.":
    "Lors de l'exécution, l'ordre peut être annulé si le prix dépasse la limite de glissement ou sort de la <1>bande de prix</1> du marché.",
  "The Maximum Slippage allowed for {{symbol}} is {{price_band_width}}.":
    "Le glissement maximal autorisé pour {{symbol}} est de {{price_band_width}}.",
  "Update Max Slippage": "Mettre à jour le glissement maximal",
  "Mark IV": "Mark IV",
  "Delta": "Delta",
  "Failed to load positions": "Échec du chargement des positions",
  "No open positions": "Aucune position ouverte",
  "learn more": "en savoir plus",
  "Learn More": "En Savoir Plus",
  "Search Markets": "Rechercher les Marchés",
  "Futures": "Contrats à Terme",
  "Options": "Options",
  "OI": "Intérêt Ouvert",
  "My Profile": "Mon Profil",
  "Addresses": "Adresses",
  "Chart": "Graphique",
  "ROI": "ROI",
  "Sharpe": "Sharpe",
  "Max Loss": "Perte maximale",
  "Total Volume": "Volume total",
  "My NFTs": "Mes NFTs",
  "My XP": "Mon XP",
  "Balances": "Saldos",
  "Other": "Autres",
  "Paradex XP: Pre-Season": "Paradex XP: Pré-Saison",
  "Paradex XP: Season {{season}}": "Paradex XP: Saison {{season}}",
  "Season {{season}} XP": "Saison {{season}} XP",
  "Refer your friends to earn 10%": "Référer des amis et gagner 10%",
  "Refer Friends and Earn 10%": "Référer des amis et gagner 10%",
  "Invest in Paradex Vault & Earn 3X Boost":
    "Investir dans Paradex Vault & Gagner 3X Boost",
  "Avg. Daily XP": "XP moyen par jour",
  "Lifetime XP": "XP de vie",
  "My Rank": "Mon Rang",
  "What is XP?": "Qu'est-ce que XP ?",
  "XP History": "Histoire de XP",
  "Last Week XP": "XP de la semaine dernière",
  "TVL XP": "XP TVL",
  "OI XP": "XP OI",
  "Liquidation XP": "XP Liquidation",
  "Vaults XP": "XP Coffres-forts",
  "Week": "Semaine",
  "Season": "Saison",
  "Last updated: {{timestamp}}": "Dernière mise à jour: {{timestamp}}",
  "Xperience Points (XP)": "Points d'expérience (XP)",
  "Log in to see your XP balance": "Connectez-vous pour voir votre solde XP",
  "Log in to see where you stand":
    "Connectez-vous pour voir où vous vous trouvez",
  "Connect your X account to join Pro League":
    "Connectez votre compte X pour rejoindre la Ligue Pro",
  "Avg. Fill Price": "Prix Moyen d'Exécution",
  "Spot": "Spot",
  "Derivatives": "Dérivés",
  "P&L Chart": "Graphique P&L",
  "Account Value Chart": "Graphique de Valeur de l'Compte",
  "Funding History": "Histoire de Financement",
  "Manage Funds": "Gérer les fonds",
  "Profile": "Profil",
  "Community XP": "XP Communautaire",
  "XP Share": "Part de XP",
  "Next XP Drop in": "Prochain XP Drop dans",
  "In Progress": "En cours",
  "{{field}} must be greater than {{value}}":
    "{{field}} doit être supérieur à {{value}}",
  "{{field}} must be less than {{value}}":
    "{{field}} doit être inférieur à {{value}}",
  "Enter a valid Take Profit or Stop Loss price":
    "Entrez un prix de Take Profit ou Stop Loss valide",
  "Take Profit and Stop Loss prices cannot be the same":
    "Les prix de Take Profit et Stop Loss ne peuvent pas être identiques",
  "Breakeven Price": "Prix d'équilibre",
  "Automatic Withdrawal": "Retrait Automatique",
  "Estimated Gas Fee": "Frais de Gas Estimés",
  "Auto Withdrawal": "Retrait Automatique",
  "Withdrawing...": "Retirant...",
  "Paradex Bridge withdrawals can take between 5-16 hours to complete":
    "Les retraits Paradex Bridge peuvent prendre entre 5 et 16 heures",
  "Socialized Loss": "Perte Sozialisée",
  "Receivable Amount": "Montant Recevable",
  "Auto withdrawing...": "Retirant automatiquement...",
  "Manage Twitter Connection": "Gérer la connexion Twitter",
  "Unlock additional functionality.":
    "Débloquez des fonctionnalités supplémentaires.",
  "Please note this connection can't be used as a login method to Paradex.":
    "Veuillez noter que cette connexion ne peut pas être utilisée comme méthode de connexion à Paradex.",
  "Toast Notifications": "Notifications Toast",
  "Display toast notifications": "Afficher les notifications Toast",
  "Average Entry Price": `Prix d'entrée moyen`,
  "Display average entry price line on chart": `Afficher la ligne de prix d'entrée moyen sur le graphique`,
  "Display liquidation price line on chart":
    "Afficher la ligne de prix de liquidation sur le graphique",
  "Display open order lines on chart": `Afficher les lignes d'ordres ouverts sur le graphique`,
  "Failed to disconnect Twitter": "Erreur lors de la déconnexion Twitter",
  "Failed to authorize Twitter": `Erreur lors de l'autorisation Twitter`,
  "Twitter account linked successfully!": "Compte Twitter lié avec succès !",
  "Failed to link twitter account":
    "Erreur lors de la liaison du compte Twitter",
  "Fees": "Frais",
  "Taker pays {{takerFee}} in fees. Maker receives {{makerRebate}} in rebates.":
    "Le taker paie {{takerFee}} en frais. Le maker reçoit {{makerRebate}} en rabais.",
  "Manage Discord Connection": "Gérer la connexion Discord",
  "Failed to authorize Discord": "Erreur lors de l'autorisation Discord",
  "Discord account linked successfully!": "Compte Discord lié avec succès !",
  "Failed to link Discord account":
    "Erreur lors de la liaison du compte Discord",
  "Failed to disconnect Discord": "Erreur lors de la déconnexion Discord",
  "Connect": "Connecter",
  "Unrealized P&L from current vault token balance. This doesn't account for owner profit share or slippage from positions unwind.":
    "P&L non réalisé à partir du solde actuel du token de la bourse. Cela ne prend pas en compte le profit du propriétaire ou le glissement des positions de dénouement.",
  "Show P&L Amount": "Afficher le P&L",
  "Receivable amount must be positive":
    "Le montant recevable doit être positif",
  "Wallet Address Change Detected":
    "Changement d'adresse de portefeuille détecté",
  "You can proceed with connecting your Paradex account to this wallet address or continue using your current connection.":
    "Vous pouvez continuer à connecter votre compte Paradex à cette adresse de portefeuille ou continuer à utiliser votre connexion actuelle.",
  "Connect To New Wallet": "Se connecter à un nouveau portefeuille",
  "Keep Using Current Wallet": "Continuer à utiliser le portefeuille actuel",
  "Connect Current Wallet": "Connecter le portefeuille actuel",
  "The account is not connected. Please make sure that <1>{{walletAddress}}</1> is connected and is the active account in your <2>{{walletName}}</2> wallet.":
    "Le compte n'est pas connecté. Veuillez vous assurer que <1>{{walletAddress}}</1> est connecté et est le compte actif dans votre portefeuille <2>{{walletName}}</2>.",
  "Reconnecting": "Reconnexion",
  "There is a request in progress. Please proceed in your wallet.":
    "Une demande est en cours. Veuillez continuer dans votre portefeuille.",
  "Modify Order": "Modifier l'ordre",
  "Realized P&L": "P&L Réalisé",
  "Close All": "Fermer Tout",
  "Close All Positions": "Fermer Toutes les Positions",
  "Market Close All Positions": "Marché Ferme Toutes les Positions",
  "Close {{percent}}% of All Positions":
    "Fermer {{percent}}% de toutes les positions",
  "Failed to close all positions":
    "Erreur lors de la fermeture de toutes les positions",
  "Number of Positions": "Nombre de Positions",
  "Markets": "Marchés",
  "Position Value (Closing)": "Valeur de la position (Fermeture)",
  "{{count}} positions will be rounded to the nearest order increment":
    "{{count}} positions seront arrondies à l'incrément d'ordre le plus proche",
  "You will earn Bonus XP on your referral activity":
    "Vous gagnerez des XP Bonus pour vos activités de parrainage",
  "Token Price": "Prix du Token",
  "Vault Token Price Chart": "Graphique Token Vault",
  "Market Close Orders Submitted": "Marché Ferme Toutes les Commandes",
  "This profile is already linked to a different Paradex account. Disconnect socials from existing account and try again.":
    "Ce profil est déjà lié à un autre compte Paradex. Déconnectez vos réseaux sociaux de votre compte actuel et réessayez.",
  "XP Drops every Friday": "XP se cae chaque vendredi",
  "No XP Data Available": "Aucune donnée XP disponible",
  "XP data is available only for the Main Account.":
    "Les données XP sont disponibles uniquement pour le compte principal.",
  "Please switch to your Main Account to view your XP.":
    "Veuillez passer à votre compte principal pour voir votre XP.",
  "Referrals Not Available": "Références non disponibles",
  "Referrals are available only for the Main Account.":
    "Les références sont disponibles uniquement pour le compte principal.",
  "Please switch to your Main Account to view your Referrals.":
    "Veuillez passer à votre compte principal pour voir vos références.",
  "Deposit initiated via Layerswap": "Dépôt initié via Layerswap",
  "Position History": "Historique des Positions",
  "Avg. Close Price": "Prix de Clôture Moyen",
  "Max Size": "Taille Maximale",
  "Closed Size": "Taille Fermée",
  "Opened": "Ouvert",
  "You have no positions": "Vous n'avez pas de positions",
  "Destination Address": "Adresse de destination",
  "Withdrawal via Layerswap initiated": "Retrait via Layerswap initié",
  "Withdrawal via Layerswap failed": "Retrait via Layerswap échoué",
  "Market Share": "Part de Marché",
  "Tier": "Niveau",
  "Avg Maker Volume": "Volume Maker Moyen",
  "Liquidity Score": "Score de Liquidité",
  "Reward Share": "Part de Récompense",
  "Enter amount": "Entrez la quantité",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount at <3 /> with an estimated profit of <4 />.":
    "Lorsque le prix du marché atteint <1 />, une commande <2 /> sera déclenchée pour clôturer le montant sélectionné à <3 /> avec un bénéfice estimé de <4 />.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount at <3 /> with an estimated loss of <4 />.":
    "Lorsque le prix du marché atteint <1 />, une commande <2 /> sera déclenchée pour clôturer le montant sélectionné à <3 /> avec une perte estimée de <4 />.",
  "Limit Price": "Prix Limite",
  "Delete": "Supprimer",
  "Use other bridges from the list above to withdraw funds much faster.":
    "Utilisez d'autres ponts de la liste ci-dessus pour retirer vos fonds beaucoup plus rapidement.",
  "Paradex Chain Monitor": "Moniteur de Chaîne Paradex",
  "OTM Amount": "Montant OTM",
  "Leverage": "Effet de Levier",
  "Strikes": "Strikes",
  "Strike": "Strike",
  "Change": "Variation",
  "Dist. to Spot": "Dist. au Spot",
  "Bid": "Achat",
  "Mark": "Mark",
  "Ask": "Vente",
  "IV": "VI",
  "Dist %": "Dist %",
  "Call": "Call",
  "Put": "Put",
  "Mark Price / IV": "Prix Mark / VI",
  "8h F-Funding": "Financement 8h F",
  "No Strikes Available": "Aucun Strike Disponible",
  "Attributes": "Attributs",
  "Why no Expiries?": "Pourquoi pas d'Expiration ?",
  "{{multiplier}}x Boost": "Boost {{multiplier}}x",
  "Like perpetual futures, perpetual options have no expiration, offering continuous exposure to the underlying asset with built-in downside protection. This gives users the flexibility to hold their position indefinitely and decide the optimal time to close it based on their strategy":
    "Comme les futures perpétuels, les options perpétuelles n'ont pas d'expiration, offrant une exposition continue à l'actif sous-jacent avec une protection intégrée contre la baisse. Cela donne aux utilisateurs la flexibilité de conserver leur position indéfiniment et de décider du moment optimal pour la fermer en fonction de leur stratégie",
  "Implied Volatility used to calculate the option's Mark Price":
    "Volatilité implicite utilisée pour calculer le Prix Mark de l'option",
  "Distance between the Strike Price and the underlying Spot Price":
    "Distance entre le Prix Strike et le Prix Spot sous-jacent",
  "Eight hour funding that is continuously accrued on positions and settled upon any trade. If the funding rate is positive, longs pay shorts. If negative, shorts pay longs":
    "Financement de huit heures qui s'accumule en continu sur les positions et est réglé lors de chaque transaction. Si le taux de financement est positif, les longs paient les courts. Si négatif, les courts paient les longs",
  "The Leverage Ratio (Lambda) measures an option's effective leverage by indicating how much the option's value changes, in percentage terms, for a 1% change in the spot price of the underlying asset. For example, if an option has a Lambda of 50 and the spot price increases by 1%, the option's value would increase by 50%":
    "Le Ratio d'Effet de Levier (Lambda) mesure l'effet de levier effectif d'une option en indiquant de combien la valeur de l'option change, en pourcentage, pour une variation de 1% du prix spot de l'actif sous-jacent. Par exemple, si une option a un Lambda de 50 et que le prix spot augmente de 1%, la valeur de l'option augmenterait de 50%",
  "Option delta measures how much the price of an option is expected to change for a $1 change in the price of the underlying asset. For example, a delta of 0.5 means the option price will move $0.50 for every $1 move in the underlying":
    "Le delta de l'option mesure de combien le prix d'une option devrait changer pour une variation de 1$ du prix de l'actif sous-jacent. Par exemple, un delta de 0,5 signifie que le prix de l'option variera de 0,50$ pour chaque variation de 1$ du sous-jacent",
  "Eight hour funding rate of the perpetual future on the same underlying asset":
    "Taux de financement de huit heures du future perpétuel sur le même actif sous-jacent",
  "Having trouble? Try signing out and connecting again.":
    "Des problèmes ? Essayez de vous déconnecter et de vous reconnecter.",
  "API Reference": "Référence API",
  "XP Distribution occurs once per week on Friday at ~12 PM UTC":
    "La distribution XP a lieu une fois par semaine le vendredi à ~12h UTC",
  "What are Perpetual Options?": "Que sont les Options Perpétuelles ?",
  "Delta represents the increase in option price based on a $1 increase in the underlying spot price.\n\nFor example, a delta of 0.50 means the price of the option is expected to increase by $0.50 for a $1 increase in the underlying spot price.":
    "Le Delta représente l'augmentation du prix de l'option basée sur une augmentation de 1$ du prix spot sous-jacent.\n\nPar exemple, un delta de 0,50 signifie que le prix de l'option devrait augmenter de 0,50$ pour une augmentation de 1$ du prix spot sous-jacent.",
  "Leverage(Lambda) represents the percentage change in option price for a 1% change in the underlying spot price.\n\nFor example, a lambda of 10 means the option price is expected to increase by 10% if the underlying spot price increases by 1%.":
    "L'Effet de Levier(Lambda) représente la variation en pourcentage du prix de l'option pour une variation de 1% du prix spot sous-jacent.\n\nPar exemple, un lambda de 10 signifie que le prix de l'option devrait augmenter de 10% si le prix spot sous-jacent augmente de 1%.",
  "Eight hour options funding rate that is continuously accrued on positions and settled upon any trade. The funding rate for perpetual options reflects both the cost of leverage and the cost of insurance against adverse price moves. \n\nThe funding period of perpetual options is 24 hours. This means that the option premium will be continuously paid out over a 24h period. 24h funding rate = 3 x 8h funding rate":
    "Taux de financement d'options de huit heures qui s'accumule en continu sur les positions et est réglé lors de chaque transaction. Le taux de financement pour les options perpétuelles reflète à la fois le coût de l'effet de levier et le coût de l'assurance contre les mouvements de prix défavorables. \n\nLa période de financement des options perpétuelles est de 24 heures. Cela signifie que la prime d'option sera payée en continu sur une période de 24h. Taux de financement 24h = 3 x taux de financement 8h",
  "Risk": "Risque",
  "Vega": "Vega",
  "Gamma": "Gamma",
  "Estimated Funding P&L for the next 8 hours based on current funding rates":
    "P&L de Financement Estimé pour les 8 prochaines heures basé sur les taux de financement actuels",
  "Vega represents the increase in option price based on a 1% increase in the implied volatility.\n\nFor example, a Vega of 5 means the price of the option is expected to increase by $5 for a 1% increase in the implied volatility.":
    "Le Vega représente l'augmentation du prix de l'option basée sur une augmentation de 1% de la volatilité implicite.\n\nPar exemple, un Vega de 5 signifie que le prix de l'option devrait augmenter de 5$ pour une augmentation de 1% de la volatilité implicite.",
  "Adjust Leverage": "Ajuster l'Effet de Levier",
  "Leverage updated successfully": "Effet de levier mis à jour avec succès",
  "Failed to update leverage": "Échec de la mise à jour de l'effet de levier",
  "Adjust leverage parameters for selected market. Leverage will also increase on your open positions.":
    "Ajustez les paramètres d'effet de levier pour le marché sélectionné. L'effet de levier augmentera également sur vos positions ouvertes.",
  "Please note that changing leverage will also apply for open positions and open orders on this market.":
    "Veuillez noter que le changement d'effet de levier s'appliquera également aux positions ouvertes et aux ordres ouverts sur ce marché.",
  "Selecting higher leverage could result in Deleveraging where some of your open orders would be cancelled.":
    "La sélection d'un effet de levier plus élevé pourrait entraîner un Désendettement où certains de vos ordres ouverts seraient annulés.",
  "disabled": "désactivé",
  "Margin Mode": "Mode Marge",
  "Manage your risk on individual positions by restricting the amount of margin allocated to each. If the margin ratio of an isolated position reaches 100%, the position will be liquidated. Margin can be added or removed to individual positions in this mode.":
    "Gérez votre risque sur les positions individuelles en limitant le montant de marge alloué à chacune. Si le ratio de marge d'une position isolée atteint 100%, la position sera liquidée. La marge peut être ajoutée ou retirée des positions individuelles dans ce mode.",
  "Margin mode updated successfully": "Mode marge mis à jour avec succès",
  "Failed to update margin mode": "Échec de la mise à jour du mode marge",
  "Favorites": "Favoris",
  "Trending": "Tendance",
  "Losers": "Perdants",
  "Gainers": "Gagnants",
  "New Listing": "Nouvelle cotation",
  "Developers": "Développeurs",
  "Developer Mode": "Mode Développeur",
  "Enable developer mode": "Activer le mode développeur",
  "For external use": "Pour usage externe",
  "Warning: Changing transfer behavior":
    "Attention: Modification du comportement de transfert",
  "USDC will be transferred to account contract instead of Paradex. Amount won't be visible or available for use within Paradex, but can be used with external applications.":
    "USDC sera transféré au contrat de compte au lieu de Paradex. Le montant ne sera pas visible ou disponible pour utilisation dans Paradex, mais peut être utilisé avec des applications externes.",
  "Affiliate Dashboard": "Tableau de bord des Affiliés",
};

export default translations;
