import { Keys } from "./en";

const translations: Record<Keys, string> = {
  "Display trade history on chart": "Grafikte işlem geçmişini görüntüle",
  "Add markets to Favorites in the Markets Browser":
    "Pazar arama tarayıcısında pazarları favorilere ekle",
  "Log in": "Giriş",
  "Export embedded wallet": "Gömülü cüzdanı dışa aktar",
  "Email or Social": "E-posta veya Sosyal",
  "Failed to load balances": "Bakiye yüklenemedi",
  "No balances": "Bakiye yok",
  "Verified Operator": "Doğrulanmış Operatör",
  "Strategy": "Strateji",
  "Multi-Strategy": "Çoklu-Strateji",
  "Master Vault": "Ana Kasa",
  "Master Vault Details": "Ana Kasa Detayları",
  "Annualized": "Yıllıklandırılmış",
  "Depositors": "Yatırımcılar",
  "No trade history": "İşlem Geçmişi Yok",
  "Unwinding Progress": "Tasfiye İlerlemesi",
  "What are Vaults?": "Kasalar Nedir?",
  "APR calculated as the Annualised 30-day Return":
    "APR, 30 günlük yıllık getiri olarak hesaplanır",
  "APR": "APR",
  "Initializing": "Başlatılıyor",
  "Awaiting Deposit": "Yatırım Bekleniyor",
  "Vault Age": "Kasa Yaşı",
  "30D Return": "30G Getiri",
  "{{field}} must be greater than the minimum initial deposit of {{minInitialDeposit}}":
    "{{field}} en az {{minInitialDeposit}} minimum başlangıç yatırımından büyük olmalıdır",
  "Minimum deposit": "Minimum yatırım",
  "Withdrawal amount exceeds available vault shares":
    "Çekim miktarı kasanın kullanılabilir paylarını aşıyor",
  "Deposit would exceed maximum vault TVL of {{maxTVL}}":
    "Yatırım miktarı kasanın maksimum TVL sınırını {{maxTVL}} aşacaktır",
  "{{field}} is required": "{{field}} alanı zorunludur",
  "Lockup Remaining": "Kalan Süre",
  "{{days}}D": "{{days}}G",
  "<1>{{days}}</1> D": "<1>{{days}}</1> G",
  "<1>{{hours}}</1> H": "<1>{{hours}}</1> S",
  "<1>{{minutes}}</1> M": "<1>{{minutes}}</1> D",
  "Vault settings submitted": "Kasa ayarları gönderildi",
  "Vault settings updated": "Kasa ayarları güncellendi",
  "Vault Settings": "Kasa Ayarları",
  "Vault not found": "Kasa bulunamadı",
  "The owner receives a {{profitShare}} profit share.":
    "Sahip {{profitShare}} oranında kâr payı alır.",
  "This vault is entirely owned by the community.":
    "Bu kasa tamamen topluluk tarafından sahip olunuyor.",
  "Withdraw from Vault submitted": "Kasadan Çekim gönderildi",
  "Deposit to Vault submitted": "Kasa yatırımı gönderildi",
  "Unknown error. Try again.": "Bilinmeyen hata. Tekrar deneyin.",
  "Vault Deposit": "Kasa Yatırımı",
  "Vault Withdrawal": "Kasadan Çekim",
  "No Lockup": "Bez bloklama",
  "Lockup period is not over yet":
    "Periode van slotperiode is nog niet afgelopen",
  "Protocol": "Protokol",
  "24H": "24S",
  "7D": "7G",
  "30D": "30G",
  "All": "Tümü",
  "You have no investments in vaults": "Kasalarınızda yapılan yatırımlar yok",
  "Total Value": "Toplam Değer",
  "Investments Made": "Yapılan Yatırımlar",
  "Operator": "Operatör",
  "Manage Vault": "Kasa Yönet",
  "Switch to the operator account": "Operatör hesabına geç",
  "Active": "Aktif",
  "My Vaults": "Kasalarım",
  "Closed": "Kapalı",
  "Deposited": "Yatırılmış",
  "Lifetime ROI": "ROI Sepanjang Masa",
  "Owners Equity": "Sahip Özsermayesi",
  "Top Trending": "En Popüler",
  "Owner Share": "Sahip Payı",
  "Vault Details": "Kasa Detayları",
  "Contract": "Sözleşme",
  "Withdraw from Vault": "Kasadan Çek",
  "Withdraw from Vault successful": "Kasadan çekim başarılı",
  "Failed to withdraw from Vault": "Kasadan çekim başarısız",
  "Name": "İsim",
  "Deposit": "Yatırma",
  "Tokens": "Tokenler",
  "Avg. Entry Price": "Ort. Giriş Fiyatı",
  "Current Price": "Mevcut Fiyat",
  "P&L": "Kâr ve Zarar",
  "My Vault Deposit": "Kasa Depozitim",
  "Vaults": "Kasalar",
  "Create": "Oluştur",
  "Create My Vault": "Kasamı Oluştur",
  "Vault Name": "Kasa Adı",
  "Vault Description": "Kasa Açıklaması",
  "Token Symbol": "Token Sembolü",
  "Deposit Amount": "Yatırma Miktarı",
  "Vault description will be visible publicly and you will not be able to edit this later.":
    "Kasa açıklaması herkese açık olarak görünecek ve daha sonra bunu düzenleyemeyeceksiniz.",
  "Once created, deposit at least {{minDeposit}} USDC to activate the vault. At this moment, only the Max TVL can be changed later.":
    "Bir kez oluşturulduğunda, kasanın etkinleştirilmesi için en az {{minDeposit}} USDC yatırmanız gerekir. Bu anda, yalnızca Maksimum TVL değeri daha sonra değiştirilebilir.",
  "Create Vault": "Kasa Oluştur",
  "Profit Share": "Kâr Payı",
  "Lockup Period (Days)": "Kilitleme Süresi (Gün)",
  "Vault was successfully created and is being initialized":
    "Kasa başarıyla oluşturuldu ve başlatılıyor",
  "Failed to create a vault": "Kasa oluşturma başarısız",
  "Maximum TVL": "Maksimum TVL",
  "No limit": "Limit yok",
  "Vault Address": "Kasa Adresi",
  "Deposit to Vault successful": "Kasaya yatırma başarılı",
  "Failed to deposit to Vault": "Kasaya yatırma başarısız",
  "Deposit to Vault": "Kasaya Yatır",
  "Vault": "Kasa",
  "Owner": "Sahip",
  "Age": "Yaş",
  "Failed to load vaults": "Kasaları yükleme başarısız",
  "No vaults available": "Kullanılabilir kasa yok",
  "Performance": "Performans",
  "Vault Value": "Kasa Değeri",
  "My Deposit": "Yatırdığım Miktar",
  "Total P&L": "Toplam Kâr ve Zarar",
  "Protocol Vaults": "Protokol Kasaları",
  "User Vaults": "Kullanıcı Kasaları",
  "No Data": "Veri Yok",
  "Last Month Return": "Geçen Ay Getirisi",
  "TVL": "TVL",
  "Total Earnings": "Toplam Kazanç",
  "About": "Hakkında",
  "Vault Stats": "Kasa İstatistikleri",
  "My Stats": "İstatistiklerim",
  "Vault Contract": "Kasa Sözleşmesi",
  "Description": "Açıklama",
  "Max Drawdown": "Maksimum Düşüş",
  "Volume": "Hacim",
  "All-Time P&L": "Tüm Zamanların Kâr ve Zararı",
  "Days": "Gün",
  "Lockup Period": "Kilitleme Süresi",
  "Search Vaults": "Kasaları Ara",
  "Blog": "Blog",
  "Block Explorer": "Blok Gezgini",
  "Close Vault": "Kasayı Kapat",
  "Close Vault successful": "Kasa kapatma başarılı",
  "You must close all open positions to close your Vault. Closed vaults cannot be reopened.":
    "Kasanızı kapatmak için tüm açık pozisyonları kapatmanız gerekir. Kapatılan kasalar yeniden açılamaz.",
  "Close My Vault": "Kasamı Kapat",
  "Failed to Close the Vault": "Kasa Kapatma Başarısız",
  "Only the Main Account can manage the vault":
    "Kasayı yalnızca Ana Hesap yönetebilir",
  "Allocate Collateral": "Teminat Ayır",
  "Switch to Main Account": "Ana Hesaba Geç",
  "Switch to Account": "Hesaba Geç",
  "You are currently trading in the {{market}} market.":
    "Şu anda {{market}} piyasasında işlem yapıyorsunuz.",
  "To start trading on Isolated Market you must Allocate Collateral from your Main Account and switch to this Market Account.":
    "İzole Piyasada işlem yapmaya başlamak için, Ana Hesabınızdan Teminat Ayırmalı ve bu Piyasa Hesabına geçmelisiniz.",
  "To trade in this market, switch to the Main Account first.":
    "Bu piyasada işlem yapmak için önce Ana Hesaba geçin.",
  "This account can only trade in the {{market}} market.":
    "Bu hesap yalnızca {{market}} piyasasında işlem yapabilir.",
  "Isolated Market": "İzole Piyasa",
  "This feature currently is still in Beta. Backup your Paradex Private Key for additional safety.":
    "Bu özellik şu anda hala Beta aşamasında. Ek güvenlik için Paradex Özel Anahtarınızı yedekleyin.",
  "Deposit failed": "Para Yatırma Başarısız",
  "Deposit in progress": "Para Yatırma Devam Ediyor",
  "External Account": "Harici Hesap",
  "Tx Hashes": "İşlem Özetleri",
  "Continue to {{bridge}}": "{{bridge}}'e Devam Et",
  "By clicking Continue to {{bridge}}, you will be sent to {{bridge}} website to bridge your funds out of Paradex.":
    "{{bridge}}'e Devam Et'e tıklayarak, varlıklarınızı Paradex'ten aktarmak için {{bridge}} sitesine yönlendirileceksiniz.",
  "By clicking Continue to {{bridge}}, you will be sent to {{bridge}} website to bridge your funds to Paradex.":
    "{{bridge}}'e Devam Et'e tıklayarak, varlıklarınızı Paradex'e aktarmak için {{bridge}} sitesine yönlendirileceksiniz.",
  "Refer": "Referans",
  "Address": "Adres",
  "Date Joined": "Katılma Tarihi",
  "Referral Code": "Referans Kodu",
  "Volume Traded": "İşlem Hacmi",
  "Bridge": "Köprü",
  "Withdraw to": "Para çekilecek yer",
  "Deposit from": "Yatırılacak kaynak",
  "Position is already closed": "Pozisyon zaten kapandı",
  "Your Paradex L2 Address is NOT a Public Starknet Address. If you try to send funds to this address on Public Starknet via a cross chain bridge you risk losing your funds. <1>Read more</1>.":
    "Paradex L2 Adresiniz Genel Starknet Adresi DEĞİLDİR. Bu adrese Public Starknet üzerinde bir köprü aracılığıyla fon göndermeye çalışırsanız, fonlarınızı kaybetme riskiyle karşı karşıya kalırsınız. <1>Daha fazla oku</1>.",
  "{{chain}} Address": "{{chain}} Adresi",
  "Copy Paradex Private Key": "Paradex Özel Anahtarını Kopyala",
  "Your account will be liquidated if Margin Ratio reaches 100%":
    "Marjin Oranı %100 olduğunda hesabınız tasfiye edilecektir",
  "Liquidation": "Likidasyon",
  "Fee": "Ücret",
  "Maker": "Yapıcı",
  "Maker Score": "Yapıcı Skoru",
  "Maker XP Share": "Yapıcı XP Paylaş",
  "Fee XP Share": "Ücret XP Paylaş",
  "Fee XP": "Ücret XP",
  "Maker XP": "Yapıcı XP",
  "Liquidation {{side}} Fill": "Likidasyon {{side}} Doldur",
  "Settings": "Ayarlar",
  "Market Score": "Pazar Skoru",
  "Season {{season}} (coming soon)": "Sezon {{season}} (çok yakında)",
  "Confirm Buy": "Satın Almayı Onayla",
  "Confirm Sell": "Satmayı Onayla",
  "Other XP": "Diğer XP",
  "Estimated Receivable Amount": "Tahmini Alınabilir Miktar",
  "Socialized Loss active. Click to learn more.":
    "Sosyal Kayıp aktif. Daha fazla bilgi için tıklayın.",
  "Analytics": "Analitik",
  "Sign In to see your account XP stats":
    "Hesabınızın XP istatistiklerini görmek için giriş yapın",
  "<1>Clock Is Out Of Sync</1> The clock on your device is out of sync. This may cause issues with the application. Please make sure your device clock is set to be auto. For more details please <2>see documentation</2>":
    "<1>Saat Senkronizasyonu Bozuk</1> Cihazınızdaki saat senkronizasyonu bozuk. Bu, uygulamayla ilgili sorunlara neden olabilir. Lütfen cihazınızın saatinin otomatik olarak ayarlandığından emin olun. Daha fazla ayrıntı için lütfen <2>dokümantasyonu</2> görün",
  "Failed to fetch max withdrawable amount":
    "Maksimum çekilebilir miktarı alma başarısız",
  "It is not possible to withdraw more than your Max Withdrawable Amount.":
    "Maksimum Çekilebilir Miktarınız'dan fazlasını çekemezsiniz.",
  "Max Withdrawable Amount": "Maksimum Çekilebilir Miktar",
  "XP Explainer": "XP Açıklayıcı",
  "Pool": "Seviye",
  "Quote Quality": "Teklif Kalitesi",
  "Create Your Public Username": "Genel Kullanıcı Adınızı Oluşturun",
  "Edit Your Public Username": "Genel Kullanıcı Adınızı Düzenleyin",
  "You have been logged out.": "Oturumunuz kapatıldı.",
  "Maker Volume Score": "Yapıcı Hacim Skoru",
  "Fee Score": "Komisyon Skoru",
  "Score Share": "Skor Oranı",
  "Instrument Share": "Enstrüman Oranı",
  "XP": "XP",
  "Total XP": "Toplam XP",
  "24h Change": "24 Saatlik Değişim",
  "Leaderboard": "Lider tablosu",
  "Cancel": "İptal",
  "Portfolio": "Portföy",
  "Trade": "Ticaret",
  "Build": "İnşa etmek",
  "Referrals Explainer": "Yönlendirme Açıklayıcı",
  "How It Works": "Nasıl Çalışır",
  "My Referrals": "Yönlendirmelerim",
  "Your Referral Code": "Yönlendirme Kodunuz",
  "Affiliate Program": "Bağlı Ortaklık Programı",
  "Enrolled": "Kayıtlı",
  "Traders Referred": "Yönlendirilen Tacirler",
  "Referral Rewards": "Yönlendirme Ödülleri",
  "Referral XP": "Yönlendirme XP",
  "Invite Friends": "Arkadaşları Davet Et",
  "Share": "Paylaş",
  "Earn": "Kazan",
  "Give": "Vermek",
  "Share your code to invite friends to join Paradex":
    "Arkadaşlarınızı Paradex'e katılmaya davet etmek için kodunuzu paylaşın",
  "<1>{{amount}}</1> Fees": "<1>{{amount}}</1> Ücretler",
  "You receive {{amount}} of the referred user's fees":
    "Yönlendirilen kullanıcının ücretlerinin %10'unu alırsınız",
  "<1>{{amount}}</1> XP": "<1>{{amount}}</1> XP",
  "You receive {{amount}} of the referred user's XP":
    "Yönlendirilen kullanıcının XP'lerinin %10'unu alırsınız",
  "<1>{{amount}}</1> Discount": "<1>{{amount}}</1> İndirim",
  "Your friend receives {{amount}} discount on fees":
    "Arkadaşınız ücretlerde %10 indirim alır",
  "Create and share your custom referral code":
    "Özel yönlendirme kodunuzu oluşturun ve paylaşın",
  "If you have a strong social media following (<1>{{followers}}+</1>), you may be eligible for our <2>VIP Affiliate Program</2> with higher commissions and XP.":
    "Eğer güçlü bir sosyal medya takipçisi (<1>{{followers}}+</1>) kitleniz varsa, daha yüksek komisyonlar ve XP sunan <2>VIP Ortaklık Programımız</2>a katılmaya uygun olabilirsiniz.",
  "You can track our <1>Affiliate Leaderboard</1> to see how you stack up.":
    "Durumunuzu görmek için genel <1>Ortaklık Sıralaması</1>mıza bakabilirsiniz.",
  "Apply": "Başvur",
  "Log in to see your Referral History.":
    "Yönlendirme Geçmişinizi görmek için giriş yapın",
  "Welcome to Paradex": "Paradex'e hoş geldiniz",
  "Congrats! The referral code you used is now active! It means you can start enjoying a discount on your trading fees!":
    "Tebrikler! Kullandığınız yönlendirme kodu artık aktif! Bu, işlem ücretlerinizde indirim yapmaya başlayabileceğiniz anlamına gelir!",
  "Congrats! The referral code <1>{{codeProvided}}</1> used is now active!":
    "Tebrikler! Kullanılan yönlendirme kodu <1>{{codeProvided}}</1> artık aktif!",
  "Referral code not activated. The code can only be applied once during the initial onboarding.":
    "Yönlendirme kodu etkinleştirilmedi. Kod, yalnızca başlangıçta bir kez uygulanabilir.",
  "Referral code <1>{{codeProvided}}</1> not activated. You have already onboarded with <1>{{referredBy}}</1>.":
    "Yönlendirme kodu <1>{{codeProvided}}</1> etkinleştirilmedi. Zaten <1>{{referredBy}}</1> tarafından yüklendiniz.",
  "Referral Code Applied": "Yönlendirme Kodu Uygulandı",
  "You will receive <1>{{userAmount}}</1> of referred user's <1>fees</1> <2>and</2> <1>{{userAmountPoints}} XP</1>. They will receive a <1>{{friendAmount}}</1> discount.":
    "Yönlendirilen kullanıcının ücretlerinin <1>{{userAmount}}</1>'ini alırsınız ve XP'lerinin <1>{{userAmountPoints}}</1>'ini alırsınız. Arkadaşınız ücretlerde <1>{{friendAmount}}</1> indirim alır.",
  "Community": "Topluluk",
  "Environments": "Ortam",
  "Language": "Dil",
  "System Status": "Sistem Durumu",
  "Docs": "Dokümanlar",
  "Code Samples": "Kod Örnekleri",
  "More": "Daha Fazla",
  "Stats": "İstatistikler",
  "Join Discord": "Discord'a Katıl",
  "Notifications": "Bildirimler",
  "All caught up!": "Hepsi bu kadar!",
  "You have no new notifications": "Yeni bildiriminiz yok",
  "Connect Wallet": "Cüzdanı Bağla",
  "WalletConnect is taking too long to open. Please refresh the page and try again.":
    "WalletConnect açılmak için çok uzun sürüyor. Lütfen sayfayı yenileyin ve tekrar deneyin.",
  "Unexpected error opening WalletConnect modal. Please refresh the page and try again.":
    "WalletConnect modalını açarken beklenmeyen bir hata oluştu. Lütfen sayfayı yenileyin ve tekrar deneyin.",
  "Connect your Wallet": "Cüzdanınızı Bağlayın",
  "Link Wallet": "Cüzdanı Bağla",
  "You will receive a signature request. Signing is free and will not send a transaction.":
    "Bir imza talebi alacaksınız. İmzalama ücretsizdir ve bir işlem göndermez.",
  "Generate Paradex Chain wallet": "Paradex Chain cüzdanı oluştur",
  "Generating Paradex Chain wallet": "Paradex Chain cüzdanı oluşturuluyor",
  "Paradex Chain wallet generated": "Paradex Chain cüzdanı oluşturuldu",
  "Verify that you own this wallet": "Bu cüzdana sahip olduğunuzu doğrulamak",
  "Remember Me": "Beni Hatırla",
  "Only use 'Remember Me' if you are using a secure device you own. Selecting this option can expose your information to  others if you are accessing this wallet from a public device.":
    "'Beni Hatırla' seçeneğini yalnızca kendinize ait güvenli bir cihaz kullanıyorsanız kullanın. Bu seçeneği seçmek, bu cüzdana bir halka açık cihazdan erişiyorsanız bilgilerinizi başkalarına açabilir.",
  "Continue": "Devam Et",
  "Disconnect Wallet": "Cüzdanı Bağlantıdan Kes",
  "Disconnect": "Bağlantıyı Kes",
  "Account": "Hesap",
  "Username": "Kullanıcı Adı",
  "Edit": "Düzenle",
  "Your username is now hidden. It is not visible to other users.":
    "Kullanıcı adınız şimdi gizli. Diğer kullanıcılara görünmez.",
  "Your username will be visible to other users unless you choose to hide it. You can always change it later.":
    "Kullanıcı adınız, onu gizlemeyi seçmediğiniz sürece diğer kullanıcılara görünür olacaktır. Daha sonra her zaman değiştirebilirsiniz.",
  "Hide my Username": "Kullanıcı Adımı Gizle",
  "Save": "Kaydet",
  "Username is required": "Kullanıcı adı gereklidir",
  "Username must be between 5 and 20 characters":
    "Kullanıcı adı 5 ile 20 karakter arasında olmalıdır",
  "Username can only contain letters and numbers":
    "Kullanıcı adı yalnızca harf ve rakam içerebilir",
  "Wallets": "Cüzdanlar",
  "Paradex Block Explorer": "Paradex Blok Gezgini",
  "Log out": "Çıkış Yap",
  "No markets available": "Kullanılabilir piyasa yok",
  "Last Price": "Son Fiyat",
  "Open Interest": "Açık İlgi",
  "24h Volume": "24 Saatlik Hacim",
  "Mark Price": "İşaret Fiyatı",
  "Spot Price": "Dayanak Varlık Fiyatı",
  "Eight hour funding rate that is continuously accrued on positions and settled upon any trade. If the funding rate is positive, longs pay shorts. If negative, shorts pay longs.":
    "Pozisyonlarda sürekli olarak biriken ve herhangi bir işlemde yerleştirilen sekiz saatlik fonlama oranı. Fonlama oranı pozitifse, uzunlar kısa öder. Negatifse, kısa uzun öder.",
  "8h Funding": "8 Saatlik Fonlama",
  "Limit": "Limit",
  "Market": "Piyasa",
  "Stop Limit": "Stop Limit",
  "Stop Market": "Stop Piyasa",
  "Scaled Order": "Ölçekli Emir",
  "Trigger Price": "Tetik Fiyat",
  "Input Price": "Giriş Fiyatı",
  "Position Mark Price": "Pozisyon İşaret Fiyatı",
  "Amount": "Miktar",
  "Reduce Only": "Sadece Azalt",
  "Reduce Only will increase position": "Reduce Only pozisyonu artıracaktır",
  "Your order stays open until it is filled or you decide to cancel.":
    "Siparişiniz, doldurulana veya iptal etmeye karar verene kadar açık kalır.",
  "Your order gets filled right away, partially or fully, and whatever is left gets canceled instantly.":
    "Siparişiniz hemen, kısmen veya tamamen doldurulur ve kalan her şey anında iptal edilir.",
  "Conditional limit order that serves to add liquidity to the order book as a Maker order.":
    "Maker siparişi olarak sipariş defterine likidite eklemek için kullanılan koşullu limit sipariş.",
  "Currently the fees are fixed and uniform across the platform. Maker {{makerFee}} / Taker {{takerFee}}":
    "Şu anda ücretler platform genelinde sabit ve uniform. Yapıcı {{makerFee}} / Alıcı {{takerFee}}",
  "Value": "Değer",
  "Buy": "Satın Al",
  "BUY": "SATIN AL",
  "LONG": "UZUN",
  "Sell": "Sat",
  "SELL": "SAT",
  "SHORT": "KISA",
  "Order Value": "Emir Değeri",
  "Current Position": "Mevcut Pozisyon",
  "Account Value": "Hesap Değeri",
  "The total value of your account at current mark prices":
    "Hesabınızın mevcut işaret fiyatlarındaki toplam değeri",
  "Unrealized P&L": "Gerçekleşmemiş K/Z",
  "Free Collateral": "Serbest Teminat",
  "Account Leverage": "Hesap Kaldıracı",
  "Margin": "Marj",
  "Margin Ratio": "Marjin Oranı",
  "Initial Margin": "Başlangıç Marjı",
  "Maintenance Margin": "Bakım Marjı",
  "Order Book": "Emir Defteri",
  "Trades": "İşlemler",
  "Price": "Fiyat",
  "Size": "Boyut",
  "Total": "Toplam",
  "Time": "Zaman",
  "Spread": "Yayılma",
  "Positions": "Pozisyonlar",
  "Open Orders": "Açık Emirler",
  "Trade History": "İşlem Geçmişi",
  "Order History": "Emir Geçmişi",
  "Funding Payments": "Fonlama Ödemeleri",
  "Side": "Taraf",
  "Entry Price": "Giriş Fiyatı",
  "Liquidation Price": "Likitasyon Fiyatı",
  "Breakeven Price": "Dengeli Fiyat",
  "Unrealized Funding": "Gerçekleşmemiş Fonlama",
  "Close Position": "Pozisyonu Kapat",
  "Market Close": "Piyasa Kapanışı",
  "Close Long": "Uzunu Kapat",
  "Close Short": "Kısayı Kapat",
  "Type": "Tür",
  "Filled": "Dolduruldu",
  "Triggered": "Tetiklendi",
  "Instruction": "Talimat",
  "Client Order Id": "Müşteri Emir Id",
  "Status": "Durum",
  "NEW": "YENİ",
  "OPEN": "AÇIK",
  "UNTRIGGERED": "Tetiklenmedi",
  "CLOSED": "KAPALI",
  "Pending": "Beklemede",
  "Complete": "Tamamlandı",
  "Completed": "Tamamlandı",
  "Failed": "Başarısız",
  "Bust": "İflas",
  "Value / Fee": "Değer / Ücret",
  "Liquidity": "Likidite",
  "Tx Hash": "Tx Hash",
  "Trade Id": "İşlem Kimliği",
  "Remaining": "Kalan",
  "Cancel Reason": "İptal Sebebi",
  "User Canceled": "Kullanıcı İptal Etti",
  "Not Enough Margin": "Yeterli Marj Yok",
  "Empty Market": "Boş Piyasa",
  "Post Only": "Sadece Gönder",
  "Post Only Would Cross": "Sadece Gönder çapraz olurdu",
  "Remaining IOC Cancel": "Kalan IOC İptal",
  "Unexpected Failure": "Beklenmeyen Hata",
  "Deleverage": "Kaldıracı Azalt",
  "In Liquidation": "Likidasyonda",
  "Self Trade": "Kendi İşlemi",
  "Asset Unavailable": "Varlık Kullanılamaz",
  "Asset Expired": "Varlık Süresi Doldu",
  "Order Type Invalid": "Emir Türü Geçersiz",
  "Price Not Available": "Fiyat Kullanılamaz",
  "Expired": "Süresi Doldu",
  "Exceeds Max Slippage": "Maksimum Hızlık Aşımı",
  "Timeout": "Zaman Aşımı",
  "Order Exceeds Position Limit": "Emir, Pozisyon Limitini Aşıyor",
  "Order Id": "Emir Id",
  "Payment": "Ödeme",
  "Cancel All": "Tümünü İptal Et",
  "{{orderSize}} will be closed at Market price":
    "{{orderSize}} piyasa fiyatından kapatılacak",
  "By connecting a wallet, you agree to <1>Paradex Terms of Service</1> and represent and warrant to Paradex that you are not a <2>Restricted Person</2>.":
    "Bir cüzdan bağlayarak, <1>Paradex Hizmet Şartları</1>na katılıyorsunuz ve Paradex'e karşı bir <2>Kısıtlı Kişi</2> olmadığınızı beyan ediyor ve garanti ediyorsunuz.",
  "Wallet": "Cüzdan",
  "Deposit_verb": "Yatır",
  "Deposit_noun": "Yatırım",
  "Withdraw": "Çek",
  "Withdrawal": "Çekme",
  "Transfers": "Transferler",
  "Transfer": "Transfer",
  "Collateral": "Teminat",
  "Asset": "Varlık",
  "Wallet Balance": "Cüzdan Bakiyesi",
  "Actions": "Eylemler",
  "Action": "Eylem",
  "External Tx Hash": "Harici Tx Hash",
  "Withdraw to Wallet": "Cüzdana Çek",
  "Deposit to Paradex": "Paradex'a Yatır",
  "You have no balance in your Ethereum wallet. Deposit some {{asset}} and try again.":
    "Ethereum cüzdanınızda bakiye yok. Biraz {{asset}} yatırın ve tekrar deneyin.",
  "It is not possible to deposit more than your current balance.":
    "Mevcut bakiyenizden daha fazla yatırma yapmanız mümkün değil.",
  "Available": "Kullanılabilir",
  "Initiate a deposit from your Ethereum wallet to the Paradex bridge. These transactions":
    "Ethereum cüzdanınızdan Paradex köprüsüne bir depozito başlatın. Bu işlemler",
  "Initiate a deposit from your Ethereum wallet to the Paradex bridge. These transactions may take several minutes depending on network conditions. For more information check out our <1>docs</1>.":
    "Ethereum cüzdanınızdan Paradex köprüsüne bir depozito başlatın. Bu işlemler, ağ koşullarına bağlı olarak birkaç dakika sürebilir. Daha fazla bilgi için <1>dokümanlarımızı</1> kontrol edin.",
  "read more": "daha fazla oku",
  "Initiate Deposit to Bridge": "Köprüye Para Yatırmayı Başlat",
  "Enable USDC on Paradex": "Paradex Üzerinde USDC'yi Etkinleştir",
  "In order to deposit you must allow Paradex access to at least {{amount}}.":
    "Para yatırmak için Paradex'in en az {{amount}} erişimine izin vermelisiniz.",
  "Enable USDC": "USDC'yi Etkinleştir",
  "Withdraw initiated": "Para çekme işlemi başlatıldı",
  "Withdrawals are a two-step process. First initiate a withdrawal from Paradex":
    "Para çekme işlemi iki aşamalıdır. Öncelikle Paradex'dan para çekmeyi başlatın",
  "Withdrawals are a two-step process. First initiate a withdrawal from Paradex to the Paradex Bridge. This first step can take 5-16 hours (10 on average). Second, initiate your withdrawal from the Paradex Bridge to your Ethereum wallet via the Deposits / Withdrawals table. For more information check out our <1>docs</1>.":
    "Para çekme işlemi iki aşamalıdır. Öncelikle Paradex'dan Paradex köprüsüne para çekmeyi başlatın. Bu ilk adım 5-16 saat sürebilir (Ortalama 10). İkinci olarak, Paradex köprüsünden Ethereum cüzdanınıza para çekmeyi Yatırımlar / Çekimler tablosu üzerinden başlatın. Daha fazla bilgi için <1>dokümanlarımızı</1> kontrol edin.",
  "Initiate Withdrawal to Bridge": "Köprüye Para Çekmeyi Başlat",
  "You have no funds to withdraw. It is not possible to withdraw more than your free collateral.":
    "Çekecek paranız yok. Serbest teminatınızdan daha fazlasını çekmek mümkün değil.",
  "It is not possible to withdraw more than your free collateral.":
    "Serbest teminatınızdan daha fazlasını çekmek mümkün değil.",
  "You have no collateral": "Teminatınız yok",
  "You have no open positions": "Açık pozisyonunuz yok",
  "You have no open orders": "Açık emriniz yok",
  "You have no trades": "İşleminiz yok",
  "You have no orders": "Emriniz yok",
  "You have no funding payments": "Fonlama ödemeniz yok",
  "You have no transfers": "Transferiniz yok",
  "Account in liquidation": "Hesap tasfiye ediliyor",
  "Account is being liquidated. Any open positions will be reduced. Trading now is disabled.":
    "Hesap tasfiye ediliyor. Herhangi bir açık pozisyon azaltılacak. Şu anda işlem yapma devre dışı.",
  "Account liquidated": "Hesap tasfiye edildi",
  "Your account was liquidated on {{createdAt}}. Any open positions were reduced.":
    "Hesabınız {{createdAt}} tarihinde tasfiye edildi. Herhangi bir açık pozisyon azaltıldı.",
  "Deposit to Paradex successful": "Paradex'a para yatırma başarılı",
  "Deposit to Paradex failed": "Paradex'a para yatırma başarısız",
  "Unexpected error. Try again.": "Beklenmeyen hata. Tekrar deneyin.",
  "Deposit initiated": "Para yatırma başlatıldı",
  "Deposit to bridge initiated": "Köprüye para yatırma başlatıldı",
  "Deposit to Paradex available": "Paradex'a para yatırma mevcut",
  "Go to Deposits": "Yatırımlara Git",
  "Deposit to Paradex in progress": "Paradex'a para yatırma devam ediyor",
  "Transfer successful": "Transfer başarılı",
  "Limit {{side}} Order Fill": "Limit {{side}} Sipariş Doldur",
  "{{type}} {{side}} Order Submitted": "{{type}} {{side}} Sipariş Gönderildi",
  "{{type}} {{side}} Order Canceled": "{{type}} {{side}} Sipariş İptal Edildi",
  "Reason": "Sebep",
  "Market {{side}} Order Fill": "Piyasa {{side}} Sipariş Doldur",
  "Trade Busted On-Chain": "Zincir Üzerinde İşlem Bozuldu",
  "Fill id": "Doldur id",
  "Withdraw to wallet failed": "Cüzdana para çekme başarısız oldu",
  "Withdrawal to bridge initiated": "Köprüye para çekme başlatıldı",
  "Withdrawal error. Try again.": "Para çekme hatası. Tekrar deneyin.",
  "Withdrawal to bridge in progress": "Köprüye para çekme devam ediyor",
  "Withdrawal to wallet initiated": "Cüzdana para çekme başlatıldı",
  "Withdrawal to bridge failed": "Köprüye para çekme başarısız oldu",
  "Transaction error. Try again.": "İşlem hatası. Tekrar deneyin.",
  "Withdrawal to wallet available": "Cüzdana para çekme mevcut",
  "Go to Withdrawals": "Çekimlere Git",
  "Withdrawal to wallet successful": "Cüzdana para çekme başarılı",
  "Session expired, please log in again.":
    "Oturum süresi doldu, lütfen tekrar giriş yapın.",
  "Failed to submit order": "Sipariş gönderilemedi",
  "Your username preferences were updated!":
    "Kullanıcı adı tercihleriniz güncellendi!",
  "Unexpected error updating username preferences":
    "Kullanıcı adı tercihlerini güncellerken beklenmeyen hata",
  "This username already exists. Please choose a different username and try again.":
    "Bu kullanıcı adı zaten var. Lütfen farklı bir kullanıcı adı seçin ve tekrar deneyin.",
  "Validation Error": "Doğrulama Hatası",
  "Binding Error": "Bağlama Hatası",
  "Internal Error": "Dahili Hata",
  "Not Found": "Bulunamadı",
  "Service Unavailable": "Hizmet Kullanılamıyor",
  "Invalid Request Parameter": "Geçersiz İstek Parametresi",
  "Order Id Not Found": "Emir Id Bulunamadı",
  "Order Is Closed": "Emir Kapalı",
  "Order Is Not Open Yet": "Emir Henüz Açık Değil",
  "Client Order Id Not Found": "Müşteri Emir Id Bulunamadı",
  "Duplicated Client Id": "Çoğaltılmış Müşteri Id",
  "Invalid Price Precision": "Geçersiz Fiyat Hassasiyeti",
  "Invalid Token": "Geçersiz Token",
  "Invalid Ethereum Address": "Geçersiz Ethereum Adresi",
  "Blocked Ethereum Address": "Engellenmiş Ethereum Adresi",
  "Invalid Ethereum Signature": "Geçersiz Ethereum İmzası",
  "Invalid Starknet Address": "Geçersiz Starknet Adresi",
  "Invalid Starknet Signature": "Geçersiz Starknet İmzası",
  "Starknet Signature Verification Failed":
    "Starknet İmza Doğrulaması Başarısız",
  "Bad Starknet Request": "Kötü Starknet İsteği",
  "Ethereum Signer Mismatch": "Ethereum İmzacı Uyuşmazlığı",
  "Ethereum Hash Mismatch": "Ethereum Hash Uyuşmazlığı",
  "Not Onboarded": "Yükleme Yapılmadı",
  "Invalid Timestamp": "Geçersiz Zaman Damgası",
  "Invalid Signature Expiration": "Geçersiz İmza Süresi Dolumu",
  "Account Not Found": "Hesap Bulunamadı",
  "Invalid Order Signature": "Geçersiz Emir İmzası",
  "Public Key Invalid": "Geçersiz Genel Anahtar",
  "Unauthorized Ethereum Address": "Yetkisiz Ethereum Adresi",
  "Ethereum Address Already Onboarded": "Ethereum Adresi Zaten Yüklendi",
  "Market Not Found": "Piyasa Bulunamadı",
  "Allowlist Entry Not Found": "İzin Verilenler Listesi Girişi Bulunamadı",
  "Username In Use": "Kullanıcı Adı Kullanımda",
  "Service access restricted in your region":
    "Hizmet erişiminiz bölgenizde kısıtlandı",
  "No Access Allowed": "Erişime İzin Verilmiyor",
  "You do not have access to the platform. Please join our Discord to get access.":
    "Platforma erişiminiz yok. Erişim sağlamak için lütfen Discord sunucumuza katılın.",
  "{{actualChainName}} Wallet Connected": "Ethereum Cüzdanı Bağlandı",
  'Incorrect network selected "{{actualChainName}}". Please change the network to "{{requiredChainName}}" (id={{requiredChainId}}) in your wallet and retry.':
    'Yanlış ağ seçildi "{{actualChainName}}". Lütfen cüzdanınızdaki ağı "{{requiredChainName}}" (id={{requiredChainId}}) ile değiştirin ve tekrar deneyin.',
  "Choose Wallet": "Cüzdan Seç",
  "Starknet Wallet": "Starknet Cüzdanı",
  "Switch to {{env}}": "{{env}}'ye geç",
  "Search": "Arama",
  "League": "Lig",
  "Switch Account": "Hesap Değiştir",
  "Rank": "Sıra",
  "24h XP": "24 Saatlik XP",
  "XP Per Day": "Günlük XP",
  "Season Volume": "Sezon Hacmi",
  "Lifetime Volume": "Ömür Boyu Hacim",
  "Pre-Season": "Ön Sezon",
  "Earn More XP": "Daha Fazla XP Kazan",
  "XP Boost": "XP Artışı",
  "XP Drop": "XP Düşüşü",
  "Trader": "Tacir",
  "Total Share": "Toplam Pay",
  "Maker Share": "Yapıcı Payı",
  "Maker Volume Share": "Yaratıcı Hacim Payı",
  "Fee Share": "Ücret Payı",
  "Fee Volume Share": "Ücret Hacim Payı",
  "No data available": "Veri yok",
  "Refer & Earn": "Yönlendir ve Kazan",
  "Hours": "Saat",
  "Minutes": "Dakika",
  "Time/Duration": "Zaman/Süre",
  "Runtime": "Çalışma süresi",
  "Frequency": "Sıklık",
  "Number of Orders": "Sipariş Sayısı",
  "Runtime should not be empty": "Çalışma süresi boş olmamalıdır",
  "Runtime should be less than or equal to 24 hours":
    "Çalışma süresi 24 saatten az veya eşit olmalıdır",
  "Inactive": "Etkin değil",
  "Avg. Price": "Ortalama Fiyat",
  "The minimum required amount for {{numOrders}} orders is {{totalSize}} {{currency}}.":
    "{{numOrders}} sipariş için gereken minimum tutar {{totalSize}} {{currency}}'dir.",
  "Take Profit / Stop Loss: Set take profit and stop loss price levels to automate trade actions. When the Mark Price will reach the price you set, it will send your order to the matching engine.":
    "Kar Al / Zarar Durdur: İşlemleri otomatikleştirmek için kar al ve zarar durdur fiyat seviyelerini belirleyin. Belirlediğiniz fiyata ulaştığında, emriniz eşleştirme motoruna gönderilecektir.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount with an estimated profit of <3 />.":
    "Piyasa Fiyatı <1 />'e ulaştığında, seçilen miktarı tahmini kar <3 /> ile kapatmak için <2 /> emri tetiklenecektir.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount with an estimated loss of <3 />.":
    "Piyasa Fiyatı <1 />'e ulaştığında, seçilen miktarı tahmini zarar <3 /> ile kapatmak için <2 /> emri tetiklenecektir.",
  "Edit TP/SL For This Position": "Bu Pozisyon İçin TP/SL Düzenle",
  "Take Profit": "Kar Al",
  "Profit": "Kar",
  "Stop Loss": "Zarar Durdur",
  "Loss": "Zarar",
  "Confirm": "Onayla",
  "Take Profit Limit": "Kar Al Limit",
  "Take Profit Market": "Kar Al Piyasa",
  "Stop Loss Limit": "Zarar Durdur Limit",
  "Stop Loss Market": "Zarar Durdur Piyasa",
  "Funding": "Fonlama",
  "Unwinding": "Pozisyon Kapatma",
  "Cancel TP/SL orders": "TP/SL emirlerini iptal et",
  "TP/SL orders submitted successfully.":
    "TP/SL emirleri başarıyla gönderildi.",
  "Pre-Season + All Seasons": "Ön Sezon + Tüm Sezonlar",
  "Season {{season}}": "Sezon {{season}}",
  "XP Season {{season}}": "XP Sezon {{season}}",
  "Slippage": "Kayma",
  "Max Slippage": "Maksimum Kayma",
  "Max slippage defines the largest acceptable price deviation from the mark price.":
    "Maksimum kayma, işaret fiyatından kabul edilebilir en büyük fiyat sapmasını tanımlar.",
  "During execution, the order can be canceled if the price exceeds the slippage limit or falls outside the Market <1>Price Band</1>.":
    "İşlem sırasında, fiyat kayma sınırını aşarsa veya Market <1>Fiyat Bandı</1> dışına düşerse sipariş iptal edilebilir.",
  "Update Max Slippage": "Maksimum kaymayı güncelle",
  "The Maximum Slippage allowed for {{symbol}} is {{price_band_width}}.":
    "{{symbol}} için izin verilen maksimum kayma {{price_band_width}}.",
  "Mark IV": "Mark IV",
  "Delta": "Delta",
  "Failed to load positions": "Pozisyonlar yüklenemedi",
  "No open positions": "Açık pozisyon yok",
  "learn more": "daha fazla bilgi",
  "Learn More": "Daha Fazla Bilgi",
  "Search Markets": "Piyasaları Ara",
  "Futures": "Vadeli İşlemler",
  "Options": "Opsiyonlar",
  "OI": "Açık Faiz",
  "My Profile": "Hesap Ayarları",
  "Addresses": "Adresler",
  "Chart": "Grafik",
  "ROI": "ROI",
  "Sharpe": "Sharpe",
  "Max Loss": "Maksimum Kayıp",
  "Total Volume": "Toplam Hacim",
  "My NFTs": "NFTlerim",
  "My XP": "XP",
  "Balances": "Bakiyeler",
  "Other": "Diğer",
  "Paradex XP: Pre-Season": "Paradex XP: Ön Sezon",
  "Paradex XP: Season {{season}}": "Paradex XP: Sezon {{season}}",
  "Season {{season}} XP": "Sezon {{season}} XP",
  "Refer your friends to earn 10%":
    "Arkadaşlarınızı refer etmek ve %10 kazanmak",
  "Refer Friends and Earn 10%": "Arkadaşlarınızı refer etmek ve %10 kazanmak",
  "Invest in Paradex Vault & Earn 3X Boost":
    "Paradex Vault'e yatırım yapmak ve 3X Artış kazanmak",
  "Avg. Daily XP": "Ortalama Günlük XP",
  "Lifetime XP": "Ömür Boyu XP",
  "My Rank": "Benim Sıralama",
  "What is XP?": "XP nedir?",
  "XP History": "XP Geçmişi",
  "Last Week XP": "Geçen Hafta XP",
  "TVL XP": "TVL XP",
  "OI XP": "OI XP",
  "Liquidation XP": "Liquidation XP",
  "Vaults XP": "Vaults XP",
  "Week": "Hafta",
  "Season": "Sezon",
  "Last updated: {{timestamp}}": "Son güncelleme: {{timestamp}}",
  "Xperience Points (XP)": "Deneyim Puanları (XP)",
  "Log in to see your XP balance": "Giriş yapın, XP bakiyenizi görüntüleyin",
  "Log in to see where you stand":
    "Giriş yapın, nerede olduğunuzu görüntüleyin",
  "Connect your X account to join Pro League":
    "X hesabınızı bağlayarak Pro Lig'e katılın",
  "Avg. Fill Price": "Ortalama İşlem Fiyatı",
  "Spot": "Spot",
  "Derivatives": "Türevler",
  "P&L Chart": "P&L Grafiği",
  "Account Value Chart": "Hesap Değeri Grafiği",
  "Funding History": "Fonlama Geçmişi",
  "Manage Funds": "Fonları Yönet",
  "Profile": "Profil",
  "Community XP": "Topluluk XP",
  "XP Share": "XP Payı",
  "Next XP Drop in": "Sonraki XP Drop in",
  "In Progress": "Devam Ediyor",
  "{{field}} must be greater than {{value}}":
    "{{field}} {{value}} den büyük olmalıdır",
  "{{field}} must be less than {{value}}":
    "{{field}} {{value}} den küçük olmalıdır",
  "Enter a valid Take Profit or Stop Loss price":
    "Geçerli bir Kar Al veya Zarar Durdur fiyatı girin",
  "Take Profit and Stop Loss prices cannot be the same":
    "Kar Al ve Zarar Durdur fiyatları aynı olamaz",
  "Automatic Withdrawal": "Otomatik Çekim",
  "Estimated Gas Fee": "Tahmini Gaz Ücreti",
  "Auto Withdrawal": "Otomatik Çekim",
  "Withdrawing...": "Çekim işlemi devam ediyor...",
  "Paradex Bridge withdrawals can take between 5-16 hours to complete":
    "Paradex Bridge çekimleri 5-16 saat arası sürebilir",
  "Socialized Loss": "Sosyal Kayıp",
  "Receivable Amount": "Alınabilir Tutar",
  "Auto withdrawing...": "Otomatik çekim işlemi devam ediyor...",
  "Manage Twitter Connection": "Twitter bağlantısını yönet",
  "Unlock additional functionality.": "Ek işlevselliği açın.",
  "Please note this connection can't be used as a login method to Paradex.":
    "Bu bağlantının Paradex için bir giriş yöntemi olarak kullanılamayacağını unutmayın.",
  "Toast Notifications": "Toast bildirimleri",
  "Display toast notifications": "Toast bildirimlerini görüntüle",
  "Average Entry Price": "Ortalama Giriş Fiyatı",
  "Display average entry price line on chart":
    "Ortalama giriş fiyatı çizgisi grafikte görüntüle",
  "Display liquidation price line on chart":
    "Likidasyon fiyatı çizgisi grafikte görüntüle",
  "Display open order lines on chart":
    "Açık emir çizgilerini grafikte görüntüle",
  "Failed to disconnect Twitter": "Twitter bağlantısı kesildi",
  "Failed to authorize Twitter": "Twitter yetkilendirme hatası",
  "Twitter account linked successfully!": "Twitter hesabı başarıyla bağlandı!",
  "Failed to link twitter account": "Twitter hesabı bağlanamadı",
  "Fees": "Ücretler",
  "Taker pays {{takerFee}} in fees. Maker receives {{makerRebate}} in rebates.":
    "Taker {{takerFee}} ücret öder. Maker {{makerRebate}} indirim alır.",
  "Manage Discord Connection": "Discord bağlantısını yönet",
  "Failed to authorize Discord": "Discord yetkilendirme hatası",
  "Discord account linked successfully!": "Discord hesabı başarıyla bağlandı!",
  "Failed to link Discord account": "Discord hesabı bağlanamadı",
  "Failed to disconnect Discord": "Discord bağlantısı kesildi",
  "Connect": "Bağlan",
  "Unrealized P&L from current vault token balance. This doesn't account for owner profit share or slippage from positions unwind.":
    "Geçerli vault token bakiyesinden oluşan gerçekleşmemiş P&L. Bu, sahibin kazancı payını veya pozisyon unwind'den kaynaklanan slippage'ı hesaba katmaz.",
  "Show P&L Amount": "P&L Miktarını Görüntüle",
  "Receivable amount must be positive": "Alınabilir miktar pozitif olmalıdır",
  "Wallet Address Change Detected": "Cüzdan Adresi Değişikliği Tespit Edildi",
  "You can proceed with connecting your Paradex account to this wallet address or continue using your current connection.":
    "Paradex hesabınızı bu cüzdan adresine bağlamaya devam edebilir veya mevcut bağlantınızı kullanmaya devam edebilirsiniz.",
  "Connect To New Wallet": "Yeni Cüzdana Bağlan",
  "Keep Using Current Wallet": "Mevcut Cüzdanı Kullan",
  "Connect Current Wallet": "Mevcut Cüzdanı Bağla",
  "The account is not connected. Please make sure that <1>{{walletAddress}}</1> is connected and is the active account in your <2>{{walletName}}</2> wallet.":
    "Hesap bağlı değil. Lütfen <1>{{walletAddress}}</1> adresinin bağlı olduğundan ve <2>{{walletName}}</2> cüzdanınızda aktif hesap olduğundan emin olun.",
  "Reconnecting": "Yeniden Bağlanıyor",
  "There is a request in progress. Please proceed in your wallet.":
    "Devam eden bir talep var. Lütfen cüzdanınızda devam edin.",
  "Modify Order": "Emir Değiştir",
  "Realized P&L": "Gerçekleşen P&L",
  "Close All": "Tümünü Kapat",
  "Close All Positions": "Tüm Pozisyonları Kapat",
  "Market Close All Positions": "Piyasa Tüm Pozisyonları Kapat",
  "Close {{percent}}% of All Positions": "Tümünü {{percent}}% Kapat",
  "Failed to close all positions": "Tüm pozisyonlar kapatılamadı",
  "Number of Positions": "Pozisyon Sayısı",
  "Markets": "Piyasalar",
  "Position Value (Closing)": "Pozisyon Değeri (Kapanış)",
  "{{count}} positions will be rounded to the nearest order increment":
    "{{count}} pozisyon en yakın sipariş artışına yuvarlanacaktır",
  "You will earn Bonus XP on your referral activity":
    "Referans aktivitelerinizde Bonus XP kazanacaksınız",
  "Token Price": "Token Fiyatı",
  "Vault Token Price Chart": "Vault Token Fiyatı Grafiği",
  "Market Close Orders Submitted": "Piyasa Tüm Emirleri Kapat",
  "This profile is already linked to a different Paradex account. Disconnect socials from existing account and try again.":
    "Bu profil zaten farklı bir Paradex hesabına bağlanmış. Sosyal ağları mevcut hesabınızdan ayırın ve tekrar deneyin.",
  "XP Drops every Friday": "XP her Cuma günü düşer",
  "No XP Data Available": "XP verileri mevcut değil",
  "XP data is available only for the Main Account.":
    "XP verileri sadece ana hesapta mevcuttur.",
  "Please switch to your Main Account to view your XP.":
    "Ana hesaba geçerek XP'nizi görüntüleyebilirsiniz.",
  "Referrals Not Available": "Referanslar mevcut değil",
  "Referrals are available only for the Main Account.":
    "Referanslar sadece ana hesapta mevcuttur.",
  "Please switch to your Main Account to view your Referrals.":
    "Ana hesaba geçerek referanslarınızı görüntüleyebilirsiniz.",
  "Deposit initiated via Layerswap": "Layerswap üzerinden yatırım başlatıldı",
  "Position History": "Pozisyon Geçmişi",
  "Avg. Close Price": "Ortalama Kapanış Fiyatı",
  "Max Size": "Maksimum Boyut",
  "Closed Size": "Kapanan Boyut",
  "Opened": "Açık",
  "You have no positions": "Pozisyonunuz yok",
  "Destination Address": "Hedef Adres",
  "Withdrawal via Layerswap initiated": "Layerswap üzerinden çekim başlatıldı",
  "Withdrawal via Layerswap failed": "Layerswap üzerinden çekim başlatılamadı",
  "Market Share": "Piyasa Payı",
  "Tier": "Tier",
  "Avg Maker Volume": "Ortalama Maker Hacim",
  "Liquidity Score": "Likidite Skoru",
  "Reward Share": "Ödül Payı",
  "Enter amount": "Miktar girin",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount at <3 /> with an estimated profit of <4 />.":
    "Piyasa Fiyatı <1 /> seviyesine ulaştığında, seçilen miktarı <3 />'te kapatmak için <2 /> emri tetiklenir ve tahmini kar <4 /> olur.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount at <3 /> with an estimated loss of <4 />.":
    "Piyasa Fiyatı <1 /> seviyesine ulaştığında, seçilen miktarı <3 />'te kapatmak için <2 /> emri tetiklenir ve tahmini zarar <4 /> olur.",
  "Limit Price": "Limit Fiyatı",
  "Delete": "Sil",
  "Use other bridges from the list above to withdraw funds much faster.":
    "Fonlarınızı çok daha hızlı çekmek için yukarıdaki listeden diğer köprüleri kullanın.",
  "Paradex Chain Monitor": "Paradex Zincir Monitörü",
  "OTM Amount": "OTM Miktarı",
  "Leverage": "Kaldıraç",
  "Strikes": "Kullanım Fiyatları",
  "Strike": "Kullanım Fiyatı",
  "Change": "Değişim",
  "Dist. to Spot": "Spot'a Uzaklık",
  "Bid": "Alış",
  "Mark": "Mark",
  "Ask": "Satış",
  "IV": "IV",
  "Dist %": "Uzaklık %",
  "Call": "Call",
  "Put": "Put",
  "Mark Price / IV": "Mark Fiyatı / IV",
  "8h F-Funding": "8s F-Fonlama",
  "No Strikes Available": "Kullanılabilir Kullanım Fiyatı Yok",
  "Attributes": "Özellikler",
  "Why no Expiries?": "Neden Vade Yok?",
  "{{multiplier}}x Boost": "{{multiplier}}x Artış",
  "Like perpetual futures, perpetual options have no expiration, offering continuous exposure to the underlying asset with built-in downside protection. This gives users the flexibility to hold their position indefinitely and decide the optimal time to close it based on their strategy":
    "Sürekli vadeli futures gibi, sürekli vadeli opsiyonların da vadesi yoktur ve yerleşik düşüş koruması ile dayanak varlığa sürekli maruz kalma imkanı sunar. Bu, kullanıcılara pozisyonlarını süresiz olarak tutma ve stratejilerine göre en uygun kapanış zamanını belirleme esnekliği sağlar",
  "Implied Volatility used to calculate the option's Mark Price":
    "Opsiyonun Mark Fiyatını hesaplamak için kullanılan Zımni Volatilite",
  "Distance between the Strike Price and the underlying Spot Price":
    "Kullanım Fiyatı ile dayanak Spot Fiyat arasındaki mesafe",
  "Eight hour funding that is continuously accrued on positions and settled upon any trade. If the funding rate is positive, longs pay shorts. If negative, shorts pay longs":
    "Pozisyonlarda sürekli olarak biriken ve herhangi bir işlemde ödenen sekiz saatlik fonlama. Fonlama oranı pozitifse, longlar shortlara öder. Negatifse, shortlar longlara öder",
  "The Leverage Ratio (Lambda) measures an option's effective leverage by indicating how much the option's value changes, in percentage terms, for a 1% change in the spot price of the underlying asset. For example, if an option has a Lambda of 50 and the spot price increases by 1%, the option's value would increase by 50%":
    "Kaldıraç Oranı (Lambda), dayanak varlığın spot fiyatındaki %1'lik değişim için opsiyonun değerinin yüzde olarak ne kadar değiştiğini göstererek opsiyonun efektif kaldıracını ölçer. Örneğin, bir opsiyonun Lambda'sı 50 ise ve spot fiyat %1 artarsa, opsiyonun değeri %50 artacaktır",
  "Option delta measures how much the price of an option is expected to change for a $1 change in the price of the underlying asset. For example, a delta of 0.5 means the option price will move $0.50 for every $1 move in the underlying":
    "Opsiyon deltası, dayanak varlığın fiyatındaki $1'lık değişim için opsiyon fiyatının ne kadar değişmesinin beklendiğini ölçer. Örneğin, 0,5'lik bir delta, dayanak varlıktaki her $1'lık hareket için opsiyon fiyatının $0,50 hareket edeceği anlamına gelir",
  "Eight hour funding rate of the perpetual future on the same underlying asset":
    "Aynı dayanak varlık üzerindeki sürekli vadeli future'ın sekiz saatlik fonlama oranı",
  "Having trouble? Try signing out and connecting again.":
    "Sorun mu yaşıyorsunuz? Oturumu kapatıp tekrar bağlanmayı deneyin.",
  "API Reference": "API Referansı",
  "XP Distribution occurs once per week on Friday at ~12 PM UTC":
    "XP Dağıtımı haftada bir kez Cuma günü ~12:00 UTC'de gerçekleşir",
  "What are Perpetual Options?": "Sürekli Vadeli Opsiyonlar Nedir?",
  "Delta represents the increase in option price based on a $1 increase in the underlying spot price.\n\nFor example, a delta of 0.50 means the price of the option is expected to increase by $0.50 for a $1 increase in the underlying spot price.":
    "Delta, dayanak spot fiyatındaki $1'lık artışa dayalı opsiyon fiyatındaki artışı temsil eder.\n\nÖrneğin, 0,50'lik bir delta, dayanak spot fiyatındaki $1'lık artış için opsiyon fiyatının $0,50 artmasının beklendiği anlamına gelir.",
  "Leverage(Lambda) represents the percentage change in option price for a 1% change in the underlying spot price.\n\nFor example, a lambda of 10 means the option price is expected to increase by 10% if the underlying spot price increases by 1%.":
    "Kaldıraç(Lambda), dayanak spot fiyatındaki %1'lik değişim için opsiyon fiyatındaki yüzdesel değişimi temsil eder.\n\nÖrneğin, 10'luk bir lambda, dayanak spot fiyat %1 artarsa opsiyon fiyatının %10 artmasının beklendiği anlamına gelir.",
  "Eight hour options funding rate that is continuously accrued on positions and settled upon any trade. The funding rate for perpetual options reflects both the cost of leverage and the cost of insurance against adverse price moves. \n\nThe funding period of perpetual options is 24 hours. This means that the option premium will be continuously paid out over a 24h period. 24h funding rate = 3 x 8h funding rate":
    "Pozisyonlarda sürekli olarak biriken ve herhangi bir işlemde ödenen sekiz saatlik opsiyon fonlama oranı. Sürekli vadeli opsiyonlar için fonlama oranı hem kaldıraç maliyetini hem de olumsuz fiyat hareketlerine karşı sigorta maliyetini yansıtır. \n\nSürekli vadeli opsiyonların fonlama süresi 24 saattir. Bu, opsiyon priminin 24 saatlik süre boyunca sürekli olarak ödeneceği anlamına gelir. 24s fonlama oranı = 3 x 8s fonlama oranı",
  "Risk": "Risk",
  "Vega": "Vega",
  "Gamma": "Gamma",
  "Estimated Funding P&L for the next 8 hours based on current funding rates":
    "Mevcut fonlama oranlarına göre sonraki 8 saat için tahmini Fonlama K/Z",
  "Vega represents the increase in option price based on a 1% increase in the implied volatility.\n\nFor example, a Vega of 5 means the price of the option is expected to increase by $5 for a 1% increase in the implied volatility.":
    "Vega, zımni volatilitedeki %1'lik artışa dayalı opsiyon fiyatındaki artışı temsil eder.\n\nÖrneğin, 5'lik bir Vega, zımni volatilitedeki %1'lik artış için opsiyon fiyatının $5 artmasının beklendiği anlamına gelir.",
  "Adjust Leverage": "Kaldıracı Ayarla",
  "Leverage updated successfully": "Kaldıraç başarıyla güncellendi",
  "Failed to update leverage": "Kaldıraç güncellenemedi",
  "Adjust leverage parameters for selected market. Leverage will also increase on your open positions.":
    "Seçili pazar için kaldıraç parametrelerini ayarlayın. Kaldıraç açık pozisyonlarınızda da artacaktır.",
  "Please note that changing leverage will also apply for open positions and open orders on this market.":
    "Lütfen kaldıraç değişikliğinin bu pazardaki açık pozisyonlar ve açık emirler için de geçerli olacağını unutmayın.",
  "Selecting higher leverage could result in Deleveraging where some of your open orders would be cancelled.":
    "Daha yüksek kaldıraç seçmek, bazı açık emirlerinizin iptal edileceği Kaldıraç Azaltmaya neden olabilir.",
  "disabled": "devre dışı",
  "Margin Mode": "Teminat Modu",
  "Manage your risk on individual positions by restricting the amount of margin allocated to each. If the margin ratio of an isolated position reaches 100%, the position will be liquidated. Margin can be added or removed to individual positions in this mode.":
    "Her birine tahsis edilen teminat miktarını sınırlayarak bireysel pozisyonlardaki riskinizi yönetin. İzole bir pozisyonun teminat oranı %100'e ulaşırsa, pozisyon tasfiye edilecektir. Bu modda bireysel pozisyonlara teminat eklenebilir veya çıkarılabilir.",
  "Margin mode updated successfully": "Teminat modu başarıyla güncellendi",
  "Failed to update margin mode": "Teminat modu güncellenemedi",
  "Favorites": "Favoriler",
  "Trending": "Trendler",
  "Losers": "Kaybedenler",
  "Gainers": "Kazananlar",
  "New Listing": "Yeni Listeleme",
  "Developers": "Geliştiriciler",
  "Developer Mode": "Geliştirici Modu",
  "Enable developer mode": "Geliştirici Modunu Etkinleştir",
  "For external use": "Dış kullanım için",
  "Warning: Changing transfer behavior":
    "Uyarı: Transfer davranışını değiştirme",
  "USDC will be transferred to account contract instead of Paradex. Amount won't be visible or available for use within Paradex, but can be used with external applications.":
    "USDC, Paradex'in yerine hesap sözleşmesine transfer edilecektir. Miktar Paradex içinde görünür ve kullanılabilir olmayacak, ancak dış uygulamalarla kullanılabilir.",
  "Affiliate Dashboard": "Affiliate Dashboard",
};

export default translations;
