import { Keys } from "./en";

const translations: Record<Keys, string> = {
  "Display trade history on chart": "Wyświetl historię transakcji na wykresie",
  "Add markets to Favorites in the Markets Browser":
    "Dodaj rynki do Ulubionych w Przeglądarce Rynków",
  "Log in": "Zaloguj się",
  "Export embedded wallet": "Eksportuj wbudowany portfel",
  "Email or Social": "Email lub Media społecznościowe",
  "Failed to load balances": "Nie udało się załadować sald",
  "No balances": "Brak sald",
  "Verified Operator": "Zweryfikowany Operator",
  "Strategy": "Strategia",
  "Multi-Strategy": "Multi-Strategia",
  "Master Vault": "Główny Skarbiec",
  "Master Vault Details": "Szczegóły Głównego Skarbca",
  "Annualized": "W skali roku",
  "Depositors": "Deponenci",
  "No trade history": "Brak historii transakcji",
  "Unwinding Progress": "Postęp zamykania",
  "What are Vaults?": "Czym są Skarbce?",
  "APR calculated as the Annualised 30-day Return":
    "APR obliczony jako roczny zwrot 30-dniowy",
  "APR": "APR",
  "Initializing": "Inicjalizacja",
  "Awaiting Deposit": "Oczekiwanie na depozyt",
  "Vault Age": "Wiek Skarbca",
  "30D Return": "Zwrot 30D",
  "{{field}} must be greater than the minimum initial deposit of {{minInitialDeposit}}":
    "{{field}} musi być większy niż minimalny depozyt początkowy {{minInitialDeposit}}",
  "Minimum deposit": "Minimalny depozyt",
  "Withdrawal amount exceeds available vault shares":
    "Kwota wypłaty przekracza dostępne udziały w skarbcu",
  "Deposit would exceed maximum vault TVL of {{maxTVL}}":
    "Depozyt przekroczyłby maksymalną wartość TVL skarbca {{maxTVL}}",
  "{{field}} is required": "{{field}} jest wymagane",
  "Lockup Remaining": "Pozostały czas blokady",
  "{{days}}D": "{{days}}D",
  "<1>{{days}}</1> D": "<1>{{days}}</1> D",
  "<1>{{hours}}</1> H": "<1>{{hours}}</1> G",
  "<1>{{minutes}}</1> M": "<1>{{minutes}}</1> M",
  "Vault settings submitted": "Ustawienia skarbca zostały przesłane",
  "Vault settings updated": "Ustawienia skarbca zostały zaktualizowane",
  "Vault Settings": "Ustawienia Skarbca",
  "Vault not found": "Nie znaleziono skarbca",
  "The owner receives a {{profitShare}} profit share.":
    "Właściciel otrzymuje {{profitShare}} udział w zysku.",
  "This vault is entirely owned by the community.":
    "Ten skarbiec jest w całości własnością społeczności.",
  "Withdraw from Vault submitted":
    "Zlecenie wypłaty ze Skarbca zostało przesłane",
  "Deposit to Vault submitted": "Zlecenie wpłaty do Skarbca zostało przesłane",
  "Deposit to Vault successful": "Wpłata do Skarbca zakończona sukcesem",
  "Unknown error. Try again.": "Nieznany błąd. Spróbuj ponownie.",
  "Vault Deposit": "Wpłata do Skarbca",
  "Vault Withdrawal": "Wypłata ze Skarbca",
  "No Lockup": "Brak blokady",
  "Lockup period is not over yet": "Okres blokady jeszcze się nie zakończył",
  "Protocol": "Protokół",
  "24H": "24G",
  "7D": "7D",
  "30D": "30D",
  "All": "Wszystkie",
  "You have no investments in vaults": "Nie masz inwestycji w skarbcach",
  "Total Value": "Całkowita wartość",
  "Investments Made": "Dokonane inwestycje",
  "Operator": "Operator",
  "Manage Vault": "Zarządzaj Skarbcem",
  "Switch to the operator account": "Przełącz na konto operatora",
  "Active": "Aktywny",
  "My Vaults": "Moje Skarbce",
  "Closed": "Zamknięte",
  "Deposited": "Zdeponowane",
  "Lifetime ROI": "Całkowity zwrot z inwestycji",
  "Owners Equity": "Kapitał właściciela",
  "Top Trending": "Najpopularniejsze",
  "Owner Share": "Udział właściciela",
  "Vault Details": "Szczegóły Skarbca",
  "Contract": "Kontrakt",
  "Withdraw from Vault": "Wypłać ze Skarbca",
  "Withdraw from Vault successful": "Wypłata ze Skarbca zakończona sukcesem",
  "Failed to withdraw from Vault": "Nie udało się wypłacić ze Skarbca",
  "Name": "Nazwa",
  "Deposit": "Wpłata",
  "Tokens": "Tokeny",
  "Avg. Entry Price": "Średnia cena wejścia",
  "Current Price": "Aktualna cena",
  "P&L": "Zysk i strata",
  "My Vault Deposit": "Moja wpłata do Skarbca",
  "Vaults": "Skarbce",
  "Create": "Utwórz",
  "Create My Vault": "Utwórz mój Skarbiec",
  "Vault Name": "Nazwa Skarbca",
  "Vault Description": "Opis Skarbca",
  "Token Symbol": "Symbol tokena",
  "Deposit Amount": "Kwota wpłaty",
  "Vault description will be visible publicly and you will not be able to edit this later.":
    "Opis Skarbca będzie widoczny publicznie i nie będzie można go później edytować.",
  "Once created, deposit at least {{minDeposit}} USDC to activate the vault. At this moment, only the Max TVL can be changed later.":
    "Po utworzeniu wpłać co najmniej {{minDeposit}} USDC, aby aktywować skarbiec. W tej chwili tylko maksymalne TVL może być zmienione później.",
  "Create Vault": "Utwórz Skarbiec",
  "Profit Share": "Udział w zysku",
  "Lockup Period (Days)": "Okres blokady (Dni)",
  "Vault was successfully created and is being initialized":
    "Skarbiec został pomyślnie utworzony i jest inicjalizowany",
  "Failed to create a vault": "Nie udało się utworzyć skarbca",
  "Maximum TVL": "Maksymalne TVL",
  "No limit": "Bez limitu",
  "Vault Address": "Adres Skarbca",
  "Failed to deposit to Vault": "Nie udało się wpłacić do Skarbca",
  "Deposit to Vault": "Wpłać do Skarbca",
  "Vault": "Skarbiec",
  "Owner": "Właściciel",
  "Age": "Wiek",
  "Failed to load vaults": "Nie udało się załadować skarbców",
  "No vaults available": "Brak dostępnych skarbców",
  "Performance": "Wydajność",
  "Vault Value": "Wartość Skarbca",
  "My Deposit": "Moja wpłata",
  "Total P&L": "Całkowity zysk i strata",
  "Protocol Vaults": "Skarbce protokołu",
  "User Vaults": "Skarbce użytkowników",
  "No Data": "Brak danych",
  "Last Month Return": "Zwrot z ostatniego miesiąca",
  "TVL": "TVL",
  "Total Earnings": "Całkowite zarobki",
  "About": "O nas",
  "Vault Stats": "Statystyki Skarbca",
  "My Stats": "Moje statystyki",
  "Vault Contract": "Kontrakt Skarbca",
  "Description": "Opis",
  "Max Drawdown": "Maksymalne obsunięcie",
  "Volume": "Wolumen",
  "All-Time P&L": "Całkowity zysk i strata",
  "Unrealized P&L": "Niezrealizowany zysk i strata",
  "Days": "Dni",
  "Account Value": "Wartość konta",
  "Lockup Period": "Okres blokady",
  "Search Vaults": "Szukaj Skarbców",
  "Blog": "Blog",
  "Block Explorer": "Eksplorator bloków",
  "Close Vault": "Zamknij Skarbiec",
  "Close Vault successful": "Pomyślnie zamknięto Skarbiec",
  "You must close all open positions to close your Vault. Closed vaults cannot be reopened.":
    "Musisz zamknąć wszystkie otwarte pozycje, aby zamknąć swój Skarbiec. Zamkniętych skarbców nie można ponownie otworzyć.",
  "Close My Vault": "Zamknij mój Skarbiec",
  "Failed to Close the Vault": "Nie udało się zamknąć Skarbca",
  "Only the Main Account can manage the vault":
    "Tylko Konto Główne może zarządzać skarbcem",
  "Allocate Collateral": "Przydziel zabezpieczenie",
  "Switch to Main Account": "Przełącz na Konto Główne",
  "Switch to Account": "Przełącz na Konto",
  "You are currently trading in the {{market}} market.":
    "Aktualnie handlujesz na rynku {{market}}.",
  "To start trading on Isolated Market you must Allocate Collateral from your Main Account and switch to this Market Account.":
    "Aby rozpocząć handel na Rynku Izolowanym, musisz przydzielić zabezpieczenie z Konta Głównego i przełączyć się na to Konto Rynkowe.",
  "To trade in this market, switch to the Main Account first.":
    "Aby handlować na tym rynku, najpierw przełącz się na Konto Główne.",
  "This account can only trade in the {{market}} market.":
    "To konto może handlować tylko na rynku {{market}}.",
  "Isolated Market": "Rynek Izolowany",
  "This feature currently is still in Beta. Backup your Paradex Private Key for additional safety.":
    "Ta funkcja jest obecnie w wersji Beta. Wykonaj kopię zapasową swojego Klucza Prywatnego Paradex dla dodatkowego bezpieczeństwa.",
  "Deposit failed": "Wpłata nie powiodła się",
  "Deposit in progress": "Wpłata w toku",
  "External Account": "Konto zewnętrzne",
  "Tx Hashes": "Hashe transakcji",
  "Continue to {{bridge}}": "Kontynuuj do {{bridge}}",
  "By clicking Continue to {{bridge}}, you will be sent to {{bridge}} website to bridge your funds out of Paradex.":
    "Klikając Kontynuuj do {{bridge}}, zostaniesz przekierowany na stronę {{bridge}}, aby wyprowadzić środki z Paradex.",
  "By clicking Continue to {{bridge}}, you will be sent to {{bridge}} website to bridge your funds to Paradex.":
    "Klikając Kontynuuj do {{bridge}}, zostaniesz przekierowany na stronę {{bridge}}, aby wprowadzić środki do Paradex.",
  "Refer": "Poleć",
  "Address": "Adres",
  "Date Joined": "Data dołączenia",
  "Referral Code": "Kod polecający",
  "Volume Traded": "Wolumen obrotu",
  "Bridge": "Most",
  "Withdraw to": "Wypłać do",
  "Deposit from": "Wpłać z",
  "Position is already closed": "Pozycja jest już zamknięta",
  "Your Paradex L2 Address is NOT a Public Starknet Address. If you try to send funds to this address on Public Starknet via a cross chain bridge you risk losing your funds. <1>Read more</1>.":
    "Twój adres Paradex L2 NIE jest publicznym adresem Starknet. Jeśli spróbujesz wysłać środki na ten adres w publicznej sieci Starknet przez most międzyłańcuchowy, ryzykujesz utratę środków. <1>Czytaj więcej</1>.",
  "{{chain}} Address": "Adres {{chain}}",
  "Copy Paradex Private Key": "Kopiuj Klucz Prywatny Paradex",
  "Your account will be liquidated if Margin Ratio reaches 100%":
    "Twoje konto zostanie zlikwidowane, jeśli Wskaźnik Depozytu zabezpieczającego osiągnie 100%",
  "Liquidation": "Likwidacja",
  "Fee": "Opłata",
  "Maker": "Maker",
  "Maker Score": "Wynik Makera",
  "Maker XP Share": "Udział XP Makera",
  "Fee XP Share": "Udział XP z opłat",
  "Fee XP": "XP z opłat",
  "Maker XP": "XP Makera",
  "Liquidation {{side}} Fill": "Wypełnienie likwidacji {{side}}",
  "Settings": "Ustawienia",
  "Market Score": "Wynik rynkowy",
  "Season {{season}} (coming soon)": "Sezon {{season}} (wkrótce)",
  "Confirm Buy": "Potwierdź kupno",
  "Confirm Sell": "Potwierdź sprzedaż",
  "Other XP": "Inne XP",
  "Analytics": "Analityka",
  "Estimated Receivable Amount": "Szacowana kwota do otrzymania",
  "Socialized Loss active. Click to learn more.":
    "Aktywna strata socjalizowana. Kliknij, aby dowiedzieć się więcej.",
  "Sign In to see your account XP stats":
    "Zaloguj się, aby zobaczyć statystyki XP swojego konta",
  "<1>Clock Is Out Of Sync</1> The clock on your device is out of sync. This may cause issues with the application. Please make sure your device clock is set to be auto. For more details please <2>see documentation</2>":
    "<1>Zegar nie jest zsynchronizowany</1> Zegar na Twoim urządzeniu nie jest zsynchronizowany. Może to powodować problemy z aplikacją. Upewnij się, że zegar urządzenia jest ustawiony na automatyczny. Aby uzyskać więcej szczegółów, <2>zobacz dokumentację</2>",
  "Failed to fetch max withdrawable amount":
    "Nie udało się pobrać maksymalnej kwoty do wypłaty",
  "It is not possible to withdraw more than your Max Withdrawable Amount.":
    "Nie można wypłacić więcej niż Maksymalna Kwota do Wypłaty.",
  "Max Withdrawable Amount": "Maksymalna kwota do wypłaty",
  "XP Explainer": "Wyjaśnienie XP",
  "Pool": "Pula",
  "Quote Quality": "Jakość kwotowań",
  "Create Your Public Username": "Utwórz swoją publiczną nazwę użytkownika",
  "Edit Your Public Username": "Edytuj swoją publiczną nazwę użytkownika",
  "You have been logged out.": "Zostałeś wylogowany.",
  "Maker Volume Score": "Wynik wolumenu Makera",
  "Fee Score": "Wynik opłat",
  "Score Share": "Udział w wyniku",
  "Instrument Share": "Udział instrumentu",
  "XP": "XP",
  "Total XP": "Całkowite XP",
  "24h Change": "Zmiana 24h",
  "Leaderboard": "Tablica liderów",
  "Cancel": "Anuluj",
  "Portfolio": "Portfolio",
  "Trade": "Handel",
  "Build": "Buduj",
  "Referrals Explainer": "Wyjaśnienie poleceń",
  "How It Works": "Jak to działa",
  "My Referrals": "Moje polecenia",
  "Your Referral Code": "Twój kod polecający",
  "Affiliate Program": "Program partnerski",
  "Enrolled": "Zapisany",
  "Traders Referred": "Poleceni traderzy",
  "Referral Rewards": "Nagrody za polecenia",
  "Referral XP": "XP za polecenia",
  "Invite Friends": "Zaproś znajomych",
  "Share": "Udostępnij",
  "Earn": "Zarabiaj",
  "Give": "Daj",
  "Share your code to invite friends to join Paradex":
    "Udostępnij swój kod, aby zaprosić znajomych do dołączenia do Paradex",
  "<1>{{amount}}</1> Fees": "<1>{{amount}}</1> Opłat",
  "You receive {{amount}} of the referred user's fees":
    "Otrzymujesz {{amount}} z opłat poleconego użytkownika",
  "<1>{{amount}}</1> XP": "<1>{{amount}}</1> XP",
  "You receive {{amount}} of the referred user's XP":
    "Otrzymujesz {{amount}} z XP poleconego użytkownika",
  "<1>{{amount}}</1> Discount": "<1>{{amount}}</1> Zniżki",
  "Your friend receives {{amount}} discount on fees":
    "Twój znajomy otrzymuje {{amount}} zniżki na opłaty",
  "Create and share your custom referral code":
    "Utwórz i udostępnij swój własny kod polecający",
  "If you have a strong social media following (<1>{{followers}}+</1>), you may be eligible for our <2>VIP Affiliate Program</2> with higher commissions and XP.":
    "Jeśli masz silną obecność w mediach społecznościowych (<1>{{followers}}+</1>), możesz być uprawniony do naszego <2>Programu partnerskiego VIP</2> z wyższymi prowizjami i XP.",
  "You can track our <1>Affiliate Leaderboard</1> to see how you stack up.":
    "Możesz śledzić naszą <1>Tablicę liderów partnerów</1>, aby zobaczyć, jak się wypada.",
  "Apply": "Aplikuj",
  "Log in to see your Referral History.":
    "Zaloguj się, aby zobaczyć historię poleceń.",
  "Welcome to Paradex": "Witaj w Paradex",
  "Congrats! The referral code you used is now active! It means you can start enjoying a discount on your trading fees!":
    "Gratulacje! Użyty kod polecający jest teraz aktywny! Oznacza to, że możesz zacząć korzystać ze zniżki na opłaty transakcyjne!",
  "Congrats! The referral code <1>{{codeProvided}}</1> used is now active!":
    "Gratulacje! Użyty kod polecający <1>{{codeProvided}}</1> jest teraz aktywny!",
  "Referral code not activated. The code can only be applied once during the initial onboarding.":
    "Kod polecający nie został aktywowany. Kod może być użyty tylko raz podczas początkowej rejestracji.",
  "Referral code <1>{{codeProvided}}</1> not activated. You have already onboarded with <1>{{referredBy}}</1>.":
    "Kod polecający <1>{{codeProvided}}</1> nie został aktywowany. Już zarejestrowałeś się z kodem <1>{{referredBy}}</1>.",
  "Referral Code Applied": "Zastosowano kod polecający",
  "You will receive <1>{{userAmount}}</1> of referred user's <1>fees</1> <2>and</2> <1>{{userAmountPoints}} XP</1>. They will receive a <1>{{friendAmount}}</1> discount.":
    "Otrzymasz <1>{{userAmount}}</1> z <1>opłat</1> poleconego użytkownika <2>oraz</2> <1>{{userAmountPoints}} XP</1>. Oni otrzymają <1>{{friendAmount}}</1> zniżki.",
  "Community": "Społeczność",
  "Environments": "Środowiska",
  "Language": "Język",
  "System Status": "Status systemu",
  "Docs": "Dokumentacja",
  "Code Samples": "Przykłady kodu",
  "More": "Więcej",
  "Stats": "Statystyki",
  "Join Discord": "Dołącz do Discord",
  "Notifications": "Powiadomienia",
  "All caught up!": "Wszystko na bieżąco!",
  "You have no new notifications": "Nie masz nowych powiadomień",
  "Connect Wallet": "Połącz portfel",
  "WalletConnect is taking too long to open. Please refresh the page and try again.":
    "WalletConnect otwiera się zbyt długo. Odśwież stronę i spróbuj ponownie.",
  "Unexpected error opening WalletConnect modal. Please refresh the page and try again.":
    "Nieoczekiwany błąd podczas otwierania okna WalletConnect. Odśwież stronę i spróbuj ponownie.",
  "Connect your Wallet": "Połącz swój portfel",
  "Link Wallet": "Połącz portfel",
  "You will receive a signature request. Signing is free and will not send a transaction.":
    "Otrzymasz prośbę o podpis. Podpisanie jest bezpłatne i nie wyśle transakcji.",
  "Generate Paradex Chain wallet": "Wygeneruj portfel Paradex Chain",
  "Generating Paradex Chain wallet": "Generowanie portfela Paradex Chain",
  "Paradex Chain wallet generated": "Wygenerowano portfel Paradex Chain",
  "Verify that you own this wallet":
    "Potwierdź, że jesteś właścicielem tego portfela",
  "Remember Me": "Zapamiętaj mnie",
  "Only use 'Remember Me' if you are using a secure device you own. Selecting this option can expose your information to  others if you are accessing this wallet from a public device.":
    "Używaj opcji 'Zapamiętaj mnie' tylko jeśli korzystasz z bezpiecznego urządzenia, którego jesteś właścicielem. Wybranie tej opcji może narazić Twoje informacje na dostęp innych osób, jeśli korzystasz z tego portfela na urządzeniu publicznym.",
  "Continue": "Kontynuuj",
  "Disconnect Wallet": "Odłącz portfel",
  "Disconnect": "Odłącz",
  "Account": "Konto",
  "Username": "Nazwa użytkownika",
  "Edit": "Edytuj",
  "Your username is now hidden. It is not visible to other users.":
    "Twoja nazwa użytkownika jest teraz ukryta. Nie jest widoczna dla innych użytkowników.",
  "Your username will be visible to other users unless you choose to hide it. You can always change it later.":
    "Twoja nazwa użytkownika będzie widoczna dla innych użytkowników, chyba że zdecydujesz się ją ukryć. Zawsze możesz ją później zmienić.",
  "Hide my Username": "Ukryj moją nazwę użytkownika",
  "Save": "Zapisz",
  "Username is required": "Nazwa użytkownika jest wymagana",
  "Username must be between 5 and 20 characters":
    "Nazwa użytkownika musi mieć od 5 do 20 znaków",
  "Username can only contain letters and numbers":
    "Nazwa użytkownika może zawierać tylko litery i cyfry",
  "Wallets": "Portfele",
  "Paradex Block Explorer": "Eksplorator bloków Paradex",
  "Log out": "Wyloguj się",
  "No markets available": "Brak dostępnych rynków",
  "Last Price": "Ostatnia cena",
  "Open Interest": "Otwarte pozycje",
  "24h Volume": "Wolumen 24h",
  "Mark Price": "Cena oznaczenia",
  "Spot Price": "Cena spot",
  "Eight hour funding rate that is continuously accrued on positions and settled upon any trade. If the funding rate is positive, longs pay shorts. If negative, shorts pay longs.":
    "Ośmiogodzinowa stopa finansowania, która jest stale naliczana na pozycjach i rozliczana przy każdej transakcji. Jeśli stopa finansowania jest dodatnia, długie pozycje płacą krótkim. Jeśli ujemna, krótkie płacą długim.",
  "8h Funding": "Finansowanie 8h",
  "Limit": "Limit",
  "Market": "Rynek",
  "Stop Limit": "Stop Limit",
  "Stop Market": "Stop Market",
  "Scaled Order": "Zlecenie skalowane",
  "Trigger Price": "Cena wyzwalająca",
  "Input Price": "Wprowadź cenę",
  "Position Mark Price": "Cena oznaczenia pozycji",
  "Amount": "Kwota",
  "Reduce Only": "Tylko redukcja",
  "Reduce Only will increase position": "Tylko redukcja zwiększy pozycję",
  "Your order stays open until it is filled or you decide to cancel.":
    "Twoje zlecenie pozostaje otwarte, dopóki nie zostanie zrealizowane lub nie zdecydujesz się je anulować.",
  "Your order gets filled right away, partially or fully, and whatever is left gets canceled instantly.":
    "Twoje zlecenie jest realizowane natychmiast, częściowo lub w całości, a wszystko co pozostanie jest natychmiast anulowane.",
  "Conditional limit order that serves to add liquidity to the order book as a Maker order.":
    "Warunkowe zlecenie z limitem, które służy do dodawania płynności do księgi zleceń jako zlecenie Makera.",
  "Currently the fees are fixed and uniform across the platform. Maker {{makerFee}} / Taker {{takerFee}}":
    "Obecnie opłaty są stałe i jednolite na całej platformie. Maker {{makerFee}} / Taker {{takerFee}}",
  "Value": "Wartość",
  "Buy": "Kup",
  "BUY": "KUP",
  "LONG": "DŁUGA",
  "Sell": "Sprzedaj",
  "SELL": "SPRZEDAJ",
  "SHORT": "KRÓTKA",
  "Order Value": "Wartość zlecenia",
  "Current Position": "Aktualna pozycja",
  "The total value of your account at current mark prices":
    "Całkowita wartość twojego konta według aktualnych cen oznaczenia",
  "Free Collateral": "Wolne zabezpieczenie",
  "Account Leverage": "Dźwignia konta",
  "Margin": "Depozyt zabezpieczający",
  "Margin Ratio": "Wskaźnik depozytu zabezpieczającego",
  "Initial Margin": "Początkowy depozyt zabezpieczający",
  "Maintenance Margin": "Depozyt podtrzymujący",
  "Order Book": "Księga zleceń",
  "Trades": "Transakcje",
  "Price": "Cena",
  "Size": "Wielkość",
  "Total": "Suma",
  "Time": "Czas",
  "Spread": "Spread",
  "Positions": "Pozycje",
  "Open Orders": "Otwarte zlecenia",
  "Trade History": "Historia transakcji",
  "Order History": "Historia zleceń",
  "Funding Payments": "Płatności finansowania",
  "Side": "Strona",
  "Entry Price": "Cena wejścia",
  "Liquidation Price": "Cena likwidacji",
  "Breakeven Price": "Cena progu rentowności",
  "Unrealized Funding": "Niezrealizowane finansowanie",
  "Close Position": "Zamknij pozycję",
  "Market Close": "Zamknięcie rynkowe",
  "Close Long": "Zamknij długą",
  "Close Short": "Zamknij krótką",
  "Type": "Typ",
  "Filled": "Zrealizowane",
  "Triggered": "Wyzwolone",
  "Instruction": "Instrukcja",
  "Client Order Id": "ID zlecenia klienta",
  "Status": "Status",
  "NEW": "NOWE",
  "UNTRIGGERED": "NIEWYZWOLONE",
  "OPEN": "OTWARTE",
  "CLOSED": "ZAMKNIĘTE",
  "Pending": "Oczekujące",
  "Complete": "Zakończone",
  "Completed": "Zakończone",
  "Failed": "Nieudane",
  "Bust": "Anulowane",
  "Value / Fee": "Wartość / Opłata",
  "Liquidity": "Płynność",
  "Tx Hash": "Hash transakcji",
  "Trade Id": "ID transakcji",
  "Remaining": "Pozostało",
  "Cancel Reason": "Powód anulowania",
  "User Canceled": "Anulowane przez użytkownika",
  "Not Enough Margin": "Niewystarczający depozyt zabezpieczający",
  "Empty Market": "Pusty rynek",
  "Post Only": "Tylko post",
  "Post Only Would Cross": "Post Only spowodowałoby krzyżowanie",
  "Remaining IOC Cancel": "Anulowanie pozostałego IOC",
  "Unexpected Failure": "Nieoczekiwany błąd",
  "Deleverage": "Zmniejszenie dźwigni",
  "In Liquidation": "W likwidacji",
  "Self Trade": "Handel własny",
  "Asset Unavailable": "Aktywo niedostępne",
  "Asset Expired": "Aktywo wygasło",
  "Order Type Invalid": "Nieprawidłowy typ zlecenia",
  "Price Not Available": "Cena niedostępna",
  "Expired": "Wygasło",
  "Exceeds Max Slippage": "Przekracza maksymalny poślizg",
  "Timeout": "Przekroczony czas",
  "Order Exceeds Position Limit": "Zlecenie przekracza limit pozycji",
  "Order Id": "ID zlecenia",
  "Payment": "Płatność",
  "Cancel All": "Anuluj wszystkie",
  "{{orderSize}} will be closed at Market price":
    "{{orderSize}} zostanie zamknięte po cenie rynkowej",
  "By connecting a wallet, you agree to <1>Paradex Terms of Service</1> and represent and warrant to Paradex that you are not a <2>Restricted Person</2>.":
    "Łącząc portfel, zgadzasz się na <1>Warunki korzystania z usług Paradex</1> i oświadczasz oraz gwarantujesz Paradex, że nie jesteś <2>Osobą objętą ograniczeniami</2>.",
  "Wallet": "Portfel",
  "Deposit_verb": "Wpłać",
  "Deposit_noun": "Wpłata",
  "Withdraw": "Wypłać",
  "Withdrawal": "Wypłata",
  "Transfers": "Przelewy",
  "Transfer": "Przelew",
  "Collateral": "Zabezpieczenie",
  "Asset": "Aktywo",
  "Wallet Balance": "Saldo portfela",
  "Actions": "Akcje",
  "Action": "Akcja",
  "External Tx Hash": "Zewnętrzny hash transakcji",
  "Withdraw to Wallet": "Wypłać do portfela",
  "Deposit to Paradex": "Wpłać do Paradex",
  "You have no balance in your Ethereum wallet. Deposit some {{asset}} and try again.":
    "Nie masz salda w swoim portfelu Ethereum. Wpłać trochę {{asset}} i spróbuj ponownie.",
  "It is not possible to deposit more than your current balance.":
    "Nie można wpłacić więcej niż twoje aktualne saldo.",
  "Available": "Dostępne",
  "Initiate a deposit from your Ethereum wallet to the Paradex bridge. These transactions":
    "Zainicjuj wpłatę z portfela Ethereum do mostu Paradex. Te transakcje",
  "Initiate a deposit from your Ethereum wallet to the Paradex bridge. These transactions may take several minutes depending on network conditions. For more information check out our <1>docs</1>.":
    "Zainicjuj wpłatę z portfela Ethereum do mostu Paradex. Te transakcje mogą potrwać kilka minut w zależności od warunków sieciowych. Aby uzyskać więcej informacji, sprawdź naszą <1>dokumentację</1>.",
  "read more": "czytaj więcej",
  "Initiate Deposit to Bridge": "Zainicjuj wpłatę do mostu",
  "Enable USDC on Paradex": "Włącz USDC na Paradex",
  "In order to deposit you must allow Paradex access to at least {{amount}}.":
    "Aby dokonać wpłaty, musisz zezwolić Paradex na dostęp do co najmniej {{amount}}.",
  "Enable USDC": "Włącz USDC",
  "Withdraw initiated": "Zainicjowano wypłatę",
  "Withdrawals are a two-step process. First initiate a withdrawal from Paradex":
    "Wypłaty to proces dwuetapowy. Najpierw zainicjuj wypłatę z Paradex",
  "Withdrawals are a two-step process. First initiate a withdrawal from Paradex to the Paradex Bridge. This first step can take 5-16 hours (10 on average). Second, initiate your withdrawal from the Paradex Bridge to your Ethereum wallet via the Deposits / Withdrawals table. For more information check out our <1>docs</1>.":
    "Wypłaty to proces dwuetapowy. Najpierw zainicjuj wypłatę z Paradex do mostu Paradex. Ten pierwszy krok może potrwać 5-16 godzin (średnio 10). Następnie zainicjuj wypłatę z mostu Paradex do swojego portfela Ethereum przez tabelę Wpłaty / Wypłaty. Aby uzyskać więcej informacji, sprawdź naszą <1>dokumentację</1>.",
  "Initiate Withdrawal to Bridge": "Zainicjuj wypłatę do mostu",
  "You have no funds to withdraw. It is not possible to withdraw more than your free collateral.":
    "Nie masz środków do wypłaty. Nie można wypłacić więcej niż twoje wolne zabezpieczenie.",
  "It is not possible to withdraw more than your free collateral.":
    "Nie można wypłacić więcej niż twoje wolne zabezpieczenie.",
  "You have no collateral": "Nie masz zabezpieczenia",
  "You have no open positions": "Nie masz otwartych pozycji",
  "You have no open orders": "Nie masz otwartych zleceń",
  "You have no trades": "Nie masz transakcji",
  "You have no orders": "Nie masz zleceń",
  "You have no funding payments": "Nie masz płatności finansowania",
  "You have no transfers": "Nie masz przelewów",
  "Account in liquidation": "Konto w likwidacji",
  "Account is being liquidated. Any open positions will be reduced. Trading now is disabled.":
    "Konto jest likwidowane. Wszystkie otwarte pozycje zostaną zredukowane. Handel jest obecnie wyłączony.",
  "Account liquidated": "Konto zlikwidowane",
  "Your account was liquidated on {{createdAt}}. Any open positions were reduced.":
    "Twoje konto zostało zlikwidowane {{createdAt}}. Wszystkie otwarte pozycje zostały zredukowane.",
  "Deposit to Paradex successful": "Wpłata do Paradex zakończona sukcesem",
  "Deposit to Paradex failed": "Wpłata do Paradex nie powiodła się",
  "Unexpected error. Try again.": "Nieoczekiwany błąd. Spróbuj ponownie.",
  "Deposit initiated": "Zainicjowano wpłatę",
  "Deposit to bridge initiated": "Zainicjowano wpłatę do mostu",
  "Deposit to Paradex available": "Wpłata do Paradex dostępna",
  "Go to Deposits": "Przejdź do wpłat",
  "Deposit to Paradex in progress": "Wpłata do Paradex w toku",
  "Transfer successful": "Przelew zakończony sukcesem",
  "Limit {{side}} Order Fill": "Wypełnienie zlecenia limit {{side}}",
  "{{type}} {{side}} Order Submitted": "Złożono zlecenie {{type}} {{side}}",
  "{{type}} {{side}} Order Canceled": "Anulowano zlecenie {{type}} {{side}}",
  "Reason": "Powód",
  "Market {{side}} Order Fill": "Wypełnienie zlecenia rynkowego {{side}}",
  "Trade Busted On-Chain": "Transakcja anulowana w łańcuchu",
  "Fill id": "ID wypełnienia",
  "Withdraw to wallet failed": "Wypłata do portfela nie powiodła się",
  "Withdrawal to bridge initiated": "Zainicjowano wypłatę do mostu",
  "Withdrawal error. Try again.": "Błąd wypłaty. Spróbuj ponownie.",
  "Withdrawal to bridge in progress": "Wypłata do mostu w toku",
  "Withdrawal to wallet initiated": "Zainicjowano wypłatę do portfela",
  "Withdrawal to bridge failed": "Wypłata do mostu nie powiodła się",
  "Transaction error. Try again.": "Błąd transakcji. Spróbuj ponownie.",
  "Withdrawal to wallet available": "Wypłata do portfela dostępna",
  "Go to Withdrawals": "Przejdź do wypłat",
  "Withdrawal to wallet successful": "Wypłata do portfela zakończona sukcesem",
  "Session expired, please log in again.":
    "Sesja wygasła, zaloguj się ponownie.",
  "Failed to submit order": "Nie udało się złożyć zlecenia",
  "Your username preferences were updated!":
    "Twoje preferencje nazwy użytkownika zostały zaktualizowane!",
  "Unexpected error updating username preferences":
    "Nieoczekiwany błąd podczas aktualizacji preferencji nazwy użytkownika",
  "This username already exists. Please choose a different username and try again.":
    "Ta nazwa użytkownika już istnieje. Wybierz inną nazwę użytkownika i spróbuj ponownie.",
  "Validation Error": "Błąd walidacji",
  "Binding Error": "Błąd wiązania",
  "Internal Error": "Błąd wewnętrzny",
  "Not Found": "Nie znaleziono",
  "Service Unavailable": "Usługa niedostępna",
  "Invalid Request Parameter": "Nieprawidłowy parametr żądania",
  "Order Id Not Found": "Nie znaleziono ID zlecenia",
  "Order Is Closed": "Zlecenie jest zamknięte",
  "Order Is Not Open Yet": "Zlecenie nie jest jeszcze otwarte",
  "Client Order Id Not Found": "Nie znaleziono ID zlecenia klienta",
  "Duplicated Client Id": "Zduplikowane ID klienta",
  "Invalid Price Precision": "Nieprawidłowa precyzja ceny",
  "Invalid Token": "Nieprawidłowy token",
  "Invalid Ethereum Address": "Nieprawidłowy adres Ethereum",
  "Invalid Ethereum Signature": "Nieprawidłowy podpis Ethereum",
  "Invalid Starknet Address": "Nieprawidłowy adres Starknet",
  "Invalid Starknet Signature": "Nieprawidłowy podpis Starknet",
  "Starknet Signature Verification Failed":
    "Weryfikacja podpisu Starknet nie powiodła się",
  "Bad Starknet Request": "Nieprawidłowe żądanie Starknet",
  "Ethereum Signer Mismatch": "Niezgodność podpisującego Ethereum",
  "Ethereum Hash Mismatch": "Niezgodność hashu Ethereum",
  "Not Onboarded": "Nie rozpoczęto",
  "Invalid Timestamp": "Nieprawidłowy znacznik czasu",
  "Invalid Signature Expiration": "Nieprawidłowa data ważności podpisu",
  "Account Not Found": "Nie znaleziono konta",
  "Invalid Order Signature": "Nieprawidłowy podpis zlecenia",
  "Public Key Invalid": "Nieprawidłowy klucz publiczny",
  "Unauthorized Ethereum Address": "Nieautoryzowany adres Ethereum",
  "Ethereum Address Already Onboarded": "Adres Ethereum już zarejestrowany",
  "Market Not Found": "Nie znaleziono rynku",
  "Allowlist Entry Not Found": "Nie znaleziono wpisu na liście dozwolonych",
  "Username In Use": "Nazwa użytkownika jest już używana",
  "Service access restricted in your region":
    "Dostęp do usługi jest ograniczony w twoim regionie",
  "No Access Allowed": "Brak dostępu",
  "You do not have access to the platform. Please join our Discord to get access.":
    "Nie masz dostępu do platformy. Dołącz do naszego Discorda, aby uzyskać dostęp.",
  "{{actualChainName}} Wallet Connected":
    "Połączono portfel {{actualChainName}}",
  'Incorrect network selected "{{actualChainName}}". Please change the network to "{{requiredChainName}}" (id={{requiredChainId}}) in your wallet and retry.':
    'Wybrano nieprawidłową sieć "{{actualChainName}}". Zmień sieć na "{{requiredChainName}}" (id={{requiredChainId}}) w swoim portfelu i spróbuj ponownie.',
  "Blocked Ethereum Address": "Zablokowany adres Ethereum",
  "Choose Wallet": "Wybierz portfel",
  "Starknet Wallet": "Portfel Starknet",
  "Switch to {{env}}": "Przełącz na {{env}}",
  "Search": "Szukaj",
  "League": "Liga",
  "Switch Account": "Przełącz konto",
  "Rank": "Ranga",
  "24h XP": "XP 24h",
  "XP Per Day": "XP na dzień",
  "Season Volume": "Wolumen sezonu",
  "Lifetime Volume": "Wolumen życiowy",
  "Pre-Season": "Przedsezon",
  "Earn More XP": "Zdobądź więcej XP",
  "XP Boost": "Wzmocnienie XP",
  "XP Drop": "Drop XP",
  "Trader": "Trader",
  "Total Share": "Całkowity udział",
  "Maker Share": "Udział Makera",
  "Maker Volume Share": "Udział wolumenu Makera",
  "Fee Share": "Udział w opłatach",
  "Fee Volume Share": "Udział wolumenu opłat",
  "No data available": "Brak dostępnych danych",
  "Refer & Earn": "Poleć i zarabiaj",
  "Hours": "Godziny",
  "Minutes": "Minuty",
  "Time/Duration": "Czas/Czas trwania",
  "Runtime": "Czas działania",
  "Frequency": "Częstotliwość",
  "Number of Orders": "Liczba zleceń",
  "Runtime should not be empty": "Czas działania nie może być pusty",
  "Runtime should be less than or equal to 24 hours":
    "Czas działania powinien być mniejszy lub równy 24 godzinom",
  "Inactive": "Nieaktywny",
  "Avg. Price": "Średnia cena",
  "The minimum required amount for {{numOrders}} orders is {{totalSize}} {{currency}}.":
    "Minimalna wymagana kwota dla {{numOrders}} zleceń to {{totalSize}} {{currency}}.",
  "Take Profit / Stop Loss: Set take profit and stop loss price levels to automate trade actions. When the Mark Price will reach the price you set, it will send your order to the matching engine.":
    "Take Profit / Stop Loss: Ustaw poziomy cen take profit i stop loss, aby zautomatyzować działania handlowe. Gdy Cena Oznaczenia osiągnie ustawioną przez Ciebie cenę, wyśle Twoje zlecenie do silnika dopasowującego.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount with an estimated profit of <3 />.":
    "Gdy Cena Oznaczenia osiągnie <1 />, wywoła zlecenie <2 /> aby zamknąć wybraną kwotę z szacowanym zyskiem <3 />.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount with an estimated loss of <3 />.":
    "Gdy Cena Oznaczenia osiągnie <1 />, wywoła zlecenie <2 /> aby zamknąć wybraną kwotę z szacowaną stratą <3 />.",
  "Edit TP/SL For This Position": "Edytuj TP/SL dla tej pozycji",
  "Take Profit": "Take Profit",
  "Profit": "Zysk",
  "Stop Loss": "Stop Loss",
  "Loss": "Strata",
  "Confirm": "Potwierdź",
  "Take Profit Limit": "Limit Take Profit",
  "Take Profit Market": "Market Take Profit",
  "Stop Loss Limit": "Limit Stop Loss",
  "Stop Loss Market": "Market Stop Loss",
  "Funding": "Finansowanie",
  "Unwinding": "Zamykanie",
  "Cancel TP/SL orders": "Anuluj zlecenia TP/SL",
  "TP/SL orders submitted successfully.": "Zlecenia TP/SL złożone pomyślnie.",
  "Pre-Season + All Seasons": "Przedsezon + Wszystkie sezony",
  "Season {{season}}": "Sezon {{season}}",
  "XP Season {{season}}": "XP Sezon {{season}}",
  "Slippage": "Poślizg",
  "Max Slippage": "Maksymalny poślizg",
  "Max slippage defines the largest acceptable price deviation from the mark price.":
    "Maksymalny poślizg definiuje największe akceptowalne odchylenie ceny od ceny oznaczenia.",
  "During execution, the order can be canceled if the price exceeds the slippage limit or falls outside the Market <1>Price Band</1>.":
    "Podczas wykonywania zlecenie może zostać anulowane, jeśli cena przekroczy limit poślizgu lub wyjdzie poza rynek <1>Pasmo Ceny</1>.",
  "The Maximum Slippage allowed for {{symbol}} is {{price_band_width}}.":
    "Maksymalny poślizg dla {{symbol}} to {{price_band_width}}.",
  "Update Max Slippage": "Aktualizuj maksymalny poślizg",
  "Mark IV": "Mark IV",
  "Delta": "Delta",
  "Failed to load positions": "Nie udało się załadować pozycji",
  "No open positions": "Brak otwartych pozycji",
  "learn more": "czytaj więcej",
  "Learn More": "Dowiedz się więcej",
  "Search Markets": "Szukaj rynków",
  "Futures": "Futures",
  "Options": "Opcje",
  "OI": "OI",
  "Addresses": "Adresy",
  "My Profile": "Moje konto",
  "Chart": "Wykres",
  "ROI": "ROI",
  "Sharpe": "Sharpe",
  "Max Loss": "Maksymalna strata",
  "Total Volume": "Całkowity wolumen",
  "My NFTs": "Moje NFT",
  "My XP": "Moje XP",
  "Balances": "Salda",
  "Other": "Inne",
  "Paradex XP: Pre-Season": "Paradex XP: Przedsezon",
  "Paradex XP: Season {{season}}": "Paradex XP: Sezon {{season}}",
  "Season {{season}} XP": "Sezon {{season}} XP",
  "Refer your friends to earn 10%": "Poleć znajomym, aby zarobić 10%",
  "Refer Friends and Earn 10%": "Poleć znajomym i zarabiaj 10%",
  "Invest in Paradex Vault & Earn 3X Boost":
    "Inwestuj w skarbiec Paradex & zarabiaj 3X Boost",
  "Avg. Daily XP": "Średnia dzienna XP",
  "Lifetime XP": "Całkowite XP",
  "My Rank": "Moja ranga",
  "What is XP?": "Co to jest XP?",
  "XP History": "Historia XP",
  "Last Week XP": "XP z ostatniego tygodnia",
  "TVL XP": "TVL XP",
  "OI XP": "OI XP",
  "Liquidation XP": "XP likwidacji",
  "Vaults XP": "XP Skarbców",
  "Week": "Tydzień",
  "Season": "Sezon",
  "Last updated: {{timestamp}}": "Ostatnia aktualizacja: {{timestamp}}",
  "Xperience Points (XP)": "Punkty doświadczenia (XP)",
  "Log in to see your XP balance": "Zaloguj się, aby zobaczyć saldo XP",
  "Log in to see where you stand":
    "Zaloguj się, aby zobaczyć, gdzie się znajdujesz",
  "Connect your X account to join Pro League":
    "Połącz konto X, aby dołączyć do Pro League",
  "Avg. Fill Price": "Średnia cena wypełnienia",
  "Spot": "Spot",
  "Derivatives": "Derwatywy",
  "P&L Chart": "Wykres P&L",
  "Account Value Chart": "Wykres wartości konta",
  "Funding History": "Historia finansowania",
  "Manage Funds": "Zarządzaj funduszami",
  "Profile": "Profil",
  "Community XP": "XP społeczności",
  "XP Share": "Udostępnij XP",
  "Next XP Drop in": "Następny Drop XP w",
  "In Progress": "W toku",
  "{{field}} must be greater than {{value}}":
    "{{field}} musi być większy niż {{value}}",
  "{{field}} must be less than {{value}}":
    "{{field}} musi być mniejszy niż {{value}}",
  "Enter a valid Take Profit or Stop Loss price":
    "Wprowadź prawidłową cenę Take Profit lub Stop Loss",
  "Take Profit and Stop Loss prices cannot be the same":
    "Ceny Take Profit i Stop Loss nie mogą być takie same",
  "Automatic Withdrawal": "Automatyczna wypłata",
  "Estimated Gas Fee": "Szacowana opłata za gaz",
  "Auto Withdrawal": "Automatyczna wypłata",
  "Withdrawing...": "Wypłacanie...",
  "Paradex Bridge withdrawals can take between 5-16 hours to complete":
    "Wypłaty przez most Paradex mogą trwać od 5 do 16 godzin",
  "Socialized Loss": "Strata socjalizowana",
  "Receivable Amount": "Kwota do otrzymania",
  "Auto withdrawing...": "Automatyczne wypłacanie...",
  "Manage Twitter Connection": "Zarządzaj połączeniem z Twitterem",
  "Unlock additional functionality.": "Odblokuj dodatkowe funkcje.",
  "Please note this connection can't be used as a login method to Paradex.":
    "Pamiętaj, że tego połączenia nie można używać jako metody logowania do Paradex.",
  "Toast Notifications": "Powiadomienia wyskakujące",
  "Display toast notifications": "Wyświetlaj powiadomienia wyskakujące",
  "Average Entry Price": "Średnia cena wejścia",
  "Display average entry price line on chart":
    "Wyświetlaj linię średniej ceny wejścia na wykresie",
  "Display liquidation price line on chart":
    "Wyświetlaj linię ceny likwidacji na wykresie",
  "Display open order lines on chart":
    "Wyświetlaj linie otwartych zleceń na wykresie",
  "Failed to disconnect Twitter": "Nie udało się rozłączyć z Twitterem",
  "Failed to authorize Twitter": "Nie udało się autoryzować Twittera",
  "Twitter account linked successfully!":
    "Konto Twitter zostało pomyślnie połączone!",
  "Failed to link twitter account": "Nie udało się połączyć konta Twitter",
  "Fees": "Opłaty",
  "Taker pays {{takerFee}} in fees. Maker receives {{makerRebate}} in rebates.":
    "Taker płaci {{takerFee}} w opłatach. Maker otrzymuje {{makerRebate}} w rabatach.",
  "Failed to authorize Discord": "Nie udało się autoryzować Discorda",
  "Manage Discord Connection": "Zarządzaj połączeniem z Discordem",
  "Failed to disconnect Discord": "Nie udało się rozłączyć z Discordem",
  "Failed to link Discord account": "Nie udało się połączyć konta Discord",
  "Discord account linked successfully!":
    "Konto Discord zostało pomyślnie połączone!",
  "Connect": "Połącz",
  "Unrealized P&L from current vault token balance. This doesn't account for owner profit share or slippage from positions unwind.":
    "Niezrealizowany P&L z aktualnego salda tokenów skarbca. Nie uwzględnia to udziału w zysku właściciela ani poślizgu z zamykania pozycji.",
  "Show P&L Amount": "Pokaż kwotę P&L",
  "Receivable amount must be positive": "Kwota do otrzymania musi być dodatnia",
  "Wallet Address Change Detected": "Wykryto zmianę adresu portfela",
  "You can proceed with connecting your Paradex account to this wallet address or continue using your current connection.":
    "Możesz kontynuować łączenie swojego konta Paradex z tym adresem portfela lub nadal korzystać z obecnego połączenia.",
  "Connect To New Wallet": "Połącz z nowym portfelem",
  "Keep Using Current Wallet": "Kontynuuj używanie obecnego portfela",
  "Connect Current Wallet": "Połącz obecny portfel",
  "The account is not connected. Please make sure that <1>{{walletAddress}}</1> is connected and is the active account in your <2>{{walletName}}</2> wallet.":
    "Konto nie jest połączone. Upewnij się, że <1>{{walletAddress}}</1> jest połączony i jest aktywnym kontem w twoim portfelu <2>{{walletName}}</2>.",
  "Reconnecting": "Ponowne łączenie",
  "There is a request in progress. Please proceed in your wallet.":
    "Trwa przetwarzanie żądania. Kontynuuj w swoim portfelu.",
  "Modify Order": "Modyfikuj zlecenie",
  "Realized P&L": "Zrealizowany P&L",
  "Close All": "Zamknij wszystkie",
  "Close All Positions": "Zamknij wszystkie pozycje",
  "Market Close All Positions": "Zamknij wszystkie pozycje rynkowe",
  "Close {{percent}}% of All Positions":
    "Zamknij {{percent}}% wszystkich pozycji",
  "Failed to close all positions": "Nie udało się zamknąć wszystkich pozycji",
  "Number of Positions": "Liczba pozycji",
  "Markets": "Rynki",
  "Position Value (Closing)": "Wartość pozycji (zamykanie)",
  "{{count}} positions will be rounded to the nearest order increment":
    "{{count}} pozycji zostanie zaokrąglone do najbliższego przyrostu zlecenia",
  "You will earn Bonus XP on your referral activity":
    "Otrzymasz Bonus XP za swoją aktywność polecającą",
  "Token Price": "Cena tokenu",
  "Vault Token Price Chart": "Wykres ceny tokenów skarbca",
  "Market Close Orders Submitted": "Złożono zamknięte zlecenia rynkowe",
  "This profile is already linked to a different Paradex account. Disconnect socials from existing account and try again.":
    "Ten profil jest już połączony z innym kontem Paradex. Odłącz social media z istniejącym kontem i spróbuj ponownie.",
  "XP Drops every Friday": "Drop XP każdego piątku",
  "No XP Data Available": "Brak dostępnych danych XP",
  "XP data is available only for the Main Account.":
    "Dane XP są dostępne tylko dla Konta Głównego.",
  "Please switch to your Main Account to view your XP.":
    "Proszę przełączyć się na swoje Konto Główne, aby zobaczyć swoje XP.",
  "Referrals Not Available": "Referrals Not Available",
  "Referrals are available only for the Main Account.":
    "Referrals are available only for the Main Account.",
  "Please switch to your Main Account to view your Referrals.":
    "Proszę przełączyć się na swoje Konto Główne, aby zobaczyć swoje Referrals.",
  "Deposit initiated via Layerswap": "Deposit initiated via Layerswap",
  "Position History": "Position History",
  "Avg. Close Price": "Avg. Close Price",
  "Max Size": "Max Size",
  "Closed Size": "Closed Size",
  "Opened": "Opened",
  "You have no positions": "Nie masz pozycji",
  "Destination Address": "Destination Address",
  "Withdrawal via Layerswap initiated": "Withdrawal via Layerswap initiated",
  "Withdrawal via Layerswap failed": "Withdrawal via Layerswap failed",
  "Market Share": "Market Share",
  "Tier": "Tier",
  "Avg Maker Volume": "Avg Maker Volume",
  "Liquidity Score": "Liquidity Score",
  "Reward Share": "Reward Share",
  "Enter amount": "Enter amount",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount at <3 /> with an estimated profit of <4 />.":
    "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount at <3 /> with an estimated profit of <4 />.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount at <3 /> with an estimated loss of <4 />.":
    "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount at <3 /> with an estimated loss of <4 />.",
  "Limit Price": "Limit Price",
  "Delete": "Delete",
  "Use other bridges from the list above to withdraw funds much faster.":
    "Use other bridges from the list above to withdraw funds much faster.",
  "Paradex Chain Monitor": "Paradex Chain Monitor",
  "OTM Amount": "OTM Amount",
  "Leverage": "Leverage",
  "Strikes": "Strikes",
  "Strike": "Strike",
  "Change": "Change",
  "Dist. to Spot": "Dist. to Spot",
  "Bid": "Bid",
  "Mark": "Mark",
  "Ask": "Ask",
  "IV": "IV",
  "Dist %": "Dist %",
  "Call": "Call",
  "Put": "Put",
  "Mark Price / IV": "Mark Price / IV",
  "8h F-Funding": "8h F-Funding",
  "No Strikes Available": "No Strikes Available",
  "Attributes": "Attributes",
  "Why no Expiries?": "Why no Expiries?",
  "{{multiplier}}x Boost": "{{multiplier}}x Boost",
  "Like perpetual futures, perpetual options have no expiration, offering continuous exposure to the underlying asset with built-in downside protection. This gives users the flexibility to hold their position indefinitely and decide the optimal time to close it based on their strategy":
    "Like perpetual futures, perpetual options have no expiration, offering continuous exposure to the underlying asset with built-in downside protection. This gives users the flexibility to hold their position indefinitely and decide the optimal time to close it based on their strategy",
  "Implied Volatility used to calculate the option's Mark Price":
    "Implied Volatility used to calculate the option's Mark Price",
  "Distance between the Strike Price and the underlying Spot Price":
    "Distance between the Strike Price and the underlying Spot Price",
  "Eight hour funding that is continuously accrued on positions and settled upon any trade. If the funding rate is positive, longs pay shorts. If negative, shorts pay longs":
    "Eight hour funding that is continuously accrued on positions and settled upon any trade. If the funding rate is positive, longs pay shorts. If negative, shorts pay longs",
  "The Leverage Ratio (Lambda) measures an option's effective leverage by indicating how much the option's value changes, in percentage terms, for a 1% change in the spot price of the underlying asset. For example, if an option has a Lambda of 50 and the spot price increases by 1%, the option's value would increase by 50%":
    "The Leverage Ratio (Lambda) measures an option's effective leverage by indicating how much the option's value changes, in percentage terms, for a 1% change in the spot price of the underlying asset. For example, if an option has a Lambda of 50 and the spot price increases by 1%, the option's value would increase by 50%",
  "Option delta measures how much the price of an option is expected to change for a $1 change in the price of the underlying asset. For example, a delta of 0.5 means the option price will move $0.50 for every $1 move in the underlying":
    "Option delta measures how much the price of an option is expected to change for a $1 change in the price of the underlying asset. For example, a delta of 0.5 means the option price will move $0.50 for every $1 move in the underlying",
  "Eight hour funding rate of the perpetual future on the same underlying asset":
    "Eight hour funding rate of the perpetual future on the same underlying asset",
  "Having trouble? Try signing out and connecting again.":
    "Having trouble? Try signing out and connecting again.",
  "API Reference": "API Reference",
  "XP Distribution occurs once per week on Friday at ~12 PM UTC":
    "XP Distribution occurs once per week on Friday at ~12 PM UTC",
  "What are Perpetual Options?": "What are Perpetual Options?",
  "Delta represents the increase in option price based on a $1 increase in the underlying spot price.\n\nFor example, a delta of 0.50 means the price of the option is expected to increase by $0.50 for a $1 increase in the underlying spot price.":
    "Delta represents the increase in option price based on a $1 increase in the underlying spot price.\n\nFor example, a delta of 0.50 means the price of the option is expected to increase by $0.50 for a $1 increase in the underlying spot price.",
  "Leverage(Lambda) represents the percentage change in option price for a 1% change in the underlying spot price.\n\nFor example, a lambda of 10 means the option price is expected to increase by 10% if the underlying spot price increases by 1%.":
    "Leverage(Lambda) represents the percentage change in option price for a 1% change in the underlying spot price.\n\nFor example, a lambda of 10 means the option price is expected to increase by 10% if the underlying spot price increases by 1%.",
  "Eight hour options funding rate that is continuously accrued on positions and settled upon any trade. The funding rate for perpetual options reflects both the cost of leverage and the cost of insurance against adverse price moves. \n\nThe funding period of perpetual options is 24 hours. This means that the option premium will be continuously paid out over a 24h period. 24h funding rate = 3 x 8h funding rate":
    "Eight hour options funding rate that is continuously accrued on positions and settled upon any trade. The funding rate for perpetual options reflects both the cost of leverage and the cost of insurance against adverse price moves. \n\nThe funding period of perpetual options is 24 hours. This means that the option premium will be continuously paid out over a 24h period. 24h funding rate = 3 x 8h funding rate",
  "Risk": "Risk",
  "Vega": "Vega",
  "Gamma": "Gamma",
  "Estimated Funding P&L for the next 8 hours based on current funding rates":
    "Estimated Funding P&L for the next 8 hours based on current funding rates",
  "Vega represents the increase in option price based on a 1% increase in the implied volatility.\n\nFor example, a Vega of 5 means the price of the option is expected to increase by $5 for a 1% increase in the implied volatility.":
    "Vega represents the increase in option price based on a 1% increase in the implied volatility.\n\nFor example, a Vega of 5 means the price of the option is expected to increase by $5 for a 1% increase in the implied volatility.",
  "Adjust Leverage": "Adjust Leverage",
  "Leverage updated successfully": "Leverage updated successfully",
  "Failed to update leverage": "Failed to update leverage",
  "Adjust leverage parameters for selected market. Leverage will also increase on your open positions.":
    "Adjust leverage parameters for selected market. Leverage will also increase on your open positions.",
  "Please note that changing leverage will also apply for open positions and open orders on this market.":
    "Please note that changing leverage will also apply for open positions and open orders on this market.",
  "Selecting higher leverage could result in Deleveraging where some of your open orders would be cancelled.":
    "Selecting higher leverage could result in Deleveraging where some of your open orders would be cancelled.",
  "disabled": "disabled",
  "Margin Mode": "Margin Mode",
  "Manage your risk on individual positions by restricting the amount of margin allocated to each. If the margin ratio of an isolated position reaches 100%, the position will be liquidated. Margin can be added or removed to individual positions in this mode.":
    "Manage your risk on individual positions by restricting the amount of margin allocated to each. If the margin ratio of an isolated position reaches 100%, the position will be liquidated. Margin can be added or removed to individual positions in this mode.",
  "Margin mode updated successfully": "Margin mode updated successfully",
  "Failed to update margin mode": "Failed to update margin mode",
  "Favorites": "Ulubione",
  "Trending": "Popularne",
  "Losers": "Przegrani",
  "Gainers": "Zwycięzcy",
  "New Listing": "Nowe notowanie",
  "Developers": "Programiści",
  "Developer Mode": "Tryb programisty",
  "Enable developer mode": "Włącz tryb programisty",
  "For external use": "Dla użycia zewnętrznego",
  "Warning: Changing transfer behavior":
    "Ostrzeżenie: Zmiana zachowania transferu",
  "USDC will be transferred to account contract instead of Paradex. Amount won't be visible or available for use within Paradex, but can be used with external applications.":
    "USDC zostanie przeniesiony do kontraktu konta zamiast Paradex. Kwota nie będzie widoczna ani dostępna do użycia w Paradex, ale może być używana z zewnętrznymi aplikacjami.",
  "Affiliate Dashboard": "Panel Afiliata",
};

export default translations;
