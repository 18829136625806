import { logException } from '#/features/logging/logging';
import { publishNotification } from '#/features/notifications/topic';

import { getErrorMessage } from '#/utils/error';

import { reducer } from './reducer';

import type BigNumber from 'bignumber.js';
import type { Dispatch, SetStateAction } from 'react';
import type { ParaclearDepositService } from '#/features/paraclear-deposit/service';
import type { ParaclearDepositState } from '#/features/paraclear-deposit/state';
import type { WalletState } from '#/features/wallet/wallet-context';
import type { AsyncResult } from '#/utils/types';

export interface ParaclearDepositActions {
  deposit(
    transferId: string,
    amount: BigNumber,
    asset: string,
  ): AsyncResult<void>;
}

export default function createActions(
  setState: Dispatch<SetStateAction<ParaclearDepositState>>,
  walletState: WalletState,
  service: ParaclearDepositService,
): ParaclearDepositActions {
  return {
    async deposit(transferId, amount, asset) {
      if (walletState.baseAccount == null) {
        throw new Error(
          'Deposit to Paradex triggered before address is available',
        );
      }

      try {
        setState(reducer.depositStart(transferId));

        const amountFormatted = formatAmount(amount);
        publishNotification({
          kind: 'deposit-to-paraclear-in-progress',
          props: { amount: amountFormatted },
        });

        const result = await service.deposit(amount, asset);

        const txHash = result.data;

        if (!result.ok) {
          const description = 'Failed to deposit to Paradex';
          const error = new Error(description, { cause: result.error });
          logException(error, { txHash });
          const reason = `${description}: ${error.message}`;

          setState(reducer.depositFailure(transferId));
          publishNotification({
            kind: 'deposit-failed',
            props: { amount: amountFormatted },
          });

          return { ok: false, error, reason };
        }
      } catch (cause: unknown) {
        const description = 'Unknown error depositing to Paradex';
        const error = new Error(description, { cause });
        logException(error, { transferId });
        const reason = `${description}: ${getErrorMessage(cause)}`;

        setState(reducer.depositFailure(transferId));
        publishNotification({
          kind: 'deposit-unexpected-error',
          props: {},
        });

        return { ok: false, error, reason };
      }

      setState(reducer.depositSuccess(transferId));

      return { ok: true, data: undefined };
    },
  };
}

function formatAmount(amount: BigNumber): string {
  return `${amount.toFormat(2)} USDC`;
}
