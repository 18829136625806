import { UAParser } from 'ua-parser-js';

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', or 'unknown'.
 *
 */
export function getMobileOperatingSystem() {
  const { os } = UAParser(navigator.userAgent);
  if (os.name === 'Android' || os.name === 'iOS') return os.name;
  return 'unknown';
}

export const getAppViewType = () => {
  if (window.isNativeApp === true) return 'native';
  if (
    window.matchMedia('(display-mode: standalone)').matches ||
    // @ts-expect-error: Property 'standalone' does not exist on type 'Navigator'.
    window.navigator.standalone === true
  ) {
    return 'pwa';
  }
  return 'browser';
};

export const isSafariBrowser = () => {
  const { browser } = UAParser(navigator.userAgent);
  return /safari/iu.test(browser.name);
};

export const isChromeBrowser = () => {
  const { browser } = UAParser(navigator.userAgent);
  return /chrome/iu.test(browser.name);
};
