import BigNumber from 'bignumber.js';

import { msToDate } from '#/utils/date';

import { AsyncResp, BaseReq, requestApi } from './fetch-api';
import { EthereumAddress, UnixTimeMs } from './types';

export interface RawInsuranceFund {
  readonly account: EthereumAddress;
  /** USD value of the account */
  readonly account_value: string;
  /** @example 'USDC' */
  readonly settlement_asset: string;
  readonly updated_at: UnixTimeMs;
}

export interface InsuranceFund
  extends Omit<RawInsuranceFund, 'account_value' | 'updated_at'> {
  readonly account_value: BigNumber;
  readonly updated_at: Date;
}

interface GetInsuranceFundReq extends BaseReq {}

export async function getInsuranceFund(
  req: GetInsuranceFundReq,
): AsyncResp<InsuranceFund> {
  const { signal } = req;

  const resp = await requestApi<RawInsuranceFund>({
    signal,
    method: 'GET',
    url: `/insurance`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: processInsuranceFund(resp.data),
  };
}

export function processInsuranceFund(
  insuranceFund: RawInsuranceFund,
): InsuranceFund {
  return {
    ...insuranceFund,
    account_value: new BigNumber(insuranceFund.account_value),
    updated_at: msToDate(insuranceFund.updated_at),
  };
}
