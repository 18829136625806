import { useWithdrawFromVaultModalActions } from '#/features/vaults/withdraw/modal-context';

/**
 * Hook to be consumed by UI components to trigger Withdraw From Vault flow
 */
export function useWithdrawFromVaultAction() {
  const modalActions = useWithdrawFromVaultModalActions();

  const execute = () => {
    // Prevents focus fighting with the modal
    setTimeout(() => {
      modalActions.open();
    }, 0);
  };

  return { execute };
}
