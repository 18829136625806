import{useCallback}from'react';import{useDepositActions}from"../../features/deposit/deposit-context";import{formatUsdcBalance}from"../../features/deposit/deposit-functions";import{useDepositModalActions}from"../../features/deposit/modal-context";import{logException}from"../../features/logging/logging";import{publishNotification}from"../../features/notifications/topic";import{useSystemConfig}from"../../features/system/system-config-context";export function useDepositToEthereumBridge(){const formActions=useDepositActions();const modalActions=useDepositModalActions();const config=useSystemConfig();return useCallback(/**
     * Deposits the specified amount to Ethereum bridge.
     * Returns when the transaction is successfully sent.
     *
     * @param amount Amount to be deposited
     */async function depositToEthereumBridge(amount,asset){try{const token=config.getToken(asset);const depositResult=await formActions.depositToBridge(token,amount);if(!depositResult.ok)return;// Wallet will display the error
const amountFormatted=formatAmount(amount);publishNotification({kind:'deposit-request-sent',props:{amount:amountFormatted}});formActions.reset();modalActions.close();}catch(err){const message='Error depositing funds to Ethereum Bridge';logException(new Error(message,{cause:err}));}},[config,formActions,modalActions]);}export function useDepositSingleStep(){const formActions=useDepositActions();const modalActions=useDepositModalActions();const config=useSystemConfig();return useCallback(/**
     * Deposits the specified amount to user's Paradex account.
     */async function depositSingleStep(amount,asset){try{const token=config.getToken(asset);const depositResult=await formActions.depositToBridgeSingleStep(token,amount);if(!depositResult.ok)return;// Wallet will display the error
const amountFormatted=formatAmount(amount);publishNotification({kind:'deposit-request-single-step-sent',props:{amount:amountFormatted}});formActions.reset();modalActions.close();}catch(err){const message=`Error initiating single step deposit`;logException(new Error(message,{cause:err}));}},[config,formActions,modalActions]);}export function useDepositViaLayerswap(){const formActions=useDepositActions();const modalActions=useDepositModalActions();const config=useSystemConfig();return useCallback(async function depositViaLayerswap(amount,asset,chainId){try{const token=config.getToken(asset);const depositResult=await formActions.depositViaLayerswap(token,amount,chainId);if(!depositResult.ok)return;// Wallet will display the error
const amountFormatted=formatAmount(amount);publishNotification({kind:'deposit-request-layerswap-sent',props:{amount:amountFormatted}});formActions.reset();modalActions.close();}catch(err){const message='Error depositing funds to Layerswap';logException(new Error(message,{cause:err}));}},[config,formActions,modalActions]);}function formatAmount(amount){return`${formatUsdcBalance(amount)} USDC`;}