// Disabling as most of the code was copied from wagmi's mock connector
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

import { ConnectorNotConnectedError } from '@starknet-react/core';
import {
  fromHex,
  getAddress,
  keccak256,
  numberToHex,
  RpcRequestError,
  stringToHex,
  SwitchChainError,
  UserRejectedRequestError,
} from 'viem';
import { rpc } from 'viem/utils';
import { ChainNotConfiguredError, createConnector, custom } from 'wagmi';

import type {
  Address,
  EIP1193RequestFn,
  Hash,
  Hex,
  WalletCallReceipt,
  WalletRpcSchema,
} from 'viem';
import type { Transport } from 'wagmi';

interface MockParameters {
  readonly accounts: readonly [Address, ...Address[]];
  readonly features?: {
    readonly defaultConnected?: boolean | undefined;
    readonly connectError?: boolean | Error | undefined;
    readonly switchChainError?: boolean | Error | undefined;
    readonly signMessageError?: boolean | Error | undefined;
    readonly reconnect?: boolean | undefined;
    readonly watchAssetError?: boolean | Error | undefined;
    readonly signTypedData?: (params: unknown) => void;
  };
}

mock.type = 'mock' as const;

/**
 * Inspired by wagmi's mock connector.
 * @see https://github.com/wevm/wagmi/blob/1cef3dad78a1d1a128f2241012a0ce37e6588827/packages/core/src/connectors/mock.ts
 */
export function mock(parameters: MockParameters) {
  const transactionCache = new Map<Hex, Hex[]>();
  const features =
    parameters.features ??
    ({ defaultConnected: false } satisfies MockParameters['features']);

  type Provider = ReturnType<
    Transport<'custom', unknown, EIP1193RequestFn<WalletRpcSchema>>
  >;
  let connected = features.defaultConnected;
  let connectedChainId: number;

  return createConnector<Provider>((config) => ({
    id: 'mock',
    name: 'Mock Connector',
    type: mock.type,
    async setup() {
      connectedChainId = config.chains[0].id;
    },
    async connect({ chainId } = {}) {
      if (features.connectError) {
        if (typeof features.connectError === 'boolean')
          throw new UserRejectedRequestError(new Error('Failed to connect.'));
        throw features.connectError;
      }

      const provider = await this.getProvider();
      const accounts = await provider.request({
        method: 'eth_requestAccounts',
      });

      let currentChainId = await this.getChainId();
      if (chainId && currentChainId !== chainId) {
        const chain = await this.switchChain!({ chainId });
        currentChainId = chain.id;
      }

      connected = true;

      return {
        accounts: accounts.map((x) => getAddress(x)),
        chainId: currentChainId,
      };
    },
    async disconnect() {
      connected = false;
    },
    async getAccounts() {
      if (!connected) throw new ConnectorNotConnectedError();
      const provider = await this.getProvider();
      const accounts = await provider.request({ method: 'eth_accounts' });
      return accounts.map((x) => getAddress(x));
    },
    async getChainId() {
      const provider = await this.getProvider();
      const hexChainId = await provider.request({ method: 'eth_chainId' });
      return fromHex(hexChainId, 'number');
    },
    async isAuthorized() {
      if (!features.reconnect) return false;
      if (!connected) return false;
      const accounts = await this.getAccounts();
      return Boolean(accounts.length);
    },
    async switchChain({ chainId }) {
      const provider = await this.getProvider();
      const chain = config.chains.find((x) => x.id === chainId);
      if (!chain) throw new SwitchChainError(new ChainNotConfiguredError());

      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: numberToHex(chainId) }],
      });
      return chain;
    },
    onAccountsChanged(accounts) {
      if (accounts.length === 0) this.onDisconnect();
      else
        config.emitter.emit('change', {
          accounts: accounts.map((x) => getAddress(x)),
        });
    },
    onChainChanged(chain) {
      const chainId = Number(chain);
      config.emitter.emit('change', { chainId });
    },
    async onDisconnect(_error) {
      config.emitter.emit('disconnect');
      connected = false;
    },
    async getProvider({ chainId } = {}) {
      const chain =
        config.chains.find((x) => x.id === chainId) ?? config.chains[0];
      const url = chain.rpcUrls.default.http[0]!;

      const request: EIP1193RequestFn = async ({ method, params }) => {
        // eth methods
        if (method === 'eth_chainId') return numberToHex(connectedChainId);
        if (method === 'eth_accounts') return parameters.accounts;
        if (method === 'eth_requestAccounts') return parameters.accounts;
        if (method === 'eth_signTypedData_v4')
          return features.signTypedData?.(params);

        // wallet methods
        if (method === 'wallet_switchEthereumChain') {
          if (features.switchChainError) {
            if (typeof features.switchChainError === 'boolean')
              throw new UserRejectedRequestError(
                new Error('Failed to switch chain.'),
              );
            throw features.switchChainError;
          }
          type Params = [{ chainId: Hex }];
          connectedChainId = fromHex((params as Params)[0].chainId, 'number');
          this.onChainChanged(connectedChainId.toString());
          return;
        }

        if (method === 'wallet_watchAsset') {
          if (features.watchAssetError) {
            if (typeof features.watchAssetError === 'boolean')
              throw new UserRejectedRequestError(
                new Error('Failed to switch chain.'),
              );
            throw features.watchAssetError;
          }
          return connected;
        }

        if (method === 'wallet_getCapabilities')
          return {
            '0x2105': {
              paymasterService: {
                supported:
                  (params as [Hex])[0] ===
                  '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266',
              },
              sessionKeys: {
                supported: true,
              },
            },
            '0x14A34': {
              paymasterService: {
                supported:
                  (params as [Hex])[0] ===
                  '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266',
              },
            },
          };

        if (method === 'wallet_sendCalls') {
          const hashes = [];
          const { calls } = (params as unknown as { calls: unknown[] }[])[0]!;
          for (const call of calls) {
            const { result, error } = await rpc.http(url, {
              body: {
                method: 'eth_sendTransaction',
                params: [call],
              },
            });
            if (error)
              throw new RpcRequestError({
                body: { method, params },
                error,
                url,
              });
            hashes.push(result);
          }
          const id = keccak256(stringToHex(JSON.stringify(calls)));
          transactionCache.set(id, hashes);
          return id;
        }

        if (method === 'wallet_getCallsStatus') {
          const hashes = transactionCache.get(
            (params as unknown as { calls: Hash }[])[0]!.calls,
          );
          if (!hashes) return null;
          const receipts = await Promise.all(
            hashes.map(async (hash) => {
              const { result, error } = await rpc.http(url, {
                body: {
                  method: 'eth_getTransactionReceipt',
                  params: [hash],
                  id: 0,
                },
              });
              if (error)
                throw new RpcRequestError({
                  body: { method, params },
                  error,
                  url,
                });
              if (!result) return null;
              return {
                blockHash: result.blockHash,
                blockNumber: result.blockNumber,
                gasUsed: result.gasUsed,
                logs: result.logs,
                status: result.status,
                transactionHash: result.transactionHash,
              } satisfies WalletCallReceipt;
            }),
          );
          if (receipts.some((x) => !x))
            return { status: 'PENDING', receipts: [] };
          return { status: 'CONFIRMED', receipts };
        }

        if (method === 'wallet_showCallsStatus') return;

        // other methods
        if (method === 'personal_sign') {
          if (features.signMessageError) {
            if (typeof features.signMessageError === 'boolean')
              throw new UserRejectedRequestError(
                new Error('Failed to sign message.'),
              );
            throw features.signMessageError;
          }
          // Change `personal_sign` to `eth_sign` and swap params
          method = 'eth_sign';
          type Params = [data: Hex, address: Address];
          params = [(params as Params)[1], (params as Params)[0]];
        }

        const body = { method, params };
        const { error, result } = await rpc.http(url, { body });
        if (error) throw new RpcRequestError({ body, error, url });

        return result;
      };
      return custom({ request })({ retryCount: 0 });
    },
  }));
}
