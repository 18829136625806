const SETTINGS = {
  ethereum: {
    explorerUrl: "https://etherscan.io/",
  },

  tokens: {
    USDC: {
      scale: 2,
    },
  },

  fiat: {
    USD: {
      scale: 2,
    },
  },
};

export default SETTINGS;
