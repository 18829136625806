import BigNumber from 'bignumber.js';
import { milliseconds } from 'date-fns';
import * as Starknet from 'starknet';
import { erc20Abi, hexToBigInt, parseEther, parseGwei } from 'viem';
import { sendTransaction } from 'wagmi/actions';

import L1_ERC20_BRIDGE_ABI from '#/features/abis/L1_ERC20_BRIDGE_ABI';
import { logException } from '#/features/logging/logging';
import { BridgedToken } from '#/features/paraclear';
import { SystemConfigView } from '#/features/system/system-config-context';
import EthereumWallet, {
  EthereumAddress,
} from '#/features/wallets/ethereum/wallet';
import { ParaclearProvider } from '#/features/wallets/paraclear/provider';
import ParaclearWallet from '#/features/wallets/paraclear/wallet';

import { toQuantums } from '#/utils/quantums';

import type { Hex } from 'viem';
import type { Config as WagmiConfig } from 'wagmi';

export async function readAllowance(
  token: BridgedToken,
  ethereumAddress: EthereumAddress,
  wagmiConfig: WagmiConfig,
): Promise<BigNumber> {
  const owner = ethereumAddress;
  const spender = token.l1BridgeAddress;
  const result = await EthereumWallet.readContract(wagmiConfig, {
    address: token.l1TokenAddress,
    abi: erc20Abi,
    functionName: 'allowance',
    args: [owner, spender],
    blockTag: 'latest',
  });

  const allowance = BigNumber(result.toString());
  if (!allowance.isFinite()) {
    throw new TypeError(
      'Result of ERC20 Request Allowance is not a numeric value',
    );
  }

  return allowance;
}

export async function requestAllowanceIncrease(
  token: BridgedToken,
  ethereumAddress: EthereumAddress,
  amount: string,
  wagmiConfig: WagmiConfig,
): Promise<{ hash: string }> {
  const spender = token.l1BridgeAddress;
  const value = toQuantums(amount, token.decimals).toString();

  const { request } = await EthereumWallet.simulateContract(wagmiConfig, {
    address: token.l1TokenAddress,
    abi: erc20Abi,
    functionName: 'approve',
    args: [spender, BigInt(value)],
    /* the account to send the transaction from. */
    account: ethereumAddress,
  });

  const hash = await EthereumWallet.writeContract(wagmiConfig, request);
  // WIP: setup global default timeout for `waitForTransaction` inside EthereumWallet implementation?
  await EthereumWallet.waitForTransaction(wagmiConfig, {
    hash,
    timeout: milliseconds({ minutes: 2 }),
  });

  return { hash };
}

const DEFAULT_DEPOSIT_FEE_WEI = parseGwei('2000');

export async function requestDeposit(
  amountBn: BigNumber,
  ethereumAddress: EthereumAddress,
  starknetAddress: string,
  token: BridgedToken,
  paraclearProvider: ParaclearProvider,
  wagmiConfig: WagmiConfig,
) {
  const amount = toQuantums(amountBn, token.decimals);
  const amountUint256 = Starknet.uint256.bnToUint256(amount);
  const l2Recipient = starknetAddress as `0x${string}`;
  const l1Depositor = ethereumAddress;

  const l2BridgeVersion = await ParaclearWallet.getL2BridgeVersion(
    paraclearProvider,
    token,
  );

  let feeEstimate;
  try {
    feeEstimate = await (async () => {
      switch (l2BridgeVersion) {
        case 1:
          return paraclearProvider.estimateMessageFee({
            from_address: token.l1BridgeAddress,
            to_address: token.l2BridgeAddress,
            entry_point_selector: 'handle_deposit',
            payload: [
              l2Recipient,
              amountUint256.low.toString(),
              amountUint256.high.toString(),
            ],
          });
        case 2:
          return paraclearProvider.estimateMessageFee({
            from_address: token.l1BridgeAddress,
            to_address: token.l2BridgeAddress,
            entry_point_selector: 'handle_token_deposit',
            payload: [
              token.l1TokenAddress,
              l1Depositor,
              l2Recipient,
              amountUint256.low.toString(),
              amountUint256.high.toString(),
            ],
          });
        // no default
      }
    })();
  } catch (cause) {
    const message = `Falling back to the default fee of WEI='${DEFAULT_DEPOSIT_FEE_WEI}'. Failed to estimate message fee for deposit to Ethereum Bridge.`;
    logException(new Error(message, { cause }));
    feeEstimate = { overall_fee: DEFAULT_DEPOSIT_FEE_WEI };
  }

  const { request } = await EthereumWallet.simulateContract(wagmiConfig, {
    address: token.l1BridgeAddress,
    abi: L1_ERC20_BRIDGE_ABI,
    functionName: 'deposit',
    args: [BigInt(amount), hexToBigInt(l2Recipient)],
    value: BigInt(feeEstimate.overall_fee),
    /* the account to send the transaction from. */
    account: ethereumAddress,
  });

  return EthereumWallet.writeContract(wagmiConfig, request);
}

export async function requestDepositSingleStep(
  amountBn: BigNumber,
  ethereumAddress: EthereumAddress,
  starknetAddress: string,
  token: BridgedToken,
  paraclearProvider: ParaclearProvider,
  systemConfig: SystemConfigView,
  wagmiConfig: WagmiConfig,
) {
  const amount = toQuantums(amountBn, token.decimals);
  const amountUint256 = Starknet.uint256.bnToUint256(amount);
  const l2Recipient = starknetAddress as `0x${string}`;
  const l1Depositor = ethereumAddress;
  const l2ParaclearAddress = systemConfig.paraclear.address as `0x${string}`;

  let feeEstimate;
  try {
    feeEstimate = await paraclearProvider.estimateMessageFee({
      from_address: token.l1BridgeAddress,
      to_address: token.l2BridgeAddress,
      entry_point_selector: 'handle_deposit_with_message',
      payload: [
        token.l1TokenAddress,
        l1Depositor,
        l2ParaclearAddress,
        amountUint256.low.toString(),
        amountUint256.high.toString(),
        '0x1', // Message length
        l2Recipient,
      ],
    });
  } catch (cause) {
    const message = `Falling back to the default fee of WEI='${DEFAULT_DEPOSIT_FEE_WEI}'. Failed to estimate message fee for Single Step deposit.`;
    logException(new Error(message, { cause }));
    feeEstimate = { overall_fee: DEFAULT_DEPOSIT_FEE_WEI };
  }

  const { request } = await EthereumWallet.simulateContract(wagmiConfig, {
    address: token.l1BridgeAddress,
    abi: L1_ERC20_BRIDGE_ABI,
    functionName: 'depositWithMessage',
    args: [
      token.l1TokenAddress,
      BigInt(amount),
      hexToBigInt(l2ParaclearAddress),
      [hexToBigInt(l2Recipient)],
    ],
    value: BigInt(feeEstimate.overall_fee),
    /* the account to send the transaction from. */
    account: ethereumAddress,
  });

  return EthereumWallet.writeContract(wagmiConfig, request);
}

export async function requestDepositLayerswap(
  wagmiConfig: WagmiConfig,
  to: EthereumAddress,
  data: Hex,
) {
  const value = parseEther('0');
  return sendTransaction(wagmiConfig, { to, data, value });
}
