import { SocializedLossStore } from './store';

export interface SocializedLossView {
  readonly isActive: boolean;
}

export function createView(store: SocializedLossStore): SocializedLossView {
  const isActive = isActiveView(store);

  return { isActive };
}

function isActiveView(store: SocializedLossStore): boolean {
  const value = store.get();
  if (value === null) return false;
  return value.isGreaterThan(0);
}
