import { hdkey } from 'ethereumjs-wallet';
import * as Starknet from 'starknet';

import { logEvent } from '#/features/logging/logging';
import { BaseAccount } from '#/features/wallet/common/base-account';
import { normPrivKey } from '#/features/wallets/paraclear/utils/normalizedPrivateKey';

export type StarknetKeypair = [string, string];

export async function getStarknetKeypairFromSignature(
  signature: string,
  type: BaseAccount['type'],
): Promise<StarknetKeypair> {
  switch (type) {
    case 'starknet':
      return getStarknetKeypairFromStarknetSignature(signature);
    case 'ethereum':
      return getStarknetKeypairFromEthSignature(signature);
    default:
      throw new Error(`Unsupported base account type`);
  }
}

export async function getStarknetKeypairFromEthSignature(
  signature: string,
): Promise<StarknetKeypair> {
  const sn = await import('@starkware-industries/starkware-crypto-utils');
  const privateKey = sn.keyDerivation.getPrivateKeyFromEthSignature(signature);
  const publicKey = sn.keyDerivation.privateToStarkKey(privateKey);
  return [privateKey, publicKey];
}

/**
 * Adapted from `starkware-crypto-utils`
 * @source https://github.com/starkware-libs/starkware-crypto-utils/blob/v0.2.1/src/js/key_derivation.ts#L77-L92
 */
export async function getSubAccountStarknetKeypair(
  parentAccountPrivateKey: string,
  derivationPath: string,
): Promise<{
  privateKey: string;
  publicKey: string;
  derivationPath: string;
}> {
  logEvent(
    `Attempt to derive sub-account key pair from derivationPath=${derivationPath}`,
    { derivationPath },
  );

  const sn = await import('@starkware-industries/starkware-crypto-utils');
  const curve = sn.keyDerivation.StarkExEc;
  if (curve == null) {
    throw new Error('StarkExEc curve is not defined');
  }

  const normalizedPrivateKey = normPrivKey(parentAccountPrivateKey);
  /** @see {@link https://github.com/paulmillr/noble-curves/blob/1.4.2/src/abstract/weierstrass.ts#L854-L860 | isValidPrivateKey } */
  if (!Starknet.ec.starkCurve.utils.isValidPrivateKey(normalizedPrivateKey)) {
    throw new Error(`Invalid parent account private key provided`);
  }

  const keySeed = hdkey
    .fromMasterSeed(Buffer.from(normalizedPrivateKey, 'hex'))
    .derivePath(derivationPath)
    .getWallet()
    .getPrivateKeyString();
  const privateKey = sn.keyDerivation.grindKey(keySeed, curve);
  const publicKey = sn.keyDerivation.privateToStarkKey(privateKey);

  return { privateKey, publicKey, derivationPath };
}

// This function borrows from starkware-crypto-utils's implementation
// of `getPrivateKeyFromEthSignature()`. Where a deterministic
// signature R segment, is hex encoded as the `keySeed` for
// `grindKey()` along the Stark curve.
export async function getStarknetKeypairFromStarknetSignature(
  signatureR: string,
): Promise<StarknetKeypair> {
  const sn = await import('@starkware-industries/starkware-crypto-utils');
  const curve = sn.keyDerivation.StarkExEc;
  if (curve == null) {
    throw new Error('StarkExEc curve is not defined');
  }
  const r = signatureR.replace(/^0x/u, '');
  const privateKey = sn.keyDerivation.grindKey(r, curve);
  const publicKey = sn.keyDerivation.privateToStarkKey(privateKey);
  return [privateKey, publicKey];
}
