import { SocializedLossState } from './state';

export interface SocializedLossStore {
  readonly status: SocializedLossState['status'];
  readonly error: SocializedLossState['error'];
  readonly get: () => SocializedLossState['socializedLoss'];
}

export function createStore(state: SocializedLossState): SocializedLossStore {
  return {
    status: state.status,
    error: state.error,
    get: () => state.socializedLoss,
  };
}
