import BigNumber from 'bignumber.js';

import { msToDate } from '#/utils/date';
import getQueryString from '#/utils/getQueryString';

import {
  AsyncResp,
  BasePaginatedReq,
  PaginatedRespData,
  requestApi,
} from './fetch-api';
import { Market } from './markets';
import { UnixTimeMs } from './types';

export interface RawFundingPayment {
  readonly id: string;
  readonly market: string;
  readonly created_at: UnixTimeMs;
  /** @example '0.0441342' */
  readonly payment: string;
  /** @example '0.0001' */
  readonly index: string;
  readonly fill_id?: string;
  readonly account: string;
}

export interface FundingPayment
  extends Omit<RawFundingPayment, 'created_at' | 'payment' | 'index'> {
  readonly created_at: Date;
  readonly payment: BigNumber;
  readonly index: BigNumber;
}

interface FundingPaymentsReq extends BasePaginatedReq {
  readonly market?: Market['symbol'];
  readonly start_at?: UnixTimeMs;
}

export interface RawFundingPaymentsResp
  extends PaginatedRespData<RawFundingPayment> {}

export interface FundingPaymentsResp
  extends PaginatedRespData<FundingPayment> {}

export async function getFundingPayments(
  req: FundingPaymentsReq,
): AsyncResp<FundingPaymentsResp> {
  const { signal, cursor, page_size, market, start_at } = req;

  const query = getQueryString([
    ['market', market],
    ['cursor', cursor],
    ['page_size', page_size],
    ['start_at', start_at],
  ]);

  const resp = await requestApi<RawFundingPaymentsResp>({
    signal,
    method: 'GET',
    url: `/funding/payments${query}`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: {
      ...resp.data,
      results: resp.data.results.map(processFundingPayment),
    },
  };
}

export function processFundingPayment(
  fundingPayment: RawFundingPayment,
): FundingPayment {
  return {
    ...fundingPayment,
    created_at: msToDate(fundingPayment.created_at),
    payment: new BigNumber(fundingPayment.payment),
    index: new BigNumber(fundingPayment.index),
  };
}
