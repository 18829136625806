import { Keys } from "./en";

const translations: Record<Keys, string> = {
  "Display trade history on chart":
    "Mostrar historial de transacciones en el gráfico",
  "Add markets to Favorites in the Markets Browser":
    "Agregue mercados a Favoritos en el navegador de mercados",
  "Log in": "Iniciar sesión",
  "Export embedded wallet": "Exportar billetera integrada",
  "Email or Social": "Correo electrónico o Social",
  "Failed to load balances": "Error al cargar los saldos",
  "No balances": "Sin saldos",
  "Verified Operator": "Operador Verificado",
  "Strategy": "Estrategia",
  "Multi-Strategy": "Multi-Estrategia",
  "Master Vault": "Bóveda Principal",
  "Master Vault Details": "Detalles de la Bóveda Principal",
  "Annualized": "Anualizado",
  "Depositors": "Depositantes",
  "No trade history": "Sin historial de transacciones",
  "Unwinding Progress": "Progreso de Liquidación",
  "What are Vaults?": "¿Qué son las Bóvedas?",
  "APR calculated as the Annualised 30-day Return":
    "APR calculado como la rentabilidad anualizada de 30 días",
  "APR": "APR",
  "Initializing": "Inicializando",
  "Awaiting Deposit": "Esperando Depósito",
  "Vault Age": "Edad de la Bóveda",
  "30D Return": "Rendimiento a 30D",
  "{{field}} must be greater than the minimum initial deposit of {{minInitialDeposit}}":
    "{{field}} debe ser mayor que el depósito mínimo inicial de {{minInitialDeposit}}",
  "Minimum deposit": "Depósito mínimo",
  "Withdrawal amount exceeds available vault shares":
    "La cantidad de retiro excede los fondos disponibles de la bóveda",
  "Deposit would exceed maximum vault TVL of {{maxTVL}}":
    "El depósito excederá el TVL máximo de la bóveda de {{maxTVL}}",
  "{{field}} is required": "{{field}} es requerido",
  "Lockup Remaining": "Restante del bloqueo",
  "{{days}}D": "{{days}}D",
  "<1>{{days}}</1> D": "<1>{{days}}</1> D",
  "<1>{{hours}}</1> H": "<1>{{hours}}</1> H",
  "<1>{{minutes}}</1> M": "<1>{{minutes}}</1> M",
  "Vault settings submitted": "Configuración de la bóveda enviada",
  "Vault settings updated": "Configuración de la bóveda actualizada",
  "Vault Settings": "Configuración de la Bóveda",
  "Vault not found": "Bóveda no encontrada",
  "The owner receives a {{profitShare}} profit share.":
    "El propietario recibe un {{profitShare}} de los beneficios.",
  "This vault is entirely owned by the community.":
    "Esta bóveda es totalmente propiedad de la comunidad.",
  "Withdraw from Vault submitted": "Retiro de bóveda enviado",
  "Deposit to Vault submitted": "Depósito en la Bóveda enviado",
  "Unknown error. Try again.": "Error desconocido. Intente nuevamente.",
  "Vault Deposit": "Depósito de bóveda",
  "Vault Withdrawal": "Retiro de bóveda",
  "No Lockup": "Sin bloqueo",
  "Lockup period is not over yet":
    "El período de bloqueo no ha terminado todavía",
  "Protocol": "Protocolo",
  "24H": "24H",
  "7D": "7D",
  "30D": "30D",
  "All": "Todo",
  "You have no investments in vaults": "No tienes inversiones en bóvedas",
  "Total Value": "Valor Total",
  "Investments Made": "Inversiones Realizadas",
  "Operator": "Operador",
  "Manage Vault": "Administrar Bóveda",
  "Switch to the operator account": "Cambiar a la cuenta del operador",
  "Active": "Activas",
  "My Vaults": "Mis Bóvedas",
  "Closed": "Cerradas",
  "Deposited": "Depositadas",
  "Lifetime ROI": "ROI Total",
  "Owners Equity": "Capital del Propietario",
  "Top Trending": "Más Populares",
  "Owner Share": "Participación del Propietario",
  "Vault Details": "Detalles de la Bóveda",
  "Contract": "Contrato",
  "Withdraw from Vault": "Retirar de la Bóveda",
  "Withdraw from Vault successful": "Retiro de la Bóveda exitoso",
  "Failed to withdraw from Vault": "Fallo al retirar de la Bóveda",
  "Name": "Nombre",
  "Deposit": "Depósito",
  "Tokens": "Tokens",
  "Avg. Entry Price": "Precio Promedio de Entrada",
  "Current Price": "Precio Actual",
  "P&L": "Ganancias y Pérdidas",
  "My Vault Deposit": "Mi Depósito de Bóveda",
  "Vaults": "Bóvedas",
  "Create": "Crear",
  "Create My Vault": "Crear Mi Bóveda",
  "Vault Name": "Nombre de la Bóveda",
  "Vault Description": "Descripción de la Bóveda",
  "Token Symbol": "Símbolo del Token",
  "Deposit Amount": "Cantidad de Depósito",
  "Vault description will be visible publicly and you will not be able to edit this later.":
    "La descripción de la bóveda será visible públicamente y no podrás editarla más tarde.",
  "Once created, deposit at least {{minDeposit}} USDC to activate the vault. At this moment, only the Max TVL can be changed later.":
    "Una vez creada, deposita al menos {{minDeposit}} USDC para activar la bóveda. En este momento, solo se puede cambiar el TVL máximo más adelante.",
  "Create Vault": "Crear Bóveda",
  "Profit Share": "Reparto de Beneficios",
  "Lockup Period (Days)": "Período de Bloqueo (Días)",
  "Vault was successfully created and is being initialized":
    "La bóveda se creó con éxito y se está inicializando",
  "Failed to create a vault": "Fallo al crear una bóveda",
  "Maximum TVL": "TVL Máximo",
  "No limit": "Sin límite",
  "Vault Address": "Dirección de la Bóveda",
  "Deposit to Vault successful": "Depósito en la Bóveda exitoso",
  "Failed to deposit to Vault": "Fallo al depositar en la Bóveda",
  "Deposit to Vault": "Depositar en la Bóveda",
  "Vault": "Bóveda",
  "Owner": "Propietario",
  "Age": "Edad",
  "Failed to load vaults": "Fallo al cargar las bóvedas",
  "No vaults available": "No hay bóvedas disponibles",
  "Performance": "Rendimiento",
  "Vault Value": "Valor de la Bóveda",
  "My Deposit": "Mi Depósito",
  "Total P&L": "Ganancias y Pérdidas Totales",
  "Protocol Vaults": "Bóvedas del Protocolo",
  "User Vaults": "Bóvedas de Usuario",
  "No Data": "Sin Datos",
  "Last Month Return": "Rendimiento del Último Mes",
  "TVL": "TVL",
  "Total Earnings": "Ganancias Totales",
  "About": "Acerca de",
  "Vault Stats": "Estadísticas de la Bóveda",
  "My Stats": "Mis Estadísticas",
  "Vault Contract": "Contrato de la Bóveda",
  "Description": "Descripción",
  "Max Drawdown": "Máximo Drawdown",
  "Volume": "Volumen",
  "All-Time P&L": "Ganancias y Pérdidas de Todos los Tiempos",
  "Days": "Días",
  "Lockup Period": "Período de Bloqueo",
  "Search Vaults": "Buscar Bóvedas",
  "Blog": "Blog",
  "Block Explorer": "Explorador de Bloques",
  "Close Vault": "Cerrar Bóveda",
  "Close Vault successful": "Cierre de Bóveda exitoso",
  "You must close all open positions to close your Vault. Closed vaults cannot be reopened.":
    "Debes cerrar todas las posiciones abiertas para cerrar tu Bóveda. Las bóvedas cerradas no se pueden reabrir.",
  "Close My Vault": "Cerrar Mi Bóveda",
  "Failed to Close the Vault": "Fallo al Cerrar la Bóveda",
  "Only the Main Account can manage the vault":
    "Solo la Cuenta Principal puede administrar la bóveda",
  "Allocate Collateral": "Asignar Colateral",
  "Switch to Main Account": "Cambiar a la Cuenta Principal",
  "Switch to Account": "Cambiar a la Cuenta",
  "You are currently trading in the {{market}} market.":
    "Actualmente estás operando en el mercado de {{market}}.",
  "To start trading on Isolated Market you must Allocate Collateral from your Main Account and switch to this Market Account.":
    "Para comenzar a operar en el Mercado Aislado, debes Asignar Colateral desde tu Cuenta Principal y cambiar a esta Cuenta de Mercado.",
  "To trade in this market, switch to the Main Account first.":
    "Para operar en este mercado, cambia primero a la Cuenta Principal.",
  "This account can only trade in the {{market}} market.":
    "Esta cuenta solo puede operar en el mercado de {{market}}.",
  "Isolated Market": "Mercado Aislado",
  "This feature currently is still in Beta. Backup your Paradex Private Key for additional safety.":
    "Esta función todavía está en Beta. Haga una copia de seguridad de su Clave Privada de Paradex para mayor seguridad.",
  "Deposit failed": "Depósito fallido",
  "Deposit in progress": "Depósito en progreso",
  "External Account": "Cuenta externa",
  "Tx Hashes": "Hashes de transacciones",
  "Continue to {{bridge}}": "Continuar a {{bridge}}",
  "By clicking Continue to {{bridge}}, you will be sent to {{bridge}} website to bridge your funds out of Paradex.":
    "Al hacer clic en Continuar a {{bridge}}, se le enviará al sitio web de {{bridge}} para transferir sus fondos fuera de Paradex.",
  "By clicking Continue to {{bridge}}, you will be sent to {{bridge}} website to bridge your funds to Paradex.":
    "Al hacer clic en Continuar a {{bridge}}, se le enviará al sitio web de {{bridge}} para transferir sus fondos a Paradex.",
  "Refer": "Referir",
  "Address": "Dirección",
  "Date Joined": "Fecha de registro",
  "Referral Code": "Código de referencia",
  "Volume Traded": "Volumen negociado",
  "Bridge": "Puente",
  "Withdraw to": "Retirar a",
  "Deposit from": "Depósito desde",
  "Position is already closed": "La posición ya está cerrada",
  "Your Paradex L2 Address is NOT a Public Starknet Address. If you try to send funds to this address on Public Starknet via a cross chain bridge you risk losing your funds. <1>Read more</1>.":
    "Tu dirección de Paradex L2 NO es una dirección Starknet pública. Si intentas enviar fondos a esta dirección en la Starknet pública a través de un puente de cadena cruzada, corres el riesgo de perder tus fondos. <1>Leer más</1>.",
  "{{chain}} Address": "Dirección de {{chain}}",
  "Copy Paradex Private Key": "Copiar Clave Privada de Paradex",
  "Your account will be liquidated if Margin Ratio reaches 100%":
    "Tu cuenta será liquidada si el Ratio de Margen alcanza el 100%",
  "Liquidation": "Liquidación",
  "Fee": "Tarifa",
  "Maker": "Creador",
  "Maker Score": "Puntuación de Creador",
  "Maker XP Share": "Porcentaje de XP de Creador",
  "Fee XP Share": "Porcentaje de XP de Tarifa",
  "Fee XP": "XP de Tarifa",
  "Maker XP": "XP de Creador",
  "Liquidation {{side}} Fill": "Liquidación {{side}} Llenado",
  "Settings": "Ajustes",
  "Market Score": "Puntuación de Mercado",
  "Season {{season}} (coming soon)": "Temporada {{season}} (próximamente)",
  "Confirm Buy": "Confirmar Compra",
  "Confirm Sell": "Confirmar Venta",
  "Other XP": "Otros XP",
  "Estimated Receivable Amount": "Cantidad estimada a recibir",
  "Socialized Loss active. Click to learn more.":
    "Pérdida socializada activa. Haz clic para obtener más información.",
  "Analytics": "Analítica",
  "Sign In to see your account XP stats":
    "Inicia sesión para ver las estadísticas de tus XP de cuenta",
  "<1>Clock Is Out Of Sync</1> The clock on your device is out of sync. This may cause issues with the application. Please make sure your device clock is set to be auto. For more details please <2>see documentation</2>":
    "<1>El reloj está fuera de sincronización</1> El reloj de su dispositivo está fuera de sincronización. Esto puede causar problemas con la aplicación. Por favor, asegúrese de que el reloj de su dispositivo esté configurado para ser automático. Para más detalles, por favor <2>consulte la documentación</2>",
  "Failed to fetch max withdrawable amount":
    "Error al obtener la cantidad máxima de retiro",
  "It is not possible to withdraw more than your Max Withdrawable Amount.":
    "No es posible retirar más de su Cantidad Máxima de Retiro.",
  "Max Withdrawable Amount": "Cantidad Máxima de Retiro",
  "XP Explainer": "Explicador de XP",
  "Pool": "Nivel",
  "Quote Quality": "Calidad de la cotización",
  "Create Your Public Username": "Crear su nombre de usuario público",
  "Edit Your Public Username": "Editar su nombre de usuario público",
  "You have been logged out.": "Has cerrado sesión.",
  "Maker Volume Score": "Volumen Del Maker Score",
  "Fee Score": "Puntuación de Comisiones",
  "Score Share": "Porcentaje de Puntuación",
  "Instrument Share": "Porcentaje de Instrumento",
  "XP": "XP",
  "Total XP": "XP Totales",
  "24h Change": "Cambio en 24h",
  "Leaderboard": "Tabla de clasificación",
  "Cancel": "Cancelar",
  "Portfolio": "Portafolio",
  "Trade": "Comercio",
  "Build": "Construir",
  "Referrals Explainer": "Explicador de Referencias",
  "How It Works": "Cómo funciona",
  "My Referrals": "Mis referencias",
  "Your Referral Code": "Su código de referencia",
  "Affiliate Program": "Programa de afiliados",
  "Enrolled": "Inscrito",
  "Traders Referred": "Operadores referidos",
  "Referral Rewards": "Recompensas de referencia",
  "Referral XP": "XP de referencia",
  "Invite Friends": "Invitar amigos",
  "Share": "Compartir",
  "Earn": "Ganar",
  "Give": "Dar",
  "Share your code to invite friends to join Paradex":
    "Comparta su código para invitar a amigos a unirse a Paradex",
  "<1>{{amount}}</1> Fees": "<1>{{amount}}</1> de tarifas",
  "You receive {{amount}} of the referred user's fees":
    "Recibe el {{amount}} de las tarifas del usuario referido",
  "<1>{{amount}}</1> XP": "<1>{{amount}}</1> de XP",
  "You receive {{amount}} of the referred user's XP":
    "Recibe el {{amount}} de los XP del usuario referido",
  "<1>{{amount}}</1> Discount": "<1>{{amount}}</1> de descuento",
  "Your friend receives {{amount}} discount on fees":
    "Su amigo recibe un {{amount}} de descuento en las tarifas",
  "Create and share your custom referral code":
    "Cree y comparta su código de referencia personalizado",
  "If you have a strong social media following (<1>{{followers}}+</1>), you may be eligible for our <2>VIP Affiliate Program</2> with higher commissions and XP.":
    "Si tienes una fuerte presencia en redes sociales (<1>{{followers}}+</1>), podrías ser elegible para nuestro programa de afiliados VIP con comisiones y XP más altos.",
  "You can track our <1>Affiliate Leaderboard</1> to see how you stack up.":
    "Puedes ver nuestra <1>Tabla de Clasificación de Afiliados</1> para ver cómo te comparas.",
  "Apply": "Aplicar",
  "Log in to see your Referral History.":
    "Inicia sesión para ver tu historial de referencias.",
  "Welcome to Paradex": "Bienvenido a Paradex",
  "Congrats! The referral code you used is now active! It means you can start enjoying a discount on your trading fees!":
    "¡Felicidades! ¡El código de referencia que utilizaste ahora está activo! ¡Significa que puedes comenzar a disfrutar de un descuento en tus tarifas de negociación!",
  "Congrats! The referral code <1>{{codeProvided}}</1> used is now active!":
    "¡Felicidades! ¡El código de referencia <1>{{codeProvided}}</1> utilizado ahora está activo!",
  "Referral code not activated. The code can only be applied once during the initial onboarding.":
    "Código de referencia no activado. El código solo se puede aplicar una vez durante el embarque inicial.",
  "Referral code <1>{{codeProvided}}</1> not activated. You have already onboarded with <1>{{referredBy}}</1>.":
    "Código de referencia <1>{{codeProvided}}</1> no activado. Ya te has embarcado con <1>{{referredBy}}</1>.",
  "Referral Code Applied": "Código de referencia aplicado",
  "You will receive <1>{{userAmount}}</1> of referred user's <1>fees</1> <2>and</2> <1>{{userAmountPoints}} XP</1>. They will receive a <1>{{friendAmount}}</1> discount.":
    "Recibirás <1>{{userAmount}}</1> de las <1>tarifas</1> del usuario referido <2>y</2> <1>{{userAmountPoints}} XP</1>. Ellos recibirán un <1>{{friendAmount}}</1> de descuento.",
  "Community": "Comunidad",
  "Environments": "Ambientes",
  "System Status": "Estado del Sistema",
  "Language": "Idioma",
  "Docs": "Documentos",
  "Code Samples": "Muestras de Código",
  "More": "Más",
  "Stats": "Estadísticas",
  "Join Discord": "Unirse a Discord",
  "Notifications": "Notificaciones",
  "All caught up!": "¡Todo al día!",
  "You have no new notifications": "No tienes nuevas notificaciones",
  "Connect Wallet": "Conectar Billetera",
  "WalletConnect is taking too long to open. Please refresh the page and try again.":
    "WalletConnect está tardando demasiado en abrirse. Por favor, actualiza la página e inténtalo de nuevo.",
  "Unexpected error opening WalletConnect modal. Please refresh the page and try again.":
    "Error inesperado al abrir el modal de WalletConnect. Por favor, actualiza la página e inténtalo de nuevo.",
  "Connect your Wallet": "Conecta tu Billetera",
  "Link Wallet": "Enlazar Billetera",
  "You will receive a signature request. Signing is free and will not send a transaction.":
    "Recibirás una solicitud de firma. Firmar es gratis y no enviará una transacción.",
  "Generate Paradex Chain wallet": "Generar billetera Paradex Chain",
  "Generating Paradex Chain wallet": "Generando billetera Paradex Chain",
  "Paradex Chain wallet generated": "Billetera Paradex Chain generada",
  "Verify that you own this wallet": "Verificar que usted posee esta billetera",
  "Remember Me": "Recuérdame",
  "Only use 'Remember Me' if you are using a secure device you own. Selecting this option can expose your information to  others if you are accessing this wallet from a public device.":
    'Solo use "Recuérdame" si está utilizando un dispositivo seguro de su propiedad. Seleccionar esta opción puede exponer su información a otros si está accediendo a esta billetera desde un dispositivo público.',
  "Continue": "Continuar",
  "Disconnect Wallet": "Desconectar Billetera",
  "Disconnect": "Desconectar",
  "Account": "Cuenta",
  "Username": "Nombre de usuario",
  "Edit": "Editar",
  "Your username is now hidden. It is not visible to other users.":
    "Su nombre de usuario ahora está oculto. No es visible para otros usuarios.",
  "Your username will be visible to other users unless you choose to hide it. You can always change it later.":
    "Su nombre de usuario será visible para otros usuarios a menos que elija ocultarlo. Siempre puedes cambiarlo más tarde.",
  "Hide my Username": "Ocultar mi nombre de usuario",
  "Save": "Guardar",
  "Username is required": "Se requiere nombre de usuario",
  "Username must be between 5 and 20 characters":
    "El nombre de usuario debe tener entre 5 y 20 caracteres",
  "Username can only contain letters and numbers":
    "El nombre de usuario solo puede contener letras y números",
  "Wallets": "Billeteras",
  "Paradex Block Explorer": "Explorador de bloques de Paradex",
  "Log out": "Cerrar sesión",
  "No markets available": "No hay mercados disponibles",
  "Last Price": "Último precio",
  "Open Interest": "Interés abierto",
  "24h Volume": "Volumen 24h",
  "Mark Price": "Precio de marca",
  "Spot Price": "Precio al contado",
  "Eight hour funding rate that is continuously accrued on positions and settled upon any trade. If the funding rate is positive, longs pay shorts. If negative, shorts pay longs.":
    "Tasa de financiamiento de 8 horas que se acumula continuamente en las posiciones y se liquida en cualquier operación. Si la tasa de financiamiento es positiva, los largos pagan a los cortos. Si es negativa, los cortos pagan a los largos.",
  "8h Funding": "Financiamiento de 8h",
  "Limit": "Límite",
  "Market": "Mercado",
  "Stop Limit": "Stop Límite",
  "Stop Market": "Stop de Mercado",
  "Scaled Order": "Orden escalada",
  "Trigger Price": "Precio de Activación",
  "Input Price": "Precio de entrada",
  "Position Mark Price": "Precio de marca de la posición",
  "Amount": "Cantidad",
  "Reduce Only": "Solo Reducción",
  "Reduce Only will increase position": "Reduce Only aumentará la posición",
  "Your order stays open until it is filled or you decide to cancel.":
    "Su orden permanece abierta hasta que se complete o decida cancelarla.",
  "Your order gets filled right away, partially or fully, and whatever is left gets canceled instantly.":
    "Su orden se completa de inmediato, parcial o totalmente, y lo que queda se cancela instantáneamente.",
  "Conditional limit order that serves to add liquidity to the order book as a Maker order.":
    "Orden límite condicional que sirve para agregar liquidez al libro de órdenes como una orden de Maker.",
  "Currently the fees are fixed and uniform across the platform. Maker {{makerFee}} / Taker {{takerFee}}":
    "Actualmente, las tarifas son fijas y uniformes en toda la plataforma. Creador {{makerFee}} / Tomador {{takerFee}}",
  "Value": "Valor",
  "Buy": "Comprar",
  "BUY": "COMPRAR",
  "LONG": "LARGO",
  "Sell": "Vender",
  "SELL": "VENDER",
  "SHORT": "CORTO",
  "Order Value": "Valor del pedido",
  "Current Position": "Posición actual",
  "Account Value": "Valor de la cuenta",
  "The total value of your account at current mark prices":
    "El valor total de su cuenta a los precios actuales de marca",
  "Unrealized P&L": "P&L no realizada",
  "Free Collateral": "Colateral libre",
  "Account Leverage": "Apalancamiento de la cuenta",
  "Margin": "Margen",
  "Margin Ratio": "Margin Ratio",
  "Initial Margin": "Margen inicial",
  "Maintenance Margin": "Margen de mantenimiento",
  "Order Book": "Libro de órdenes",
  "Trades": "Operaciones",
  "Price": "Precio",
  "Size": "Tamaño",
  "Total": "Total",
  "Time": "Tiempo",
  "Spread": "Diferencial",
  "Positions": "Posiciones",
  "Open Orders": "Órdenes abiertas",
  "Trade History": "Historial de operaciones",
  "Order History": "Historial de órdenes",
  "Funding Payments": "Pagos de financiación",
  "Side": "Lado",
  "Entry Price": "Precio de entrada",
  "Liquidation Price": "Precio de Liquidación",
  "Breakeven Price": "Precio de Equilibrio",
  "Unrealized Funding": "Financiación no realizada",
  "Close Position": "Cerrar posición",
  "Market Close": "Cierre de mercado",
  "Close Long": "Cerrar largo",
  "Close Short": "Cerrar corto",
  "Type": "Tipo",
  "Filled": "Llenado",
  "Triggered": "Activado",
  "Instruction": "Instrucción",
  "Client Order Id": "ID de orden del cliente",
  "Status": "Estado",
  "NEW": "NUEVO",
  "OPEN": "ABIERTO",
  "UNTRIGGERED": "No activado",
  "CLOSED": "CERRADO",
  "Pending": "Pendiente",
  "Complete": "Completo",
  "Completed": "Completo",
  "Failed": "Fallado",
  "Bust": "Quiebra",
  "Value / Fee": "Valor / Tarifa",
  "Liquidity": "Liquidez",
  "Tx Hash": "Hash de Tx",
  "Trade Id": "ID de la operación",
  "Remaining": "Restante",
  "Cancel Reason": "Razón de cancelación",
  "User Canceled": "Usuario cancelado",
  "Not Enough Margin": "No hay suficiente margen",
  "Empty Market": "Mercado vacío",
  "Post Only": "Solo publicar",
  "Post Only Would Cross": "Solo publicar cruzaría",
  "Remaining IOC Cancel": "Cancelación restante de IOC",
  "Unexpected Failure": "Fallo inesperado",
  "Deleverage": "Desapalancamiento",
  "In Liquidation": "En liquidación",
  "Self Trade": "Auto comercio",
  "Asset Unavailable": "Activo no disponible",
  "Asset Expired": "Activo vencido",
  "Order Type Invalid": "Tipo de orden inválido",
  "Price Not Available": "Precio no disponible",
  "Expired": "Vencido",
  "Exceeds Max Slippage": "Excede el máximo de deslizamiento",
  "Timeout": "Tiempo agotado",
  "Order Exceeds Position Limit": "La orden excede el límite de posición",
  "Order Id": "Id de la orden",
  "Payment": "Pago",
  "Cancel All": "Cancelar todo",
  "{{orderSize}} will be closed at Market price":
    "{{orderSize}} se cerrará al precio de mercado",
  "By connecting a wallet, you agree to <1>Paradex Terms of Service</1> and represent and warrant to Paradex that you are not a <2>Restricted Person</2>.":
    "Al conectar una billetera, aceptas los <1>Términos de servicio de Paradex</1> y representas y garantizas a Paradex que no eres una <2>Persona restringida</2>.",
  "Wallet": "Billetera",
  "Deposit_verb": "Depositar",
  "Deposit_noun": "Depósito",
  "Withdraw": "Retirar",
  "Withdrawal": "Retiro",
  "Transfers": "Transferencias",
  "Transfer": "Transferir",
  "Collateral": "Colateral",
  "Asset": "Activo",
  "Wallet Balance": "Saldo de la billetera",
  "Actions": "Acciones",
  "Action": "Acción",
  "External Tx Hash": "Hash de Tx externo",
  "Withdraw to Wallet": "Retirar a la billetera",
  "Deposit to Paradex": "Depositar a Paradex",
  "You have no balance in your Ethereum wallet. Deposit some {{asset}} and try again.":
    "No tienes saldo en tu billetera Ethereum. Deposita algo de {{asset}} e intenta de nuevo.",
  "It is not possible to deposit more than your current balance.":
    "No es posible depositar más que tu saldo actual.",
  "Available": "Disponible",
  "Initiate a deposit from your Ethereum wallet to the Paradex bridge. These transactions":
    "Inicia un depósito desde tu billetera Ethereum al puente Paradex. Estas transacciones",
  "Initiate a deposit from your Ethereum wallet to the Paradex bridge. These transactions may take several minutes depending on network conditions. For more information check out our <1>docs</1>.":
    "Inicia un depósito desde tu billetera Ethereum al puente Paradex. Estas transacciones pueden tardar varios minutos dependiendo de las condiciones de la red. Para más información, consulta nuestros <1>documentos</1>.",
  "read more": "leer más",
  "Initiate Deposit to Bridge": "Iniciar depósito al puente",
  "Enable USDC on Paradex": "Habilitar USDC en Paradex",
  "In order to deposit you must allow Paradex access to at least {{amount}}.":
    "Para depositar debes permitir el acceso de Paradex a al menos {{amount}}.",
  "Enable USDC": "Habilitar USDC",
  "Withdraw initiated": "Retiro iniciado",
  "Withdrawals are a two-step process. First initiate a withdrawal from Paradex":
    "Los retiros son un proceso de dos pasos. Primero inicia un retiro desde Paradex",
  "Withdrawals are a two-step process. First initiate a withdrawal from Paradex to the Paradex Bridge. This first step can take 5-16 hours (10 on average). Second, initiate your withdrawal from the Paradex Bridge to your Ethereum wallet via the Deposits / Withdrawals table. For more information check out our <1>docs</1>.":
    "Los retiros son un proceso de dos pasos. Primero inicia un retiro desde Paradex al puente Paradex. Este primer paso puede tardar de 5 a 16 horas (10 en promedio). Segundo, inicia tu retiro desde el puente Paradex a tu billetera Ethereum a través de la tabla de Depósitos / Retiros. Para más información, consulta nuestros <1>documentos</1>.",
  "Initiate Withdrawal to Bridge": "Iniciar retiro al puente",
  "You have no funds to withdraw. It is not possible to withdraw more than your free collateral.":
    "No tienes fondos para retirar. No es posible retirar más que tu colateral libre.",
  "It is not possible to withdraw more than your free collateral.":
    "No es posible retirar más que tu colateral libre.",
  "You have no collateral": "No tienes colateral",
  "You have no open positions": "No tienes posiciones abiertas",
  "You have no open orders": "No tienes órdenes abiertas",
  "You have no trades": "No tienes operaciones",
  "You have no orders": "No tienes órdenes",
  "You have no funding payments": "No tienes pagos de financiación",
  "You have no transfers": "No tienes transferencias",
  "Account in liquidation": "Cuenta en liquidación",
  "Account is being liquidated. Any open positions will be reduced. Trading now is disabled.":
    "La cuenta está siendo liquidada. Cualquier posición abierta será reducida. El comercio ahora está deshabilitado.",
  "Account liquidated": "Cuenta liquidada",
  "Your account was liquidated on {{createdAt}}. Any open positions were reduced.":
    "Tu cuenta fue liquidada el {{createdAt}}. Cualquier posición abierta fue reducida.",
  "Deposit to Paradex successful": "Depósito a Paradex exitoso",
  "Deposit to Paradex failed": "Depósito a Paradex fallido",
  "Unexpected error. Try again.": "Error inesperado. Intenta de nuevo.",
  "Deposit initiated": "Depósito iniciado",
  "Deposit to bridge initiated": "Depósito al puente iniciado",
  "Deposit to Paradex available": "Depósito a Paradex disponible",
  "Go to Deposits": "Ir a Depósitos",
  "Deposit to Paradex in progress": "Depósito a Paradex en progreso",
  "Transfer successful": "Transferencia exitosa",
  "Limit {{side}} Order Fill": "Límite {{side}} Orden Completada",
  "{{type}} {{side}} Order Submitted": "{{type}} {{side}} Orden Enviada",
  "{{type}} {{side}} Order Canceled": "{{type}} {{side}} Orden Cancelada",
  "Reason": "Razón",
  "Market {{side}} Order Fill": "Mercado {{side}} Orden Completada",
  "Trade Busted On-Chain": "Comercio Anulado en Cadena",
  "Fill id": "Id de llenado",
  "Withdraw to wallet failed": "Retiro a la billetera fallido",
  "Withdrawal to bridge initiated": "Retiro al puente iniciado",
  "Withdrawal error. Try again.": "Error de retiro. Intenta de nuevo.",
  "Withdrawal to bridge in progress": "Retiro al puente en progreso",
  "Withdrawal to wallet initiated": "Retiro a la billetera iniciado",
  "Withdrawal to bridge failed": "Retiro al puente fallido",
  "Transaction error. Try again.": "Error de transacción. Intenta de nuevo.",
  "Withdrawal to wallet available": "Retiro a la billetera disponible",
  "Go to Withdrawals": "Ir a Retiros",
  "Withdrawal to wallet successful": "Retiro a la billetera exitoso",
  "Session expired, please log in again.":
    "Sesión expirada, por favor inicia sesión de nuevo.",
  "Failed to submit order": "Falló al enviar la orden",
  "Your username preferences were updated!":
    "¡Tus preferencias de nombre de usuario fueron actualizadas!",
  "Unexpected error updating username preferences":
    "Error inesperado al actualizar las preferencias de nombre de usuario",
  "This username already exists. Please choose a different username and try again.":
    "Este nombre de usuario ya existe. Por favor elige un nombre de usuario diferente e intenta de nuevo.",
  "Validation Error": "Error de Validación",
  "Binding Error": "Error de Vinculación",
  "Internal Error": "Error Interno",
  "Not Found": "No Encontrado",
  "Service Unavailable": "Servicio No Disponible",
  "Invalid Request Parameter": "Parámetro de Solicitud Inválido",
  "Order Id Not Found": "Id de Orden No Encontrado",
  "Order Is Closed": "La Orden Está Cerrada",
  "Order Is Not Open Yet": "La Orden Aún No Está Abierta",
  "Client Order Id Not Found": "Id de Orden del Cliente No Encontrado",
  "Duplicated Client Id": "Id de Cliente Duplicado",
  "Invalid Price Precision": "Precisión de Precio Inválida",
  "Invalid Token": "Token Inválido",
  "Invalid Ethereum Address": "Dirección de Ethereum Inválida",
  "Blocked Ethereum Address": "Dirección de Ethereum bloqueada",
  "Invalid Ethereum Signature": "Firma de Ethereum Inválida",
  "Invalid Starknet Address": "Dirección de Starknet Inválida",
  "Invalid Starknet Signature": "Firma de Starknet Inválida",
  "Starknet Signature Verification Failed":
    "Falló la Verificación de Firma de Starknet",
  "Bad Starknet Request": "Mala Solicitud de Starknet",
  "Ethereum Signer Mismatch": "Desajuste del Firmante de Ethereum",
  "Ethereum Hash Mismatch": "Desajuste del Hash de Ethereum",
  "Not Onboarded": "No Embarcado",
  "Invalid Timestamp": "Marca de Tiempo Inválida",
  "Invalid Signature Expiration": "Expiración de Firma Inválida",
  "Account Not Found": "Cuenta No Encontrada",
  "Invalid Order Signature": "Firma de Orden Inválida",
  "Public Key Invalid": "Clave Pública Inválida",
  "Unauthorized Ethereum Address": "Dirección de Ethereum No Autorizada",
  "Ethereum Address Already Onboarded": "Dirección de Ethereum Ya Embarcada",
  "Market Not Found": "Mercado No Encontrado",
  "Allowlist Entry Not Found": "Entrada de lista de permitidos no encontrada",
  "Username In Use": "Nombre de usuario en uso",
  "Service access restricted in your region":
    "Acceso al servicio restringido en tu región",
  "No Access Allowed": "No se permite el acceso",
  "You do not have access to the platform. Please join our Discord to get access.":
    "No tienes acceso a la plataforma. Por favor, únete a nuestro Discord para obtener acceso.",
  "{{actualChainName}} Wallet Connected":
    "Billetera {{actualChainName}} conectada",
  'Incorrect network selected "{{actualChainName}}". Please change the network to "{{requiredChainName}}" (id={{requiredChainId}}) in your wallet and retry.':
    'Red incorrecta seleccionada "{{actualChainName}}". Por favor, cambia la red a "{{requiredChainName}}" (id={{requiredChainId}}) en tu billetera e intenta de nuevo.',
  "Choose Wallet": "Elegir Monedero",
  "Starknet Wallet": "Billetera Starknet",
  "Switch to {{env}}": "Cambiar a {{env}}",
  "Search": "Buscar",
  "League": "Liga",
  "Switch Account": "Cambiar Cuenta",
  "Rank": "Rango",
  "24h XP": "XP 24h",
  "XP Per Day": "XP por día",
  "Season Volume": "Volumen de temporada",
  "Lifetime Volume": "Volumen de por vida",
  "Pre-Season": "Pretemporada",
  "Earn More XP": "Gana más XP",
  "XP Boost": "Impulso de XP",
  "XP Drop": "Caída de XP",
  "Trader": "Operador",
  "Total Share": "Participación total",
  "Maker Share": "Participación de Creador",
  "Maker Volume Share": "Participación de Volumen de Creador",
  "Fee Share": "Participación de Tarifa",
  "Fee Volume Share": "Participación de Volumen de Tarifa",
  "No data available": "No hay datos disponibles",
  "Refer & Earn": "Referir y Ganar",
  "Hours": "Horas",
  "Minutes": "Minutos",
  "Time/Duration": "Tiempo/Duración",
  "Runtime": "Tiempo de ejecución",
  "Frequency": "Frecuencia",
  "Number of Orders": "Número de órdenes",
  "Runtime should not be empty": "El tiempo de ejecución no debe estar vacío",
  "Runtime should be less than or equal to 24 hours":
    "El tiempo de ejecución debe ser menor o igual a 24 horas",
  "Inactive": "Inactivo",
  "Avg. Price": "Precio medio",
  "The minimum required amount for {{numOrders}} orders is {{totalSize}} {{currency}}.":
    "La cantidad mínima requerida para {{numOrders}} órdenes es {{totalSize}} {{currency}}.",
  "Take Profit / Stop Loss: Set take profit and stop loss price levels to automate trade actions. When the Mark Price will reach the price you set, it will send your order to the matching engine.":
    "Take Profit / Stop Loss: Establezca niveles de precios de toma de ganancias y stop loss para automatizar las acciones de comercio. Cuando el precio de referencia alcance el precio que configuró, enviará su orden al motor de emparejamiento.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount with an estimated profit of <3 />.":
    "Cuando el precio de mercado alcance <1 />, se activará la orden <2 /> para cerrar la cantidad seleccionada con un beneficio estimado de <3 />.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount with an estimated loss of <3 />.":
    "Cuando el precio de mercado alcance <1 />, se activará la orden <2 /> para cerrar la cantidad seleccionada con una pérdida estimada de <3 />.",
  "Edit TP/SL For This Position": "Editar TP/SL para esta posición",
  "Take Profit": "Take Profit",
  "Profit": "Beneficio",
  "Stop Loss": "Stop Loss",
  "Loss": "Pérdida",
  "Confirm": "Confirmar",
  "Take Profit Limit": "Límite de Take Profit",
  "Take Profit Market": "Take Profit a Mercado",
  "Stop Loss Limit": "Límite de Stop Loss",
  "Stop Loss Market": "Stop Loss a Mercado",
  "Funding": "Financiación",
  "Unwinding": "Desenrollado",
  "Cancel TP/SL orders": "Cancelar órdenes TP/SL",
  "TP/SL orders submitted successfully.":
    "Órdenes TP/SL enviadas correctamente.",
  "Pre-Season + All Seasons": "Pretemporada + Todas las temporadas",
  "Season {{season}}": "Temporada {{season}}",
  "XP Season {{season}}": "XP Temporada {{season}}",
  "Slippage": "Deslizamiento",
  "Max Slippage": "Deslizamiento máximo",
  "Max slippage defines the largest acceptable price deviation from the mark price.":
    "El deslizamiento máximo define la mayor desviación de precio aceptable con respecto al precio de referencia.",
  "During execution, the order can be canceled if the price exceeds the slippage limit or falls outside the Market <1>Price Band</1>.":
    "Durante la ejecución, la orden puede ser cancelada si el precio excede el límite de deslizamiento o cae fuera de la <1>banda de precios</1> del mercado.",
  "Update Max Slippage": "Actualizar deslizamiento máximo",
  "The Maximum Slippage allowed for {{symbol}} is {{price_band_width}}.":
    "El deslizamiento máximo permitido para {{symbol}} es {{price_band_width}}.",
  "Mark IV": "Mark IV",
  "Delta": "Delta",
  "Failed to load positions": "Error al cargar posiciones",
  "No open positions": "Sin posiciones abiertas",
  "learn more": "aprender más",
  "Learn More": "Aprende Más",
  "Search Markets": "Buscar Mercados",
  "Futures": "Futuros",
  "Options": "Opciones",
  "OI": "Interés Abierto",
  "Addresses": "Direcciones",
  "My Profile": "Mi Perfil",
  "Chart": "Gráfico",
  "ROI": "ROI",
  "Sharpe": "Sharpe",
  "Max Loss": "Máxima Pérdida",
  "Total Volume": "Volumen Total",
  "My NFTs": "Mis NFTs",
  "My XP": "Mis XP",
  "Balances": "Saldo",
  "Other": "Otros",
  "Paradex XP: Pre-Season": "Paradex XP: Pretemporada",
  "Paradex XP: Season {{season}}": "Paradex XP: Temporada {{season}}",
  "Season {{season}} XP": "Temporada {{season}} XP",
  "Refer your friends to earn 10%": "Referir a tus amigos y ganar 10%",
  "Refer Friends and Earn 10%": "Referir amigos y ganar 10%",
  "Invest in Paradex Vault & Earn 3X Boost":
    "Invertir en Paradex Vault & Ganar 3X Boost",
  "Avg. Daily XP": "XP promedio diario",
  "Lifetime XP": "XP de por vida",
  "My Rank": "Mi rango",
  "What is XP?": "¿Qué es XP?",
  "XP History": "Historial de XP",
  "Last Week XP": "XP de la semana pasada",
  "TVL XP": "XP TVL",
  "OI XP": "XP OI",
  "Liquidation XP": "XP Liquidación",
  "Vaults XP": "XP Bóvedas",
  "Week": "Semana",
  "Season": "Temporada",
  "Last updated: {{timestamp}}": "Última actualización: {{timestamp}}",
  "Xperience Points (XP)": "Puntos de Experiencia (XP)",
  "Log in to see your XP balance": "Inicia sesión para ver tu saldo de XP",
  "Log in to see where you stand": "Inicia sesión para ver dónde te encuentras",
  "Connect your X account to join Pro League":
    "Conecta tu cuenta de X para unirte a la Liga Pro",
  "Avg. Fill Price": "Precio Promedio de Ejecución",
  "Spot": "Spot",
  "Derivatives": "Derivados",
  "P&L Chart": "Gráfico P&L",
  "Account Value Chart": "Gráfico de Valor de la Cuenta",
  "Funding History": "Historial de Financiamiento",
  "Manage Funds": "Gestionar Fondos",
  "Profile": "Perfil",
  "Community XP": "XP Comunitario",
  "XP Share": "Participación de XP",
  "Next XP Drop in": "Próximo XP Drop en",
  "In Progress": "En Progreso",
  "{{field}} must be greater than {{value}}":
    "{{field}} debe ser mayor que {{value}}",
  "{{field}} must be less than {{value}}":
    "{{field}} debe ser menor que {{value}}",
  "Enter a valid Take Profit or Stop Loss price":
    "Ingrese un precio de Take Profit o Stop Loss válido",
  "Take Profit and Stop Loss prices cannot be the same":
    "Los precios de Take Profit y Stop Loss no pueden ser iguales",
  "Automatic Withdrawal": "Retiro Automático",
  "Estimated Gas Fee": "Gastos de Gas Estimados",
  "Auto Withdrawal": "Retiro Automático",
  "Withdrawing...": "Retirando...",
  "Paradex Bridge withdrawals can take between 5-16 hours to complete":
    "Los retiros de Paradex Bridge pueden tardar entre 5-16 horas en completarse",
  "Socialized Loss": "Pérdida Socializada",
  "Receivable Amount": "Cantidad Recibible",
  "Auto withdrawing...": "Retirando automáticamente...",
  "Manage Twitter Connection": "Gestionar conexión de Twitter",
  "Unlock additional functionality.": "Desbloquea funcionalidad adicional.",
  "Please note this connection can't be used as a login method to Paradex.":
    "Tenga en cuenta que esta conexión no se puede utilizar como método de inicio de sesión en Paradex.",
  "Toast Notifications": "Notificaciones de Toast",
  "Display toast notifications": "Mostrar notificaciones de Toast",
  "Average Entry Price": "Precio de entrada promedio",
  "Display average entry price line on chart":
    "Mostrar línea de precio de entrada promedio en el gráfico",
  "Display liquidation price line on chart":
    "Mostrar línea de precio de liquidación en el gráfico",
  "Display open order lines on chart":
    "Mostrar líneas de órdenes abiertas en el gráfico",
  "Failed to disconnect Twitter": "Error al desconectar Twitter",
  "Failed to authorize Twitter": "Error al autorizar Twitter",
  "Twitter account linked successfully!":
    "¡Cuenta de Twitter vinculada correctamente!",
  "Failed to link twitter account": "Error al vincular la cuenta de Twitter",
  "Fees": "Comisiones",
  "Taker pays {{takerFee}} in fees. Maker receives {{makerRebate}} in rebates.":
    "El taker paga {{takerFee}} en comisiones. El maker recibe {{makerRebate}} en reembolsos.",
  "Manage Discord Connection": "Gestionar conexión de Discord",
  "Failed to authorize Discord": "Error al autorizar Discord",
  "Discord account linked successfully!":
    "¡Cuenta de Discord vinculada correctamente!",
  "Failed to link Discord account": "Error al vincular la cuenta de Discord",
  "Failed to disconnect Discord": "Error al desconectar Discord",
  "Connect": "Conectar",
  "Unrealized P&L from current vault token balance. This doesn't account for owner profit share or slippage from positions unwind.":
    "Pérdida/Ganancia no realizada desde el saldo actual del token de la bóveda. No se tiene en cuenta el beneficio del propietario o el deslizamiento de las posiciones de desenrollado.",
  "Show P&L Amount": "Mostrar P&L",
  "Receivable amount must be positive":
    "La cantidad recibible debe ser positiva",
  "Wallet Address Change Detected":
    "Cambio de dirección de billetera detectado",
  "You can proceed with connecting your Paradex account to this wallet address or continue using your current connection.":
    "Puedes proceder a conectar tu cuenta de Paradex a esta dirección de billetera o continuar utilizando tu conexión actual.",
  "Connect To New Wallet": "Conectar a nueva billetera",
  "Keep Using Current Wallet": "Seguir usando la billetera actual",
  "Connect Current Wallet": "Conectar la billetera actual",
  "The account is not connected. Please make sure that <1>{{walletAddress}}</1> is connected and is the active account in your <2>{{walletName}}</2> wallet.":
    "La cuenta no está conectada. Por favor, asegúrate de que <1>{{walletAddress}}</1> esté conectada y sea la cuenta activa en tu billetera <2>{{walletName}}</2>.",
  "Reconnecting": "Reconectando",
  "There is a request in progress. Please proceed in your wallet.":
    "Hay una solicitud en curso. Por favor, continúe en su billetera.",
  "Modify Order": "Modificar Orden",
  "Realized P&L": "P&L Realizado",
  "Close All": "Cerrar Todo",
  "Close All Positions": "Cerrar Todas las Posiciones",
  "Market Close All Positions": "Mercado Cierra Todas las Posiciones",
  "Close {{percent}}% of All Positions":
    "Cerrar {{percent}}% de todas las posiciones",
  "Failed to close all positions": "Error al cerrar todas las posiciones",
  "Number of Positions": "Número de Posiciones",
  "Markets": "Mercados",
  "Position Value (Closing)": "Valor de la posición (Cierre)",
  "{{count}} positions will be rounded to the nearest order increment":
    "{{count}} posiciones se redondearán al incremento de orden más cercano",
  "You will earn Bonus XP on your referral activity":
    "Ganarás XP Bonus por tus actividades de referidos",
  "Token Price": "Precio del Token",
  "Vault Token Price Chart": "Gráfico Token Vault",
  "Market Close Orders Submitted": "Mercado Cierra Todas las Órdenes",
  "This profile is already linked to a different Paradex account. Disconnect socials from existing account and try again.":
    "Este perfil ya está vinculado a una cuenta de Paradex diferente. Desvincula tus redes sociales de tu cuenta actual y vuelve a intentarlo.",
  "XP Drops every Friday": "XP se cae cada viernes",
  "No XP Data Available": "No hay datos de XP disponibles",
  "XP data is available only for the Main Account.":
    "Los datos de XP están disponibles solo para la cuenta principal.",
  "Please switch to your Main Account to view your XP.":
    "Por favor, cambia a tu cuenta principal para ver tu XP.",
  "Referrals Not Available": "Referrals no disponibles",
  "Referrals are available only for the Main Account.":
    "Las Referrals están disponibles solo para la cuenta principal.",
  "Please switch to your Main Account to view your Referrals.":
    "Por favor, cambia a tu cuenta principal para ver tus Referrals.",
  "Deposit initiated via Layerswap": "Depósito iniciado a través de Layerswap",
  "Position History": "Historial de Posiciones",
  "Avg. Close Price": "Precio Promedio de Cierre",
  "Max Size": "Tamaño Máximo",
  "Closed Size": "Tamaño Cerrado",
  "Opened": "Abierto",
  "You have no positions": "No tienes posiciones",
  "Destination Address": "Dirección de Destino",
  "Withdrawal via Layerswap initiated": "Retiro via Layerswap iniciado",
  "Withdrawal via Layerswap failed": "Retiro via Layerswap fallido",
  "Market Share": "Participación de Mercado",
  "Tier": "Nivel",
  "Avg Maker Volume": "Promedio de Volumen de Maker",
  "Liquidity Score": "Puntuación de Liquidez",
  "Reward Share": "Participación de Recompensas",
  "Enter amount": "Ingrese cantidad",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount at <3 /> with an estimated profit of <4 />.":
    "Cuando el Precio de Mercado alcance <1 />, activará una orden <2 /> para cerrar la cantidad seleccionada a <3 /> con una ganancia estimada de <4 />.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount at <3 /> with an estimated loss of <4 />.":
    "Cuando el Precio de Mercado alcance <1 />, activará una orden <2 /> para cerrar la cantidad seleccionada a <3 /> con una pérdida estimada de <4 />.",
  "Limit Price": "Precio Límite",
  "Delete": "Eliminar",
  "Use other bridges from the list above to withdraw funds much faster.":
    "Utiliza otros puentes de la lista anterior para retirar fondos mucho más rápido.",
  "Paradex Chain Monitor": "Monitor de Cadena Paradex",
  "OTM Amount": "Cantidad OTM",
  "Leverage": "Apalancamiento",
  "Strikes": "Strikes",
  "Strike": "Strike",
  "Change": "Cambio",
  "Dist. to Spot": "Dist. al Spot",
  "Bid": "Compra",
  "Mark": "Marca",
  "Ask": "Venta",
  "IV": "VI",
  "Dist %": "Dist %",
  "Call": "Call",
  "Put": "Put",
  "Mark Price / IV": "Precio Marca / VI",
  "8h F-Funding": "Financiación 8h F",
  "No Strikes Available": "No hay Strikes Disponibles",
  "Attributes": "Atributos",
  "Why no Expiries?": "¿Por qué no hay Vencimientos?",
  "{{multiplier}}x Boost": "Impulso {{multiplier}}x",
  "Like perpetual futures, perpetual options have no expiration, offering continuous exposure to the underlying asset with built-in downside protection. This gives users the flexibility to hold their position indefinitely and decide the optimal time to close it based on their strategy":
    "Al igual que los futuros perpetuos, las opciones perpetuas no tienen vencimiento, ofreciendo exposición continua al activo subyacente con protección incorporada contra caídas. Esto da a los usuarios la flexibilidad de mantener su posición indefinidamente y decidir el momento óptimo para cerrarla según su estrategia",
  "Implied Volatility used to calculate the option's Mark Price":
    "Volatilidad Implícita utilizada para calcular el Precio Marca de la opción",
  "Distance between the Strike Price and the underlying Spot Price":
    "Distancia entre el Precio Strike y el Precio Spot subyacente",
  "Eight hour funding that is continuously accrued on positions and settled upon any trade. If the funding rate is positive, longs pay shorts. If negative, shorts pay longs":
    "Financiación de ocho horas que se acumula continuamente en las posiciones y se liquida en cada operación. Si la tasa de financiación es positiva, los largos pagan a los cortos. Si es negativa, los cortos pagan a los largos",
  "The Leverage Ratio (Lambda) measures an option's effective leverage by indicating how much the option's value changes, in percentage terms, for a 1% change in the spot price of the underlying asset. For example, if an option has a Lambda of 50 and the spot price increases by 1%, the option's value would increase by 50%":
    "El Ratio de Apalancamiento (Lambda) mide el apalancamiento efectivo de una opción indicando cuánto cambia el valor de la opción, en términos porcentuales, para un cambio del 1% en el precio spot del activo subyacente. Por ejemplo, si una opción tiene un Lambda de 50 y el precio spot aumenta un 1%, el valor de la opción aumentaría un 50%",
  "Option delta measures how much the price of an option is expected to change for a $1 change in the price of the underlying asset. For example, a delta of 0.5 means the option price will move $0.50 for every $1 move in the underlying":
    "El delta de la opción mide cuánto se espera que cambie el precio de una opción por un cambio de $1 en el precio del activo subyacente. Por ejemplo, un delta de 0.5 significa que el precio de la opción se moverá $0.50 por cada movimiento de $1 en el subyacente",
  "Eight hour funding rate of the perpetual future on the same underlying asset":
    "Tasa de financiación de ocho horas del futuro perpetuo sobre el mismo activo subyacente",
  "Having trouble? Try signing out and connecting again.":
    "¿Tienes problemas? Intenta cerrar sesión y conectarte de nuevo.",
  "API Reference": "Referencia de API",
  "XP Distribution occurs once per week on Friday at ~12 PM UTC":
    "La distribución de XP ocurre una vez por semana los viernes a las ~12 PM UTC",
  "What are Perpetual Options?": "¿Qué son las Opciones Perpetuas?",
  "Delta represents the increase in option price based on a $1 increase in the underlying spot price.\n\nFor example, a delta of 0.50 means the price of the option is expected to increase by $0.50 for a $1 increase in the underlying spot price.":
    "Delta representa el aumento en el precio de la opción basado en un aumento de $1 en el precio spot subyacente.\n\nPor ejemplo, un delta de 0.50 significa que se espera que el precio de la opción aumente $0.50 por cada aumento de $1 en el precio spot subyacente.",
  "Leverage(Lambda) represents the percentage change in option price for a 1% change in the underlying spot price.\n\nFor example, a lambda of 10 means the option price is expected to increase by 10% if the underlying spot price increases by 1%.":
    "Apalancamiento(Lambda) representa el cambio porcentual en el precio de la opción para un cambio del 1% en el precio spot subyacente.\n\nPor ejemplo, un lambda de 10 significa que se espera que el precio de la opción aumente un 10% si el precio spot subyacente aumenta un 1%.",
  "Eight hour options funding rate that is continuously accrued on positions and settled upon any trade. The funding rate for perpetual options reflects both the cost of leverage and the cost of insurance against adverse price moves. \n\nThe funding period of perpetual options is 24 hours. This means that the option premium will be continuously paid out over a 24h period. 24h funding rate = 3 x 8h funding rate":
    "Tasa de financiación de opciones de ocho horas que se acumula continuamente en las posiciones y se liquida en cada operación. La tasa de financiación para opciones perpetuas refleja tanto el costo del apalancamiento como el costo del seguro contra movimientos adversos de precios. \n\nEl período de financiación de las opciones perpetuas es de 24 horas. Esto significa que la prima de la opción se pagará continuamente durante un período de 24h. Tasa de financiación 24h = 3 x tasa de financiación 8h",
  "Risk": "Riesgo",
  "Vega": "Vega",
  "Gamma": "Gamma",
  "Estimated Funding P&L for the next 8 hours based on current funding rates":
    "P&L de Financiación Estimada para las próximas 8 horas basado en las tasas de financiación actuales",
  "Vega represents the increase in option price based on a 1% increase in the implied volatility.\n\nFor example, a Vega of 5 means the price of the option is expected to increase by $5 for a 1% increase in the implied volatility.":
    "Vega representa el aumento en el precio de la opción basado en un aumento del 1% en la volatilidad implícita.\n\nPor ejemplo, un Vega de 5 significa que se espera que el precio de la opción aumente $5 por un aumento del 1% en la volatilidad implícita.",
  "Adjust Leverage": "Ajustar Apalancamiento",
  "Leverage updated successfully": "Apalancamiento actualizado con éxito",
  "Failed to update leverage": "Error al actualizar el apalancamiento",
  "Adjust leverage parameters for selected market. Leverage will also increase on your open positions.":
    "Ajustar parámetros de apalancamiento para el mercado seleccionado. El apalancamiento también aumentará en tus posiciones abiertas.",
  "Please note that changing leverage will also apply for open positions and open orders on this market.":
    "Ten en cuenta que el cambio de apalancamiento también se aplicará a las posiciones abiertas y órdenes abiertas en este mercado.",
  "Selecting higher leverage could result in Deleveraging where some of your open orders would be cancelled.":
    "Seleccionar un apalancamiento más alto podría resultar en Desapalancamiento donde algunas de tus órdenes abiertas serían canceladas.",
  "disabled": "deshabilitado",
  "Margin Mode": "Modo Margen",
  "Manage your risk on individual positions by restricting the amount of margin allocated to each. If the margin ratio of an isolated position reaches 100%, the position will be liquidated. Margin can be added or removed to individual positions in this mode.":
    "Gestiona tu riesgo en posiciones individuales restringiendo la cantidad de margen asignado a cada una. Si el ratio de margen de una posición aislada alcanza el 100%, la posición será liquidada. El margen puede ser añadido o removido de posiciones individuales en este modo.",
  "Margin mode updated successfully": "Modo margen actualizado con éxito",
  "Failed to update margin mode": "Error al actualizar el modo margen",
  "Favorites": "Favoritos",
  "Trending": "Tendencias",
  "Losers": "Perdedores",
  "Gainers": "Ganadores",
  "New Listing": "Nueva cotización",
  "Developers": "Desarrolladores",
  "Developer Mode": "Modo Desarrollador",
  "Enable developer mode": "Habilitar modo desarrollador",
  "For external use": "Para uso externo",
  "Warning: Changing transfer behavior":
    "Advertencia: Cambiando el comportamiento de transferencia",
  "USDC will be transferred to account contract instead of Paradex. Amount won't be visible or available for use within Paradex, but can be used with external applications.":
    "USDC se transferirá al contrato de cuenta en lugar de Paradex. La cantidad no será visible ni disponible para su uso en Paradex, pero puede ser utilizada con aplicaciones externas.",
  "Affiliate Dashboard": "Panel de Afiliados",
};

export default translations;
