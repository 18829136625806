import * as TradingView from '#/vendor/charting_library';

import { c00EE91, c7A35CB, c181C20, cFF005C } from '#/colors';

//
const COLOR_UP = c00EE91;
const COLOR_DOWN = cFF005C;

/** Docs: @see https://www.tradingview.com/charting-library-docs/latest/api/interfaces/Charting_Library.ChartPropertiesOverrides/ */
export const DESKTOP_STYLES: Partial<TradingView.ChartPropertiesOverrides> = {
  'paneProperties.backgroundType': 'solid',
  'paneProperties.background': c181C20,

  // Crosshair
  'paneProperties.crossHairProperties.transparency': 50,
  'paneProperties.crossHairProperties.color': '#089981',

  // Status line
  'paneProperties.legendProperties.showSeriesTitle': false,
  'paneProperties.legendProperties.showStudyValues': false,

  'paneProperties.legendProperties.showStudyTitles': true,
  'paneProperties.legendProperties.showStudyArguments': true,

  // Background Grid Lines
  'paneProperties.horzGridProperties.color': 'rgba(42, 46, 57, 0.5)',
  'paneProperties.vertGridProperties.color': 'rgba(42, 46, 57, 0.5)',

  // Candles styles
  'mainSeriesProperties.candleStyle.upColor': COLOR_UP,
  'mainSeriesProperties.candleStyle.downColor': COLOR_DOWN,
  'mainSeriesProperties.candleStyle.drawWick': true,
  'mainSeriesProperties.candleStyle.drawBorder': true,
  'mainSeriesProperties.candleStyle.borderUpColor': COLOR_UP,
  'mainSeriesProperties.candleStyle.borderDownColor': COLOR_DOWN,
  'mainSeriesProperties.candleStyle.wickUpColor': COLOR_UP,
  'mainSeriesProperties.candleStyle.wickDownColor': COLOR_DOWN,
  'mainSeriesProperties.candleStyle.barColorsOnPrevClose': false,
  'mainSeriesProperties.candleStyle.drawBody': true,

  // Hollow Candles styles
  'mainSeriesProperties.hollowCandleStyle.upColor': COLOR_UP,
  'mainSeriesProperties.hollowCandleStyle.downColor': COLOR_DOWN,
  'mainSeriesProperties.hollowCandleStyle.drawWick': true,
  'mainSeriesProperties.hollowCandleStyle.drawBorder': true,
  'mainSeriesProperties.hollowCandleStyle.borderUpColor': COLOR_UP,
  'mainSeriesProperties.hollowCandleStyle.borderDownColor': COLOR_DOWN,
  'mainSeriesProperties.hollowCandleStyle.wickUpColor': COLOR_UP,
  'mainSeriesProperties.hollowCandleStyle.wickDownColor': COLOR_DOWN,
  'mainSeriesProperties.hollowCandleStyle.drawBody': true,

  // Heikin Ashi styles
  'mainSeriesProperties.haStyle.upColor': COLOR_UP,
  'mainSeriesProperties.haStyle.downColor': COLOR_DOWN,
  'mainSeriesProperties.haStyle.drawWick': true,
  'mainSeriesProperties.haStyle.drawBorder': true,
  'mainSeriesProperties.haStyle.borderUpColor': COLOR_UP,
  'mainSeriesProperties.haStyle.borderDownColor': COLOR_DOWN,
  'mainSeriesProperties.haStyle.wickUpColor': COLOR_UP,
  'mainSeriesProperties.haStyle.wickDownColor': COLOR_DOWN,
  'mainSeriesProperties.haStyle.barColorsOnPrevClose': false,
  'mainSeriesProperties.haStyle.drawBody': true,

  // Bar styles
  'mainSeriesProperties.barStyle.upColor': COLOR_UP,
  'mainSeriesProperties.barStyle.downColor': COLOR_DOWN,
  'mainSeriesProperties.barStyle.barColorsOnPrevClose': false,
  'mainSeriesProperties.barStyle.dontDrawOpen': false,
  'mainSeriesProperties.barStyle.thinBars': true,

  // Line styles
  'mainSeriesProperties.lineStyle.color': COLOR_UP,
  'mainSeriesProperties.lineStyle.linestyle': 0,
  'mainSeriesProperties.lineStyle.linewidth': 2,
  'mainSeriesProperties.lineStyle.priceSource': 'close',

  // Area styles
  'mainSeriesProperties.areaStyle.color1': COLOR_UP,
  'mainSeriesProperties.areaStyle.color2': COLOR_UP,
  'mainSeriesProperties.areaStyle.linecolor': COLOR_UP,
  'mainSeriesProperties.areaStyle.linestyle': 0,
  'mainSeriesProperties.areaStyle.linewidth': 2,
  'mainSeriesProperties.areaStyle.priceSource': 'close',
  'mainSeriesProperties.areaStyle.transparency': 80,

  // Baseline styles
  'mainSeriesProperties.baselineStyle.baselineColor': 'rgba( 117, 134, 150, 1)',
  'mainSeriesProperties.baselineStyle.topFillColor1': COLOR_UP,
  'mainSeriesProperties.baselineStyle.topFillColor2': COLOR_UP,
  'mainSeriesProperties.baselineStyle.bottomFillColor1': COLOR_DOWN,
  'mainSeriesProperties.baselineStyle.bottomFillColor2': COLOR_DOWN,
  'mainSeriesProperties.baselineStyle.topLineColor': COLOR_UP,
  'mainSeriesProperties.baselineStyle.bottomLineColor': COLOR_DOWN,
  'mainSeriesProperties.baselineStyle.topLineWidth': 2,
  'mainSeriesProperties.baselineStyle.bottomLineWidth': 2,
  'mainSeriesProperties.baselineStyle.priceSource': 'close',
  'mainSeriesProperties.baselineStyle.transparency': 40,
  'mainSeriesProperties.baselineStyle.baseLevelPercentage': 50,
};

export const MOBILE_STYLES: Partial<TradingView.ChartPropertiesOverrides> = {
  ...DESKTOP_STYLES,
  preset: 'mobile',
  // Area styles
  'mainSeriesProperties.areaStyle.color1': c7A35CB,
  'mainSeriesProperties.areaStyle.color2': c7A35CB,
  'mainSeriesProperties.areaStyle.linecolor': c7A35CB,
  // Line styles
  'mainSeriesProperties.lineStyle.color': c7A35CB,
  'mainSeriesProperties.lineWithMarkersStyle.color': c7A35CB,
};

export const STUDIES_STYLES: Partial<TradingView.StudyOverrides> = {
  'volume.volume.color.0': COLOR_DOWN,
  'volume.volume.color.1': COLOR_UP,
};

export const LOADING_SCREEN_STYLES: TradingView.LoadingScreenOptions = {
  backgroundColor: c181C20,
  foregroundColor: '#FFFFFF',
};
