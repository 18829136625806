export const DISCORD_IMG_SIZES = '(max-width: 600px) 64px, 512px';

export function discordProfileUrl(id: string): string {
  if (id === '') {
    return 'https://discord.com/';
  }

  return `https://discord.com/users/${id}`;
}

export function discordHandle(username: string): string {
  return `@${username}`;
}

export function discordImgSrcSet(url: string | null): string {
  if (url === null || url === '') {
    return '';
  }

  // Low-resolution image size
  const lowRes = '64w';

  // Hi resolution image size
  const highRes = '512w';
  const highResUrl = `${url}?size=512`;

  return `${url} ${lowRes}, ${highResUrl} ${highRes}`;
}
