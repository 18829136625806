import{useEffect}from'react';import{findBridge}from"../bridging/bridges";import{logException}from"../logging/logging";import{useParaclearDepositActions,useParaclearDepositStore}from"../paraclear-deposit/context";import{useTransfersStore}from"../transfers/transfers-context";/**
 * Some deposits do not support the single-step architecture. An example is
 * deposits coming from Rhino. These deposits pause in the AVAILABLE status
 * and require intervention to be completed on Paraclear. This component
 * automatically detects and triggers the Paraclear deposit for deposits
 * found in the AVAILABLE status in the UI.
 */export default function AutoDeposit(){const paraclearDepositStore=useParaclearDepositStore();const transfersStore=useTransfersStore();const{deposit}=useParaclearDepositActions();useEffect(()=>{const availableDeposits=transfersStore.transfers.selectAvailableDeposits();const twoStepDeposits=availableDeposits.filter(transfer=>{const bridge=findBridge(transfer.bridge);if(bridge==null)return false;return bridge.config.depositMode==='two-step';});twoStepDeposits.forEach(processDeposit);/**
     * Processes a deposit transfer if it has not been processed yet.
     * Processing a deposit means triggering a deposit to Paraclear.
     */function processDeposit(transfer){if(isDepositProcessed(transfer))return;deposit(transfer.id,transfer.amount,transfer.token).catch(cause=>{const description=`Unexpected error depositing to Paraclear`;const error=new Error(description,{cause});logException(error,{transferId:transfer.id});});}/**
     * Checks if the deposit has already been processed by cheecking whether
     * the deposit is in the Paraclear deposit store.
     */function isDepositProcessed(transfer){const{id}=transfer;const paraclearDeposit=paraclearDepositStore.deposits.selectById(id);const isProcessed=paraclearDeposit!=null;return isProcessed;}},[deposit,paraclearDepositStore.deposits,transfersStore.transfers]);return null;}