import * as Starknet from 'starknet';

import { logException } from '#/features/logging/logging';
import { type ParaclearProvider } from '#/features/wallets/paraclear/provider';

import { AsyncResult } from './types';

import type { Maybe } from './types';

const WAIT_FOR_TRANSACTION_OPTIONS_DEFAULT_OVERRIDES: Starknet.waitForTransactionOptions =
  {
    errorStates: [
      Starknet.TransactionExecutionStatus.REJECTED,
      Starknet.TransactionExecutionStatus.REVERTED, // Not included in default options
    ],
  };

export async function waitForTransaction(
  provider: ParaclearProvider,
  txnHash: string,
  options: Starknet.waitForTransactionOptions = {},
): AsyncResult<Awaited<ReturnType<typeof provider.getTransactionReceipt>>> {
  const finalOptions = {
    ...options,
    errorStates:
      options.errorStates ??
      WAIT_FOR_TRANSACTION_OPTIONS_DEFAULT_OVERRIDES.errorStates,
    // In the current version, it is not possible to perform the leading call for `waitForTransaction`,
    // so we override the default retry interval to 100ms to speed up the test execution.
    ...("false" === 'true' ? { retryInterval: 100 } : {}),
  };

  try {
    const response = await provider.waitForTransaction(txnHash, finalOptions);
    return { ok: true, data: response };
  } catch (cause) {
    const message = `Starknet transaction failed: ${(cause as Error).message}`;
    const error = new Error(message, { cause });
    return { ok: false, error };
  }
}

export function isEqualAddress(a: string, b: string): boolean {
  return BigInt(a) === BigInt(b);
}

export function getRevertReason(
  simulateResponse: Starknet.SimulatedTransaction,
) {
  const revertReason =
    // Starknet types seem to be incorrect and do not match the v0.7 spec
    // This is true even for the latest version of the starknet package
    (
      simulateResponse as unknown as Maybe<{
        transaction_trace: {
          execute_invocation?: { revert_reason?: string };
        };
      }>
    )?.transaction_trace.execute_invocation?.revert_reason;

  return revertReason;
}

export function parseFailureReason(revertReason: string) {
  const regex = /Failure reason: 0x\w+? \('(.*?)'\)/iu;
  const match = regex.exec(revertReason);
  const failureReason = match?.[1];

  if (failureReason == null) {
    const error = new Error(
      'Failed to parse failure reason from revert reason',
    );
    logException(error, { revertReason });
    return 'Unknown failure reason';
  }

  return failureReason;
}
