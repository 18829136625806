import { createOrder } from '#/api/orders';

import { publishNotification } from '#/features/notifications/topic';
import { signOrderRequest } from '#/features/order-signature';

import type { Resp } from '#/api/fetch-api';
import type { Market } from '#/api/markets';
import type { CreateOrderReq, CreateOrderResp } from '#/api/orders';
import type { MarketCloseFormView } from '#/features/perpetuals/market-close/form-view';
import type { SystemConfigView } from '#/features/system/system-config-context';
import type { AsyncResult, Result } from '#/utils/types';

export interface MarketCloseFormService {
  readonly submit: (signal?: AbortSignal) => AsyncResult<void>;
}

export function createService(
  view: MarketCloseFormView,
  market: Market,
  config: SystemConfigView,
  onBehalfOfAccount: string | null,
): MarketCloseFormService {
  return {
    submit: async (signal?: AbortSignal) => {
      if (!view.isValid) throw new Error(INVALID_ERROR_MESSAGE);
      if (onBehalfOfAccount == null) {
        throw new Error('Account is not connected');
      }
      const request = prepareRequest(
        view,
        config.starknet.chainId,
        onBehalfOfAccount,
      );
      const resp = await createOrder({ ...request, signal });

      if (resp.ok) {
        const order = resp.data;
        publishNotification({
          kind: 'submitted-order',
          props: { order, market },
        });
      }

      const result = prepareResult(resp);
      return result;
    },
  };
}

function prepareRequest(
  formView: MarketCloseFormView,
  paradexChainId: string,
  onBehalfOfAccount: string,
): CreateOrderReq {
  if (formView.type == null) {
    throw new Error('Order type is not set');
  }

  const request = (() => {
    switch (formView.type) {
      case 'MARKET':
        return {
          signature_timestamp: Date.now(),
          market: formView.market,
          side: formView.side,
          type: 'MARKET',
          size: formView.amountBaseAsset.value,
          price: '',
          trigger_price: '',
          instruction: 'GTC',
          flags: ['REDUCE_ONLY'],
        } as const;
      case 'LIMIT':
        return {
          signature_timestamp: Date.now(),
          market: formView.market,
          side: formView.side,
          type: 'LIMIT',
          size: formView.amountBaseAsset.value,
          price: formView.limitPrice.value,
          trigger_price: '',
          instruction: 'GTC',
          flags: ['REDUCE_ONLY'],
        } as const;
      // no default
    }
  })();
  return signOrderRequest(request, paradexChainId, onBehalfOfAccount);
}

function prepareResult(resp: Resp<CreateOrderResp>): Result<void> {
  return resp.ok
    ? { ok: true, data: undefined }
    : { ok: false, reason: resp.message, error: resp.error };
}

const INVALID_ERROR_MESSAGE =
  'Close Market form is not valid. Make sure to only call this function when the state is valid.';
