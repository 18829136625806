import {
  findBridge,
  getAllBridges,
  getAllSupportedChains,
  isEnabledFilter,
  supportsAccountTypeFilter,
  supportsChainFilter,
  supportsWalletKindFilter,
} from '#/features/bridging/bridges';
import { findChain } from '#/features/bridging/chains';

import type { Chain } from '#/features/bridging/chains';
import type { WalletState } from '#/features/wallet/wallet-context';
import type { Bridge } from './types';

export type OffsiteOptions =
  | {
      /** The URL to redirect to for offsite bridges */
      readonly url: string;
      readonly enabled: true;
    }
  | { readonly enabled: false };

interface BridgeViewStore {
  readonly chain: string | null;
  readonly bridge: string;
}

export interface BridgeView {
  readonly options: readonly BridgeOptionView[];
  readonly selected: BridgeOptionView;
  readonly isDisabled: boolean;
}

interface BridgeOptionView {
  readonly id: string;
  readonly name: string;
  readonly icon: string;
}

export function bridgeView(
  store: BridgeViewStore,
  walletState: WalletState,
): BridgeView {
  if (walletState.baseAccount == null)
    throw new Error('Base account is not set');

  const selectedBridge = findBridge(store.bridge);
  if (selectedBridge == null) throw new Error('No bridge options found');

  const accountType = walletState.baseAccount.type;

  const bridges =
    store.chain != null && walletState.walletKind != null
      ? getAllBridges()
          .filter(isEnabledFilter(true))
          .filter(supportsAccountTypeFilter(accountType))
          .filter(supportsWalletKindFilter(walletState.walletKind))
          .filter(supportsChainFilter(store.chain))
      : [];

  const hasNoBridges = bridges.length === 0;
  const hasOneSelectedBridge =
    bridges.length === 1 && selectedBridge.config.id === bridges[0]!.config.id;

  return {
    options: bridges.map(bridgeOptionView),
    selected: bridgeOptionView(selectedBridge),
    isDisabled: hasNoBridges || hasOneSelectedBridge,
  };

  function bridgeOptionView(bridge: Bridge): BridgeOptionView {
    return {
      id: bridge.config.id,
      name: bridge.config.name,
      icon: bridge.config.icon,
    };
  }
}

interface ChainViewStore {
  readonly chain: string | null;
}

export interface ChainView {
  readonly options: readonly ChainOptionView[];
  readonly selected: ChainOptionView;
}

interface ChainOptionView {
  readonly id: string;
  readonly name: string;
  readonly icon: string;
}

export function chainView(store: ChainViewStore): ChainView {
  if (store.chain == null)
    return { options: [], selected: { id: '', name: '', icon: '' } };

  const selected = findChain(store.chain);
  if (selected == null) throw new Error('No chain options found');

  const supportedChains = getAllSupportedChains();
  const options = [
    ...new Set(
      supportedChains.map((supportedChain) => {
        const chain = findChain(supportedChain.id);
        if (chain == null)
          throw new Error(`Supported chain not found: ${supportedChain.id}`);
        return chain;
      }),
    ),
  ];

  return {
    options: options
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(chainOptionView),
    selected: chainOptionView(selected),
  };

  function chainOptionView(chain: Chain): ChainOptionView {
    return {
      id: chain.id,
      name: chain.name,
      icon: chain.id,
    };
  }
}
