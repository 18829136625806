/**
 * WIP on `TRADE_ROUTE`(ex `FUTURES_ROUTE):
 *  We also need to rename all the `perpetuals` references in code (file, folder, variable names)
 *  open separate PR to `rename-all` when we have enough test coverage
 */
export const TRADE_ROUTE = '/trade';
export const FUNDING_ROUTE = '/funding';
export const TRADE_SYMBOL_SEGMENT = ':symbol';
export const TRADE_SYMBOL_ROUTE = `/trade/${TRADE_SYMBOL_SEGMENT}`;
export const TRADE_SUMMARY_ROUTE = `summary`;
export const TRADE_ORDERBOOK_ROUTE = `orderbook`;

export const PORTFOLIO_ROUTE = '/portfolio';
export const STATUS_ROUTE = '/status';
export const LEADERBOARD_ROUTE = '/leaderboard';
export const POINTS_ROUTE = '/xp';
export const VAULTS_ROUTE = '/vaults';
export const MARKETS_ROUTE = '/markets';
export const VAULT_ADDRESS_SEGMENT = ':vaultAddress';
export const VAULT_ROUTE = `/vaults/${VAULT_ADDRESS_SEGMENT}`;
export const STRATEGY_ADDRESS_SEGMENT = ':strategyAddress';
export const VAULT_STRATEGY_ROUTE = `${VAULT_ROUTE}/${STRATEGY_ADDRESS_SEGMENT}`;
export const PRO_LEAGUE_ROUTE = '/pro-league';
export const REFERRALS_ROUTE = '/referrals';
export const AFFILIATE_DASHBOARD_ROUTE = '/referrals/affiliate';
export const REFERRAL_ROUTE = '/r';
export const REFERRAL_CODE_ROUTE = `/${REFERRAL_ROUTE}/:referralCode`;
export const TWITTER_OAUTH_CALLBACK_ROUTE = '/oauth/twitter/callback';
export const DISCORD_OAUTH_CALLBACK_ROUTE = '/oauth/discord/callback';
export const SYSTEM_CONFIG_ROUTE = '/system-config';

export type VaultRouteParams = {
  vaultAddress?: string;
};

export type VaultStrategyRouteParams = {
  vaultAddress?: string;
  strategyAddress?: string;
};

export type TradeRouteParams = {
  symbol?: string;
};
