import { format as formatDate } from 'date-fns';

import { calcResolution } from '#/features/portfolio/single-history/functions';

import type { PeriodStore } from '#/features/portfolio/period/context';
import type { AccountHistoryStore } from '#/features/portfolio/single-history/store';
import type { DataPoint } from '#/utils/types';

export function errorView(...errors: (string | null)[]): string | null {
  const errorMessages = errors
    .filter((err) => err != null)
    .filter((err) => err.length > 0);
  return errorMessages.length > 0 ? errorMessages.join('; ') : null;
}

export function dataView(
  accountHistoryStore: AccountHistoryStore,
  periodStore: PeriodStore,
): DataPoint[] {
  const accountHistory = accountHistoryStore.data;

  const resolution = calcResolution(periodStore.hours);
  if (accountHistory[resolution] == null) return [];

  const data: DataPoint[] = [];
  const cap = Date.now() - periodStore.hours * 60 * 60 * 1000;
  accountHistory[resolution].data.forEach((value, i) => {
    const date = new Date(accountHistory[resolution]!.timestamps[i]!);
    if (date.getTime() > cap) {
      const name = formatDate(date, 'dd MMM yy HH:mm');
      data.push({ name, value });
    }
  });

  if (data.length === 0) return [];

  if (data.length === 1) {
    // Add a datapoint to the beginning so that a line can be drawn
    // instead of a point. Reuses the first datapoint's date.
    const name = data[0]?.name ?? '';
    return [{ name, value: 0 }, ...data];
  }

  return data;
}
