import BigNumber from 'bignumber.js';
import * as Starknet from 'starknet';
import * as Yup from 'yup';

export const isBigNumber = (fieldName: string) =>
  [
    fieldName,
    `${fieldName} must be a BigNumber`,
    BigNumber.isBigNumber,
  ] as const;

export const isFinite = (fieldName: string) =>
  [
    fieldName,
    `${fieldName} must be finite`,
    (bn: BigNumber) => bn.isFinite(),
  ] as const;

export const isGreaterThan = (
  fieldName: string,
  min: number,
  nullable = false,
) =>
  [
    fieldName,
    `${fieldName} must be greater than ${min}`,
    (bn: Yup.Maybe<BigNumber>) => {
      if (bn == null) return nullable;
      return bn.isGreaterThan(min);
    },
  ] as const;

export const isWithinRange = (
  fieldName: string,
  min: number,
  max: number,
  nullable = false,
) =>
  [
    fieldName,
    `${fieldName} must be between ${min} and ${max}`,
    (bn: Yup.Maybe<BigNumber>) => {
      if (bn == null) return nullable;
      return bn.isGreaterThanOrEqualTo(min) && bn.isLessThanOrEqualTo(max);
    },
  ] as const;

export const isLessThanOrEqualTo = (fieldName: string, max: number) =>
  [
    fieldName,
    `${fieldName} must be less than or equal to ${max}`,
    (bn: BigNumber) => bn.isLessThanOrEqualTo(max),
  ] as const;

export const isStarknetAddress = (fieldName: string) =>
  [
    fieldName,
    `${fieldName} must be a valid Starknet address`,
    async (address: string) => {
      try {
        Starknet.validateAndParseAddress(address);
        return true;
      } catch {
        return false;
      }
    },
  ] as const;
