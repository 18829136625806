import BigNumber from 'bignumber.js';

import { Account } from '#/api/account';
import { Position } from '#/api/positions';

import { MarketsStoreView } from '#/features/perpetuals/market-info/markets-context';
import { MarketsSummaryStoreView } from '#/features/perpetuals/market-info/markets-summary-context';
import {
  OpenPositionsStore,
  positionId,
} from '#/features/perpetuals/positions/open-positions-store-context';
import { OpenOrdersStoreView } from '#/features/perpetuals/trade-overview/open-orders-context';

import { accountImfWithCap } from '#/utils/margin';

import { calc_margin_excluding_asset } from './calculate_margin_requirement';

/**
 * Adapted from
 * @see https://github.com/tradeparadigm/mono/blob/release/paradex-1.23.3/api/paradex/prototype/account/account.py#L1159
 */
export default function estimate_liquidation_price(
  target_asset: string, // SyntheticAsset, // e.g. 'SOL-USD-PERP'
  estimatedPosition: Pick<Position, 'side' | 'size'>,
  /** mark price or limit price depending on the order type */
  basePrice: BigNumber | null,
  openPositionsStore: OpenPositionsStore,
  openOrdersStore: OpenOrdersStoreView,
  marketsSummaryStore: MarketsSummaryStoreView,
  marketsStore: MarketsStoreView,
  activeAccount: Account | null,
  profileMaxSlippage: BigNumber | null,
  accountImfBase: BigNumber | null,
): BigNumber | null {
  const targetAssetMarket = marketsStore.getMarket(target_asset);
  const targetAssetMarketSummary =
    marketsSummaryStore.getMarketSummary(target_asset);

  if (targetAssetMarket == null) {
    throw new Error(
      `estimate_liquidation_price: Market is not initialized for target_asset=${target_asset}`,
    );
  }
  if (targetAssetMarketSummary == null) {
    throw new Error(
      `estimate_liquidation_price: Market summary is not initialized for target_asset=${target_asset}`,
    );
  }
  if (targetAssetMarketSummary.mark_price == null) {
    throw new Error(
      `estimate_liquidation_price: 'mark_price' is not initialized for ${target_asset} market summary`,
    );
  }

  if (basePrice == null) {
    throw new Error(
      `estimate_liquidation_price: 'basePrice' is not initialized for ${target_asset}`,
    );
  }

  if (activeAccount == null) {
    throw new Error('estimate_liquidation_price: Account is not initialized');
  }

  if (targetAssetMarket.delta1_cross_margin_params == null) {
    throw new Error('asset type not supported');
  }

  const assetPosition = openPositionsStore.get(
    positionId(target_asset, activeAccount.account),
  );
  const assetPositionSize = assetPosition?.size ?? BigNumber(0);
  const totalPositionSize = assetPositionSize.plus(estimatedPosition.size);
  const positionSign = totalPositionSize.isNegative() ? -1 : 1;
  const finalSide = totalPositionSize.isNegative() ? 'SELL' : 'BUY';

  const mmr_excluding_asset = calc_margin_excluding_asset(
    'Maintenance',
    target_asset,
    marketsSummaryStore,
    marketsStore,
    openOrdersStore,
    openPositionsStore,
    activeAccount.account,
    profileMaxSlippage,
    accountImfBase,
  );

  if (totalPositionSize.isZero()) return null;

  const margin_params = targetAssetMarket.delta1_cross_margin_params;
  const { imf_base, mmf_factor } = margin_params;

  const IMF = accountImfWithCap(imf_base, accountImfBase);
  const current_account_value = activeAccount.account_value;
  const fee_rate = BigNumber.max(0.0003, -0.00005);
  const tentative_notional = current_account_value
    .minus(mmr_excluding_asset)
    .minus(totalPositionSize.times(basePrice))
    .div(IMF.times(mmf_factor).plus(fee_rate).minus(positionSign));

  if (tentative_notional.isNegative()) {
    return null;
  }

  const liquidation_price = tentative_notional.div(totalPositionSize.abs());

  return isLiquidationPriceValid(liquidation_price) ? liquidation_price : null;

  /**
   * If user does not have enough margin to cover the new order open loss,
   * the liquidation price formula can return a price that is not valid
   */
  function isLiquidationPriceValid(liquidationPrice: BigNumber) {
    if (basePrice == null) return false;
    if (liquidationPrice.isZero()) return false;

    if (liquidationPrice.isLessThan(basePrice) && finalSide === 'SELL')
      return false;

    if (liquidationPrice.isGreaterThan(basePrice) && finalSide === 'BUY')
      return false;

    return true;
  }
}
