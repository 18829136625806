import BigNumber from 'bignumber.js';

import { AsyncResp, BaseReq, requestApi } from '#/api/fetch-api';

import { nullableBigNumber } from '#/utils/number';
import { Nullable } from '#/utils/types';

export interface ProleagueCampaignReq extends BaseReq {
  readonly season?: string;
  readonly category?: string;
}

interface ProleagueStatsResp {
  readonly results: RawProleagueStat[];
}

interface RawProleagueStat {
  readonly username: string;
  readonly annualised_return?: number;
  readonly avg_daily_return?: number;
  readonly max_drawdown?: number;
  readonly num_traded_markets?: number;
  readonly num_trades?: number;
  readonly num_obs?: number;
  readonly realized_pnl?: number;
  readonly season_name: string;
  readonly sharpe_ratio?: number;
  readonly stdev_daily_return?: number;
  readonly total_fees?: number;
  readonly total_roi?: number;
  readonly traded_volume?: number;
  readonly unrealized_pnl?: number;
  readonly rank: number;
  readonly account_value: Record<string, number>;
  readonly is_user_account?: boolean;
  readonly twitter_username?: string;
  readonly twitter_profile_image_url?: string;
}

export interface ProleagueStat
  extends Omit<
    RawProleagueStat,
    | 'annualised_return'
    | 'avg_daily_return'
    | 'max_drawdown'
    | 'num_traded_markets'
    | 'num_trades'
    | 'num_obs'
    | 'realized_pnl'
    | 'sharpe_ratio'
    | 'stdev_daily_return'
    | 'total_fees'
    | 'total_roi'
    | 'traded_volume'
    | 'unrealized_pnl'
    | 'is_user_account'
    | 'twitter_username'
    | 'twitter_profile_image_url'
  > {
  readonly annualised_return: Nullable<BigNumber>;
  readonly avg_daily_return: Nullable<BigNumber>;
  readonly max_drawdown: Nullable<BigNumber>;
  readonly num_traded_markets: Nullable<BigNumber>;
  readonly num_trades: Nullable<BigNumber>;
  readonly num_obs: Nullable<BigNumber>;
  readonly realized_pnl: Nullable<BigNumber>;
  readonly sharpe_ratio: Nullable<BigNumber>;
  readonly stdev_daily_return: Nullable<BigNumber>;
  readonly total_fees: Nullable<BigNumber>;
  readonly total_roi: Nullable<BigNumber>;
  readonly traded_volume: Nullable<BigNumber>;
  readonly unrealized_pnl: Nullable<BigNumber>;
  readonly is_user_account: boolean;
  readonly twitter_username: string | null;
  readonly twitter_profile_image_url: string | null;
}

export async function getPublicProleagueLeaderboard(
  req: ProleagueCampaignReq,
): AsyncResp<ProleagueStat[]> {
  const { signal } = req;
  const season = encodeURIComponent(req.season ?? '');
  const category = encodeURIComponent(req.category ?? '');

  const resp = await requestApi<ProleagueStatsResp>({
    signal,
    method: 'GET',
    url: `/campaigns/public/proleague/leaderboard/${season}/${category}`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: resp.data.results.map(processRawProleagueStat),
  };
}

export async function getPrivateProleagueLeaderboard(
  req: ProleagueCampaignReq,
): AsyncResp<ProleagueStat[]> {
  const { signal } = req;
  const season = encodeURIComponent(req.season ?? '');
  const category = encodeURIComponent(req.category ?? '');

  const resp = await requestApi<ProleagueStatsResp>({
    signal,
    method: 'GET',
    url: `/campaigns/private/proleague/leaderboard/${season}/${category}`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: resp.data.results.map(processRawProleagueStat),
  };
}

function processRawProleagueStat(data: RawProleagueStat): ProleagueStat {
  return {
    ...data,
    annualised_return: nullableBigNumber(data.annualised_return),
    avg_daily_return: nullableBigNumber(data.avg_daily_return),
    max_drawdown: nullableBigNumber(data.max_drawdown),
    num_traded_markets: nullableBigNumber(data.num_traded_markets),
    num_trades: nullableBigNumber(data.num_trades),
    num_obs: nullableBigNumber(data.num_obs),
    realized_pnl: nullableBigNumber(data.realized_pnl),
    sharpe_ratio: nullableBigNumber(data.sharpe_ratio),
    stdev_daily_return: nullableBigNumber(data.stdev_daily_return),
    total_fees: nullableBigNumber(data.total_fees),
    total_roi: nullableBigNumber(data.total_roi),
    traded_volume: nullableBigNumber(data.traded_volume),
    unrealized_pnl: nullableBigNumber(data.unrealized_pnl),
    is_user_account: data.is_user_account ?? false,
    twitter_username: data.twitter_username ?? null,
    twitter_profile_image_url: data.twitter_profile_image_url ?? null,
  };
}
