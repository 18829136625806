import { shortString } from 'starknet';

import type { constants } from 'starknet';

export const STARK_KEY_SIGNATURE_VERSION = '1';

export function buildStarkKeysEthTypedData(chainId: number) {
  return {
    domain: {
      name: 'Paradex',
      chainId,
      version: STARK_KEY_SIGNATURE_VERSION,
    },
    primaryType: 'Constant',
    types: {
      EIP712Domain: [
        { name: 'name', type: 'string' },
        { name: 'version', type: 'string' },
        { name: 'chainId', type: 'uint256' },
      ],
      Constant: [{ name: 'action', type: 'string' }],
    },
    message: {
      action: 'STARK Key',
    },
  } as const;
}

export function buildStarkKeysStarknetTypedData(
  chainId: constants.NetworkName,
) {
  return {
    domain: {
      name: 'Paradex',
      // Encoding is required for Argent mobile wallet.
      // Resulting signature is the same as passing the chainId directly.
      chainId: shortString.encodeShortString(chainId),
      version: STARK_KEY_SIGNATURE_VERSION,
    },
    primaryType: 'Constant',
    types: {
      StarkNetDomain: [
        { name: 'name', type: 'felt' },
        { name: 'version', type: 'felt' },
        { name: 'chainId', type: 'felt' },
      ],
      Constant: [{ name: 'action', type: 'felt' }],
    },
    message: {
      action: 'STARK Key',
    },
  };
}
