import BigNumber from 'bignumber.js';

export function leverageToImfBase(
  leverage: BigNumber | null,
): BigNumber | null {
  if (leverage == null) return null;
  return new BigNumber(1).dividedBy(leverage);
}

export function accountImfWithCap(
  imf_base: BigNumber,
  userImf: BigNumber | null,
): BigNumber {
  if (userImf == null) {
    return imf_base;
  }
  return BigNumber.max(imf_base, userImf);
}
