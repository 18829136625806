import { AsyncResp, BaseReq, requestApi } from './fetch-api';
import { UnixTimeMs } from './types';

type EthereumAddress = `0x${string}`;

interface BridgedTokenConfig {
  readonly name: string;
  readonly symbol: string;
  readonly decimals: number;
  readonly l1_token_address: EthereumAddress;
  readonly l1_bridge_address: EthereumAddress;
  readonly l2_token_address: string;
  readonly l2_bridge_address: string;
}

export interface SystemConfig {
  readonly starknet_gateway_url: string;
  readonly starknet_fullnode_rpc_url: string;
  readonly starknet_chain_id: string;
  readonly block_explorer_url: string;
  readonly paraclear_address: string;
  readonly paraclear_decimals: number;
  readonly paraclear_account_proxy_hash: string;
  readonly paraclear_account_hash: string;
  readonly oracle_address: string;
  readonly bridged_tokens: ReadonlyArray<BridgedTokenConfig>;
  readonly l1_core_contract_address: EthereumAddress;
  readonly l1_operator_address: EthereumAddress;
  readonly l1_chain_id: string;
  readonly l1_withdraw_relayer_address: EthereumAddress;
  readonly l2_withdraw_relayer_address: EthereumAddress;
  readonly liquidation_fee: string;
}

interface GetConfigReq extends BaseReq {}

export async function getSystemConfig(
  req: GetConfigReq,
): AsyncResp<SystemConfig> {
  const { signal } = req;

  const resp = await requestApi<SystemConfig>({
    signal,
    method: 'GET',
    url: `/system/config`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: resp.data,
  };
}

export type SystemStatus = 'ok' | 'maintenance' | 'cancel_only';

export interface SystemState {
  readonly status: SystemStatus;
}

interface GetStateReq extends BaseReq {}

export async function getSystemState(req: GetStateReq): AsyncResp<SystemState> {
  const { signal } = req;

  const resp = await requestApi<SystemState>({
    signal,
    method: 'GET',
    url: `/system/state`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: resp.data,
  };
}

interface RawSystemTime {
  readonly server_time: UnixTimeMs;
}
export interface SystemTime {
  readonly server_time: Date;
}

interface GetStateReq extends BaseReq {}

export async function getSystemTime(req: GetStateReq): AsyncResp<SystemTime> {
  const { signal } = req;

  const resp = await requestApi<RawSystemTime>({
    signal,
    method: 'GET',
    url: `/system/time`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: processSystemTime(resp.data),
  };
}

function processSystemTime(rawSystemTime: RawSystemTime): SystemTime {
  return {
    ...rawSystemTime,
    server_time: new Date(Number(rawSystemTime.server_time)),
  };
}
