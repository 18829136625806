import BigNumber from 'bignumber.js';

import { Nullable } from '#/utils/types';
import { PollingConnectionBaseState } from '#/utils/usePollingConnection';

export interface SocializedLossState extends PollingConnectionBaseState {
  readonly socializedLoss: Nullable<BigNumber>;
}

export const INITIAL_STATE: SocializedLossState = {
  status: 'connected',
  error: null,
  socializedLoss: null,
};
