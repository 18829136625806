import { msToDate } from '#/utils/date';
import getQueryString from '#/utils/getQueryString';

import {
  AsyncResp,
  BasePaginatedReq,
  PaginatedRespData,
  requestApi,
} from './fetch-api';
import { RawFill } from './fills';
import { UnixTimeMs } from './types';

export interface RawTradeBust {
  readonly account: string;
  readonly busted_fill_id: RawFill['id'];
  readonly created_at: UnixTimeMs;
}

export interface TradeBust extends Omit<RawTradeBust, 'created_at'> {
  readonly created_at: Date;
}

interface TradeBustsReq extends BasePaginatedReq {
  readonly market?: string;
}

export interface RawTradeBustsResp extends PaginatedRespData<RawTradeBust> {}

export interface TradeBustsResp extends PaginatedRespData<TradeBust> {}

export async function getTradeBusts(
  req: TradeBustsReq,
): AsyncResp<TradeBustsResp> {
  const { signal, market, cursor, page_size } = req;

  const query = getQueryString([
    ['market', market],
    ['cursor', cursor],
    ['page_size', page_size],
  ]);

  const resp = await requestApi<RawTradeBustsResp>({
    signal,
    method: 'GET',
    url: `/tradebusts${query}`,
  });

  if (!resp.ok) {
    return resp;
  }

  return {
    ...resp,
    data: {
      ...resp.data,
      results: resp.data.results.map(processTradeBust),
    },
  };
}

export function processTradeBust(bust: RawTradeBust): TradeBust {
  return {
    ...bust,
    created_at: msToDate(bust.created_at),
  };
}
