import { Dispatch, SetStateAction } from 'react';
import { getUnixTime } from 'date-fns';

import { postAuth } from '#/api/account';
import * as auth from '#/api/auth';
import { FailedResp } from '#/api/fetch-api';
import { Abortable } from '#/api/types';
import { ParadexWebSocket } from '#/api/ws/ws';

import { AuthState } from '#/features/auth/auth-context';
import { FeatureFlags } from '#/features/feature-flags';
import { logEvent, logException } from '#/features/logging/logging';
import { STORAGE_KEY_SESSION_EXPIRES_AT } from '#/features/storage/storage';
import { logGeoMetadata } from '#/features/system/utils';
import { AuthRequest, buildAuthTypedData } from '#/features/wallet/chain-l2';
import { prepareErrorMessage } from '#/features/wallet/ethereum';
import { ParadexChainConfigurable } from '#/features/wallet/wallet-context';
import ParaclearWallet, {
  StarknetSignature,
  TypedData,
} from '#/features/wallets/paraclear/wallet';

import {
  isAbortError,
  isTimeoutError,
  UnauthorizedError,
} from '#/utils/errors';
import { setStorageItem } from '#/utils/localStorage';
import { AsyncResult } from '#/utils/types';

interface Params extends ParadexChainConfigurable {
  signedRequest: AuthState['signedRequest'];
  setAuthState: Dispatch<SetStateAction<AuthState>>;
  ws: ParadexWebSocket | undefined;
}

export default function actionPerformAuth(params: Params) {
  return async ({ signal }: Abortable = {}) =>
    performAuth({ ...params, signal });
}

const SEVEN_DAYS_MS = 7 * 24 * 60 * 60 * 1000;

const isL2OnlySessionEnabled = FeatureFlags.getBooleanValue(
  'l2-only-session-enabled',
  false,
);

async function performAuth({
  signedRequest,
  setAuthState,
  ws,
  paradexChainId,
  signal,
}: Params & Abortable): AsyncResult<void> {
  const isWsUnavailable =
    isL2OnlySessionEnabled &&
    (ws?.readyState === WebSocket.CLOSED ||
      ws?.readyState === WebSocket.CLOSING);

  if (ws == null || isWsUnavailable) {
    throw new Error('auth called before WS available');
  }
  if (signal?.aborted === true) {
    return { ok: true, data: undefined };
  }

  const actions = prepareActions(setAuthState);
  const isAuthSignedRequestAvailable = signedRequest != null;

  actions.resetError();
  const authRequest = isAuthSignedRequestAvailable
    ? signedRequest.request
    : prepareAuthRequest();

  try {
    const authRequestTypedData: TypedData = buildAuthTypedData(
      authRequest,
      paradexChainId,
    );
    const signature = isAuthSignedRequestAvailable
      ? signedRequest.signature
      : ParaclearWallet.signTypedData(authRequestTypedData);

    logGeoMetadata('Auth request');
    const httpAuthResp = await postAuth({
      signature,
      paradexAddress: ParaclearWallet.getAddress(),
      createdAt: authRequest.timestamp,
      expiresAt: authRequest.expiration,
      signal,
    });

    if (!httpAuthResp.ok) {
      if (httpAuthResp.status === 401) {
        const error = new UnauthorizedError(
          'Request to acquire JWT not authorized',
          { cause: httpAuthResp.error },
        );
        return {
          ok: false,
          reason: 'Not authorized to acquire authentication token',
          error,
        };
      }

      const error = new Error(`Request to acquire JWT failed`, {
        cause: httpAuthResp.error,
      });

      const reason = `Failed to acquire authentication token. ${extractFailedReason(
        httpAuthResp,
      )}`;
      return {
        ok: false,
        reason,
        error,
      };
    }

    let attemptsCount = 1;
    const maxAttempts = 5;
    while (true) {
      try {
        const wsAuthResp = await ws.request({
          method: 'auth',
          params: { bearer: httpAuthResp.data.jwt_token },
          timeout: 1000 * 2 * attemptsCount,
          signal,
        });

        // If `wsAuthResp.error` populated, the request should not be retried
        //    as it is a valid error response from the server, e.g. `-32600: invalid request`
        if (wsAuthResp.error != null) {
          const error = new Error('Error sending WS API auth request', {
            cause: wsAuthResp.error,
          });
          return {
            ok: false,
            reason: 'Failed to authenticate real-time connection',
            error,
          };
        }

        break;
      } catch (_error) {
        const error = _error as Error;
        const shouldRetry =
          isTimeoutError(error) && attemptsCount < maxAttempts;
        if (shouldRetry) {
          const message = `Timed out waiting for WS auth response ${attemptsCount}/${maxAttempts} times. Retrying…`;
          logEvent(message, { error });
          continue;
        }
        throw error;
      } finally {
        attemptsCount++;
      }
    }

    setStorageItem(STORAGE_KEY_SESSION_EXPIRES_AT, authRequest.expiration);
    actions.setAuthToken(httpAuthResp.data.jwt_token);
    actions.setAuthSignedRequest(authRequest, signature);
  } catch (err) {
    if (isAbortError(err as Error)) {
      logEvent('Authentication action aborted', { error: err });
      return { ok: true, data: undefined };
    }
    const description = 'Failed to authenticate user';
    const { message, isException } = prepareErrorMessage(description, err);
    actions.setError(message);
    if (isException) {
      const error = new Error(description, { cause: err });
      logException(error);
      return { ok: false, reason: message, error };
    }
    logEvent(message);
    return { ok: false, reason: message, error: null };
  }

  logGeoMetadata('Auth success');
  return { ok: true, data: undefined };
}

function prepareActions(setState: Dispatch<SetStateAction<AuthState>>) {
  const setAuthToken = (token: string) => {
    auth.setAuthToken(token);
    setState((state) => ({ ...state, token }));
  };

  const setAuthSignedRequest = (
    request: AuthRequest,
    signature: StarknetSignature,
  ) => {
    setState((state) => ({ ...state, signedRequest: { request, signature } }));
  };

  const setError = (error: string) => {
    setState((state) => ({ ...state, error }));
  };

  const resetError = () => {
    setState((state) => ({ ...state, error: null }));
  };

  return {
    setAuthToken,
    setAuthSignedRequest,
    setError,
    resetError,
  };
}

function prepareAuthRequest(): AuthRequest {
  const dateNow = new Date();
  const dateExpiration = new Date(dateNow.getTime() + SEVEN_DAYS_MS);

  return {
    method: 'POST',
    path: '/v1/auth',
    body: '',
    timestamp: getUnixTime(dateNow),
    expiration: getUnixTime(dateExpiration),
  };
}

function extractFailedReason(resp: FailedResp) {
  if (resp.data == null) return '';
  if (typeof resp.data !== 'object') return '';
  return Object.values(resp.data).join(', ');
}
