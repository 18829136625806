import getQueryString from '#/utils/getQueryString';

import { PORTFOLIO_ROUTE } from '#/routes';

import { PortfolioSearchParams } from './portfolio-context';

export function linkToActiveTab(tab: PortfolioSearchParams['active_tab']) {
  const query = getQueryString([['active_tab', tab]]);
  return `${PORTFOLIO_ROUTE}${query}`;
}
