import BigNumber from 'bignumber.js';
import * as Yup from 'yup';

import { isContractDeployed } from '#/features/paradex-chain';
import {
  isBigNumber,
  isFinite,
  isGreaterThan,
  isLessThanOrEqualTo,
  isStarknetAddress,
} from '#/features/vaults/validation';
import { WITHDRAW_AMOUNT_SCALE } from '#/features/vaults/withdraw/config';
import { ParaclearProvider } from '#/features/wallets/paraclear/provider';

import { Maybe } from '#/utils/types';

import { WithdrawFormStore } from './form-store';

import type { TFunction } from '#/features/localization/utils';
import type { VaultAccountSummaryStore } from '#/features/vaults/single-account-summary/store';

type FormShape = {
  [K in keyof WithdrawFormStore]: Yup.Schema<unknown>;
};

const validate = async (formStore: WithdrawFormStore, t: TFunction) => {
  const yupFormSchema = Yup.object().shape<FormShape>({
    vaultAddress: Yup.string()
      .required(t('{{field}} is required', { field: t('Vault Address') }))
      .test(...isStarknetAddress(t('Vault Address'))),
    withdrawAmount: Yup.mixed<BigNumber>()
      .required(t('{{field}} is required', { field: t('Amount') }))
      .test(...isBigNumber(`${t('Amount')}`))
      .test(...isFinite(`${t('Amount')}`))
      .test(...isGreaterThan(`${t('Amount')}`, 0))
      .test(...isLessThanOrEqualTo(`${t('Amount')}`, Number.MAX_SAFE_INTEGER)),
  });
  return yupFormSchema.validate(formStore);
};

const validateVaultAddress = async (
  provider: ParaclearProvider,
  address: string,
) => {
  try {
    await isContractDeployed(provider, address);
  } catch (err: unknown) {
    const message =
      `Address '${address}' is not a deployed Paradex Account address: ` +
      `${(err as Maybe<Error>)?.message}`;
    throw new Error(message, { cause: err });
  }
};

const validateAvailableAmount = async (
  formStore: WithdrawFormStore,
  vaultAccountSummaryStore: VaultAccountSummaryStore,
  t: TFunction,
) => {
  const shares = vaultAccountSummaryStore.data?.vtoken_amount;
  if (shares == null) return;

  const amount = formStore.withdrawAmount;
  if (amount == null) return;

  const sharesRounded = shares.decimalPlaces(
    WITHDRAW_AMOUNT_SCALE,
    BigNumber.ROUND_DOWN,
  );

  const exceedsAvailable = amount.isGreaterThan(sharesRounded);

  if (exceedsAvailable) {
    throw new Yup.ValidationError(
      t(`Withdrawal amount exceeds available vault shares`),
    );
  }
};

/**
 * Yup's validate() handles static validation that is known beforehand.
 * While a set of functions handle more complex validation that depends on live data.
 */
export const formSchema = {
  validate,
  validateVaultAddress,
  validateAvailableAmount,
};
