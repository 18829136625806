import BigNumber from 'bignumber.js';
import * as Yup from 'yup';

import { Resp, useApi } from '#/api/fetch-api';
import {
  CreateOrderReq,
  OrderType,
  postOrdersBatch,
  PostOrdersBatchResp,
} from '#/api/orders';

import { useActiveMarginAccountView } from '#/features/account/margin/active-account/active-margin-account-view';
import { useMarginConfigsView } from '#/features/account/margin/store';
import { signOrderRequest } from '#/features/order-signature';
import { useTradeActiveMarketStoreView } from '#/features/perpetuals/active-market-context';
import { useActiveMarketSummaryState } from '#/features/perpetuals/market-info/markets-summary-context';
import * as OrderBuilderFn from '#/features/perpetuals/order-builder/functions';
import { useScaledOrderFormStore } from '#/features/perpetuals/order-builder/scaled-order/form-store';
import { useSystemConfig } from '#/features/system/system-config-context';
import { useWalletView } from '#/features/wallet/wallet-context';

import { usePrepareMarginTransfer } from '../isolated-margin/prepare-margin-transfer-context';
import { getOnBehalfOfAccountFromActiveMarginAccount } from '../shared/on-behalf-of-account';

import { formSchema } from './form-validation';
import * as Formula from './formula';

export interface RespCallback {
  (resp: Resp<PostOrdersBatchResp>): void;
}

type UnsignedOrder = Omit<CreateOrderReq, 'signature'>;

export function useScaledOrderFormSubmit() {
  const config = useSystemConfig();
  const formStore = useScaledOrderFormStore();
  const { activeMarket } = useTradeActiveMarketStoreView();
  const activeMarketSummary = useActiveMarketSummaryState();
  const [{ isLoading: isPostingOrders }, exec] = useApi(postOrdersBatch);
  const activeMarginAccountView = useActiveMarginAccountView();
  const walletView = useWalletView();
  const prepareMarginTransfer = usePrepareMarginTransfer();
  const marginConfigsView = useMarginConfigsView();

  const onBehalfOfAccount = getOnBehalfOfAccountFromActiveMarginAccount(
    walletView,
    activeMarginAccountView,
  );

  const validateForm = async () => {
    await formSchema.validate(formStore);
    formSchema.validatePriceBands(formStore, activeMarket, activeMarketSummary);
  };

  const isLoading = isPostingOrders || prepareMarginTransfer.isLoading;
  const validatePreparedOrders = (orders: readonly UnsignedOrder[]) => {
    if (activeMarket == null) {
      throw new Error(`Unexpected undefined active market`);
    }
    if (activeMarketSummary == null) {
      throw new Error(`Unexpected undefined active market summary`);
    }
    const minAmountInBaseCurrency = OrderBuilderFn.minAmountInBaseAsset(
      activeMarket,
      activeMarketSummary,
    );
    if (minAmountInBaseCurrency == null) {
      throw new Error(
        `Unexpected min amount in base currency not defined for market '${activeMarket.symbol}'`,
      );
    }

    orders.forEach((order, idx) => {
      if (order.price == null) {
        throw new Error(`Unexpected empty price in scaled order idx='${idx}'`);
      }

      if (
        !BigNumber(order.price).isFinite() ||
        !BigNumber(order.price).isGreaterThan(0)
      ) {
        throw new Yup.ValidationError(
          `Calculation resulted in an invalid orders batch. Invalid price for order at idx='${idx}'. Try adjusting the inputs.`,
        );
      }

      if (!BigNumber(order.size).isFinite()) {
        throw new Yup.ValidationError(
          `Calculation resulted in an invalid orders batch. Invalid size for order at idx='${idx}'. Try adjusting the inputs.`,
        );
      }

      if (BigNumber(order.size).isLessThan(minAmountInBaseCurrency.value)) {
        throw new Yup.ValidationError(
          `Calculation resulted in an invalid orders batch. Size '${order.size}' is less than min notional '${minAmountInBaseCurrency.formatted}' for order at idx='${idx}'. Try adjusting the inputs.`,
        );
      }
    });
  };

  const signOrder = (order: UnsignedOrder) => {
    if (onBehalfOfAccount == null) {
      throw new Error('Account is not connected');
    }
    const orderRequest = {
      ...order,
    };
    return signOrderRequest(
      orderRequest,
      config.starknet.chainId,
      onBehalfOfAccount,
    );
  };

  const submit = async (respCallback: RespCallback) => {
    await validateForm();

    if (activeMarket == null) throw new Error('Active Market required');
    if (activeMarketSummary?.mark_price == null)
      throw new Error('Active Market mark price required');
    if (formStore.startPrice == null) throw new Error('Start Price required');
    if (formStore.endPrice == null) throw new Error('End Price required');
    if (formStore.currency == null) throw new Error('Currency required');
    if (formStore.amount == null) throw new Error('Size required');
    if (formStore.totalOrders == null) throw new Error('Total Orders required');
    if (formStore.sizeSkew == null) throw new Error('Size Skew required');
    const orders = Formula.prepareOrders(
      activeMarket,
      activeMarketSummary.mark_price,
      formStore.side,
      formStore.currency,
      formStore.startPrice,
      formStore.endPrice,
      formStore.amount,
      formStore.totalOrders,
      formStore.sizeSkew,
      formStore.isPostOnly,
      formStore.timeInForce,
      formStore.isReduceOnly,
    );
    validatePreparedOrders(orders);

    if (marginConfigsView.activeMarketConfig?.margin_type === 'ISOLATED') {
      const incomingOrders = orders.map((order) => {
        if (order.price == null) {
          throw new Error('Price is required');
        }
        return {
          side: order.side,
          size: BigNumber(order.size),
          price: BigNumber(order.price),
          type: 'LIMIT' as OrderType,
          flags: order.flags ?? [],
        };
      });
      await prepareMarginTransfer.prepareMarginTransfer(incomingOrders);
    }

    const signedOrders = orders.map(signOrder);
    const request = { orders: signedOrders };

    exec(request, respCallback);
  };

  return { isLoading, submit };
}
