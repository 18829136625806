function calculator(m: number) {
  return function calculate(n: number) {
    return Math.round(n * m);
  };
}

const calculators = {
  seconds: calculator(1e3),
  minutes: calculator(6e4),
  hours: calculator(36e5),
  days: calculator(864e5),
  weeks: calculator(6048e5),
  months: calculator(26298e5),
  years: calculator(315576e5),
};

export default calculators;
