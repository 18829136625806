import BigNumber from 'bignumber.js';

import { AsyncResp, BaseReq, requestApi } from '#/api/fetch-api';

import { msToDate } from '#/utils/date';
import { nullableBigNumber } from '#/utils/number';
import { Nullable } from '#/utils/types';

import { UnixTimeMs } from './types';

export interface RawPointsLeaderboardEntry {
  readonly is_user_account?: boolean;
  readonly username: string;
  readonly rank: number;
  readonly total_points?: number;
  readonly total_share?: number;
  readonly total_volume_share?: number;
  readonly twitter_username?: string;
  readonly twitter_profile_image_url?: string;
}

export interface PointsLeaderboardEntry
  extends Omit<
    RawPointsLeaderboardEntry,
    'is_user_account' | 'total_points' | 'total_share' | 'total_volume_share'
  > {
  readonly is_user_account: boolean;
  readonly total_points: Nullable<BigNumber>;
  readonly total_share: Nullable<BigNumber>;
  readonly total_volume_share: Nullable<BigNumber>;
}

export interface PointsCampaignReq extends BaseReq {
  readonly season?: string;
}

interface PointsLeaderboardResp {
  readonly results: readonly RawPointsLeaderboardEntry[];
}

export async function getPublicPointsLeaderboard(
  req: PointsCampaignReq,
): AsyncResp<PointsLeaderboardEntry[]> {
  const { signal, season } = req;

  const resp = await requestApi<PointsLeaderboardResp>({
    signal,
    method: 'GET',
    url: `/campaigns/public/points/leaderboard/${encodeURIComponent(
      season ?? '',
    )}`,
  });

  if (!resp.ok) {
    return resp;
  }

  return {
    ...resp,
    data: resp.data.results.map(processPointsLeaderboardEntry),
  };
}

export async function getPrivatePointsLeaderboard(
  req: PointsCampaignReq,
): AsyncResp<PointsLeaderboardEntry[]> {
  const { signal, season } = req;

  const resp = await requestApi<PointsLeaderboardResp>({
    signal,
    method: 'GET',
    url: `/campaigns/private/points/leaderboard/${encodeURIComponent(
      season ?? '',
    )}`,
  });

  if (!resp.ok) {
    return resp;
  }

  return {
    ...resp,
    data: resp.data.results.map(processPointsLeaderboardEntry),
  };
}

export interface RawPointsSummary {
  readonly total_points: string;
  readonly rank: number;
  readonly total_points_24h: string;
  readonly total_points_7d: string;
  readonly season_volume: string;
  readonly lifetime_volume: string;
  readonly total_share: string;
  readonly last_updated_at: UnixTimeMs;
}

export interface PointsSummary {
  readonly rank: number;
  readonly total_points: BigNumber;
  readonly total_points_24h: BigNumber;
  readonly total_points_7d: BigNumber;
  readonly season_volume: BigNumber;
  readonly lifetime_volume: BigNumber;
  readonly total_share: BigNumber;
  readonly last_updated_at: Date;
}

export async function getPointsSummary(
  req: PointsCampaignReq,
): AsyncResp<PointsSummary> {
  const { signal, season } = req;

  const resp = await requestApi<RawPointsSummary>({
    signal,
    method: 'GET',
    url: `/campaigns/private/points/summary/${encodeURIComponent(
      season ?? '',
    )}`,
  });

  if (!resp.ok) {
    return resp;
  }

  return {
    ...resp,
    data: processPointsSummary(resp.data),
  };
}

function processPointsLeaderboardEntry(
  raw: RawPointsLeaderboardEntry,
): PointsLeaderboardEntry {
  return {
    ...raw,
    is_user_account: raw.is_user_account ?? false,
    total_points: nullableBigNumber(raw.total_points),
    total_share: nullableBigNumber(raw.total_share),
    total_volume_share: nullableBigNumber(raw.total_volume_share),
  };
}

function processPointsSummary(raw: RawPointsSummary): PointsSummary {
  return {
    rank: raw.rank,
    total_points: new BigNumber(raw.total_points),
    total_points_24h: new BigNumber(raw.total_points_24h),
    total_points_7d: new BigNumber(raw.total_points_7d),
    season_volume: new BigNumber(raw.season_volume),
    lifetime_volume: new BigNumber(raw.lifetime_volume),
    total_share: new BigNumber(raw.total_share),
    last_updated_at: msToDate(raw.last_updated_at),
  };
}

export interface RawPointsInfo {
  readonly total: string;
  readonly total_share: string;
}

export interface RawPointsHistoryEntry {
  readonly season: string;
  readonly week: number;
  readonly points: RawPointsInfo;
}

interface PointsHistoryRawResp {
  readonly results: readonly RawPointsHistoryEntry[];
}

export interface PointsInfo {
  readonly total: BigNumber;
  readonly total_share: BigNumber;
}

export interface PointsHistoryEntry {
  readonly season: string;
  readonly week: number;
  readonly points: PointsInfo;
}

export function processPointsHistoryEntry(
  raw: RawPointsHistoryEntry,
): PointsHistoryEntry {
  return {
    ...raw,
    points: processPointsInfo(raw.points),
  };
}

function processPointsInfo(raw: RawPointsInfo): PointsInfo {
  return {
    total: new BigNumber(raw.total),
    total_share: new BigNumber(raw.total_share),
  };
}

export async function getPointsHistory(
  req: PointsCampaignReq,
): AsyncResp<PointsHistoryEntry[]> {
  const { signal, season } = req;

  const resp = await requestApi<PointsHistoryRawResp>({
    signal,
    method: 'GET',
    url: `/campaigns/private/points/history/${encodeURIComponent(
      season ?? '',
    )}`,
  });

  if (!resp.ok) {
    return resp;
  }

  return {
    ...resp,
    data: resp.data.results.map(processPointsHistoryEntry),
  };
}

export interface RawLPPointsEntry {
  readonly market: string;
  readonly pool: string;
  readonly quote_quality: string;
  readonly maker_volume_score: string;
  readonly wallet_score: string;
  readonly total_market_score: string;
  readonly score_share: string;
  readonly market_pool_share: string;
  readonly total_accrued_points: string;
  readonly total_distributed_points: string;
}

export interface LPPointsEntry {
  readonly market: string;
  readonly tier: string;
  readonly quote_quality: BigNumber;
  readonly maker_volume_score: BigNumber;
  readonly lp_score: BigNumber;
  readonly instrument_share: BigNumber;
  readonly score_share: BigNumber;
  readonly lp_points_share: BigNumber;
  readonly lp_points: BigNumber;
}

interface LPPointsRawResp {
  readonly results: readonly RawLPPointsEntry[];
}

export function processLPPointsEntry(raw: RawLPPointsEntry): LPPointsEntry {
  const totalAccruedPoints = new BigNumber(raw.total_accrued_points);
  const totalDistributedPoints = new BigNumber(raw.total_distributed_points);
  const lpPointsShare = totalDistributedPoints.isZero()
    ? new BigNumber(0)
    : totalAccruedPoints.div(totalDistributedPoints);

  return {
    market: raw.market,
    tier: raw.pool,
    quote_quality: new BigNumber(raw.quote_quality),
    maker_volume_score: new BigNumber(raw.maker_volume_score),
    lp_score: new BigNumber(raw.wallet_score),
    score_share: new BigNumber(raw.score_share),
    instrument_share: new BigNumber(raw.market_pool_share),
    lp_points_share: lpPointsShare,
    lp_points: new BigNumber(raw.total_accrued_points),
  };
}

export async function getLPPointsAllMarkets(
  req: BaseReq,
): AsyncResp<LPPointsEntry[]> {
  const { signal } = req;

  const resp = await requestApi<LPPointsRawResp>({
    signal,
    method: 'GET',
    url: `/points_data/ALL/Maker`,
  });

  if (!resp.ok) {
    return resp;
  }

  return {
    ...resp,
    data: resp.data.results.map(processLPPointsEntry),
  };
}
