/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useEffect, useRef } from 'react';

type Destructor = () => void;

/**
 * Set your callback function as a first parameter and a delay (in milliseconds) for the second argument.
 * You can also stop the timer passing null instead the delay or even, execute it right away passing 0.
 *
 * @see https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 * @param leading - additionally invoke once on the leading edge of the timeout
 * @param key - used to invalidate the interval when the key changes
 */
export function useInterval(
  callback: (() => Destructor) | (() => void),
  delay: number | null,
  leading = true,
  key = '',
) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (!delay && delay !== 0) {
      return;
    }

    const destructors: Destructor[] = [];

    const executeCallback = () => {
      const destructor = savedCallback.current();
      if (destructor != null) destructors.push(destructor);
    };

    const executeDestructors = () => {
      destructors.map((d) => d());
    };

    if (leading) executeCallback();
    const id = setInterval(executeCallback, delay);

    return () => {
      clearInterval(id);
      executeDestructors();
    };
  }, [delay, leading, key]);
}
