export const TWITTER_IMG_SIZES = '(max-width: 600px) 60px, 400px';
export const NFT_IMG_SIZES = '(max-width: 600px) 60px, 400px';

export function twitterProfileUrl(username: string): string {
  return `https://twitter.com/${username}`;
}

export function twitterHandle(username: string): string {
  if (username === '') {
    return '';
  }
  return `@${username}`;
}

export function twitterImgSrcSet(url: string): string {
  if (url === '') {
    return '';
  }

  // Low-resolution image size
  const lowRes = '60w';

  // Hi resolution image size
  const highRes = '400w';
  const highResUrl = url.replace(/_normal|_x96/u, '_400x400');

  return `${url} ${lowRes}, ${highResUrl} ${highRes}`;
}
