import type { VaultHistoryReq } from '#/api/vaults-history';

const MAX_DATA_POINTS = 100;

/**
 * API can return a maximum of 100 data points.
 */
export function calcResolution(hours: number): VaultHistoryReq['resolution'] {
  if (hours <= MAX_DATA_POINTS) return '1h';
  if (hours <= MAX_DATA_POINTS * 8) return '8h';
  return 'alltime';
}
