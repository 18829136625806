import { Keys } from "./en";

const translations: Record<Keys, string> = {
  "Display trade history on chart": "Handelsverlauf auf dem Chart anzeigen",
  "Add markets to Favorites in the Markets Browser":
    "Fügen Sie Märkte in der Marktübersicht zu den Favoriten hinzu",
  "Log in": "Anmelden",
  "Export embedded wallet": "Eingebettete Wallet exportieren",
  "Email or Social": "E-Mail oder Soziales",
  "Failed to load balances": "Laden der Guthaben fehlgeschlagen",
  "No balances": "Keine Guthaben",
  "Verified Operator": "Verifizierter Betreiber",
  "Strategy": "Strategie",
  "Multi-Strategy": "Multi-Strategie",
  "Master Vault": "Haupt-Tresor",
  "Master Vault Details": "Haupt-Tresor Details",
  "Annualized": "Jährlich",
  "Depositors": "Einzahler",
  "No trade history": "Keine Handelsverlauf",
  "Unwinding Progress": "Abwicklungsfortschritt",
  "What are Vaults?": "Was sind Tresore?",
  "APR calculated as the Annualised 30-day Return":
    "APR berechnet als die jährliche 30-Tage-Rendite",
  "APR": "APR",
  "Initializing": "Initialisierung",
  "Awaiting Deposit": "Warte auf Einzahlung",
  "Vault Age": "Tresor-Alter",
  "30D Return": "30T-Rendite",
  "{{field}} must be greater than the minimum initial deposit of {{minInitialDeposit}}":
    "{{field}} muss größer als die Mindesteinzahlung von {{minInitialDeposit}} sein",
  "Minimum deposit": "Mindesteinzahlung",
  "Withdrawal amount exceeds available vault shares":
    "Abhebungsbetrag überschreitet verfügbare Tresor-Anteile",
  "Deposit would exceed maximum vault TVL of {{maxTVL}}":
    "Einzahlung würde den maximalen Tresor-TVL von {{maxTVL}} überschreiten",
  "{{field}} is required": "{{field}} ist erforderlich",
  "Lockup Remaining": "Verbleibende Sperrfrist",
  "{{days}}D": "{{days}}T",
  "<1>{{days}}</1> D": "<1>{{days}}</1> T",
  "<1>{{hours}}</1> H": "<1>{{hours}}</1> Std",
  "<1>{{minutes}}</1> M": "<1>{{minutes}}</1> Min",
  "Vault settings submitted": "Tresor-Einstellungen eingereicht",
  "Vault settings updated": "Tresor-Einstellungen aktualisiert",
  "Vault Settings": "Tresor-Einstellungen",
  "Vault not found": "Tresor nicht gefunden",
  "The owner receives a {{profitShare}} profit share.":
    "Der Eigentümer erhält einen Gewinnanteil von {{profitShare}}.",
  "This vault is entirely owned by the community.":
    "Dieser Tresor ist vollständig im Besitz der Gemeinschaft.",
  "Withdraw from Vault submitted": "Auszahlung aus Tresor eingereicht",
  "Deposit to Vault submitted": "Einzahlung in Tresor eingereicht",
  "Unknown error. Try again.": "Unbekannter Fehler. Versuchen Sie es erneut.",
  "Vault Deposit": "Tresor-Einzahlung",
  "Vault Withdrawal": "Tresor-Auszahlung",
  "No Lockup": "Keine Sperrfrist",
  "Lockup period is not over yet": "Sperrfrist ist noch nicht abgelaufen",
  "Protocol": "Protokoll",
  "24H": "24Std",
  "7D": "7T",
  "30D": "30T",
  "All": "Alle",
  "You have no investments in vaults":
    "Sie haben keine Investitionen in Tresoren",
  "Total Value": "Gesamtwert",
  "Investments Made": "Getätigte Investitionen",
  "Operator": "Betreiber",
  "Manage Vault": "Tresor verwalten",
  "Switch to the operator account": "Zum Betreiberkonto wechseln",
  "Active": "Aktiv",
  "My Vaults": "Meine Tresore",
  "Closed": "Geschlossen",
  "Deposited": "Eingezahlt",
  "Lifetime ROI": "Gesamtertrag",
  "Owners Equity": "Eigenkapital der Inhaber",
  "Top Trending": "Top-Trend",
  "Owner Share": "Inhaberanteil",
  "Vault Details": "Tresor-Details",
  "Contract": "Vertrag",
  "Withdraw from Vault": "Aus Tresor abheben",
  "Withdraw from Vault successful": "Abhebung aus Tresor erfolgreich",
  "Failed to withdraw from Vault": "Abhebung aus Tresor fehlgeschlagen",
  "Name": "Name",
  "Deposit": "Einzahlung",
  "Tokens": "Token",
  "Avg. Entry Price": "Durchschn. Einstiegspreis",
  "Current Price": "Aktueller Preis",
  "P&L": "G&V",
  "My Vault Deposit": "Mein Tresor-Einzahlung",
  "Vaults": "Tresore",
  "Create": "Erstellen",
  "Create My Vault": "Meinen Tresor erstellen",
  "Vault Name": "Tresorname",
  "Vault Description": "Tresorbeschreibung",
  "Token Symbol": "Token-Symbol",
  "Deposit Amount": "Einzahlungsbetrag",
  "Vault description will be visible publicly and you will not be able to edit this later.":
    "Die Tresorbeschreibung wird öffentlich sichtbar sein und kann später nicht mehr bearbeitet werden.",
  "Once created, deposit at least {{minDeposit}} USDC to activate the vault. At this moment, only the Max TVL can be changed later.":
    "Nachdem der Tresor erstellt wurde, müssen Sie mindestens {{minDeposit}} USDC einzahlen, um ihn zu aktivieren. Zurzeit kann nur der Maximalertrag geändert werden.",
  "Create Vault": "Tresor erstellen",
  "Profit Share": "Gewinnbeteiligung",
  "Lockup Period (Days)": "Sperrfrist (Tage)",
  "Vault was successfully created and is being initialized":
    "Tresor wurde erfolgreich erstellt und wird initialisiert",
  "Failed to create a vault": "Erstellung des Tresors fehlgeschlagen",
  "Maximum TVL": "Maximaler TVL",
  "No limit": "Keine Begrenzung",
  "Vault Address": "Tresoradresse",
  "Deposit to Vault successful": "Einzahlung in Tresor erfolgreich",
  "Failed to deposit to Vault": "Einzahlung in Tresor fehlgeschlagen",
  "Deposit to Vault": "In Tresor einzahlen",
  "Vault": "Tresor",
  "Owner": "Inhaber",
  "Age": "Alter",
  "Failed to load vaults": "Laden der Tresore fehlgeschlagen",
  "No vaults available": "Keine Tresore verfügbar",
  "Performance": "Leistung",
  "Vault Value": "Tresorwert",
  "My Deposit": "Meine Einzahlung",
  "Total P&L": "Gesamte G&V",
  "Protocol Vaults": "Protokoll-Tresore",
  "User Vaults": "Benutzer-Tresore",
  "No Data": "Keine Daten",
  "Last Month Return": "Rendite letzter Monat",
  "TVL": "TVL",
  "Total Earnings": "Gesamteinnahmen",
  "About": "Über",
  "Vault Stats": "Tresor-Statistiken",
  "My Stats": "Meine Statistiken",
  "Vault Contract": "Tresor-Vertrag",
  "Description": "Beschreibung",
  "Max Drawdown": "Maximaler Drawdown",
  "Volume": "Volumen",
  "All-Time P&L": "G&V aller Zeiten",
  "Days": "Tage",
  "Lockup Period": "Sperrfrist",
  "Search Vaults": "Tresore suchen",
  "Blog": "Blog",
  "Block Explorer": "Block-Explorer",
  "Close Vault": "Tresor schließen",
  "Close Vault successful": "Tresor erfolgreich geschlossen",
  "You must close all open positions to close your Vault. Closed vaults cannot be reopened.":
    "Sie müssen alle offenen Positionen schließen, um Ihren Tresor zu schließen. Geschlossene Tresore können nicht wieder geöffnet werden.",
  "Close My Vault": "Meinen Tresor schließen",
  "Failed to Close the Vault": "Schließen des Tresors fehlgeschlagen",
  "Only the Main Account can manage the vault":
    "Nur das Hauptkonto kann den Tresor verwalten",
  "Allocate Collateral": "Sicherheiten zuweisen",
  "Switch to Main Account": "Zum Hauptkonto wechseln",
  "Switch to Account": "Zum Konto wechseln",
  "You are currently trading in the {{market}} market.":
    "Sie handeln derzeit im {{market}}-Markt.",
  "To start trading on Isolated Market you must Allocate Collateral from your Main Account and switch to this Market Account.":
    "Um im Isolierten Markt zu handeln, müssen Sie Sicherheiten aus Ihrem Hauptkonto zuweisen und auf dieses Marktkonto wechseln.",
  "To trade in this market, switch to the Main Account first.":
    "Um in diesem Markt zu handeln, wechseln Sie zuerst zum Hauptkonto.",
  "This account can only trade in the {{market}} market.":
    "Dieses Konto kann nur im {{market}}-Markt handeln.",
  "Isolated Market": "Isolierter Markt",
  "This feature currently is still in Beta. Backup your Paradex Private Key for additional safety.":
    "Diese Funktion befindet sich derzeit noch in der Beta-Phase. Sichern Sie Ihren Paradex-Private-Key zur zusätzlichen Sicherheit.",
  "Deposit failed": "Einzahlung fehlgeschlagen",
  "Deposit in progress": "Einzahlung läuft",
  "External Account": "Externes Konto",
  "Tx Hashes": "Transaktions-Hashes",
  "Continue to {{bridge}}": "Weiter zu {{bridge}}",
  "By clicking Continue to {{bridge}}, you will be sent to {{bridge}} website to bridge your funds out of Paradex.":
    "Wenn Sie auf Weiter zu {{bridge}} klicken, werden Sie zur {{bridge}}-Website weitergeleitet, um Ihre Gelder von Paradex zu übertragen.",
  "By clicking Continue to {{bridge}}, you will be sent to {{bridge}} website to bridge your funds to Paradex.":
    "Wenn Sie auf Weiter zu {{bridge}} klicken, werden Sie zur {{bridge}}-Website weitergeleitet, um Ihre Gelder zu Paradex zu übertragen.",
  "Refer": "Empfehlen",
  "Address": "Adresse",
  "Date Joined": "Beitrittsdatum",
  "Referral Code": "Empfehlungscode",
  "Volume Traded": "Gehandeltes Volumen",
  "Bridge": "Brücke",
  "Withdraw to": "Abheben auf",
  "Deposit from": "Einzahlung von",
  "Position is already closed": "Position ist bereits geschlossen",
  "Your Paradex L2 Address is NOT a Public Starknet Address. If you try to send funds to this address on Public Starknet via a cross chain bridge you risk losing your funds. <1>Read more</1>.":
    "Ihre Paradex L2-Adresse ist KEINE öffentliche Starknet-Adresse. Wenn Sie versuchen, Gelder an diese Adresse auf dem öffentlichen Starknet über eine Cross-Chain-Brücke zu senden, riskieren Sie den Verlust Ihrer Gelder. <1>Mehr lesen</1>.",
  "{{chain}} Address": "{{chain}}-Adresse",
  "Copy Paradex Private Key": "Paradex-Privaten Schlüssel kopieren",
  "Your account will be liquidated if Margin Ratio reaches 100%":
    "Ihr Konto wird liquidiert, wenn das Marge-Verhältnis 100% erreicht",
  "Liquidation": "Liquidation",
  "Fee": "Gebühr",
  "Maker": "Maker",
  "Maker Score": "Maker Score",
  "Maker XP Share": "Maker XP-Anteil",
  "Fee XP Share": "Gebühren XP-Anteil",
  "Fee XP": "Gebühren XP",
  "Maker XP": "Maker XP",
  "Liquidation {{side}} Fill": "Liquidation {{side}} Ausführung",
  "Settings": "Einstellungen",
  "Market Score": "Marktwertung",
  "Season {{season}} (coming soon)": "Saison {{season}} (bald verfügbar)",
  "Confirm Buy": "Kauf bestätigen",
  "Confirm Sell": "Verkauf bestätigen",
  "Other XP": "Andere XP",
  "Estimated Receivable Amount": "Geschätzter Empfangsbetrag",
  "Socialized Loss active. Click to learn more.":
    "Sozialisierter Verlust aktiv. Klicken Sie, um mehr zu erfahren.",
  "Analytics": "Analytik",
  "Sign In to see your account XP stats":
    "Melden Sie sich an, um Ihre Kontopunktestatistiken zu sehen",
  "<1>Clock Is Out Of Sync</1> The clock on your device is out of sync. This may cause issues with the application. Please make sure your device clock is set to be auto. For more details please <2>see documentation</2>":
    "<1>Clock Is Out Of Sync</1> Die Uhr auf Ihrem Gerät ist nicht synchron. Dies kann Probleme mit der Anwendung verursachen. Bitte stellen Sie sicher, dass die Uhr Ihres Geräts auf automatisch eingestellt ist. Weitere Informationen finden Sie in der <2>Dokumentation</2>",
  "Failed to fetch max withdrawable amount":
    "Fehler beim Abrufen des maximalen Auszahlungsbetrags",
  "It is not possible to withdraw more than your Max Withdrawable Amount.":
    "Es ist nicht möglich, mehr als Ihren maximalen Auszahlungsbetrag abzuheben.",
  "Max Withdrawable Amount": "Maximaler Auszahlungsbetrag",
  "XP Explainer": "XP-Erklärung",
  "Pool": "Stufe",
  "Quote Quality": "Kursqualität",
  "Create Your Public Username": "Öffentlichen Benutzernamen erstellen",
  "Edit Your Public Username": "Öffentlichen Benutzernamen bearbeiten",
  "You have been logged out.": "Sie wurden abgemeldet.",
  "Maker Volume Score": "Maker Volumen Score",
  "Fee Score": "Gebührenwertung",
  "Score Share": "Wertungsanteil",
  "Instrument Share": "Instrumentenanteil",
  "XP": "XP",
  "Total XP": "Gesamt XP",
  "24h Change": "24h Änderung",
  "Leaderboard": "Rangliste",
  "Cancel": "Abbrechen",
  "Portfolio": "Portfolio",
  "Trade": "Handel",
  "Build": "Erstellen",
  "Referrals Explainer": "Empfehlungen-Erklärung",
  "How It Works": "Wie es funktioniert",
  "My Referrals": "Meine Empfehlungen",
  "Your Referral Code": "Ihr Empfehlungscode",
  "Affiliate Program": "Partnerprogramm",
  "Enrolled": "Eingeschrieben",
  "Traders Referred": "Empfohlene Händler",
  "Referral Rewards": "Empfehlungsbelohnungen",
  "Referral XP": "Empfehlung XP",
  "Invite Friends": "Freunde einladen",
  "Share": "Teilen",
  "Earn": "Verdienen",
  "Give": "Geben",
  "Share your code to invite friends to join Paradex":
    "Teilen Sie Ihren Code, um Freunde zur Teilnahme an Paradex einzuladen",
  "<1>{{amount}}</1> Fees": "<1>{{amount}}</1> Gebühren",
  "You receive {{amount}} of the referred user's fees":
    "Sie erhalten {{amount}} der Gebühren des empfohlenen Benutzers",
  "<1>{{amount}}</1> XP": "<1>{{amount}}</1> XP",
  "You receive {{amount}} of the referred user's XP":
    "Sie erhalten {{amount}} der XP des empfohlenen Benutzers",
  "<1>{{amount}}</1> Discount": "<1>{{amount}}</1> Rabatt",
  "Your friend receives {{amount}} discount on fees":
    "Ihr Freund erhält {{amount}} Rabatt auf die Gebühren",
  "Create and share your custom referral code":
    "Erstellen und teilen Sie Ihren individuellen Empfehlungscode",
  "If you have a strong social media following (<1>{{followers}}+</1>), you may be eligible for our <2>VIP Affiliate Program</2> with higher commissions and XP.":
    "Wenn Sie eine starke soziale Medienpräsenz (<1>{{followers}}+</1> Follower) haben, können Sie für unser VIP-Partnerprogramm in Frage kommen. Sie können auch unsere öffentliche <2>Partner-Rangliste</2> einsehen, um zu sehen, wie Sie im Vergleich abschneiden.",
  "You can track our <1>Affiliate Leaderboard</1> to see how you stack up.":
    "Sie können unsere öffentliche <1>Partner-Rangliste</1> einsehen, um zu sehen, wie Sie im Vergleich abschneiden.",
  "Apply": "Anwenden",
  "Log in to see your Referral History.":
    "Melden Sie sich an, um Ihren Empfehlungsverlauf zu sehen.",
  "Welcome to Paradex": "Willkommen bei Paradex",
  "Congrats! The referral code you used is now active! It means you can start enjoying a discount on your trading fees!":
    "Glückwunsch! Der verwendete Empfehlungscode ist jetzt aktiv! Das bedeutet, dass Sie einen Rabatt auf Ihre Handelsgebühren erhalten können!",
  "Congrats! The referral code <1>{{codeProvided}}</1> used is now active!":
    "Glückwunsch! Der verwendete Empfehlungscode <1>{{codeProvided}}</1> ist jetzt aktiv!",
  "Referral code not activated. The code can only be applied once during the initial onboarding.":
    "Empfehlungscode nicht aktiviert. Der Code kann nur einmal während des ersten Onboardings angewendet werden.",
  "Referral code <1>{{codeProvided}}</1> not activated. You have already onboarded with <1>{{referredBy}}</1>.":
    "Empfehlungscode <1>{{codeProvided}}</1> nicht aktiviert. Sie haben bereits mit <1>{{referredBy}}</1> an Bord.",
  "Referral Code Applied": "Empfehlungscode angewendet",
  "You will receive <1>{{userAmount}}</1> of referred user's <1>fees</1> <2>and</2> <1>{{userAmountPoints}} XP</1>. They will receive a <1>{{friendAmount}}</1> discount.":
    "Sie erhalten <1>{{userAmount}}</1> der Gebühren des empfohlenen Benutzers <1>und</1> <1>{{userAmountPoints}} XP</1>. Sie erhalten einen Rabatt von <1>{{friendAmount}}</1>.",
  "Community": "Gemeinschaft",
  "Environments": "Umgebungen",
  "Language": "Sprache",
  "System Status": "Systemstatus",
  "Docs": "Dokumente",
  "Code Samples": "Codebeispiele",
  "More": "Mehr",
  "Stats": "Statistiken",
  "Join Discord": "Discord beitreten",
  "Notifications": "Benachrichtigungen",
  "All caught up!": "Alles auf dem neuesten Stand!",
  "You have no new notifications": "Sie haben keine neuen Benachrichtigungen",
  "Connect Wallet": "Wallet verbinden",
  "WalletConnect is taking too long to open. Please refresh the page and try again.":
    "WalletConnect benötigt zu lange zum Öffnen. Bitte aktualisieren Sie die Seite und versuchen Sie es erneut.",
  "Unexpected error opening WalletConnect modal. Please refresh the page and try again.":
    "Unerwarteter Fehler beim Öffnen des WalletConnect-Modals. Bitte aktualisieren Sie die Seite und versuchen Sie es erneut.",
  "Connect your Wallet": "Verbinden Sie Ihre Wallet",
  "Link Wallet": "Wallet verlinken",
  "You will receive a signature request. Signing is free and will not send a transaction.":
    "Sie erhalten eine Signaturanforderung. Die Signatur ist kostenlos und sendet keine Transaktion.",
  "Generate Paradex Chain wallet": "Paradex-Chain-Wallet generieren",
  "Generating Paradex Chain wallet": "Paradex-Chain-Wallet wird generiert",
  "Paradex Chain wallet generated": "Paradex-Chain-Wallet generiert",
  "Verify that you own this wallet":
    "Überprüfen, dass Sie dieses Wallet besitzen",
  "Remember Me": "Erinnere dich an mich",
  "Only use 'Remember Me' if you are using a secure device you own. Selecting this option can expose your information to  others if you are accessing this wallet from a public device.":
    'Verwenden Sie "Erinnere dich an mich" nur, wenn Sie ein sicheres Gerät verwenden, das Ihnen gehört. Wenn Sie diese Option auswählen, können Ihre Informationen anderen zugänglich gemacht werden, wenn Sie auf diese Wallet von einem öffentlichen Gerät aus zugreifen.',
  "Continue": "Fortsetzen",
  "Disconnect Wallet": "Wallet trennen",
  "Disconnect": "Trennen",
  "Account": "Konto",
  "Username": "Benutzername",
  "Edit": "Bearbeiten",
  "Your username is now hidden. It is not visible to other users.":
    "Ihr Benutzername ist jetzt verborgen. Er ist für andere Benutzer nicht sichtbar.",
  "Your username will be visible to other users unless you choose to hide it. You can always change it later.":
    "Ihr Benutzername wird für andere Benutzer sichtbar sein, es sei denn, Sie entscheiden sich dafür, ihn zu verbergen. Sie können ihn jederzeit später ändern.",
  "Hide my Username": "Meinen Benutzernamen verbergen",
  "Save": "Speichern",
  "Username is required": "Benutzername ist erforderlich",
  "Username must be between 5 and 20 characters":
    "Der Benutzername muss zwischen 5 und 20 Zeichen lang sein",
  "Username can only contain letters and numbers":
    "Der Benutzername darf nur Buchstaben und Zahlen enthalten",
  "Wallets": "Wallets",
  "Paradex Block Explorer": "Paradex Block Explorer",
  "Log out": "Abmelden",
  "No markets available": "Keine Märkte verfügbar",
  "Last Price": "Letzter Preis",
  "Open Interest": "Offenes Interesse",
  "24h Volume": "24h Volumen",
  "Mark Price": "Marktpreis",
  "Spot Price": "Spotpreis",
  "Input Price": "Eingabepreis",
  "Position Mark Price": "Positionsmarkpreis",
  "Eight hour funding rate that is continuously accrued on positions and settled upon any trade. If the funding rate is positive, longs pay shorts. If negative, shorts pay longs.":
    "Achtstündiger Finanzierungssatz, der kontinuierlich auf Positionen anfällt und bei jedem Handel abgerechnet wird. Wenn der Finanzierungssatz positiv ist, zahlen Longs Shorts. Wenn negativ, zahlen Shorts Longs.",
  "8h Funding": "8h Finanzierung",
  "Limit": "Limit",
  "Market": "Markt",
  "Stop Limit": "Stop-Limit",
  "Stop Market": "Stop-Market",
  "Scaled Order": "Skalierte Order",
  "Trigger Price": "Auslösepreis",
  "Amount": "Menge",
  "Reduce Only": "Nur reduzieren",
  "Reduce Only will increase position": "Reduce Only wird die Position erhöhen",
  "Your order stays open until it is filled or you decide to cancel.":
    "Ihre Bestellung bleibt offen, bis sie ausgeführt oder von Ihnen storniert wird.",
  "Your order gets filled right away, partially or fully, and whatever is left gets canceled instantly.":
    "Ihre Bestellung wird sofort teilweise oder vollständig ausgeführt und der Rest wird sofort storniert.",
  "Conditional limit order that serves to add liquidity to the order book as a Maker order.":
    "Bedingte Limitorder, die dazu dient, Liquidität zum Orderbuch als Maker-Order hinzuzufügen.",
  "Currently the fees are fixed and uniform across the platform. Maker {{makerFee}} / Taker {{takerFee}}":
    "Derzeit sind die Gebühren auf der gesamten Plattform fest und einheitlich. Maker {{makerFee}} / Taker {{takerFee}}",
  "Value": "Wert",
  "Buy": "Kaufen",
  "BUY": "KAUFEN",
  "LONG": "LANG",
  "Sell": "Verkaufen",
  "SELL": "VERKAUFEN",
  "SHORT": "KURZ",
  "Order Value": "Auftragswert",
  "Current Position": "Aktuelle Position",
  "Account Value": "Kontowert",
  "The total value of your account at current mark prices":
    "Der Gesamtwert Ihres Kontos zu aktuellen Marktpreisen",
  "Unrealized P&L": "Nicht realisierte P&L",
  "Free Collateral": "Freie Sicherheiten",
  "Account Leverage": "Kontohebel",
  "Margin": "Marge",
  "Margin Ratio": "Marge-Verhältnis",
  "Initial Margin": "Anfangsmarge",
  "Maintenance Margin": "Wartungsmarge",
  "Order Book": "Orderbuch",
  "Trades": "Handel",
  "Price": "Preis",
  "Size": "Größe",
  "Total": "Gesamt",
  "Time": "Zeit",
  "Spread": "Spread",
  "Positions": "Positionen",
  "Open Orders": "Offene Aufträge",
  "Trade History": "Handelshistorie",
  "Order History": "Auftragshistorie",
  "Funding Payments": "Finanzierungszahlungen",
  "Side": "Seite",
  "Entry Price": "Einstiegspreis",
  "Liquidation Price": "Liquidationspreis",
  "Breakeven Price": "Break-Even-Preis",
  "Unrealized Funding": "Nicht realisierte Finanzierung",
  "Close Position": "Position schließen",
  "Market Close": "Marktschluss",
  "Close Long": "Long schließen",
  "Close Short": "Short schließen",
  "Type": "Typ",
  "Filled": "Gefüllt",
  "Triggered": "Ausgelöst",
  "Instruction": "Anweisung",
  "Client Order Id": "Kundenbestell-ID",
  "Status": "Status",
  "NEW": "NEU",
  "OPEN": "OFFEN",
  "UNTRIGGERED": "Nicht ausgelöst",
  "CLOSED": "GESCHLOSSEN",
  "Pending": "Ausstehend",
  "Complete": "Vollständig",
  "Completed": "Vollständig",
  "Failed": "Fehlgeschlagen",
  "Bust": "Fehlschlag",
  "Value / Fee": "Wert / Gebühr",
  "Liquidity": "Liquidität",
  "Tx Hash": "Tx Hash",
  "Trade Id": "Handels-ID",
  "Remaining": "Verbleibend",
  "Cancel Reason": "Grund für die Stornierung",
  "User Canceled": "Benutzer hat storniert",
  "Not Enough Margin": "Nicht genug Marge",
  "Empty Market": "Leerer Markt",
  "Post Only": "Nur posten",
  "Post Only Would Cross": "Nur posten würde überqueren",
  "Remaining IOC Cancel": "Verbleibende IOC-Stornierung",
  "Unexpected Failure": "Unerwarteter Fehler",
  "Deleverage": "Hebelwirkung reduzieren",
  "In Liquidation": "In Liquidation",
  "Self Trade": "Eigenhandel",
  "Asset Unavailable": "Vermögenswert nicht verfügbar",
  "Asset Expired": "Vermögenswert abgelaufen",
  "Order Type Invalid": "Auftragstyp ungültig",
  "Price Not Available": "Preis nicht verfügbar",
  "Expired": "Abgelaufen",
  "Exceeds Max Slippage": "Überschreitet Maximal-Schlupf",
  "Timeout": "Zeitüberschreitung",
  "Order Exceeds Position Limit": "Auftrag überschreitet Positions-Limit",
  "Order Id": "Auftrags-ID",
  "Payment": "Zahlung",
  "Cancel All": "Alles stornieren",
  "{{orderSize}} will be closed at Market price":
    "{{orderSize}} wird zum Marktpreis geschlossen",
  "By connecting a wallet, you agree to <1>Paradex Terms of Service</1> and represent and warrant to Paradex that you are not a <2>Restricted Person</2>.":
    "Durch das Verbinden einer Wallet stimmen Sie den <1>Paradex-Nutzungsbedingungen</1> zu und versichern und garantieren Paradex, dass Sie keine <2>eingeschränkte Person</2> sind.",
  "Wallet": "Wallet",
  "Deposit_verb": "Einzahlen",
  "Deposit_noun": "Einzahlung",
  "Withdraw": "Abheben",
  "Withdrawal": "Abhebung",
  "Transfers": "Überweisungen",
  "Transfer": "Überweisung",
  "Collateral": "Sicherheit",
  "Asset": "Vermögenswert",
  "Wallet Balance": "Wallet-Guthaben",
  "Actions": "Aktionen",
  "Action": "Aktion",
  "External Tx Hash": "Externer Tx Hash",
  "Withdraw to Wallet": "Zur Wallet abheben",
  "Deposit to Paradex": "Einzahlung auf Paradex",
  "You have no balance in your Ethereum wallet. Deposit some {{asset}} and try again.":
    "Sie haben kein Guthaben in Ihrer Ethereum-Wallet. Zahlen Sie etwas {{asset}} ein und versuchen Sie es erneut.",
  "It is not possible to deposit more than your current balance.":
    "Es ist nicht möglich, mehr als Ihr aktuelles Guthaben einzuzahlen.",
  "Available": "Verfügbar",
  "Initiate a deposit from your Ethereum wallet to the Paradex bridge. These transactions":
    "Leiten Sie eine Einzahlung von Ihrer Ethereum-Wallet zur Paradex-Brücke ein. Diese Transaktionen",
  "Initiate a deposit from your Ethereum wallet to the Paradex bridge. These transactions may take several minutes depending on network conditions. For more information check out our <1>docs</1>.":
    "Leiten Sie eine Einzahlung von Ihrer Ethereum-Wallet zur Paradex-Brücke ein. Diese Transaktionen können je nach Netzwerkbedingungen mehrere Minuten dauern. Weitere Informationen finden Sie in unseren <1>Dokumenten</1>.",
  "read more": "mehr lesen",
  "Initiate Deposit to Bridge": "Einzahlung zur Brücke initiieren",
  "Enable USDC on Paradex": "USDC auf Paradex aktivieren",
  "In order to deposit you must allow Paradex access to at least {{amount}}.":
    "Um eine Einzahlung vorzunehmen, müssen Sie Paradex Zugriff auf mindestens {{amount}} erlauben.",
  "Enable USDC": "USDC aktivieren",
  "Withdraw initiated": "Abhebung eingeleitet",
  "Withdrawals are a two-step process. First initiate a withdrawal from Paradex":
    "Abhebungen sind ein zweistufiger Prozess. Zuerst initiieren Sie eine Abhebung von Paradex",
  "Withdrawals are a two-step process. First initiate a withdrawal from Paradex to the Paradex Bridge. This first step can take 5-16 hours (10 on average). Second, initiate your withdrawal from the Paradex Bridge to your Ethereum wallet via the Deposits / Withdrawals table. For more information check out our <1>docs</1>.":
    "Abhebungen sind ein zweistufiger Prozess. Zuerst initiieren Sie eine Abhebung von Paradex zur Paradex-Brücke. Dieser erste Schritt kann 5-16 Stunden dauern (10 im Durchschnitt). Zweitens, initiieren Sie Ihre Abhebung von der Paradex-Brücke zu Ihrer Ethereum-Wallet über die Einzahlungen-/Abhebungentabelle. Weitere Informationen finden Sie in unseren <1>Dokumenten</1>.",
  "Initiate Withdrawal to Bridge": "Abhebung zur Brücke initiieren",
  "You have no funds to withdraw. It is not possible to withdraw more than your free collateral.":
    "Sie haben keine Mittel zum Abheben. Es ist nicht möglich, mehr als Ihre freien Sicherheiten abzuheben.",
  "It is not possible to withdraw more than your free collateral.":
    "Es ist nicht möglich, mehr als Ihre freien Sicherheiten abzuheben.",
  "You have no collateral": "Sie haben keine Sicherheiten",
  "You have no open positions": "Sie haben keine offenen Positionen",
  "You have no open orders": "Sie haben keine offenen Aufträge",
  "You have no trades": "Sie haben keine Trades",
  "You have no orders": "Sie haben keine Aufträge",
  "You have no funding payments": "Sie haben keine Finanzierungszahlungen",
  "You have no transfers": "Sie haben keine Überweisungen",
  "Account in liquidation": "Konto in Liquidation",
  "Account is being liquidated. Any open positions will be reduced. Trading now is disabled.":
    "Das Konto wird liquidiert. Alle offenen Positionen werden reduziert. Der Handel ist jetzt deaktiviert.",
  "Account liquidated": "Konto liquidiert",
  "Your account was liquidated on {{createdAt}}. Any open positions were reduced.":
    "Ihr Konto wurde am {{createdAt}} liquidiert. Alle offenen Positionen wurden reduziert.",
  "Deposit to Paradex successful": "Einzahlung auf Paradex erfolgreich",
  "Deposit to Paradex failed": "Einzahlung auf Paradex fehlgeschlagen",
  "Unexpected error. Try again.":
    "Unerwarteter Fehler. Versuchen Sie es erneut.",
  "Deposit initiated": "Einzahlung eingeleitet",
  "Deposit to bridge initiated": "Einzahlung zur Brücke eingeleitet",
  "Deposit to Paradex available": "Einzahlung auf Paradex verfügbar",
  "Go to Deposits": "Zu Einzahlungen gehen",
  "Deposit to Paradex in progress": "Einzahlung auf Paradex in Bearbeitung",
  "Transfer successful": "Überweisung erfolgreich",
  "Limit {{side}} Order Fill": "Limit {{side}} Orderausführung",
  "{{type}} {{side}} Order Submitted": "{{type}} {{side}} Auftrag eingereicht",
  "{{type}} {{side}} Order Canceled": "{{type}} {{side}} Auftrag storniert",
  "Reason": "Grund",
  "Market {{side}} Order Fill": "Markt {{side}} Orderausführung",
  "Trade Busted On-Chain": "Handel On-Chain gescheitert",
  "Fill id": "Ausführungs-ID",
  "Withdraw to wallet failed": "Abhebung zur Wallet fehlgeschlagen",
  "Withdrawal to bridge initiated": "Abhebung zur Brücke eingeleitet",
  "Withdrawal error. Try again.":
    "Fehler bei der Abhebung. Versuchen Sie es erneut.",
  "Withdrawal to bridge in progress": "Abhebung zur Brücke in Bearbeitung",
  "Withdrawal to wallet initiated": "Abhebung zur Wallet eingeleitet",
  "Withdrawal to bridge failed": "Abhebung zur Brücke fehlgeschlagen",
  "Transaction error. Try again.":
    "Transaktionsfehler. Versuchen Sie es erneut.",
  "Withdrawal to wallet available": "Abhebung zur Wallet verfügbar",
  "Go to Withdrawals": "Zu Abhebungen gehen",
  "Withdrawal to wallet successful": "Abhebung zur Wallet erfolgreich",
  "Session expired, please log in again.":
    "Sitzung abgelaufen, bitte melden Sie sich erneut an.",
  "Failed to submit order": "Auftrag konnte nicht eingereicht werden",
  "Your username preferences were updated!":
    "Ihre Benutzernameneinstellungen wurden aktualisiert!",
  "Unexpected error updating username preferences":
    "Unerwarteter Fehler bei der Aktualisierung der Benutzernameneinstellungen",
  "This username already exists. Please choose a different username and try again.":
    "Dieser Benutzername existiert bereits. Bitte wählen Sie einen anderen Benutzernamen und versuchen Sie es erneut.",
  "Validation Error": "Validierungsfehler",
  "Binding Error": "Bindungsfehler",
  "Internal Error": "Interner Fehler",
  "Not Found": "Nicht gefunden",
  "Service Unavailable": "Dienst nicht verfügbar",
  "Invalid Request Parameter": "Ungültiger Anforderungsparameter",
  "Order Id Not Found": "Auftrags-ID nicht gefunden",
  "Order Is Closed": "Auftrag ist geschlossen",
  "Order Is Not Open Yet": "Auftrag ist noch nicht offen",
  "Client Order Id Not Found": "Kunden-Auftrags-ID nicht gefunden",
  "Duplicated Client Id": "Doppelte Kunden-ID",
  "Invalid Price Precision": "Ungültige Preisgenauigkeit",
  "Invalid Token": "Ungültiges Token",
  "Invalid Ethereum Address": "Ungültige Ethereum-Adresse",
  "Blocked Ethereum Address": "Blockierte Ethereum-Adresse",
  "Invalid Ethereum Signature": "Ungültige Ethereum-Signatur",
  "Invalid Starknet Address": "Ungültige Starknet-Adresse",
  "Invalid Starknet Signature": "Ungültige Starknet-Signatur",
  "Starknet Signature Verification Failed":
    "Starknet-Signaturüberprüfung fehlgeschlagen",
  "Bad Starknet Request": "Schlechte Starknet-Anforderung",
  "Ethereum Signer Mismatch": "Ethereum-Signer stimmt nicht überein",
  "Ethereum Hash Mismatch": "Ethereum-Hash stimmt nicht überein",
  "Not Onboarded": "Nicht an Bord",
  "Invalid Timestamp": "Ungültiger Zeitstempel",
  "Invalid Signature Expiration": "Ungültiges Signaturablaufdatum",
  "Account Not Found": "Konto nicht gefunden",
  "Invalid Order Signature": "Ungültige Auftragssignatur",
  "Public Key Invalid": "Öffentlicher Schlüssel ungültig",
  "Unauthorized Ethereum Address": "Nicht autorisierte Ethereum-Adresse",
  "Ethereum Address Already Onboarded": "Ethereum-Adresse bereits an Bord",
  "Market Not Found": "Markt nicht gefunden",
  "Allowlist Entry Not Found": "Eintrag in der Zulassungsliste nicht gefunden",
  "Username In Use": "Benutzername bereits in Gebrauch",
  "Service access restricted in your region":
    "Dienstzugang in Ihrer Region eingeschränkt",
  "No Access Allowed": "Kein Zugang erlaubt",
  "You do not have access to the platform. Please join our Discord to get access.":
    "Sie haben keinen Zugang zur Plattform. Bitte treten Sie unserem Discord bei, um Zugang zu erhalten.",
  "{{actualChainName}} Wallet Connected": "Ethereum Wallet verbunden",
  'Incorrect network selected "{{actualChainName}}". Please change the network to "{{requiredChainName}}" (id={{requiredChainId}}) in your wallet and retry.':
    'Falsches Netzwerk ausgewählt "{{actualChainName}}". Bitte ändern Sie das Netzwerk in "{{requiredChainName}}" (id={{requiredChainId}}) in Ihrer Wallet und versuchen Sie es erneut.',
  "Choose Wallet": "Wählen Sie eine Geldbörse",
  "Starknet Wallet": "Starknet Wallet",
  "Switch to {{env}}": "Wechseln Sie zu {{env}}",
  "Search": "Suche",
  "League": "Liga",
  "Switch Account": "Konto wechseln",
  "Rank": "Rang",
  "24h XP": "24h XP",
  "XP Per Day": "XP pro Tag",
  "Season Volume": "Saisonvolumen",
  "Lifetime Volume": "Lebenslanges Volumen",
  "Pre-Season": "Vorsaison",
  "Earn More XP": "Verdienen Sie mehr XP",
  "XP Boost": "XP-Boost",
  "XP Drop": "XP-Abfall",
  "Trader": "Händler",
  "Total Share": "Gesamtanteil",
  "Maker Share": "Maker-Anteil",
  "Maker Volume Share": "Maker-Volumenanteil",
  "Fee Share": "Gebührenanteil",
  "Fee Volume Share": "Gebührenvolumenanteil",
  "No data available": "Keine Daten verfügbar",
  "Refer & Earn": "Empfehlen & Verdienen",
  "Hours": "Stunden",
  "Minutes": "Minuten",
  "Time/Duration": "Zeit/Dauer",
  "Runtime": "Laufzeit",
  "Frequency": "Häufigkeit",
  "Number of Orders": "Anzahl der Bestellungen",
  "Runtime should not be empty": "Laufzeit darf nicht leer sein",
  "Runtime should be less than or equal to 24 hours":
    "Die Laufzeit sollte kleiner oder gleich 24 Stunden sein",
  "Inactive": "Inaktiv",
  "Avg. Price": "Durchschnittspreis",
  "The minimum required amount for {{numOrders}} orders is {{totalSize}} {{currency}}.":
    "Der Mindestbetrag für {{numOrders}} Bestellungen beträgt {{totalSize}} {{currency}}.",
  "Take Profit / Stop Loss: Set take profit and stop loss price levels to automate trade actions. When the Mark Price will reach the price you set, it will send your order to the matching engine.":
    "Take Profit / Stop Loss: Legen Sie Gewinnmitnahme- und Stop-Loss-Preise fest, um Handelsaktionen zu automatisieren. Wenn der Mark-Preis den von Ihnen festgelegten Preis erreicht, wird Ihre Order an die Matching-Engine gesendet.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount with an estimated profit of <3 />.":
    "Wenn der Markpreis <1 /> erreicht, wird die <2 />-Order ausgelöst, um den ausgewählten Betrag mit einem geschätzten Gewinn von <3 /> zu schließen.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount with an estimated loss of <3 />.":
    "Wenn der Markpreis <1 /> erreicht, wird die <2 />-Order ausgelöst, um den ausgewählten Betrag mit einem geschätzten Verlust von <3 /> zu schließen.",
  "Edit TP/SL For This Position": "TP/SL für diese Position bearbeiten",
  "Take Profit": "Take Profit",
  "Profit": "Gewinn",
  "Stop Loss": "Stop Loss",
  "Loss": "Verlust",
  "Confirm": "Bestätigen",
  "Take Profit Limit": "Take-Profit-Limit",
  "Take Profit Market": "Take-Profit-Market",
  "Stop Loss Limit": "Stop-Loss-Limit",
  "Stop Loss Market": "Stop-Loss-Market",
  "Funding": "Finanzierung",
  "Unwinding": "Abwicklung",
  "Cancel TP/SL orders": "TP/SL-Aufträge stornieren",
  "TP/SL orders submitted successfully.":
    "TP/SL-Aufträge erfolgreich eingereicht.",
  "Pre-Season + All Seasons": "Vorsaison + Alle Saisons",
  "Season {{season}}": "Saison {{season}}",
  "XP Season {{season}}": "XP Saison {{season}}",
  "Slippage": "Schlupf",
  "Max Slippage": "Maximale Kursabweichung",
  "Max slippage defines the largest acceptable price deviation from the mark price.":
    "Maximale Kursabweichung definiert die größte akzeptable Preisabweichung vom Markpreis.",
  "During execution, the order can be canceled if the price exceeds the slippage limit or falls outside the Market <1>Price Band</1>.":
    "Während der Ausführung kann der Auftrag storniert werden, wenn der Preis das Slippage-Limit überschreitet oder außerhalb der Markt-<1>Preisspanne</1> fällt.",
  "The Maximum Slippage allowed for {{symbol}} is {{price_band_width}}.":
    "Der maximal zulässige Kursabweichung für {{symbol}} beträgt {{price_band_width}}.",
  "Update Max Slippage": "Maximale Kursabweichung aktualisieren",
  "Mark IV": "Mark IV",
  "Delta": "Delta",
  "Failed to load positions": "Positionen konnten nicht geladen werden",
  "No open positions": "Keine offenen Positionen",
  "learn more": "mehr erfahren",
  "Learn More": "Mehr Erfahren",
  "Search Markets": "Märkte durchsuchen",
  "Futures": "Futures",
  "Options": "Optionen",
  "OI": "Offenes Interesse",
  "Addresses": "Adressen",
  "My Profile": "Mein Profil",
  "Chart": "Chart",
  "ROI": "ROI",
  "Sharpe": "Sharpe",
  "Max Loss": "Max Loss",
  "Total Volume": "Total Volume",
  "My NFTs": "Meine NFTs",
  "My XP": "Meine XP",
  "Balances": "Guthaben",
  "Other": "Andere",
  "Paradex XP: Pre-Season": "Paradex XP: Vorsaison",
  "Paradex XP: Season {{season}}": "Paradex XP: Saison {{season}}",
  "Season {{season}} XP": "Saison {{season}} XP",
  "Refer your friends to earn 10%":
    "Empfehlen Sie Freunde und verdienen Sie 10%",
  "Refer Friends and Earn 10%": "Empfehlen Sie Freunde und verdienen Sie 10%",
  "Invest in Paradex Vault & Earn 3X Boost":
    "Investieren in Paradex Vault & Verdienen Sie 3X Boost",
  "Avg. Daily XP": "Durchschnittliche tägliche XP",
  "Lifetime XP": "Lebenslanges XP",
  "My Rank": "Mein Rang",
  "What is XP?": "Was ist XP?",
  "XP History": "XP Geschichte",
  "Last Week XP": "Letzte Woche XP",
  "TVL XP": "TVL XP",
  "OI XP": "OI XP",
  "Liquidation XP": "Liquidation XP",
  "Vaults XP": "Tresor XP",
  "Week": "Woche",
  "Season": "Saison",
  "Last updated: {{timestamp}}": "Zuletzt aktualisiert: {{timestamp}}",
  "Xperience Points (XP)": "Erlebnispunkte (XP)",
  "Log in to see your XP balance":
    "Melden Sie sich an, um Ihre XP-Guthaben zu sehen",
  "Log in to see where you stand":
    "Melden Sie sich an, um zu sehen, wo Sie stehen",
  "Connect your X account to join Pro League":
    "Verbinden Sie Ihre X-Konto, um an der Pro League teilzunehmen",
  "Avg. Fill Price": "Durchschnittlicher Ausführungspreis",
  "Spot": "Spot",
  "Derivatives": "Derivate",
  "P&L Chart": "P&L Chart",
  "Account Value Chart": "Kontowert Chart",
  "Funding History": "Finanzierungshistorie",
  "Manage Funds": "Geldmittel verwalten",
  "Profile": "Profil",
  "Community XP": "Community XP",
  "XP Share": "XP-Anteil",
  "Next XP Drop in": "Nächste XP-Ausschüttung in",
  "In Progress": "In Bearbeitung",
  "{{field}} must be greater than {{value}}":
    "{{field}} muss größer sein als {{value}}",
  "{{field}} must be less than {{value}}":
    "{{field}} muss kleiner sein als {{value}}",
  "Enter a valid Take Profit or Stop Loss price":
    "Geben Sie einen gültigen Take-Profit- oder Stop-Loss-Preis ein",
  "Take Profit and Stop Loss prices cannot be the same":
    "Take-Profit- und Stop-Loss-Preise dürfen nicht gleich sein",
  "Automatic Withdrawal": "Automatische Abhebung",
  "Estimated Gas Fee": "Geschätzte Gasgebühr",
  "Auto Withdrawal": "Automatische Abhebung",
  "Withdrawing...": "Abhebung wird ausgeführt...",
  "Paradex Bridge withdrawals can take between 5-16 hours to complete":
    "Paradex Bridge-Abhebungen dauern zwischen 5-16 Stunden",
  "Socialized Loss": "Sozialisierter Verlust",
  "Receivable Amount": "Zu erhaltender Betrag",
  "Auto withdrawing...": "Automatische Abhebung wird ausgeführt...",
  "Manage Twitter Connection": "Twitter-Verbindung verwalten",
  "Unlock additional functionality.": "Zusätzliche Funktionen freischalten.",
  "Please note this connection can't be used as a login method to Paradex.":
    "Bitte beachten Sie, dass diese Verbindung nicht als Anmeldemethode für Paradex verwendet werden kann.",
  "Toast Notifications": "Toast-Benachrichtigungen",
  "Display toast notifications": "Toast-Benachrichtigungen anzeigen",
  "Average Entry Price": "Durchschnittlicher Einstiegspreis",
  "Display average entry price line on chart":
    "Durchschnittlicher Einstiegspreislinie auf Chart anzeigen",
  "Display liquidation price line on chart":
    "Liquidation-Preislinie auf Chart anzeigen",
  "Display open order lines on chart":
    "Offene Auftragslinien auf Chart anzeigen",
  "Failed to disconnect Twitter":
    "Twitter-Verbindung konnte nicht getrennt werden",
  "Failed to authorize Twitter": "Twitter-Autorisierung fehlgeschlagen",
  "Twitter account linked successfully!":
    "Twitter-Konto erfolgreich verbunden!",
  "Failed to link twitter account":
    "Twitter-Konto konnte nicht verbunden werden",
  "Fees": "Gebühren",
  "Taker pays {{takerFee}} in fees. Maker receives {{makerRebate}} in rebates.":
    "Taker zahlt {{takerFee}} an Gebühren. Maker erhält {{makerRebate}} an Rabatten.",
  "Discord account linked successfully!":
    "Discord-Konto erfolgreich verbunden!",
  "Failed to link Discord account":
    "Discord-Konto konnte nicht verbunden werden",
  "Manage Discord Connection": "Discord-Verbindung verwalten",
  "Failed to disconnect Discord":
    "Discord-Verbindung konnte nicht getrennt werden",
  "Failed to authorize Discord": "Discord-Autorisierung fehlgeschlagen",
  "Connect": "Verbinden",
  "Unrealized P&L from current vault token balance. This doesn't account for owner profit share or slippage from positions unwind.":
    "Unrealisierter Gewinn/Verlust aus der aktuellen Vault-Token-Guthaben. Dies berücksichtigt nicht den Anteil des Vault-Besitzers an Gewinnen oder Verlusten oder die Slippage aus der Abwicklung von Positionen.",
  "Show P&L Amount": "P&L-Betrag anzeigen",
  "Receivable amount must be positive":
    "Zu erhaltender Betrag muss positiv sein",
  "Wallet Address Change Detected": "Änderung der Wallet-Adresse erkannt",
  "You can proceed with connecting your Paradex account to this wallet address or continue using your current connection.":
    "Sie können Ihr Paradex-Konto mit dieser Wallet-Adresse verbinden oder Ihre aktuelle Verbindung weiterhin nutzen.",
  "Connect To New Wallet": "Mit neuer Wallet verbinden",
  "Keep Using Current Wallet": "Aktuelle Wallet weiterhin verwenden",
  "Connect Current Wallet": "Aktuelle Wallet verbinden",
  "The account is not connected. Please make sure that <1>{{walletAddress}}</1> is connected and is the active account in your <2>{{walletName}}</2> wallet.":
    "Das Konto ist nicht verbunden. Bitte stellen Sie sicher, dass <1>{{walletAddress}}</1> verbunden ist und das aktive Konto in Ihrer <2>{{walletName}}</2> Wallet ist.",
  "Reconnecting": "Wiederherstellen der Verbindung",
  "There is a request in progress. Please proceed in your wallet.":
    "Es gibt eine laufende Anfrage. Bitte fahren Sie in Ihrer Wallet fort.",
  "Modify Order": "Auftrag bearbeiten",
  "Realized P&L": "Realisierter P&L",
  "Close All": "Alle schließen",
  "Close All Positions": "Alle Positionen schließen",
  "Market Close All Positions": "Markt schließt alle Positionen",
  "Close {{percent}}% of All Positions":
    "Schließe {{percent}}% aller Positionen",
  "Failed to close all positions": "Fehler beim Schließen aller Positionen",
  "Number of Positions": "Anzahl der Positionen",
  "Position Value (Closing)": "Position Wert (Schließen)",
  "Markets": "Märkte",
  "{{count}} positions will be rounded to the nearest order increment":
    "{{count}} Positionen werden auf die nächste Bestellungssteigerung gerundet",
  "You will earn Bonus XP on your referral activity":
    "Sie erhalten Bonus-XP für Ihre Empfehlungsaktivitäten",
  "Token Price": "Token-Preis",
  "Vault Token Price Chart": "Vault-Token-Preis-Chart",
  "Market Close Orders Submitted": "Markt schließt alle Bestellungen",
  "This profile is already linked to a different Paradex account. Disconnect socials from existing account and try again.":
    "Dieses Profil ist bereits mit einem anderen Paradex-Konto verbunden. Trennen Sie die sozialen Netzwerke von Ihrem aktuellen Konto und versuchen Sie es erneut.",
  "XP Drops every Friday": "XP wird jeden Freitag ausgeschüttet",
  "No XP Data Available": "Keine XP-Daten verfügbar",
  "XP data is available only for the Main Account.":
    "XP-Daten sind nur für das Haupt-Konto verfügbar.",
  "Please switch to your Main Account to view your XP.":
    "Bitte wechseln Sie zu Ihrem Haupt-Konto, um Ihre XP zu sehen.",
  "Referrals Not Available": "Empfehlungen nicht verfügbar",
  "Referrals are available only for the Main Account.":
    "Empfehlungen sind nur für das Haupt-Konto verfügbar.",
  "Please switch to your Main Account to view your Referrals.":
    "Bitte wechseln Sie zu Ihrem Haupt-Konto, um Ihre Empfehlungen zu sehen.",
  "Deposit initiated via Layerswap": "Einzahlung eingeleitet über Layerswap",
  "Position History": "Positionshistorie",
  "Avg. Close Price": "Durchschn. Schlusskurs",
  "Max Size": "Maximale Größe",
  "Closed Size": "Geschlossene Größe",
  "Opened": "Geöffnet",
  "You have no positions": "Sie haben keine Positionen",
  "Destination Address": "Zieladresse",
  "Withdrawal via Layerswap initiated": "Abhebung via Layerswap eingeleitet",
  "Withdrawal via Layerswap failed": "Abhebung via Layerswap fehlgeschlagen",
  "Market Share": "Marktanteil",
  "Tier": "Tier",
  "Avg Maker Volume": "Durchschn. Maker-Volumen",
  "Liquidity Score": "Liquiditäts-Score",
  "Reward Share": "Belohnungsanteil",
  "Enter amount": "Betrag eingeben",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount at <3 /> with an estimated profit of <4 />.":
    "Wenn der Markpreis <1 /> erreicht, wird ein <2 />-Auftrag ausgelöst, um die ausgewählte Menge zu <3 /> mit einem geschätzten Gewinn von <4 /> zu schließen.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount at <3 /> with an estimated loss of <4 />.":
    "Wenn der Markpreis <1 /> erreicht, wird ein <2 />-Auftrag ausgelöst, um die ausgewählte Menge zu <3 /> mit einem geschätzten Verlust von <4 /> zu schließen.",
  "Limit Price": "Limitpreis",
  "Delete": "Löschen",
  "Use other bridges from the list above to withdraw funds much faster.":
    "Verwenden Sie andere Brücken aus der obigen Liste, um Gelder viel schneller abzuheben.",
  "Paradex Chain Monitor": "Paradex Chain Monitor",
  "OTM Amount": "OTM-Betrag",
  "Leverage": "Hebel",
  "Strikes": "Strikes",
  "Strike": "Strike",
  "Change": "Änderung",
  "Dist. to Spot": "Abstand zum Spot",
  "Bid": "Gebotspr.",
  "Mark": "Markpr.",
  "Ask": "Briefpr.",
  "IV": "IV",
  "Dist %": "Abst. %",
  "Call": "Call",
  "Put": "Put",
  "Mark Price / IV": "Markpreis / IV",
  "8h F-Funding": "8h F-Funding",
  "No Strikes Available": "Keine Strikes verfügbar",
  "Attributes": "Attribute",
  "Why no Expiries?": "Warum keine Verfallsdaten?",
  "{{multiplier}}x Boost": "{{multiplier}}x Boost",
  "Like perpetual futures, perpetual options have no expiration, offering continuous exposure to the underlying asset with built-in downside protection. This gives users the flexibility to hold their position indefinitely and decide the optimal time to close it based on their strategy":
    "Wie perpetuelle Futures haben perpetuelle Optionen kein Verfallsdatum und bieten ein kontinuierliches Engagement im Basiswert mit integriertem Abwärtsschutz. Dies gibt Benutzern die Flexibilität, ihre Position unbegrenzt zu halten und den optimalen Zeitpunkt für die Schließung basierend auf ihrer Strategie zu wählen",
  "Implied Volatility used to calculate the option's Mark Price":
    "Implizite Volatilität zur Berechnung des Optionsmarkpreises",
  "Distance between the Strike Price and the underlying Spot Price":
    "Abstand zwischen Strike-Preis und zugrundeliegendem Spot-Preis",
  "Eight hour funding that is continuously accrued on positions and settled upon any trade. If the funding rate is positive, longs pay shorts. If negative, shorts pay longs":
    "Achtstündige Finanzierung, die kontinuierlich auf Positionen aufläuft und bei jedem Handel abgerechnet wird. Bei positivem Finanzierungssatz zahlen Longs an Shorts, bei negativem zahlen Shorts an Longs",
  "The Leverage Ratio (Lambda) measures an option's effective leverage by indicating how much the option's value changes, in percentage terms, for a 1% change in the spot price of the underlying asset. For example, if an option has a Lambda of 50 and the spot price increases by 1%, the option's value would increase by 50%":
    "Das Hebelverhältnis (Lambda) misst den effektiven Hebel einer Option, indem es anzeigt, wie stark sich der Optionswert prozentual bei einer 1%igen Änderung des Spot-Preises des Basiswerts ändert. Beispiel: Bei einem Lambda von 50 würde der Optionswert um 50% steigen, wenn der Spot-Preis um 1% steigt",
  "Option delta measures how much the price of an option is expected to change for a $1 change in the price of the underlying asset. For example, a delta of 0.5 means the option price will move $0.50 for every $1 move in the underlying":
    "Das Options-Delta misst, wie stark sich der Optionspreis bei einer Änderung des Basiswertpreises um $1 voraussichtlich ändert. Beispiel: Ein Delta von 0,5 bedeutet, dass sich der Optionspreis um $0,50 für jede $1-Bewegung im Basiswert ändert",
  "Eight hour funding rate of the perpetual future on the same underlying asset":
    "Achtstündiger Finanzierungssatz des perpetuellen Futures auf denselben Basiswert",
  "Having trouble? Try signing out and connecting again.":
    "Probleme? Versuchen Sie, sich ab- und wieder anzumelden.",
  "API Reference": "API-Referenz",
  "XP Distribution occurs once per week on Friday at ~12 PM UTC":
    "XP-Verteilung erfolgt einmal pro Woche am Freitag um ~12 Uhr UTC",
  "What are Perpetual Options?": "Was sind Perpetuelle Optionen?",
  "Delta represents the increase in option price based on a $1 increase in the underlying spot price.\n\nFor example, a delta of 0.50 means the price of the option is expected to increase by $0.50 for a $1 increase in the underlying spot price.":
    "Delta repräsentiert den Anstieg des Optionspreises basierend auf einem $1-Anstieg des zugrundeliegenden Spot-Preises.\n\nBeispiel: Ein Delta von 0,50 bedeutet, dass der Optionspreis voraussichtlich um $0,50 steigt, wenn der Basiswertpreis um $1 steigt.",
  "Leverage(Lambda) represents the percentage change in option price for a 1% change in the underlying spot price.\n\nFor example, a lambda of 10 means the option price is expected to increase by 10% if the underlying spot price increases by 1%.":
    "Hebel(Lambda) repräsentiert die prozentuale Änderung des Optionspreises bei einer 1%igen Änderung des zugrundeliegenden Spot-Preises.\n\nBeispiel: Ein Lambda von 10 bedeutet, dass der Optionspreis voraussichtlich um 10% steigt, wenn der Basiswertpreis um 1% steigt.",
  "Eight hour options funding rate that is continuously accrued on positions and settled upon any trade. The funding rate for perpetual options reflects both the cost of leverage and the cost of insurance against adverse price moves. \n\nThe funding period of perpetual options is 24 hours. This means that the option premium will be continuously paid out over a 24h period. 24h funding rate = 3 x 8h funding rate":
    "Achtstündiger Options-Finanzierungssatz, der kontinuierlich auf Positionen aufläuft und bei jedem Handel abgerechnet wird. Der Finanzierungssatz für perpetuelle Optionen spiegelt sowohl die Hebelkosten als auch die Versicherungskosten gegen ungünstige Preisbewegungen wider. \n\nDie Finanzierungsperiode für perpetuelle Optionen beträgt 24 Stunden. Das bedeutet, dass die Optionsprämie kontinuierlich über einen 24-Stunden-Zeitraum ausgezahlt wird. 24h-Finanzierungssatz = 3 x 8h-Finanzierungssatz",
  "Risk": "Risiko",
  "Vega": "Vega",
  "Gamma": "Gamma",
  "Estimated Funding P&L for the next 8 hours based on current funding rates":
    "Geschätzter Funding-G&V für die nächsten 8 Stunden basierend auf aktuellen Finanzierungssätzen",
  "Vega represents the increase in option price based on a 1% increase in the implied volatility.\n\nFor example, a Vega of 5 means the price of the option is expected to increase by $5 for a 1% increase in the implied volatility.":
    "Vega repräsentiert den Anstieg des Optionspreises basierend auf einem 1%igen Anstieg der impliziten Volatilität.\n\nBeispiel: Ein Vega von 5 bedeutet, dass der Optionspreis voraussichtlich um $5 steigt, wenn die implizite Volatilität um 1% steigt.",
  "Adjust Leverage": "Hebel anpassen",
  "Leverage updated successfully": "Hebel erfolgreich aktualisiert",
  "Failed to update leverage": "Hebel-Aktualisierung fehlgeschlagen",
  "Adjust leverage parameters for selected market. Leverage will also increase on your open positions.":
    "Passen Sie die Hebelparameter für den ausgewählten Markt an. Der Hebel wird auch für Ihre offenen Positionen erhöht.",
  "Please note that changing leverage will also apply for open positions and open orders on this market.":
    "Bitte beachten Sie, dass die Änderung des Hebels auch für offene Positionen und offene Orders in diesem Markt gilt.",
  "Selecting higher leverage could result in Deleveraging where some of your open orders would be cancelled.":
    "Die Auswahl eines höheren Hebels könnte zu einem Deleveraging führen, bei dem einige Ihrer offenen Orders storniert würden.",
  "disabled": "deaktiviert",
  "Margin Mode": "Margin-Modus",
  "Manage your risk on individual positions by restricting the amount of margin allocated to each. If the margin ratio of an isolated position reaches 100%, the position will be liquidated. Margin can be added or removed to individual positions in this mode.":
    "Verwalten Sie Ihr Risiko bei einzelnen Positionen, indem Sie die zugewiesene Margin-Menge begrenzen. Wenn das Margin-Verhältnis einer isolierten Position 100% erreicht, wird die Position liquidiert. In diesem Modus kann Margin zu einzelnen Positionen hinzugefügt oder entfernt werden.",
  "Margin mode updated successfully": "Margin-Modus erfolgreich aktualisiert",
  "Failed to update margin mode":
    "Aktualisierung des Margin-Modus fehlgeschlagen",
  "Favorites": "Favoriten",
  "Trending": "Im Trend",
  "Losers": "Verlierer",
  "Gainers": "Gewinner",
  "New Listing": "Neue Notierung",
  "Developers": "Entwickler",
  "Developer Mode": "Entwickler-Modus",
  "Enable developer mode": "Entwickler-Modus aktivieren",
  "For external use": "Für externe Verwendung",
  "Warning: Changing transfer behavior":
    "Warnung: Änderung des Übertragungsverhaltens",
  "USDC will be transferred to account contract instead of Paradex. Amount won't be visible or available for use within Paradex, but can be used with external applications.":
    "USDC wird an das Kontovertrag anstelle von Paradex übertragen. Der Betrag wird nicht sichtbar oder für die Verwendung in Paradex verfügbar, aber mit externen Anwendungen verwendet werden können.",
  "Affiliate Dashboard": "Affiliate-Dashboard",
};

export default translations;
