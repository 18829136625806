import type { RawMarket } from '#/api/markets';
import type { SystemConfig } from '#/api/system';

export interface BuildTimeStore {
  /** System config, defined at build time. */
  readonly systemConfig?: SystemConfig;
  /** Markets, defined at build time. */
  readonly markets?: readonly RawMarket[];
}

declare global {
  interface Window {
    /**
     * Global store object used exclusively to hydrate the app at build time.
     * The purspose of this build-time store is to prevent the app from having
     * to wait for API responses during the initial render.
     */
    readonly store?: BuildTimeStore;
  }
}

/**
 * Gets a build-time store slice.
 * Throws an error if the slice is not defined.
 *
 * @param sliceName - The name of the slice to read.
 * @returns The slice value.
 */
export function readBuildTimeStore<T extends keyof BuildTimeStore>(
  sliceName: T,
): NonNullable<BuildTimeStore[T]> {
  if (window.store == null)
    throw new Error(
      'Missing build-time store. ' +
        'Make sure `window.store` is properly defined in index.html file.',
    );

  const slice = window.store[sliceName];
  if (slice == null)
    throw new Error(
      `Missing build-time store slice '${sliceName}'. ` +
        `Make sure \`window.store.${sliceName}\` is properly defined in index.html file.`,
    );
  return slice;
}
