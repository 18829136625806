import BigNumber from 'bignumber.js';

import { Market, OptionType } from '#/api/markets';
import { MarketSummary } from '#/api/markets-summary';

const FUNDING_PERIOD_HOURS = 8;

/** Decimal places precision for base currency (e.g. BTC) */
export function baseCurrencyDecimalPlaces(
  market: Pick<Market, 'order_size_increment'>,
): number {
  return getDecimalPlaces(market.order_size_increment);
}

/** Decimal places precision for quote currency (e.g. USD) */
export function quoteCurrencyDecimalPlaces(
  market: Pick<Market, 'price_tick_size'>,
): number {
  return getDecimalPlaces(market.price_tick_size);
}

export function roundAmountByAsset(
  market: Pick<
    Market,
    'base_currency' | 'order_size_increment' | 'price_tick_size'
  >,
  asset: string,
  value: BigNumber,
  roundingMode?: BigNumber.RoundingMode,
) {
  if (asset === market.base_currency) {
    return roundToBaseDecimalPlaces(market, value, roundingMode);
  }

  return roundToQuoteDecimalPlaces(market, value, roundingMode);
}

/**
 * Rounds value to the market's quote currency precision
 */
export function roundToQuoteDecimalPlaces(
  market: Pick<Market, 'price_tick_size'>,
  value: BigNumber,
  roundingMode?: BigNumber.RoundingMode,
): BigNumber {
  return value.decimalPlaces(quoteCurrencyDecimalPlaces(market), roundingMode);
}

/**
 * Rounds value to the market's base currency precision
 */
export function roundToBaseDecimalPlaces(
  market: Pick<Market, 'order_size_increment'>,
  value: BigNumber,
  roundingMode?: BigNumber.RoundingMode,
): BigNumber {
  return value.decimalPlaces(baseCurrencyDecimalPlaces(market), roundingMode);
}

/** Format price in market's quote currency */
export function formatPrice(
  market: Pick<Market, 'price_tick_size'>,
  price: BigNumber,
): string {
  return price.toFormat(priceDecimalPlaces(market, price));
}

/** Format size in market's base currency */
export function formatSize(
  market: Pick<Market, 'order_size_increment'>,
  size: BigNumber,
): string {
  return size.toFormat(sizeDecimalPlaces(market, size));
}

function getDecimalPlaces(num: BigNumber, fallback = 0): number {
  return num.decimalPlaces() ?? fallback;
}

/** Decimal places for an order price (in market's quote currency) */
function priceDecimalPlaces(
  market: Pick<Market, 'price_tick_size'>,
  price: BigNumber,
): number {
  // This prevents rounding the price to the market's quote currency
  // number of decimal places if price has more decimals
  return Math.max(getDecimalPlaces(price), quoteCurrencyDecimalPlaces(market));
}

/** Decimal places for an order size (in market's base currency) */
function sizeDecimalPlaces(
  market: Pick<Market, 'order_size_increment'>,
  size: BigNumber,
): number {
  // This prevents rounding the size to the market's base currency
  // number of decimal places if size has more decimals
  return Math.max(getDecimalPlaces(size), baseCurrencyDecimalPlaces(market));
}

export function isPerpetual(market: Pick<Market, 'asset_kind'>): boolean {
  return market.asset_kind === 'PERP';
}

export function isPerpetualOption(market: Pick<Market, 'asset_kind'>): boolean {
  return market.asset_kind === 'PERP_OPTION';
}

export function isPerpetualFuture(market: Pick<Market, 'asset_kind'>): boolean {
  return market.asset_kind === 'PERP';
}

export function isOptionType(
  market: Pick<Market, 'option_type'>,
  optionType: OptionType,
): boolean {
  return market.option_type === optionType;
}

export function isBaseCurrency(
  market: Pick<Market, 'base_currency'>,
  baseCurrency: string,
): boolean {
  return market.base_currency === baseCurrency;
}

export function isOpen(market: Pick<Market, 'open_at'>): boolean {
  return market.open_at.getTime() <= Date.now();
}

export function calcFundingRateApy(
  fundingRate: BigNumber,
  hours: BigNumber,
): BigNumber {
  if (fundingRate.isZero()) return new BigNumber(0);
  return calcSimpleApy(fundingRate, hours);
}

export function calcFundingRate8h(
  rate: BigNumber,
  fundingPeriodHours: BigNumber,
): BigNumber {
  if (rate.isZero()) return new BigNumber(0);
  return rate.times(FUNDING_PERIOD_HOURS).div(fundingPeriodHours);
}

function calcSimpleApy(rate: BigNumber, hours: BigNumber): BigNumber {
  return rate.div(hours).times(24 * 365);
}

export function getAssetPrice(
  market: Pick<Market, 'asset_kind'>,
  marketSummary: MarketSummary,
): BigNumber | null {
  switch (market.asset_kind) {
    case 'PERP_OPTION':
      return marketSummary.underlying_price;
    default:
      return marketSummary.mark_price;
  }
}
