import { AccountProfile } from '#/api/account';
import { AsyncResp, BaseReq, requestApi } from '#/api/fetch-api';

interface DiscordAuthReq extends BaseReq {}

export interface DiscordAuth {
  readonly url: string;
  readonly state: string;
  readonly code_verifier: string;
}

export interface LinkDiscordProfileReq extends BaseReq {
  readonly code: string;
  readonly code_verifier: string;
}

export interface UnlinkDiscordProfileReq extends BaseReq {}

export async function getDiscordAuth(
  req: DiscordAuthReq,
): AsyncResp<DiscordAuth> {
  const { signal } = req;

  return requestApi<DiscordAuth>({
    signal,
    method: 'GET',
    url: '/campaigns/public/discord/authorize',
  });
}

export async function linkDiscordProfile(
  req: LinkDiscordProfileReq,
): AsyncResp<AccountProfile> {
  const { signal, ...body } = req;

  return requestApi<AccountProfile>({
    signal,
    method: 'POST',
    url: '/campaigns/private/discord/link',
    body,
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export async function unlinkDiscordProfile(
  req: UnlinkDiscordProfileReq,
): AsyncResp<AccountProfile> {
  const { signal } = req;

  return requestApi<AccountProfile>({
    signal,
    method: 'DELETE',
    url: '/campaigns/private/discord/unlink',
  });
}
