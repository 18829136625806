import {
  RumActionEventDomainContext,
  RumErrorEventDomainContext,
  RumEvent,
  RumFetchResourceEventDomainContext,
  RumLongTaskEventDomainContext,
  RumOtherResourceEventDomainContext,
  RumViewEventDomainContext,
  RumXhrResourceEventDomainContext,
} from '@datadog/browser-rum';

import { logException } from '#/features/logging/logging';

import { Maybe } from '#/utils/types';

type Context =
  | RumViewEventDomainContext
  | RumActionEventDomainContext
  | RumFetchResourceEventDomainContext
  | RumXhrResourceEventDomainContext
  | RumOtherResourceEventDomainContext
  | RumErrorEventDomainContext
  | RumLongTaskEventDomainContext;

type ExtraContext = Record<string, unknown>;

/**
 * Enrich action event with attributes of target element (e.g. if btn is disabled)
 */
export default function enrichActionWithCustomAttributes(
  event: RumEvent,
  context: Context,
) {
  if (event.type !== 'action') return;

  try {
    if (event.context == null) {
      event.context = {};
    }
  } catch (error) {
    logException(
      'datadog :: beforeSend :: Failed to initialize event context',
      { cause: error },
    );
  }

  try {
    if (event.context == null) {
      throw new Error('event.context is null');
    }
    if (!('events' in context)) return;
    if (context.events == null) return;

    const targetElement = context.events[0]?.target as Maybe<Element>;
    if (targetElement == null) {
      throw new Error('context.events[0].target does not exist');
    }

    const trackedAttributes = {
      disabled: targetElement.hasAttribute('disabled'),
      extraContext: JSON.parse(
        targetElement.getAttribute('data-dd-action-context') ?? '{}',
      ) as ExtraContext,
    };
    event.context.dex_ui_trackedAttributes = trackedAttributes;
  } catch (error) {
    logException(
      'datadog :: beforeSend :: Failed to enrich action with custom attributes',
      { cause: error },
    );
  }
}
