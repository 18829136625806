import BigNumber from 'bignumber.js';

import getQueryString from '#/utils/getQueryString';

import { AsyncResp, BaseReq, requestApi } from './fetch-api';
import { UnixTimeMs } from './types';

export interface RawChainGasResp {
  /** @example 'ethereum' */
  readonly chain: string;
  /** @example '0' */
  readonly fee_native: string;
  /** @example '0' */
  readonly fee_usdc: string;
  /** @example '1716556800000' */
  readonly updated_at: UnixTimeMs;
}

interface ChainGasReq extends BaseReq {
  readonly amount: string;
  readonly bridge: string;
  readonly destination_chain: string;
  readonly source_chain: string;
}

export interface ChainGasResp {
  readonly chain: string;
  readonly fee_native: BigNumber;
  readonly fee_usdc: BigNumber;
  readonly updated_at: Date;
}

export async function getChainGas(req: ChainGasReq): AsyncResp<ChainGasResp> {
  const { signal } = req;

  const query = getQueryString([
    ['amount', req.amount],
    ['bridge', req.bridge],
    ['destination_chain', req.destination_chain],
    ['source_chain', req.source_chain],
  ]);

  const resp = await requestApi<RawChainGasResp>({
    signal,
    method: 'GET',
    url: `/chain/gas${query}`,
  });

  if (!resp.ok) {
    return resp;
  }

  return {
    ...resp,
    data: processChainGas(resp.data),
  };
}

export function processChainGas(chainGas: RawChainGasResp): ChainGasResp {
  return {
    ...chainGas,
    fee_native: new BigNumber(chainGas.fee_native),
    fee_usdc: new BigNumber(chainGas.fee_usdc),
    updated_at: new Date(chainGas.updated_at),
  };
}
