import { createEntityAdapter } from '@reduxjs/toolkit';

import type { EntityState } from '@reduxjs/toolkit';

/**
 * Represents a deposit to Paraclear triggered that was automatically triggered.
 */
export interface ParaclearDeposit {
  /** Transfer this deposit relates to */
  readonly transferId: string;
  /** Paraclear deposit transaction status */
  readonly status: 'PENDING' | 'COMPLETED' | 'FAILED';
}

export const adapter = createEntityAdapter<
  ParaclearDeposit,
  ParaclearDeposit['transferId']
>({
  selectId: (deposit) => deposit.transferId,
});

export interface ParaclearDepositState {
  readonly deposits: EntityState<
    ParaclearDeposit,
    ParaclearDeposit['transferId']
  >;
}

export const INITIAL_STATE: ParaclearDepositState = {
  deposits: adapter.getInitialState(),
};
