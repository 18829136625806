import { Dispatch, SetStateAction } from 'react';

import { AuthActions, AuthState } from '#/features/auth/auth-context';
import { logEvent, logException } from '#/features/logging/logging';
import { getIsBaseAccountCompliantStorageKey } from '#/features/storage/storage';
import {
  BaseAccount,
  getBaseAccountTypeLabel,
} from '#/features/wallet/common/base-account';
import { WalletClient } from '#/features/wallet/common/wallet-client';
import { prepareErrorMessage } from '#/features/wallet/ethereum';
import {
  AccountContractConfigurable,
  WalletState,
} from '#/features/wallet/wallet-context';
import ParaclearWallet from '#/features/wallets/paraclear/wallet';

import { getStorageItem } from '#/utils/localStorage';
import { sleep } from '#/utils/sleep';
import { AsyncResult } from '#/utils/types';

export default function actionInitializeParaclearWallet(
  ...params: Parameters<typeof initializeParaclearWallet>
) {
  return async () => initializeParaclearWallet(...params);
}

async function initializeParaclearWallet(
  walletClient: WalletClient,
  authState: AuthState,
  authActions: AuthActions,
  setWalletState: Dispatch<SetStateAction<WalletState>>,
  options: AccountContractConfigurable,
): AsyncResult<void> {
  const actions = prepareActions(setWalletState);

  actions.resetError();
  actions.setStatus('signing_stark_keys');

  try {
    await walletClient.switchChainId();
  } catch (err) {
    const baseAccountTypeLabel = getBaseAccountTypeLabel(
      walletClient.getBaseAccount(),
    );
    const description = `Error switching ${baseAccountTypeLabel} chain`;
    const { message, isException } = prepareErrorMessage(description, err);
    const errorMessage = `${message}. Try changing the chain manually in the wallet.`;
    actions.setError(errorMessage);
    actions.setStatus('wallet_connected_idle');
    if (isException) {
      const error = new Error(description, { cause: err });
      logException(error);
      return { ok: false, error };
    }
    logEvent(message);
    return { ok: false, error: null };
  }

  try {
    const isSeedAvailable = authState.walletSeed != null;
    const isSeedEthereumAddressAvailable =
      authState.baseAccount?.address != null;

    if (isSeedAvailable && !isSeedEthereumAddressAvailable) {
      throw new Error(
        `Starknet Wallet seed was recovered, but there is no associated ${getBaseAccountTypeLabel(
          walletClient.getBaseAccount(),
        )} address`,
      );
    }

    const retrieveBaseAccount = (): BaseAccount => {
      if (isSeedEthereumAddressAvailable) {
        return authState.baseAccount;
      }
      return walletClient.getBaseAccount();
    };

    const retrieveSignature = async () => {
      if (isSeedAvailable) {
        return authState.walletSeed;
      }
      return walletClient.signStarkKeys();
    };

    const verifyDeterministicSigning = async (baseAccount: BaseAccount) => {
      const storageKey = getIsBaseAccountCompliantStorageKey(baseAccount);
      const isWalletCompliant = getStorageItem<boolean>(storageKey) ?? false;

      if (isWalletCompliant) return true;

      const additionalSignature = await retrieveSignature();
      if (signature !== additionalSignature) return false;

      return true;
    };

    const baseAccount = retrieveBaseAccount();
    const signature = await retrieveSignature();
    // Allow the wallet time to finish processing the first signature.
    // This prevents a bug with Rabby wallet where onboarding fails with
    // "User rejected the request" error. The error appears right after
    // the user approves the first signature, but is thrown by the second
    // signature request.
    await sleep(100);

    const isDeterministicSigning = await verifyDeterministicSigning(
      baseAccount,
    );

    if (!isDeterministicSigning) {
      throw new Error(
        `Your wallet does not support deterministic signing. Please use different wallet.`,
      );
    }

    await ParaclearWallet.initializeMainAccount(
      baseAccount,
      signature,
      options.accountClassHash,
      options.accountProxyClassHash,
    );

    const address = ParaclearWallet.getAddress();
    actions.setStarknetAddress(address);

    if (authState.rememberMe) {
      authActions.rememberUser(baseAccount, signature);
    }
  } catch (err) {
    const description = 'Failed to derive Paradex account';
    const { message, isException } = prepareErrorMessage(description, err);
    actions.setError(message);
    actions.setStatus('wallet_connected_idle');
    if (isException) {
      const error = new Error(description, { cause: err });
      logException(error);
      return { ok: false, error };
    }
    logEvent(message);
    return { ok: false, error: null };
  }

  actions.setStatus('wallet_connected_idle');
  return { ok: true, data: undefined };
}

function prepareActions(setWalletState: Dispatch<SetStateAction<WalletState>>) {
  function setStatus(status: WalletState['step']) {
    setWalletState((state) => ({ ...state, step: status }));
  }

  function setStarknetAddress(starknetAddress: WalletState['paradexAddress']) {
    setWalletState((state) => ({ ...state, paradexAddress: starknetAddress }));
  }

  const setError = (error: string) => {
    setWalletState((state) => ({ ...state, error }));
  };

  const resetError = () => {
    setWalletState((state) => ({ ...state, error: '' }));
  };

  return {
    setStatus,
    setStarknetAddress,
    setError,
    resetError,
  };
}
