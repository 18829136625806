import BigNumber from 'bignumber.js';

import { ProleagueStat } from '#/api/proleague';

import {
  TWITTER_IMG_SIZES,
  twitterHandle,
  twitterImgSrcSet,
  twitterProfileUrl,
} from '#/components/twitter/utils';

import { TwitterProfileView } from '#/features/account/account-profile-views';

import { formatUsd } from '#/utils/fiat';
import { formatPercent } from '#/utils/percent';
import { FormattedValue, Nullable } from '#/utils/types';
import { PollingConnectionBaseState } from '#/utils/usePollingConnection';

export interface Store extends PollingConnectionBaseState {
  readonly data: Nullable<ProleagueStat[]>;
}

export interface ProleagueLeaderboardView {
  readonly data: ProleagueStatView[];
  readonly isLoading: boolean;
  readonly error: string | null;
}

interface AccountValueSnapshot {
  readonly label: string;
  readonly value: number;
}

export interface ProleagueStatView {
  readonly rank: number;
  readonly username: string;
  readonly pnl: FormattedValue<Nullable<BigNumber>>;
  readonly pnlDirection: 'up' | 'down' | null;
  readonly roi: FormattedValue<Nullable<BigNumber>>;
  readonly sharpe: FormattedValue<Nullable<BigNumber>>;
  readonly maxLoss: FormattedValue<Nullable<BigNumber>>;
  readonly performance: AccountValueSnapshot[];
  readonly isUserAccount: boolean;
  readonly twitter: Nullable<TwitterProfileView>;
  readonly totalVolume: FormattedValue<Nullable<BigNumber>>;
}

export function createView(state: Store): ProleagueLeaderboardView {
  if (state.data == null) {
    return {
      data: [],
      isLoading: false,
      error: null,
    };
  }
  return {
    data: state.data.map(statView),
    isLoading: state.status === 'loading',
    error: state.error === '' ? null : state.error,
  };
}

function statView(stat: ProleagueStat): ProleagueStatView {
  return {
    rank: stat.rank,
    username: stat.username,
    isUserAccount: stat.is_user_account,
    pnl: pnlView(stat),
    pnlDirection: pnlDirectionView(stat),
    roi: roiView(stat),
    sharpe: sharpeView(stat),
    maxLoss: maxLossView(stat),
    performance: performanceView(stat),
    twitter: twitterView(stat),
    totalVolume: totalVolumeView(stat),
  };
}

function totalVolumeView(
  stat: ProleagueStat,
): FormattedValue<Nullable<BigNumber>> {
  const value = stat.traded_volume;
  const formatted = formatUsd(value ?? new BigNumber(0), {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
  return { value, formatted };
}

function pnlDirectionView(
  stat: ProleagueStat,
): ProleagueStatView['pnlDirection'] {
  const value = stat.realized_pnl;
  if (value == null) return null;
  return value.isNegative() ? 'down' : 'up';
}

function performanceView(stat: ProleagueStat): AccountValueSnapshot[] {
  return Object.entries(stat.account_value).map(([label, value]) => ({
    label,
    value,
  }));
}

function maxLossView(stat: ProleagueStat): FormattedValue<Nullable<BigNumber>> {
  const value = stat.max_drawdown;
  const formatted = value != null ? formatPercent(value) : '-';
  return { value, formatted };
}

function sharpeView(stat: ProleagueStat): FormattedValue<Nullable<BigNumber>> {
  const value = stat.sharpe_ratio;
  const formatted = value != null ? value.toFixed(1) : '-';
  return { value, formatted };
}

function pnlView(stat: ProleagueStat): FormattedValue<Nullable<BigNumber>> {
  const value = stat.realized_pnl;
  const formatted = formatUsd(value ?? new BigNumber(0), {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
  return { value, formatted };
}

function roiView(stat: ProleagueStat): FormattedValue<Nullable<BigNumber>> {
  const value = stat.total_roi;
  const formatted = value != null ? formatPercent(value) : '-';
  return { value, formatted };
}

function twitterView(stat: ProleagueStat): Nullable<TwitterProfileView> {
  const { twitter_username, twitter_profile_image_url } = stat;
  if (twitter_username == null) return null;

  return {
    id: null,
    handle: twitterHandle(twitter_username),
    imageUrl: twitter_profile_image_url ?? '',
    url: twitterProfileUrl(twitter_username),
    imageSrcSet: twitterImgSrcSet(twitter_profile_image_url ?? ''),
    imageSizes: TWITTER_IMG_SIZES,
  };
}
