import { STORAGE_KEY_LAST_USED_SUBACCOUNT } from '#/features/storage/storage';
import { Address } from '#/features/wallets/paraclear/wallet';

import {
  getStorageItem,
  removeStorageItem,
  setStorageItem,
} from '#/utils/localStorage';

interface LastUsedSubaccount {
  address: Address;
}

export const get = () => {
  return getStorageItem<LastUsedSubaccount>(STORAGE_KEY_LAST_USED_SUBACCOUNT);
};

export const set = (subaccount: LastUsedSubaccount) => {
  setStorageItem(STORAGE_KEY_LAST_USED_SUBACCOUNT, subaccount);
};

export const reset = () => {
  removeStorageItem(STORAGE_KEY_LAST_USED_SUBACCOUNT);
};
