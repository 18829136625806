import BigNumber from 'bignumber.js';

import { msToDate } from '#/utils/date';
import getQueryString from '#/utils/getQueryString';

import { AsyncResp, BaseReq, requestApi } from './fetch-api';
import { Market } from './markets';
import { UnixTimeMs } from './types';

export type TradeSide = 'BUY' | 'SELL';

export type TradeType = 'FILL' | 'LIQUIDATION';

export interface RawTrade {
  readonly id: string;
  readonly market: string;
  readonly side: TradeSide;
  readonly size: string;
  readonly price: string;
  readonly created_at: UnixTimeMs;
  readonly trade_type: TradeType;
}

export interface Trade extends Omit<RawTrade, 'size' | 'price' | 'created_at'> {
  readonly size: BigNumber;
  readonly price: BigNumber;
  readonly created_at: Date;
}

interface TradesReq extends BaseReq {
  readonly market: Market['symbol'];
  readonly pageSize?: number;
}

export interface RawTradesResp {
  readonly results: readonly RawTrade[];
}

export interface TradesResp {
  readonly results: readonly Trade[];
}

export async function getTrades(req: TradesReq): AsyncResp<TradesResp> {
  const { signal, market, pageSize = 100 } = req;

  const query = getQueryString([
    ['market', market],
    ['page_size', pageSize],
  ]);

  const resp = await requestApi<RawTradesResp>({
    signal,
    method: 'GET',
    url: `/trades${query}`,
  });

  if (!resp.ok) {
    return resp;
  }

  return {
    ...resp,
    data: { results: resp.data.results.map(processTrade) },
  };
}

export function processTrade(trade: RawTrade): Trade {
  return {
    ...trade,
    size: new BigNumber(trade.size),
    price: new BigNumber(trade.price),
    created_at: msToDate(trade.created_at),
  };
}
