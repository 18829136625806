import BigNumber from 'bignumber.js';

import { LPPointsEntry as ApiLPPointsEntry } from '#/api/points';

import { formatPercent } from '#/utils/percent';
import { FormattedValue } from '#/utils/types';
import { PollingConnectionBaseState } from '#/utils/usePollingConnection';

export interface Store extends PollingConnectionBaseState {
  readonly data: ApiLPPointsEntry[] | null;
}

export interface LPPointsEntryView {
  readonly market: string;
  readonly tier: string;
  readonly quote_quality: FormattedValue<BigNumber>;
  readonly maker_volume_score: FormattedValue<BigNumber>;
  readonly total_market_score: FormattedValue<BigNumber>;
  readonly score_share: FormattedValue<BigNumber>;
  readonly market_pool_share: FormattedValue<BigNumber>;
  readonly total_distributed_points: FormattedValue<BigNumber>;
}

export interface LPPointsView {
  readonly isLoading: boolean;
  readonly error: string | null;
  readonly data: readonly LPPointsEntryView[];
}

export function createView(state: Store): LPPointsView {
  const { status, error, data } = state;

  let transformedData: LPPointsEntryView[] = [];

  if (data !== null && Array.isArray(data)) {
    transformedData = data.map(
      (entry): LPPointsEntryView => ({
        market: entry.market,
        tier: entry.tier,
        quote_quality: {
          value: new BigNumber(entry.quote_quality),
          formatted: new BigNumber(entry.quote_quality).toFormat(2),
        },
        maker_volume_score: {
          value: new BigNumber(entry.maker_volume_score),
          formatted: new BigNumber(entry.maker_volume_score).toFormat(2),
        },
        total_market_score: {
          value: new BigNumber(entry.lp_score),
          formatted: new BigNumber(entry.lp_score).toFormat(2),
        },
        score_share: {
          value: new BigNumber(entry.score_share),
          formatted: formatPercent(new BigNumber(entry.score_share)),
        },
        market_pool_share: {
          value: new BigNumber(entry.instrument_share),
          formatted: formatPercent(new BigNumber(entry.instrument_share)),
        },
        total_distributed_points: {
          value: new BigNumber(entry.lp_points),
          formatted: new BigNumber(entry.lp_points).toFormat(2),
        },
      }),
    );
  }

  return {
    isLoading: status === 'loading',
    error: error === '' ? null : error,
    data: transformedData,
  };
}
