import { useTradeActiveMarketStoreView } from '#/features/perpetuals/active-market-context';
import { useSubAccountsStore } from '#/features/sub-accounts/context';

export function useActiveIsolatedMarketSubAccount() {
  const { activeMarket } = useTradeActiveMarketStoreView();
  const subAccountsStore = useSubAccountsStore();

  if (activeMarket == null) {
    return null;
  }

  const subAccount = subAccountsStore.subAccounts.getByIsolatedMarket(
    activeMarket.symbol,
  );

  if (subAccount == null) {
    return null;
  }

  return subAccount;
}
