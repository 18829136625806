import { adapter } from './state';

import type { ParaclearDeposit, ParaclearDepositState } from './state';

export interface ParaclearDepositStore {
  readonly deposits: {
    selectById: (id: string) => ParaclearDeposit | undefined;
  };
}

const selectors = adapter.getSelectors();

export function createStore(
  state: ParaclearDepositState,
): ParaclearDepositStore {
  return {
    deposits: {
      selectById: (id: string) => selectors.selectById(state.deposits, id),
    },
  };
}
