import { AsyncResp, BaseReq, requestApi } from '#/api/fetch-api';

import getQueryString from '#/utils/getQueryString';

export interface RawAccountHistory {
  /** Ordered list of datapoints */
  readonly data: ReadonlyArray<number>;
  /** Ordered list of timestamps */
  readonly timestamps: ReadonlyArray<number>;
}

export interface AccountHistory extends RawAccountHistory {}

export interface AccountHistoryReq extends BaseReq {
  readonly type: 'pnl' | 'value' | 'volume';
  readonly resolution: 'alltime' | '1h' | '8h';
}

export async function getAccountHistory(
  req: AccountHistoryReq,
): AsyncResp<AccountHistory> {
  const { signal } = req;
  const queryString = getQueryString([
    ['type', req.type],
    ['resolution', req.resolution],
  ]);

  const resp = await requestApi<RawAccountHistory>({
    signal,
    method: 'GET',
    url: `/account/history${queryString}`,
  });

  return resp;
}
