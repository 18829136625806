import { mix } from 'polished';

import { c0B0E12, c00EE91, cFF005C, cFFFFFF } from '#/colors';

const DEFAULT_FONT = 'bold 10px monospace';

const DEFAULT_LINE_LENGTH = 50;

const LINE_STYLE = 1; // Dotted line

const ACTION_COLOR = cFFFFFF;

const BODY_TEXT_COLOR = {
  BUY: c00EE91,
  SELL: cFF005C,
} as const;

const BODY_BACKGROUND_COLOR = c0B0E12;

const SIDE_COLOR = {
  BUY: c00EE91,
  SELL: cFF005C,
} as const;

const SIDE_BG_COLOR = {
  BUY: mix(0.2, c00EE91, c0B0E12),
  SELL: mix(0.2, cFF005C, c0B0E12),
} as const;

export interface OrderLineStyles {
  readonly lineLength: number;
  readonly lineColor: string;
  readonly lineStyle: number;
  readonly bodyFont: string;
  readonly bodyTextColor: string;
  readonly bodyBorderColor: string;
  readonly bodyBackgroundColor: string;
  readonly amountBackgroundColor: string;
  readonly amountBorderColor: string;
  readonly amountTextColor: string;
  readonly quantityFont: string;
  readonly cancelButtonBorderColor: string;
  readonly cancelButtonIconColor: string;
  readonly cancelButtonBackgroundColor: string;
}

export default function styles(side: 'BUY' | 'SELL'): OrderLineStyles {
  return {
    lineLength: DEFAULT_LINE_LENGTH,
    lineColor: SIDE_COLOR[side],
    bodyBorderColor: 'transparent',
    bodyBackgroundColor: BODY_BACKGROUND_COLOR,
    bodyTextColor: BODY_TEXT_COLOR[side],
    amountTextColor: SIDE_COLOR[side],
    amountBorderColor: 'transparent',
    amountBackgroundColor: SIDE_BG_COLOR[side],
    cancelButtonIconColor: ACTION_COLOR,
    cancelButtonBorderColor: 'transparent',
    cancelButtonBackgroundColor: SIDE_BG_COLOR[side],
    lineStyle: LINE_STYLE,
    bodyFont: DEFAULT_FONT,
    quantityFont: DEFAULT_FONT,
  };
}
