import { Dispatch, SetStateAction } from 'react';

import { postOnboarding } from '#/api/account';

import { userSignOutTopic } from '#/features/auth/auth-topics';
import { FeatureFlags } from '#/features/feature-flags';
import { logEvent, logException } from '#/features/logging/logging';
import {
  getIsBaseAccountCompliantStorageKey,
  getIsOnboardedStorageKey,
} from '#/features/storage/storage';
import { buildOnboardingTypedData } from '#/features/wallet/chain-l2';
import { getBaseAccountTypeLabel } from '#/features/wallet/common/base-account';
import { WalletClient } from '#/features/wallet/common/wallet-client';
import { prepareErrorMessage } from '#/features/wallet/ethereum';
import {
  ParadexChainConfigurable,
  ParadexEmbeddedConfigurable,
  WalletState,
} from '#/features/wallet/wallet-context';
import ParaclearWallet from '#/features/wallets/paraclear/wallet';

import { getStorageItem, setStorageItem } from '#/utils/localStorage';
import { AsyncResult } from '#/utils/types';

export default function actionPerformOnboarding(
  ...params: Parameters<typeof performOnboarding>
) {
  return async () => performOnboarding(...params);
}

const isL2OnlySessionEnabled = FeatureFlags.getBooleanValue(
  'l2-only-session-enabled',
  false,
);

async function performOnboarding(
  walletClient: WalletClient,
  walletState: WalletState,
  setState: Dispatch<SetStateAction<WalletState>>,
  options: ParadexChainConfigurable & ParadexEmbeddedConfigurable,
): AsyncResult<void> {
  if (walletState.isOnboarded) {
    return { ok: true, data: undefined };
  }

  const actions = prepareActions(setState);

  actions.resetError();
  actions.setStatus('signing_onboarding');

  try {
    const baseAccountRecovered = ParaclearWallet.getBaseAccount();

    const hasOnboardedBefore =
      getStorageItem<boolean>(getIsOnboardedStorageKey(baseAccountRecovered)) ??
      false;

    if (!hasOnboardedBefore) {
      const onboardingTypedData = buildOnboardingTypedData(
        options.paradexChainId,
      );
      const signature = ParaclearWallet.signTypedData(onboardingTypedData);
      const paradexPublicKey = `0x${ParaclearWallet.getPublicKey()}`;
      const paradexAddress = ParaclearWallet.getAddress();

      const baseAddressRecovered = baseAccountRecovered.address;

      if (!isL2OnlySessionEnabled) {
        const baseAddressConnected = walletClient.getAddress();
        if (baseAddressConnected !== baseAddressRecovered) {
          const baseAccountRecoveredTypeLabel =
            getBaseAccountTypeLabel(baseAccountRecovered);
          logEvent(
            `Failed to perform onboarding. Currently active ${baseAccountRecoveredTypeLabel} address does not match the address that generated the Paradex address.`,
            {
              baseAddressConnected,
              baseAddressRecovered,
              paradexAddress,
            },
          );
          // WIP: to be translated
          actions.setError(
            `The ${baseAccountRecoveredTypeLabel} address ${baseAddressRecovered} is still associated with this session. ` +
              `To connect with ${baseAddressConnected}, first click "Disconnect Wallet" and try again.`,
          );
          actions.setStatus('wallet_connected_idle');
          return { ok: false, error: null };
        }
      }

      const { referralCode } = walletState;
      if (referralCode != null) {
        logEvent('Onboarding with referral code', { referralCode });
      }

      const resp = await postOnboarding({
        signature,
        paradexPublicKey,
        paradexAddress,
        derivationPath: ParaclearWallet.getDerivation()?.path ?? null,
        baseAccount: baseAccountRecovered,
        parentUserToken: options.parentUserToken,
        referralCode: referralCode ?? undefined,
      });

      if (!resp.ok) {
        const error = [400, 401, 403].includes(resp.status)
          ? null
          : new Error(`Error onboarding: ${resp.message}`, {
              cause: resp.error,
            });
        actions.setError(resp.message);
        actions.setStatus('wallet_connected_idle');

        if (!walletState.isWalletConnected) {
          userSignOutTopic.publish({});
          logException(
            'User signed out due to onboarding error and disconnected wallet to prevent invalid state',
            { cause: error },
          );
          return { ok: false, error };
        }
      }
      setStorageItem(getIsOnboardedStorageKey(baseAccountRecovered), true);
    }

    // Only after successful onboarding, we can remember that the wallet is compliant
    setStorageItem(
      getIsBaseAccountCompliantStorageKey(baseAccountRecovered),
      true,
    );

    actions.setIsOnboarded(true);
    actions.setStatus('wallet_connected_idle');

    return { ok: true, data: undefined };
  } catch (err) {
    const description = 'Failed to perform onboarding';
    const { message, isException } = prepareErrorMessage(description, err);
    actions.setError(message);
    actions.setStatus('wallet_connected_idle');
    if (isException) {
      const error = new Error(description, { cause: err });
      logException(error);
      return { ok: false, error };
    }
    logEvent(message);
    return { ok: false, error: null };
  }
}

function prepareActions(setState: Dispatch<SetStateAction<WalletState>>) {
  function setStatus(status: WalletState['step']) {
    setState((state) => ({ ...state, step: status }));
  }

  function setIsOnboarded(isOnboarded: WalletState['isOnboarded']) {
    setState((state) => ({ ...state, isOnboarded }));
  }

  const setError = (error: string) => {
    setState((state) => ({ ...state, error }));
  };

  const resetError = () => {
    setState((state) => ({ ...state, error: '' }));
  };

  return { setStatus, setIsOnboarded, setError, resetError };
}
