import BigNumber from 'bignumber.js';

import { Market } from '#/api/markets';
import { OrderInstruction, OrderSide, UnsignedOrderReq } from '#/api/orders';

import * as OrderBuilderFn from '#/features/perpetuals/order-builder/functions';

export function prepareOrders(
  activeMarket: Market,
  markPrice: BigNumber,
  side: OrderSide,
  currency: string,
  startPrice: BigNumber,
  endPrice: BigNumber,
  amount: BigNumber,
  totalOrders: BigNumber,
  sizeSkew: BigNumber,
  isPostOnly: boolean,
  timeInForce: OrderInstruction,
  isReduceOnly: boolean,
): readonly UnsignedOrderReq[] {
  const amountInBaseCurrency = OrderBuilderFn.convertAmountToBaseAsset(
    currency,
    amount,
    markPrice,
    activeMarket,
  );

  if (amountInBaseCurrency == null) {
    throw new Error(
      `Failed to calculate amount in base currency '${activeMarket.base_currency}'`,
    );
  }

  const priceIncrement = calcPriceIncrement(startPrice, endPrice, totalOrders);
  const sizeP1 = calcSizeP1(amountInBaseCurrency, totalOrders, sizeSkew);
  const sizeIncrement = calcSizeIncrement(sizeP1, totalOrders, sizeSkew);
  const orderSizeIncrement = activeMarket.order_size_increment;

  const orders = [];
  for (let index = 1; totalOrders.isGreaterThanOrEqualTo(index); index++) {
    const size = calcSize(
      BigNumber(index),
      sizeP1,
      sizeIncrement,
      orderSizeIncrement,
    );
    const price = calcPrice(
      BigNumber(index),
      startPrice,
      priceIncrement,
      activeMarket.price_tick_size,
    );
    const order: UnsignedOrderReq = {
      signature_timestamp: Date.now(),
      market: activeMarket.symbol,
      side,
      type: 'LIMIT',
      size: size.toFixed(),
      price: price.toFixed(),
      instruction: isPostOnly ? 'POST_ONLY' : timeInForce,
      flags: isReduceOnly ? ['REDUCE_ONLY'] : [],
    };
    orders.push(order);
  }
  return orders;
}

function calcPrice(
  orderNumber: BigNumber,
  startPrice: BigNumber,
  priceIncrement: BigNumber,
  priceTickSize: BigNumber,
): BigNumber {
  const price = startPrice
    .plus(orderNumber.minus(1).multipliedBy(priceIncrement))
    .dividedBy(priceTickSize)
    .decimalPlaces(0, BigNumber.ROUND_HALF_UP)
    .multipliedBy(priceTickSize);

  return price;
}

function calcSize(
  orderNumber: BigNumber,
  initialSize: BigNumber,
  sizeIncrement: BigNumber,
  orderSizeIncrement: BigNumber,
): BigNumber {
  // Calculate the size for the current order
  const currentOrderSize = initialSize
    .multipliedBy(orderNumber)
    .plus(
      sizeIncrement
        .multipliedBy(orderNumber.minus(1))
        .multipliedBy(orderNumber)
        .dividedBy(2),
    )
    .dividedBy(orderSizeIncrement)
    .decimalPlaces(0, BigNumber.ROUND_HALF_UP)
    .multipliedBy(orderSizeIncrement);

  // Calculate the size for the previous order
  const previousOrderSize = initialSize
    .multipliedBy(orderNumber.minus(1))
    .plus(
      sizeIncrement
        .multipliedBy(orderNumber.minus(2))
        .multipliedBy(orderNumber.minus(1))
        .dividedBy(2),
    )
    .dividedBy(orderSizeIncrement)
    .decimalPlaces(0, BigNumber.ROUND_HALF_UP)
    .multipliedBy(orderSizeIncrement);

  // Calculate the size difference between the current and previous order
  const sizeDifference = currentOrderSize.minus(previousOrderSize);

  return sizeDifference;
}

function calcPriceIncrement(
  startPrice: BigNumber,
  endPrice: BigNumber,
  orderCount: BigNumber,
): BigNumber {
  // Calculate the price increment
  const priceIncrement = endPrice
    .minus(startPrice)
    .dividedBy(orderCount.minus(1));

  return priceIncrement;
}

function calcSizeIncrement(
  sizeP1: BigNumber,
  orderCount: BigNumber,
  skew: BigNumber,
): BigNumber {
  const sizeIncrement = sizeP1
    .multipliedBy(skew.minus(1))
    .dividedBy(orderCount.minus(1));

  return sizeIncrement;
}

function calcSizeP1(
  totalAmount: BigNumber,
  orderCount: BigNumber,
  sizeSkew: BigNumber,
): BigNumber {
  const sizeP1 = totalAmount
    .multipliedBy(2)
    .dividedBy(orderCount.multipliedBy(sizeSkew.plus(1)));

  return sizeP1;
}
