import { adapter } from './state';

import type { FavoriteMarket, FavoriteMarketsState } from './state';

export interface FavoriteMarketsStore {
  readonly favoriteMarkets: {
    getAll: () => readonly FavoriteMarket[];
    isFavorite: (symbol: string) => boolean;
  };
}

const selectors = adapter.getSelectors();

export function createStore(state: FavoriteMarketsState): FavoriteMarketsStore {
  return {
    favoriteMarkets: {
      getAll: () => selectors.selectAll(state.favoriteMarkets),
      isFavorite: (symbol: string) =>
        selectors.selectById(state.favoriteMarkets, symbol) != null,
    },
  };
}
