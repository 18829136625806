import { useCallback } from 'react';
import { debounce } from 'lodash';

export default function useDebouncedCallback(
  callback: (value: string) => void,
  delay: number,
) {
  // ESLint fails to check deps for debounced function, callback and delay should be the only dependencies
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    debounce((value: string) => {
      callback(value);
    }, delay),
    [callback, delay],
  );
}
