import BigNumber from 'bignumber.js';

const smallValueFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});
const largeValueFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export default function formatIV(value: BigNumber): string {
  if (value.abs().isLessThan(0.01)) {
    return smallValueFormatter.format(value.toNumber());
  }
  return largeValueFormatter.format(value.toNumber());
}
