/**
 * Observability action names
 * @example:
 * ```tsx
 * import { ACTION } from '#/features/logging/datadog/actions';
 *
 * <Button
 *   data-dd-action-name={ACTION["Transfer funds"]}
 *   disabled={isDisabled}
 * >
 *   Transfer
 * </Button>
 * ```
 *
 * To add extra context to the action, use the `data-dd-action-context` attribute:
 * ```tsx
 * <Button
 *   data-dd-action-name={ACTION["Withdraw via bridge"]}
 *   data-dd-action-context={JSON.stringify({
 *     bridgeId: bridgeId,
 *   })}
 * >
 *   Withdraw
 * </Button>
 * ```
 */
export const ACTION = stringEnum([
  "Connect wallet",
  "Connect wallet small",
  "Connect Starknet wallet",
  "Choose wallet",
  "Connect with email or social",
  "Continue onboarding",
  "Disconnect Wallet in Onboarding",
  "Disconnect Wallet after Error",
  "Transfer funds",
  "Withdraw funds",
  "Deposit funds",
  "Deposit from App Bar",
  "Withdraw via bridge",
  "Withdraw via Layerswap",
  "Deposit via bridge",
  "Enable USDC deposit",
  "Transfer between accounts",
  "Withdraw from vault",
  "Manage vault withdrawal",
]);

/** Type representing all available action names */
export type ActionName = keyof typeof ACTION;

/** Helper function to create an object with identical keys and values from an array of strings */
function stringEnum<T extends string>(values: readonly T[]) {
  return Object.fromEntries(values.map((value) => [value, value])) as { [K in T]: K };
}
