import {
  add,
  differenceInSeconds,
  format,
  formatDuration,
  intervalToDuration,
} from 'date-fns';
import { flow } from 'lodash';

import { UnixTimeMicroseconds, UnixTimeMs } from '#/api/types';

// Don't reorder, must match 0..11 to Jan..Dec
const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

/**
 * e.g. "1 May 21", "21 Sep 98"
 *
 * This is used on some hot paths of our application. Please don't
 * refactor without measuring the performance impact.
 */
export function shortDateFormat(date: Readonly<Date>) {
  const year = date.getFullYear().toString();
  const twoDigitYear = year.slice(-2);
  const month = MONTHS[date.getMonth()];
  return `${date.getDate()} ${month} ${twoDigitYear}`;
}

export function timeFormat(date: Date) {
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
}

/**
 * Convert unix milliseconds to Date
 */
export function msToDate(milliseconds: number): Date {
  return new Date(milliseconds);
}

export function usToMs(microseconds: UnixTimeMicroseconds): UnixTimeMs {
  return microseconds / 1000;
}

export function durationInSeconds(duration: Duration) {
  const baseDate = new Date(0);
  const toSeconds = flow(
    () => add(baseDate, duration),
    (dateWithDuration) => differenceInSeconds(dateWithDuration, baseDate),
  );

  return toSeconds();
}

export function secondsToLabel(seconds: number) {
  const toLabel = flow(
    () => intervalToDuration({ start: 0, end: seconds * 1000 }),
    formatDuration,
  );

  return toLabel();
}

export function formatDurationByStr(duration: Duration, formatStr: string) {
  const baseDate = new Date(0, 0, 0, 0, 0, 0, 0);
  const dateInterval = add(baseDate, duration);
  return format(dateInterval, formatStr);
}

export function sortDateAscending(a: Date, b: Date) {
  return a.getTime() - b.getTime();
}
