import maskString from '#/utils/maskString';

/**
 * @param account Account address
 * @example
 *  maskAccount('0x370786779Ab9159FA853af87a24FB01D58C96B28') // => '0x3707…6B28'
 */
export function maskAccount(account: string | null): string {
  return maskString(account ?? '', 6, 4);
}
