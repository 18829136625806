import { throttle } from 'lodash';

import { RawOrderBookUpdate } from '#/api/order-book';

import type { DebouncedFunc } from 'lodash';

type Identity = <T>(value: T) => T;

const identity: Identity = (value) => value;

const throttledIdentityScoped: Map<string, DebouncedFunc<Identity>> = new Map();

function getThrottledIdentity(scope: string): DebouncedFunc<Identity> {
  if (!throttledIdentityScoped.has(scope)) {
    throttledIdentityScoped.set(scope, throttle(identity, 200));
  }
  const throttledIdentity = throttledIdentityScoped.get(scope)!;
  return throttledIdentity;
}

let lastUpdate: unknown = undefined;

export function throttleOrderBookUpdate(update: RawOrderBookUpdate): boolean {
  const throttledIdentity = getThrottledIdentity(update.market);
  const currentUpdate = throttledIdentity(update);
  const shouldThrottle = currentUpdate === lastUpdate;
  lastUpdate = currentUpdate;
  return shouldThrottle;
}
