import BigNumber from 'bignumber.js';

import * as MarketFn from '#/features/market/functions';

import { formatUsd } from '#/utils/fiat';
import { formatPercentChange } from '#/utils/percent';

import type { Market } from '#/api/markets';
import type { MarketSummary } from '#/api/markets-summary';
import type { FavoriteMarket } from '#/features/favorite-markets/state';
import type { FavoriteMarketsStore } from '#/features/favorite-markets/store';
import type { FavoritesBarStore } from '#/features/favorites-bar/store';
import type { MarketsStoreView } from '#/features/perpetuals/market-info/markets-context';
import type { MarketsSummaryStoreView } from '#/features/perpetuals/market-info/markets-summary-context';
import type { FormattedValueChange } from '#/utils/types';

interface FavoriteView {
  readonly symbol: string;
  readonly symbolShort: string;
  readonly priceChange24h: FormattedValueChange<BigNumber | null>;
}

interface View {
  readonly favorites: readonly FavoriteView[];
  readonly mode: 'percentage' | 'absolute';
  readonly isModeVisible: boolean;
  readonly isEmpty: boolean;
}
export type { View as FavoritesBarView };

export function createView(
  store: FavoritesBarStore,
  favoriteMarketsStore: FavoriteMarketsStore,
  marketsStore: MarketsStoreView,
  marketsSummaryStore: MarketsSummaryStoreView,
): View {
  const allFavorites = favoriteMarketsStore.favoriteMarkets.getAll();

  const availableFavorites = allFavorites.reduce<FavoriteView[]>(
    (acc, favorite) => {
      const market = marketsStore.getMarket(favorite.symbol);
      if (market == null) return acc;

      const marketSummary = marketsSummaryStore.getMarketSummary(
        favorite.symbol,
      );
      if (marketSummary == null) return acc;

      acc.push(favoriteView(store, favorite, market, marketSummary));
      return acc;
    },
    [],
  );

  return {
    favorites: availableFavorites,
    mode: store.mode,
    isModeVisible: availableFavorites.length > 0,
    isEmpty: availableFavorites.length === 0,
  };
}

function favoriteView(
  store: FavoritesBarStore,
  favorite: FavoriteMarket,
  market: Market,
  marketSummary: MarketSummary,
): FavoriteView {
  return {
    symbol: favorite.symbol,
    symbolShort: shortSymbolView(market),
    priceChange24h: priceChange24hView(store, market, marketSummary),
  };
}

function shortSymbolView(market: Market): FavoriteView['symbolShort'] {
  switch (market.asset_kind) {
    case 'PERP':
      return `${market.base_currency}-${market.quote_currency}`;
    case 'PERP_OPTION':
      return market.symbol;
    // no default
  }
}

function priceChange24hView(
  store: FavoritesBarStore,
  market: Market,
  marketSummary: MarketSummary,
): FavoriteView['priceChange24h'] {
  switch (store.mode) {
    case 'percentage':
      return priceChange24hPercentageView(marketSummary);
    case 'absolute':
      return priceChange24hAbsoluteView(market, marketSummary);
    // no default
  }
}

function priceChange24hPercentageView(
  marketSummary: MarketSummary,
): FavoriteView['priceChange24h'] {
  if (marketSummary.price_change_rate_24h == null)
    return { value: null, formatted: '-', direction: 'up' };

  return {
    value: marketSummary.price_change_rate_24h,
    formatted: formatPercentChange(marketSummary.price_change_rate_24h, {
      signDisplay: 'always',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }),
    direction: marketSummary.price_change_rate_24h.gt(0) ? 'up' : 'down',
  };
}

function priceChange24hAbsoluteView(
  market: Market,
  marketSummary: MarketSummary,
): FavoriteView['priceChange24h'] {
  if (marketSummary.price_change_rate_24h == null)
    return { value: null, formatted: '-', direction: 'up' };

  if (marketSummary.mark_price == null)
    return { value: null, formatted: '-', direction: 'up' };

  const priceChange24 = marketSummary.price_change_rate_24h
    .times(marketSummary.mark_price)
    .div(marketSummary.price_change_rate_24h.plus(1));

  const decimalPlaces = MarketFn.quoteCurrencyDecimalPlaces(market);
  const formatted = formatUsd(priceChange24, {
    signDisplay: 'always',
    maximumFractionDigits: decimalPlaces,
    minimumFractionDigits: decimalPlaces,
  });

  return {
    value: priceChange24,
    formatted,
    direction: priceChange24.gt(0) ? 'up' : 'down',
  };
}
