import { BigNumber } from 'bignumber.js';

import { Market } from '#/api/markets';
import { MarketSummary } from '#/api/markets-summary';

import { ModifyOrderFormState } from './form-state';

export function getPriceByOrderType(
  state: ModifyOrderFormState,
  marketInfo: Market,
  marketSummary: MarketSummary,
): BigNumber | null {
  switch (marketInfo.asset_kind) {
    case 'PERP': // assume limit type order
      return state.price;
    case 'PERP_OPTION': // assume limit type order
      return marketSummary.underlying_price;
    default:
      return null;
  }
}
