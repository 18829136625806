import { useNavigate } from 'react-router-dom';

import { OptionType } from '#/api/markets';

import { logEvent, logException } from '#/features/logging/logging';
import { useTradeActiveMarketStoreView } from '#/features/perpetuals/active-market-context';
import { usePerpetualMarketsStoreView } from '#/features/perpetuals/market-info/markets-context';

import { TRADE_ROUTE } from '#/routes';

export default function useNavigateToMarketOptionType() {
  const navigate = useNavigate();
  const { activeMarket } = useTradeActiveMarketStoreView();
  const marketsStoreView = usePerpetualMarketsStoreView();

  return (optionType: OptionType) => {
    if (activeMarket == null) {
      logException(
        new Error('No active market available. Staying on the current market.'),
      );
      return;
    }

    if (activeMarket.asset_kind !== 'PERP_OPTION') {
      logException(
        new Error(
          'Current market is not a perpetual option. Staying on the current market.',
        ),
      );
      return;
    }

    const currentSymbol = activeMarket.symbol;
    const nextSymbol = currentSymbol.replace(
      /-[CP]$/u,
      optionType === 'CALL' ? '-C' : '-P',
    );

    const nextMarket = marketsStoreView.getMarket(nextSymbol);
    if (nextMarket == null) {
      logEvent(
        'User attempted to switch to non-existent option market. Staying on the current market.',
        {
          currentSymbol,
          nextSymbol,
        },
      );
      return;
    }

    navigate(`${TRADE_ROUTE}/${nextSymbol}`);
  };
}
