import { useFlags } from 'launchdarkly-react-client-sdk';

import { isTestEnv } from './env';

// by launchdarkly convention, flag is automatically converted into camelCase
export type Flag =
  | 'enablePerpetualOption'
  | 'enableMultiStrategyVaultsUi'
  | 'enableModifyOrder'
  | 'enableDynamicActiveMarket'
  | 'enableMarketCloseAll'
  | 'enableLayerswapOnsiteIntegration'
  | 'enablePrivyEmailLogin'
  | 'enablePositionsHistoryFeature'
  | 'enableLiquidityDashboard'
  | 'enablePortfolioGreeks'
  | 'enablePositionLimitTpsl'
  | 'enableMaxLeverage'
  | 'enableIsolatedMargin'
  | 'marketMenuCategoryFiltering'
  | 'enableChartTradeHistory'
  | 'enableVolumeStatsPortfolio'
  | 'enableVaultWithdrawalUi'
  | 'enablePositionModeModal'
  | 'enablePositionLeverage'
  | 'enableDeveloperModeUi'
  | 'enableMarketsPageUi'
  | 'enableAdvancedTpsl'
  | 'enableAffiliateDashboard'
  | 'enableLimitClosePositionUi';

export const useLaunchDarklyFlag = (
  flag: Flag,
  options: { default?: boolean } = {},
): boolean => {
  const flags = useFlags();
  if (isTestEnv()) return true;
  return Boolean(flags[flag] ?? options.default ?? false);
};
