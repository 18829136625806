import BigNumber from 'bignumber.js';

import { Market } from '#/api/markets';
import { MarketSummary } from '#/api/markets-summary';

import perpBsPrice from '#/features/shared/formula/PerpBsPrice';

/**
 * Implementation based on:
 * @source https://github.com/tradeparadigm/mono/blob/release/paradex-1.82.0/api/paradex/position-management-service/handler/account.go#L116
 */
export default function getPerpOptionPriceBand(
  market: Market,
  marketSummary: MarketSummary,
  side: 'BUY' | 'SELL',
): BigNumber {
  if (marketSummary.underlying_price == null) return new BigNumber(0);
  if (marketSummary.mark_iv == null) return new BigNumber(0);
  if (marketSummary.future_funding_rate == null) return new BigNumber(0);
  if (market.asset_kind !== 'PERP_OPTION') return new BigNumber(0);
  if (market.option_type == null) return new BigNumber(0);
  if (market.iv_bands_width == null) return new BigNumber(0);

  // Calculate direction multiplier based on side
  const sideDirection = side === 'BUY' ? new BigNumber(1) : new BigNumber(-1);

  // Set band positions based on option type
  let ivBandPosition = new BigNumber(1);
  let spotBandPosition = new BigNumber(1);

  if (market.option_type === 'PUT') {
    // For PUT options:
    // - BUY: use upper price band (spotBand = -1) and upper iv band (ivBand = 1)
    // - SELL: use lower price band (spotBand = -1) and lower iv band (ivBand = 1)
    ivBandPosition = new BigNumber(1);
    spotBandPosition = new BigNumber(-1);
  } else {
    // For CALL options:
    // - BUY: use upper price band (spotBand = 1) and upper iv band (ivBand = 1)
    // - SELL: use lower price band (spotBand = 1) and lower iv band (ivBand = 1)
    ivBandPosition = new BigNumber(1);
    spotBandPosition = new BigNumber(1);
  }

  // Get required values from market and market summary
  const priceBandWidth = market.price_bands_width;
  const ivBandWidth = market.iv_bands_width;
  const spotPrice = new BigNumber(marketSummary.underlying_price);
  // @ts-expect-error: not used, review before merging
  const markVariance = new BigNumber(marketSummary.mark_iv).pow(2);
  const strike = market.strike_price!;
  const fundingPeriodYears = market.funding_period_hours.div(24 * 365);
  const futureFundingPeriodYears = fundingPeriodYears; // Same as funding period in the current implementation
  const d1FundingRate = new BigNumber(marketSummary.future_funding_rate);

  // Calculate bands
  const iv = new BigNumber(marketSummary.mark_iv);
  const ivBand = iv.times(
    new BigNumber(1).plus(
      ivBandWidth.times(sideDirection).times(ivBandPosition),
    ),
  );
  const spotBand = spotPrice.times(
    new BigNumber(1).plus(
      priceBandWidth.times(sideDirection).times(spotBandPosition),
    ),
  );

  // Calculate price using Black-Scholes
  let price = perpBsPrice(
    spotBand,
    strike,
    ivBand.pow(2), // variance
    d1FundingRate,
    fundingPeriodYears,
    futureFundingPeriodYears,
    market.option_type,
  );

  // Ensure all option prices are positive
  price = price.abs();

  // Round to tick size based on side
  const tickSize = market.price_tick_size;
  const result =
    side === 'BUY'
      ? price.div(tickSize).integerValue(BigNumber.ROUND_CEIL).times(tickSize)
      : price.div(tickSize).integerValue(BigNumber.ROUND_FLOOR).times(tickSize);

  return result;
}
