import BigNumber from 'bignumber.js';

import { msToDate } from '#/utils/date';

import { AsyncResp, BaseReq, requestApi } from './fetch-api';
import { UnixTimeMs } from './types';

export interface RawBalance {
  readonly token: string;
  readonly size: string;
  readonly last_updated_at: UnixTimeMs;
}

export interface Balance extends Omit<RawBalance, 'size' | 'last_updated_at'> {
  readonly size: BigNumber;
  readonly last_updated_at: Date;
}

interface BalancesReq extends BaseReq {}

export interface RawBalancesResp {
  readonly results: readonly RawBalance[];
}

export interface BalancesResp {
  readonly results: readonly Balance[];
}

export async function getBalances(req: BalancesReq): AsyncResp<BalancesResp> {
  const { signal } = req;

  const resp = await requestApi<RawBalancesResp>({
    signal,
    method: 'GET',
    url: `/balance`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: { results: resp.data.results.map(processBalance) },
  };
}

export function processBalance(balance: RawBalance): Balance {
  return {
    ...balance,
    last_updated_at: msToDate(balance.last_updated_at),
    size: new BigNumber(balance.size),
  };
}
