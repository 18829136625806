import BigNumber from 'bignumber.js';

import { msToDate } from '#/utils/date';

import { UnixTimeMs } from './types';

export const fundingRateSources = [
  'SOURCE_PARADEX',
  'SOURCE_BINANCE',
  'SOURCE_BYBIT',
  'SOURCE_OKX',
  'SOURCE_HYPERLIQUID',
] as const;

export type FundingRateSource = (typeof fundingRateSources)[number];

export interface RawFundingRateComparison {
  asset_kind: string;
  symbol: string;
  source: FundingRateSource;
  last_updated_at: UnixTimeMs;
  hourly_funding_rate: string;
}

export interface RawFundingRateComparisonResp {
  readonly results: readonly RawFundingRateComparison[];
}

export interface FundingRateComparisonResp {
  readonly results: readonly FundingRateComparison[];
}

export interface FundingRateComparison
  extends Omit<
    RawFundingRateComparison,
    'last_updated_at' | 'hourly_funding_rate'
  > {
  readonly last_updated_at: Date;
  readonly hourly_funding_rate: BigNumber;
}

export function processFundingRateComparison(
  fundingRateComparison: RawFundingRateComparison,
): FundingRateComparison {
  return {
    ...fundingRateComparison,
    last_updated_at: msToDate(fundingRateComparison.last_updated_at),
    hourly_funding_rate: new BigNumber(
      fundingRateComparison.hourly_funding_rate,
    ),
  };
}
