import { CreateOrderReq, ModifyOrderReq } from '#/api/orders';

import * as OrderFn from '#/features/order/functions';
import {
  buildModifyOrderTypeData,
  buildOrderTypeData,
} from '#/features/wallet/chain-l2';
import ParaclearWallet, {
  Address,
  TypedData,
} from '#/features/wallets/paraclear/wallet';

import { toQuantums } from '#/utils/quantums';

import { MAIN_ACCOUNT_IDENTIFIER } from './perpetuals/order-builder/shared/on-behalf-of-account';

type OrderRequest = Pick<
  CreateOrderReq,
  | 'signature_timestamp'
  | 'price'
  | 'signature_timestamp'
  | 'market'
  | 'side'
  | 'type'
  | 'size'
>;
export function signOrderRequest<T extends OrderRequest>(
  request: T,
  paradexChainId: string,
  onBehalfOfAccount: string, // Submitting order from isolated account
): T & { signature: string } {
  const price = OrderFn.isLimitOrder(request) ? request.price : '0';
  if (price == null) throw new Error('Limit order must have a price');

  const message = {
    timestamp: request.signature_timestamp,
    market: request.market,
    side: request.side === 'BUY' ? '1' : '2',
    orderType: request.type,
    size: toQuantums(request.size, 8),
    price: toQuantums(price, 8),
  };

  const typedData: TypedData = buildOrderTypeData(message, paradexChainId);

  const signature = (() => {
    if (onBehalfOfAccount === MAIN_ACCOUNT_IDENTIFIER) {
      return ParaclearWallet.signTypedData(typedData);
    }
    return ParaclearWallet.signTypedDataAs(
      onBehalfOfAccount as Address,
      typedData,
    );
  })();

  return {
    ...request,
    on_behalf_of_account:
      onBehalfOfAccount === MAIN_ACCOUNT_IDENTIFIER ? null : onBehalfOfAccount,
    signature: JSON.stringify([signature.r.toString(), signature.s.toString()]),
  };
}

type ModifyOrderRequest = Pick<
  ModifyOrderReq,
  | 'signature_timestamp'
  | 'signature'
  | 'id'
  | 'market'
  | 'price'
  | 'size'
  | 'side'
  | 'type'
>;
export function signModifyOrderRequest<T extends ModifyOrderRequest>(
  request: T,
  paradexChainId: string,
  onBehalfOfAccount: string, // Submitting order from isolated margin account
): T & { signature: string } {
  const message = {
    timestamp: request.signature_timestamp,
    market: request.market,
    side: request.side === 'BUY' ? '1' : '2',
    orderType: request.type,
    size: toQuantums(request.size, 8),
    price: toQuantums(request.price, 8),
    id: request.id,
    on_behalf_of_account: onBehalfOfAccount,
  };

  const typedData: TypedData = buildModifyOrderTypeData(
    message,
    paradexChainId,
  );

  const signature = (() => {
    if (onBehalfOfAccount === MAIN_ACCOUNT_IDENTIFIER) {
      return ParaclearWallet.signTypedData(typedData);
    }
    return ParaclearWallet.signTypedDataAs(
      onBehalfOfAccount as Address,
      typedData,
    );
  })();

  return {
    ...request,
    on_behalf_of_account:
      onBehalfOfAccount === MAIN_ACCOUNT_IDENTIFIER ? null : onBehalfOfAccount,
    signature: JSON.stringify([signature.r.toString(), signature.s.toString()]),
  };
}
