import type { DataPoint } from '#/utils/types';

/**
 * Creates a function that adds a delta value to a point's value.
 * Useful when mapping over an array to adjust all values.
 *
 * @param delta The value to add to each point's value
 * @returns A function that takes a DataPoint and returns a new DataPoint with adjusted value
 */
export function applyDelta(delta: number) {
  return <T extends DataPoint>(point: T): T => {
    return { ...point, value: point.value + delta };
  };
}
