import {
  getStorageItem,
  removeStorageItem,
  setStorageItem,
} from '#/utils/localStorage';

import {
  getParaclearWalletSeedStorageKey,
  getParaclearWalletSeedStorageListKey,
  STORAGE_KEY_ETHEREUM_WALLET_ADDRESS,
  STORAGE_KEY_ETHEREUM_WALLET_ADDRESS_LIST,
} from './storage';

interface WalletAddressMap {
  // value is the timestamp of the last connection
  [key: string]: number;
}

interface WalletSeedMap {
  [walletAddress: string]: string | null;
}

/**
 * Operations to manage list of ethereum wallet addresses in local storage.
 *
 * If an wallet is stored here, it means that it has a valid session.
 *
 * It stores a map like:
 *
 * {
 *   '0x123': 1718524800000,
 *   '0x456': 1718524800000,
 * }
 *
 * The value is the timestamp of the last connection and the key is the wallet address.
 */
const ethereumWalletsStore = {
  key: STORAGE_KEY_ETHEREUM_WALLET_ADDRESS_LIST,
  get: () => {
    return getStorageItem<WalletAddressMap>(ethereumWalletsStore.key) ?? {};
  },
  set: (addressList: WalletAddressMap) => {
    setStorageItem<WalletAddressMap>(ethereumWalletsStore.key, addressList);
  },
  getOne: (ethereumAddress: string) => {
    const addressList = ethereumWalletsStore.get();
    return addressList[ethereumAddress] != null ? ethereumAddress : null;
  },
  setOne: (ethereumAddress: string) => {
    const addressList = ethereumWalletsStore.get();
    ethereumWalletsStore.set({
      ...addressList,
      [ethereumAddress]: Date.now(),
    });
  },
  remove: () => {
    removeStorageItem(ethereumWalletsStore.key);
  },
  removeOne: (ethereumAddress: string) => {
    const addressList = ethereumWalletsStore.get();
    const { [ethereumAddress]: _, ...restAddress } = addressList;
    ethereumWalletsStore.set(restAddress);
  },
  getActiveWallet: () => {
    const addressList = ethereumWalletsStore.get();
    return (
      Object.keys(addressList).sort(
        (addressA, addressB) => addressList[addressB]! - addressList[addressA]!,
      )[0] ?? null
    );
  },
};

/**
 * Operations to manage list of ethereum wallet seeds in local storage.
 *
 * This is used to store the seed of the connected wallets.
 *
 * It stores a map like:
 *
 * {
 *   '0x123': '0x1234567890abcdef',
 *   '0x456': '0x1234567890abcdef',
 * }
 *
 * The key is the wallet address and the value is the seed.
 */
const walletSeedsStore = {
  getKey: (chainId: number) => getParaclearWalletSeedStorageListKey(chainId),
  get: (chainId: number) => {
    return (
      getStorageItem<WalletSeedMap>(walletSeedsStore.getKey(chainId)) ?? {}
    );
  },
  set: (chainId: number, walletSeedMap: WalletSeedMap) => {
    setStorageItem<WalletSeedMap>(
      walletSeedsStore.getKey(chainId),
      walletSeedMap,
    );
  },
  setOne: (chainId: number, walletAddress: string, walletSeed: string) => {
    const walletSeedMap = walletSeedsStore.get(chainId);
    walletSeedsStore.set(chainId, {
      ...walletSeedMap,
      [walletAddress]: walletSeed,
    });
  },
  getOne: (chainId: number, walletAddress: string) => {
    const walletSeedMap = walletSeedsStore.get(chainId);
    return walletSeedMap[walletAddress];
  },
  remove: (chainId: number) => {
    removeStorageItem(walletSeedsStore.getKey(chainId));
  },
  removeOne: (chainId: number, walletAddress: string) => {
    const walletSeedMap = walletSeedsStore.get(chainId);
    const { [walletAddress]: _, ...restSeeds } = walletSeedMap;
    walletSeedsStore.set(chainId, restSeeds);
  },
  getActiveSeed: (chainId: number) => {
    const walletSeedMap = walletSeedsStore.get(chainId);
    const lastUsedAddress = ethereumWalletsStore.getActiveWallet();
    return lastUsedAddress != null
      ? walletSeedMap[lastUsedAddress] ?? null
      : null;
  },
};

export const getActiveConnection = (chainId: number) => {
  const legacyWalletSeed = getStorageItem<string>(
    getParaclearWalletSeedStorageKey(chainId),
  );
  const legacyAddress = getStorageItem<string>(
    STORAGE_KEY_ETHEREUM_WALLET_ADDRESS,
  );
  return {
    address: ethereumWalletsStore.getActiveWallet() ?? legacyAddress,
    walletSeed: walletSeedsStore.getActiveSeed(chainId) ?? legacyWalletSeed,
  };
};

export const removeConnection = (chainId: number) => {
  setStorageItem(STORAGE_KEY_ETHEREUM_WALLET_ADDRESS, null);
  setStorageItem(getParaclearWalletSeedStorageKey(chainId), null);

  const currentWalletAddress = ethereumWalletsStore.getActiveWallet();
  if (currentWalletAddress == null) return;
  ethereumWalletsStore.removeOne(currentWalletAddress);
  walletSeedsStore.removeOne(chainId, currentWalletAddress);
};

export const removeAllConnections = (chainId: number) => {
  removeStorageItem(STORAGE_KEY_ETHEREUM_WALLET_ADDRESS);
  removeStorageItem(getParaclearWalletSeedStorageKey(chainId));

  ethereumWalletsStore.remove();
  walletSeedsStore.remove(chainId);
};

export const saveConnection = (
  chainId: number,
  address: string,
  walletSeed: string,
) => {
  ethereumWalletsStore.setOne(address);
  walletSeedsStore.setOne(chainId, address, walletSeed);

  setStorageItem(getParaclearWalletSeedStorageKey(chainId), walletSeed);
  setStorageItem(STORAGE_KEY_ETHEREUM_WALLET_ADDRESS, address);
};

export const getConnection = (walletAddress: string, chainId: number) => {
  const address = ethereumWalletsStore.getOne(walletAddress);
  const walletSeed = walletSeedsStore.getOne(chainId, walletAddress);
  if (address == null || walletSeed == null) {
    return null;
  }

  return { address, walletSeed };
};
