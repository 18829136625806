import { useCallback } from 'react';

import { Resp, useApi } from '#/api/fetch-api';
import { cancelOrder, CancelOrderResp, OpenOrder } from '#/api/orders';

export type CancelOrderRespHandler = (resp: Resp<CancelOrderResp>) => void;

export function useCancelOpenOrder(respHandler?: CancelOrderRespHandler) {
  const [{ isLoading }, exec] = useApi(cancelOrder);

  const execCancelOrder = useCallback(
    (id: OpenOrder['id']) => {
      exec({ id }, respHandler);
    },
    [exec, respHandler],
  );

  return { exec: execCancelOrder, isLoading };
}
