import { useCallback } from 'react';

import { updateAccountProfileUsername } from '#/api/account';

import { logEvent, logException } from '#/features/logging/logging';

/**
 * Sets the username privacy to private, assuming username
 * is already empty. This indicates that the user has dismissed the
 * username prompt and doesn't want to be reminded again.
 */
export function useDismissUsernamePrompt() {
  const dismiss = useCallback(() => {
    (async () => {
      logEvent('Username prompt dismiss request');

      const resp = await updateAccountProfileUsername({
        username: '',
        is_username_private: true,
      });

      if (resp.ok) {
        logEvent('Username prompt dismiss success');
        return;
      }

      const cause = resp.error;
      const message = 'Error updating username privacy upon prompt dismissal';
      const error = new Error(message, { cause });
      logException(error);
      logEvent('Username prompt dismiss error');
    })().catch((cause: unknown) => {
      const message =
        'Unexpected error updating username privacy upon prompt dismissal';
      const error = new Error(message, { cause });
      logException(error);
      logEvent('Username prompt dismiss error');
    });
  }, []);

  return dismiss;
}
