import type { FavoritesBarState } from './state';

interface Store {
  readonly mode: FavoritesBarState['mode'];
}
export type { Store as FavoritesBarStore };

export function createStore(state: FavoritesBarState): Store {
  return {
    mode: state.mode,
  };
}
