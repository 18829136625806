import BigNumber from 'bignumber.js';

import { Nullable } from '#/utils/types';

/**
 * Matches the digits that are followed by 3 digit groups. In
 * other words, matches the digits which may include a thousand
 * separator afterwards.
 */
const GROUP_END_RE = /(\d)(?=(?:\d{3})+(?!\d))/gu;

const LEFT_ZERO_RE = /^0+(?!$)/u;

const DEC_SEP = '.';

// Default value used for max TVL during vault creation
const MAX_INT64 = new BigNumber('9223372036854');

/**
 * Format for quote prices.
 *   - Remove left zeros
 *   - Truncate the precision to a given number of decimal digits
 *
 * @param precision number of digits after decimal separator to keep
 * @param value string representing a decimal
 */
export function formatNumber(precision: number, value: string) {
  if (!Number.isInteger(precision))
    throw new TypeError(`Precision must be an integer, received ${precision}`);
  if (precision < 0)
    throw new TypeError(`Precision must be postive, received ${precision}`);

  const [int, dec] = value.split(DEC_SEP);

  const intGrouped = int!.replace(GROUP_END_RE, '$1,');
  const intCleaned = intGrouped.replace(LEFT_ZERO_RE, '');

  if (dec == null) return intCleaned;
  if (dec.length === 0) return `${intCleaned}${DEC_SEP}`;

  const decTrucated = dec.slice(0, precision);
  return `${intCleaned}${DEC_SEP}${decTrucated}`;
}

export function nullableBigNumber(
  value: number | undefined | null | string,
): Nullable<BigNumber> {
  return value == null ? null : new BigNumber(value);
}

/**
 * Constrains a value to be within a specified range.
 * @param value The value to clamp
 * @param min The minimum allowed value
 * @param max The maximum allowed value
 * @returns A value guaranteed to be within [min, max]
 */
export function clamp(
  value: number | BigNumber,
  {
    min,
    max,
  }: { readonly min: number | BigNumber; readonly max: number | BigNumber },
): BigNumber {
  return BigNumber.max(min, BigNumber.min(max, value));
}

export function formatCompact(
  value: number | string | BigNumber,
  options?: Intl.NumberFormatOptions,
): string {
  const valueNumber = BigNumber.isBigNumber(value)
    ? value.toNumber()
    : Number(value);

  const compactUsdFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    currency: 'USD',
    notation: 'compact',
    compactDisplay: 'short',
    ...options,
  });
  return compactUsdFormatter.format(valueNumber);
}

/**
 * Checks if a BigNumber value equals MAX_INT64, which represents
 * an unlimited TVL cap for vaults.
 * @param value The BigNumber to check
 * @returns true if the value equals MAX_INT64
 */
export function isMaxInt64(value: BigNumber): boolean {
  if (!BigNumber.isBigNumber(value)) return false;
  return value.isEqualTo(MAX_INT64);
}
