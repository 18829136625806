import { useEffect } from 'react';

import { logException } from '#/features/logging/logging';
import { KEY_PREFIX } from '#/features/storage/storage';

import { Maybe } from '#/utils/types';

interface Args {
  ignorePattern: RegExp;
  cleanupPattern: RegExp;
}

export default function useLocalStorageCleanup(args: Args): void {
  useEffect(() => {
    (async function executeCleanup() {
      const keysAll = Object.keys(window.localStorage);
      const keysParadex = keysAll.filter((key) => key.startsWith(KEY_PREFIX));
      const keysExcludingIgnored = keysParadex.filter(
        (key) => !args.ignorePattern.test(key),
      );
      const keysToCleanup = keysExcludingIgnored.filter((key) =>
        args.cleanupPattern.test(key),
      );

      keysToCleanup.forEach((keyToCleanup) => {
        try {
          window.localStorage.removeItem(keyToCleanup);
        } catch (error) {
          const message = `Failed to remove localStorage entry by key='${keyToCleanup}'`;
          logException(new Error(message, { cause: error }));
        }
      });
    })().catch((error: Maybe<Error>) => {
      const message = `Failed to execute localStorage cleanup for cleanupPattern=${args.cleanupPattern.toString()}`;
      logException(new Error(message, { cause: error }));
    });
    // Attempt to remove matching localStorage entries only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
