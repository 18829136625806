import { processTrade } from '#/api/trades';

import getQueryString from '#/utils/getQueryString';

import { AsyncResp, BaseReq, requestApi } from './fetch-api';

import type { RawTrade, Trade, TradeSide } from '#/api/trades';

interface RawVaultTrade extends RawTrade {}
interface VaultTrade extends Trade {}
type VaultTradeSide = TradeSide;

export type { RawVaultTrade, VaultTrade, VaultTradeSide };

interface VaultTradesReq extends BaseReq {
  readonly address: string;
}

export interface RawVaultTradesResp {
  readonly results: readonly RawTrade[];
}

export interface VaultTradesResp {
  readonly results: readonly VaultTrade[];
}

export async function getVaultTrades(
  req: VaultTradesReq,
): AsyncResp<VaultTradesResp> {
  const { signal } = req;

  const queryString = getQueryString([['address', req.address]]);

  const resp = await requestApi<RawVaultTradesResp>({
    signal,
    method: 'GET',
    url: `/vaults/trades${queryString}`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: {
      results: resp.data.results.map(processVaultTrade),
    },
  };
}

function processVaultTrade(trade: RawVaultTrade): VaultTrade {
  return processTrade(trade) as VaultTrade;
}
