import { AsyncResp, BaseReq, requestApi } from '#/api/fetch-api';

export interface RawVaultsConfig {
  readonly vault_factory_address: string;
  readonly max_profit_share_percentage: string;
  readonly min_lockup_period_days: string;
  readonly max_lockup_period_days: string;
  readonly min_initial_deposit: string;
  readonly min_owner_share_percentage: string;
}

export interface VaultsConfig
  extends Omit<
    RawVaultsConfig,
    | 'max_profit_share_percentage'
    | 'min_lockup_period_days'
    | 'max_lockup_period_days'
    | 'min_initial_deposit'
    | 'min_owner_share_percentage'
  > {
  readonly max_profit_share_percentage: number;
  readonly min_lockup_period_days: number;
  readonly max_lockup_period_days: number;
  readonly min_initial_deposit: number;
  readonly min_owner_share_percentage: number;
}

interface GetVaultsConfigReq extends BaseReq {}

export async function getVaultsConfig(
  req: GetVaultsConfigReq,
): AsyncResp<VaultsConfig> {
  const { signal } = req;

  const resp = await requestApi<RawVaultsConfig>({
    signal,
    method: 'GET',
    url: '/vaults/config',
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: processVaultsConfig(resp.data),
  };
}

function processVaultsConfig(config: RawVaultsConfig): VaultsConfig {
  return {
    ...config,
    max_profit_share_percentage: Number(config.max_profit_share_percentage),
    max_lockup_period_days: Number(config.max_lockup_period_days),
    // Until API returns a value:
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    min_lockup_period_days: Number(config.min_lockup_period_days ?? 1),
    min_initial_deposit: Number(config.min_initial_deposit),
    min_owner_share_percentage: Number(config.min_owner_share_percentage),
  };
}
