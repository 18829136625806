import { PERIODS } from '#/features/vaults/period/config';

import type { TFunction } from '#/features/localization/utils';
import type { Period } from '#/features/vaults/period/config';

export function periodLabelView(
  period: Period,
  utils: { readonly t: TFunction },
): string {
  return utils.t(PERIODS[period].label);
}
