import BigNumber from 'bignumber.js';

import { msToDate } from '#/utils/date';

import { AsyncResp, BaseReq, requestApi } from './fetch-api';
import { Market } from './markets';
import { UnixTimeMs } from './types';

export interface RawBbo {
  readonly ask: string;
  readonly ask_size: string;
  readonly bid: string;
  readonly bid_size: string;
  readonly last_updated_at: UnixTimeMs;
  readonly market: string;
}

export interface Bbo
  extends Omit<
    RawBbo,
    'ask' | 'ask_size' | 'bid' | 'bid_size' | 'last_updated_at'
  > {
  readonly ask: BigNumber;
  readonly ask_size: BigNumber;
  readonly bid: BigNumber;
  readonly bid_size: BigNumber;
  readonly last_updated_at: Date;
}

interface BboReq extends BaseReq {
  readonly market: Market['symbol'];
}

export interface RawBboResp extends RawBbo {}

export interface BboResp extends Bbo {}

export async function getBbo(req: BboReq): AsyncResp<BboResp> {
  const { signal, market } = req;

  const resp = await requestApi<RawBboResp>({
    signal,
    method: 'GET',
    url: `/bbo/${market}`,
  });

  if (!resp.ok) {
    return resp;
  }

  return {
    ...resp,
    data: processBbo(resp.data),
  };
}

export function processBbo(bbo: RawBbo): Bbo {
  return {
    ...bbo,
    ask: new BigNumber(bbo.ask),
    ask_size: new BigNumber(bbo.ask_size),
    bid: new BigNumber(bbo.bid),
    bid_size: new BigNumber(bbo.bid_size),
    last_updated_at: msToDate(bbo.last_updated_at),
  };
}
