import{useEffect,useRef}from'react';import{useActiveAccountView}from"../../account/account-context";import{useWalletView}from"../../wallet/wallet-context";import*as OrderBuilderFn from"./functions";/**
 * Provides an opportunity to run leverage initialization
 * when the given market changes and dependencies are ready.
 * Runs at most once for every market change.
 *
 * Dependencies are:
 *  - Market is available
 *  - Market summary is available
 *  - User is signed in
 *  - User's account is available
 *  - Limit price can be estimated for leverage calculation
 */export default function useLeverageInitializationWindow(formState,market,marketSummary,callback){const activeAccountView=useActiveAccountView();const walletView=useWalletView();const wasCalledOnceForMarket=useRef(false);useEffect(()=>{wasCalledOnceForMarket.current=false;},[market?.symbol]);useEffect(()=>{if(wasCalledOnceForMarket.current)return;if(market==null)return;if(marketSummary==null)return;if(!walletView.isSignedIn)return;if(activeAccountView.account==null)return;const leverageDisabled=OrderBuilderFn.isLeverageDisabled(formState);if(leverageDisabled)return;const limitPrice=OrderBuilderFn.estimateLimitPriceForLeverageCalculation(formState,market,marketSummary);if(limitPrice==null){return;}wasCalledOnceForMarket.current=true;callback();},[activeAccountView.account,formState,walletView.isSignedIn,market,marketSummary,callback]);}