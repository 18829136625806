import { useCallback, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';

import { logEvent, logException } from '#/features/logging/logging';
import { flatErrorMessage } from '#/features/logging/utils';
import { useSystemConfig } from '#/features/system/system-config-context';
import { prepareErrorMessage } from '#/features/wallet/ethereum';
import { useWalletConnectionConfig } from '#/features/wallet/wallet-connection-context';
import { useWalletState } from '#/features/wallet/wallet-context';
import * as EthereumService from '#/features/withdraw/services/ethereum-service';

import { AsyncResult } from '#/utils/types';

type UseCollectFunds = [
  (amount: BigNumber, asset: string) => AsyncResult<void>,
  { readonly isBusy: boolean },
];

export default function useCollectFunds(): UseCollectFunds {
  const [walletState] = useWalletState();
  const [isBusy, setBusy] = useState(false);

  const config = useSystemConfig();
  const { wagmiConfig } = useWalletConnectionConfig();

  const collectFunds = useCallback(
    async function collectFunds(
      amount: BigNumber,
      asset: string,
    ): AsyncResult<void> {
      if (walletState.baseAccount == null) {
        throw new Error('collectFunds called before address is available');
      }

      if (walletState.baseAccount.type !== 'ethereum') {
        throw new Error('collectFunds called for non Ethereum address');
      }

      const token = config.getToken(asset);

      setBusy(true);

      try {
        await EthereumService.requestWithdraw(
          amount,
          walletState.baseAccount.address,
          token,
          wagmiConfig,
        );
      } catch (err) {
        setBusy(false);
        const description = flatErrorMessage('Failed to collect funds', err);
        const { message, isException } = prepareErrorMessage(description, err);
        if (isException) {
          const error = new Error(description, { cause: err });
          logException(error);
          return { ok: false, error, reason: message };
        }
        logEvent(message);
        return { ok: false, error: null, reason: message };
      }

      // Keep busy until the component is taken
      // off the screen by a parent component.
      return { ok: true, data: undefined };
    },
    [config, wagmiConfig, walletState.baseAccount],
  );

  return useMemo(() => [collectFunds, { isBusy }], [collectFunds, isBusy]);
}
