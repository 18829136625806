/* eslint-disable no-else-return */
import BigNumber from 'bignumber.js';

import { OrderSide } from '#/api/orders';

import { ActiveAccountView } from '#/features/account/account-context';
import * as OrderFn from '#/features/order/functions';
import { MarketsStoreView } from '#/features/perpetuals/market-info/markets-context';
import { MarketsSummaryStoreView } from '#/features/perpetuals/market-info/markets-summary-context';
import {
  OpenPositionsStore,
  positionId,
} from '#/features/perpetuals/positions/open-positions-store-context';
import { OpenOrdersStoreView } from '#/features/perpetuals/trade-overview/open-orders-context';

import { accountImfWithCap } from '#/utils/margin';

import { calc_margin_excluding_asset } from './calculate_margin_requirement';
import { calculate_open_loss } from './calculate_open_loss';
import total_order_size from './total_order_size';

/**
 * Adapted from
 * @see https://github.com/tradeparadigm/mono/blob/release/paradex-1.23.3/api/paradex/prototype/account/account.py#L1172
 */
export default function get_order_size_from_free_collateral_usage(
  target_asset: string, // SyntheticAsset, // e.g. 'SOL-USD-PERP'
  order_side: OrderSide,
  limit_price: BigNumber,
  target_usage: BigNumber, // our leverage %
  openPositionsStore: OpenPositionsStore,
  openOrdersStore: OpenOrdersStoreView,
  marketsSummaryStore: MarketsSummaryStoreView,
  marketsStore: MarketsStoreView,
  activeAccountView: ActiveAccountView,
  profileMaxSlippage: BigNumber | null, // current market max slippage
  accountImfBase: BigNumber | null,
): BigNumber {
  const targetAssetMarket = marketsStore.getMarket(target_asset);
  const targetAssetMarketSummary =
    marketsSummaryStore.getMarketSummary(target_asset);

  if (targetAssetMarket == null) {
    throw new Error(
      `Market is not initialized for target_asset=${target_asset}`,
    );
  }
  if (targetAssetMarketSummary == null) {
    throw new Error(
      `Market summary is not initialized for target_asset=${target_asset}`,
    );
  }
  if (targetAssetMarketSummary.mark_price == null) {
    throw new Error(
      `'mark_price' is not initialized for ${target_asset} market summary`,
    );
  }
  if (activeAccountView.account == null) {
    throw new Error('Account is not initialized');
  }
  if (targetAssetMarket.delta1_cross_margin_params == null) {
    throw new Error(
      `this asset is not using delta1 cross margin params: ${target_asset}`,
    );
  }

  const position = openPositionsStore.get(
    positionId(target_asset, activeAccountView.account.account),
  );

  const target_balance = {
    amount: position?.size ?? BigNumber(0),
  };

  const initial_margin_excluding_asset = calc_margin_excluding_asset(
    'Initial',
    target_asset,
    marketsSummaryStore,
    marketsStore,
    openOrdersStore,
    openPositionsStore,
    activeAccountView.account.account,
    profileMaxSlippage,
    accountImfBase,
  );

  const { account_value } = activeAccountView.account;
  const current_free_collateral = activeAccountView.account.free_collateral;
  const target_free_collateral = BigNumber(1)
    .minus(target_usage)
    .multipliedBy(current_free_collateral);
  const asset_target_margin = account_value
    .minus(initial_margin_excluding_asset)
    .minus(target_free_collateral);
  const same_side_position = BigNumber.max(
    BigNumber(0),
    BigNumber(order_side === 'BUY' ? 1 : -1).times(target_balance.amount),
  );
  const opposite_side_position = BigNumber.max(
    BigNumber(0),
    BigNumber(order_side === 'BUY' ? -1 : 1).times(target_balance.amount),
  );
  const same_side_order_size = total_order_size(
    target_asset,
    activeAccountView.account.account,
    order_side,
    openOrdersStore,
  );
  const opposite_side_order_size = total_order_size(
    target_asset,
    activeAccountView.account.account,
    OrderFn.oppositeSide(order_side),
    openOrdersStore,
  );

  //
  // # calculate net IMR for both sides
  //
  const opposite_side_open_size = opposite_side_order_size
    .plus(opposite_side_position)
    .minus(same_side_position);
  const same_side_open_size = same_side_order_size
    .plus(same_side_position)
    .minus(opposite_side_position);
  const fee_rate = BigNumber.max(0.0003, -0.00005);
  const { mark_price } = targetAssetMarketSummary;

  //
  // # order open loss (per unit of contract)
  //
  const order_unit_open_loss = BigNumber.max(
    BigNumber(order_side === 'BUY' ? 1 : -1).times(
      limit_price.minus(mark_price),
    ),
    BigNumber(0),
  );

  //
  // # cross margin parameters for target_asset
  //
  const margin_params = targetAssetMarket.delta1_cross_margin_params;
  const { imf_base } = margin_params;
  let max_order_size = null;
  let unit_provision = null;
  if (opposite_side_open_size > same_side_open_size) {
    //
    // # order size to match the open size from the opposite side
    //
    max_order_size = opposite_side_open_size
      .minus(same_side_order_size)
      .minus(same_side_position)
      .plus(opposite_side_position);
    unit_provision = fee_rate.times(mark_price).plus(order_unit_open_loss);
    if (
      unit_provision
        .times(max_order_size)
        .isGreaterThanOrEqualTo(target_usage.times(current_free_collateral))
    ) {
      const target_size = target_usage
        .times(current_free_collateral)
        .dividedBy(unit_provision);
      return target_size;
    }
  }

  //
  // # open loss of existing open orders
  //
  const current_open_loss = calculate_open_loss(
    target_asset,
    activeAccountView.account.account,
    position,
    openOrdersStore,
    marketsSummaryStore,
    marketsStore,
    profileMaxSlippage,
  );

  const adjusted_target_margin = asset_target_margin
    .plus(
      same_side_position
        .minus(opposite_side_position)
        .plus(same_side_order_size)
        .multipliedBy(order_unit_open_loss),
    )
    .minus(current_open_loss)
    .minus(
      fee_rate
        .multipliedBy(
          opposite_side_order_size.plus(opposite_side_position.multipliedBy(2)),
        )
        .multipliedBy(mark_price),
    );
  const IMF = accountImfWithCap(imf_base, accountImfBase);
  const target_open_size = adjusted_target_margin.dividedBy(
    mark_price.multipliedBy(IMF.plus(fee_rate)).plus(order_unit_open_loss),
  );

  const target_size = target_open_size
    .minus(same_side_position)
    .plus(opposite_side_position)
    .minus(same_side_order_size);

  if (target_size.isNegative()) {
    throw new Error(
      `get_order_size_from_free_collateral_usage() calculation resulted in negative 'target_size'=${target_size.toString()} for target_asset=${target_asset}`,
    );
  }

  return target_size;
}
