import { Keys } from "./en";

const translations: Record<Keys, string> = {
  "Display trade history on chart": "Отображать историю торгов на графике",
  "Add markets to Favorites in the Markets Browser":
    "Додайте ринки до Обраного в Оглядачі ринків",
  "Log in": "Увійти",
  "Export embedded wallet": "Експортувати вбудований гаманець",
  "Email or Social": "Електронна пошта або соціальні мережі",
  "Failed to load balances": "Не вдалося завантажити баланси",
  "No balances": "Немає балансів",
  "Verified Operator": "Перевірений оператор",
  "Strategy": "Стратегія",
  "Multi-Strategy": "Мульти-стратегія",
  "Master Vault": "Головне сховище",
  "Master Vault Details": "Деталі головного сховища",
  "Annualized": "Річний",
  "Depositors": "Вкладники",
  "No trade history": "Немає історії торгів",
  "Unwinding Progress": "Прогрес розгортання",
  "What are Vaults?": "Що таке сховища?",
  "APR calculated as the Annualised 30-day Return":
    "APR розраховується як річна 30-денна прибутковість",
  "APR": "APR",
  "Initializing": "Ініціалізація",
  "Awaiting Deposit": "Очікування депозиту",
  "Vault Age": "Вік сховища",
  "30D Return": "30-денна прибутковість",
  "{{field}} must be greater than the minimum initial deposit of {{minInitialDeposit}}":
    "{{field}} має бути більшим за мінімальний початковий депозит {{minInitialDeposit}}",
  "Minimum deposit": "Мінімальний депозит",
  "Withdrawal amount exceeds available vault shares":
    "Сума виведення перевищує доступні частки сховища",
  "Deposit would exceed maximum vault TVL of {{maxTVL}}":
    "Депозит перевищить максимальний TVL сховища {{maxTVL}}",
  "{{field}} is required": "{{field}} є обов'язковим",
  "Lockup Remaining": "Залишок блокування",
  "{{days}}D": "{{days}}Д",
  "<1>{{days}}</1> D": "<1>{{days}}</1> Д",
  "<1>{{hours}}</1> H": "<1>{{hours}}</1> Г",
  "<1>{{minutes}}</1> M": "<1>{{minutes}}</1> Х",
  "Vault settings submitted": "Налаштування сховища надіслано",
  "Vault settings updated": "Налаштування сховища оновлено",
  "Vault Settings": "Налаштування сховища",
  "Vault not found": "Сховище не знайдено",
  "The owner receives a {{profitShare}} profit share.":
    "Власник отримує {{profitShare}} частку прибутку.",
  "This vault is entirely owned by the community.":
    "Це сховище повністю належить спільноті.",
  "Withdraw from Vault submitted": "Виведення зі сховища надіслано",
  "Deposit to Vault submitted": "Депозит до сховища надіслано",
  "Deposit to Vault successful": "Депозит до сховища успішний",
  "Unknown error. Try again.": "Невідома помилка. Спробуйте ще раз.",
  "Vault Deposit": "Депозит сховища",
  "Vault Withdrawal": "Виведення зі сховища",
  "No Lockup": "Без блокування",
  "Lockup period is not over yet": "Період блокування ще не закінчився",
  "Protocol": "Протокол",
  "24H": "24Г",
  "7D": "7Д",
  "30D": "30Д",
  "All": "Усі",
  "You have no investments in vaults": "У вас немає інвестицій у сховища",
  "Total Value": "Загальна вартість",
  "Investments Made": "Інвестиції зроблені",
  "Operator": "Оператор",
  "Manage Vault": "Керуйте сховищем",
  "Switch to the operator account":
    "Переключіться на операторський обліковий запис",
  "Active": "Активний",
  "My Vaults": "Мої сховища",
  "Closed": "Закритий",
  "Deposited": "Депоновано",
  "Lifetime ROI": "Життєвий ROI",
  "Owners Equity": "Рівень власника",
  "Top Trending": "Топ трендуючий",
  "Owner Share": "Частка власника",
  "Vault Details": "Деталі сховища",
  "Contract": "Контракт",
  "Withdraw from Vault": "Виведення зі сховища",
  "Withdraw from Vault successful": "Виведення зі сховища успішне",
  "Failed to withdraw from Vault": "Не вдалося вивести зі сховища",
  "Name": "Ім'я",
  "Deposit": "Депозит",
  "Tokens": "Токени",
  "Avg. Entry Price": "Середня ціна входу",
  "Current Price": "Поточна ціна",
  "P&L": "P&L",
  "My Vault Deposit": "Депозит мого сховища",
  "Vaults": "Сховища",
  "Create": "Створіть",
  "Create My Vault": "Створіть моє сховище",
  "Vault Name": "Ім'я сховища",
  "Vault Description": "Опис сховища",
  "Token Symbol": "Символ токену",
  "Deposit Amount": "Сума депозиту",
  "Vault description will be visible publicly and you will not be able to edit this later.":
    "Опис сховища буде доступний публічно, і ви не зможете його редагувати пізніше.",
  "Once created, deposit at least {{minDeposit}} USDC to activate the vault. At this moment, only the Max TVL can be changed later.":
    "Після створення депозиту принаймні {{minDeposit}} USDC, щоб активувати сховище. На цей момент можна змінити лише Max TVL.",
  "Create Vault": "Створіть сховище",
  "Profit Share": "Частка прибутку",
  "Lockup Period (Days)": "Період блокування (дні)",
  "Vault was successfully created and is being initialized":
    "Сховище успішно створено і ініціалізується",
  "Failed to create a vault": "Не вдалося створити сховище",
  "Maximum TVL": "Максимальний TVL",
  "No limit": "Без обмеження",
  "Vault Address": "Адреса сховища",
  "Failed to deposit to Vault": "Не вдалося депонувати до сховища",
  "Deposit to Vault": "Депозит до сховища",
  "Vault": "Сховище",
  "Owner": "Власник",
  "Age": "Вік",
  "Failed to load vaults": "Не вдалося завантажити сховища",
  "No vaults available": "Немає доступних сховищ",
  "Performance": "Продуктивність",
  "Vault Value": "Вартість сховища",
  "My Deposit": "Мой депозит",
  "Total P&L": "Загальний P&L",
  "Protocol Vaults": "Сховища протоколу",
  "User Vaults": "Сховища користувача",
  "No Data": "Немає даних",
  "Last Month Return": "Повернення за останній місяць",
  "TVL": "TVL",
  "Total Earnings": "Загальний дохід",
  "About": "Про",
  "Vault Stats": "Статистика сховища",
  "My Stats": "Моя статистика",
  "Vault Contract": "Контракт сховища",
  "Description": "Опис",
  "Max Drawdown": "Максимальне зниження",
  "Volume": "Обсяг",
  "All-Time P&L": "P&L за всю історію",
  "Unrealized P&L": "Нереалізований P&L",
  "Days": "Дні",
  "Account Value": "Вартість рахунку",
  "Lockup Period": "Період блокування",
  "Search Vaults": "Пошук сховищ",
  "Blog": "Блог",
  "Block Explorer": "Оглядач блоків",
  "Close Vault": "Закрити сховище",
  "Close Vault successful": "Сховище успішно закрито",
  "You must close all open positions to close your Vault. Closed vaults cannot be reopened.":
    "Ви повинні закрити всі відкриті позиції, щоб закрити ваше сховище. Закриті сховища не можна відкрити знову.",
  "Close My Vault": "Закрити моє сховище",
  "Failed to Close the Vault": "Не вдалося закрити сховище",
  "Only the Main Account can manage the vault":
    "Тільки головний рахунок може керувати сховищем",
  "Allocate Collateral": "Назначити забезпечення",
  "Switch to Main Account": "Переключитися на головний рахунок",
  "Switch to Account": "Переключитися на рахунок",
  "You are currently trading in the {{market}} market.":
    "Ви в даний час торгуєте на ринку {{market}}.",
  "To start trading on Isolated Market you must Allocate Collateral from your Main Account and switch to this Market Account.":
    "Щоб почати торгівлю на Ізольованому ринку, ви повинні надати забезпечення з вашого головного рахунку і переключитися на цей ринковий рахунок.",
  "To trade in this market, switch to the Main Account first.":
    "Щоб торгувати на цьому ринку, спочатку переключіться на головний рахунок.",
  "This account can only trade in the {{market}} market.":
    "Цей рахунок може торгувати лише на ринку {{market}}.",
  "Isolated Market": "Ізольований ринок",
  "This feature currently is still in Beta. Backup your Paradex Private Key for additional safety.":
    "Ця функція ще не завершена. Зберить ваш приватний ключ Paradex для додаткової безпеки.",
  "Deposit failed": "Депозит не вдався",
  "Deposit in progress": "Депозит у процесі",
  "External Account": "Зовнішній рахунок",
  "Tx Hashes": "Хеші транзакцій",
  "Continue to {{bridge}}": "Продовжити до {{bridge}}",
  "By clicking Continue to {{bridge}}, you will be sent to {{bridge}} website to bridge your funds out of Paradex.":
    "Натиснувши Продовжити до {{bridge}}, вас переадресують на веб-сайт {{bridge}}, щоб перевести ваші кошти з Paradex.",
  "By clicking Continue to {{bridge}}, you will be sent to {{bridge}} website to bridge your funds to Paradex.":
    "Натиснувши Продовжити до {{bridge}}, вас переадресують на веб-сайт {{bridge}}, щоб перевести ваші кошти на Paradex.",
  "Refer": "Запросити",
  "Address": "Адреса",
  "Date Joined": "Дата приєднання",
  "Referral Code": "Код запрошення",
  "Volume Traded": "Обсяг торгів",
  "Bridge": "Мостик",
  "Withdraw to": "Вивести на",
  "Deposit from": "Депозит з",
  "Position is already closed": "Позиція вже закрита",
  "Your Paradex L2 Address is NOT a Public Starknet Address. If you try to send funds to this address on Public Starknet via a cross chain bridge you risk losing your funds. <1>Read more</1>.":
    "Ваш адрес L2 Paradex не є публічним адресою Starknet. Якщо ви спробуєте передати кошти на цей адрес на Public Starknet через крос-ланцевий мостик, ризикуєте втратити кошти. <1>Читати більше</1>.",
  "{{chain}} Address": "Адреса {{chain}}",
  "Copy Paradex Private Key": "Скопіювати приватний ключ Paradex",
  "Your account will be liquidated if Margin Ratio reaches 100%":
    "Ваш рахунок буде ліквідований, якщо коефіцієнт маржі досягне 100%",
  "Liquidation": "Ліквідація",
  "Fee": "Комісія",
  "Maker": "Виробник",
  "Maker Score": "Оцінка виробника",
  "Maker XP Share": "Частка XP виробника",
  "Fee XP Share": "Частка XP комісії",
  "Fee XP": "XP комісії",
  "Maker XP": "XP виробника",
  "Liquidation {{side}} Fill": "Ліквідація {{side}} заповнення",
  "Settings": "Налаштування",
  "Market Score": "Оцінка ринку",
  "Season {{season}} (coming soon)": "Сезон {{season}} (завтра)",
  "Confirm Buy": "Підтвердити покупку",
  "Confirm Sell": "Підтвердити продаж",
  "Other XP": "Інший XP",
  "Analytics": "Аналітика",
  "Estimated Receivable Amount": "Оцінене отримане значення",
  "Socialized Loss active. Click to learn more.":
    "Соціалізований збиток активний. Натисніть, щоб дізнатися більше.",
  "Sign In to see your account XP stats":
    "Увійдіть, щоб побачити статистику XP вашого рахунку",
  "<1>Clock Is Out Of Sync</1> The clock on your device is out of sync. This may cause issues with the application. Please make sure your device clock is set to be auto. For more details please <2>see documentation</2>":
    "<1>Годинник не синхронізований</1> Час на вашому пристрої не синхронізований. Це може призвести до проблем з додатком. Будь ласка, переконайтеся, що час на вашому пристрої налаштований на автоматичне.",
  "Failed to fetch max withdrawable amount":
    "Не вдалося отримати максимальну суму виведення",
  "It is not possible to withdraw more than your Max Withdrawable Amount.":
    "Неможливо вивести більше, ніж ваша максимальна виводима сума.",
  "Max Withdrawable Amount": "Максимальна виводима сума",
  "XP Explainer": "Пояснення XP",
  "Pool": "Пул",
  "Quote Quality": "Якість котирування",
  "Create Your Public Username": "Створіть публічне ім'я користувача",
  "Edit Your Public Username": "Редагувати публічне ім'я користувача",
  "You have been logged out.": "Ви вийшли з системи.",
  "Maker Volume Score": "Оцінка обсягу виробника",
  "Fee Score": "Оцінка комісії",
  "Score Share": "Частка оцінки",
  "Instrument Share": "Частка інструменту",
  "XP": "XP",
  "Total XP": "Загальний XP",
  "24h Change": "Зміна за 24 години",
  "Leaderboard": "Таблиця лідерів",
  "Cancel": "Скасувати",
  "Portfolio": "Портфель",
  "Trade": "Торгівля",
  "Build": "Створювати",
  "Referrals Explainer": "Пояснення запрошень",
  "How It Works": "Як це працює",
  "My Referrals": "Мої запрошення",
  "Your Referral Code": "Код запрошення",
  "Affiliate Program": "Програма афіліата",
  "Enrolled": "Зареєстрований",
  "Traders Referred": "Торговці запросили",
  "Referral Rewards": "Винагороди запрошень",
  "Referral XP": "XP запрошень",
  "Invite Friends": "Запросити друзів",
  "Share": "Поділитися",
  "Earn": "Заробляти",
  "Give": "Дати",
  "Share your code to invite friends to join Paradex":
    "Поділіть код, щоб запросити друзів приєднатися до Paradex",
  "<1>{{amount}}</1> Fees": "<1>{{amount}}</1> Комісія",
  "You receive {{amount}} of the referred user's fees":
    "Ви отримаєте {{amount}} комісії користувача, якого запросили",
  "<1>{{amount}}</1> XP": "<1>{{amount}}</1> XP",
  "You receive {{amount}} of the referred user's XP":
    "Ви отримаєте {{amount}} XP користувача, якого запросили",
  "<1>{{amount}}</1> Discount": "<1>{{amount}}</1> Знижка",
  "Your friend receives {{amount}} discount on fees":
    "Ваш друг отримає {{amount}} знижку на комісію",
  "Create and share your custom referral code":
    "Створіть і поділіть код запрошення",
  "If you have a strong social media following (<1>{{followers}}+</1>), you may be eligible for our <2>VIP Affiliate Program</2> with higher commissions and XP.":
    "Якщо у вас сильна соціальна медіа-аудиторія (<1>{{followers}}+</1>), ви можете бути придатними для нашої програми афіліатів VIP з більшими комісіями і XP.",
  "You can track our <1>Affiliate Leaderboard</1> to see how you stack up.":
    "Ви можете побачити наш публічний <1>Таблицю лідерів афіліатів</1>, щоб побачити, як ви стоїте.",
  "Apply": "Застосувати",
  "Log in to see your Referral History.":
    "Увійдіть, щоб побачити історію запрошень.",
  "Welcome to Paradex": "Ласкаво просимо до Paradex",
  "Congrats! The referral code you used is now active! It means you can start enjoying a discount on your trading fees!":
    "Вітаємо! Код запрошення, який ви використали, тепер активний! Це означає, що ви можете почати користуватися знижкою на комісію з вашої торгівлі.",
  "Congrats! The referral code <1>{{codeProvided}}</1> used is now active!":
    "Вітаємо! Код запрошення <1>{{codeProvided}}</1> тепер активний!",
  "Referral code not activated. The code can only be applied once during the initial onboarding.":
    "Код запрошення не активований. Код можна застосувати лише один раз під час початкового відкриття.",
  "Referral code <1>{{codeProvided}}</1> not activated. You have already onboarded with <1>{{referredBy}}</1>.":
    "Код запрошення <1>{{codeProvided}}</1> не активований. Ви вже зареєстровані з <1>{{referredBy}}</1>.",
  "Referral Code Applied": "Застосований код запрошення",
  "You will receive <1>{{userAmount}}</1> of referred user's <1>fees</1> <2>and</2> <1>{{userAmountPoints}} XP</1>. They will receive a <1>{{friendAmount}}</1> discount.":
    "Ви отримаєте <1>{{userAmount}}</1> комісії користувача, якого запросили <1>fees</1> <2>і</2> <1>{{userAmountPoints}} XP</1>. Вони отримають <1>{{friendAmount}}</1> знижку.",
  "Community": "Спільнота",
  "Environments": "Середовища",
  "Language": "Мова",
  "System Status": "Статус системи",
  "Docs": "Документація",
  "Code Samples": "Код прикладів",
  "More": "Більше",
  "Stats": "Статистика",
  "Join Discord": "Приєднатися до Discord",
  "Notifications": "Повідомлення",
  "All caught up!": "Усі повідомлення прочитані",
  "You have no new notifications": "У вас немає нових повідомлень",
  "Connect Wallet": "Підключити гаманець",
  "WalletConnect is taking too long to open. Please refresh the page and try again.":
    "WalletConnect не відкривається занадто довго. Будь ласка, оновіть сторінку і спробуйте ще раз.",
  "Unexpected error opening WalletConnect modal. Please refresh the page and try again.":
    "Несподівана помилка при відкритті модального вікна WalletConnect. Будь ласка, оновіть сторінку і спробуйте ще раз.",
  "Connect your Wallet": "Підключити гаманець",
  "Link Wallet": "Пов'язати гаманець",
  "You will receive a signature request. Signing is free and will not send a transaction.":
    "Ви отримаєте запит на підпис. Підпис безкоштовний і не відправить транзакцію.",
  "Generate Paradex Chain wallet": "Створіть гаманець ланцюга Paradex",
  "Generating Paradex Chain wallet": "Створюється гаманець ланцюга Paradex",
  "Paradex Chain wallet generated": "Гаманець ланцюга Paradex створено",
  "Verify that you own this wallet": "Перевірте, чи ви володієте цім гаманцем",
  "Remember Me": "Пам'ятати мене",
  "Only use 'Remember Me' if you are using a secure device you own. Selecting this option can expose your information to  others if you are accessing this wallet from a public device.":
    "Використовуйте 'Пам'ятати мене', якщо ви користуєтеся безпечним пристроєм, який належить вам. Вибір цього параметра може відобразити вашу інформацію для інших користувачів, якщо ви користуєтеся цей гаманець з публічного пристрою.",
  "Continue": "Продовжити",
  "Disconnect Wallet": "Відключити гаманець",
  "Disconnect": "Відключити",
  "Account": "Рахунок",
  "Username": "Ім'я користувача",
  "Edit": "Редагувати",
  "Your username is now hidden. It is not visible to other users.":
    "Ваше ім'я користувача тепер приховане. Воно не видиме для інших користувачів.",
  "Your username will be visible to other users unless you choose to hide it. You can always change it later.":
    "Ваше ім'я користувача буде доступним для інших користувачів, якщо ви не виберете приховати його. Ви завжди можете змінити його пізніше.",
  "Hide my Username": "Приховати моє ім'я",
  "Save": "Зберегти",
  "Username is required": "Ім'я користувача є обов'язковим",
  "Username must be between 5 and 20 characters":
    "Ім'я користувача повинно бути від 5 до 20 символів",
  "Username can only contain letters and numbers":
    "Ім'я користувача може містити лише літери і цифри",
  "Wallets": "Гаманці",
  "Paradex Block Explorer": "Оглядач блоків Paradex",
  "Log out": "Вийти",
  "No markets available": "Немає доступних ринків",
  "Last Price": "Остання ціна",
  "Open Interest": "Відкритий інтерес",
  "24h Volume": "Обсяг за 24 години",
  "Mark Price": "Ціна маркування",
  "Spot Price": "Ціна спот",
  "Eight hour funding rate that is continuously accrued on positions and settled upon any trade. If the funding rate is positive, longs pay shorts. If negative, shorts pay longs.":
    "8-годинний коефіцієнт фінансування, який нараховується щодня і розраховується на будь-яку торгівлю. Якщо коефіцієнт фінансування позитивний, довгі платять короткі. Якщо негативний, короткі платять довгі.",
  "8h Funding": "8-годинне фінансування",
  "Limit": "Ліміт",
  "Market": "Ринок",
  "Stop Limit": "Ліміт зупинки",
  "Stop Market": "Ліміт ринку",
  "Scaled Order": "Масштабована заявка",
  "Trigger Price": "Ціна тригера",
  "Input Price": "Вхідна ціна",
  "Position Mark Price": "Ціна маркування позиції",
  "Amount": "Сума",
  "Reduce Only": "Зменшити лише",
  "Reduce Only will increase position": "Зменшити лише збільшить позицію",
  "Your order stays open until it is filled or you decide to cancel.":
    "Ваша заявка залишається відкритою, поки вона не буде заповнена або ви не відмовитеся від неї.",
  "Your order gets filled right away, partially or fully, and whatever is left gets canceled instantly.":
    "Ваша заявка заповнюється відразу, частково або повністю, і все, що залишиться, буде скасовано миттєво.",
  "Conditional limit order that serves to add liquidity to the order book as a Maker order.":
    "Умовна обмежувальна заявка, яка служить для додавання ліквідності до книги заявок як заявка виробника.",
  "Currently the fees are fixed and uniform across the platform. Maker {{makerFee}} / Taker {{takerFee}}":
    "Наразі комісії є фіксованими і однорідними на всій платформі. Виробник {{makerFee}} / Taker {{takerFee}}",
  "Value": "Вартість",
  "Buy": "Купити",
  "BUY": "КУПИТИ",
  "LONG": "ДОВГІ",
  "Sell": "Продати",
  "SELL": "ПРОДАТИ",
  "SHORT": "КОРОТКІ",
  "Order Value": "Вартість заявки",
  "Current Position": "Поточна позиція",
  "The total value of your account at current mark prices":
    "Загальна вартість вашого рахунку за поточною ціною маркування",
  "Free Collateral": "Вільне забезпечення",
  "Account Leverage": "Маржа рахунку",
  "Margin": "Маржа",
  "Margin Ratio": "Коефіцієнт маржі",
  "Initial Margin": "Початкова маржа",
  "Maintenance Margin": "Маржа обслуговування",
  "Order Book": "Книга заявок",
  "Trades": "Торги",
  "Price": "Ціна",
  "Size": "Розмір",
  "Total": "Загальний",
  "Time": "Час",
  "Spread": "Спред",
  "Positions": "Позиції",
  "Open Orders": "Відкриті заявки",
  "Trade History": "Історія торгів",
  "Order History": "Історія заявок",
  "Funding Payments": "Фінансування платежів",
  "Side": "Сторона",
  "Entry Price": "Ціна входу",
  "Liquidation Price": "Ціна ліквідації",
  "Breakeven Price": "Ціна беззбитковості",
  "Unrealized Funding": "Нереалізований фінансування",
  "Close Position": "Закрити позицію",
  "Market Close": "Закрити ринок",
  "Close Long": "Закрити довгу",
  "Close Short": "Закрити коротку",
  "Type": "Тип",
  "Filled": "Заповнена",
  "Triggered": "Тригерована",
  "Instruction": "Інструкція",
  "Client Order Id": "Ідентифікатор заявки користувача",
  "Status": "Статус",
  "NEW": "НОВИЙ",
  "UNTRIGGERED": "НЕТРИГЕРОВАНИЙ",
  "OPEN": "ВІДКРИТИЙ",
  "CLOSED": "ЗАКРИТИЙ",
  "Pending": "В ОЧІКУВАННІ",
  "Complete": "Завершено",
  "Completed": "Завершено",
  "Failed": "Не вдалося",
  "Bust": "Банкрутство",
  "Value / Fee": "Вартість / Комісія",
  "Liquidity": "Ліквідність",
  "Tx Hash": "Хеш транзакції",
  "Trade Id": "Ідентифікатор торгу",
  "Remaining": "Залишок",
  "Cancel Reason": "Причина скасування",
  "User Canceled": "Користувач скасував",
  "Not Enough Margin": "Недостатньо маржі",
  "Empty Market": "Пустий ринок",
  "Post Only": "Тільки публікація",
  "Post Only Would Cross": "Тільки публікація перетинала б",
  "Remaining IOC Cancel": "Залишок IOC Скасувати",
  "Unexpected Failure": "Несподівана помилка",
  "Deleverage": "Зменшити маржу",
  "In Liquidation": "В ліквідації",
  "Self Trade": "Самоторгівля",
  "Asset Unavailable": "Актив недоступний",
  "Asset Expired": "Актив закінчився",
  "Order Type Invalid": "Недійсний тип заявки",
  "Price Not Available": "Ціна недоступна",
  "Expired": "Закінчився",
  "Exceeds Max Slippage": "Перевищує максимальну знижку",
  "Timeout": "Таймаут",
  "Order Exceeds Position Limit": "Заявка перевищує ліміт позиції",
  "Order Id": "Ідентифікатор заявки",
  "Payment": "Платіж",
  "Cancel All": "Скасувати все",
  "{{orderSize}} will be closed at Market price":
    "{{orderSize}} буде закрито за ціною ринку",
  "By connecting a wallet, you agree to <1>Paradex Terms of Service</1> and represent and warrant to Paradex that you are not a <2>Restricted Person</2>.":
    "Надіславши гаманець, ви погоджуєтеся з <1>Умовами обслуговування Paradex</1> і представляєте і гарантуєте Paradex, що ви не є <2>Обмеженою особою</2>.",
  "Wallet": "Гаманець",
  "Deposit_verb": "Депозит",
  "Deposit_noun": "Депозит",
  "Withdraw": "Вивести",
  "Withdrawal": "Виведення",
  "Transfers": "Передачі",
  "Transfer": "Передача",
  "Collateral": "Забезпечення",
  "Asset": "Актив",
  "Wallet Balance": "Баланс гаманця",
  "Actions": "Дії",
  "Action": "Дія",
  "External Tx Hash": "Хеш зовнішньої транзакції",
  "Withdraw to Wallet": "Вивести на гаманець",
  "Deposit to Paradex": "Депозит до Paradex",
  "You have no balance in your Ethereum wallet. Deposit some {{asset}} and try again.":
    "У вашому гаманці Ethereum немає балансу. Депозитуйте деякі {{asset}} і спробуйте ще раз.",
  "It is not possible to deposit more than your current balance.":
    "Неможливо депозитувати більше, ніж поточний баланс.",
  "Available": "Доступний",
  "Initiate a deposit from your Ethereum wallet to the Paradex bridge. These transactions":
    "Ініціюйте депозит з вашого гаманця Ethereum до моста Paradex. Ці транзакції",
  "Initiate a deposit from your Ethereum wallet to the Paradex bridge. These transactions may take several minutes depending on network conditions. For more information check out our <1>docs</1>.":
    "Ініціюйте депозит з вашого гаманця Ethereum до моста Paradex. Ці транзакції можуть тривати кілька хвилин залежно від умов мережі. Для отримання додаткової інформації перегляньте нашу <1>документацію</1>.",
  "read more": "читати більше",
  "Initiate Deposit to Bridge": "Ініціюйте депозит до моста",
  "Enable USDC on Paradex": "Увімкніть USDC на Paradex",
  "In order to deposit you must allow Paradex access to at least {{amount}}.":
    "Щоб депозитувати, ви повинні дозволити Paradex доступ до принаймні {{amount}}.",
  "Enable USDC": "Увімкнути USDC",
  "Withdraw initiated": "Виведення ініційовано",
  "Withdrawals are a two-step process. First initiate a withdrawal from Paradex":
    "Виведення є двокроковим процесом. Спочатку ініціюйте виведення з Paradex",
  "Withdrawals are a two-step process. First initiate a withdrawal from Paradex to the Paradex Bridge. This first step can take 5-16 hours (10 on average). Second, initiate your withdrawal from the Paradex Bridge to your Ethereum wallet via the Deposits / Withdrawals table. For more information check out our <1>docs</1>.":
    "Виведення є двокроковим процесом. Спочатку ініціюйте виведення з Paradex до моста Paradex. Цей перший крок може тривати 5-16 годин (10 у середньому). Друга, ініціюйте виведення з моста Paradex до вашого гаманця Ethereum через таблицю депозитів / виведень. Для отримання додаткової інформації перегляньте нашу <1>документацію</1>.",
  "Initiate Withdrawal to Bridge": "Ініціюйте виведення до моста",
  "You have no funds to withdraw. It is not possible to withdraw more than your free collateral.":
    "У вас немає коштів для виведення. Неможливо вивести більше, ніж ваш безкоштовний баланс.",
  "It is not possible to withdraw more than your free collateral.":
    "Неможливо вивести більше, ніж ваш безкоштовний баланс.",
  "You have no collateral": "У вас немає забезпечення",
  "You have no open positions": "У вас немає відкритих позицій",
  "You have no open orders": "У вас немає відкритих заявок",
  "You have no trades": "У вас немає торгів",
  "You have no orders": "У вас немає заявок",
  "You have no funding payments": "У вас немає платежів фінансування",
  "You have no transfers": "У вас немає передач",
  "Account in liquidation": "Рахунок у ліквідації",
  "Account is being liquidated. Any open positions will be reduced. Trading now is disabled.":
    "Рахунок у ліквідації. Будь-які відкриті позиції будуть зменшені. Торгівля зараз неможлива.",
  "Account liquidated": "Рахунок ліквідований",
  "Your account was liquidated on {{createdAt}}. Any open positions were reduced.":
    "Ваш рахунок був ліквідований {{createdAt}}. Будь-які відкриті позиції були зменшені.",
  "Deposit to Paradex successful": "Депозит до Paradex успішний",
  "Deposit to Paradex failed": "Депозит до Paradex не вдався",
  "Unexpected error. Try again.": "Несподівана помилка. Спробуйте ще раз.",
  "Deposit initiated": "Депозит ініційований",
  "Deposit to bridge initiated": "Депозит до моста ініційований",
  "Deposit to Paradex available": "Депозит до Paradex доступний",
  "Go to Deposits": "Перейти до депозитів",
  "Deposit to Paradex in progress": "Депозит до Paradex у процесі",
  "Transfer successful": "Передача успішна",
  "Limit {{side}} Order Fill": "Ліміт {{side}} заповнення заявки",
  "{{type}} {{side}} Order Submitted": "{{type}} {{side}} Заявка надіслана",
  "{{type}} {{side}} Order Canceled": "{{type}} {{side}} Заявка скасована",
  "Reason": "Причина",
  "Market {{side}} Order Fill": "Заявка ринку {{side}} заповнена",
  "Trade Busted On-Chain": "Торг розбитий на ланцюгу",
  "Fill id": "Ідентифікатор заповнення",
  "Withdraw to wallet failed": "Виведення на гаманець не вдалося",
  "Withdrawal to bridge initiated": "Виведення до моста ініційоване",
  "Withdrawal error. Try again.": "Помилка виведення. Спробуйте ще раз.",
  "Withdrawal to bridge in progress": "Виведення до моста у процесі",
  "Withdrawal to wallet initiated": "Виведення на гаманець ініційоване",
  "Withdrawal to bridge failed": "Виведення до моста не вдалося",
  "Transaction error. Try again.": "Помилка транзакції. Спробуйте ще раз.",
  "Withdrawal to wallet available": "Виведення на гаманець доступне",
  "Go to Withdrawals": "Перейти до виведень",
  "Withdrawal to wallet successful": "Виведення на гаманець успішне",
  "Session expired, please log in again.":
    "Сесія закінчилася. Будь ласка, увійдіть ще раз.",
  "Failed to submit order": "Не вдалося надіслати заявку",
  "Your username preferences were updated!":
    "Ваші налаштування імені користувача оновлено!",
  "Unexpected error updating username preferences":
    "Несподівана помилка при оновленні налаштувань імені користувача",
  "This username already exists. Please choose a different username and try again.":
    "Це ім'я користувача вже існує. Будь ласка, оберіть інше ім'я користувача і спробуйте ще раз.",
  "Validation Error": "Помилка перевірки",
  "Binding Error": "Помилка зв'язування",
  "Internal Error": "Внутрішня помилка",
  "Not Found": "Не знайдено",
  "Service Unavailable": "Сервіс недоступний",
  "Invalid Request Parameter": "Недійсний параметр запиту",
  "Order Id Not Found": "Ідентифікатор заявки не знайдено",
  "Order Is Closed": "Заявка закрита",
  "Order Is Not Open Yet": "Заявка ще не відкрита",
  "Client Order Id Not Found": "Ідентифікатор заявки користувача не знайдено",
  "Duplicated Client Id": "Дублікат ідентифікатора користувача",
  "Invalid Price Precision": "Недійсна точність ціни",
  "Invalid Token": "Недійсний токен",
  "Invalid Ethereum Address": "Недійсна адреса Ethereum",
  "Invalid Ethereum Signature": "Недійсна електронна підпис Ethereum",
  "Invalid Starknet Address": "Недійсна адреса Starknet",
  "Invalid Starknet Signature": "Недійсна електронна підпис Starknet",
  "Starknet Signature Verification Failed":
    "Перевірка електронної підпису Starknet не пройдена",
  "Bad Starknet Request": "Поганий запит Starknet",
  "Ethereum Signer Mismatch": "Незбільшення підписувача Ethereum",
  "Ethereum Hash Mismatch": "Незбільшення хешу Ethereum",
  "Not Onboarded": "Незареєстрований",
  "Invalid Timestamp": "Недійсний часовий штамп",
  "Invalid Signature Expiration": "Недійсний термін дії електронної підпису",
  "Account Not Found": "Рахунок не знайдено",
  "Invalid Order Signature": "Недійсна електронна підпис заявки",
  "Public Key Invalid": "Публічний ключ недійсний",
  "Unauthorized Ethereum Address": "Неавторизована адреса Ethereum",
  "Ethereum Address Already Onboarded": "Адреса Ethereum вже зареєстрована",
  "Market Not Found": "Ринок не знайдено",
  "Allowlist Entry Not Found": "Вхід до списку дозволених не знайдено",
  "Username In Use": "Ім'я користувача у використанні",
  "Service access restricted in your region":
    "Доступ до сервісу обмежений у вашому регіоні",
  "No Access Allowed": "Доступ заборонено",
  "You do not have access to the platform. Please join our Discord to get access.":
    "У вас немає доступу до платформи. Будь ласка, приєднайтеся до нашого Discord, щоб отримати доступ.",
  "{{actualChainName}} Wallet Connected":
    "Гаманець {{actualChainName}} підключено",
  'Incorrect network selected "{{actualChainName}}". Please change the network to "{{requiredChainName}}" (id={{requiredChainId}}) in your wallet and retry.':
    'Неправильна мережа "{{actualChainName}}". Будь ласка, змініть мережу на "{{requiredChainName}}" (id={{requiredChainId}}) у вашому гаманці і спробуйте ще раз.',
  "Blocked Ethereum Address": "Заблокована адреса Ethereum",
  "Choose Wallet": "Оберіть гаманець",
  "Starknet Wallet": "Гаманець Starknet",
  "Switch to {{env}}": "Перейти до {{env}}",
  "Search": "Пошук",
  "League": "Ліга",
  "Switch Account": "Змінити рахунок",
  "Rank": "Ранг",
  "24h XP": "XP за 24 години",
  "XP Per Day": "XP за день",
  "Season Volume": "Обсяг сезону",
  "Lifetime Volume": "Загальний обсяг",
  "Pre-Season": "Передсезон",
  "Earn More XP": "Заробити більше XP",
  "XP Boost": "Підсилення XP",
  "XP Drop": "Випадання XP",
  "Trader": "Трейдер",
  "Total Share": "Загальна частка",
  "Maker Share": "Частка мейкера",
  "Maker Volume Share": "Частка обсягу мейкера",
  "Fee Share": "Частка комісії",
  "Fee Volume Share": "Частка обсягу комісії",
  "No data available": "Немає доступних даних",
  "Refer & Earn": "Запроси та заробляй",
  "Hours": "Години",
  "Minutes": "Хвилини",
  "Time/Duration": "Час/Тривалість",
  "Runtime": "Час виконання",
  "Frequency": "Частота",
  "Number of Orders": "Кількість ордерів",
  "Runtime should not be empty": "Час виконання не може бути порожнім",
  "Runtime should be less than or equal to 24 hours":
    "Час виконання має бути менше або дорівнювати 24 годинам",
  "Inactive": "Неактивний",
  "Avg. Price": "Середня ціна",
  "Take Profit / Stop Loss: Set take profit and stop loss price levels to automate trade actions. When the Mark Price will reach the price you set, it will send your order to the matching engine.":
    "Take Profit / Stop Loss: Встановіть рівні цін take profit та stop loss для автоматизації торгових дій. Коли Маркована Ціна досягне встановленої вами ціни, ваш ордер буде надіслано до системи співставлення.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount with an estimated profit of <3 />.":
    "Коли Маркована Ціна досягне <1 />, це активує <2 /> ордер для закриття обраної суми з очікуваним прибутком <3 />.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount with an estimated loss of <3 />.":
    "Коли Маркована Ціна досягне <1 />, це активує <2 /> ордер для закриття обраної суми з очікуваним збитком <3 />.",
  "Edit TP/SL For This Position": "Редагувати TP/SL для цієї позиції",
  "Take Profit": "Take Profit",
  "Profit": "Прибуток",
  "Stop Loss": "Stop Loss",
  "Loss": "Збиток",
  "Confirm": "Підтвердити",
  "Take Profit Limit": "Ліміт Take Profit",
  "Take Profit Market": "Ринковий Take Profit",
  "Stop Loss Limit": "Ліміт Stop Loss",
  "Stop Loss Market": "Ринковий Stop Loss",
  "Funding": "Фінансування",
  "Unwinding": "Розгортання",
  "Cancel TP/SL orders": "Скасувати ордери TP/SL",
  "TP/SL orders submitted successfully.": "Ордери TP/SL успішно надіслано",
  "Pre-Season + All Seasons": "Передсезон + Всі Сезони",
  "Season {{season}}": "Сезон {{season}}",
  "XP Season {{season}}": "XP Сезон {{season}}",
  "Slippage": "Знижка",
  "Max Slippage": "Максимальна знижка",
  "Max slippage defines the largest acceptable price deviation from the mark price.":
    "Максимальна знижка визначає найбільшу прийняту відхилення ціни від ринкової ціни.",
  "During execution, the order can be canceled if the price exceeds the slippage limit or falls outside the Market <1>Price Band</1>.":
    "Виконання, заявка може бути скасована, якщо ціна перевищує ліміт знижки або виходить за межі ринку <1>Ціновий Діапазон</1>.",
  "The Maximum Slippage allowed for {{symbol}} is {{price_band_width}}.":
    "Максимальна знижка для {{symbol}} становить {{price_band_width}}.",
  "Update Max Slippage": "Оновити максимальну знижку",
  "Mark IV": "Марк IV",
  "Delta": "Дельта",
  "Failed to load positions": "Не вдалося завантажити позиції",
  "No open positions": "Немає відкритих позицій",
  "learn more": "читати більше",
  "Learn More": "Дізнатися більше",
  "Search Markets": "Пошук ринків",
  "Futures": "Фючерси",
  "Options": "Опції",
  "OI": "ВІ",
  "Addresses": "Адреси",
  "My Profile": "Мій профіль",
  "Chart": "Діаграма",
  "ROI": "РОІ",
  "Sharpe": "Шарп",
  "Max Loss": "Максимальний Збиток",
  "Total Volume": "Загальний Обсяг",
  "My NFTs": "Мої NFT",
  "My XP": "Мой XP",
  "Balances": "Баланси",
  "Other": "Інший",
  "Paradex XP: Pre-Season": "Paradex XP: Передсезон",
  "Paradex XP: Season {{season}}": "Paradex XP: Сезон {{season}}",
  "Season {{season}} XP": "Сезон {{season}} XP",
  "Refer your friends to earn 10%": "Запросити друзів для зароблення 10%",
  "Refer Friends and Earn 10%": "Запросити друзів і заробити 10%",
  "Invest in Paradex Vault & Earn 3X Boost":
    "Інвестувати в Paradex Vault & Заробити 3X Підсилення",
  "Avg. Daily XP": "Середній денний XP",
  "Lifetime XP": "Загальний XP",
  "My Rank": "Мой Ранг",
  "What is XP?": "Що таке XP?",
  "XP History": "Історія XP",
  "Last Week XP": "XP за попередній тиждень",
  "TVL XP": "TVL XP",
  "OI XP": "ВІ XP",
  "Liquidation XP": "Ліквідація XP",
  "Vaults XP": "Сховища XP",
  "Week": "Тиждень",
  "Season": "Сезон",
  "Last updated: {{timestamp}}": "Останнє оновлення: {{timestamp}}",
  "Xperience Points (XP)": "Точки досвіду (XP)",
  "Log in to see your XP balance": "Увійдіть, щоб побачити баланс XP",
  "Log in to see where you stand": "Увійдіть, щоб побачити, де ви стоїте",
  "Connect your X account to join Pro League":
    "Підключіть ваш X акаунт для приєднання до Про Ліги",
  "Avg. Fill Price": "Середня ціна заповнення",
  "Spot": "Спот",
  "Derivatives": "Похідні",
  "P&L Chart": "Діаграма P&L",
  "Account Value Chart": "Діаграма Балансу Рахунку",
  "Funding History": "Історія Фінансування",
  "Manage Funds": "Керуйте Коштами",
  "Profile": "Профіль",
  "Community XP": "Спільний XP",
  "XP Share": "Частка XP",
  "Next XP Drop in": "Наступне Випадання XP",
  "In Progress": "В Процесі",
  "{{field}} must be greater than {{value}}":
    "{{field}} має бути більшим за {{value}}",
  "{{field}} must be less than {{value}}":
    "{{field}} має бути меншим за {{value}}",
  "Enter a valid Take Profit or Stop Loss price":
    "Введіть дійсну ціну Take Profit або Stop Loss",
  "Take Profit and Stop Loss prices cannot be the same":
    "Ціни Take Profit і Stop Loss не можуть бути однаковими",
  "Automatic Withdrawal": "Автоматичне Виведення",
  "Estimated Gas Fee": "Оцінена вартість газу",
  "Auto Withdrawal": "Автоматичне Виведення",
  "Withdrawing...": "Виведення...",
  "Paradex Bridge withdrawals can take between 5-16 hours to complete":
    "Виведення з моста Paradex може тривати від 5 до 16 годин для завершення",
  "Socialized Loss": "Соціалізований Збиток",
  "Receivable Amount": "Очікувана Сума",
  "Auto withdrawing...": "Автоматичне виведення...",
  "Manage Twitter Connection": "Керувати З'єднанням Twitter",
  "Unlock additional functionality.": "Розблокувати додаткові функції.",
  "Please note this connection can't be used as a login method to Paradex.":
    "Будь ласка, пам'ятайте, що цей з'єднання не можна використовувати як метод входу до Paradex.",
  "Toast Notifications": "Тост Повідомлення",
  "Display toast notifications": "Показувати тост повідомлення",
  "Average Entry Price": "Середня Ціна Входу",
  "Display average entry price line on chart":
    "Показувати лінію середньої ціни входу на діаграмі",
  "Display liquidation price line on chart":
    "Показувати лінію ціни ліквідації на діаграмі",
  "Display open order lines on chart":
    "Показувати лінії відкритих заявок на діаграмі",
  "Failed to disconnect Twitter": "Не вдалося відключити Twitter",
  "Failed to authorize Twitter": "Не вдалося авторизувати Twitter",
  "Twitter account linked successfully!": "Акаунт Twitter підключено успішно!",
  "Failed to link twitter account": "Не вдалося підключити акаунт Twitter",
  "Fees": "Комісії",
  "Taker pays {{takerFee}} in fees. Maker receives {{makerRebate}} in rebates.":
    "Taker платить {{takerFee}} у комісіях. Maker отримує {{makerRebate}} у відшкодуваннях.",
  "Failed to authorize Discord": "Не вдалося авторизувати Discord",
  "Manage Discord Connection": "Керувати З'єднанням Discord",
  "Failed to disconnect Discord": "Не вдалося відключити Discord",
  "Failed to link Discord account": "Не вдалося підключити акаунт Discord",
  "Discord account linked successfully!": "Акаунт Discord підключено успішно!",
  "Connect": "Підключити",
  "Unrealized P&L from current vault token balance. This doesn't account for owner profit share or slippage from positions unwind.":
    "Нереалізований P&L з балансу токенів поточного сховища. Це не враховує частку прибутку власника або знижку з позицій розгортання.",
  "Show P&L Amount": "Показати Суму P&L",
  "Receivable amount must be positive": "Очікувана сума має бути позитивною",
  "Wallet Address Change Detected": "Зміна адреси гаманця",
  "You can proceed with connecting your Paradex account to this wallet address or continue using your current connection.":
    "Ви можете продовжити підключення вашого акаунту Paradex до цієї адреси гаманця або продовжити використовувати ваш поточний з'єднання.",
  "Connect To New Wallet": "Підключити до нового гаманця",
  "Keep Using Current Wallet": "Продовжити Використовувати Поточний Гаманець",
  "Connect Current Wallet": "Підключити Поточний Гаманець",
  "The account is not connected. Please make sure that <1>{{walletAddress}}</1> is connected and is the active account in your <2>{{walletName}}</2> wallet.":
    "Акаунт не підключено. Будь ласка, переконайтеся, що <1>{{walletAddress}}</1> підключено і є активним акаунтом у вашому <2>{{walletName}}</2> гаманці.",
  "Reconnecting": "Підключаючись",
  "There is a request in progress. Please proceed in your wallet.":
    "Існує запит у процесі. Будь ласка, продовжите в вашому гаманці.",
  "Modify Order": "Змінити Заявку",
  "Realized P&L": "Реалізований P&L",
  "Close All": "Закрити Все",
  "Close All Positions": "Закрити Всі Позиції",
  "Market Close All Positions": "Закрити Всі Позиції Ринку",
  "Close {{percent}}% of All Positions": "Закрити {{percent}}% Всіх Позицій",
  "Failed to close all positions": "Не вдалося закрити всі позиції",
  "Number of Positions": "Кількість Позицій",
  "Markets": "Ринки",
  "Position Value (Closing)": "Вартість Позиції (Закриття)",
  "{{count}} positions will be rounded to the nearest order increment":
    "{{count}} позицій буде округлено до найближчого інкременту заявки",
  "You will earn Bonus XP on your referral activity":
    "Ви отримаєте Бонус XP за вашу активність запрошень",
  "Token Price": "Ціна Токену",
  "Vault Token Price Chart": "Діаграма Ціни Токенів Сховища",
  "Market Close Orders Submitted": "Закриті Заявки Надіслані",
  "This profile is already linked to a different Paradex account. Disconnect socials from existing account and try again.":
    "Цей профіль вже підключено до іншого акаунту Paradex. Відключіть соціальні мережі з існуючого акаунту і спробуйте ще раз.",
  "XP Drops every Friday": "Випадання XP кожного П'ятниці",
  "No XP Data Available": "Немає Доступних Даних XP",
  "XP data is available only for the Main Account.":
    "Дані XP доступні лише для головного акаунту.",
  "Please switch to your Main Account to view your XP.":
    "Будь ласка, переключіться на головний акаунт, щоб побачити ваш XP.",
  "Referrals Not Available": "Запрошення Недоступні",
  "Referrals are available only for the Main Account.":
    "Запрошення доступні лише для головного акаунту.",
  "Please switch to your Main Account to view your Referrals.":
    "Будь ласка, переключіться на головний акаунт, щоб побачити ваші запрошення.",
  "Deposit initiated via Layerswap": "Депозит ініційований через Layerswap",
  "Position History": "Історія Позицій",
  "Avg. Close Price": "Середня Ціна Закриття",
  "Max Size": "Максимальний Розмір",
  "Closed Size": "Закритий Розмір",
  "Opened": "Відкритий",
  "You have no positions": "У вас немає позицій",
  "Destination Address": "Адреса Призначення",
  "Withdrawal via Layerswap initiated": "Виведення через Layerswap ініційоване",
  "Withdrawal via Layerswap failed": "Виведення через Layerswap не вдалося",
  "Market Share": "Частка Ринку",
  "Tier": "Рівень",
  "Avg Maker Volume": "Середній Обсяг Виробника",
  "Liquidity Score": "Оцінка Ліквідності",
  "Reward Share": "Частка Винагороди",
  "Enter amount": "Введіть Суму",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount at <3 /> with an estimated profit of <4 />.":
    "Коли Маркована Ціна досягне <1 />, це активує <2 /> ордер для закриття обраної суми на <3 /> з очікуваним прибутком <4 />.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount at <3 /> with an estimated loss of <4 />.":
    "Коли Маркована Ціна досягне <1 />, це активує <2 /> ордер для закриття обраної суми на <3 /> з очікуваним збитком <4 />.",
  "Limit Price": "Лімітна Ціна",
  "Delete": "Видалити",
  "Use other bridges from the list above to withdraw funds much faster.":
    "Використовуйте інші мости зі списку вище для виведення коштів набагато швидше.",
  "Paradex Chain Monitor": "Монітор Ланцюга Paradex",
  "OTM Amount": "OTM Сума",
  "Leverage": "Маржа",
  "Strikes": "Стріли",
  "Strike": "Стріла",
  "Change": "Зміна",
  "Dist. to Spot": "Відстань до Споту",
  "Bid": "Заявки",
  "Mark": "Марк",
  "Ask": "Запит",
  "IV": "IV",
  "Dist %": "Відстань %",
  "Call": "Запит",
  "Put": "Постачання",
  "Mark Price / IV": "Ціна Маркування / IV",
  "8h F-Funding": "8h F-Фінансування",
  "No Strikes Available": "Немає Доступних Стріл",
  "Attributes": "Атрибути",
  "Why no Expiries?": "Чому немає Термінів?",
  "{{multiplier}}x Boost": "{{multiplier}}x Підсилення",
  "Like perpetual futures, perpetual options have no expiration, offering continuous exposure to the underlying asset with built-in downside protection. This gives users the flexibility to hold their position indefinitely and decide the optimal time to close it based on their strategy":
    "Як і в фючерсах, опціони не мають терміну дії, що забезпечує безперервний доступ до базового активу з вбудованою захистою від збитків. Це дає користувачам гнучкість тримати позицію нескінченно і визначати оптимальний час закриття на основі їх стратегії",
  "Implied Volatility used to calculate the option's Mark Price":
    "Імплікована Відхиленість, використана для розрахунку ціни маркування опції",
  "Distance between the Strike Price and the underlying Spot Price":
    "Відстань між ціною страйку і базовою ціною споту",
  "Eight hour funding that is continuously accrued on positions and settled upon any trade. If the funding rate is positive, longs pay shorts. If negative, shorts pay longs":
    "8-годинне фінансування, яке нараховується щодня і розраховується на будь-яку торгівлю. Якщо коефіцієнт фінансування позитивний, довгі платять короткі. Якщо негативний, короткі платять довгі.",
  "The Leverage Ratio (Lambda) measures an option's effective leverage by indicating how much the option's value changes, in percentage terms, for a 1% change in the spot price of the underlying asset. For example, if an option has a Lambda of 50 and the spot price increases by 1%, the option's value would increase by 50%":
    "Коефіцієнт левериджу (Ламбда) вимірює ефективне левериджу опції, вказуючи, наскільки змінюється вартість опції в процентах для 1% зміни базової ціни активу. Наприклад, якщо опція має Ламбда 50 і базова ціна збільшується на 1%, вартість опції збільшиться на 50%.",
  "Option delta measures how much the price of an option is expected to change for a $1 change in the price of the underlying asset. For example, a delta of 0.5 means the option price will move $0.50 for every $1 move in the underlying":
    "Дельта вимірює, наскільки ціна опції очікується змінитися для $1 зміни ціни базового активу. Наприклад, дельта 0,5 означає, що ціна опції переміститься на $0,50 для кожного $1 руху в базовому активі.",
  "Eight hour funding rate of the perpetual future on the same underlying asset":
    "8-годинне фінансування періодичного фючерса на тому ж базовому активі",
  "Having trouble? Try signing out and connecting again.":
    "Маєте проблеми? Спробуйте вийти і знову підключитися.",
  "API Reference": "API Reference",
  "XP Distribution occurs once per week on Friday at ~12 PM UTC":
    "Розподіл XP відбувається раз на тиждень у п'ятницю о 12:00 UTC",
  "What are Perpetual Options?": "Що таке Періодичні Опції?",
  "Delta represents the increase in option price based on a $1 increase in the underlying spot price.\n\nFor example, a delta of 0.50 means the price of the option is expected to increase by $0.50 for a $1 increase in the underlying spot price.":
    "Дельта представляє збільшення ціни опції на $1 у ціні базового споту.\n\nНаприклад, дельта 0,50 означає, що ціна опції очікується збільшитися на $0,50 для $1 збільшення базової ціни споту.",
  "Leverage(Lambda) represents the percentage change in option price for a 1% change in the underlying spot price.\n\nFor example, a lambda of 10 means the option price is expected to increase by 10% if the underlying spot price increases by 1%.":
    "Леверидж (Ламбда) представляє зміну ціни опції на 1% у ціні базового споту.\n\nНаприклад, лямбда 10 означає, що ціна опції очікується збільшитися на 10%, якщо базова ціна споту збільшиться на 1%.",
  "Eight hour options funding rate that is continuously accrued on positions and settled upon any trade. The funding rate for perpetual options reflects both the cost of leverage and the cost of insurance against adverse price moves. \n\nThe funding period of perpetual options is 24 hours. This means that the option premium will be continuously paid out over a 24h period. 24h funding rate = 3 x 8h funding rate":
    "8-годинне фінансування опцій, яке нараховується щодня і розраховується на будь-яку торгівлю. Ставка фінансування для періодичних опцій відображає як вартість левериджу, так і вартість страхування проти несприятливих рухів ціни. \n\nТермін дії періодичних опцій становить 24 години. Це означає, що премія опції буде безперервно виплачуватися протягом 24-годинного періоду. Ставка 24-годинного фінансування = 3 x Ставка 8-годинного фінансування",
  "Risk": "Ризик",
  "Vega": "Вега",
  "Gamma": "Гама",
  "Estimated Funding P&L for the next 8 hours based on current funding rates":
    "Оцінений P&L Фінансування на наступні 8 годин на основі поточної ставки фінансування",
  "Vega represents the increase in option price based on a 1% increase in the implied volatility.\n\nFor example, a Vega of 5 means the price of the option is expected to increase by $5 for a 1% increase in the implied volatility.":
    "Вега представляє збільшення ціни опції на 1% у відхиленні, яке очікується для 1% збільшення відхилення.",
  "Adjust Leverage": "Налаштувати Леверидж",
  "Leverage updated successfully": "Леверидж оновлено успішно",
  "Failed to update leverage": "Не вдалося оновити леверидж",
  "Adjust leverage parameters for selected market. Leverage will also increase on your open positions.":
    "Налаштуйте параметри левериджу для вибраного ринку. Леверидж також збільшиться на ваших відкритих позиціях.",
  "Please note that changing leverage will also apply for open positions and open orders on this market.":
    "Будь ласка, пам'ятайте, що зміна левериджу також застосується до відкритих позицій і відкритих заявок на цьому ринку.",
  "Selecting higher leverage could result in Deleveraging where some of your open orders would be cancelled.":
    "Вибір більшого левериджу може призвести до делевериджу, де деякі ваші відкриті заявки будуть скасовані.",
  "disabled": "відключений",
  "Margin Mode": "Режим Маржі",
  "Manage your risk on individual positions by restricting the amount of margin allocated to each. If the margin ratio of an isolated position reaches 100%, the position will be liquidated. Margin can be added or removed to individual positions in this mode.":
    "Керуйте ризиком на окремих позиціях, обмежуючи суму маржі, яка призначена для кожної. Якщо коефіцієнт маржі ізольованої позиції досягне 100%, позиція буде ліквідована. Маржу можна додавати або знімати з окремих позицій у цьому режимі.",
  "Margin mode updated successfully": "Режим маржі оновлено успішно",
  "Failed to update margin mode": "Не вдалося оновити режим маржі",
  "The minimum required amount for {{numOrders}} orders is {{totalSize}} {{currency}}.":
    "Мінімальна необхідна сума для {{numOrders}} ордерів становить {{totalSize}} {{currency}}.",
  "Favorites": "Обране",
  "Trending": "Трендові",
  "Losers": "Ті, що падають",
  "Gainers": "Ті, що зростають",
  "New Listing": "Новий лістинг",
  "Developers": "Розробники",
  "Developer Mode": "Режим розробника",
  "Enable developer mode": "Увімкнути режим розробника",
  "For external use": "Для зовнішнього використання",
  "Warning: Changing transfer behavior":
    "Попередження: Зміна поведінки передачі",
  "USDC will be transferred to account contract instead of Paradex. Amount won't be visible or available for use within Paradex, but can be used with external applications.":
    "USDC буде передано в контракт рахунку замість Paradex. Сума не буде видима або доступна для використання в Paradex, але може бути використана з зовнішніми застосунками.",
  "Affiliate Dashboard": "Панель партнерських програм",
};

export default translations;
