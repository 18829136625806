import { useDepositModalActions } from '#/features/deposit/modal-context';
import { useWalletView } from '#/features/wallet/wallet-context';

/**
 * Hook to be consumed by UI components to trigger deposit flow
 */
export function useDepositAction() {
  const depositModalActions = useDepositModalActions();
  const walletView = useWalletView();

  const isDisabled = !walletView.isSignedIn;

  const execute = () => {
    depositModalActions.open();
  };

  return { execute, isDisabled };
}
