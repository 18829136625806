import * as Starknet from 'starknet';

/**
 * Adopted from starknet-snap v3 and adapted.
 * @source https://github.com/Consensys/starknet-snap/blob/f879fe0d9a1ffa59686ba356ebd4820b31fc66e6/packages/starknet-snap/src/utils/starknetUtils.ts#L372-L383
 */
export default function getAccContractAddressAndCallData(
  accountClassHash: string,
  accountProxyClassHash: string,
  publicKey: string,
) {
  const calldata = Starknet.CallData.compile({
    implementation: accountClassHash,
    selector: Starknet.hash.getSelectorFromName('initialize'),
    calldata: Starknet.CallData.compile({
      signer: publicKey,
      guardian: '0',
    }),
  });
  const address = Starknet.hash.calculateContractAddressFromHash(
    publicKey,
    accountProxyClassHash,
    calldata,
    0,
  );

  return {
    address,
    calldata,
  };
}
