import { Keys } from "./en";

const translations: Record<Keys, string> = {
  "Display trade history on chart": "チャートで取引履歴を表示",
  "Add markets to Favorites in the Markets Browser":
    "マーケットブラウザでお気に入りにマーケットを追加しましょう",
  "Log in": "ログイン",
  "Export embedded wallet": "埋め込みウォレットをエクスポート",
  "Email or Social": "メールまたはソーシャル",
  "Failed to load balances": "残高の読み込みに失敗しました",
  "No balances": "残高がありません",
  "Verified Operator": "Verified Operator",
  "Strategy": "戦略",
  "Multi-Strategy": "マルチ戦略",
  "Master Vault": "メインボールト",
  "Master Vault Details": "メインボールトの詳細",
  "Annualized": "年率",
  "Depositors": "入金者",
  "No trade history": "取引履歴がありません",
  "Unwinding Progress": "解消進捗",
  "What are Vaults?": "ボールトとは？",
  "APR calculated as the Annualised 30-day Return":
    "APRは年間30日間のリターンとして計算されます",
  "APR": "APR",
  "Initializing": "初期化中",
  "Awaiting Deposit": "入金待ち",
  "Vault Age": "ボールト年齢",
  "30D Return": "30日間リターン",
  "{{field}} must be greater than the minimum initial deposit of {{minInitialDeposit}}":
    "{{field}}は{{minInitialDeposit}}以上の最小入金額である必要があります",
  "Minimum deposit": "最小入金額",
  "Withdrawal amount exceeds available vault shares":
    "引き出し額が利用可能なボールトのシェアを超えます",
  "Deposit would exceed maximum vault TVL of {{maxTVL}}":
    "入金がボールトの最大TVL {{maxTVL}} を超えます",
  "{{field}} is required": "{{field}}は必須です",
  "Lockup Remaining": "ロックアップ残高",
  "{{days}}D": "{{days}}日",
  "<1>{{days}}</1> D": "<1>{{days}}</1>日",
  "<1>{{hours}}</1> H": "<1>{{hours}}</1>時",
  "<1>{{minutes}}</1> M": "<1>{{minutes}}</1>分",
  "Vault settings submitted": "ボールト設定送信済み",
  "Vault settings updated": "ボールト設定更新済み",
  "Vault Settings": "ボールト設定",
  "Vault not found": "ボールトが見つかりません",
  "The owner receives a {{profitShare}} profit share.":
    "オーナーは{{profitShare}}の利益配分を受け取ります。",
  "This vault is entirely owned by the community.":
    "このボールトはコミュニティに完全に所有されています。",
  "Withdraw from Vault submitted": "ボールトから出金送信済み",
  "Deposit to Vault submitted": "ボールトへの入金送信済み",
  "Unknown error. Try again.": "不明なエラー。再試行してください。",
  "Vault Deposit": "ボールト入金",
  "Vault Withdrawal": "ボールト出金",
  "No Lockup": "ロックアップなし",
  "Lockup period is not over yet": "ロックアップ期間がまだ終了していません",
  "Protocol": "プロトコル",
  "24H": "24時間",
  "7D": "7日間",
  "30D": "30日間",
  "All": "全期間",
  "You have no investments in vaults": "ボールトに投資はありません",
  "Total Value": "総投資額",
  "Investments Made": "投資回数",
  "Operator": "オペレーター",
  "Manage Vault": "ボールトを管理",
  "Switch to the operator account": "オペレーターアカウントに切り替える",
  "Active": "アクティブ",
  "My Vaults": "自分のボールト",
  "Closed": "閉鎖済み",
  "Deposited": "預入済み",
  "Lifetime ROI": "ROI 総計",
  "Owners Equity": "オーナーズエクイティ",
  "Top Trending": "人気急上昇",
  "Owner Share": "オーナーシェア",
  "Vault Details": "ボールト詳細",
  "Contract": "契約",
  "Withdraw from Vault": "ボールトから出金",
  "Withdraw from Vault successful": "ボールトからの出金成功",
  "Failed to withdraw from Vault": "ボールトからの出金失敗",
  "Name": "名前",
  "Deposit": "入金",
  "Tokens": "トークン",
  "Avg. Entry Price": "平均エントリー価格",
  "Current Price": "現在価格",
  "P&L": "損益",
  "My Vault Deposit": "マイボールト入金",
  "Vaults": "ボールト",
  "Create": "作成",
  "Create My Vault": "マイボールトを作成",
  "Vault Name": "ボールト名",
  "Vault Description": "ボールト説明",
  "Token Symbol": "トークンシンボル",
  "Deposit Amount": "入金額",
  "Vault description will be visible publicly and you will not be able to edit this later.":
    "ボールトの説明は公開され、後で編集することはできません。",
  "Once created, deposit at least {{minDeposit}} USDC to activate the vault. At this moment, only the Max TVL can be changed later.":
    "一度作成すると、少なくとも{{minDeposit}} USDCを入金してボールトをアクティブ化できます。この時点では、最大TVLのみ変更できます。",
  "Create Vault": "ボールトを作成",
  "Profit Share": "利益シェア",
  "Lockup Period (Days)": "ロックアップ期間（日数）",
  "Vault was successfully created and is being initialized":
    "ボールトが正常に作成され、初期化中です",
  "Failed to create a vault": "ボールトの作成に失敗しました",
  "Maximum TVL": "最大TVL",
  "No limit": "制限なし",
  "Vault Address": "ボールトアドレス",
  "Deposit to Vault successful": "ボールトへの入金成功",
  "Failed to deposit to Vault": "ボールトへの入金失敗",
  "Deposit to Vault": "ボールトに入金",
  "Vault": "ボールト",
  "Owner": "オーナー",
  "Age": "年齢",
  "Failed to load vaults": "ボールトの読み込みに失敗しました",
  "No vaults available": "利用可能なボールトがありません",
  "Performance": "パフォーマンス",
  "Vault Value": "ボールト価値",
  "My Deposit": "自分の入金",
  "Total P&L": "総損益",
  "Protocol Vaults": "プロトコルボールト",
  "User Vaults": "ユーザーボールト",
  "No Data": "データなし",
  "Last Month Return": "先月のリターン",
  "TVL": "TVL",
  "Total Earnings": "総収益",
  "About": "概要",
  "Vault Stats": "ボールト統計",
  "My Stats": "自分の統計",
  "Vault Contract": "ボールト契約",
  "Description": "説明",
  "Max Drawdown": "最大ドローダウン",
  "Volume": "取引量",
  "All-Time P&L": "全期間損益",
  "Days": "日数",
  "Lockup Period": "ロックアップ期間",
  "Search Vaults": "ボールトを検索",
  "Blog": "ブログ",
  "Block Explorer": "ブロックエクスプローラー",
  "Close Vault": "ボールトを閉じる",
  "Close Vault successful": "ボールトの閉鎖成功",
  "You must close all open positions to close your Vault. Closed vaults cannot be reopened.":
    "ボールトを閉じるには、すべてのオープンポジションを閉じる必要があります。閉じたボールトは再開できません。",
  "Close My Vault": "マイボールトを閉じる",
  "Failed to Close the Vault": "ボールトの閉鎖に失敗しました",
  "Only the Main Account can manage the vault":
    "メインアカウントのみがボールトを管理できます",
  "Allocate Collateral": "担保を割り当てる",
  "Switch to Main Account": "メインアカウントに切り替える",
  "Switch to Account": "アカウントに切り替える",
  "You are currently trading in the {{market}} market.":
    "現在、{{market}}市場で取引しています。",
  "To start trading on Isolated Market you must Allocate Collateral from your Main Account and switch to this Market Account.":
    "アイソレーテッド・マーケットで取引を開始するには、メインアカウントから担保を割り当て、このマーケットアカウントに切り替える必要があります。",
  "To trade in this market, switch to the Main Account first.":
    "この市場で取引するには、まずメインアカウントに切り替えてください。",
  "This account can only trade in the {{market}} market.":
    "このアカウントは{{market}}市場でのみ取引できます。",
  "Isolated Market": "アイソレーテッド・マーケット",
  "This feature currently is still in Beta. Backup your Paradex Private Key for additional safety.":
    "この機能は現在ベータ版です。追加の安全のためにParadexプライベートキーをバックアップしてください。",
  "Deposit failed": "入金に失敗しました",
  "Deposit in progress": "入金中",
  "External Account": "外部アカウント",
  "Tx Hashes": "トランザクションハッシュ",
  "Continue to {{bridge}}": "{{bridge}}に進む",
  "By clicking Continue to {{bridge}}, you will be sent to {{bridge}} website to bridge your funds out of Paradex.":
    "{{bridge}}に進むをクリックすると、{{bridge}}のウェブサイトに送られ、資金をParadexから移動します。",
  "By clicking Continue to {{bridge}}, you will be sent to {{bridge}} website to bridge your funds to Paradex.":
    "{{bridge}}に進むをクリックすると、{{bridge}}のウェブサイトに送られ、資金をParadexに移動します。",
  "Refer": "参照",
  "Address": "住所",
  "Date Joined": "参加日",
  "Referral Code": "紹介コード",
  "Volume Traded": "取引量",
  "Bridge": "ブリッジ",
  "Withdraw to": "引き出し先",
  "Deposit from": "からの入金",
  "Position is already closed": "ポジションはすでに閉じています",
  "Your Paradex L2 Address is NOT a Public Starknet Address. If you try to send funds to this address on Public Starknet via a cross chain bridge you risk losing your funds. <1>Read more</1>.":
    "あなたのParadex L2アドレスは公開Starknetアドレスではありません。クロスチェーンブリッジを介してこのアドレスに公開Starknetで資金を送信しようとすると、資金を失うリスクがあります。<1>もっと読む</1>。",
  "{{chain}} Address": "{{chain}}アドレス",
  "Copy Paradex Private Key": "Paradexプライベートキーをコピー",
  "Your account will be liquidated if Margin Ratio reaches 100%":
    "マージン比率が100%に達すると、アカウントが清算されます",
  "Liquidation": "清算",
  "Fee": "手数料",
  "Maker": "メーカー",
  "Maker Score": "メーカースコア",
  "Maker XP Share": "メーカーXPシェア",
  "Fee XP Share": "手数料XPシェア",
  "Fee XP": "手数料XP",
  "Maker XP": "メーカーXP",
  "Liquidation {{side}} Fill": "清算{{side}}埋め",
  "Settings": "設定",
  "Market Score": "マーケットスコア",
  "Season {{season}} (coming soon)": "シーズン{{season}}（近日公開）",
  "Confirm Buy": "購入を確認",
  "Confirm Sell": "売却を確認",
  "Other XP": "その他のXP",
  "Estimated Receivable Amount": "見積もり受取可能額",
  "Socialized Loss active. Click to learn more.":
    "ソーシャライズドロスが有効です。詳細はクリックしてください。",
  "Analytics": "アナリティクス",
  "Sign In to see your account XP stats":
    "アカウントのXP統計を見るにはサインインしてください",
  "<1>Clock Is Out Of Sync</1> The clock on your device is out of sync. This may cause issues with the application. Please make sure your device clock is set to be auto. For more details please <2>see documentation</2>":
    "<1>クロックが同期していません</1> あなたのデバイスのクロックが同期していません。これはアプリケーションに問題を引き起こす可能性があります。デバイスのクロックが自動に設定されていることを確認してください。詳細については、<2>ドキュメント</2>をご覧ください",
  "Failed to fetch max withdrawable amount": "最大出金金額の取得に失敗しました",
  "It is not possible to withdraw more than your Max Withdrawable Amount.":
    "最大出金金額を超える出金はできません。",
  "Max Withdrawable Amount": "最大出金金額",
  "XP Explainer": "ポイント説明",
  "Pool": "ティア",
  "Quote Quality": "クオート品質",
  "Create Your Public Username": "公開ユーザー名を作成する",
  "Edit Your Public Username": "公開ユーザー名を編集する",
  "You have been logged out.": "ログアウトしました。",
  "Maker Volume Score": "メーカーボリュームスコア",
  "Fee Score": "手数料スコア",
  "Score Share": "スコアシェア",
  "Instrument Share": "インストルメントシェア",
  "XP": "XP",
  "Total XP": "総XP",
  "24h Change": "24時間の変動",
  "Leaderboard": "リーダーボード",
  "Cancel": "キャンセル",
  "Portfolio": "ポートフォリオ",
  "Trade": "取引",
  "Build": "ビルド",
  "Referrals Explainer": "紹介説明",
  "How It Works": "仕組み",
  "My Referrals": "私の紹介",
  "Your Referral Code": "紹介コード",
  "Affiliate Program": "アフィリエイトプログラム",
  "Enrolled": "登録済み",
  "Traders Referred": "紹介されたトレーダー",
  "Referral Rewards": "紹介報酬",
  "Referral XP": "紹介XP",
  "Invite Friends": "友達を招待",
  "Share": "共有",
  "Earn": "収益",
  "Give": "与える",
  "Share your code to invite friends to join Paradex":
    "友達を招待するためにコードを共有する",
  "<1>{{amount}}</1> Fees": "手数料<1>{{amount}}</1>",
  "You receive {{amount}} of the referred user's fees":
    "紹介されたユーザーの手数料の{{amount}}を受け取ります",
  "<1>{{amount}}</1> XP": "XP<1>{{amount}}</1>",
  "You receive {{amount}} of the referred user's XP":
    "紹介されたユーザーのXPの{{amount}}を受け取ります",
  "<1>{{amount}}</1> Discount": "<1>{{amount}}</1>割引",
  "Your friend receives {{amount}} discount on fees":
    "友達は手数料の<割引を受け取りま{{amount}}割引を受け取ります",
  "Create and share your custom referral code":
    "カスタム紹介コードを作成して共有する",
  "If you have a strong social media following (<1>{{followers}}+</1>), you may be eligible for our <2>VIP Affiliate Program</2> with higher commissions and XP.":
    "強力なソーシャルメディアフォロワーが<1>{{followers}}+</1>いる場合、私たちの<2>VIPアフィリエイトプログラム</2>に参加する資格があるかもしれません。",
  "You can track our <1>Affiliate Leaderboard</1> to see how you stack up.":
    "あなたのパフォーマンスを見るには、<1>アフィリエイトリーダーボード</1>をチェックしてください。",
  "Apply": "適用する",
  "Log in to see your Referral History.": "ログインして紹介履歴を表示します。",
  "Welcome to Paradex": "Paradexへようこそ",
  "Congrats! The referral code you used is now active! It means you can start enjoying a discount on your trading fees!":
    "おめでとうございます！使用した紹介コードが有効になりました！これは、取引手数料の割引を楽しむことができることを意味します！",
  "Congrats! The referral code <1>{{codeProvided}}</1> used is now active!":
    "おめでとうございます！使用した紹介コード<1>{{codeProvided}}</1>が有効になりました！",
  "Referral code not activated. The code can only be applied once during the initial onboarding.":
    "紹介コードが有効になっていません。コードは初回のオンボーディング時に1回だけ適用できます。",
  "Referral code <1>{{codeProvided}}</1> not activated. You have already onboarded with <1>{{referredBy}}</1>.":
    "紹介コード<1>{{codeProvided}}</1>が有効になっていません。すでに<1>{{referredBy}}</1>でオンボードしています。",
  "Referral Code Applied": "紹介コードが適用されました",
  "You will receive <1>{{userAmount}}</1> of referred user's <1>fees</1> <2>and</2> <1>{{userAmountPoints}} XP</1>. They will receive a <1>{{friendAmount}}</1> discount.":
    "あなたは紹介されたユーザーの<1>手数料</1>の<1>{{userAmount}}</1>と<1>XP</1>の<1>{{userAmountPoints}}</1>を受け取ります。彼らは<1>{{friendAmount}}</1>の割引を受け取ります。",
  "Community": "コミュニティ",
  "Environments": "環境",
  "Language": "言語",
  "System Status": "システムステータス",
  "Docs": "ドキュメント",
  "Code Samples": "コードサンプル",
  "More": "もっと",
  "Stats": "統計",
  "Join Discord": "Discordに参加",
  "Notifications": "通知",
  "All caught up!": "全て最新の状態です！",
  "You have no new notifications": "新しい通知はありません",
  "Connect Wallet": "ウォレットを接続",
  "WalletConnect is taking too long to open. Please refresh the page and try again.":
    "WalletConnectのオープンに時間がかかりすぎています。ページを更新してもう一度お試しください。",
  "Unexpected error opening WalletConnect modal. Please refresh the page and try again.":
    "WalletConnectモーダルのオープン中に予期しないエラーが発生しました。ページを更新してもう一度お試しください。",
  "Connect your Wallet": "あなたのウォレットを接続",
  "Link Wallet": "ウォレットをリンク",
  "You will receive a signature request. Signing is free and will not send a transaction.":
    "署名リクエストを受け取ります。署名は無料で、トランザクションは送信されません。",
  "Generate Paradex Chain wallet": "Paradexチェーンウォレットを生成",
  "Generating Paradex Chain wallet": "Paradexチェーンウォレットを生成中",
  "Paradex Chain wallet generated": "Paradexチェーンウォレットが生成されました",
  "Verify that you own this wallet": "このウォレットの所有を確認する",
  "Remember Me": "私を覚えておいて",
  "Only use 'Remember Me' if you are using a secure device you own. Selecting this option can expose your information to  others if you are accessing this wallet from a public device.":
    "「私を覚えておいて」は、自分が所有する安全なデバイスを使用している場合にのみ使用してください。このオプションを選択すると、公共のデバイスからこのウォレットにアクセスしている場合、他の人に情報が公開される可能性があります。",
  "Continue": "続ける",
  "Disconnect Wallet": "ウォレットの接続を解除",
  "Disconnect": "切断",
  "Account": "アカウント",
  "Username": "ユーザー名",
  "Edit": "編集",
  "Your username is now hidden. It is not visible to other users.":
    "あなたのユーザー名は現在非表示です。他のユーザーには表示されません。",
  "Your username will be visible to other users unless you choose to hide it. You can always change it later.":
    "ユーザー名は、非表示に設定しない限り他のユーザーに表示されます。後でいつでも変更することができます。",
  "Hide my Username": "ユーザー名を隠す",
  "Save": "保存",
  "Username is required": "ユーザー名は必須です",
  "Username must be between 5 and 20 characters":
    "ユーザー名は5文字から20文字の間でなければなりません",
  "Username can only contain letters and numbers":
    "ユーザー名は文字と数字のみ含めることができます",
  "Wallets": "ウォレット",
  "Paradex Block Explorer": "Paradexブロックエクスプローラ",
  "Log out": "サインアウト",
  "No markets available": "利用可能な市場はありません",
  "Last Price": "最終価格",
  "Open Interest": "未決済の注文",
  "24h Volume": "24時間の取引量",
  "Mark Price": "マーク価格",
  "Spot Price": "現物価格",
  "Eight hour funding rate that is continuously accrued on positions and settled upon any trade. If the funding rate is positive, longs pay shorts. If negative, shorts pay longs.":
    "ポジションに連続して蓄積され、取引時に決済される8時間の資金調達率。資金調達率がプラスの場合、ロングはショートに支払います。マイナスの場合、ショートはロングに支払います。",
  "8h Funding": "8時間の資金調達",
  "Limit": "制限",
  "Market": "市場",
  "Stop Limit": "ストップリミット",
  "Stop Market": "ストップマーケット",
  "Scaled Order": "スケールオーダー",
  "Trigger Price": "トリガープライス",
  "Input Price": "入力価格",
  "Position Mark Price": "ポジションのマーク価格",
  "Amount": "数量",
  "Reduce Only": "減少のみ",
  "Reduce Only will increase position":
    "リデュースオンリーはポジションを増やします",
  "Your order stays open until it is filled or you decide to cancel.":
    "注文は、埋められるかキャンセルするまで開いたままです。",
  "Your order gets filled right away, partially or fully, and whatever is left gets canceled instantly.":
    "注文はすぐに部分的または完全に埋められ、残った部分は即座にキャンセルされます。",
  "Conditional limit order that serves to add liquidity to the order book as a Maker order.":
    "メーカーオーダーとして注文ブックに流動性を追加するための条件付きリミットオーダー。",
  "Currently the fees are fixed and uniform across the platform. Maker {{makerFee}} / Taker {{takerFee}}":
    "現在、手数料はプラットフォーム全体で固定かつ一様です。メーカー {{makerFee}} / テイカー {{takerFee}}",
  "Value": "価値",
  "Buy": "購入",
  "BUY": "購入",
  "LONG": "ロング",
  "Sell": "売却",
  "SELL": "売却",
  "SHORT": "ショート",
  "Order Value": "注文の価値",
  "Current Position": "現在のポジション",
  "Account Value": "口座の価値",
  "The total value of your account at current mark prices":
    "現在のマーク価格でのあなたの口座の総価値",
  "Unrealized P&L": "未実現損益",
  "Free Collateral": "自由担保",
  "Account Leverage": "口座のレバレッジ",
  "Margin": "マージン",
  "Margin Ratio": "証拠金比率",
  "Initial Margin": "初期マージン",
  "Maintenance Margin": "維持マージン",
  "Order Book": "注文簿",
  "Trades": "取引",
  "Price": "価格",
  "Size": "サイズ",
  "Total": "合計",
  "Time": "時間",
  "Spread": "スプレッド",
  "Positions": "ポジション",
  "Open Orders": "未決済の注文",
  "Trade History": "取引履歴",
  "Order History": "注文履歴",
  "Funding Payments": "資金調達の支払い",
  "Side": "サイド",
  "Entry Price": "エントリー価格",
  "Liquidation Price": "清算価格",
  "Unrealized Funding": "未実現の資金調達",
  "Close Position": "ポジションを閉じる",
  "Market Close": "市場クローズ",
  "Close Long": "ロングをクローズ",
  "Close Short": "ショートをクローズ",
  "Type": "タイプ",
  "Filled": "埋まった",
  "Triggered": "トリガーされました",
  "Instruction": "指示",
  "Client Order Id": "クライアントの注文ID",
  "Status": "ステータス",
  "NEW": "新規",
  "OPEN": "オープン",
  "UNTRIGGERED": "トリガーされていません",
  "CLOSED": "クローズド",
  "Pending": "保留中",
  "Complete": "完了",
  "Completed": "完了",
  "Failed": "失敗",
  "Bust": "バスト",
  "Value / Fee": "価値 / 手数料",
  "Liquidity": "流動性",
  "Tx Hash": "Txハッシュ",
  "Trade Id": "取引ID",
  "Remaining": "残り",
  "Cancel Reason": "キャンセルの理由",
  "User Canceled": "ユーザーによるキャンセル",
  "Not Enough Margin": "マージンが不足",
  "Empty Market": "市場が空",
  "Post Only": "ポストのみ",
  "Post Only Would Cross": "ポストのみがクロスします",
  "Remaining IOC Cancel": "残りのIOCキャンセル",
  "Unexpected Failure": "予期しない失敗",
  "Deleverage": "デレバレッジ",
  "In Liquidation": "清算中",
  "Self Trade": "自己取引",
  "Asset Unavailable": "資産利用不可",
  "Asset Expired": "資産の有効期限切れ",
  "Order Type Invalid": "注文タイプが無効",
  "Price Not Available": "価格が利用できません",
  "Expired": "期限切れ",
  "Exceeds Max Slippage": "最大スリッページを超えました",
  "Timeout": "タイムアウト",
  "Order Exceeds Position Limit": "注文がポジション限度を超えています",
  "Order Id": "注文ID",
  "Payment": "支払い",
  "Cancel All": "すべてキャンセル",
  "{{orderSize}} will be closed at Market price":
    "{{orderSize}}は市場価格でクローズされます",
  "By connecting a wallet, you agree to <1>Paradex Terms of Service</1> and represent and warrant to Paradex that you are not a <2>Restricted Person</2>.":
    "ウォレットを接続することで、あなたは<1>Paradexの利用規約</1>に同意し、Paradexに対して<2>制限された人物</2>ではないことを表明および保証します。",
  "Wallet": "ウォレット",
  "Deposit_verb": "預金する",
  "Deposit_noun": "預金",
  "Withdraw": "引き出す",
  "Withdrawal": "引き出し",
  "Transfers": "転送",
  "Transfer": "転送",
  "Collateral": "担保",
  "Asset": "資産",
  "Wallet Balance": "ウォレット残高",
  "Actions": "アクション",
  "Action": "アクション",
  "External Tx Hash": "外部Txハッシュ",
  "Withdraw to Wallet": "ウォレットへの引き出し",
  "Deposit to Paradex": "Paradexへの預け入れ",
  "You have no balance in your Ethereum wallet. Deposit some {{asset}} and try again.":
    "あなたのEthereumウォレットに残高がありません。{{asset}}を預け入れて再試行してください。",
  "It is not possible to deposit more than your current balance.":
    "現在の残高以上を預け入れることはできません。",
  "Available": "利用可能",
  "Initiate a deposit from your Ethereum wallet to the Paradex bridge. These transactions":
    "EthereumウォレットからParadexブリッジへの預け入れを開始します。これらの取引",
  "Initiate a deposit from your Ethereum wallet to the Paradex bridge. These transactions may take several minutes depending on network conditions. For more information check out our <1>docs</1>.":
    "EthereumウォレットからParadexブリッジへの預け入れを開始します。これらの取引は、ネットワークの状況に応じて数分かかる場合があります。詳細については、<1>ドキュメント</1>をご覧ください。",
  "read more": "もっと読む",
  "Initiate Deposit to Bridge": "ブリッジへの預け入れを開始",
  "Enable USDC on Paradex": "ParadexでUSDCを有効にする",
  "In order to deposit you must allow Paradex access to at least {{amount}}.":
    "預け入れるためには、Paradexが少なくとも{{amount}}にアクセスできるように許可する必要があります。",
  "Enable USDC": "USDCを有効にする",
  "Withdraw initiated": "引き出しが開始されました",
  "Withdrawals are a two-step process. First initiate a withdrawal from Paradex":
    "引き出しは2ステップのプロセスです。まず、Paradexからの引き出しを開始します",
  "Withdrawals are a two-step process. First initiate a withdrawal from Paradex to the Paradex Bridge. This first step can take 5-16 hours (10 on average). Second, initiate your withdrawal from the Paradex Bridge to your Ethereum wallet via the Deposits / Withdrawals table. For more information check out our <1>docs</1>.":
    "引き出しは2ステップのプロセスです。まず、ParadexからParadexブリッジへの引き出しを開始します。この最初のステップには5〜16時間かかることがあります (平均10)。次に、ParadexブリッジからあなたのEthereumウォレットへの引き出しを、預け入れ/引き出し表を通じて開始します。詳細は<1>ドキュメント</1>をご覧ください。",
  "Initiate Withdrawal to Bridge": "ブリッジへの引き出しを開始",
  "You have no funds to withdraw. It is not possible to withdraw more than your free collateral.":
    "引き出す資金がありません。自由担保以上を引き出すことはできません。",
  "It is not possible to withdraw more than your free collateral.":
    "自由担保以上を引き出すことはできません。",
  "You have no collateral": "担保がありません",
  "You have no open positions": "オープンポジションがありません",
  "You have no open orders": "オープンオーダーがありません",
  "You have no trades": "取引がありません",
  "You have no orders": "注文がありません",
  "You have no funding payments": "資金調達の支払いがありません",
  "You have no transfers": "転送がありません",
  "Account in liquidation": "アカウントが清算中",
  "Account is being liquidated. Any open positions will be reduced. Trading now is disabled.":
    "アカウントが清算中です。オープンポジションはすべて減少します。現在、取引は無効化されています。",
  "Account liquidated": "アカウントが清算されました",
  "Your account was liquidated on {{createdAt}}. Any open positions were reduced.":
    "あなたのアカウントは{{createdAt}}に清算されました。全てのオープンポジションは減少しました。",
  "Deposit to Paradex successful": "Paradexへの預け入れが成功しました",
  "Deposit to Paradex failed": "Paradexへの預け入れに失敗しました",
  "Unexpected error. Try again.":
    "予期しないエラーが発生しました。再試行してください。",
  "Deposit initiated": "預け入れが開始されました",
  "Deposit to bridge initiated": "ブリッジへの預け入れが開始されました",
  "Deposit to Paradex available": "Paradexへの預け入れが可能です",
  "Go to Deposits": "預け入れに進む",
  "Deposit to Paradex in progress": "Paradexへの預け入れが進行中です",
  "Transfer successful": "転送が成功しました",
  "Limit {{side}} Order Fill": "制限{{side}}注文埋め",
  "{{type}} {{side}} Order Submitted": "{{type}} {{side}}注文が提出されました",
  "{{type}} {{side}} Order Canceled":
    "{{type}} {{side}}注文がキャンセルされました",
  "Reason": "理由",
  "Market {{side}} Order Fill": "市場{{side}}注文埋め",
  "Trade Busted On-Chain": "オンチェーンで取引が破綻しました",
  "Fill id": "埋めID",
  "Withdraw to wallet failed": "ウォレットへの引き出しに失敗しました",
  "Withdrawal to bridge initiated": "ブリッジへの引き出しが開始されました",
  "Withdrawal error. Try again.": "引き出しエラー。再試行してください。",
  "Withdrawal to bridge in progress": "ブリッジへの引き出しが進行中です",
  "Withdrawal to wallet initiated": "ウォレットへの引き出しが開始されました",
  "Withdrawal to bridge failed": "ブリッジへの引き出しに失敗しました",
  "Transaction error. Try again.": "取引エラー。再試行してください。",
  "Withdrawal to wallet available": "ウォレットへの引き出しが可能です",
  "Go to Withdrawals": "引き出しに進む",
  "Withdrawal to wallet successful": "ウォレットへの引き出しが成功しました",
  "Session expired, please log in again.":
    "セッションが期限切れです、再度ログインしてください。",
  "Failed to submit order": "注文の提出に失敗しました",
  "Your username preferences were updated!":
    "ユーザー名の設定が更新されました！",
  "Unexpected error updating username preferences":
    "ユーザー名の設定更新中に予期しないエラーが発生しました",
  "This username already exists. Please choose a different username and try again.":
    "このユーザー名は既に存在します。別のユーザー名を選択して再試行してください。",
  "Validation Error": "検証エラー",
  "Binding Error": "バインディングエラー",
  "Internal Error": "内部エラー",
  "Not Found": "見つかりません",
  "Service Unavailable": "サービス利用不可",
  "Invalid Request Parameter": "無効なリクエストパラメータ",
  "Order Id Not Found": "注文IDが見つかりません",
  "Order Is Closed": "注文は閉じられています",
  "Order Is Not Open Yet": "注文はまだ開かれていません",
  "Client Order Id Not Found": "クライアントの注文IDが見つかりません",
  "Duplicated Client Id": "重複したクライアントID",
  "Invalid Price Precision": "無効な価格精度",
  "Invalid Token": "無効なトークン",
  "Invalid Ethereum Address": "無効なEthereumアドレス",
  "Blocked Ethereum Address": "ブロックされたEthereumアドレス",
  "Invalid Ethereum Signature": "無効なEthereum署名",
  "Invalid Starknet Address": "無効なStarknetアドレス",
  "Invalid Starknet Signature": "無効なStarknet署名",
  "Starknet Signature Verification Failed": "Starknet署名の検証に失敗しました",
  "Bad Starknet Request": "不正なStarknetリクエスト",
  "Ethereum Signer Mismatch": "Ethereum署名者の不一致",
  "Ethereum Hash Mismatch": "Ethereumハッシュの不一致",
  "Not Onboarded": "オンボーディングされていません",
  "Invalid Timestamp": "無効なタイムスタンプ",
  "Invalid Signature Expiration": "無効な署名の有効期限",
  "Account Not Found": "アカウントが見つかりません",
  "Invalid Order Signature": "無効な注文署名",
  "Public Key Invalid": "公開鍵が無効",
  "Unauthorized Ethereum Address": "許可されていないEthereumアドレス",
  "Ethereum Address Already Onboarded":
    "既にオンボーディングされているEthereumアドレス",
  "Market Not Found": "マーケットが見つかりません",
  "Allowlist Entry Not Found": "許可リストのエントリが見つかりません",
  "Username In Use": "ユーザー名が既に使用されています",
  "Service access restricted in your region":
    "あなたの地域ではサービスのアクセスが制限されています",
  "No Access Allowed": "アクセスは許可されていません",
  "You do not have access to the platform. Please join our Discord to get access.":
    "あなたはプラットフォームへのアクセス権を持っていません。アクセスを得るためには、私たちのDiscordに参加してください。",
  "{{actualChainName}} Wallet Connected": "Ethereumウォレットが接続されました",
  'Incorrect network selected "{{actualChainName}}". Please change the network to "{{requiredChainName}}" (id={{requiredChainId}}) in your wallet and retry.':
    '選択されたネットワーク"{{actualChainName}}"が正しくありません。ウォレットでネットワークを"{{requiredChainName}}"（id={{requiredChainId}}）に変更して、再試行してください。',
  "Choose Wallet": "ウォレットを選択",
  "Starknet Wallet": "Starknetウォレット",
  "Switch to {{env}}": "{{env}}に切り替える",
  "Search": "検索",
  "League": "リーグ",
  "Switch Account": "アカウントを切り替える",
  "Rank": "ランク",
  "24h XP": "24時間のXP",
  "XP Per Day": "1日あたりのXP",
  "Season Volume": "シーズンボリューム",
  "Lifetime Volume": "生涯のボリューム",
  "Pre-Season": "プレシーズン",
  "Earn More XP": "もっとXPを稼ぐ",
  "XP Boost": "XPブースト",
  "XP Drop": "XPドロップ",
  "Trader": "トレーダー",
  "Total Share": "総シェア",
  "Maker Share": "メーカーシェア",
  "Maker Volume Share": "メーカーボリュームシェア",
  "Fee Share": "手数料シェア",
  "Fee Volume Share": "手数料ボリュームシェア",
  "No data available": "データがありません",
  "Refer & Earn": "紹介して稼ぐ",
  "Hours": "時間",
  "Minutes": "分",
  "Time/Duration": "時間/期間",
  "Runtime": "実行時間",
  "Frequency": "頻度",
  "Number of Orders": "注文数",
  "Runtime should not be empty": "実行時間は空にしてはいけません",
  "Runtime should be less than or equal to 24 hours":
    "実行時間は24時間以下である必要があります",
  "Inactive": "非アクティブ",
  "Avg. Price": "平均価格",
  "The minimum required amount for {{numOrders}} orders is {{totalSize}} {{currency}}.":
    "{{numOrders}}件の注文に必要な最低金額は{{totalSize}} {{currency}}です。",
  "Take Profit / Stop Loss: Set take profit and stop loss price levels to automate trade actions. When the Mark Price will reach the price you set, it will send your order to the matching engine.":
    "利食い／損切り：取引アクションを自動化するために、利食いと損切りの価格レベルを設定します。基準価格が設定した価格に達すると、注文がマッチングエンジンに送信されます。",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount with an estimated profit of <3 />.":
    "マーク価格が<1 />に達すると、<2 />注文がトリガーされ、選択した量が推定利益<3 />で決済されます。",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount with an estimated loss of <3 />.":
    "マーク価格が<1 />に達すると、<2 />注文がトリガーされ、選択した量が推定損失<3 />で決済されます。",
  "Edit TP/SL For This Position": "このポジションのTP/SLを編集",
  "Take Profit": "テイクプロフィット",
  "Profit": "利益",
  "Stop Loss": "ストップロス",
  "Loss": "損失",
  "Confirm": "確認",
  "Take Profit Limit": "テイクプロフィット指値",
  "Take Profit Market": "テイクプロフィット成行",
  "Stop Loss Limit": "ストップロス指値",
  "Stop Loss Market": "ストップロス成行",
  "Funding": "資金調達",
  "Unwinding": "解消",
  "Cancel TP/SL orders": "TP/SL注文をキャンセル",
  "TP/SL orders submitted successfully.": "TP/SL注文が成功しました。",
  "Pre-Season + All Seasons": "プレシーズン + 全シーズン",
  "Season {{season}}": "シーズン {{season}}",
  "XP Season {{season}}": "XPシーズン {{season}}",
  "Slippage": "スリッページ",
  "Max Slippage": "最大スリッページ",
  "Max slippage defines the largest acceptable price deviation from the mark price.":
    "最大スリッページは、基準価格から許容できる最大の価格偏差を定義します。",
  "During execution, the order can be canceled if the price exceeds the slippage limit or falls outside the Market <1>Price Band</1>.":
    "実行中に価格がスリッページ制限を超えるか、マーケットの<1>価格帯</1>の外に出た場合、注文はキャンセルされる可能性があります。",
  "Update Max Slippage": "最大スリッページを更新",
  "The Maximum Slippage allowed for {{symbol}} is {{price_band_width}}.":
    "{{symbol}}の許容最大スリッページは{{price_band_width}}です。",
  "Mark IV": "マークIV",
  "Delta": "デルタ",
  "Failed to load positions": "ポジションの読み込みに失敗しました",
  "No open positions": "開いているポジションはありません",
  "learn more": "もっと詳しく",
  "Learn More": "もっと詳しく",
  "Search Markets": "市場を検索",
  "Futures": "先物",
  "Options": "オプション",
  "OI": "未決済約定",
  "My Profile": "マイプロフィール",
  "Addresses": "アドレス",
  "Chart": "チャート",
  "ROI": "ROI",
  "Sharpe": "シャープ",
  "Max Loss": "最大損失",
  "Total Volume": "総ボリューム",
  "My NFTs": "マイNFT",
  "My XP": "マイXP",
  "Balances": "残高",
  "Other": "その他",
  "Paradex XP: Pre-Season": "Paradex XP: プレシーズン",
  "Paradex XP: Season {{season}}": "Paradex XP: シーズン {{season}}",
  "Season {{season}} XP": "シーズン {{season}} XP",
  "Refer your friends to earn 10%": "友人を紹介して10%を稼ぐ",
  "Refer Friends and Earn 10%": "友人を紹介して10%を稼ぐ",
  "Invest in Paradex Vault & Earn 3X Boost":
    "Paradex Vaultに投資して3Xブーストを稼ぐ",
  "Avg. Daily XP": "平均1日あたりのXP",
  "Lifetime XP": "生涯XP",
  "My Rank": "ランク",
  "What is XP?": "XPとは？",
  "XP History": "XP履歴",
  "Last Week XP": "先週のXP",
  "TVL XP": "TVL XP",
  "OI XP": "OI XP",
  "Liquidation XP": "Liquidation XP",
  "Vaults XP": "Vaults XP",
  "Week": "週",
  "Season": "シーズン",
  "Last updated: {{timestamp}}": "最終更新: {{timestamp}}",
  "Xperience Points (XP)": "経験点 (XP)",
  "Log in to see your XP balance": "ログインしてXP残高を確認",
  "Log in to see where you stand": "ログインしてどこにいるか確認",
  "Connect your X account to join Pro League":
    "Xアカウントを接続してプロリーグに参加",
  "Avg. Fill Price": "平均約定価格",
  "Spot": "スポット",
  "Derivatives": "デリバティブ",
  "P&L Chart": "P&Lチャート",
  "Account Value Chart": "アカウント価値チャート",
  "Funding History": "資金調達履歴",
  "Manage Funds": "資金管理",
  "Profile": "プロフィール",
  "Community XP": "コミュニティXP",
  "XP Share": "XPシェア",
  "Next XP Drop in": "次のXPドロップまで",
  "In Progress": "進行中",
  "{{field}} must be greater than {{value}}":
    "{{field}}は{{value}}よりも大きくなければなりません",
  "{{field}} must be less than {{value}}":
    "{{field}}は{{value}}よりも小さくなければなりません",
  "Enter a valid Take Profit or Stop Loss price":
    "有効な利確または損切り価格を入力してください",
  "Take Profit and Stop Loss prices cannot be the same":
    "利確価格と損切り価格は同じにできません",
  "Breakeven Price": "損益分岐点価格",
  "Automatic Withdrawal": "自動引き出し",
  "Estimated Gas Fee": "推定ガス料金",
  "Auto Withdrawal": "自動引き出し",
  "Withdrawing...": "引き出しが進行中...",
  "Paradex Bridge withdrawals can take between 5-16 hours to complete":
    "Paradex Bridgeの引き出しは5-16時間かかる場合があります",
  "Socialized Loss": "ソシアライズド損失",
  "Receivable Amount": "受け取り可能額",
  "Auto withdrawing...": "自動引き出しが進行中...",
  "Manage Twitter Connection": "Twitter接続を管理",
  "Unlock additional functionality.": "追加機能をアンロック。",
  "Please note this connection can't be used as a login method to Paradex.":
    "この接続はParadexのログイン方法として使用できないことに注意してください。",
  "Toast Notifications": "トースト通知",
  "Display toast notifications": "トースト通知を表示",
  "Average Entry Price": "平均入金価格",
  "Display average entry price line on chart":
    "平均入金価格ラインをチャートに表示",
  "Display liquidation price line on chart": "決済価格ラインをチャートに表示",
  "Display open order lines on chart": "オープンオーダーラインをチャートに表示",
  "Failed to disconnect Twitter": "Twitterの切断に失敗しました",
  "Failed to authorize Twitter": "Twitterの認可に失敗しました",
  "Twitter account linked successfully!":
    "Twitterアカウントのリンクに成功しました!",
  "Failed to link twitter account": "Twitterアカウントのリンクに失敗しました",
  "Fees": "手数料",
  "Taker pays {{takerFee}} in fees. Maker receives {{makerRebate}} in rebates.":
    "テイカーは{{takerFee}}の手数料を支払い、メーカーは{{makerRebate}}のリベートを受け取ります。",
  "Manage Discord Connection": "Discord接続を管理",
  "Failed to authorize Discord": "Discordの認可に失敗しました",
  "Discord account linked successfully!":
    "Discordアカウントのリンクに成功しました!",
  "Failed to link Discord account": "Discordアカウントのリンクに失敗しました",
  "Failed to disconnect Discord": "Discordの切断に失敗しました",
  "Connect": "接続",
  "Unrealized P&L from current vault token balance. This doesn't account for owner profit share or slippage from positions unwind.":
    "現在のバレットトークンの残高からの未実現P&L。これは所有者の利益分配やポジションの解消によるスリッページを考慮していません。",
  "Show P&L Amount": "P&Lを表示",
  "Receivable amount must be positive":
    "受け取り可能な金額は正でなければなりません",
  "Wallet Address Change Detected": "ウォレットアドレスの変更が検出されました",
  "You can proceed with connecting your Paradex account to this wallet address or continue using your current connection.":
    "このウォレットアドレスにParadexアカウントを接続するか、現在の接続を引き続き使用することができます。",
  "Connect To New Wallet": "新しいウォレットに接続",
  "Keep Using Current Wallet": "現在のウォレットを使用する",
  "Connect Current Wallet": "現在のウォレットを接続",
  "The account is not connected. Please make sure that <1>{{walletAddress}}</1> is connected and is the active account in your <2>{{walletName}}</2> wallet.":
    "アカウントが接続されていません。<1>{{walletAddress}}</1>が接続されており、<2>{{walletName}}</2>ウォレットでアクティブなアカウントであることを確認してください。",
  "Reconnecting": "再接続中",
  "There is a request in progress. Please proceed in your wallet.":
    "進行中のリクエストがあります。ウォレットで続行してください。",
  "Modify Order": "注文を変更",
  "Realized P&L": "実現損益",
  "Close All": "すべて閉じる",
  "Close All Positions": "すべてのポジションを閉じる",
  "Market Close All Positions": "マーケットがすべてのポジションを閉じる",
  "Close {{percent}}% of All Positions":
    "すべてのポジションを{{percent}}%閉じる",
  "Failed to close all positions":
    "すべてのポジションを閉じることができませんでした",
  "Number of Positions": "ポジション数",
  "Markets": "マーケット",
  "Position Value (Closing)": "ポジション価値（決済）",
  "{{count}} positions will be rounded to the nearest order increment":
    "{{count}}ポジションは最も近い注文増分に丸められます",
  "You will earn Bonus XP on your referral activity":
    "紹介活動によりボーナスXPを獲得できます",
  "Token Price": "トークン価格",
  "Vault Token Price Chart": "ガレットトークン価格チャート",
  "Market Close Orders Submitted": "マーケットがすべての注文を閉じる",
  "This profile is already linked to a different Paradex account. Disconnect socials from existing account and try again.":
    "このプロフィールはすでに別のParadexアカウントにリンクされています。現在のアカウントからSNSを解除して、もう一度お試しください。",
  "XP Drops every Friday": "XPは毎週金曜日に降ります",
  "No XP Data Available": "XPデータは利用できません",
  "XP data is available only for the Main Account.":
    "XPデータはメインアカウントのみ利用できます。",
  "Please switch to your Main Account to view your XP.":
    "メインアカウントに切り替えてXPを確認してください。",
  "Referrals Not Available": "紹介は利用できません",
  "Referrals are available only for the Main Account.":
    "紹介はメインアカウントのみ利用できます。",
  "Please switch to your Main Account to view your Referrals.":
    "メインアカウントに切り替えて紹介を確認してください。",
  "Deposit initiated via Layerswap": "Layerswap経由で預け入れが開始されました",
  "Position History": "ポジション履歴",
  "Avg. Close Price": "平均決済価格",
  "Max Size": "最大サイズ",
  "Closed Size": "決済済みサイズ",
  "Opened": "オープン",
  "You have no positions": "ポジションがありません",
  "Destination Address": "宛先アドレス",
  "Withdrawal via Layerswap initiated": "Layerswap経由で引き出しを開始しました",
  "Withdrawal via Layerswap failed": "Layerswap経由で引き出しに失敗しました",
  "Market Share": "マーケットシェア",
  "Tier": "階層",
  "Avg Maker Volume": "平均Makerボリューム",
  "Liquidity Score": "リキディティスコア",
  "Reward Share": "リワードシェア",
  "Enter amount": "金額を入力",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount at <3 /> with an estimated profit of <4 />.":
    "マーク価格が<1 />に達すると、<2 />注文が発動し、<3 />で選択した量をクローズし、推定利益は<4 />になります。",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount at <3 /> with an estimated loss of <4 />.":
    "マーク価格が<1 />に達すると、<2 />注文が発動し、<3 />で選択した量をクローズし、推定損失は<4 />になります。",
  "Limit Price": "指値価格",
  "Delete": "削除",
  "Use other bridges from the list above to withdraw funds much faster.":
    "上記のリストから他のブリッジを使用すると、より迅速に資金を引き出せます。",
  "Paradex Chain Monitor": "Paradexチェーンモニター",
  "OTM Amount": "OTM数量",
  "Leverage": "レバレッジ",
  "Strikes": "ストライク",
  "Strike": "ストライク",
  "Change": "変更",
  "Dist. to Spot": "スポットまでの距離",
  "Bid": "買値",
  "Mark": "マーク",
  "Ask": "売値",
  "IV": "IV",
  "Dist %": "距離 %",
  "Call": "コール",
  "Put": "プット",
  "Mark Price / IV": "マーク価格 / IV",
  "8h F-Funding": "8時間F-ファンディング",
  "No Strikes Available": "利用可能なストライクはありません",
  "Attributes": "属性",
  "Why no Expiries?": "期限がない理由は？",
  "{{multiplier}}x Boost": "{{multiplier}}倍ブースト",
  "Like perpetual futures, perpetual options have no expiration, offering continuous exposure to the underlying asset with built-in downside protection. This gives users the flexibility to hold their position indefinitely and decide the optimal time to close it based on their strategy":
    "無期限先物と同様に、無期限オプションには期限がなく、下落保護が組み込まれた原資産への継続的なエクスポージャーを提供します。これにより、ユーザーは無期限にポジションを保持し、戦略に基づいて最適なクローズタイミングを決定する柔軟性を得られます",
  "Implied Volatility used to calculate the option's Mark Price":
    "オプションのマーク価格を計算するために使用されるインプライドボラティリティ",
  "Distance between the Strike Price and the underlying Spot Price":
    "ストライク価格と原資産のスポット価格との距離",
  "Eight hour funding that is continuously accrued on positions and settled upon any trade. If the funding rate is positive, longs pay shorts. If negative, shorts pay longs":
    "ポジションに継続的に発生し、取引時に決済される8時間のファンディング。ファンディングレートがプラスの場合、ロングがショートに支払い、マイナスの場合、ショートがロングに支払います",
  "The Leverage Ratio (Lambda) measures an option's effective leverage by indicating how much the option's value changes, in percentage terms, for a 1% change in the spot price of the underlying asset. For example, if an option has a Lambda of 50 and the spot price increases by 1%, the option's value would increase by 50%":
    "レバレッジ比率（ラムダ）は、原資産のスポット価格が1%変動した場合のオプション価値の変動率を示すことで、オプションの実効レバレッジを測定します。例えば、オプションのラムダが50で、スポット価格が1%上昇した場合、オプション価値は50%上昇します",
  "Option delta measures how much the price of an option is expected to change for a $1 change in the price of the underlying asset. For example, a delta of 0.5 means the option price will move $0.50 for every $1 move in the underlying":
    "オプションデルタは、原資産価格が1ドル変動した場合のオプション価格の予想変動額を測定します。例えば、デルタが0.5の場合、原資産が1ドル動くごとにオプション価格は0.50ドル動きます",
  "Eight hour funding rate of the perpetual future on the same underlying asset":
    "同じ原資産の無期限先物の8時間ファンディングレート",
  "Having trouble? Try signing out and connecting again.":
    "問題が発生していますか？サインアウトして再接続してみてください。",
  "API Reference": "APIリファレンス",
  "XP Distribution occurs once per week on Friday at ~12 PM UTC":
    "XP配布は毎週金曜日のUTC午後12時頃に行われます",
  "What are Perpetual Options?": "無期限オプションとは？",
  "Delta represents the increase in option price based on a $1 increase in the underlying spot price.\n\nFor example, a delta of 0.50 means the price of the option is expected to increase by $0.50 for a $1 increase in the underlying spot price.":
    "デルタは、原資産のスポット価格が1ドル上昇した場合のオプション価格の上昇を表します。\n\n例えば、デルタが0.50の場合、原資産のスポット価格が1ドル上昇すると、オプション価格は0.50ドル上昇すると予想されます。",
  "Leverage(Lambda) represents the percentage change in option price for a 1% change in the underlying spot price.\n\nFor example, a lambda of 10 means the option price is expected to increase by 10% if the underlying spot price increases by 1%.":
    "レバレッジ（ラムダ）は、原資産のスポット価格が1%変動した場合のオプション価格の変動率を表します。\n\n例えば、ラムダが10の場合、原資産のスポット価格が1%上昇すると、オプション価格は10%上昇すると予想されます。",
  "Eight hour options funding rate that is continuously accrued on positions and settled upon any trade. The funding rate for perpetual options reflects both the cost of leverage and the cost of insurance against adverse price moves. \n\nThe funding period of perpetual options is 24 hours. This means that the option premium will be continuously paid out over a 24h period. 24h funding rate = 3 x 8h funding rate":
    "ポジションに継続的に発生し、取引時に決済される8時間のオプションファンディングレート。無期限オプションのファンディングレートは、レバレッジのコストと価格の不利な動きに対する保険のコストの両方を反映しています。\n\n無期限オプションのファンディング期間は24時間です。これは、オプションプレミアムが24時間の期間にわたって継続的に支払われることを意味します。24時間ファンディングレート = 3 x 8時間ファンディングレート",
  "Risk": "リスク",
  "Vega": "ベガ",
  "Gamma": "ガンマ",
  "Estimated Funding P&L for the next 8 hours based on current funding rates":
    "現在のファンディングレートに基づく次の8時間の予想ファンディングP&L",
  "Vega represents the increase in option price based on a 1% increase in the implied volatility.\n\nFor example, a Vega of 5 means the price of the option is expected to increase by $5 for a 1% increase in the implied volatility.":
    "ベガは、インプライドボラティリティが1%上昇した場合のオプション価格の上昇を表します。\n\n例えば、ベガが5の場合、インプライドボラティリティが1%上昇すると、オプション価格は5ドル上昇すると予想されます。",
  "Adjust Leverage": "レバレッジを調整",
  "Leverage updated successfully": "レバレッジが正常に更新されました",
  "Failed to update leverage": "レバレッジの更新に失敗しました",
  "Adjust leverage parameters for selected market. Leverage will also increase on your open positions.":
    "選択した市場のレバレッジパラメータを調整します。オープンポジションのレバレッジも増加します。",
  "Please note that changing leverage will also apply for open positions and open orders on this market.":
    "レバレッジの変更は、この市場のオープンポジションとオープンオーダーにも適用されることにご注意ください。",
  "Selecting higher leverage could result in Deleveraging where some of your open orders would be cancelled.":
    "より高いレバレッジを選択すると、一部のオープンオーダーがキャンセルされるデレバレッジが発生する可能性があります。",
  "disabled": "無効",
  "Margin Mode": "マージンモード",
  "Manage your risk on individual positions by restricting the amount of margin allocated to each. If the margin ratio of an isolated position reaches 100%, the position will be liquidated. Margin can be added or removed to individual positions in this mode.":
    "各ポジションに割り当てられるマージン額を制限することで、個々のポジションのリスクを管理します。分離ポジションのマージン比率が100%に達すると、ポジションは清算されます。このモードでは、個々のポジションにマージンを追加または削除できます。",
  "Margin mode updated successfully": "マージンモードが正常に更新されました",
  "Failed to update margin mode": "マージンモードの更新に失敗しました",
  "Favorites": "お気に入り",
  "Trending": "トレンド",
  "Losers": "下落銘柄",
  "Gainers": "上昇銘柄",
  "New Listing": "新規上場",
  "Developers": "開発者",
  "Developer Mode": "開発者モード",
  "Enable developer mode": "開発者モードを有効にする",
  "For external use": "外部使用",
  "Warning: Changing transfer behavior": "警告: 転送動作の変更",
  "USDC will be transferred to account contract instead of Paradex. Amount won't be visible or available for use within Paradex, but can be used with external applications.":
    "USDCはParadexの代わりにアカウント契約に転送されます。金額はParadex内では表示されず、外部アプリケーションで使用できます。",
  "Affiliate Dashboard": "アフィリエイトダッシュボード",
};

export default translations;
