/** Legal notes */
export const TERMS_OF_SERVICE_URL = 'https://paradex.trade/terms-of-service';
/** Legal notes */
export const PRIVACY_POLICY_URL = 'https://www.paradex.trade/privacy-policy';
/** Legal notes */
export const ABOUT_RESTRICTED_PERSONS_URL =
  'https://paradex.trade/restricted-persons';
/** Paradex product docs site */
export const PRODUCT_DOCS_URL = 'https://docs.paradex.trade/';
/** Paradex statistics */
export const STATS_URL = 'https://www.paradex.trade/stats';
/** Paradex Blog */
export const BLOG_URL = 'https://www.paradex.trade/blog';
/** Paradex Discord server */
export const DISCORD_URL = 'https://discord.gg/paradex';
/** URL to Paradex github code samples */
export const CODE_SAMPLES_URL = 'https://github.com/tradeparadex';
/** URL to Paradex twitter user */
export const TWITTER_URL = 'https://twitter.com/tradeparadex';
/** URL to documentation explaining how deleverage works */
export const DELEVERAGE_DOCS_URL =
  'https://docs.paradex.trade/documentation/risk-system/deleveraging';
export const PARADEX_CHAIN_MONITOR_URL =
  'https://paradex.starknet-monitor.com/';
/** URL to documentation explaining health checks */
export const UNHEALTHY_DOCS_URL =
  'https://docs.paradex.trade/documentation/risk-system/paradex-risk-checks#health-check';
/** URL to documentation explaining liquidation */
export const LIQUIDATION_DOCS_URL =
  'https://docs.paradex.trade/documentation/risk-system/liquidations';
/** External System status monitoring service that remains available even if all Paradex systems are down */
export const SYSTEM_STATUS_URL = 'https://status.paradex.trade/';
/** URL to documentation explaining deposit / withdrawal */
export const DEPOSITS_WITHDRAWALS_DOCS_URL =
  'https://docs.paradex.trade/documentation/accounts/deposits-and-withdrawals';
export const PARADEX_L2_ADDRESS_DOCS_URL =
  'https://docs.paradex.trade/documentation/getting-started/architecture-overview/wallet-integration';
/** URL to documentation explaining Points program */
export const XP_DOCS_URL =
  'https://docs.paradex.trade/documentation/points-referrals/xp';
export const REFERRALS_DOCS_URL =
  'https://docs.paradex.trade/documentation/points-referrals/referral-program';
export const CLOCK_SYNCHRONIZATION_DOCS_URL =
  'https://docs.paradex.trade/documentation/getting-started/architecture-overview/wallet-integration#clock-synchronization';
/** URL to documentation explaining Socialized Loss concept */
export const SOCIALIZED_LOSS_DOCS_URL =
  'https://docs.paradex.trade/documentation/risk-system/socialized-losses';
export const REFERRALS_URL = 'https://app.paradex.trade/referrals';
export const AFFILIATE_PROGRAM_APPLICATION_FORM_URL =
  'https://forms.gle/T4UKjinSefnVgiJx8';
export const STATUS_PAGE_URL = 'https://status.paradex.trade';
export const STARKNET_WALLETS_DOC_URL =
  'https://docs.paradex.trade/documentation/ecosystem-partners/starknet-wallets';
export const VAULTS_DOCS_URL =
  'https://www.paradex.trade/paradexblog/paradex-vaults-the-future-of-on-chain-investment-management';
export const PRICE_BAND_DOCS_URL =
  'https://docs.paradex.trade/documentation/risk-system/price-bands';
export const PERPETUAL_OPTIONS_DOCS_URL =
  'https://docs.paradex.trade/documentation/perpetual-options';
export const INTRODUCING_DIME_URL =
  'https://www.paradex.foundation/blog/introducing-dime-the-native-token-of-the-paradex-network';
export const CROSS_CHAIN_BRIDGING_DOCS_URL =
  'https://docs.paradex.trade/documentation/accounts/deposits-and-withdrawals/cross-chain-bridging';
export const AFFILIATE_PROGRAM_DOCS_URL =
  'https://docs.paradex.trade/documentation/points-referrals/affiliate-program';
export const AFFILIATE_PROGRAM_LEADERBOARD_URL =
  'https://www.paradex.trade/stats';
export const DELETE_ACCOUNT_FORM_URL = 'https://forms.gle/ZvZhVGM7ZiWAvUBX8';
