import { useEffect } from 'react';
import { usePrivy } from '@privy-io/react-auth';

import toast from '#/components/common/toast';

import { useAuthActions } from '#/features/auth/auth-context';
import { useTranslation } from '#/features/localization/utils';
import * as Logging from '#/features/logging/logging';
import {
  STORAGE_KEY_SESSION_EXPIRES_AT,
  STORAGE_KEY_SESSION_STARTED_AT,
} from '#/features/storage/storage';
import { logGeoMetadata } from '#/features/system/utils';
import { useWalletClient } from '#/features/wallet/common/wallet-client-context';
import {
  useWalletActions,
  useWalletView,
} from '#/features/wallet/wallet-context';
import ParaclearWallet from '#/features/wallets/paraclear/wallet';

import { removeStorageItem } from '#/utils/localStorage';

import { userSignOutTopic } from './auth-topics';

export function useSignOutHandler() {
  const authActions = useAuthActions();
  const walletActions = useWalletActions();
  const walletView = useWalletView();
  const walletClient = useWalletClient();
  const privy = usePrivy();

  const { t } = useTranslation();

  useEffect(() => {
    const handleSignOut = async () => {
      authActions.reset();
      walletActions.reset();

      removeStorageItem(STORAGE_KEY_SESSION_EXPIRES_AT);
      removeStorageItem(STORAGE_KEY_SESSION_STARTED_AT);

      if (privy.authenticated) await privy.logout();

      try {
        await walletClient.disconnect();
        toast.info(t('You have been logged out.'), {
          toastId: 'auth-successfully-logged-out',
        });
      } catch (err) {
        const message = `Unexpected error disconnecting wallet during handling Sign Out`;
        Logging.logException(new Error(message, { cause: err }));
        // Only reload in case of an error
        window.location.reload();
      }

      Logging.clearUser();
      ParaclearWallet.reset();

      logGeoMetadata('User signed out');
    };

    const unsubscribe = userSignOutTopic.subscribe(handleSignOut);

    return unsubscribe;
  }, [authActions, t, walletActions, walletView, walletClient, privy]);
}
