import BigNumber from 'bignumber.js';

import { msToDate } from '#/utils/date';
import getQueryString from '#/utils/getQueryString';
import { DecimalOrEmptyString } from '#/utils/types';

import { AsyncResp, BasePaginatedReq, BaseReq, requestApi } from './fetch-api';
import { Market } from './markets';
import { UnixTimeMs } from './types';

/** Direction of the order */
export type OrderSide = 'BUY' | 'SELL';

/** The way this order will interact with the orderbook */
export type OrderType =
  | 'LIMIT'
  | 'MARKET'
  | 'STOP_LIMIT'
  | 'STOP_MARKET'
  | 'TAKE_PROFIT_LIMIT'
  | 'TAKE_PROFIT_MARKET'
  | 'STOP_LOSS_LIMIT'
  | 'STOP_LOSS_MARKET';

export type OrderStatus = 'NEW' | 'UNTRIGGERED' | 'OPEN' | 'CLOSED';

export type OrderInstruction = 'GTC' | 'POST_ONLY' | 'IOC';

export type OrderCancelReason =
  | 'USER_CANCELED'
  | 'NOT_ENOUGH_MARGIN'
  | 'EMPTY_MARKET'
  | 'POST_ONLY_WOULD_CROSS'
  | 'REMAINING_IOC_CANCEL'
  | 'UNEXPECTED_FAILURE'
  | 'DELEVERAGE'
  | 'IN_LIQUIDATION'
  | 'SELF_TRADE'
  | 'ASSET_UNAVAILABLE'
  | 'ASSET_EXPIRED'
  | 'ORDER_TYPE_INVALID'
  | 'PRICE_NOT_AVAILABLE'
  | 'EXPIRED'
  | 'EXCEEDS_MAX_SLIPPAGE'
  | 'TIMEOUT'
  | 'ORDER_EXCEEDS_POSITION_LIMIT'
  | 'REDUCE_ONLY_WILL_INCREASE';

export type OrderFlag = 'REDUCE_ONLY';

export interface BaseRawOrder {
  /** Unique order identifier */
  readonly id: string;
  readonly created_at: UnixTimeMs;
  readonly last_updated_at: UnixTimeMs;
  /** Which account owns this order */
  readonly account: string;
  /** Unique identifier for the market that this order will be sent to */
  readonly market: string;
  /** Direction of the order */
  readonly side: OrderSide;
  readonly size: string;
  /** Size yet to be filled */
  readonly remaining_size: string;
  /** Average fill price */
  readonly avg_fill_price: DecimalOrEmptyString;
}

export interface RawOrder extends BaseRawOrder {
  readonly client_id: string | null;
  /** The way this order will interact with the orderbook */
  readonly type: OrderType;

  /**
   * Price of limit orders. 0 for market orders.
   * @example '34576.54'
   * @example '0'
   */
  readonly price: string;
  /**
   * Trigger price for stop orders. 0 for non-stop orders.
   * @example '34576.54'
   * @example '0'
   */
  readonly trigger_price: string;
  readonly status: OrderStatus;
  readonly cancel_reason: OrderCancelReason | '';
  readonly instruction: OrderInstruction;
  // WIP optional until implemented on API
  readonly flags?: ReadonlyArray<OrderFlag>;
  /** Used to track the modify order request status */
  readonly request_info?: RequestInfo;
}

export interface RequestInfo {
  readonly id: string;
  readonly status: RequestStatus;
  readonly message: string;
  readonly request_type: RequestType;
}

type RequestType = 'MODIFY_ORDER';
type RequestStatus = 'PENDING' | 'REJECTED' | 'SUCCESS';

export interface RawAlgoOrder extends BaseRawOrder {
  // end time for algorithm execution
  readonly end_at: UnixTimeMs;
  readonly status: 'NEW' | 'OPEN' | 'CLOSED';
  readonly algo_type: 'TWAP';
  readonly avg_fill_price: string;
  readonly cancel_reason: OrderCancelReason | '';
  // WIP optional until implemented on API
  readonly flags?: ReadonlyArray<OrderFlag>;
}

export interface RawOpenOrder extends RawOrder {
  readonly status: 'OPEN' | 'UNTRIGGERED' | 'NEW';
}

export interface RawClosedOrder extends RawOrder {
  readonly status: 'CLOSED';
  readonly cancel_reason: OrderCancelReason;
}

export interface Order
  extends Omit<
    RawOrder,
    | 'created_at'
    | 'last_updated_at'
    | 'size'
    | 'remaining_size'
    | 'price'
    | 'trigger_price'
    | 'expiration'
    | 'flags'
    | 'avg_fill_price'
  > {
  readonly created_at: Date;
  readonly last_updated_at: Date;
  readonly size: BigNumber;
  readonly remaining_size: BigNumber;
  readonly price: BigNumber;
  readonly trigger_price: BigNumber;
  readonly avg_fill_price: BigNumber;
  // WIP needed until implemented on API
  readonly flags: ReadonlyArray<OrderFlag>;
}

interface OrdersReq extends BasePaginatedReq {
  readonly market?: Order['market'];
  readonly side?: Order['side'];
  readonly status?: Order['status'];
  readonly type?: Order['type'];
}

export interface OrdersResp {
  readonly prev: string | null;
  readonly next: string | null;
  readonly results: readonly Order[];
}
interface RawOrdersResp {
  readonly prev: string | null;
  readonly next: string | null;
  readonly results: readonly RawOrder[];
}

export async function getOrders(req: OrdersReq): AsyncResp<OrdersResp> {
  const { signal, cursor, page_size, ...filters } = req;

  const query = getQueryString([
    ['market', filters.market],
    ['side', filters.side],
    ['status', filters.status],
    ['type', filters.type],
    ['cursor', cursor],
    ['page_size', page_size],
  ]);

  const resp = await requestApi<RawOrdersResp>({
    signal,
    method: 'GET',
    url: `/orders-history/${query}`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: {
      ...resp.data,
      results: resp.data.results.map(processOrder),
    },
  };
}

export interface OpenOrder extends Order {
  readonly status: 'OPEN' | 'NEW' | 'UNTRIGGERED';
}
export interface ClosedOrder extends Order {
  readonly status: 'CLOSED';
  readonly cancel_reason: OrderCancelReason;
}

interface OpenOrdersReq extends BaseReq {
  readonly market?: Market['symbol'];
}

export interface OpenOrdersResp {
  readonly results: readonly OpenOrder[];
}
interface RawOpenOrdersResp {
  readonly results: readonly RawOpenOrder[];
}

export async function getOpenOrders(
  req: OpenOrdersReq,
): AsyncResp<OpenOrdersResp> {
  const { signal, market } = req;

  const query = getQueryString([['market', market]]);

  const resp = await requestApi<RawOpenOrdersResp>({
    signal,
    method: 'GET',
    url: `/orders${query}`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: {
      results: resp.data.results.map(processOrder) as OpenOrder[],
    },
  };
}

interface OrderReq extends BaseReq {
  readonly id: Order['id'];
}

export interface OrderResp extends Order {}

interface RawOrderResp extends RawOrder {}

export async function getOrder(req: OrderReq): AsyncResp<OrderResp> {
  const { signal, id } = req;

  const resp = await requestApi<RawOrderResp>({
    signal,
    method: 'GET',
    url: `/orders/${id}`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: processOrder(resp.data),
  };
}

export type UnsignedOrderReq = Omit<CreateOrderReq, 'signature'>;

export interface CreateOrderReq extends BaseReq {
  /** @example 'BTC-USD-PERP' */
  readonly market: string;
  readonly side: OrderSide;
  readonly type: OrderType;
  /** @example '12.34' */
  readonly size: string;
  /** @example '34576.54' */
  readonly price?: string;
  /** @example '34576.54' */
  readonly trigger_price?: string;
  /** @example '0.1' */
  readonly signature_timestamp: UnixTimeMs;
  readonly instruction: OrderInstruction;
  readonly signature: string;
  readonly flags?: ReadonlyArray<OrderFlag>;
  readonly on_behalf_of_account?: string;
}

export type CreateOrderResp = Order;

export async function createOrder(
  req: CreateOrderReq,
): AsyncResp<CreateOrderResp> {
  const { signal, ...body } = req;

  const resp = await requestApi<RawOrder>({
    signal,
    method: 'POST',
    url: `/orders`,
    body,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: processOrder(resp.data),
  };
}
export interface AlgoOrder
  extends Omit<
    RawAlgoOrder,
    | 'created_at'
    | 'end_at'
    | 'last_updated_at'
    | 'size'
    | 'remaining_size'
    | 'avg_fill_price'
  > {
  readonly created_at: Date;
  readonly end_at: Date;
  readonly last_updated_at: Date;
  readonly size: BigNumber;
  readonly remaining_size: BigNumber;
  readonly avg_fill_price: BigNumber;
}

export interface CreateAlgoOrderReq extends BaseReq {
  /** @example 'BTC-USD-PERP' */
  readonly market: string;
  readonly side: OrderSide;
  readonly type: 'MARKET';
  /** @example '12.34' */
  readonly size: string;
  /** @example 300 (seconds) */
  readonly duration_seconds: number;
  /** @example '0.1' */
  readonly signature_timestamp: UnixTimeMs;
  /** In seconds */
  readonly frequency: number;
  readonly algo_type: 'TWAP';
  readonly signature: string;
  readonly flags?: ReadonlyArray<OrderFlag>;
}

export async function createAlgoOrder(
  req: CreateAlgoOrderReq,
): AsyncResp<AlgoOrder> {
  const { signal, ...body } = req;

  const resp = await requestApi<RawAlgoOrder>({
    signal,
    method: 'POST',
    url: `/algo/orders`,
    body,
  });
  if (!resp.ok) return resp;

  return {
    ...resp,
    data: processAlgoOrder(resp.data),
  };
}

export interface CancelAlgoOrderReq extends BaseReq {
  readonly id: string;
}

export async function cancelAlgoOrder(
  req: CancelAlgoOrderReq,
): AsyncResp<unknown> {
  const { signal, id, ...body } = req;

  const resp = await requestApi<RawAlgoOrder>({
    signal,
    method: 'DELETE',
    url: `/algo/orders/${id}`,
    body,
  });

  return resp;
}

export interface AlgoOrdersResp {
  readonly results: AlgoOrder[];
}

interface RawAlgoOrdersResp {
  readonly results: RawAlgoOrder[];
}

export async function getAlgoOrders(req: BaseReq): AsyncResp<AlgoOrdersResp> {
  const { signal } = req;

  const resp = await requestApi<RawAlgoOrdersResp>({
    signal,
    method: 'GET',
    url: `/algo/orders`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: {
      ...resp.data,
      results: resp.data.results.map(processAlgoOrder),
    },
  };
}

export interface GetAlgoOrdersHistoryReq extends BasePaginatedReq {
  readonly market?: string;
  readonly status?: AlgoOrder['status'];
  readonly side?: AlgoOrder['side'];
  readonly start_time?: UnixTimeMs;
  readonly end_time?: UnixTimeMs;
}

export async function getAlgoOrdersHistory(
  req: GetAlgoOrdersHistoryReq,
): AsyncResp<AlgoOrdersResp> {
  const { signal, ...filters } = req;
  const query = getQueryString([
    ['market', filters.market],
    ['side', filters.side],
    ['status', filters.status],
    ['start_time', filters.start_time],
    ['end_time', filters.end_time],
    ['cursor', filters.cursor],
    ['page_size', filters.page_size],
  ]);

  const resp = await requestApi<RawAlgoOrdersResp>({
    signal,
    method: 'GET',
    url: `/algo/orders-history${query}`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: {
      ...resp.data,
      results: resp.data.results.map(processAlgoOrder),
    },
  };
}

export interface AlgoOrderReq extends BaseReq {
  readonly algoId: string;
}

export async function getAlgoOrder(req: AlgoOrderReq): AsyncResp<AlgoOrder> {
  const { signal, algoId } = req;

  const resp = await requestApi<RawAlgoOrder>({
    signal,
    method: 'GET',
    url: `/algo/orders/${algoId}`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: processAlgoOrder(resp.data),
  };
}

export interface PostOrdersBatchReq extends BaseReq {
  readonly orders: readonly CreateOrderReq[];
}

export interface PostOrdersBatchResp {
  orders: readonly Order[];
}

export async function postOrdersBatch(
  req: PostOrdersBatchReq,
): AsyncResp<PostOrdersBatchResp> {
  const { signal, orders } = req;

  const resp = await requestApi<{ orders: RawOrder[] }>({
    signal,
    method: 'POST',
    url: `/orders/batch`,
    body: orders,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: {
      ...resp.data,
      orders: resp.data.orders.map(processOrder),
    },
  };
}

export interface CancelOrderReq extends BaseReq {
  readonly id: Order['id'];
}

export interface CancelOrderResp {
  readonly order_id: RawOrder['id'];
}

export async function cancelOrder(
  req: CancelOrderReq,
): AsyncResp<CancelOrderResp> {
  const { id, ...rest } = req;

  const resp = await requestApi<CancelOrderResp>({
    ...rest,
    method: 'DELETE',
    url: `/orders/${id}`,
  });

  return resp;
}

export interface CancelAllOrdersReq extends BaseReq {
  readonly market?: Order['market'];
}

export async function cancelAllOrders(
  req: CancelAllOrdersReq,
): AsyncResp<undefined> {
  const { market, ...rest } = req;

  const query = getQueryString([['market', market]]);

  const resp = await requestApi<undefined>({
    ...rest,
    method: 'DELETE',
    url: `/orders${query}`,
  });

  return resp;
}

export function processOrder(order: RawOrder): Order {
  return {
    ...order,
    created_at: msToDate(order.created_at),
    last_updated_at: msToDate(order.last_updated_at),
    size: new BigNumber(order.size),
    remaining_size: new BigNumber(order.remaining_size),
    price: new BigNumber(order.price),
    avg_fill_price: new BigNumber(order.avg_fill_price),
    trigger_price: new BigNumber(order.trigger_price),
    flags: order.flags ?? [],
  };
}

export function processAlgoOrder(order: RawAlgoOrder): AlgoOrder {
  return {
    ...order,
    created_at: msToDate(order.created_at),
    end_at: msToDate(order.end_at),
    last_updated_at: msToDate(order.last_updated_at),
    size: new BigNumber(order.size),
    remaining_size: new BigNumber(order.remaining_size),
    avg_fill_price: new BigNumber(order.avg_fill_price),
    flags: order.flags ?? [],
  };
}

export interface ModifyOrderReq extends BaseReq {
  readonly id: string;
  readonly market: string;
  readonly price: string;
  readonly size: string;
  readonly type: OrderType;
  readonly side: OrderSide;
  readonly signature_timestamp: UnixTimeMs;
  readonly signature: string;
}

export type ModifyOrderResp = Order;

export async function modifyOrder(
  req: ModifyOrderReq,
): AsyncResp<ModifyOrderResp> {
  const { signal, ...body } = req;
  const orderId = body.id;

  const resp = await requestApi<RawOrder>({
    signal,
    method: 'PUT',
    url: `/orders/${orderId}`,
    body,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: processOrder(resp.data),
  };
}
