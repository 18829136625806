import { c00EE91, cFF005C, cFFFFFF } from '#/colors';

const SIDE_COLOR = {
  BUY: c00EE91,
  SELL: cFF005C,
} as const;

export interface TradeHistoryStyles {
  readonly textColor: string;
  readonly backgroundColor: string;
}

export default function styles(side: 'BUY' | 'SELL'): TradeHistoryStyles {
  return {
    textColor: cFFFFFF,
    backgroundColor: SIDE_COLOR[side],
  };
}
