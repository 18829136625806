import { useState } from 'react';

import useEffectOnce from '#/utils/useEffectOnce';

/**
 * Return true if the date is in the past.
 * @param expiresAt Date string in format '2025-03-15T00:00:00Z'. Other formats are not tested/supported on all browsers.
 */
export default function useIsExpired(expiresAt?: string): boolean {
  const [isExpired, setIsExpired] = useState(false);
  useEffectOnce(() => {
    if (expiresAt == null) return;
    const now = Date.now();
    const expirationTime = new Date(expiresAt).getTime();
    if (Number.isNaN(expirationTime)) return;
    setIsExpired(now >= expirationTime);
  });
  return isExpired;
}
