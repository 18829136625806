import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';

import { Transfer } from '#/api/transfers';

import { LiveConnectionBaseState } from '#/utils/useLiveConnection';

export const adapter = createEntityAdapter<Transfer>({
  sortComparer: (a, b) =>
    b.id.localeCompare(a.id, undefined, { numeric: true }),
});

export interface TransfersState extends LiveConnectionBaseState {
  readonly transfers: EntityState<Transfer, Transfer['id']>;
}

export const INITIAL_STATE: TransfersState = {
  status: 'connected',
  error: '',
  transfers: adapter.getInitialState(),
};
