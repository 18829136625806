import { perEnv } from '#/features/env';

import type { BridgeOnsite } from '#/features/bridging/types';

export const starkgate: BridgeOnsite = {
  config: {
    id: 'STARKGATE',
    name: 'Paradex Bridge',
    icon: 'starkgate',
    integrationKind: 'onsite',
    depositMode: 'one-step',
    supportedChains: perEnv({
      prod: [{ id: 'ETHEREUM', externalId: '' }] as const,
      fallback: [{ id: 'ETHEREUM', externalId: '' }] as const,
    }),
    supportedAccountTypes: ['ethereum'],
    supportedWalletKinds: ['ethereum-external'],
    enabled: true,
  },
} as const;
