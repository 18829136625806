import { getGeo } from '#/api/geo';

import { logEvent, logException } from '#/features/logging/logging';

import flatObject from '#/utils/flatObject';

/**
 * Intervention to keep use of localStorage by WalletConnect in check.
 * WalletConnect is known to add a lot of data to localStorage, which can
 * cause the user to get stuck trying to connect wallet. This function
 * should be removed once WalletConnect fixes this issue.
 */
export function optimizeLocalStorage(): void {
  try {
    logEvent('Attempting to optimize localStorage');

    let removedKeysCount = 0;
    let removedItemsLength = 0;

    for (const [key, item] of Object.entries<string>(localStorage)) {
      if (!key.startsWith('wc@')) continue;
      removedKeysCount += 1;
      removedItemsLength += item.length;
      localStorage.removeItem(key);
    }

    logEvent('localStorage optimized', {
      removedKeysCount,
      removedLength: removedItemsLength,
    });
  } catch (err) {
    const message = 'Failed to reset system state.';
    logException(new Error(message, { cause: err }));
  }
}

/**
 * Checks whether the current window is embedded in an iframe.
 */
export function isEmbedded(): boolean {
  return window.self !== window.top;
}

export function logGeoMetadata(msg: string): void {
  getGeo({})
    .then((result) => {
      const metadata = {
        timestampMs: Date.now(),
        statusCode: result.status,
        data: flatObject(result.data) ?? 'undefined',
      };
      logEvent(`User GEO metadata: ${msg}`, metadata);
    })
    .catch((err) => {
      const message = `Failed to log User GEO metadata: ${msg}`;
      const error = new Error(message, { cause: err });
      logException(error);
    });
}
