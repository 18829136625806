import BigNumber from 'bignumber.js';
import { Call } from 'starknet';

import { BridgedToken } from '#/features/paraclear';
import { type ParaclearProvider } from '#/features/wallets/paraclear/provider';
import ParaclearWallet from '#/features/wallets/paraclear/wallet';

import { fromQuantums, toQuantums } from '#/utils/quantums';
import { waitForTransaction } from '#/utils/starknet';
import { AsyncResult } from '#/utils/types';

import type { ParaclearContract } from '#/features/paraclear';

/** 2 ** 128 - 1 */
const INCREASE_ALLOWANCE_MAX_VALUE = BigNumber(2).pow(128).minus(1);

type TxHash = string;

/**
 * Issues a request to deposit funds from L2 account to Paraclear,
 * Takes into account the user's current allowance and the requested
 * deposit amount to also request allowance increase if needed.
 *
 * @param amount Amount to deposit
 * @param token Token to deposit
 * @param paraclear Paraclear contract
 * @param provider Starknet provider
 *
 * @returns Result with transaction hash
 */
export async function requestDeposit(
  amount: BigNumber,
  token: BridgedToken,
  paraclear: ParaclearContract,
  provider: ParaclearProvider,
): AsyncResult<TxHash, TxHash> {
  const allowanceQuantum = await readAllowance(token, paraclear, provider);

  const allowance = fromQuantums(allowanceQuantum, token.decimals);

  const result = await (allowance.isLessThan(amount)
    ? requestDepositWithAllowance(amount, token, paraclear, provider)
    : requestDepositWithoutAllowance(amount, token, paraclear, provider));

  if (!result.ok) {
    return {
      ok: false,
      error:
        result.error ??
        new Error('Unknown Error, Failed to wait for transaction'),
      data: '',
    };
  }

  if (result.data.isRejected()) {
    return {
      ok: false,
      error: new Error('Transaction rejected'),
      data: '',
    };
  }

  if (!result.data.isSuccess()) {
    return {
      ok: false,
      error: new Error(
        `Transaction not successful execution_status='${String(
          result.data.execution_status,
        )}' transaction_hash='${result.data.transaction_hash}' revert_reason='${
          result.data.revert_reason
        }'`,
      ),
      data: '',
    };
  }

  return { ok: true, data: result.data.transaction_hash };
}

/**
 * Queries the current allowance for Paraclear.
 *
 * @param token Token to deposit
 * @param paraclear Paraclear contract
 * @param provider Starknet provider
 *
 * @returns Current allowance for Paraclear
 */
async function readAllowance(
  token: BridgedToken,
  paraclear: ParaclearContract,
  provider: ParaclearProvider,
): Promise<BigNumber> {
  return ParaclearWallet.getErc20Allowance(
    provider,
    token.l2TokenAddress,
    paraclear.address,
  );
}

/**
 * Requests allowance increase and deposit to
 * Paraclear in a single batch to save time.
 *
 * @param amount Amount to deposit
 * @param token Token to deposit
 * @param paraclear Paraclear contract
 * @param provider Starknet provider
 *
 * @returns Result with transaction hash
 */
async function requestDepositWithAllowance(
  amount: BigNumber,
  token: BridgedToken,
  paraclear: ParaclearContract,
  provider: ParaclearProvider,
): AsyncResult<Awaited<ReturnType<typeof provider.getTransactionReceipt>>> {
  const allowanceIncreaseCall = buildMaxAllowanceIncreaseCall(token, paraclear);
  const depositToParaclearCall = buildDepositCall(amount, token, paraclear);
  const response = await ParaclearWallet.executeTransaction(provider, [
    allowanceIncreaseCall,
    depositToParaclearCall,
  ]);
  const result = await waitForTransaction(provider, response.transaction_hash);
  return result;
}

/**
 * Requests deposit to Paraclear without
 * requesting allowance increase.
 *
 * @param amount Amount to deposit
 * @param token Token to deposit
 * @param paraclear Paraclear contract
 * @param provider Starknet provider
 *
 * @returns Result with transaction hash
 */
async function requestDepositWithoutAllowance(
  amount: BigNumber,
  token: BridgedToken,
  paraclear: ParaclearContract,
  provider: ParaclearProvider,
): AsyncResult<Awaited<ReturnType<typeof provider.getTransactionReceipt>>> {
  const depositToParaclearCall = buildDepositCall(amount, token, paraclear);
  const response = await ParaclearWallet.executeTransaction(
    provider,
    depositToParaclearCall,
  );
  const result = await waitForTransaction(provider, response.transaction_hash);
  return result;
}

/**
 * Builds a call to increase allowance to the maximum
 * value possible for Paraclear.
 *
 * @param token Token to deposit
 * @param paraclear Paraclear contract
 *
 * @returns Call to increase allowance for Paraclear
 */
function buildMaxAllowanceIncreaseCall(
  token: BridgedToken,
  paraclear: ParaclearContract,
): Call {
  const spender = paraclear.address;

  return {
    contractAddress: token.l2TokenAddress,
    entrypoint: 'increaseAllowance',
    calldata: [spender, INCREASE_ALLOWANCE_MAX_VALUE.toFixed(), '0'],
  };
}

/**
 * Builds a call to deposit funds from L2 account to Paraclear.
 *
 * @param amount Amount to deposit
 * @param token Token to deposit
 * @param paraclear Paraclear contract
 *
 * @returns Call to deposit funds from L2 account to Paraclear
 */
function buildDepositCall(
  amount: BigNumber,
  token: BridgedToken,
  paraclear: ParaclearContract,
): Call {
  const tokenAddress = token.l2TokenAddress;
  const amountQuantums = toQuantums(amount, paraclear.decimals);
  return {
    contractAddress: paraclear.address,
    entrypoint: 'deposit',
    calldata: [tokenAddress, amountQuantums],
  };
}
