/**
 * About `translator` export:
 *  Use `translator.t('Some Phrase')` with care, as it won't trigger React to re-render when locale changes.
 *  Whenever possible, use `useTranslation()` hook instead.
 *  And never use `translator` as a global outside of function,
 *      as it will result in always using the the initial locale that will be set on `i18next` initialization (e.g. always in `en`)
 */
export { i18nInstance as translator } from './i18next';
export { useToDoTranslation } from './utilsToDo';
export { useTranslation, Trans as Translation } from 'react-i18next';
export type { TFunction } from 'i18next';
