/**
 * update query param value in immutable way
 *
 * @returns new instance of URLSearchParams without mutating the original
 */
export function updateSearchParam(
  urlSearchParams: URLSearchParams,
  key: string,
  val: string,
) {
  const newUrlSearchParams = new URLSearchParams(urlSearchParams);
  newUrlSearchParams.set(key, val);
  return newUrlSearchParams;
}
