import BigNumber from 'bignumber.js';

export function formatPercentChange(
  value: BigNumber,
  options?: Intl.NumberFormatOptions,
): string {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    signDisplay: 'always',
    ...options,
  });
  return formatter.format(value.toNumber());
}

export function formatPercent(
  value: BigNumber,
  options?: Intl.NumberFormatOptions,
): string {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    ...options,
  });
  return formatter.format(value.toNumber());
}
