export async function sleep(timeInMs: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, timeInMs);
  });
}

/**
 * Adds random number (min to max) to sleep.
 */
export async function sleepWithJitter(
  timeInMs: number,
  [min, max]: [min: number, max: number],
) {
  const jitter = Math.random() * (max - min) + min;
  return sleep(timeInMs + jitter);
}
