import { Keys } from "./en";

const translations: Record<Keys, string> = {
  "Display trade history on chart":
    "Exibir histórico de negociações no gráfico",
  "Add markets to Favorites in the Markets Browser":
    "Adicione mercados aos Favoritos no Navegador de Mercados",
  "Log in": "Entrar",
  "Export embedded wallet": "Exportar carteira embarcada",
  "Email or Social": "Email ou Social",
  "Failed to load balances": "Falha ao carregar saldos",
  "No balances": "Sem saldos",
  "Verified Operator": "Operador Verificado",
  "Strategy": "Estratégia",
  "Multi-Strategy": "Multi-Estratégia",
  "Master Vault": "Cofre Principal",
  "Master Vault Details": "Detalhes do Cofre Principal",
  "Annualized": "Anualizado",
  "Depositors": "Depositantes",
  "No trade history": "Nenhum histórico de trades",
  "Unwinding Progress": "Progresso de Encerramento",
  "What are Vaults?": "O que são Cofres?",
  "APR calculated as the Annualised 30-day Return":
    "APR calculado como a rentabilidade anualizada de 30 dias",
  "APR": "APR",
  "Initializing": "Inicializando",
  "Awaiting Deposit": "Aguardando Depósito",
  "Vault Age": "Idade do Cofre",
  "30D Return": "Retorno em 30D",
  "{{field}} must be greater than the minimum initial deposit of {{minInitialDeposit}}":
    "{{field}} deve ser maior que o depósito inicial mínimo de {{minInitialDeposit}}",
  "Minimum deposit": "Depósito mínimo",
  "Withdrawal amount exceeds available vault shares":
    "A quantidade de saque excede os compartilhamentos disponíveis do cofre",
  "Deposit would exceed maximum vault TVL of {{maxTVL}}":
    "O depósito excederá o TVL máximo do cofre de {{maxTVL}}",
  "{{field}} is required": "{{field}} é obrigatório",
  "Lockup Remaining": "Bloqueio restante",
  "{{days}}D": "{{days}}D",
  "<1>{{days}}</1> D": "<1>{{days}}</1> D",
  "<1>{{hours}}</1> H": "<1>{{hours}}</1> H",
  "<1>{{minutes}}</1> M": "<1>{{minutes}}</1> M",
  "Vault settings submitted": "Configurações do Cofre enviadas",
  "Vault settings updated": "Configurações do Cofre atualizadas",
  "Vault Settings": "Configurações do Cofre",
  "Vault not found": "Cofre não encontrado",
  "The owner receives a {{profitShare}} profit share.":
    "O proprietário recebe {{profitShare}} de participação nos lucros.",
  "This vault is entirely owned by the community.":
    "Este cofre é inteiramente de propriedade da comunidade.",
  "Withdraw from Vault submitted": "Saque do Cofre enviado",
  "Deposit to Vault submitted": "Depósito no Cofre enviado",
  "Unknown error. Try again.": "Erro desconhecido. Tente novamente.",
  "Vault Deposit": "Depósito no Cofre",
  "Vault Withdrawal": "Saque do Cofre",
  "No Lockup": "Sem bloqueio",
  "Lockup period is not over yet": "Período de bloqueio ainda não terminou",
  "Protocol": "Protocolo",
  "24H": "24H",
  "7D": "7D",
  "30D": "30D",
  "All": "Tudo",
  "You have no investments in vaults": "Você não tem investimentos em cofres",
  "Total Value": "Valor Total",
  "Investments Made": "Investimentos Realizados",
  "Operator": "Operador",
  "Manage Vault": "Gerenciar Cofre",
  "Switch to the operator account": "Mudar para a conta do operador",
  "Active": "Ativos",
  "My Vaults": "Meus Cofres",
  "Closed": "Fechados",
  "Deposited": "Depositados",
  "Lifetime ROI": "ROI desde o início",
  "Owners Equity": "Patrimônio do Proprietário",
  "Top Trending": "Mais Populares",
  "Owner Share": "Participação do Proprietário",
  "Vault Details": "Detalhes do Cofre",
  "Contract": "Contrato",
  "Withdraw from Vault": "Sacar do Cofre",
  "Withdraw from Vault successful": "Saque do Cofre bem-sucedido",
  "Failed to withdraw from Vault": "Falha ao sacar do Cofre",
  "Name": "Nome",
  "Deposit": "Depósito",
  "Tokens": "Tokens",
  "Avg. Entry Price": "Preço Médio de Entrada",
  "Current Price": "Preço Atual",
  "P&L": "L&P",
  "My Vault Deposit": "Meu Depósito do Cofre",
  "Vaults": "Cofres",
  "Create": "Criar",
  "Create My Vault": "Criar Meu Cofre",
  "Vault Name": "Nome do Cofre",
  "Vault Description": "Descrição do Cofre",
  "Token Symbol": "Símbolo do Token",
  "Deposit Amount": "Valor do Depósito",
  "Vault description will be visible publicly and you will not be able to edit this later.":
    "A descrição do cofre será visível publicamente e você não poderá editá-la posteriormente.",
  "Once created, deposit at least {{minDeposit}} USDC to activate the vault. At this moment, only the Max TVL can be changed later.":
    "Uma vez criado, deposite pelo menos {{minDeposit}} USDC para ativar o cofre. Neste momento, apenas o TVL máximo pode ser alterado posteriormente.",
  "Create Vault": "Criar Cofre",
  "Profit Share": "Compartilhamento de Lucros",
  "Lockup Period (Days)": "Período de Bloqueio (Dias)",
  "Vault was successfully created and is being initialized":
    "O cofre foi criado com sucesso e está sendo inicializado",
  "Failed to create a vault": "Falha ao criar um cofre",
  "Maximum TVL": "TVL Máximo",
  "No limit": "Sem limite",
  "Vault Address": "Endereço do Cofre",
  "Deposit to Vault successful": "Depósito no Cofre bem-sucedido",
  "Failed to deposit to Vault": "Falha ao depositar no Cofre",
  "Deposit to Vault": "Depositar no Cofre",
  "Vault": "Cofre",
  "Owner": "Proprietário",
  "Age": "Idade",
  "Failed to load vaults": "Falha ao carregar cofres",
  "No vaults available": "Nenhum cofre disponível",
  "Performance": "Desempenho",
  "Vault Value": "Valor do Cofre",
  "My Deposit": "Meu Depósito",
  "Total P&L": "L&P Totais",
  "Protocol Vaults": "Cofres do Protocolo",
  "User Vaults": "Cofres de Usuários",
  "No Data": "Sem Dados",
  "Last Month Return": "Retorno do Último Mês",
  "TVL": "TVL",
  "Total Earnings": "Ganhos Totais",
  "About": "Sobre",
  "Vault Stats": "Estatísticas do Cofre",
  "My Stats": "Minhas Estatísticas",
  "Vault Contract": "Contrato do Cofre",
  "Description": "Descrição",
  "Max Drawdown": "Drawdown Máximo",
  "Volume": "Volume",
  "All-Time P&L": "L&P de todo o tempo",
  "Days": "Dias",
  "Lockup Period": "Período de Bloqueio",
  "Search Vaults": "Pesquisar Cofres",
  "Blog": "Blog",
  "Block Explorer": "Explorador de Blocos",
  "Close Vault": "Fechar Cofre",
  "Close Vault successful": "Fechamento do Cofre bem-sucedido",
  "You must close all open positions to close your Vault. Closed vaults cannot be reopened.":
    "Você deve fechar todas as posições abertas para fechar seu Cofre. Cofres fechados não podem ser reabertos.",
  "Close My Vault": "Fechar Meu Cofre",
  "Failed to Close the Vault": "Falha ao Fechar o Cofre",
  "Only the Main Account can manage the vault":
    "Apenas a Conta Principal pode gerenciar o cofre",
  "Allocate Collateral": "Alocar Colateral",
  "Switch to Main Account": "Mudar para a Conta Principal",
  "Switch to Account": "Mudar para a Conta",
  "You are currently trading in the {{market}} market.":
    "Você está negociando atualmente no mercado de {{market}}.",
  "To start trading on Isolated Market you must Allocate Collateral from your Main Account and switch to this Market Account.":
    "Para começar a negociar no Mercado Isolado, você deve Alocar Colateral da sua Conta Principal e mudar para esta Conta de Mercado.",
  "To trade in this market, switch to the Main Account first.":
    "Para negociar neste mercado, mude primeiro para a Conta Principal.",
  "This account can only trade in the {{market}} market.":
    "Esta conta só pode negociar no mercado de {{market}}.",
  "Isolated Market": "Mercado Isolado",
  "This feature currently is still in Beta. Backup your Paradex Private Key for additional safety.":
    "Este recurso ainda está em Beta. Faça backup da sua Chave Privada Paradex para maior segurança.",
  "Deposit failed": "Depósito falhou",
  "Deposit in progress": "Depósito em andamento",
  "External Account": "Conta externa",
  "Tx Hashes": "Hashes de transações",
  "Continue to {{bridge}}": "Continuar para {{bridge}}",
  "By clicking Continue to {{bridge}}, you will be sent to {{bridge}} website to bridge your funds out of Paradex.":
    "Ao clicar em Continuar para {{bridge}}, você será direcionado ao site da {{bridge}} para transferir seus fundos para fora da Paradex.",
  "By clicking Continue to {{bridge}}, you will be sent to {{bridge}} website to bridge your funds to Paradex.":
    "Ao clicar em Continuar para {{bridge}}, você será direcionado ao site da {{bridge}} para transferir seus fundos para Paradex.",
  "Refer": "Indicar",
  "Address": "Endereço",
  "Date Joined": "Data de Entrada",
  "Referral Code": "Código de Indicação",
  "Volume Traded": "Volume Negociado",
  "Bridge": "Ponte",
  "Withdraw to": "Sacar para",
  "Deposit from": "Depósitar de",
  "Position is already closed": "A posição já está fechada",
  "Your Paradex L2 Address is NOT a Public Starknet Address. If you try to send funds to this address on Public Starknet via a cross chain bridge you risk losing your funds. <1>Read more</1>.":
    "Seu endereço L2 da Paradex NÃO é um endereço Starknet público. Se você tentar enviar fundos para este endereço na Starknet pública via uma ponte de cadeia cruzada, você corre o risco de perder seus fundos. <1>Leia mais</1>.",
  "{{chain}} Address": "Endereço {{chain}}",
  "Copy Paradex Private Key": "Copiar Chave Privada da Paradex",
  "Your account will be liquidated if Margin Ratio reaches 100%":
    "Sua conta será liquidada se a Taxa de Margem atingir 100%",
  "Liquidation": "Liquidação",
  "Fee": "Taxa",
  "Maker": "Maker",
  "Maker Score": "Pontuação de Maker",
  "Maker XP Share": "Quota de XP de Maker",
  "Fee XP Share": "Quota de XP de Taxa",
  "Fee XP": "XP de Taxa",
  "Maker XP": "XP de Maker",
  "Liquidation {{side}} Fill": "Preenchimento de Liquidação {{side}}",
  "Settings": "Configurações",
  "Market Score": "Pontuação de Mercado",
  "Season {{season}} (coming soon)": "Temporada {{season}} (em breve)",
  "Confirm Buy": "Confirmar Compra",
  "Confirm Sell": "Confirmar Venda",
  "Other XP": "Outros XP",
  "Estimated Receivable Amount": "Valor Recebível Estimado",
  "Socialized Loss active. Click to learn more.":
    "Perda Socializada em vigor. Clique para saber mais.",
  "Analytics": "Analytics",
  "Sign In to see your account XP stats":
    "Faça login para ver as estatísticas de XP da sua conta",
  "<1>Clock Is Out Of Sync</1> The clock on your device is out of sync. This may cause issues with the application. Please make sure your device clock is set to be auto. For more details please <2>see documentation</2>":
    "<1>O Relógio Está Fora de Sincronia</1> O relógio do seu dispositivo está fora de sincronia. Isso pode causar problemas com o aplicativo. Por favor, certifique-se de que o relógio do seu dispositivo está definido para automático. Para mais detalhes, por favor <2>consulte a documentação</2>",
  "Failed to fetch max withdrawable amount":
    "Falha ao buscar valor máximo de saque",
  "It is not possible to withdraw more than your Max Withdrawable Amount.":
    "Não é possível sacar mais do que o seu Valor Máximo de Saque.",
  "Max Withdrawable Amount": "Valor Máximo de Saque",
  "XP Explainer": "Explicação de XP",
  "Pool": "Nível",
  "Quote Quality": "Qualidade da cotação",
  "Create Your Public Username": "Crie seu nome de usuário público",
  "Edit Your Public Username": "Edite seu nome de usuário público",
  "You have been logged out.": "Você foi desconectado.",
  "Maker Volume Score": "Pontuação de Volume do Maker",
  "Fee Score": "Pontuação de Taxa",
  "Score Share": "Quota de Pontuação",
  "Instrument Share": "Quota de Instrumento",
  "XP": "XP",
  "Total XP": "XP Totais",
  "24h Change": "Mudança em 24h",
  "Leaderboard": "Classificação",
  "Cancel": "Cancelar",
  "Portfolio": "Portfólio",
  "Trade": "Trade",
  "Build": "Construir",
  "Referrals Explainer": "Explicador de Indicações",
  "How It Works": "Como Funciona",
  "My Referrals": "Minhas Indicações",
  "Your Referral Code": "Seu Código de Indicação",
  "Affiliate Program": "Programa de Afiliados",
  "Enrolled": "Matriculado",
  "Traders Referred": "Traders Indicados",
  "Referral Rewards": "Recompensas de Indicação",
  "Referral XP": "XP de Indicação",
  "Invite Friends": "Convidar Amigos",
  "Share": "Compartilhar",
  "Earn": "Ganhar",
  "Give": "Dar",
  "Share your code to invite friends to join Paradex":
    "Compartilhe seu código para convidar amigos para se juntarem à Paradex",
  "<1>{{amount}}</1> Fees": "<1>{{amount}}</1> de Taxas",
  "You receive {{amount}} of the referred user's fees":
    "Você recebe {{amount}} das taxas do usuário indicado",
  "<1>{{amount}}</1> XP": "<1>{{amount}}</1> de XP",
  "You receive {{amount}} of the referred user's XP":
    "Você recebe {{amount}} dos XP do usuário indicado",
  "<1>{{amount}}</1> Discount": "<1>{{amount}}</1> de Desconto",
  "Your friend receives {{amount}} discount on fees":
    "Seu amigo recebe {{amount}} de desconto nas taxas",
  "Create and share your custom referral code":
    "Crie e compartilhe seu código de indicação personalizado",
  "If you have a strong social media following (<1>{{followers}}+</1>), you may be eligible for our <2>VIP Affiliate Program</2> with higher commissions and XP.":
    "Se você tem uma presença forte nas redes sociais (<1>{{followers}}+</1>), você pode ser elegível para o nosso <2>Programa de Afiliados VIP</2> com comissões e XP maiores.",
  "You can track our <1>Affiliate Leaderboard</1> to see how you stack up.":
    "Você pode acompanhar nosso <1>Ranking de Afiliados</1> para ver como você se sai.",
  "Apply": "Aplicar",
  "Log in to see your Referral History.":
    "Faça login para ver seu histórico de indicações.",
  "Welcome to Paradex": "Bem-vindo à Paradex",
  "Congrats! The referral code you used is now active! It means you can start enjoying a discount on your trading fees!":
    "Parabéns! O código de indicação que você usou agora está ativo! Isso significa que você pode começar a aproveitar um desconto nas suas taxas de negociação!",
  "Congrats! The referral code <1>{{codeProvided}}</1> used is now active!":
    "Parabéns! O código de indicação <1>{{codeProvided}}</1> usado está ativo!",
  "Referral code not activated. The code can only be applied once during the initial onboarding.":
    "Código de indicação não ativado. O código só pode ser aplicado uma vez durante o onboarding inicial.",
  "Referral code <1>{{codeProvided}}</1> not activated. You have already onboarded with <1>{{referredBy}}</1>.":
    "Código de indicação <1>{{codeProvided}}</1> não ativado. Você já fez o onboarding com <1>{{referredBy}}</1>.",
  "Referral Code Applied": "Código de Indicação Aplicado",
  "You will receive <1>{{userAmount}}</1> of referred user's <1>fees</1> <2>and</2> <1>{{userAmountPoints}} XP</1>. They will receive a <1>{{friendAmount}}</1> discount.":
    "Você receberá <1>{{userAmount}}</1> das <1>taxas</1> do usuário indicado <2>e</2> <1>{{userAmountPoints}} XP</1>. Eles receberão um desconto de <1>{{friendAmount}}</1>.",
  "Community": "Comunidade",
  "Environments": "Ambientes",
  "System Status": "Status do Sistema",
  "Language": "Idioma",
  "Docs": "Documentação",
  "Code Samples": "Exemplos de Código",
  "More": "Mais",
  "Stats": "Estatísticas",
  "Join Discord": "Junte-se ao Discord",
  "Notifications": "Notificações",
  "All caught up!": "Tudo atualizado!",
  "You have no new notifications": "Você não tem novas notificações",
  "Connect Wallet": "Conectar Carteira",
  "WalletConnect is taking too long to open. Please refresh the page and try again.":
    "WalletConnect está demorando muito para abrir. Por favor, atualize a página e tente novamente.",
  "Unexpected error opening WalletConnect modal. Please refresh the page and try again.":
    "Erro inesperado ao abrir o modal WalletConnect. Por favor, atualize a página e tente novamente.",
  "Connect your Wallet": "Conecte sua Carteira",
  "Link Wallet": "Associar Carteira",
  "You will receive a signature request. Signing is free and will not send a transaction.":
    "Você receberá uma solicitação de assinatura. Assinar é gratuito e não enviará uma transação.",
  "Generate Paradex Chain wallet": "Gerar carteira Paradex Chain",
  "Generating Paradex Chain wallet": "Gerando carteira Paradex Chain",
  "Paradex Chain wallet generated": "Carteira Paradex Chain gerada",
  "Verify that you own this wallet": "Verificar que você possui esta carteira",
  "Remember Me": "Permanecer conectado",
  "Only use 'Remember Me' if you are using a secure device you own. Selecting this option can expose your information to  others if you are accessing this wallet from a public device.":
    'Use "Permanecer conectado" apenas se estiver usando um dispositivo seguro próprio. Selecionar esta opção pode expor suas informações a outros se você estiver acessando esta carteira de um dispositivo público.',
  "Continue": "Continuar",
  "Disconnect Wallet": "Desconectar Carteira",
  "Disconnect": "Desconectar",
  "Account": "Conta",
  "Username": "Nome de usuário",
  "Edit": "Editar",
  "Your username is now hidden. It is not visible to other users.":
    "Seu nome de usuário agora está oculto. Não é visível para outros usuários.",
  "Your username will be visible to other users unless you choose to hide it. You can always change it later.":
    "Seu nome de usuário será visível para outros usuários, a menos que você escolha ocultá-lo. Você sempre pode mudá-lo mais tarde.",
  "Hide my Username": "Ocultar meu nome de usuário",
  "Save": "Salvar",
  "Username is required": "Nome de usuário é obrigatório",
  "Username must be between 5 and 20 characters":
    "O nome de usuário deve ter entre 5 e 20 caracteres",
  "Username can only contain letters and numbers":
    "O nome de usuário só pode conter letras e números",
  "Wallets": "Carteiras",
  "Paradex Block Explorer": "Explorador de Blocos da Paradex",
  "Log out": "Sair",
  "No markets available": "Nenhum mercado disponível",
  "Last Price": "Último Preço",
  "Open Interest": "Contratos em Aberto",
  "24h Volume": "Volume 24h",
  "Mark Price": "Marcação de Mercado",
  "Spot Price": "Preço à vista",
  "Eight hour funding rate that is continuously accrued on positions and settled upon any trade. If the funding rate is positive, longs pay shorts. If negative, shorts pay longs.":
    "Taxa de financiamento de 8 horas que é continuamente acumulada em posições e liquidada em qualquer negociação. Se a taxa de financiamento for positiva, os longos pagam os shorts. Se for negativa, os shorts pagam os longos.",
  "8h Funding": "Financiamento de 8h",
  "Limit": "Limite",
  "Market": "Mercado",
  "Stop Limit": "Stop Limitada",
  "Stop Market": "Stop a Mercado",
  "Scaled Order": "Ordem Escalonada",
  "Trigger Price": "Preço de Disparo",
  "Input Price": "Preço de Entrada",
  "Position Mark Price": "Preço de Marcação da Posição",
  "Amount": "Quantidade",
  "Reduce Only": "Apenas Reduzir",
  "Reduce Only will increase position": "Apenas Reduzir aumentará a posição",
  "Your order stays open until it is filled or you decide to cancel.":
    "Sua ordem permanece aberta até ser preenchida ou você decidir cancelar.",
  "Your order gets filled right away, partially or fully, and whatever is left gets canceled instantly.":
    "Sua ordem é preenchida imediatamente, parcial ou totalmente, e o que sobrar é cancelado instantaneamente.",
  "Conditional limit order that serves to add liquidity to the order book as a Maker order.":
    "Ordem limite condicional que serve para adicionar liquidez ao livro de ordens como uma ordem de Maker.",
  "Currently the fees are fixed and uniform across the platform. Maker {{makerFee}} / Taker {{takerFee}}":
    "Atualmente, as taxas são fixas e uniformes em toda a plataforma. Maker {{makerFee}} / Taker {{takerFee}}",
  "Value": "Valor",
  "Buy": "Comprar",
  "BUY": "COMPRAR",
  "LONG": "LONG",
  "Sell": "Vender",
  "SELL": "VENDER",
  "SHORT": "SHORT",
  "Order Value": "Valor da ordem",
  "Current Position": "Posição atual",
  "Account Value": "Valor da Conta",
  "The total value of your account at current mark prices":
    "Valor total da sua conta nas marcações de mercado atuais",
  "Unrealized P&L": "P&L Não Realizado",
  "Free Collateral": "Colateral Livre",
  "Account Leverage": "Alavancagem da Conta",
  "Margin": "Margem",
  "Margin Ratio": "Taxa de margem",
  "Initial Margin": "Margem Inicial",
  "Maintenance Margin": "Margem de Manutenção",
  "Order Book": "Livro de Ordens",
  "Trades": "Negociações",
  "Price": "Preço",
  "Size": "Tamanho",
  "Total": "Total",
  "Time": "Hora",
  "Spread": "Spread",
  "Positions": "Posições",
  "Open Orders": "Ordens Abertas",
  "Trade History": "Histórico de Trades",
  "Order History": "Histórico de Ordens",
  "Funding Payments": "Pagamentos de Financiamento",
  "Side": "Lado",
  "Entry Price": "Preço de Entrada",
  "Liquidation Price": "Preço de Liquidação",
  "Breakeven Price": "Preço de Equilíbrio",
  "Unrealized Funding": "Financiamento Não Realizado",
  "Close Position": "Fechar Posição",
  "Market Close": "Fechamento a mercado",
  "Close Long": "Fechar Long",
  "Close Short": "Fechar Short",
  "Type": "Tipo",
  "Filled": "Preenchido",
  "Triggered": "Disparado",
  "Instruction": "Instrução",
  "Client Order Id": "Id de Ordem do Cliente",
  "Status": "Status",
  "NEW": "NOVO",
  "OPEN": "ABERTO",
  "UNTRIGGERED": "Não disparado",
  "CLOSED": "FECHADO",
  "Pending": "Pendente",
  "Complete": "Completo",
  "Completed": "Completo",
  "Failed": "Falhou",
  "Bust": "Falha",
  "Value / Fee": "Valor / Taxa",
  "Liquidity": "Liquidez",
  "Tx Hash": "Hash da Transação",
  "Trade Id": "ID do Trade",
  "Remaining": "Restante",
  "Cancel Reason": "Motivo do Cancelamento",
  "User Canceled": "Usuário Cancelou",
  "Not Enough Margin": "Margem Insuficiente",
  "Empty Market": "Mercado Vazio",
  "Post Only": "Apenas postar",
  "Post Only Would Cross": "Apenas postar cruzaria",
  "Remaining IOC Cancel": "Cancelamento Restante IOC",
  "Unexpected Failure": "Falha Inesperada",
  "Deleverage": "Desalavancagem",
  "In Liquidation": "Em Liquidação",
  "Self Trade": "Autotrade",
  "Asset Unavailable": "Ativo Indisponível",
  "Asset Expired": "Ativo Expirado",
  "Order Type Invalid": "Tipo de Ordem Inválido",
  "Price Not Available": "Preço Não Disponível",
  "Expired": "Expirado",
  "Exceeds Max Slippage": "Excede o máximo de deslizamento",
  "Timeout": "Tempo Esgotado",
  "Order Exceeds Position Limit": "Ordem Excede o Limite de Posição",
  "Order Id": "Id da Ordem",
  "Payment": "Pagamento",
  "Cancel All": "Cancelar Tudo",
  "{{orderSize}} will be closed at Market price":
    "{{orderSize}} será fechada ao preço de mercado",
  "By connecting a wallet, you agree to <1>Paradex Terms of Service</1> and represent and warrant to Paradex that you are not a <2>Restricted Person</2>.":
    "Ao conectar uma carteira, você concorda com os <1>Termos de Serviço da Paradex</1> e declara e garante à Paradex que não é uma <2>Pessoa Restrita</2>.",
  "Wallet": "Carteira",
  "Deposit_verb": "Depositar",
  "Deposit_noun": "Depósito",
  "Withdraw": "Sacar",
  "Withdrawal": "Saque",
  "Transfers": "Transferências",
  "Transfer": "Transferir",
  "Collateral": "Colateral",
  "Asset": "Ativo",
  "Wallet Balance": "Saldo da Carteira",
  "Actions": "Ações",
  "Action": "Ação",
  "External Tx Hash": "Hash da Transação Externa",
  "Withdraw to Wallet": "Sacar para Carteira",
  "Deposit to Paradex": "Depositar para Paradex",
  "You have no balance in your Ethereum wallet. Deposit some {{asset}} and try again.":
    "Você não tem saldo na sua carteira Ethereum. Deposite {{asset}} e tente novamente.",
  "It is not possible to deposit more than your current balance.":
    "Não é possível depositar mais do que o seu saldo atual.",
  "Available": "Disponível",
  "Initiate a deposit from your Ethereum wallet to the Paradex bridge. These transactions":
    "Inicie um depósito de sua carteira Ethereum para a pont bridge da Paradex. Essas transações",
  "Initiate a deposit from your Ethereum wallet to the Paradex bridge. These transactions may take several minutes depending on network conditions. For more information check out our <1>docs</1>.":
    "Inicie um depósito de sua carteira Ethereum para a pont bridge da Paradex. Essas transações podem levar vários minutos, dependendo das condições da rede. Para mais informações, consulte nossa <1>documentação</1>.",
  "read more": "leia mais",
  "Initiate Deposit to Bridge": "Iniciar Depósito para Ponte",
  "Enable USDC on Paradex": "Habilitar USDC na Paradex",
  "In order to deposit you must allow Paradex access to at least {{amount}}.":
    "Para depositar, você deve permitir que a Paradex acesse pelo menos {{amount}}.",
  "Enable USDC": "Habilitar USDC",
  "Withdraw initiated": "Saque iniciado",
  "Withdrawals are a two-step process. First initiate a withdrawal from Paradex":
    "Os saques são um processo de dois passos. Primeiro, inicie um saque do Paradex",
  "Withdrawals are a two-step process. First initiate a withdrawal from Paradex to the Paradex Bridge. This first step can take 5-16 hours (10 on average). Second, initiate your withdrawal from the Paradex Bridge to your Ethereum wallet via the Deposits / Withdrawals table. For more information check out our <1>docs</1>.":
    "Os saques são um processo de dois passos. Primeiro, inicie um saque do Paradex para a ponte Paradex. Este primeiro passo pode levar de 5 a 16 horas (10 em média). Em segundo lugar, inicie a seu saque da ponte Paradex para a sua carteira Ethereum através da tabela de Depósitos / Saques. Para mais informações, consulte nossa <1>documentação</1>.",
  "Initiate Withdrawal to Bridge": "Iniciar Saque para Ponte",
  "You have no funds to withdraw. It is not possible to withdraw more than your free collateral.":
    "Você não tem fundos para sacar. Não é possível sacar mais do que o seu colateral livre.",
  "It is not possible to withdraw more than your free collateral.":
    "Não é possível sacar mais do que o seu colateral livre.",
  "You have no collateral": "Você não tem colateral",
  "You have no open positions": "Você não tem posições abertas",
  "You have no open orders": "Você não tem ordens abertas",
  "You have no trades": "Você não tem negociações",
  "You have no orders": "Você não tem ordens",
  "You have no funding payments": "Você não tem pagamentos de financiamento",
  "You have no transfers": "Você não tem transferências",
  "Account in liquidation": "Conta em liquidação",
  "Account is being liquidated. Any open positions will be reduced. Trading now is disabled.":
    "A conta está sendo liquidada. Todas as posições abertas serão reduzidas. A negociação agora está desativada.",
  "Account liquidated": "Conta liquidada",
  "Your account was liquidated on {{createdAt}}. Any open positions were reduced.":
    "Sua conta foi liquidada em {{createdAt}}. Todas as posições abertas foram reduzidas.",
  "Deposit to Paradex successful": "Depósito para Paradex bem-sucedido",
  "Deposit to Paradex failed": "Depósito para Paradex falhou",
  "Unexpected error. Try again.": "Erro inesperado. Tente novamente.",
  "Deposit initiated": "Depósito iniciado",
  "Deposit to bridge initiated": "Depósito para ponte iniciado",
  "Deposit to Paradex available": "Depósito para Paradex disponível",
  "Go to Deposits": "Ir para Depósitos",
  "Deposit to Paradex in progress": "Depósito para Paradex em andamento",
  "Transfer successful": "Transferência bem-sucedida",
  "Limit {{side}} Order Fill": "Preenchimento de Ordem Limitada {{side}}",
  "Market {{side}} Order Fill": "Preenchimento de Ordem a Mercado {{side}}",
  "{{type}} {{side}} Order Submitted": "Ordem {{side}} {{type}} Submetida",
  "{{type}} {{side}} Order Canceled": "Ordem {{side}} {{type}} Cancelada",
  "Reason": "Razão",
  "Trade Busted On-Chain": "Trade falhou on-chain",
  "Fill id": "Id de preenchimento",
  "Withdraw to wallet failed": "Falha no saque para a carteira",
  "Withdrawal to bridge initiated": "Saque para a ponte iniciada",
  "Withdrawal error. Try again.": "Erro de saque. Tente novamente.",
  "Withdrawal to bridge in progress": "Saque para a ponte em andamento",
  "Withdrawal to wallet initiated": "Saque para a carteira iniciada",
  "Withdrawal to bridge failed": "Falha no saque para a ponte",
  "Transaction error. Try again.": "Erro de transação. Tente novamente.",
  "Withdrawal to wallet available": "Saque para a carteira disponível",
  "Go to Withdrawals": "Ir para Saques",
  "Withdrawal to wallet successful":
    "Saque para a carteira completado com sucesso",
  "Session expired, please log in again.":
    "Sessão expirada, por favor, faça login novamente.",
  "Failed to submit order": "Falha ao enviar o pedido",
  "Your username preferences were updated!":
    "Suas preferências de nome de usuário foram atualizadas!",
  "Unexpected error updating username preferences":
    "Erro inesperado ao atualizar as preferências de nome de usuário",
  "This username already exists. Please choose a different username and try again.":
    "Este nome de usuário já existe. Por favor, escolha um nome de usuário diferente e tente novamente.",
  "Validation Error": "Erro de validação",
  "Binding Error": "Erro de vinculação",
  "Internal Error": "Erro interno",
  "Not Found": "Não encontrado",
  "Service Unavailable": "Serviço indisponível",
  "Invalid Request Parameter": "Parâmetro de solicitação inválido",
  "Order Id Not Found": "Id do pedido não encontrado",
  "Order Is Closed": "Pedido está fechado",
  "Order Is Not Open Yet": "Pedido ainda não está aberto",
  "Client Order Id Not Found": "Id do pedido do cliente não encontrado",
  "Duplicated Client Id": "Id do cliente duplicado",
  "Invalid Price Precision": "Precisão de preço inválida",
  "Invalid Token": "Token inválido",
  "Invalid Ethereum Address": "Endereço Ethereum inválido",
  "Blocked Ethereum Address": "Endereço Ethereum bloqueado",
  "Invalid Ethereum Signature": "Assinatura Ethereum inválida",
  "Invalid Starknet Address": "Endereço Starknet inválido",
  "Invalid Starknet Signature": "Assinatura Starknet inválida",
  "Starknet Signature Verification Failed":
    "Falha na verificação da assinatura Starknet",
  "Bad Starknet Request": "Pedido Starknet ruim",
  "Ethereum Signer Mismatch": "Incompatibilidade de assinante Ethereum",
  "Ethereum Hash Mismatch": "Incompatibilidade de hash Ethereum",
  "Not Onboarded": "Conta não existente",
  "Invalid Timestamp": "Timestamp inválido",
  "Invalid Signature Expiration": "Expiração de assinatura inválida",
  "Account Not Found": "Conta não encontrada",
  "Invalid Order Signature": "Assinatura de ordem inválida",
  "Public Key Invalid": "Chave pública inválida",
  "Unauthorized Ethereum Address": "Endereço Ethereum não autorizado",
  "Ethereum Address Already Onboarded":
    "Endereço Ethereum já utilizado em uma conta existente",
  "Market Not Found": "Mercado não encontrado",
  "Allowlist Entry Not Found": "Entrada da lista de permissões não encontrada",
  "Username In Use": "Nome de usuário em uso",
  "Service access restricted in your region":
    "Acesso ao serviço restrito na sua região",
  "No Access Allowed": "Nenhum acesso permitido",
  "You do not have access to the platform. Please join our Discord to get access.":
    "Você não tem acesso à plataforma. Por favor, junte-se ao nosso Discord para obter acesso.",
  "{{actualChainName}} Wallet Connected": "Carteira Ethereum conectada",
  'Incorrect network selected "{{actualChainName}}". Please change the network to "{{requiredChainName}}" (id={{requiredChainId}}) in your wallet and retry.':
    'Rede selecionada incorreta "{{actualChainName}}". Por favor, mude a rede para "{{requiredChainName}}" (id={{requiredChainId}}) na sua carteira e tente novamente.',
  "Choose Wallet": "Escolha uma Carteira",
  "Starknet Wallet": "Carteira Starknet",
  "Switch to {{env}}": "Mudar para {{env}}",
  "Search": "Buscar",
  "League": "Liga",
  "Switch Account": "Trocar de Conta",
  "Rank": "Classificação",
  "24h XP": "XP 24h",
  "XP Per Day": "XP por Dia",
  "Season Volume": "Volume Temporada",
  "Lifetime Volume": "Volume Vitalício",
  "Pre-Season": "Pré-Temporada",
  "Earn More XP": "Ganhe mais XP",
  "XP Boost": "Impulso de XP",
  "XP Drop": "Queda de XP",
  "Trader": "Trader",
  "Total Share": "Quota Total",
  "Maker Share": "Quota de Maker",
  "Maker Volume Share": "Quota de Volume de Maker",
  "Fee Share": "Quota de Taxa",
  "Fee Volume Share": "Quota de Volume de Taxa",
  "No data available": "Nenhum dado disponível",
  "Refer & Earn": "Indique e Ganhe",
  "Hours": "Horas",
  "Minutes": "Minutos",
  "Time/Duration": "Tempo/Duração",
  "Runtime": "Tempo de execução",
  "Frequency": "Frequência",
  "Number of Orders": "Número de ordens",
  "Runtime should not be empty": "O tempo de execução não deve estar vazio",
  "Runtime should be less than or equal to 24 hours":
    "O tempo de execução deve ser menor ou igual a 24 horas",
  "Inactive": "Inativo",
  "Avg. Price": "Preço médio",
  "The minimum required amount for {{numOrders}} orders is {{totalSize}} {{currency}}.":
    "O valor mínimo exigido para {{numOrders}} ordens é {{totalSize}} {{currency}}.",
  "Take Profit / Stop Loss: Set take profit and stop loss price levels to automate trade actions. When the Mark Price will reach the price you set, it will send your order to the matching engine.":
    "Take Profit / Stop Loss: Defina os níveis de preço de take profit e stop loss para automatizar ações de negociação. Quando o Preço de Mercado atingir o preço definido, sua ordem será enviada para o motor de correspondência.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount with an estimated profit of <3 />.":
    "Quando o Preço de Mercado atingir <1 />, será acionada a ordem <2 /> para encerrar o montante selecionado com um lucro estimado de <3 />.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount with an estimated loss of <3 />.":
    "Quando o Preço de Mercado atingir <1 />, será acionada a ordem <2 /> para encerrar o montante selecionado com uma perda estimada de <3 />.",
  "Edit TP/SL For This Position": "Editar TP/SL para esta posição",
  "Take Profit": "Take Profit",
  "Profit": "Lucro",
  "Stop Loss": "Stop Loss",
  "Loss": "Perda",
  "Confirm": "Confirmar",
  "Take Profit Limit": "Limite de Take Profit",
  "Take Profit Market": "Take Profit a Mercado",
  "Stop Loss Limit": "Limite de Stop Loss",
  "Stop Loss Market": "Stop Loss a Mercado",
  "Funding": "Financiamento",
  "Unwinding": "Desenrolamento",
  "Cancel TP/SL orders": "Cancelar ordens TP/SL",
  "TP/SL orders submitted successfully.": "Ordens TP/SL enviadas com sucesso.",
  "Pre-Season + All Seasons": "Pré-Temporada + Todas as temporadas",
  "Season {{season}}": "Temporada {{season}}",
  "XP Season {{season}}": "Temporada {{season}} XP",
  "Slippage": "Deslizamento",
  "Max Slippage": "Deslizamento máximo",
  "Max slippage defines the largest acceptable price deviation from the mark price.":
    "O máximo deslizamento define o maior desvio de preço aceitável em relação ao preço de referência.",
  "During execution, the order can be canceled if the price exceeds the slippage limit or falls outside the Market <1>Price Band</1>.":
    "Durante a execução, o pedido pode ser cancelado se o preço exceder o limite de deslizamento ou sair da <1>faixa de preço</1> do mercado.",
  "Update Max Slippage": "Atualizar o máximo deslizamento",
  "The Maximum Slippage allowed for {{symbol}} is {{price_band_width}}.":
    "O deslizamento máximo permitido para {{symbol}} é {{price_band_width}}.",
  "Mark IV": "Mark IV",
  "Delta": "Delta",
  "Failed to load positions": "Falha ao carregar posições",
  "No open positions": "Sem posições abertas",
  "learn more": "saiba mais",
  "Learn More": "Saiba Mais",
  "Search Markets": "Pesquisar Mercados",
  "Futures": "Futuros",
  "Options": "Opções",
  "OI": "OI",
  "My Profile": "Meu Perfil",
  "Addresses": "Endereços",
  "Chart": "Gráfico",
  "ROI": "ROI",
  "Sharpe": "Sharpe",
  "Max Loss": "Máxima Perda",
  "Total Volume": "Volume Total",
  "My NFTs": "Meus NFTs",
  "My XP": "Meu XP",
  "Balances": "Saldos",
  "Other": "Outros",
  "Paradex XP: Pre-Season": "Paradex XP: Pré-Temporada",
  "Paradex XP: Season {{season}}": "Paradex XP: Temporada {{season}}",
  "Season {{season}} XP": "Temporada {{season}} XP",
  "Refer your friends to earn 10%": "Indique seus amigos e ganhe 10%",
  "Refer Friends and Earn 10%": "Indique seus amigos e ganhe 10%",
  "Invest in Paradex Vault & Earn 3X Boost":
    "Invista na Paradex Vault & Ganhe 3X Boost",
  "Avg. Daily XP": "XP médio diário",
  "Lifetime XP": "XP vitalício",
  "My Rank": "Meu Rank",
  "What is XP?": "O que é XP?",
  "XP History": "Histórico de XP",
  "Last Week XP": "XP da Semana Passada",
  "TVL XP": "XP de TVL",
  "OI XP": "XP de OI",
  "Liquidation XP": "XP de Liquidação",
  "Vaults XP": "XP de Cofres",
  "Week": "Semana",
  "Season": "Temporada",
  "Last updated: {{timestamp}}": "Última atualização: {{timestamp}}",
  "Xperience Points (XP)": "Pontos de Experiência (XP)",
  "Log in to see your XP balance": "Faça login para ver seu saldo de XP",
  "Log in to see where you stand": "Faça login para ver onde você está",
  "Connect your X account to join Pro League":
    "Conecte sua conta X para se inscrever na Liga Pro",
  "Avg. Fill Price": "Preço Médio de Execução",
  "Spot": "Spot",
  "Derivatives": "Derivativos",
  "P&L Chart": "Gráfico P&L",
  "Account Value Chart": "Gráfico de Valor da Conta",
  "Funding History": "Histórico de Financiamento",
  "Manage Funds": "Gerenciar Fundos",
  "Profile": "Perfil",
  "Community XP": "XP da Comunidade",
  "XP Share": "Parte de XP",
  "Next XP Drop in": "Próximo XP Drop em",
  "In Progress": "Em Andamento",
  "{{field}} must be greater than {{value}}":
    "{{field}} deve ser maior que {{value}}",
  "{{field}} must be less than {{value}}":
    "{{field}} deve ser menor que {{value}}",
  "Enter a valid Take Profit or Stop Loss price":
    "Insira um preço de Take Profit ou Stop Loss válido",
  "Take Profit and Stop Loss prices cannot be the same":
    "Os preços de Take Profit e Stop Loss não podem ser iguais",
  "Automatic Withdrawal": "Saque Automático",
  "Estimated Gas Fee": "Taxa de Gas Estimada",
  "Auto Withdrawal": "Saque Automático",
  "Withdrawing...": "Sacando...",
  "Paradex Bridge withdrawals can take between 5-16 hours to complete":
    "Os saques da Paradex Bridge podem demorar entre 5-16 horas para ser concluídos",
  "Socialized Loss": "Perda Socializada",
  "Receivable Amount": "Quantidade Recebível",
  "Auto withdrawing...": "Sacando automaticamente...",
  "Manage Twitter Connection": "Gerenciar conexão com Twitter",
  "Unlock additional functionality.": "Desbloqueie funcionalidades adicionais.",
  "Please note this connection can't be used as a login method to Paradex.":
    "Observe que esta conexão não pode ser usada como método de login no Paradex.",
  "Toast Notifications": "Notificações Toast",
  "Display toast notifications": "Exibir notificações Toast",
  "Average Entry Price": "Preço de Entrada Médio",
  "Display average entry price line on chart":
    "Exibir linha de preço de entrada médio no gráfico",
  "Display liquidation price line on chart":
    "Exibir linha de preço de liquidação no gráfico",
  "Display open order lines on chart":
    "Exibir linhas de ordens abertas no gráfico",
  "Failed to disconnect Twitter": "Falha ao desconectar o Twitter",
  "Failed to authorize Twitter": "Falha na autorização do Twitter",
  "Twitter account linked successfully!":
    "Conta do Twitter conectada com sucesso!",
  "Failed to link twitter account": "Falha ao conectar a conta do Twitter",
  "Fees": "Taxas",
  "Taker pays {{takerFee}} in fees. Maker receives {{makerRebate}} in rebates.":
    "Taker paga {{takerFee}} em taxas. Maker recebe {{makerRebate}} em reembolsos.",
  "Manage Discord Connection": "Gerenciar conexão do Discord",
  "Failed to authorize Discord": "Falha na autorização do Discord",
  "Discord account linked successfully!":
    "Conta do Discord conectada com sucesso!",
  "Failed to link Discord account": "Falha ao conectar a conta do Discord",
  "Failed to disconnect Discord": "Falha ao desconectar o Discord",
  "Connect": "Conectar",
  "Unrealized P&L from current vault token balance. This doesn't account for owner profit share or slippage from positions unwind.":
    "P&L não realizado a partir do saldo atual do token da bolsa. Isso não inclui o lucro do proprietário ou o deslizamento das posições de desenrolamento.",
  "Show P&L Amount": "Mostrar P&L",
  "Receivable amount must be positive":
    "A quantidade recebível deve ser positiva",
  "Wallet Address Change Detected": "Mudança de endereço de carteira detectada",
  "You can proceed with connecting your Paradex account to this wallet address or continue using your current connection.":
    "Você pode prosseguir conectando sua conta Paradex a este endereço de carteira ou continuar usando sua conexão atual.",
  "Connect To New Wallet": "Conectar à nova carteira",
  "Keep Using Current Wallet": "Continuar usando a carteira atual",
  "Connect Current Wallet": "Conectar a carteira atual",
  "The account is not connected. Please make sure that <1>{{walletAddress}}</1> is connected and is the active account in your <2>{{walletName}}</2> wallet.":
    "A conta não está conectada. Por favor, certifique-se de que <1>{{walletAddress}}</1> está conectada e é a conta ativa na sua carteira <2>{{walletName}}</2>.",
  "Reconnecting": "Reconectando",
  "There is a request in progress. Please proceed in your wallet.":
    "Há uma solicitação em andamento. Por favor, continue na sua carteira.",
  "Modify Order": "Modificar Ordem",
  "Realized P&L": "P&L Realizado",
  "Close All": "Fechar Tudo",
  "Close All Positions": "Fechar Todas as Posições",
  "Market Close All Positions": "Fechar todas posições a mercado",
  "Close {{percent}}% of All Positions":
    "Fechar {{percent}}% de todas as posições",
  "Failed to close all positions": "Falha ao fechar todas as posições",
  "Number of Positions": "Número de Posições",
  "Markets": "Mercados",
  "Position Value (Closing)": "Valor da Posição (Fechamento)",
  "{{count}} positions will be rounded to the nearest order increment":
    "{{count}} posições serão arredondadas para o incremento de ordem mais próximo",
  "You will earn Bonus XP on your referral activity":
    "Você ganhará XP Bonus pelas suas atividades de indicação",
  "Token Price": "Preço do Token",
  "Vault Token Price Chart": "Gráfico de preço do token do cofre",
  "Market Close Orders Submitted": "Ordens de fechamento a mercado enviadas",
  "This profile is already linked to a different Paradex account. Disconnect socials from existing account and try again.":
    "Este perfil já está vinculado a uma conta Paradex diferente. Desvincule suas redes sociais da conta atual e tente novamente.",
  "XP Drops every Friday": "XP cai toda sexta-feira",
  "No XP Data Available": "Nenhum dado de XP disponível",
  "XP data is available only for the Main Account.":
    "Os dados de XP estão disponíveis apenas para a conta principal.",
  "Please switch to your Main Account to view your XP.":
    "Por favor, mude para sua conta principal para ver seu XP.",
  "Referrals Not Available": "Indicações não disponíveis",
  "Referrals are available only for the Main Account.":
    "As indicações estão disponíveis apenas para a conta principal.",
  "Please switch to your Main Account to view your Referrals.":
    "Por favor, mude para sua conta principal para ver suas indicações.",
  "Deposit initiated via Layerswap": "Depósito iniciado via Layerswap",
  "Position History": "Histórico de Posições",
  "Avg. Close Price": "Preço Médio de Fechamento",
  "Max Size": "Tamanho Máximo",
  "Closed Size": "Tamanho Fechado",
  "Opened": "Aberto",
  "You have no positions": "Você não tem posições",
  "Destination Address": "Endereço de Destino",
  "Withdrawal via Layerswap initiated": "Saque via Layerswap iniciado",
  "Withdrawal via Layerswap failed": "Saque via Layerswap falhou",
  "Market Share": "Market Share",
  "Tier": "Tier",
  "Avg Maker Volume": "Volume Maker Médio",
  "Liquidity Score": "Pontuação de Liquidez",
  "Reward Share": "Parte de Recompensa",
  "Enter amount": "Digite a quantidade",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount at <3 /> with an estimated profit of <4 />.":
    "Quando o Preço de Mercado atingir <1 />, uma ordem <2 /> será acionada para fechar a quantidade selecionada em <3 /> com um lucro estimado de <4 />.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount at <3 /> with an estimated loss of <4 />.":
    "Quando o Preço de Mercado atingir <1 />, uma ordem <2 /> será acionada para fechar a quantidade selecionada em <3 /> com uma perda estimada de <4 />.",
  "Limit Price": "Preço Limite",
  "Delete": "Excluir",
  "Use other bridges from the list above to withdraw funds much faster.":
    "Use outras pontes da lista acima para sacar fundos muito mais rápido.",
  "Paradex Chain Monitor": "Monitor de Cadeia Paradex",
  "OTM Amount": "Quantidade OTM",
  "Leverage": "Alavancagem",
  "Strikes": "Strikes",
  "Strike": "Strike",
  "Change": "Alteração",
  "Dist. to Spot": "Dist. ao Spot",
  "Bid": "Compra",
  "Mark": "Mark",
  "Ask": "Venda",
  "IV": "VI",
  "Dist %": "Dist %",
  "Call": "Call",
  "Put": "Put",
  "Mark Price / IV": "Preço Mark / VI",
  "8h F-Funding": "Financiamento 8h F",
  "No Strikes Available": "Nenhum Strike Disponível",
  "Attributes": "Atributos",
  "Why no Expiries?": "Por que não há Vencimentos?",
  "{{multiplier}}x Boost": "Impulso {{multiplier}}x",
  "Like perpetual futures, perpetual options have no expiration, offering continuous exposure to the underlying asset with built-in downside protection. This gives users the flexibility to hold their position indefinitely and decide the optimal time to close it based on their strategy":
    "Como os futuros perpétuos, as opções perpétuas não têm vencimento, oferecendo exposição contínua ao ativo subjacente com proteção contra queda integrada. Isso dá aos usuários a flexibilidade de manter sua posição indefinidamente e decidir o momento ideal para fechá-la com base em sua estratégia",
  "Implied Volatility used to calculate the option's Mark Price":
    "Volatilidade Implícita usada para calcular o Preço Mark da opção",
  "Distance between the Strike Price and the underlying Spot Price":
    "Distância entre o Preço Strike e o Preço Spot subjacente",
  "Eight hour funding that is continuously accrued on positions and settled upon any trade. If the funding rate is positive, longs pay shorts. If negative, shorts pay longs":
    "Financiamento de oito horas que é continuamente acumulado nas posições e liquidado em qualquer negociação. Se a taxa de financiamento for positiva, longs pagam shorts. Se negativa, shorts pagam longs",
  "The Leverage Ratio (Lambda) measures an option's effective leverage by indicating how much the option's value changes, in percentage terms, for a 1% change in the spot price of the underlying asset. For example, if an option has a Lambda of 50 and the spot price increases by 1%, the option's value would increase by 50%":
    "O Índice de Alavancagem (Lambda) mede a alavancagem efetiva de uma opção indicando quanto o valor da opção muda, em termos percentuais, para uma mudança de 1% no preço spot do ativo subjacente. Por exemplo, se uma opção tem um Lambda de 50 e o preço spot aumenta 1%, o valor da opção aumentaria 50%",
  "Option delta measures how much the price of an option is expected to change for a $1 change in the price of the underlying asset. For example, a delta of 0.5 means the option price will move $0.50 for every $1 move in the underlying":
    "O delta da opção mede quanto o preço de uma opção deve mudar para uma mudança de $1 no preço do ativo subjacente. Por exemplo, um delta de 0,5 significa que o preço da opção se moverá $0,50 para cada movimento de $1 no subjacente",
  "Eight hour funding rate of the perpetual future on the same underlying asset":
    "Taxa de financiamento de oito horas do futuro perpétuo no mesmo ativo subjacente",
  "Having trouble? Try signing out and connecting again.":
    "Problemas? Tente sair e conectar novamente.",
  "API Reference": "Referência da API",
  "XP Distribution occurs once per week on Friday at ~12 PM UTC":
    "A distribuição de XP ocorre uma vez por semana na sexta-feira às ~12h UTC",
  "What are Perpetual Options?": "O que são Opções Perpétuas?",
  "Delta represents the increase in option price based on a $1 increase in the underlying spot price.\n\nFor example, a delta of 0.50 means the price of the option is expected to increase by $0.50 for a $1 increase in the underlying spot price.":
    "Delta representa o aumento no preço da opção baseado em um aumento de $1 no preço spot subjacente.\n\nPor exemplo, um delta de 0,50 significa que o preço da opção deve aumentar $0,50 para um aumento de $1 no preço spot subjacente.",
  "Leverage(Lambda) represents the percentage change in option price for a 1% change in the underlying spot price.\n\nFor example, a lambda of 10 means the option price is expected to increase by 10% if the underlying spot price increases by 1%.":
    "Alavancagem(Lambda) representa a mudança percentual no preço da opção para uma mudança de 1% no preço spot subjacente.\n\nPor exemplo, um lambda de 10 significa que o preço da opção deve aumentar 10% se o preço spot subjacente aumentar 1%.",
  "Eight hour options funding rate that is continuously accrued on positions and settled upon any trade. The funding rate for perpetual options reflects both the cost of leverage and the cost of insurance against adverse price moves. \n\nThe funding period of perpetual options is 24 hours. This means that the option premium will be continuously paid out over a 24h period. 24h funding rate = 3 x 8h funding rate":
    "Taxa de financiamento de opções de oito horas que é continuamente acumulada nas posições e liquidada em qualquer negociação. A taxa de financiamento para opções perpétuas reflete tanto o custo da alavancagem quanto o custo do seguro contra movimentos adversos de preço. \n\nO período de financiamento das opções perpétuas é de 24 horas. Isso significa que o prêmio da opção será pago continuamente durante um período de 24h. Taxa de financiamento 24h = 3 x taxa de financiamento 8h",
  "Risk": "Risco",
  "Vega": "Vega",
  "Gamma": "Gamma",
  "Estimated Funding P&L for the next 8 hours based on current funding rates":
    "P&L de Financiamento Estimado para as próximas 8 horas com base nas taxas de financiamento atuais",
  "Vega represents the increase in option price based on a 1% increase in the implied volatility.\n\nFor example, a Vega of 5 means the price of the option is expected to increase by $5 for a 1% increase in the implied volatility.":
    "Vega representa o aumento no preço da opção baseado em um aumento de 1% na volatilidade implícita.\n\nPor exemplo, um Vega de 5 significa que o preço da opção deve aumentar $5 para um aumento de 1% na volatilidade implícita.",
  "Adjust Leverage": "Ajustar Alavancagem",
  "Leverage updated successfully": "Alavancagem atualizada com sucesso",
  "Failed to update leverage": "Falha ao atualizar alavancagem",
  "Adjust leverage parameters for selected market. Leverage will also increase on your open positions.":
    "Ajuste os parâmetros de alavancagem para o mercado selecionado. A alavancagem também aumentará em suas posições abertas.",
  "Please note that changing leverage will also apply for open positions and open orders on this market.":
    "Observe que a alteração da alavancagem também se aplicará a posições abertas e ordens abertas neste mercado.",
  "Selecting higher leverage could result in Deleveraging where some of your open orders would be cancelled.":
    "Selecionar uma alavancagem maior pode resultar em Desalavancagem onde algumas de suas ordens abertas seriam canceladas.",
  "disabled": "desativado",
  "Margin Mode": "Modo Margem",
  "Manage your risk on individual positions by restricting the amount of margin allocated to each. If the margin ratio of an isolated position reaches 100%, the position will be liquidated. Margin can be added or removed to individual positions in this mode.":
    "Gerencie seu risco em posições individuais restringindo a quantidade de margem alocada para cada uma. Se a razão de margem de uma posição isolada atingir 100%, a posição será liquidada. Margem pode ser adicionada ou removida de posições individuais neste modo.",
  "Margin mode updated successfully": "Modo margem atualizado com sucesso",
  "Failed to update margin mode": "Falha ao atualizar modo margem",
  "Favorites": "Favoritos",
  "Trending": "Em alta",
  "Losers": "Perdedores",
  "Gainers": "Ganhadores",
  "New Listing": "Nova listagem",
  "Developers": "Desenvolvedores",
  "Developer Mode": "Modo Desenvolvedor",
  "Enable developer mode": "Ativar modo desenvolvedor",
  "For external use": "Para uso externo",
  "Warning: Changing transfer behavior":
    "Aviso: Alterando o comportamento de transferência",
  "USDC will be transferred to account contract instead of Paradex. Amount won't be visible or available for use within Paradex, but can be used with external applications.":
    "USDC será transferido para o contrato de conta em vez de Paradex. A quantidade não será visível ou disponível para uso no Paradex, mas pode ser usada com aplicativos externos.",
  "Affiliate Dashboard": "Painel de Afiliados",
};

export default translations;
