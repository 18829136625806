import * as Toastify from 'react-toastify';

import { Fill } from '#/api/fills';
import { Order } from '#/api/orders';

import * as FillFn from '#/features/fill/functions';
import * as OrderFn from '#/features/order/functions';

import { FillNotificationEntry, OrderNotificationEntry } from './types';

export function getOrderNotificationType(
  order: Order,
): OrderNotificationEntry['kind'] | null {
  if (OrderFn.isMarketOrder(order) && OrderFn.hadSomeFill(order)) {
    return 'filled-market-order';
  }
  if (OrderFn.isMarketOrder(order) && OrderFn.isCancelledOrder(order)) {
    return 'cancelled-market-order';
  }
  if (OrderFn.isLimitOrder(order) && OrderFn.isCancelledOrder(order)) {
    return 'cancelled-limit-order';
  }
  if (OrderFn.isLimitOrder(order) && OrderFn.isModifiedOrderSuccess(order)) {
    return 'modify-order-success';
  }
  if (OrderFn.isLimitOrder(order) && OrderFn.isModifiedOrderRejected(order)) {
    return 'modify-order-rejected';
  }
  return null;
}

export function getFillNotificationType(
  fill: Fill,
  order: Order | null,
): FillNotificationEntry['kind'] | null {
  if (order != null && !OrderFn.isMarketOrder(order)) {
    return 'limit-order-fill';
  }
  if (FillFn.isLiquidationFill(fill)) {
    return 'liquidation-fill';
  }

  return null;
}

export function closeAllNotifications() {
  Toastify.toast.clearWaitingQueue();
  Toastify.toast.dismiss();
}
