export class UnauthorizedError extends Error {
  constructor(message?: string, options?: ErrorOptions) {
    super(message, options);
    this.name = 'UnauthorizedError';
  }
}

export class AbortError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'AbortError';
  }
}

export class TimeoutError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'TimeoutError';
  }
}

export function isTimeoutError(error: Error): boolean {
  return isError('TimeoutError', error);
}

export function isAbortError(error: Error): boolean {
  return isError('AbortError', error);
}

export function isError(name: string, error: Error): boolean {
  if (error.name === name) {
    return true;
  }
  if (error.cause instanceof Error) {
    return isError(name, error.cause);
  }
  return false;
}

export function isUserRejectedRequestError(error: unknown): boolean {
  if (!(error instanceof Error)) return false;
  return isError('UserRejectedRequestError', error);
}
