import BigNumber from 'bignumber.js';

import { Market, PerpetualMarket } from '#/api/markets';
import { PerpetualMarketSummary } from '#/api/markets-summary';

import * as MarketFn from '#/features/market/functions';

import { formatUsd } from '#/utils/fiat';
import { formatPercent } from '#/utils/percent';
import { FormattedChange, FormattedValue } from '#/utils/types';

export function fundingRateView(
  market: Pick<Market, 'funding_period_hours'>,
  marketSummary?: PerpetualMarketSummary,
): FormattedValue<BigNumber | null> {
  const value = marketSummary?.funding_rate;
  if (value == null) return { value: null, formatted: '-' };

  const fundingRate8h = MarketFn.calcFundingRate8h(
    value,
    market.funding_period_hours,
  );
  const formatted = formatPercent(fundingRate8h, {
    maximumFractionDigits: 4,
    minimumFractionDigits: 4,
  });
  return { value, formatted };
}

export function fundingRateAmountView(
  market: PerpetualMarket,
  marketSummary?: PerpetualMarketSummary,
): FormattedValue<BigNumber | null> {
  const fundingRate = marketSummary?.funding_rate;
  const spotPrice = marketSummary?.underlying_price;
  if (fundingRate == null) return { value: null, formatted: '-' };
  if (spotPrice == null) return { value: null, formatted: '-' };

  const fundingRate8h = MarketFn.calcFundingRate8h(
    fundingRate,
    market.funding_period_hours,
  );
  const value = fundingRate8h.times(spotPrice);
  const decimalPlaces = MarketFn.quoteCurrencyDecimalPlaces(market);
  const formatted = value.toFormat(decimalPlaces);
  return { value, formatted };
}

export function pnlView(pnl: BigNumber | null): FormattedChange {
  if (pnl == null) return { formatted: '-', direction: null };
  const formatted = formatUsd(pnl, { signDisplay: 'always' });
  const direction = pnl.isPositive() ? 'up' : 'down';
  return { formatted, direction };
}

export function quotePriceView(market: Market, price: BigNumber): string {
  const decimalPlaces = MarketFn.quoteCurrencyDecimalPlaces(market);
  return price.toFormat(decimalPlaces);
}
