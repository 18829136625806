import { pickBy } from 'lodash';

import customCssPath from '#/assets/tradingview.vendor.css';

import { ScreenSizeView } from '#/features/layout/screen-size-context';
import { logException } from '#/features/logging/logging';
import tradingViewSettings from '#/features/storage/trading-view-settings';

import * as TradingView from '#/vendor/charting_library';

import {
  DESKTOP_STYLES,
  LOADING_SCREEN_STYLES,
  MOBILE_STYLES,
  STUDIES_STYLES,
} from './styling';

const INTERVAL = '60';

const getOptions = (
  options: CreateWidgetOptions,
): TradingView.ChartingLibraryWidgetOptions => ({
  container: options.container,
  symbol: options.symbol,
  autosize: true,
  /**
   * For available disabled_features, enabled_features:
   * @see https://charting-library.tradingview-widget.com/featuresets.html
   * @see https://github.com/tradeparadigm/tradingview_charting_library/wiki/Featuresets
   */
  disabled_features:
    options.size === 'sm'
      ? [
          'header_compare',
          'header_fullscreen_button',
          'header_quick_search',
          'header_screenshot',
          'header_settings',
          'header_symbol_search',
          'header_undo_redo',
          'left_toolbar',
          'source_selection_markers',
          'timeframes_toolbar',
        ]
      : [
          'header_compare',
          'header_quick_search',
          'header_screenshot',
          'header_settings',
          'header_symbol_search',
          'header_undo_redo',
          'timeframes_toolbar',
        ],
  enabled_features: [
    'symbol_info_price_source',
    // Prevents usage of blob urls which is not supported in browser wallets such as Rabby
    'iframe_loading_compatibility_mode',
  ],
  favorites: {
    intervals: [
      '1',
      '5',
      '15',
      '30',
      '60',
      '240',
      '1D',
    ] as TradingView.ResolutionString[],
  },
  interval: INTERVAL as TradingView.ChartingLibraryWidgetOptions['interval'],
  timezone: 'exchange',
  datafeed: options.datafeed,
  theme: 'dark',
  locale: 'en',
  library_path: `/vendor/charting_library/`,
  client_id: 'paradex',
  fullscreen: false,
  custom_css_url: customCssPath,
  /**
   * Customize font family for the chart.
   * To customize fonts outside of the chart see `custom_css_url`.
   * @see https://www.tradingview.com/charting-library-docs/latest/api/interfaces/Charting_Library.ChartingLibraryWidgetOptions#custom_font_family
   */
  custom_font_family: "'HarmonyOS Sans', sans-serif",
  /**
   * Override styles for chart
   * @see https://github.com/tradeparadigm/tradingview_charting_library/wiki/Overrides
   */
  overrides: options.size === 'sm' ? MOBILE_STYLES : DESKTOP_STYLES,
  /**
   * Override styles for indicators
   * @see https://github.com/tradeparadigm/tradingview_charting_library/wiki/Studies-Overrides
   */
  studies_overrides: STUDIES_STYLES,
  loading_screen: LOADING_SCREEN_STYLES,
  /**
   * Delay to call save function after a chart setting is changed
   */
  auto_save_delay: 1,
});

export type TradingViewWidget = TradingView.IChartingLibraryWidget;
export const PRICE_KINDS = ['last', 'mark'] as const;
export type PriceKind = (typeof PRICE_KINDS)[number];

interface CreateWidgetOptions {
  size: ScreenSizeView;
  container: TradingView.ChartingLibraryWidgetOptions['container'];
  symbol: TradingView.ChartingLibraryWidgetOptions['symbol'];
  datafeed: TradingView.ChartingLibraryWidgetOptions['datafeed'];
}

export function createWidget(options: CreateWidgetOptions): TradingViewWidget {
  const widgetOptions = getOptions(options);
  // eslint-disable-next-line new-cap
  const widget = new TradingView.widget({
    ...widgetOptions,
    saved_data: tradingViewSettings.get(),
    // Keep timeframe to be auto-set by TradingView
    timeframe: undefined,
  });

  widget.onChartReady(() => {
    widget.subscribe('onAutoSaveNeeded', () =>
      widget.save(tradingViewSettings.set),
    );
    overridePaneProperties(widget, widgetOptions);
    createVolumeStudy(widget);
  });

  return widget;
}

/**
 * Creates volume study if not already present
 */
function createVolumeStudy(widget: TradingViewWidget) {
  const chart = widget.activeChart();

  const hasVolumeStudy = chart
    .getAllStudies()
    .some((study) => study.name === 'Volume');
  if (hasVolumeStudy) return;

  chart.createStudy('Volume', true, true).catch((cause) => {
    const message = 'Failed to create volume study';
    const error = new Error(message, { cause: cause as Error });
    logException(error);
  });
}

function overridePaneProperties(
  widget: TradingViewWidget,
  options: TradingView.ChartingLibraryWidgetOptions,
) {
  widget.applyOverrides(
    pickBy(options.overrides, (_, key) => key.startsWith('paneProperties')),
  );
}
