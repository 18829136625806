import { createLayerswapSwap, LayerswapSwapReq } from '#/api/bridges';

export async function submitSwapRequest(
  amount: string,
  destinationChain: string,
  sourceChain: string,
  sourceAddress: string,
  destinationAddress: string,
) {
  const req = prepareRequest(
    amount,
    destinationChain,
    sourceChain,
    sourceAddress,
    destinationAddress,
  );
  const resp = await createLayerswapSwap(req);
  return resp;
}

function prepareRequest(
  amount: string,
  destinationChain: string,
  sourceChain: string,
  sourceAddress: string,
  destinationAddress: string,
): LayerswapSwapReq {
  const request = {
    amount,
    destination_chain: destinationChain,
    source_chain: sourceChain,
    source_address: sourceAddress,
    destination_address: destinationAddress,
  } as const;
  return request;
}
