import BigNumber from 'bignumber.js';

import { msToDate } from '#/utils/date';
import { DecimalOrEmptyString, DecimalString } from '#/utils/types';

import {
  AsyncResp,
  BasePaginatedReq,
  BaseReq,
  PaginatedRespData,
  requestApi,
} from './fetch-api';
import { UnixTimeMs } from './types';

export type PositionSide = 'LONG' | 'SHORT';

type PositionStatus = 'OPEN' | 'CLOSED' | 'UNKNOWN';

export interface RawPosition {
  readonly id: string;
  /**
   * Unique identifier for the market that this order will be sent to.
   * Also serves as the unique identifier.
   */
  readonly market: string;
  readonly account: string;
  readonly status: PositionStatus;
  /** Direction of the order */
  readonly side: PositionSide;
  readonly size: DecimalString;
  /** Average entry price in the settlement currency */
  readonly average_entry_price: DecimalString;
  /** Average entry price in USD */
  readonly average_entry_price_usd: DecimalString;
  /** Average exit price in the settlement currency */
  readonly average_exit_price: DecimalString;
  /** Unrealized P&L of the position in the quote currency using the market's index price */
  readonly unrealized_pnl: DecimalString;
  /** Unrealized funding P&L of the position in the quote currency */
  readonly unrealized_funding_pnl: DecimalString;
  /** Realized P&L of the position in the quote currency */
  readonly realized_pnl: DecimalString;
  readonly last_updated_at: UnixTimeMs;
  readonly created_at?: UnixTimeMs;
  readonly closed_at?: UnixTimeMs;
  readonly liquidation_price: DecimalOrEmptyString;
  readonly leverage: DecimalOrEmptyString;
  /** Realized P&L of the position in the quote currency */
  readonly realized_positional_pnl: DecimalOrEmptyString;
}

export interface Position
  extends Omit<
    RawPosition,
    | 'last_updated_at'
    | 'created_at'
    | 'closed_at'
    | 'size'
    | 'average_entry_price'
    | 'average_entry_price_usd'
    | 'average_exit_price'
    | 'unrealized_pnl'
    | 'unrealized_funding_pnl'
    | 'liquidation_price'
    | 'leverage'
    | 'realized_positional_pnl'
  > {
  readonly last_updated_at: Date;
  readonly created_at: Date | null;
  readonly closed_at: Date | null;
  readonly size: BigNumber;
  readonly average_entry_price: BigNumber;
  readonly average_entry_price_usd: BigNumber;
  readonly average_exit_price: BigNumber | null;
  readonly unrealized_pnl: BigNumber;
  readonly unrealized_funding_pnl: BigNumber;
  readonly liquidation_price: BigNumber | null;
  readonly leverage: BigNumber | null;
  readonly realized_positional_pnl: BigNumber | null;
}

export interface OpenPosition extends Position {
  readonly status: 'OPEN';
}

export interface ClosedPosition extends Position {
  readonly status: 'CLOSED';
}

interface PositionsReq extends BaseReq {}

interface PositionsHistoryReq extends BasePaginatedReq {
  readonly side?: PositionSide;
  readonly market?: string;
  readonly start_at?: UnixTimeMs;
  readonly end_at?: UnixTimeMs;
}

export interface RawPositionsResp {
  readonly results: readonly RawPosition[];
}

export interface RawPositionsHistoryResp
  extends PaginatedRespData<RawPosition> {}

export interface PositionsHistoryResp
  extends PaginatedRespData<ClosedPosition> {}

export interface OpenPositionsResp {
  readonly results: readonly OpenPosition[];
}

export async function getOpenPositions(
  req: PositionsReq,
): AsyncResp<OpenPositionsResp> {
  const { signal } = req;

  const resp = await requestApi<RawPositionsResp>({
    signal,
    method: 'GET',
    url: `/positions`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: {
      results: resp.data.results
        .filter((position) => position.status === 'OPEN')
        .map(processPosition) as OpenPosition[],
    },
  };
}

export async function getPositionsHistory(
  req: PositionsHistoryReq,
): AsyncResp<PositionsHistoryResp> {
  const { signal } = req;

  const resp = await requestApi<RawPositionsHistoryResp>({
    signal,
    method: 'GET',
    url: `/positions-history`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: {
      ...resp.data,
      results: resp.data.results.map(processPosition) as ClosedPosition[],
    },
  };
}

export function processPosition(position: RawPosition): Position {
  return {
    ...position,
    last_updated_at: msToDate(position.last_updated_at),
    created_at:
      position.created_at == null ? null : msToDate(position.created_at),
    closed_at: position.closed_at == null ? null : msToDate(position.closed_at),
    size: new BigNumber(position.size),
    average_entry_price: new BigNumber(position.average_entry_price),
    average_entry_price_usd: new BigNumber(position.average_entry_price_usd),
    unrealized_pnl: new BigNumber(position.unrealized_pnl),
    unrealized_funding_pnl: new BigNumber(position.unrealized_funding_pnl),
    average_exit_price: BigNumber(position.average_exit_price).isNaN()
      ? null
      : new BigNumber(position.average_exit_price),
    leverage:
      position.leverage !== '' ? new BigNumber(position.leverage) : null,
    liquidation_price:
      position.liquidation_price !== ''
        ? new BigNumber(position.liquidation_price)
        : null,
    realized_positional_pnl:
      position.realized_positional_pnl !== ''
        ? new BigNumber(position.realized_positional_pnl)
        : null,
  };
}
