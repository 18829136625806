import BigNumber from'bignumber.js';import{logException}from"../../logging/logging";import*as MarketFn from"../../market/functions";import*as OrderBuilderFn from"./functions";/**
 * Updates the state with:
 *  * `asset`
 *  * `amount`: converted to the currency of the new asset
 *
 * @param state - The current state
 * @param nextAsset - The new asset
 * @param market - The relevant market
 * @param price - The reference price for the market. Can be different depending on the order type.
 */export function assetUpdate(state,nextAsset,market,price){const nextState={...state,asset:nextAsset};if(state.amount==null)return nextState;if(market==null)return nextState;if(nextAsset===market.base_currency){const amount=OrderBuilderFn.convertAmountToBaseAsset(state.asset,state.amount,price,market);return{...nextState,amount};}if(nextAsset===market.quote_currency){const amount=OrderBuilderFn.convertAmountToQuoteAsset(state.asset,state.amount,price,market);return{...nextState,amount};}return nextState;}/**
 * Updates the state with the new, sanitized amount,
 * rouded to the market decimal places.
 *
 * @param state - The current state
 * @param amount - The new amount
 * @param market - The relevant market
 */export function amountUpdate(state,amount,market){if(amount===''){return{...state,amount:null};}const parsed=new BigNumber(amount);if(!parsed.isFinite()){logException(`order builder :: amountUpdate :: invalid parsed amount`,{amount:String(amount),parsed:String(parsed),asset:state.asset});return{...state,amount:null};}if(state.asset==null)return{...state,amount:parsed};const rounded=market!=null?MarketFn.roundAmountByAsset(market,state.asset,parsed):parsed;if(!rounded.isFinite()){logException(`order builder :: amountUpdate :: invalid rounded amount`,{amount:String(amount),rounded:String(rounded),asset:state.asset});return{...state,amount:null};}return{...state,amount:rounded};}