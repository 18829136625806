import BigNumber from 'bignumber.js';

import { formatUsd } from '#/utils/fiat';

const deltaFormatter = new Intl.NumberFormat('en-US', {
  signDisplay: 'always',
  minimumFractionDigits: 4,
  maximumFractionDigits: 4,
});

const gammaFormatter = new Intl.NumberFormat('en-US', {
  signDisplay: 'always',
  minimumFractionDigits: 9,
  maximumFractionDigits: 9,
});

const defaultGreeksFormatter = new Intl.NumberFormat('en-US', {
  signDisplay: 'always',
  minimumFractionDigits: 3,
  maximumFractionDigits: 3,
});

export function formatDelta(value: BigNumber): string {
  return deltaFormatter.format(value.toNumber());
}

export function formatGamma(value: BigNumber): string {
  return gammaFormatter.format(value.toNumber());
}

export function formatVega(value: BigNumber): string {
  return defaultGreeksFormatter.format(value.toNumber());
}

export function formatFundingRateUsd(value: BigNumber): string {
  return formatUsd(value, {
    signDisplay: 'always',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
