import { Maybe } from './types';

/**
 * Gets the error message from an unknown error. If `error` is an `Error`, it
 * will return the `message` property. Otherwise, it will return the string
 * representation of `error`.
 *
 * @param error Possibly an `Error`
 * @returns String representation of `error`
 */
export function getErrorMessage(error: unknown): string {
  return (error as Maybe<Error>)?.message ?? String(error);
}

export function getRootCause(error: unknown): unknown {
  let cause: unknown = error;
  while (cause != null && (cause as Error).cause != null) {
    cause = (cause as Error).cause;
  }
  return cause;
}

const MAX_CAUSE_DEPTH = 10;
export function getErrorMessageWithCauses(
  error: unknown,
  maxDepth = MAX_CAUSE_DEPTH,
): string {
  try {
    let message = `📕 Captured error: \n${getErrorMessage(error)}`;
    let currentError = error as Maybe<Error>;
    let depth = 0;

    while (currentError?.cause != null && depth < maxDepth) {
      depth++;
      const causeMessage = getErrorMessage(currentError.cause);
      message += `\n📎 Caused by: ${causeMessage}`;
      currentError = currentError.cause as Maybe<Error>;
    }

    return message;
  } catch (e) {
    return String(error);
  }
}
