import { AsyncResp, BaseReq, requestApi } from '#/api/fetch-api';

import getQueryString from '#/utils/getQueryString';

import type { Nullable } from '#/utils/types';

export interface RawVaultHistory {
  /** Contract address of the vault or strategy */
  readonly address: string;
  /** Ordered list of datapoints */
  readonly data: ReadonlyArray<number>;
  /** Ordered list of timestamps */
  readonly timestamps: ReadonlyArray<number>;
}

export interface VaultHistory extends RawVaultHistory {}

export interface VaultHistoryReq extends BaseReq {
  readonly address: string;
  readonly strategyAddress?: Nullable<string>;
  readonly type: 'pnl' | 'value' | 'price';
  readonly resolution: 'alltime' | '1h' | '8h';
}

export interface RawVaultHistoryResp {
  readonly results: readonly [RawVaultHistory] | [];
}

export type VaultHistoryResp = VaultHistory | null;

export async function getVaultHistory(
  req: VaultHistoryReq,
): AsyncResp<VaultHistoryResp> {
  const { signal } = req;

  const queryString = getQueryString([
    ['address', req.address],
    ['strategy', req.strategyAddress],
    ['type', req.type],
    ['resolution', req.resolution],
  ]);

  const resp = await requestApi<RawVaultHistoryResp>({
    signal,
    method: 'GET',
    url: `/vaults/history${queryString}`,
  });

  if (!resp.ok) return resp;

  const vaultHistory = resp.data.results.find(
    (v) => v.address === req.address || v.address === req.strategyAddress,
  );

  if (vaultHistory == null) {
    return { ...resp, data: null };
  }

  return {
    ...resp,
    data: vaultHistory,
  };
}
