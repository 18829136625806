import { logException } from '#/features/logging/logging';

import { TRADING_VIEW_SETTINGS } from './storage';

const tradingViewSettings = {
  set: <T>(data: T): void => {
    try {
      localStorage.setItem(TRADING_VIEW_SETTINGS, JSON.stringify(data));
    } catch (error) {
      logException(
        new Error('Failed to set TradingView user settings', { cause: error }),
        { data },
      );
    }
  },
  get: <T>(): T | undefined => {
    try {
      const storedItem = localStorage.getItem(TRADING_VIEW_SETTINGS);
      return storedItem != null ? (JSON.parse(storedItem) as T) : undefined;
    } catch (error) {
      logException(
        new Error('Failed to get TradingView user settings', { cause: error }),
      );
      return undefined;
    }
  },
} as const;

export default tradingViewSettings;
