import { Row as RowProp } from '@tanstack/react-table';
import { BigNumber } from 'bignumber.js';

import { sortBigNumbers } from '#/features/shared/sorting';

import { FormattedValue } from '#/utils/types';

export function sortFormattedBigNumber<T extends Record<string, unknown>>(
  rowA: RowProp<T>,
  rowB: RowProp<T>,
  columnId: string,
) {
  const columnA = rowA.original[columnId] as FormattedValue<BigNumber>;
  const columnB = rowB.original[columnId] as FormattedValue<BigNumber>;

  if (!('value' in columnA) || !('value' in columnB)) return 0;

  if (
    BigNumber.isBigNumber(columnA.value) &&
    BigNumber.isBigNumber(columnB.value)
  )
    return sortBigNumbers(columnA.value, columnB.value);

  return 0;
}
