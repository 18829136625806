import { Dispatch, SetStateAction } from 'react';

import { logEvent, logException } from '#/features/logging/logging';
import {
  BaseAccount,
  getBaseAccountTypeLabel,
} from '#/features/wallet/common/base-account';
import { WalletClient } from '#/features/wallet/common/wallet-client';
import { prepareErrorMessage } from '#/features/wallet/ethereum';
import { WalletState } from '#/features/wallet/wallet-context';

import { AsyncResult } from '#/utils/types';

export default function actionConnectWallet(
  ...params: Parameters<typeof connectWallet>
) {
  return async () => connectWallet(...params);
}

async function connectWallet(
  walletClient: WalletClient,
  setState: Dispatch<SetStateAction<WalletState>>,
): AsyncResult<void> {
  const actions = prepareActions(setState);

  actions.resetError();
  actions.setStatus('confirming_wallet_connection');

  try {
    await walletClient.switchChainId();
  } catch (err) {
    const description = `Error switching ${getBaseAccountTypeLabel(
      walletClient.getBaseAccount(),
    )} chain`;
    const { message, isException } = prepareErrorMessage(description, err);
    actions.setError(message);
    actions.setStatus('wallet_not_connected');
    if (isException) {
      const error = new Error(description, { cause: err });
      logException(error);
      return { ok: false, error };
    }
    logEvent(message);
    return { ok: false, error: null };
  }

  try {
    const baseAccount = walletClient.getBaseAccount();
    actions.setBaseAccount(baseAccount);
  } catch (err) {
    const description = `Failed to request ${getBaseAccountTypeLabel(
      walletClient.getBaseAccount(),
    )} address`;
    const { message, isException } = prepareErrorMessage(description, err);
    actions.setError(message);
    actions.setStatus('wallet_not_connected');
    if (isException) {
      const error = new Error(description, { cause: err });
      logException(error);
      return { ok: false, error };
    }
    logEvent(message);
    return { ok: false, error: null };
  }

  actions.setStatus('wallet_connected_idle');
  return { ok: true, data: undefined };
}

function prepareActions(setState: Dispatch<SetStateAction<WalletState>>) {
  const setStatus = (status: WalletState['step']) => {
    setState((state) => ({ ...state, step: status }));
  };

  const setBaseAccount = (baseAccount: NonNullable<BaseAccount>) => {
    setState((state) => ({
      ...state,
      baseAccount,
    }));
  };

  const setError = (error: string) => {
    setState((state) => ({ ...state, error }));
  };

  const resetError = () => {
    setState((state) => ({ ...state, error: '' }));
  };

  return { setStatus, setBaseAccount, setError, resetError };
}
