import type { Dispatch, SetStateAction } from 'react';
import type { FavoritesBarState } from './state';

interface Actions {
  readonly setMode: (mode: FavoritesBarState['mode']) => void;
}
export type { Actions as FavoritesBarActions };

export function createActions(
  setState: Dispatch<SetStateAction<FavoritesBarState>>,
): Actions {
  return {
    setMode: (mode: FavoritesBarState['mode']) => {
      setState((state) => ({ ...state, mode }));
    },
  };
}
