/**
 * We use approach similar to `GNU gettext` where keys are written in plain English and used as fallback for missing translations.
 *  So that separate `id` is not needed, just the `key` string is used with `{{abc}}` for variables and `<1>text<1/>` for React components.
 *  So that from the developer's perspective, we still write in plain English and not with `id`s that are harder to understand and maintain.
 */

const keysToDo = [
  "Position Mode",
  "One-Way",
  "Order Modification Submitted",
  "Order Modified Successfully",
  "Order Modification Rejected",
  "Order Modification Unknown Status",
  "Order Modification Unknown Error",
  "Your position on this market can be either long or short. Orders define only the size and direction.",
  "Delete Account",
  "Permanently delete your account from our platform. Please note you will no longer be able to login with your wallet after this is completed.",
  "Markets",
  "Funding Comparison",
  "Failed to load funding rates",
  "No funding rates available",
  "Paradex OI",
  "Paradex",
  "Binance",
  "OKX",
  "ByBit",
  "Binance Arb",
  "OKX Arb",
  "ByBit Arb",
  "Search Markets",
  "Funding Arb",
  "Funding History",
  "Adjust Margin",
  "Current Margin",
  "Max Available Margin",
  "Max Removable Margin",
  "Liquidation Price (Est.) After Increase",
  "Liquidation Price (Est.) After Decrease",
  "Margin adjusted successfully",
  "Add",
  "Remove",
  "Limit Close",
  "Add TP/SL For This Order",
  "Take Profit Limit Price",
  "Stop Loss Limit Price",
  "Margin has been transferred back to main account",
  "Transferring margin to isolated position",
] as const;

const keys = [
  "Display trade history on chart",
  "Add markets to Favorites in the Markets Browser",
  "Log in",
  "Export embedded wallet",
  "Email or Social",
  "Failed to load balances",
  "No balances",
  "Verified Operator",
  "Strategy",
  "Multi-Strategy",
  "Master Vault",
  "Master Vault Details",
  "Annualized",
  "Depositors",
  "No trade history",
  "Unwinding Progress",
  "What are Vaults?",
  "APR calculated as the Annualised 30-day Return",
  "APR",
  "Initializing",
  "Awaiting Deposit",
  "Vault Age",
  "30D Return",
  "{{field}} must be greater than the minimum initial deposit of {{minInitialDeposit}}",
  "Minimum deposit",
  "Withdrawal amount exceeds available vault shares",
  "Deposit would exceed maximum vault TVL of {{maxTVL}}",
  "{{field}} is required",
  "Lockup Remaining",
  "{{days}}D",
  "<1>{{days}}</1> D",
  "<1>{{hours}}</1> H",
  "<1>{{minutes}}</1> M",
  "Vault settings submitted",
  "Vault settings updated",
  "Vault Settings",
  "Vault not found",
  "The owner receives a {{profitShare}} profit share.",
  "This vault is entirely owned by the community.",
  "Withdraw from Vault submitted",
  "Deposit to Vault submitted",
  "Deposit to Vault successful",
  "Unknown error. Try again.",
  "Vault Deposit",
  "Vault Withdrawal",
  "No Lockup",
  "Lockup period is not over yet",
  "Protocol",
  "24H",
  "7D",
  "30D",
  "All",
  "You have no investments in vaults",
  "Total Value",
  "Investments Made",
  "Operator",
  "Manage Vault",
  "Switch to the operator account",
  "Active",
  "My Vaults",
  "Closed",
  "Deposited",
  "Lifetime ROI",
  "Owners Equity",
  "Top Trending",
  "Owner Share",
  "Vault Details",
  "Contract",
  "Withdraw from Vault",
  "Withdraw from Vault successful",
  "Failed to withdraw from Vault",
  "Name",
  "Deposit",
  "Tokens",
  "Avg. Entry Price",
  "Current Price",
  "P&L",
  "My Vault Deposit",
  "Vaults",
  "Create",
  "Create My Vault",
  "Vault Name",
  "Vault Description",
  "Token Symbol",
  "Deposit Amount",
  "Vault description will be visible publicly and you will not be able to edit this later.",
  "Once created, deposit at least {{minDeposit}} USDC to activate the vault. At this moment, only the Max TVL can be changed later.",
  "Create Vault",
  "Profit Share",
  "Lockup Period (Days)",
  "Vault was successfully created and is being initialized",
  "Failed to create a vault",
  "Maximum TVL",
  "No limit",
  "Vault Address",
  "Deposit to Vault successful",
  "Failed to deposit to Vault",
  "Deposit to Vault",
  "Vault",
  "Owner",
  "Age",
  "Failed to load vaults",
  "No vaults available",
  "Performance",
  "Vault Value",
  "My Deposit",
  "Total P&L",
  "Protocol Vaults",
  "User Vaults",
  "No Data",
  "Last Month Return",
  "TVL",
  "Total Earnings",
  "About",
  "Vault Stats",
  "My Stats",
  "Vault Contract",
  "Description",
  "Max Drawdown",
  "Volume",
  "All-Time P&L",
  "Unrealized P&L",
  "Days",
  "Account Value",
  "Lockup Period",
  "Search Vaults",
  "Blog",
  "Block Explorer",
  "Close Vault",
  "Close Vault successful",
  "You must close all open positions to close your Vault. Closed vaults cannot be reopened.",
  "Close My Vault",
  "Failed to Close the Vault",
  "Only the Main Account can manage the vault",
  "Allocate Collateral",
  "Switch to Main Account",
  "Switch to Account",
  "You are currently trading in the {{market}} market.",
  "To start trading on Isolated Market you must Allocate Collateral from your Main Account and switch to this Market Account.",
  "To trade in this market, switch to the Main Account first.",
  "This account can only trade in the {{market}} market.",
  "Isolated Market",
  "This feature currently is still in Beta. Backup your Paradex Private Key for additional safety.",
  "Deposit failed",
  "Deposit in progress",
  "External Account",
  "Tx Hashes",
  "Continue to {{bridge}}",
  "By clicking Continue to {{bridge}}, you will be sent to {{bridge}} website to bridge your funds out of Paradex.",
  "By clicking Continue to {{bridge}}, you will be sent to {{bridge}} website to bridge your funds to Paradex.",
  "Refer",
  "Address",
  "Date Joined",
  "Referral Code",
  "Volume Traded",
  "Bridge",
  "Withdraw to",
  "Deposit from",
  "Position is already closed",
  "Your Paradex L2 Address is NOT a Public Starknet Address. If you try to send funds to this address on Public Starknet via a cross chain bridge you risk losing your funds. <1>Read more</1>.",
  "{{chain}} Address",
  "Copy Paradex Private Key",
  "Your account will be liquidated if Margin Ratio reaches 100%",
  "Liquidation",
  "Fee",
  "Maker",
  "Maker Score",
  "Maker XP Share",
  "Fee XP Share",
  "Fee XP",
  "Maker XP",
  "Liquidation {{side}} Fill",
  "Settings",
  "Market Score",
  "Season {{season}} (coming soon)",
  "Confirm Buy",
  "Confirm Sell",
  "Other XP",
  "Analytics",
  "Estimated Receivable Amount",
  "Socialized Loss active. Click to learn more.",
  "Sign In to see your account XP stats",
  "<1>Clock Is Out Of Sync</1> The clock on your device is out of sync. This may cause issues with the application. Please make sure your device clock is set to be auto. For more details please <2>see documentation</2>",
  "Failed to fetch max withdrawable amount",
  "It is not possible to withdraw more than your Max Withdrawable Amount.",
  "Max Withdrawable Amount",
  "XP Explainer",
  "Pool",
  "Quote Quality",
  "Create Your Public Username",
  "Edit Your Public Username",
  "You have been logged out.",
  "Maker Volume Score",
  "Fee Score",
  "Score Share",
  "Instrument Share",
  "XP",
  "Total XP",
  "24h Change",
  "Leaderboard",
  "Cancel",
  "Portfolio",
  "Trade",
  "Build",
  "Referrals Explainer",
  "How It Works",
  "My Referrals",
  "Your Referral Code",
  "Affiliate Program",
  "Enrolled",
  "Traders Referred",
  "Referral Rewards",
  "Referral XP",
  "Invite Friends",
  "Share",
  "Earn",
  "Give",
  "Share your code to invite friends to join Paradex",
  "<1>{{amount}}</1> Fees",
  "You receive {{amount}} of the referred user's fees",
  "<1>{{amount}}</1> XP",
  "You receive {{amount}} of the referred user's XP",
  "<1>{{amount}}</1> Discount",
  "Your friend receives {{amount}} discount on fees",
  "Create and share your custom referral code",
  "If you have a strong social media following (<1>{{followers}}+</1>), you may be eligible for our <2>VIP Affiliate Program</2> with higher commissions and XP.",
  "You can track our <1>Affiliate Leaderboard</1> to see how you stack up.",
  "Apply",
  "Log in to see your Referral History.",
  "Welcome to Paradex",
  "Congrats! The referral code you used is now active! It means you can start enjoying a discount on your trading fees!",
  "Congrats! The referral code <1>{{codeProvided}}</1> used is now active!",
  "Referral code not activated. The code can only be applied once during the initial onboarding.",
  "Referral code <1>{{codeProvided}}</1> not activated. You have already onboarded with <1>{{referredBy}}</1>.",
  "Referral Code Applied",
  "You will receive <1>{{userAmount}}</1> of referred user's <1>fees</1> <2>and</2> <1>{{userAmountPoints}} XP</1>. They will receive a <1>{{friendAmount}}</1> discount.",
  "Community",
  "Environments",
  "Language",
  "System Status",
  "Docs",
  "Code Samples",
  "More",
  "Stats",
  "Join Discord",
  "Notifications",
  "All caught up!",
  "You have no new notifications",
  "Connect Wallet",
  "WalletConnect is taking too long to open. Please refresh the page and try again.",
  "Unexpected error opening WalletConnect modal. Please refresh the page and try again.",
  "Connect your Wallet",
  "Link Wallet",
  "You will receive a signature request. Signing is free and will not send a transaction.",
  "Generate Paradex Chain wallet",
  "Generating Paradex Chain wallet",
  "Paradex Chain wallet generated",
  "Verify that you own this wallet",
  "Remember Me",
  "Only use 'Remember Me' if you are using a secure device you own. Selecting this option can expose your information to  others if you are accessing this wallet from a public device.",
  "Continue",
  "Disconnect Wallet",
  "Disconnect",
  "Account",
  "Username",
  "Edit",
  "Your username is now hidden. It is not visible to other users.",
  "Your username will be visible to other users unless you choose to hide it. You can always change it later.",
  "Hide my Username",
  "Save",
  "Username is required",
  "Username must be between 5 and 20 characters",
  "Username can only contain letters and numbers",
  "Wallets",
  "Paradex Block Explorer",
  "Log out",
  "No markets available",
  "Last Price",
  "Open Interest",
  "24h Volume",
  "Mark Price",
  "Spot Price",
  "Eight hour funding rate that is continuously accrued on positions and settled upon any trade. If the funding rate is positive, longs pay shorts. If negative, shorts pay longs.",
  "8h Funding",
  "Limit",
  "Market",
  "Stop Limit",
  "Stop Market",
  "Scaled Order",
  "Trigger Price",
  "Input Price",
  "Position Mark Price",
  "Amount",
  "Reduce Only",
  "Reduce Only will increase position",
  "Your order stays open until it is filled or you decide to cancel.",
  "Your order gets filled right away, partially or fully, and whatever is left gets canceled instantly.",
  "Conditional limit order that serves to add liquidity to the order book as a Maker order.",
  "Currently the fees are fixed and uniform across the platform. Maker {{makerFee}} / Taker {{takerFee}}",
  "Value",
  "Buy",
  "BUY",
  "LONG",
  "Sell",
  "SELL",
  "SHORT",
  "Order Value",
  "Current Position",
  "Account Value",
  "The total value of your account at current mark prices",
  "Unrealized P&L",
  "Free Collateral",
  "Account Leverage",
  "Margin",
  "Margin Ratio",
  "Initial Margin",
  "Maintenance Margin",
  "Order Book",
  "Trades",
  "Price",
  "Size",
  "Total",
  "Time",
  "Spread",
  "Positions",
  "Open Orders",
  "Trade History",
  "Order History",
  "Funding Payments",
  "Side",
  "Entry Price",
  "Liquidation Price",
  "Breakeven Price",
  "Unrealized Funding",
  "Close Position",
  "Market Close",
  "Close Long",
  "Close Short",
  "Type",
  "Filled",
  "Triggered",
  "Instruction",
  "Client Order Id",
  "Status",
  "NEW",
  "UNTRIGGERED",
  "OPEN",
  "CLOSED",
  "Pending",
  "Complete",
  "Completed",
  "Failed",
  "Bust",
  "Value / Fee",
  "Liquidity",
  "Tx Hash",
  "Trade Id",
  "Remaining",
  "Cancel Reason",
  "User Canceled",
  "Not Enough Margin",
  "Empty Market",
  "Post Only",
  "Post Only Would Cross",
  "Remaining IOC Cancel",
  "Unexpected Failure",
  "Deleverage",
  "In Liquidation",
  "Self Trade",
  "Asset Unavailable",
  "Asset Expired",
  "Order Type Invalid",
  "Price Not Available",
  "Expired",
  "Exceeds Max Slippage",
  "Timeout",
  "Order Exceeds Position Limit",
  "Order Id",
  "Payment",
  "Cancel All",
  "{{orderSize}} will be closed at Market price",
  "By connecting a wallet, you agree to <1>Paradex Terms of Service</1> and represent and warrant to Paradex that you are not a <2>Restricted Person</2>.",
  "Wallet",
  "Deposit_verb",
  "Deposit_noun",
  "Withdraw",
  "Withdrawal",
  "Transfers",
  "Transfer",
  "Collateral",
  "Asset",
  "Wallet Balance",
  "Actions",
  "Action",
  "Tx Hash",
  "External Tx Hash",
  "Withdraw to Wallet",
  "Deposit to Paradex",
  "You have no balance in your Ethereum wallet. Deposit some {{asset}} and try again.",
  "It is not possible to deposit more than your current balance.",
  "Available",
  "Initiate a deposit from your Ethereum wallet to the Paradex bridge. These transactions",
  "Initiate a deposit from your Ethereum wallet to the Paradex bridge. These transactions may take several minutes depending on network conditions. For more information check out our <1>docs</1>.",
  "read more",
  "Initiate Deposit to Bridge",
  "Enable USDC on Paradex",
  "In order to deposit you must allow Paradex access to at least {{amount}}.",
  "Enable USDC",
  "Withdraw initiated",
  "Withdrawals are a two-step process. First initiate a withdrawal from Paradex",
  "Withdrawals are a two-step process. First initiate a withdrawal from Paradex to the Paradex Bridge. This first step can take 5-16 hours (10 on average). Second, initiate your withdrawal from the Paradex Bridge to your Ethereum wallet via the Deposits / Withdrawals table. For more information check out our <1>docs</1>.",
  "Initiate Withdrawal to Bridge",
  "You have no funds to withdraw. It is not possible to withdraw more than your free collateral.",
  "It is not possible to withdraw more than your free collateral.",
  "You have no collateral",
  "You have no open positions",
  "You have no open orders",
  "You have no trades",
  "You have no orders",
  "You have no funding payments",
  "You have no transfers",
  "Account in liquidation",
  "Account is being liquidated. Any open positions will be reduced. Trading now is disabled.",
  "Account liquidated",
  "Your account was liquidated on {{createdAt}}. Any open positions were reduced.",
  "Deposit to Paradex successful",
  "Deposit to Paradex failed",
  "Unexpected error. Try again.",
  "Deposit initiated",
  "Deposit to bridge initiated",
  "Deposit to Paradex available",
  "Go to Deposits",
  "Deposit to Paradex in progress",
  "Transfer successful",
  "Limit {{side}} Order Fill",
  "{{type}} {{side}} Order Submitted",
  "{{type}} {{side}} Order Canceled",
  "Reason",
  "Market {{side}} Order Fill",
  "Trade Busted On-Chain",
  "Fill id",
  "Withdraw to wallet failed",
  "Withdrawal to bridge initiated",
  "Withdrawal error. Try again.",
  "Withdrawal to bridge in progress",
  "Withdrawal to wallet initiated",
  "Withdrawal to bridge failed",
  "Transaction error. Try again.",
  "Withdrawal to wallet available",
  "Go to Withdrawals",
  "Withdrawal to wallet successful",
  "Session expired, please log in again.",
  "Failed to submit order",
  "Your username preferences were updated!",
  "Unexpected error updating username preferences",
  "This username already exists. Please choose a different username and try again.",
  "Validation Error",
  "Binding Error",
  "Internal Error",
  "Not Found",
  "Service Unavailable",
  "Invalid Request Parameter",
  "Order Id Not Found",
  "Order Is Closed",
  "Order Is Not Open Yet",
  "Client Order Id Not Found",
  "Duplicated Client Id",
  "Invalid Price Precision",
  "Invalid Token",
  "Invalid Ethereum Address",
  "Invalid Ethereum Signature",
  "Invalid Starknet Address",
  "Invalid Starknet Signature",
  "Starknet Signature Verification Failed",
  "Bad Starknet Request",
  "Ethereum Signer Mismatch",
  "Ethereum Hash Mismatch",
  "Not Onboarded",
  "Invalid Timestamp",
  "Invalid Signature Expiration",
  "Account Not Found",
  "Invalid Order Signature",
  "Public Key Invalid",
  "Unauthorized Ethereum Address",
  "Ethereum Address Already Onboarded",
  "Market Not Found",
  "Allowlist Entry Not Found",
  "Username In Use",
  "Service access restricted in your region",
  "No Access Allowed",
  "You do not have access to the platform. Please join our Discord to get access.",
  "{{actualChainName}} Wallet Connected",
  'Incorrect network selected "{{actualChainName}}". Please change the network to "{{requiredChainName}}" (id={{requiredChainId}}) in your wallet and retry.',
  "Blocked Ethereum Address",
  "Choose Wallet",
  "Starknet Wallet",
  "Switch to {{env}}",
  "Search",
  "League",
  "Switch Account",
  "Rank",
  "24h XP",
  "XP Per Day",
  "Season Volume",
  "Lifetime Volume",
  "Pre-Season",
  "Earn More XP",
  "XP Boost",
  "XP Drop",
  "Trader",
  "Total Share",
  "Maker Share",
  "Maker Volume Share",
  "Fee Share",
  "Fee Volume Share",
  "No data available",
  "Refer & Earn",
  "Hours",
  "Minutes",
  "Time/Duration",
  "Runtime",
  "Frequency",
  "Number of Orders",
  "Runtime should not be empty",
  "Runtime should be less than or equal to 24 hours",
  "Inactive",
  "Avg. Price",
  "The minimum required amount for {{numOrders}} orders is {{totalSize}} {{currency}}.",
  "Take Profit / Stop Loss: Set take profit and stop loss price levels to automate trade actions. When the Mark Price will reach the price you set, it will send your order to the matching engine.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount with an estimated profit of <3 />.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount with an estimated loss of <3 />.",
  "Edit TP/SL For This Position",
  "Market",
  "Size",
  "Entry Price",
  "Mark Price",
  "Take Profit",
  "Profit",
  "Stop Loss",
  "Loss",
  "Confirm",
  "Take Profit Limit",
  "Take Profit Market",
  "Stop Loss Limit",
  "Stop Loss Market",
  "Funding",
  "Unwinding",
  "Cancel TP/SL orders",
  "TP/SL orders submitted successfully.",
  "Pre-Season + All Seasons",
  "Season {{season}}",
  "XP Season {{season}}",
  "Slippage",
  "Max Slippage",
  "Max slippage defines the largest acceptable price deviation from the mark price.",
  "During execution, the order can be canceled if the price exceeds the slippage limit or falls outside the Market <1>Price Band</1>.",
  "The Maximum Slippage allowed for {{symbol}} is {{price_band_width}}.",
  "Update Max Slippage",
  "Mark IV",
  "Delta",
  "Failed to load positions",
  "No open positions",
  "learn more",
  "Learn More",
  "Search Markets",
  "Futures",
  "Options",
  "OI",
  "Addresses",
  "My Profile",
  "Chart",
  "ROI",
  "Sharpe",
  "Max Loss",
  "Total Volume",
  "My NFTs",
  "My XP",
  "Balances",
  "Other",
  "XP",
  "Paradex XP: Pre-Season",
  "Paradex XP: Season {{season}}",
  "Season {{season}} XP",
  "Refer your friends to earn 10%",
  "Refer Friends and Earn 10%",
  "Invest in Paradex Vault & Earn 3X Boost",
  "XP Boost",
  "Avg. Daily XP",
  "Lifetime XP",
  "My Rank",
  "What is XP?",
  "XP History",
  "Last Week XP",
  "TVL XP",
  "OI XP",
  "Liquidation XP",
  "Vaults XP",
  "Week",
  "Season",
  "Last updated: {{timestamp}}",
  "Xperience Points (XP)",
  "Log in to see your XP balance",
  "Log in to see where you stand",
  "Connect your X account to join Pro League",
  "Avg. Fill Price",
  "Spot",
  "Derivatives",
  "P&L Chart",
  "Account Value Chart",
  "Funding History",
  "Manage Funds",
  "Profile",
  "Community XP",
  "XP Share",
  "Next XP Drop in",
  "In Progress",
  "{{field}} must be greater than {{value}}",
  "{{field}} must be less than {{value}}",
  "Enter a valid Take Profit or Stop Loss price",
  "Take Profit and Stop Loss prices cannot be the same",
  "Automatic Withdrawal",
  "Estimated Gas Fee",
  "Auto Withdrawal",
  "Withdrawing...",
  "Paradex Bridge withdrawals can take between 5-16 hours to complete",
  "Socialized Loss",
  "Receivable Amount",
  "Auto withdrawing...",
  "Manage Twitter Connection",
  "Unlock additional functionality.",
  "Please note this connection can't be used as a login method to Paradex.",
  "Toast Notifications",
  "Display toast notifications",
  "Average Entry Price",
  "Display average entry price line on chart",
  "Display liquidation price line on chart",
  "Display open order lines on chart",
  "Failed to disconnect Twitter",
  "Failed to authorize Twitter",
  "Twitter account linked successfully!",
  "Failed to link twitter account",
  "Fees",
  "Taker pays {{takerFee}} in fees. Maker receives {{makerRebate}} in rebates.",
  "Failed to authorize Discord",
  "Manage Discord Connection",
  "Failed to disconnect Discord",
  "Failed to link Discord account",
  "Discord account linked successfully!",
  "Connect",
  "Unrealized P&L from current vault token balance. This doesn't account for owner profit share or slippage from positions unwind.",
  "Show P&L Amount",
  "Receivable amount must be positive",
  "Wallet Address Change Detected",
  "You can proceed with connecting your Paradex account to this wallet address or continue using your current connection.",
  "Connect To New Wallet",
  "Keep Using Current Wallet",
  "Connect Current Wallet",
  "The account is not connected. Please make sure that <1>{{walletAddress}}</1> is connected and is the active account in your <2>{{walletName}}</2> wallet.",
  "Reconnecting",
  "There is a request in progress. Please proceed in your wallet.",
  "Modify Order",
  "Realized P&L",
  "Close All",
  "Close All Positions",
  "Market Close All Positions",
  "Close {{percent}}% of All Positions",
  "Failed to close all positions",
  "Number of Positions",
  "Markets",
  "Position Value (Closing)",
  "{{count}} positions will be rounded to the nearest order increment",
  "You will earn Bonus XP on your referral activity",
  "Token Price",
  "Vault Token Price Chart",
  "Market Close Orders Submitted",
  "This profile is already linked to a different Paradex account. Disconnect socials from existing account and try again.",
  "XP Drops every Friday",
  "No XP Data Available",
  "XP data is available only for the Main Account.",
  "Please switch to your Main Account to view your XP.",
  "Referrals Not Available",
  "Referrals are available only for the Main Account.",
  "Please switch to your Main Account to view your Referrals.",
  "Deposit initiated via Layerswap",
  "Position History",
  "Avg. Close Price",
  "Max Size",
  "Closed Size",
  "Opened",
  "You have no positions",
  "Destination Address",
  "Withdrawal via Layerswap initiated",
  "Withdrawal via Layerswap failed",
  "Market Share",
  "Tier",
  "Avg Maker Volume",
  "Liquidity Score",
  "Reward Share",
  "Enter amount",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount at <3 /> with an estimated profit of <4 />.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount at <3 /> with an estimated loss of <4 />.",
  "Limit Price",
  "Delete",
  "Use other bridges from the list above to withdraw funds much faster.",
  "Paradex Chain Monitor",
  "OTM Amount",
  "Leverage",
  "Strikes",
  "Strike",
  "Change",
  "Dist. to Spot",
  "Bid",
  "Mark",
  "Ask",
  "IV",
  "Dist %",
  "Call",
  "Put",
  "Mark Price / IV",
  "8h F-Funding",
  "No Strikes Available",
  "Attributes",
  "Why no Expiries?",
  "{{multiplier}}x Boost",
  "Like perpetual futures, perpetual options have no expiration, offering continuous exposure to the underlying asset with built-in downside protection. This gives users the flexibility to hold their position indefinitely and decide the optimal time to close it based on their strategy",
  "Implied Volatility used to calculate the option's Mark Price",
  "Distance between the Strike Price and the underlying Spot Price",
  "Eight hour funding that is continuously accrued on positions and settled upon any trade. If the funding rate is positive, longs pay shorts. If negative, shorts pay longs",
  "The Leverage Ratio (Lambda) measures an option's effective leverage by indicating how much the option's value changes, in percentage terms, for a 1% change in the spot price of the underlying asset. For example, if an option has a Lambda of 50 and the spot price increases by 1%, the option's value would increase by 50%",
  "Option delta measures how much the price of an option is expected to change for a $1 change in the price of the underlying asset. For example, a delta of 0.5 means the option price will move $0.50 for every $1 move in the underlying",
  "Eight hour funding rate of the perpetual future on the same underlying asset",
  "Having trouble? Try signing out and connecting again.",
  "API Reference",
  "XP Distribution occurs once per week on Friday at ~12 PM UTC",
  "What are Perpetual Options?",
  "Delta represents the increase in option price based on a $1 increase in the underlying spot price.\n\nFor example, a delta of 0.50 means the price of the option is expected to increase by $0.50 for a $1 increase in the underlying spot price.",
  "Leverage(Lambda) represents the percentage change in option price for a 1% change in the underlying spot price.\n\nFor example, a lambda of 10 means the option price is expected to increase by 10% if the underlying spot price increases by 1%.",
  "Eight hour options funding rate that is continuously accrued on positions and settled upon any trade. The funding rate for perpetual options reflects both the cost of leverage and the cost of insurance against adverse price moves. \n\nThe funding period of perpetual options is 24 hours. This means that the option premium will be continuously paid out over a 24h period. 24h funding rate = 3 x 8h funding rate",
  "Risk",
  "Vega",
  "Gamma",
  "Estimated Funding P&L for the next 8 hours based on current funding rates",
  "Vega represents the increase in option price based on a 1% increase in the implied volatility.\n\nFor example, a Vega of 5 means the price of the option is expected to increase by $5 for a 1% increase in the implied volatility.",
  "IV",
  "Adjust Leverage",
  "Leverage updated successfully",
  "Failed to update leverage",
  "Adjust leverage parameters for selected market. Leverage will also increase on your open positions.",
  "Please note that changing leverage will also apply for open positions and open orders on this market.",
  "Selecting higher leverage could result in Deleveraging where some of your open orders would be cancelled.",
  "disabled",
  "Margin Mode",
  "Manage your risk on individual positions by restricting the amount of margin allocated to each. If the margin ratio of an isolated position reaches 100%, the position will be liquidated. Margin can be added or removed to individual positions in this mode.",
  "Margin mode updated successfully",
  "Failed to update margin mode",
  "Favorites",
  "Trending",
  "Losers",
  "Gainers",
  "New Listing",
  "Developers",
  "Developer Mode",
  "Enable developer mode",
  "For external use",
  "Warning: Changing transfer behavior",
  "USDC will be transferred to account contract instead of Paradex. Amount won't be visible or available for use within Paradex, but can be used with external applications.",
  "Affiliate Dashboard",
] as const;

type Keys = (typeof keys)[number];
type KeysToDo = (typeof keysToDo)[number];
type Translations = { [K in Keys]: K };

const translations = {
  ...(Object.fromEntries(keys.map((key) => [key, key])) as Translations),
  Deposit_verb: "Deposit",
  Deposit_noun: "Deposit",
} as const;

export type { Keys, KeysToDo };
export type TranslationResources = typeof translations;
export default translations;
