import{useCallback,useMemo}from'react';import Cache from'timed-cache';import{useApi}from"../../api/fetch-api";import{getOrder}from"../../api/orders";import*as FillFn from"../fill/functions";import{logException}from"../logging/logging";import{usePerpetualMarketsState}from"../perpetuals/market-info/markets-context";import{useWalletView}from"../wallet/wallet-context";import ms from"../../utils/ms";import useWSListener from"../../utils/useWSListener";import{getFillNotificationType,getOrderNotificationType}from"./functions";import{publishNotification}from"./topic";export function useTradeNotifications(){useOrdersListener();useFillsListener();}function useOrdersListener(){const walletView=useWalletView();const isReady=walletView.isSignedIn;const channel='orders.ALL';const{markets}=usePerpetualMarketsState();const handleWSNotification=useCallback(notif=>{const order=notif.data;const market=markets.entities[order.market];if(markets.ids.length===0)return;// markets not loaded yet
if(market==null){const message=`Unknown market symbol '${order.market}'`;logException(new Error(message));return;}const notificationType=getOrderNotificationType(order);switch(notificationType){case'filled-market-order':{publishNotification({kind:notificationType,props:{order,market}});break;}case'cancelled-market-order':{publishNotification({kind:notificationType,props:{order,market}});break;}case'cancelled-limit-order':{publishNotification({kind:notificationType,props:{order,market}});break;}case'modify-order-success':{publishNotification({kind:notificationType,props:{order,market}});break;}case'modify-order-rejected':{publishNotification({kind:notificationType,props:{order,market}});break;}case'modify-order-info':{publishNotification({kind:notificationType,props:{order,market}});break;}case'submitted-algo-order':case'submitted-order':{/**
           * processed based on REST API response
           */break;}case null:break;// no default
}},[markets.entities,markets.ids.length]);useWSListener({channel,isReady,handleWSNotification});}function useFillsListener(){const walletView=useWalletView();/**
   * Caches promisses to retrieve orders so that multiple fills don't
   * trigger many requests to the same order, even if they start before
   * the first attemt to retrieve the order is finished.
   */const orderPromiseCache=useMemo(()=>new Cache({defaultTtl:ms.minutes(5)}),[]);const isReady=walletView.isSignedIn;const channel='fills.ALL';const{markets}=usePerpetualMarketsState();const[,fetchOrder]=useApi(getOrder);const retrieveOrder=useCallback(async fill=>{const cachedPromise=orderPromiseCache.get(fill.order_id);if(cachedPromise!=null){return cachedPromise;}const promise=new Promise((resolve,reject)=>{fetchOrder({id:fill.order_id},resp=>{if(!resp.ok){const message=`Error fetching order ${fill.order_id} for fill notification`;reject(new Error(message,{cause:resp.error}));return;}const order=resp.data;resolve(order);});});orderPromiseCache.put(fill.order_id,promise);return promise;},[fetchOrder,orderPromiseCache]);const handleWSNotification=useCallback(notif=>{const fill=notif.data;(async function work(){if(markets.ids.length===0){return;// markets not loaded yet
}const market=markets.entities[fill.market];if(market==null){const message=`Unknown market symbol '${fill.market}'`;logException(new Error(message));return;}// Liquidation fills do not have an order associated with them.
const order=FillFn.isLiquidationFill(fill)?null:await retrieveOrder(fill);const notificationType=getFillNotificationType(fill,order);switch(notificationType){case'limit-order-fill':{publishNotification({kind:notificationType,props:{fill,market}});break;}case'liquidation-fill':{publishNotification({kind:notificationType,props:{fill,market}});break;}case null:break;// no default
}})().catch(err=>{const message=`Error processing fill notification for fill.id='${fill.id}' order_id='${fill.order_id}'`;const error=new Error(message,{cause:err});logException(error);});},[markets.entities,markets.ids.length,retrieveOrder]);useWSListener({channel,isReady,handleWSNotification});}