import BigNumber from 'bignumber.js';

function formatFiat(
  value: BigNumber | number,
  options: Intl.NumberFormatOptions,
): string {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: 'symbol',
    notation: 'standard',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
  });
  return formatter.format(typeof value === 'number' ? value : value.toNumber());
}

export function formatUsd(
  value: BigNumber | number,
  options?: Intl.NumberFormatOptions,
): string {
  return formatFiat(value, {
    ...options,
    currency: 'USD',
  });
}
