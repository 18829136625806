import { Dispatch, SetStateAction } from 'react';

import { Abortable } from '#/api/types';

import { AuthActions } from '#/features/auth/auth-context';
import { WalletState } from '#/features/wallet/wallet-context';

import { AsyncResult } from '#/utils/types';

export default function actionPerformAuth(
  ...params: Parameters<typeof performAuth>
) {
  return async ({ signal }: Abortable = {}) =>
    performAuth(params[0], params[1], signal);
}

async function performAuth(
  authActions: AuthActions,
  setState: Dispatch<SetStateAction<WalletState>>,
  signal?: AbortSignal,
): AsyncResult<void> {
  const actions = prepareActions(setState);

  actions.resetError();
  actions.setStatus('signing_auth_request');

  const result = await authActions.performAuth({ signal });

  if (!result.ok) {
    actions.setError(result.reason ?? 'Unknown authentication error');
    actions.setStatus('wallet_connected_idle');

    if (result.error != null) {
      const error = new Error('Error performing wallet authentication', {
        cause: result.error,
      });
      return { ok: false, error };
    }
    return { ok: false, error: null };
  }

  actions.setStatus('wallet_connected_idle');
  return { ok: true, data: undefined };
}

function prepareActions(setState: Dispatch<SetStateAction<WalletState>>) {
  function setStatus(status: WalletState['step']) {
    setState((state) => ({ ...state, step: status }));
  }

  const setError = (error: string) => {
    setState((state) => ({ ...state, error }));
  };

  const resetError = () => {
    setState((state) => ({ ...state, error: '' }));
  };

  return { setStatus, setError, resetError };
}
