import { useEffect, useRef, useState } from 'react';
import { useAccountEffect as useEvmAccountEffect } from 'wagmi';

import { FeatureFlags } from '#/features/feature-flags';
import {
  SignOutReason,
  trackAuthRecoveryStep,
  trackUserSignOut,
} from '#/features/logging/datadog/user-session-tracking';
import { logException } from '#/features/logging/logging';
import { useWalletClient } from '#/features/wallet/common/wallet-client-context';
import { useWalletActions } from '#/features/wallet/wallet-context';
import { useStarknetWalletView } from '#/features/wallets/starknet/wallet-context';
import { useParadexWebSocket } from '#/features/ws/ws-context';

import { useAuthActions, useAuthState } from './auth-context';
import { userSignOutTopic, userUnauthorizedTopic } from './auth-topics';

const isL2OnlySessionEnabled = FeatureFlags.getBooleanValue(
  'l2-only-session-enabled',
  false,
);

const useEvmAccountSetRecoverable = (
  setRecoverable: (value: boolean) => void,
) => {
  useEvmAccountEffect({
    onConnect: ({ isReconnected }) => {
      if ("false" === 'true') {
        setRecoverable(true);
        return;
      }

      // isReconnected will be true if the user reconnected via autoConnect.
      // isReconnected will be false in the scenario where user manually connects the wallet,
      // including the case where the user visits the app with "Remember Me" enabled,
      // but wallet is locked (e.g.locked MetaMask wallet).
      if (!isReconnected) return;
      setRecoverable(true);
    },
  });
};

const useStarknetAccountSetRecoverable = (
  setRecoverable: (value: boolean) => void,
) => {
  const { address } = useStarknetWalletView();
  useEffect(() => {
    if (address != null) {
      setRecoverable(true);
    }
  }, [setRecoverable, address]);
};

export function useAuthRecovery() {
  const [isRecoverable, setRecoverable] = useState(false);
  const wasCalledOnce = useRef(false);
  const ws = useParadexWebSocket();
  const [authState] = useAuthState();
  const authActions = useAuthActions();
  const walletActions = useWalletActions();
  const walletClient = useWalletClient();

  useEvmAccountSetRecoverable(setRecoverable);
  useStarknetAccountSetRecoverable(setRecoverable);

  useEffect(() => {
    if (!isRecoverable && !isL2OnlySessionEnabled) return;
    if (ws == null) return;
    if (ws.readyState === WebSocket.CLOSED) return;
    if (ws.readyState === WebSocket.CLOSING) return;

    if (wasCalledOnce.current) return;
    wasCalledOnce.current = true;

    if (authState.walletSeed == null) return;

    function signOut(signedOutReason: SignOutReason) {
      trackAuthRecoveryStep('failed');
      if (isL2OnlySessionEnabled) {
        trackUserSignOut(signedOutReason, 'token-invalid');
        userUnauthorizedTopic.publish({});
        return;
      }
      trackUserSignOut(signedOutReason);
      userSignOutTopic.publish({});
    }

    (async () => {
      trackAuthRecoveryStep('started');
      const result = await walletActions.connectWallet();
      if (!result.ok) {
        signOut('auth_recovery_connect_wallet_failed');
        return;
      }

      const didUserChangeAccount =
        walletClient.getAddress() !== authState.baseAccount?.address;
      if (didUserChangeAccount) {
        signOut('auth_recovery_address_changed');
        return;
      }

      const resultAuth = await walletActions.onboardAndAuthenticate();
      if (!resultAuth.ok) {
        if (resultAuth.reason === 'failed_to_initialize_paraclear_wallet') {
          signOut('auth_recovery_initialize_wallet_failed');
        }
        if (
          resultAuth.reason === 'failed_to_authenticate' &&
          resultAuth.error != null
        ) {
          const error = new Error(
            'Error authenticating Starknet wallet during auth recovery',
            { cause: resultAuth.error },
          );
          logException(error);
        }
      }
      trackAuthRecoveryStep('success');
    })().catch((err) => {
      const error = new Error(
        "Failed to recover session for a 'Remember Me' user",
        { cause: err },
      );
      logException(error);
      signOut('auth_recovery_failed');
    });
  }, [
    authActions,
    authState.baseAccount,
    authState.walletSeed,
    isRecoverable,
    walletActions,
    walletClient,
    ws,
  ]);
}
