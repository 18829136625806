import BigNumber from 'bignumber.js';
import { LibraryError } from 'starknet';
import * as Yup from 'yup';

import { getTotalAssetsChain } from '#/api/vaults-chain';

import { isContractDeployed } from '#/features/paradex-chain';
import { SystemConfigView } from '#/features/system/system-config-context';
import { minDepositApplies } from '#/features/vaults/functions';
import {
  isBigNumber,
  isFinite,
  isGreaterThan,
  isLessThanOrEqualTo,
  isStarknetAddress,
} from '#/features/vaults/validation';
import { ParaclearProvider } from '#/features/wallets/paraclear/provider';

import { Maybe } from '#/utils/types';

import { DepositFormStore } from './form-store';

import type { TFunction } from '#/features/localization/utils';
import type { TransferrableAmountStore } from '#/features/transfer/transferrable-amount-context';
import type { VaultsConfigStore } from '#/features/vaults/config/store';
import type { VaultSummaryStore } from '#/features/vaults/single-summary/store';
import type { VaultStore } from '#/features/vaults/single/store';
import type { ParadexWallet } from '#/features/wallets/paraclear/wallet-context';

type FormShape = {
  [K in keyof DepositFormStore]: Yup.Schema<unknown>;
};

const validate = async (
  formStore: DepositFormStore,
  vaultStore: VaultStore,
  vaultSummaryStore: VaultSummaryStore,
  vaultsConfigStore: VaultsConfigStore,
  paradexWallet: ParadexWallet,
  t: TFunction,
) => {
  const minInitialDeposit = BigNumber(
    vaultsConfigStore.get()?.min_initial_deposit ?? 0,
  );

  const formSchema = Yup.object().shape<FormShape>({
    asset: Yup.string()
      .required(t('{{field}} is required', { field: t('Asset') }))
      .min(1, 'Asset must be at least 1 character long'),
    vaultAddress: Yup.string()
      .required(t('{{field}} is required', { field: t('Vault Address') }))
      .test(...isStarknetAddress('Vault Address')),
    depositAmount: Yup.mixed<BigNumber>()
      .required(t('{{field}} is required', { field: t('Deposit Amount') }))
      .test(...isBigNumber(`${t('Deposit Amount')}`))
      .test(...isFinite(`${t('Deposit Amount')}`))
      .test(...isGreaterThan(`${t('Deposit Amount')}`, 0))
      .test(
        `${t('Deposit Amount')}`,
        `${t(
          '{{field}} must be greater than the minimum initial deposit of {{minInitialDeposit}}',
          {
            field: t('Deposit Amount'),
            minInitialDeposit: minInitialDeposit.toFormat(),
          },
        )}`,
        (bn: BigNumber) => {
          const vault = vaultStore.data;
          if (vault == null) return true;

          const vaultSummary = vaultSummaryStore.data;
          if (vaultSummary == null) return true;

          if (!minDepositApplies(vault, vaultSummary, paradexWallet))
            return true;

          if (bn.isGreaterThanOrEqualTo(minInitialDeposit)) return true;

          return false;
        },
      )
      .test(
        ...isLessThanOrEqualTo(
          `${t('Deposit Amount')}`,
          Number.MAX_SAFE_INTEGER,
        ),
      ),
  });

  return formSchema.validate(formStore);
};

const validateVaultAddress = async (
  provider: ParaclearProvider,
  address: string,
) => {
  try {
    await isContractDeployed(provider, address);
  } catch (err: unknown) {
    if (
      err instanceof LibraryError &&
      err.message.includes('Contract not found')
    ) {
      const message = `Vault ${address} is not deployed to Paradex Chain`;
      throw new Error(message, { cause: err });
    }
    const message =
      `Failed to verify if vault ${address} is deployed to Paradex Chain: ` +
      `${(err as Maybe<Error>)?.message}`;
    throw new Error(message, { cause: err });
  }
};

const validateMaxTvl = async (
  paraclearProvider: ParaclearProvider,
  config: SystemConfigView,
  formStore: DepositFormStore,
  vaultStore: VaultStore,
  t: TFunction,
) => {
  const vault = vaultStore.data;
  if (vault == null) return;
  if (formStore.depositAmount == null) return;

  const totalAssetsResp = await getTotalAssetsChain({
    paraclearProvider,
    config,
    vaultAddress: formStore.vaultAddress,
  });
  if (!totalAssetsResp.ok) {
    throw new Error(`Failed to get Vault's TVL. Please try again.`);
  }

  const currentVaultTvl = totalAssetsResp.data.amount;
  const exceedsMaxTvl = currentVaultTvl
    .plus(formStore.depositAmount)
    .isGreaterThan(vault.max_tvl);

  if (exceedsMaxTvl) {
    throw new Yup.ValidationError(
      t('Deposit would exceed maximum vault TVL of {{maxTVL}}', {
        maxTVL: vault.max_tvl.toFormat(),
      }),
    );
  }
};

const validateAvailableAmount = async (
  formStore: DepositFormStore,
  transferrableAmountStore: TransferrableAmountStore,
  t: TFunction,
) => {
  const availableAmount = transferrableAmountStore.get(formStore.asset);
  if (availableAmount == null) return;
  if (formStore.depositAmount == null) return;

  if (formStore.depositAmount.isGreaterThan(availableAmount)) {
    throw new Yup.ValidationError(
      t('It is not possible to deposit more than your current balance.'),
    );
  }
};

/**
 * Yup's validate() handles static validation that is known beforehand.
 * While a set of functions handle more complex validation that depends on live data.
 */
export const formSchema = {
  validate,
  validateVaultAddress,
  validateMaxTvl,
  validateAvailableAmount,
};
