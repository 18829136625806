import BigNumber from 'bignumber.js';

import { msToDate } from '#/utils/date';

import { UnixTimeMs } from './types';

export interface RawFundingData {
  market: string;
  created_at: UnixTimeMs;
  funding_index: string;
  funding_premium: string;
  funding_rate: string;
}

export interface FundingData
  extends Omit<
    RawFundingData,
    'created_at' | 'funding_index' | 'funding_premium' | 'funding_rate'
  > {
  readonly created_at: Date;
  readonly funding_index: BigNumber;
  readonly funding_premium: BigNumber;
  readonly funding_rate: BigNumber;
}

export function processFundingData(fundingData: RawFundingData): FundingData {
  return {
    ...fundingData,
    created_at: msToDate(fundingData.created_at),
    funding_index: new BigNumber(fundingData.funding_index),
    funding_premium: new BigNumber(fundingData.funding_premium),
    funding_rate: new BigNumber(fundingData.funding_rate),
  };
}
