import { Keys } from "./en";

const translations: Record<Keys, string> = {
  "Display trade history on chart": "在圖表上顯示交易歷史",
  "Add markets to Favorites in the Markets Browser":
    "在市場瀏覽器中加入收藏市場",
  "Log in": "登入",
  "Export embedded wallet": "導出嵌入式錢包",
  "Email or Social": "電子郵件或社交",
  "Failed to load balances": "無法加載餘額",
  "No balances": "沒有餘額",
  "Verified Operator": "已驗證操作員",
  "Strategy": "策略",
  "Multi-Strategy": "多策略",
  "Master Vault": "主保管庫",
  "Master Vault Details": "主保管庫詳情",
  "Annualized": "年化",
  "Depositors": "存款人",
  "No trade history": "無交易歷史",
  "Unwinding Progress": "清算進度",
  "What are Vaults?": "保管庫是什麼？",
  "APR calculated as the Annualised 30-day Return":
    "APR 以年化 30 天回報率計算",
  "APR": "APR",
  "Initializing": "初始化中",
  "Awaiting Deposit": "等待存款",
  "Vault Age": "保管庫年齡",
  "30D Return": "30天回報",
  "{{field}} must be greater than the minimum initial deposit of {{minInitialDeposit}}":
    "{{field}} 必須大於最小初始存款 {{minInitialDeposit}}",
  "Minimum deposit": "最小存款",
  "Withdrawal amount exceeds available vault shares":
    "提现金额超过可用保管库份额",
  "Deposit would exceed maximum vault TVL of {{maxTVL}}":
    "存款将超过保管库的最大TVL {{maxTVL}}",
  "{{field}} is required": "{{field}} 是必填项",
  "Lockup Remaining": "鎖定期剩餘",
  "{{days}}D": "{{days}}天",
  "<1>{{days}}</1> D": "<1>{{days}}</1>天",
  "<1>{{hours}}</1> H": "<1>{{hours}}</1>時",
  "<1>{{minutes}}</1> M": "<1>{{minutes}}</1>分",
  "Vault settings submitted": "保管庫設定已提交",
  "Vault settings updated": "保管庫設定已更新",
  "Vault Settings": "保管庫設定",
  "Vault not found": "保管庫未找到",
  "The owner receives a {{profitShare}} profit share.":
    "擁有者將獲得 {{profitShare}} 的利潤分成。",
  "This vault is entirely owned by the community.":
    "這個保管庫完全由社區擁有。",
  "Withdraw from Vault submitted": "從保管庫提取已提交",
  "Deposit to Vault submitted": "存入保管庫已提交",
  "Unknown error. Try again.": "未知錯誤。請重試。",
  "Vault Deposit": "保管庫存款",
  "Vault Withdrawal": "保管庫提款",
  "No Lockup": "無鎖定期",
  "Lockup period is not over yet": "鎖定期尚未結束",
  "Protocol": "協議",
  "24H": "24小時",
  "7D": "7天",
  "30D": "30天",
  "All": "全部",
  "You have no investments in vaults": "您在保管庫中沒有投資",
  "Total Value": "總價值",
  "Investments Made": "已進行的投資",
  "Operator": "操作員",
  "Manage Vault": "管理保管庫",
  "Switch to the operator account": "切換到操作員帳戶",
  "Active": "活躍",
  "My Vaults": "我的保管庫",
  "Closed": "已關閉",
  "Deposited": "已存入",
  "Lifetime ROI": "ROI 總和",
  "Owners Equity": "所有者權益",
  "Top Trending": "熱門趨勢",
  "Owner Share": "擁有者份額",
  "Vault Details": "保管庫詳情",
  "Contract": "合約",
  "Withdraw from Vault": "從保管庫提取",
  "Withdraw from Vault successful": "從保管庫提取成功",
  "Failed to withdraw from Vault": "從保管庫提取失敗",
  "Name": "名稱",
  "Deposit": "存款",
  "Tokens": "代幣",
  "Avg. Entry Price": "平均入場價格",
  "Current Price": "當前價格",
  "P&L": "盈虧",
  "My Vault Deposit": "我的保管庫存款",
  "Vaults": "保管庫",
  "Create": "創建",
  "Create My Vault": "創建我的保管庫",
  "Vault Name": "保管庫名稱",
  "Vault Description": "保管庫描述",
  "Token Symbol": "代幣符號",
  "Deposit Amount": "存款金額",
  "Vault description will be visible publicly and you will not be able to edit this later.":
    "保管庫描述將公開可見，之後您將無法編輯。",
  "Once created, deposit at least {{minDeposit}} USDC to activate the vault. At this moment, only the Max TVL can be changed later.":
    "一旦創建，存入至少{{minDeposit}} USDC以激活保管庫。此時，只有最大TVL可以稍後更改。",
  "Create Vault": "創建保管庫",
  "Profit Share": "利潤分享",
  "Lockup Period (Days)": "鎖定期（天）",
  "Vault was successfully created and is being initialized":
    "保管庫創建成功，正在初始化",
  "Failed to create a vault": "創建保管庫失敗",
  "Maximum TVL": "最大 TVL",
  "No limit": "無限制",
  "Vault Address": "保管庫地址",
  "Deposit to Vault successful": "存入保管庫成功",
  "Failed to deposit to Vault": "存入保管庫失敗",
  "Deposit to Vault": "存入保管庫",
  "Vault": "保管庫",
  "Owner": "擁有者",
  "Age": "年齡",
  "Failed to load vaults": "加載保管庫失敗",
  "No vaults available": "沒有可用的保管庫",
  "Performance": "表現",
  "Vault Value": "保管庫價值",
  "My Deposit": "我的存款",
  "Total P&L": "總盈虧",
  "Protocol Vaults": "協議保管庫",
  "User Vaults": "用戶保管庫",
  "No Data": "無數據",
  "Last Month Return": "上月回報",
  "TVL": "TVL",
  "Total Earnings": "總收益",
  "About": "關於",
  "Vault Stats": "保管庫統計",
  "My Stats": "我的統計",
  "Vault Contract": "保管庫合約",
  "Description": "描述",
  "Max Drawdown": "最大回撤",
  "Volume": "交易量",
  "All-Time P&L": "歷史總盈虧",
  "Days": "天數",
  "Lockup Period": "鎖定期",
  "Search Vaults": "搜索保管庫",
  "Blog": "部落格",
  "Block Explorer": "區塊瀏覽器",
  "Close Vault": "關閉保管庫",
  "Close Vault successful": "關閉保管庫成功",
  "You must close all open positions to close your Vault. Closed vaults cannot be reopened.":
    "您必須關閉所有未平倉頭寸才能關閉您的保管庫。已關閉的保管庫無法重新打開。",
  "Close My Vault": "關閉我的保管庫",
  "Failed to Close the Vault": "關閉保管庫失敗",
  "Only the Main Account can manage the vault": "只有主帳戶可以管理保管庫",
  "Allocate Collateral": "分配抵押品",
  "Switch to Main Account": "切換到主帳戶",
  "Switch to Account": "切換到帳戶",
  "You are currently trading in the {{market}} market.":
    "您目前在{{market}}市場進行交易。",
  "To start trading on Isolated Market you must Allocate Collateral from your Main Account and switch to this Market Account.":
    "要在隔離市場開始交易，您必須從主帳戶分配抵押品並切換到此市場帳戶。",
  "To trade in this market, switch to the Main Account first.":
    "要在此市場進行交易，請先切換到主帳戶。",
  "This account can only trade in the {{market}} market.":
    "此帳戶只能在{{market}}市場進行交易。",
  "Isolated Market": "隔離市場",
  "This feature currently is still in Beta. Backup your Paradex Private Key for additional safety.":
    "此功能目前仍處於測試階段。為了增加安全性，請備份您的Paradex私鑰。",
  "Deposit failed": "存款失敗",
  "Deposit in progress": "存款進行中",
  "External Account": "外部賬戶",
  "Tx Hashes": "交易哈希",
  "Continue to {{bridge}}": "繼續前往{{bridge}}",
  "By clicking Continue to {{bridge}}, you will be sent to {{bridge}} website to bridge your funds out of Paradex.":
    "點擊繼續前往{{bridge}}，您將被引導到{{bridge}}網站以將您的資金從Paradex轉移出去。",
  "By clicking Continue to {{bridge}}, you will be sent to {{bridge}} website to bridge your funds to Paradex.":
    "點擊繼續前往{{bridge}}，您將被引導到{{bridge}}網站以將您的資金轉移到Paradex。",
  "Refer": "推薦",
  "Address": "地址",
  "Date Joined": "加入日期",
  "Referral Code": "推薦代碼",
  "Volume Traded": "交易量",
  "Bridge": "橋",
  "Withdraw to": "提取至",
  "Deposit from": "存入自",
  "Position is already closed": "位置已經關閉",
  "Your Paradex L2 Address is NOT a Public Starknet Address. If you try to send funds to this address on Public Starknet via a cross chain bridge you risk losing your funds. <1>Read more</1>.":
    "您的Paradex L2地址不是公共Starknet地址。如果您嘗試通過跨鏈橋將資金發送到公共Starknet上的此地址，您將面臨失去資金的風險。<1>閱讀更多</1>。",
  "{{chain}} Address": "{{chain}}地址",
  "Copy Paradex Private Key": "複製Paradex私鑰",
  "Your account will be liquidated if Margin Ratio reaches 100%":
    "如果保證金比率達到100%，您的賬戶將被清算",
  "Liquidation": "清算",
  "Fee": "費用",
  "Maker": "製造商",
  "Maker Score": "製造商得分",
  "Maker XP Share": "製造商XP占比",
  "Fee XP Share": "手續費XP占比",
  "Fee XP": "手續費XP",
  "Maker XP": "製造商XP",
  "Liquidation {{side}} Fill": "清算{{side}}填充",
  "Settings": "設置",
  "Market Score": "市場得分",
  "Season {{season}} (coming soon)": "第{{season}}季（即將推出）",
  "Confirm Buy": "確認買入",
  "Confirm Sell": "確認賣出",
  "Other XP": "其他XP",
  "Estimated Receivable Amount": "預計應收金額",
  "Socialized Loss active. Click to learn more.":
    "社會化損失已激活。點擊了解更多。",
  "Analytics": "分析",
  "Sign In to see your account XP stats": "登錄以查看您的賬戶XP統計",
  "<1>Clock Is Out Of Sync</1> The clock on your device is out of sync. This may cause issues with the application. Please make sure your device clock is set to be auto. For more details please <2>see documentation</2>":
    "<1>時鐘不同步</1> 您設備上的時鐘不同步。這可能會導致應用程序出現問題。請確保您的設備時鐘設置為自動。有關更多詳細信息，請<2>參閱文檔</2>",
  "Failed to fetch max withdrawable amount": "無法獲取最大可提取金額",
  "It is not possible to withdraw more than your Max Withdrawable Amount.":
    "不可提取超過您的最大可提取金額。",
  "Max Withdrawable Amount": "最大可提取金額",
  "XP Explainer": "XP說明",
  "Pool": "等級",
  "Quote Quality": "報價品質",
  "Create Your Public Username": "創建您的公共用戶名",
  "Edit Your Public Username": "編輯您的公共用戶名",
  "You have been logged out.": "您已登出。",
  "Maker Volume Score": "製造商成交量得分",
  "Fee Score": "手續費評分",
  "Score Share": "積分占比",
  "Instrument Share": "合約占比",
  "XP": "XP",
  "Total XP": "總XP",
  "24h Change": "24小時變化",
  "Leaderboard": "排行榜",
  "Cancel": "取消",
  "Portfolio": "投資組合",
  "Trade": "交易",
  "Build": "建立",
  "Referrals Explainer": "推薦說明",
  "How It Works": "它是如何工作的",
  "My Referrals": "我的推薦",
  "Your Referral Code": "您的推薦代碼",
  "Affiliate Program": "聯盟計劃",
  "Enrolled": "已註冊",
  "Traders Referred": "已推薦的交易者",
  "Referral Rewards": "推薦獎勵",
  "Referral XP": "推薦XP",
  "Invite Friends": "邀請朋友",
  "Share": "分享",
  "Earn": "賺",
  "Give": "給予",
  "Share your code to invite friends to join Paradex":
    "分享您的代碼以邀請朋友加入Paradex",
  "<1>{{amount}}</1> Fees": "<1>{{amount}}</1> 手續費",
  "You receive {{amount}} of the referred user's fees":
    "您將獲得被推薦用戶費用的{{amount}}",
  "<1>{{amount}}</1> XP": "<1>{{amount}}</1> XP",
  "You receive {{amount}} of the referred user's XP":
    "您將獲得被推薦用戶的{{amount}}XP",
  "<1>{{amount}}</1> Discount": "<1>{{amount}}</1> 折扣",
  "Your friend receives {{amount}} discount on fees":
    "您的朋友在費用上享受{{amount}}折扣",
  "Create and share your custom referral code": "創建並分享您的自定義推薦代碼",
  "If you have a strong social media following (<1>{{followers}}+</1>), you may be eligible for our <2>VIP Affiliate Program</2> with higher commissions and XP.":
    "如果您擁有強大的社交媒體關注者（<1>{{followers}}+</1>），您可能有資格參加我們的VIP聯盟計劃。",
  "You can track our <1>Affiliate Leaderboard</1> to see how you stack up.":
    "您可以查看我們的<1>聯盟排行榜</1>來了解您的排名情況。",
  "Apply": "申請",
  "Log in to see your Referral History.": "登錄以查看您的推薦歷史。",
  "Welcome to Paradex": "歡迎來到 Paradex",
  "Congrats! The referral code you used is now active! It means you can start enjoying a discount on your trading fees!":
    "恭喜！您使用的推薦代碼現在已激活！這意味著您可以開始享受交易費用的折扣！",
  "Congrats! The referral code <1>{{codeProvided}}</1> used is now active!":
    "恭喜！使用的推薦碼 <1>{{codeProvided}}</1> 現在已啟動！",
  "Referral code not activated. The code can only be applied once during the initial onboarding.":
    "推薦碼未激活。該代碼只能在初始登錄期間應用一次。",
  "Referral code <1>{{codeProvided}}</1> not activated. You have already onboarded with <1>{{referredBy}}</1>.":
    "推薦碼 <1>{{codeProvided}}</1> 未激活。您已經與 <1>{{referredBy}}</1> 登錄。",
  "Referral Code Applied": "推薦碼已應用",
  "You will receive <1>{{userAmount}}</1> of referred user's <1>fees</1> <2>and</2> <1>{{userAmountPoints}} XP</1>. They will receive a <1>{{friendAmount}}</1> discount.":
    "您將獲得被推薦用戶的<1>費用</1> <2>和</2> <1>{{userAmountPoints}}XP</1>。他們將獲得<1>{{friendAmount}}</1>折扣。",
  "Community": "社區",
  "Environments": "環境",
  "Language": "語言",
  "System Status": "系統狀態",
  "Docs": "文件",
  "Code Samples": "代碼示例",
  "More": "更多",
  "Stats": "統計",
  "Join Discord": "加入Discord",
  "Notifications": "通知",
  "All caught up!": "全部趕上了！",
  "You have no new notifications": "您沒有新的通知",
  "Connect Wallet": "連接錢包",
  "WalletConnect is taking too long to open. Please refresh the page and try again.":
    "WalletConnect 開啟時間過長。請重新整理頁面並重試。",
  "Unexpected error opening WalletConnect modal. Please refresh the page and try again.":
    "開啟 WalletConnect 模態窗口時發生意外錯誤。請重新整理頁面並重試。",
  "Connect your Wallet": "連接您的錢包",
  "Link Wallet": "鏈接錢包",
  "You will receive a signature request. Signing is free and will not send a transaction.":
    "您將收到簽名請求。簽名是免費的，不會發送交易。",
  "Generate Paradex Chain wallet": "生成Paradex鏈錢包",
  "Generating Paradex Chain wallet": "正在生成Paradex鏈錢包",
  "Paradex Chain wallet generated": "Paradex鏈錢包已生成",
  "Verify that you own this wallet": "驗證您擁有此錢包",
  "Remember Me": "記住我",
  "Only use 'Remember Me' if you are using a secure device you own. Selecting this option can expose your information to  others if you are accessing this wallet from a public device.":
    "只有在使用您自己的安全設備時才使用'記住我'。如果您從公共設備訪問此錢包，選擇此選項可能會將您的信息暴露給其他人。",
  "Continue": "繼續",
  "Disconnect Wallet": "斷開錢包",
  "Disconnect": "斷開",
  "Account": "賬戶",
  "Username": "用戶名",
  "Edit": "編輯",
  "Your username is now hidden. It is not visible to other users.":
    "您的用戶名現在已隱藏。其他用戶看不到。",
  "Your username will be visible to other users unless you choose to hide it. You can always change it later.":
    "除非您選擇隱藏，否則您的用戶名將對其他用戶可見。您隨時可以稍後更改。",
  "Hide my Username": "隱藏我的用戶名",
  "Save": "保存",
  "Username is required": "需要用戶名",
  "Username must be between 5 and 20 characters": "用戶名必須在5到20個字符之間",
  "Username can only contain letters and numbers": "用戶名只能包含字母和數字",
  "Wallets": "錢包",
  "Paradex Block Explorer": "Paradex區塊瀏覽器",
  "Log out": "登出",
  "No markets available": "沒有可用市場",
  "Last Price": "最後價格",
  "Open Interest": "未平倉合約",
  "24h Volume": "24小時交易量",
  "Mark Price": "標記價格",
  "Spot Price": "現貨價格",
  "Eight hour funding rate that is continuously accrued on positions and settled upon any trade. If the funding rate is positive, longs pay shorts. If negative, shorts pay longs.":
    "連續8小時的資金費率，將在任何交易時累計在頭寸上並結算。如果資金費率為正，多頭支付空頭。如果為負，空頭支付多頭。",
  "8h Funding": "8小時資金",
  "Limit": "限價",
  "Market": "市價",
  "Stop Limit": "止損限價",
  "Stop Market": "止損市價",
  "Scaled Order": "比例訂單",
  "Trigger Price": "觸發價格",
  "Input Price": "輸入價格",
  "Position Mark Price": "倉位標記價格",
  "Amount": "數量",
  "Reduce Only": "僅減少",
  "Reduce Only will increase position": "僅減少將增加倉位",
  "Your order stays open until it is filled or you decide to cancel.":
    "您的訂單將保持開放，直到被執行或您決定取消。",
  "Your order gets filled right away, partially or fully, and whatever is left gets canceled instantly.":
    "您的訂單將立即被執行，部分或全部，剩下的將立即被取消。",
  "Conditional limit order that serves to add liquidity to the order book as a Maker order.":
    "條件限價單，用於將流動性添加到訂單簿作為Maker訂單。",
  "Currently the fees are fixed and uniform across the platform. Maker {{makerFee}} / Taker {{takerFee}}":
    "目前，費用在平台上是固定和統一的。製造商 {{makerFee}} / 接受者 {{takerFee}}",
  "Value": "價值",
  "Buy": "買入",
  "BUY": "買入",
  "LONG": "做多",
  "Sell": "賣出",
  "SELL": "賣出",
  "SHORT": "做空",
  "Order Value": "訂單價值",
  "Current Position": "當前持倉",
  "Account Value": "賬戶價值",
  "The total value of your account at current mark prices":
    "您的賬戶在當前標記價格下的總價值",
  "Unrealized P&L": "未實現的損益",
  "Free Collateral": "可用擔保",
  "Account Leverage": "賬戶槓桿",
  "Margin": "保證金",
  "Margin Ratio": "保證金比率",
  "Initial Margin": "初始保證金",
  "Maintenance Margin": "維護保證金",
  "Order Book": "訂單簿",
  "Trades": "交易",
  "Price": "價格",
  "Size": "大小",
  "Total": "總計",
  "Time": "時間",
  "Spread": "價差",
  "Positions": "位置",
  "Open Orders": "未完成訂單",
  "Trade History": "交易歷史",
  "Order History": "訂單歷史",
  "Funding Payments": "資金支付",
  "Side": "方向",
  "Entry Price": "入場價格",
  "Liquidation Price": "清算價格",
  "Unrealized Funding": "未實現的資金",
  "Close Position": "關閉位置",
  "Market Close": "市場關閉",
  "Close Long": "平多倉",
  "Close Short": "平空倉",
  "Type": "類型",
  "Filled": "已填充",
  "Triggered": "觸發",
  "Instruction": "指令",
  "Client Order Id": "客戶訂單ID",
  "Status": "狀態",
  "NEW": "新的",
  "OPEN": "開放",
  "UNTRIGGERED": "未觸發",
  "CLOSED": "已關閉",
  "Pending": "待定",
  "Complete": "完成",
  "Completed": "完成",
  "Failed": "失敗",
  "Bust": "破產",
  "Value / Fee": "價值 / 費用",
  "Liquidity": "流動性",
  "Tx Hash": "交易哈希",
  "Trade Id": "交易 ID",
  "Remaining": "剩餘",
  "Cancel Reason": "取消原因",
  "User Canceled": "用戶已取消",
  "Not Enough Margin": "保證金不足",
  "Empty Market": "空市場",
  "Post Only": "僅發布",
  "Post Only Would Cross": "僅發布會交叉",
  "Remaining IOC Cancel": "剩餘的IOC取消",
  "Unexpected Failure": "意外失敗",
  "Deleverage": "去杠杆",
  "In Liquidation": "在清算中",
  "Self Trade": "自我交易",
  "Asset Unavailable": "資產不可用",
  "Asset Expired": "資產已過期",
  "Order Type Invalid": "訂單類型無效",
  "Price Not Available": "價格不可用",
  "Expired": "已過期",
  "Exceeds Max Slippage": "超出最大滑点",
  "Timeout": "超時",
  "Order Exceeds Position Limit": "訂單超過位置限制",
  "Order Id": "訂單ID",
  "Payment": "付款",
  "Cancel All": "取消全部",
  "{{orderSize}} will be closed at Market price":
    "{{orderSize}}將以市場價格關閉",
  "By connecting a wallet, you agree to <1>Paradex Terms of Service</1> and represent and warrant to Paradex that you are not a <2>Restricted Person</2>.":
    "通過連接錢包，您同意<1>Paradex服務條款</1>，並向Paradex聲明和保證您不是<2>受限人士</2>。",
  "Wallet": "錢包",
  "Deposit_verb": "存款",
  "Deposit_noun": "存款",
  "Withdraw": "取款",
  "Withdrawal": "取款",
  "Transfers": "轉賬",
  "Transfer": "轉賬",
  "Collateral": "抵押品",
  "Asset": "資產",
  "Wallet Balance": "錢包餘額",
  "Actions": "操作",
  "Action": "行動",
  "External Tx Hash": "外部交易哈希",
  "Withdraw to Wallet": "提款到錢包",
  "Deposit to Paradex": "存款到Paradex",
  "You have no balance in your Ethereum wallet. Deposit some {{asset}} and try again.":
    "您的以太坊錢包沒有餘額。存入一些{{asset}}然後再試一次。",
  "It is not possible to deposit more than your current balance.":
    "不能存款超過您當前的餘額。",
  "Available": "可用",
  "Initiate a deposit from your Ethereum wallet to the Paradex bridge. These transactions":
    "從您的以太坊錢包發起一筆存款到Paradex橋。這些交易",
  "Initiate a deposit from your Ethereum wallet to the Paradex bridge. These transactions may take several minutes depending on network conditions. For more information check out our <1>docs</1>.":
    "從您的以太坊錢包發起一筆存款到Paradex橋。這些交易可能需要幾分鐘的時間，具體取決於網絡狀況。欲了解更多信息，請查看我們的<1>文檔</1>。",
  "read more": "閱讀更多",
  "Initiate Deposit to Bridge": "開始存款到橋",
  "Enable USDC on Paradex": "在Paradex上啟用USDC",
  "In order to deposit you must allow Paradex access to at least {{amount}}.":
    "為了存款，您必須允許Paradex訪問至少{{amount}}。",
  "Enable USDC": "啟用USDC",
  "Withdraw initiated": "已開始提款",
  "Withdrawals are a two-step process. First initiate a withdrawal from Paradex":
    "提款是兩步驟的過程。首先從Paradex開始提款",
  "Withdrawals are a two-step process. First initiate a withdrawal from Paradex to the Paradex Bridge. This first step can take 5-16 hours (10 on average). Second, initiate your withdrawal from the Paradex Bridge to your Ethereum wallet via the Deposits / Withdrawals table. For more information check out our <1>docs</1>.":
    "提款是兩步驟的過程。首先從Paradex開始提款到Paradex橋。這第一步可能需要5-16小時 (平均10個)。其次，通過存款/提款表從Paradex橋開始將您的提款轉到您的以太坊錢包。欲了解更多信息，請查看我們的<1>文檔</1>。",
  "Initiate Withdrawal to Bridge": "開始提款到橋",
  "You have no funds to withdraw. It is not possible to withdraw more than your free collateral.":
    "您沒有可提款的資金。不能提款超過您的可用抵押品。",
  "It is not possible to withdraw more than your free collateral.":
    "不能提款超過您的可用抵押品。",
  "You have no collateral": "您沒有抵押品",
  "You have no open positions": "您沒有開放的頭寸",
  "You have no open orders": "您沒有未完成的訂單",
  "You have no trades": "您沒有交易",
  "You have no orders": "您沒有訂單",
  "You have no funding payments": "您沒有資金支付",
  "You have no transfers": "您沒有轉賬",
  "Account in liquidation": "賬戶正在清算",
  "Account is being liquidated. Any open positions will be reduced. Trading now is disabled.":
    "賬戶正在被清算。任何開放的頭寸將被減少。現在禁止交易。",
  "Account liquidated": "賬戶已清算",
  "Your account was liquidated on {{createdAt}}. Any open positions were reduced.":
    "您的賬戶在{{createdAt}}被清算。任何開放的頭寸已被減少。",
  "Deposit to Paradex successful": "存款到Paradex成功",
  "Deposit to Paradex failed": "存款到Paradex失敗",
  "Unexpected error. Try again.": "意外錯誤。請再試一次。",
  "Deposit initiated": "已開始存款",
  "Deposit to bridge initiated": "已開始向橋存款",
  "Deposit to Paradex available": "可以存款到Paradex",
  "Go to Deposits": "去存款",
  "Deposit to Paradex in progress": "正在進行存款到Paradex",
  "Transfer successful": "轉賬成功",
  "Limit {{side}} Order Fill": "限價{{side}}訂單填充",
  "{{type}} {{side}} Order Submitted": "{{type}} {{side}}訂單已提交",
  "{{type}} {{side}} Order Canceled": "{{type}} {{side}}訂單已取消",
  "Reason": "原因",
  "Market {{side}} Order Fill": "市價{{side}}訂單填充",
  "Trade Busted On-Chain": "鏈上交易破裂",
  "Fill id": "填充id",
  "Withdraw to wallet failed": "提款到錢包失敗",
  "Withdrawal to bridge initiated": "已開始向橋提款",
  "Withdrawal error. Try again.": "提款錯誤。請再試一次。",
  "Withdrawal to bridge in progress": "正在進行向橋提款",
  "Withdrawal to wallet initiated": "已開始向錢包提款",
  "Withdrawal to bridge failed": "向橋提款失敗",
  "Transaction error. Try again.": "交易錯誤。請再試一次。",
  "Withdrawal to wallet available": "可以提款到錢包",
  "Go to Withdrawals": "去提款",
  "Withdrawal to wallet successful": "提款到錢包成功",
  "Session expired, please log in again.": "會話已過期，請再次登入。",
  "Failed to submit order": "提交訂單失敗",
  "Your username preferences were updated!": "您的用戶名偏好設置已更新！",
  "Unexpected error updating username preferences":
    "更新用戶名偏好設置時出現意外錯誤",
  "This username already exists. Please choose a different username and try again.":
    "此用戶名已存在。請選擇一個不同的用戶名並再試一次。",
  "Validation Error": "驗證錯誤",
  "Binding Error": "綁定錯誤",
  "Internal Error": "內部錯誤",
  "Not Found": "未找到",
  "Service Unavailable": "服務不可用",
  "Invalid Request Parameter": "無效的請求參數",
  "Order Id Not Found": "找不到訂單ID",
  "Order Is Closed": "訂單已關閉",
  "Order Is Not Open Yet": "訂單尚未開放",
  "Client Order Id Not Found": "找不到客戶訂單ID",
  "Duplicated Client Id": "重複的客戶ID",
  "Invalid Price Precision": "價格精度無效",
  "Invalid Token": "無效的令牌",
  "Invalid Ethereum Address": "無效的以太坊地址",
  "Blocked Ethereum Address": "被阻止的以太幣地址",
  "Invalid Ethereum Signature": "無效的以太坊簽名",
  "Invalid Starknet Address": "無效的Starknet地址",
  "Invalid Starknet Signature": "無效的Starknet簽名",
  "Starknet Signature Verification Failed": "Starknet簽名驗證失敗",
  "Bad Starknet Request": "錯誤的Starknet請求",
  "Ethereum Signer Mismatch": "以太坊簽名者不匹配",
  "Ethereum Hash Mismatch": "以太坊哈希不匹配",
  "Not Onboarded": "未登記",
  "Invalid Timestamp": "無效的時間戳",
  "Invalid Signature Expiration": "無效的簽名過期",
  "Account Not Found": "找不到賬戶",
  "Invalid Order Signature": "無效的訂單簽名",
  "Public Key Invalid": "公鑰無效",
  "Unauthorized Ethereum Address": "未授權的以太坊地址",
  "Ethereum Address Already Onboarded": "以太坊地址已登記",
  "Market Not Found": "找不到市場",
  "Allowlist Entry Not Found": "找不到允許列表條目",
  "Username In Use": "用戶名已被使用",
  "Service access restricted in your region": "您的地區限制服務訪問",
  "No Access Allowed": "不允許訪問",
  "You do not have access to the platform. Please join our Discord to get access.":
    "您無權訪問該平台。請加入我們的Discord以獲取訪問權限。",
  "{{actualChainName}} Wallet Connected": "以太坊錢包已連接",
  'Incorrect network selected "{{actualChainName}}". Please change the network to "{{requiredChainName}}" (id={{requiredChainId}}) in your wallet and retry.':
    '選擇的網絡"{{actualChainName}}"不正確。請在您的錢包中將網絡更改為"{{requiredChainName}}"（id={{requiredChainId}}）並重試。',
  "Choose Wallet": "選擇錢包",
  "Starknet Wallet": "Starknet錢包",
  "Switch to {{env}}": "切換到{{env}}",
  "Search": "搜索",
  "League": "聯賽",
  "Switch Account": "切換賬戶",
  "Rank": "排名",
  "24h XP": "24小時XP",
  "XP Per Day": "每日XP",
  "Season Volume": "賽季交易量",
  "Lifetime Volume": "終身交易量",
  "Pre-Season": "季前賽",
  "Earn More XP": "賺取更多XP",
  "XP Boost": "XP加成",
  "XP Drop": "XP下降",
  "Trader": "交易者",
  "Total Share": "總份額",
  "Maker Share": "製造商份額",
  "Maker Volume Share": "製造商成交量份額",
  "Fee Share": "手續費份額",
  "Fee Volume Share": "手續費成交量份額",
  "No data available": "沒有可用數據",
  "Refer & Earn": "推薦並賺取",
  "Hours": "小時",
  "Minutes": "分鐘",
  "Time/Duration": "時間/持續時間",
  "Runtime": "執行時間",
  "Frequency": "頻率",
  "Number of Orders": "訂單數量",
  "Runtime should not be empty": "執行時間不能為空",
  "Runtime should be less than or equal to 24 hours":
    "執行時間應小於或等於24小時",
  "Inactive": "不活躍",
  "Avg. Price": "平均價格",
  "The minimum required amount for {{numOrders}} orders is {{totalSize}} {{currency}}.":
    "{{numOrders}}筆訂單的最低要求金額為{{totalSize}} {{currency}}。",
  "Take Profit / Stop Loss: Set take profit and stop loss price levels to automate trade actions. When the Mark Price will reach the price you set, it will send your order to the matching engine.":
    "止盈/止損：設置止盈和止損價格水平以自動化交易操作。當標記價格達到您設置的價格時，您的訂單將發送到匹配引擎。",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount with an estimated profit of <3 />.":
    "當標記價格達到 <1 /> 時，將觸發 <2 /> 訂單，以估計的利潤 <3 /> 關閉所選金額。",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount with an estimated loss of <3 />.":
    "當標記價格達到 <1 /> 時，將觸發 <2 /> 訂單，以估計的損失 <3 /> 關閉所選金額。",
  "Edit TP/SL For This Position": "編輯此倉位的止盈/止損",
  "Take Profit": "止盈",
  "Profit": "盈利",
  "Stop Loss": "止損",
  "Loss": "虧損",
  "Confirm": "確認",
  "Take Profit Limit": "限價止盈",
  "Take Profit Market": "市價止盈",
  "Stop Loss Limit": "限價止損",
  "Stop Loss Market": "市價止損",
  "Funding": "資金",
  "Unwinding": "平倉",
  "Cancel TP/SL orders": "取消TP/SL訂單",
  "TP/SL orders submitted successfully.": "TP/SL訂單已成功提交。",
  "Pre-Season + All Seasons": "季前賽 + 所有賽季",
  "Season {{season}}": "賽季 {{season}}",
  "XP Season {{season}}": "賽季 {{season}} XP",
  "Slippage": "滑點",
  "Max Slippage": "最大滑點",
  "Max slippage defines the largest acceptable price deviation from the mark price.":
    "最大滑點定義了標記價格中可接受的最大價格偏差。",
  "During execution, the order can be canceled if the price exceeds the slippage limit or falls outside the Market <1>Price Band</1>.":
    "在執行過程中，如果價格超出滑點限制或超出市場<1>價格區間</1>，訂單可能會被取消。",
  "Update Max Slippage": "更新最大滑點",
  "The Maximum Slippage allowed for {{symbol}} is {{price_band_width}}.":
    "{{symbol}}的最大允許滑點是{{price_band_width}}。",
  "Mark IV": "Mark IV",
  "Delta": "Delta",
  "Failed to load positions": "載入倉位失敗",
  "No open positions": "沒有開放倉位",
  "learn more": "了解更多",
  "Learn More": "了解更多",
  "Search Markets": "搜尋市場",
  "Futures": "期貨",
  "Options": "期權",
  "OI": "未平倉合約",
  "My Profile": "我的賬戶",
  "Addresses": "地址",
  "Chart": "圖表",
  "ROI": "ROI",
  "Sharpe": "Sharpe",
  "Max Loss": "最大損失",
  "Total Volume": "總交易量",
  "My NFTs": "我的NFT",
  "My XP": "我的XP",
  "Balances": "餘額",
  "Other": "其他",
  "Paradex XP: Pre-Season": "Paradex XP: 季前賽",
  "Paradex XP: Season {{season}}": "Paradex XP: 賽季 {{season}}",
  "Season {{season}} XP": "賽季 {{season}} XP",
  "Refer your friends to earn 10%": "推薦朋友並賺取10%",
  "Refer Friends and Earn 10%": "推薦朋友並賺取10%",
  "Invest in Paradex Vault & Earn 3X Boost": "投資Paradex Vault並賺取3X Boost",
  "Avg. Daily XP": "平均每日XP",
  "Lifetime XP": "終身XP",
  "My Rank": "我的排名",
  "What is XP?": "XP是什麼？",
  "XP History": "XP歷史",
  "Last Week XP": "上周XP",
  "TVL XP": "TVL XP",
  "OI XP": "OI XP",
  "Liquidation XP": "Liquidation XP",
  "Vaults XP": "Vaults XP",
  "Week": "周",
  "Season": "赛季",
  "Last updated: {{timestamp}}": "最後更新: {{timestamp}}",
  "Xperience Points (XP)": "經驗點數 (XP)",
  "Log in to see your XP balance": "登入以查看您的XP餘額",
  "Log in to see where you stand": "登入以查看您所在的位置",
  "Connect your X account to join Pro League": "連接您的X賬戶以加入職業聯賽",
  "Avg. Fill Price": "平均成交價",
  "Spot": "現貨",
  "Derivatives": "衍生品",
  "P&L Chart": "P&L圖表",
  "Account Value Chart": "賬戶價值圖表",
  "Funding History": "資金歷史",
  "Manage Funds": "管理資金",
  "Profile": "賬戶",
  "Community XP": "社區XP",
  "XP Share": "XP份額",
  "Next XP Drop in": "下一個XP下降",
  "In Progress": "進行中",
  "{{field}} must be greater than {{value}}": "{{field}}必須大於{{value}}",
  "{{field}} must be less than {{value}}": "{{field}}必須小於{{value}}",
  "Enter a valid Take Profit or Stop Loss price": "請輸入有效的止盈或止損價格",
  "Take Profit and Stop Loss prices cannot be the same":
    "止盈價和止損價不能相同",
  "Breakeven Price": "損益平衡價格",
  "Automatic Withdrawal": "自動提現",
  "Estimated Gas Fee": "預計Gas費",
  "Auto Withdrawal": "自動提現",
  "Withdrawing...": "提現中...",
  "Paradex Bridge withdrawals can take between 5-16 hours to complete":
    "Paradex Bridge提現可能需要5-16小時完成",
  "Socialized Loss": "社會化損失",
  "Receivable Amount": "可收金額",
  "Auto withdrawing...": "自動提現中...",
  "Manage Twitter Connection": "管理Twitter連接",
  "Unlock additional functionality.": "解鎖額外功能。",
  "Please note this connection can't be used as a login method to Paradex.":
    "請注意，此連接不能用作Paradex的登錄方法。",
  "Toast Notifications": "Toast通知",
  "Display toast notifications": "顯示Toast通知",
  "Average Entry Price": "平均入場價",
  "Display average entry price line on chart": "在圖表上顯示平均入場價線",
  "Display liquidation price line on chart": "在圖表上顯示清算價格線",
  "Display open order lines on chart": "在圖表上顯示開放訂單線",
  "Failed to disconnect Twitter": "無法斷開Twitter連接",
  "Failed to authorize Twitter": "無法授權Twitter",
  "Twitter account linked successfully!": "Twitter賬戶已成功連接！",
  "Failed to link twitter account": "無法連接Twitter賬戶",
  "Fees": "費用",
  "Taker pays {{takerFee}} in fees. Maker receives {{makerRebate}} in rebates.":
    "Taker支付{{takerFee}}的費用。Maker收到{{makerRebate}}的回扣。",
  "Manage Discord Connection": "管理Discord連接",
  "Failed to authorize Discord": "無法授權Discord",
  "Discord account linked successfully!": "Discord賬戶已成功連接！",
  "Failed to link Discord account": "無法連接Discord賬戶",
  "Failed to disconnect Discord": "無法斷開Discord連接",
  "Connect": "連接",
  "Unrealized P&L from current vault token balance. This doesn't account for owner profit share or slippage from positions unwind.":
    "當前vault token餘額的未實現P&L。這不包括所有者利潤分享或從倉位解開引起的滑點。",
  "Show P&L Amount": "顯示P&L金額",
  "Receivable amount must be positive": "可收金額必須為正",
  "Wallet Address Change Detected": "檢測到錢包地址變更",
  "You can proceed with connecting your Paradex account to this wallet address or continue using your current connection.":
    "您可以將Paradex帳戶連接到此錢包地址，或繼續使用當前連接。",
  "Connect To New Wallet": "連接到新錢包",
  "Keep Using Current Wallet": "繼續使用當前錢包",
  "Connect Current Wallet": "連接當前錢包",
  "The account is not connected. Please make sure that <1>{{walletAddress}}</1> is connected and is the active account in your <2>{{walletName}}</2> wallet.":
    "賬戶未連接。請確保<1>{{walletAddress}}</1>已連接並且是您的<2>{{walletName}}</2>錢包中的活動賬戶。",
  "Reconnecting": "重新連接",
  "There is a request in progress. Please proceed in your wallet.":
    "請求正在進行中。請在您的錢包中繼續。",
  "Modify Order": "修改訂單",
  "Realized P&L": "已實現盈虧",
  "Close All": "全部關閉",
  "Close All Positions": "關閉全部倉位",
  "Market Close All Positions": "市場關閉全部倉位",
  "Close {{percent}}% of All Positions": "關閉全部倉位的{{percent}}%",
  "Failed to close all positions": "無法關閉全部倉位",
  "Number of Positions": "倉位數量",
  "Markets": "市場",
  "Position Value (Closing)": "倉位價值（關閉）",
  "{{count}} positions will be rounded to the nearest order increment":
    "{{count}}倉位將四捨五入到最近的訂單增量",
  "You will earn Bonus XP on your referral activity":
    "您將在推薦活動中獲得 XP 獎勵",
  "Token Price": "代幣價格",
  "Vault Token Price Chart": "Vault代幣價格圖表",
  "Market Close Orders Submitted": "市場關閉所有訂單",
  "This profile is already linked to a different Paradex account. Disconnect socials from existing account and try again.":
    "此配置文件已與不同的Paradex帳戶鏈接。斷開現有帳戶的社交網絡並重試。",
  "XP Drops every Friday": "XP 每週五下降",
  "No XP Data Available": "沒有XP數據",
  "XP data is available only for the Main Account.": "XP數據僅適用於主帳戶。",
  "Please switch to your Main Account to view your XP.":
    "請切換到您的主要帳戶以查看您的XP。",
  "Referrals Not Available": "沒有Referrals",
  "Referrals are available only for the Main Account.":
    "Referrals僅適用於主帳戶。",
  "Please switch to your Main Account to view your Referrals.":
    "請切換到您的主要帳戶以查看您的Referrals。",
  "Deposit initiated via Layerswap": "存款通過Layerswap發起",
  "Position History": "持倉歷史",
  "Avg. Close Price": "平均平倉價格",
  "Max Size": "最大規模",
  "Closed Size": "平倉規模",
  "Opened": "已開倉",
  "You have no positions": "您沒有持倉",
  "Destination Address": "目標地址",
  "Withdrawal via Layerswap initiated": "通過Layerswap發起提現",
  "Withdrawal via Layerswap failed": "通過Layerswap發起提現失敗",
  "Market Share": "市場份額",
  "Tier": "等級",
  "Avg Maker Volume": "平均Maker成交量",
  "Liquidity Score": "流動性評分",
  "Reward Share": "獎勵份額",
  "Enter amount": "輸入金額",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount at <3 /> with an estimated profit of <4 />.":
    "當標記價格達到<1 />時，將觸發<2 />訂單以<3 />關閉選定數量，預計利潤為<4 />。",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount at <3 /> with an estimated loss of <4 />.":
    "當標記價格達到<1 />時，將觸發<2 />訂單以<3 />關閉選定數量，預計虧損為<4 />。",
  "Limit Price": "限價",
  "Delete": "刪除",
  "Use other bridges from the list above to withdraw funds much faster.":
    "使用上方列表中的其他橋接方式可更快提取資金。",
  "Paradex Chain Monitor": "Paradex鏈監控",
  "OTM Amount": "虛值數量",
  "Leverage": "槓桿",
  "Strikes": "履約價",
  "Strike": "履約價",
  "Change": "變化",
  "Dist. to Spot": "距現貨",
  "Bid": "買入",
  "Mark": "標記",
  "Ask": "賣出",
  "IV": "隱含波動率",
  "Dist %": "距離 %",
  "Call": "看漲",
  "Put": "看跌",
  "Mark Price / IV": "標記價格 / 隱含波動率",
  "8h F-Funding": "8小時資金費率",
  "No Strikes Available": "無可用履約價",
  "Attributes": "屬性",
  "Why no Expiries?": "為什麼沒有到期日？",
  "{{multiplier}}x Boost": "{{multiplier}}倍提升",
  "Like perpetual futures, perpetual options have no expiration, offering continuous exposure to the underlying asset with built-in downside protection. This gives users the flexibility to hold their position indefinitely and decide the optimal time to close it based on their strategy":
    "與永續期貨類似，永續期權沒有到期日，提供對標的資產的持續敞口，並內置下行保護。這使用戶可以靈活地無限期持有頭寸，並根據其策略決定最佳平倉時機",
  "Implied Volatility used to calculate the option's Mark Price":
    "用於計算期權標記價格的隱含波動率",
  "Distance between the Strike Price and the underlying Spot Price":
    "履約價與標的現貨價格之間的距離",
  "Eight hour funding that is continuously accrued on positions and settled upon any trade. If the funding rate is positive, longs pay shorts. If negative, shorts pay longs":
    "在頭寸上持續累積並在任何交易時結算的八小時資金費率。如果資金費率為正，多頭支付空頭。如果為負，空頭支付多頭",
  "The Leverage Ratio (Lambda) measures an option's effective leverage by indicating how much the option's value changes, in percentage terms, for a 1% change in the spot price of the underlying asset. For example, if an option has a Lambda of 50 and the spot price increases by 1%, the option's value would increase by 50%":
    "槓桿比率（Lambda）通過指示標的資產現貨價格變動1%時期權價值的百分比變化來衡量期權的有效槓桿。例如，如果期權的Lambda為50，現貨價格上漲1%，期權價值將上漲50%",
  "Option delta measures how much the price of an option is expected to change for a $1 change in the price of the underlying asset. For example, a delta of 0.5 means the option price will move $0.50 for every $1 move in the underlying":
    "期權delta衡量標的資產價格變動1美元時期權價格的預期變動。例如，delta為0.5意味著標的資產每變動1美元，期權價格將變動0.50美元",
  "Eight hour funding rate of the perpetual future on the same underlying asset":
    "同一標的資產永續期貨的八小時資金費率",
  "Having trouble? Try signing out and connecting again.":
    "遇到問題？嘗試登出並重新連接。",
  "API Reference": "API參考",
  "XP Distribution occurs once per week on Friday at ~12 PM UTC":
    "XP分發每週五UTC時間12點左右進行一次",
  "What are Perpetual Options?": "什麼是永續期權？",
  "Delta represents the increase in option price based on a $1 increase in the underlying spot price.\n\nFor example, a delta of 0.50 means the price of the option is expected to increase by $0.50 for a $1 increase in the underlying spot price.":
    "Delta表示基於標的現貨價格上漲1美元的期權價格增加。\n\n例如，delta為0.50意味著標的現貨價格上漲1美元時，期權價格預計將上漲0.50美元。",
  "Leverage(Lambda) represents the percentage change in option price for a 1% change in the underlying spot price.\n\nFor example, a lambda of 10 means the option price is expected to increase by 10% if the underlying spot price increases by 1%.":
    "槓桿（Lambda）表示標的現貨價格變動1%時期權價格的百分比變化。\n\n例如，lambda為10意味著如果標的現貨價格上漲1%，期權價格預計將上漲10%。",
  "Eight hour options funding rate that is continuously accrued on positions and settled upon any trade. The funding rate for perpetual options reflects both the cost of leverage and the cost of insurance against adverse price moves. \n\nThe funding period of perpetual options is 24 hours. This means that the option premium will be continuously paid out over a 24h period. 24h funding rate = 3 x 8h funding rate":
    "在頭寸上持續累積並在任何交易時結算的八小時期權資金費率。永續期權的資金費率反映了槓桿成本和對不利價格變動的保險成本。\n\n永續期權的資金期間為24小時。這意味著期權費將在24小時期間內持續支付。24小時資金費率 = 3 x 8小時資金費率",
  "Risk": "風險",
  "Vega": "Vega",
  "Gamma": "Gamma",
  "Estimated Funding P&L for the next 8 hours based on current funding rates":
    "基於當前資金費率的未來8小時預估資金盈虧",
  "Vega represents the increase in option price based on a 1% increase in the implied volatility.\n\nFor example, a Vega of 5 means the price of the option is expected to increase by $5 for a 1% increase in the implied volatility.":
    "Vega表示基於隱含波動率上漲1%的期權價格增加。\n\n例如，Vega為5意味著隱含波動率上漲1%時，期權價格預計將上漲5美元。",
  "Adjust Leverage": "調整槓桿",
  "Leverage updated successfully": "槓桿更新成功",
  "Failed to update leverage": "槓桿更新失敗",
  "Adjust leverage parameters for selected market. Leverage will also increase on your open positions.":
    "調整所選市場的槓桿參數。您的未平倉頭寸的槓桿也將增加。",
  "Please note that changing leverage will also apply for open positions and open orders on this market.":
    "請注意，更改槓桿也將適用於此市場的未平倉頭寸和未完成訂單。",
  "Selecting higher leverage could result in Deleveraging where some of your open orders would be cancelled.":
    "選擇更高的槓桿可能會導致去槓桿化，其中一些未完成的訂單將被取消。",
  "disabled": "已禁用",
  "Margin Mode": "保證金模式",
  "Manage your risk on individual positions by restricting the amount of margin allocated to each. If the margin ratio of an isolated position reaches 100%, the position will be liquidated. Margin can be added or removed to individual positions in this mode.":
    "通過限制分配給每個頭寸的保證金金額來管理個別頭寸的風險。如果隔離頭寸的保證金比率達到100%，該頭寸將被清算。在此模式下，可以添加或移除個別頭寸的保證金。",
  "Margin mode updated successfully": "保證金模式更新成功",
  "Failed to update margin mode": "更新保證金模式失敗",
  "Favorites": "收藏",
  "Trending": "趨勢",
  "Losers": "跌幅",
  "Gainers": "漲幅",
  "New Listing": "新上市",
  "Developers": "開發者",
  "Developer Mode": "開發者模式",
  "Enable developer mode": "啟用開發者模式",
  "For external use": "用於外部使用",
  "Warning: Changing transfer behavior": "警告: 更改傳輸行為",
  "USDC will be transferred to account contract instead of Paradex. Amount won't be visible or available for use within Paradex, but can be used with external applications.":
    "USDC將被轉移到帳戶合約而不是Paradex。金額不會在Paradex中可見或可用，但可以與外部應用程序一起使用。",
  "Affiliate Dashboard": "推薦人儀表板",
} as const;

export default translations;
