import { Transfer } from '#/api/transfers';

import { adapter } from './transfers-state';

import type { TransfersState } from './transfers-state';

export interface TransfersStore {
  readonly status: TransfersState['status'];
  readonly error: TransfersState['error'];
  readonly transfers: {
    selectAll: () => readonly Transfer[];
    selectById: (id: string) => Transfer | undefined;
    selectAvailableDeposits: () => readonly Transfer[];
    selectAllPendingUnwinding: () => readonly Transfer[];
    selectAllPendingUnwindingByVaultAddress: (
      vaultAddress: string,
    ) => readonly Transfer[];
  };
}

const selectors = adapter.getSelectors();

export function createStore(state: TransfersState): TransfersStore {
  return {
    status: state.status,
    error: state.error,
    transfers: {
      selectAll: () => selectors.selectAll(state.transfers),
      selectById: (id) => selectors.selectById(state.transfers, id),
      selectAvailableDeposits: () => {
        const allTransfers = selectors.selectAll(state.transfers);
        return allTransfers.filter(
          (transfer) =>
            transfer.kind === 'DEPOSIT' && transfer.status === 'AVAILABLE',
        );
      },
      selectAllPendingUnwinding: () => {
        const allTransfers = selectors.selectAll(state.transfers);
        return allTransfers.filter(
          (transfer) =>
            transfer.kind === 'UNWINDING' && transfer.status === 'PENDING',
        );
      },
      selectAllPendingUnwindingByVaultAddress: (vaultAddress) => {
        const allTransfers = selectors.selectAll(state.transfers);
        return allTransfers.filter(
          (transfer) =>
            transfer.vault_address === vaultAddress &&
            transfer.kind === 'UNWINDING' &&
            transfer.status === 'PENDING',
        );
      },
    },
  };
}
