import { useCallback, useEffect, useRef, useState } from 'react';

import { getTradingViewSymbol } from '#/api/tradingview';

import { useLaunchDarklyFlag } from '#/features/launchdarkly-feature-flags';
import { logEvent, logException } from '#/features/logging/logging';

import { PRICE_KINDS } from '../charting';

import type { UDFCompatibleDatafeed } from '#/features/tradingview/datafeeds/udf/udf-compatible-datafeed';
import type {
  IChartWidgetApi,
  IDropdownApi,
} from '#/vendor/charting_library/charting_library';
import type { PriceKind, TradingViewWidget } from '../charting';

const CHEVRON_ICON = `<svg style="margin-left: 4px;" width="8.344" viewBox="0 0 8.344 4.884" xmlns="http://www.w3.org/2000/svg"><path d="m1 1 3.172 2.884L7.345 1" stroke="currentColor" stroke-width="1.4" fill="none" stroke-linecap="round" stroke-linejoin="round"/></svg>`;

const CHART_TYPE_TITLE: Record<PriceKind, string> = {
  last: 'Last Price',
  mark: 'Mark Price',
} as const;

/**
 * Implements price kind dropdown UI for the TradingView chart
 */
export default function usePriceKindDropdown(
  widget: TradingViewWidget | null,
  datafeed: UDFCompatibleDatafeed,
  symbol: string | undefined,
) {
  const isLoadingRef = useRef(false);
  const dropdownApiRef = useRef<IDropdownApi | null>(null);
  const [priceKind, setPriceKind] = useState<PriceKind | null>(null);
  const enableDynamicActiveMarket = useLaunchDarklyFlag(
    'enableDynamicActiveMarket',
  );

  const updatePriceKind = useCallback((type: PriceKind | null) => {
    setPriceKind(type);
    // WIP: approach to be refactored, should use a ts module to store state outside of react instead of window (e.g. similar to src/api/auth.ts)
    window.overrideMarketPriceKind = type;
  }, []);

  /**
   * Handle isLoading state
   */
  useEffect(() => {
    if (widget == null) return;
    const updateIsLoading = () => {
      isLoadingRef.current = false;
    };
    let chart: IChartWidgetApi | null = null;
    widget.onChartReady(() => {
      chart = widget.activeChart();
      chart.onDataLoaded().subscribe(null, updateIsLoading);
    });
    return () => {
      widget.onChartReady(() => {
        chart?.onDataLoaded().unsubscribe(null, updateIsLoading);
      });
    };
  }, [widget]);

  /**
   * Initialize with default price kind
   */
  useEffect(() => {
    if (symbol == null) return;
    if (priceKind != null) return; // symbol already initialized

    // WIP: to be extended to persist user's choice of price kind in the IndexedDB. Should take priority over cloud api suggested default.

    getTradingViewSymbol({ symbol })
      .then((resp) => {
        if (!resp.ok) {
          logEvent('Failed to get price_source_id for market.');
          return;
        }
        if (!PRICE_KINDS.includes(resp.data.price_source_id)) {
          logEvent(
            `TradingView Chart: unknown price_source_id='${resp.data.price_source_id}' market='${symbol}'`,
          );
          return;
        }
        updatePriceKind(resp.data.price_source_id);
      })
      .catch((cause) => {
        const message = `TradingView Chart: Unexpected error initializing default price_source_id for market='${symbol}'`;
        const error = new Error(message, { cause });
        logException(error);
      });
  }, [symbol, updatePriceKind, priceKind]);

  /**
   * Create dropdown
   */
  useEffect(() => {
    if (widget == null) return;
    if (!enableDynamicActiveMarket) return;
    if (priceKind == null) return;

    const items = PRICE_KINDS.map((type) => ({
      title: CHART_TYPE_TITLE[type],
      onSelect: () => {
        // Prevent changing price kind while data is loading,
        //   which could lead to stale data being displayed
        if (isLoadingRef.current) return;
        isLoadingRef.current = true;

        updatePriceKind(type);
        /**
         * Reset the chart state to trigger data refetch, In v27 there still seems to be a bug where `widget.activeChart().resetData()` fails to clear some cached data
         * The `widget.load(state)` completely restarts the chart and starts the processes from the beginning.
         * @see https://github.com/tradingview/charting_library/issues/8348
         */
        widget.save((state) => widget.load(state));
      },
    }));

    widget.onChartReady(() => {
      widget
        .createDropdown({
          title: CHART_TYPE_TITLE[priceKind],
          icon: CHEVRON_ICON,
          items,
        })
        .then((api) => {
          dropdownApiRef.current = api;
        })
        .catch((cause: unknown) => {
          const message =
            'TradingView Chart: Failed to create chart type dropdown';
          const error = new Error(message, { cause });
          logException(error);
        });
    });

    return () => {
      if (dropdownApiRef.current != null) {
        dropdownApiRef.current.remove();
        dropdownApiRef.current = null;
      }
    };
  }, [widget, enableDynamicActiveMarket, priceKind, datafeed, updatePriceKind]);

  /**
   * Update dropdown title when selection changes
   */
  useEffect(() => {
    if (dropdownApiRef.current == null) return;
    if (priceKind == null) return;

    const title = CHART_TYPE_TITLE[priceKind];
    dropdownApiRef.current.applyOptions({ title });
  }, [priceKind]);

  /**
   * Reset price kind to default when symbol changes
   */
  useEffect(() => {
    if (symbol == null) return;
    updatePriceKind(null);
  }, [updatePriceKind, symbol]);
}
