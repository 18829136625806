import BigNumber from 'bignumber.js';

import { LIQUIDATOR_VAULT_ADDRESS } from '#/features/vaults/config';
import {
  calcVaultTvlUsd,
  isVaultInLockupPeriod,
  minDepositApplies,
} from '#/features/vaults/functions';
import { hasWithdrawableAmount } from '#/features/vaults/withdraw/functions';

import { formatUsd } from '#/utils/fiat';
import { isMaxInt64 } from '#/utils/number';
import { formatPercent, formatPercentChange } from '#/utils/percent';
import { isEqualAddress } from '#/utils/starknet';

import type { Vault } from '#/api/vaults';
import type { VaultAccountSummary } from '#/api/vaults-account-summary';
import type { VaultSummary } from '#/api/vaults-summary';
import type { TFunction } from '#/features/localization/utils';
import type { Permissions } from '#/features/permissions';
import type { AvgDepositTimeStore } from '#/features/vaults/avg-deposit-time/store';
import type { VaultView } from '#/features/vaults/page-vaults/view';
import type { WalletView } from '#/features/wallet/wallet-context';
import type { ParadexWallet } from '#/features/wallets/paraclear/wallet-context';
import type { Nullable } from '#/utils/types';

interface LockupPeriodViewUtils {
  readonly t: TFunction;
}

export function lockupPeriodView(
  vault: Vault | null,
  utils: LockupPeriodViewUtils,
): string {
  if (vault == null) return '-';
  if (vault.lockup_period.eq(0)) return utils.t('No Lockup');
  const period = vault.lockup_period.toFormat();
  return utils.t('{{days}}D', { days: period });
}

export function profitShareView(vault: Vault | null): string {
  if (vault == null) return '-';
  const value = vault.profit_share.dividedBy(100);
  const formatted = formatPercent(value, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return formatted;
}

export function pointsBoostView(vault: Vault | null): string | null {
  if (vault == null) return null;
  switch (vault.kind) {
    case 'protocol':
    case 'user':
    case 'vtf':
      return null;
    // no default
  }
}

export function visibleStatusView(
  vault: Vault,
  vaultSummary: VaultSummary | null,
  paradexWallet: ParadexWallet,
  utils: { readonly t: TFunction },
): VaultView['visibleStatus'] {
  if (isEqualAddress(vault.address, LIQUIDATOR_VAULT_ADDRESS)) {
    return {
      value: 'DEPRECATED',
      label: 'Deprecated. Please migrate funds to Gigavault',
    };
  }

  switch (vault.status) {
    case 'INITIALIZING':
      return {
        value: 'INITIALIZING',
        label: utils.t('Initializing'),
      };
    case 'CLOSED':
      return {
        value: 'CLOSED',
        label: utils.t('Closed'),
      };
    case 'ACTIVE':
      if (vaultSummary == null) return null;
      if (minDepositApplies(vault, vaultSummary, paradexWallet)) {
        return {
          value: 'AWAITING_DEPOSIT',
          label: utils.t('Awaiting Deposit'),
        };
      }
      return null;
    case 'FAILED':
      return null;
    // no default
  }
}

export function aprView(vaultSummary: VaultSummary | null): {
  readonly value: BigNumber | null;
  readonly formatted: string;
  readonly direction: 'up' | 'down' | null;
} {
  if (vaultSummary == null)
    return { value: null, formatted: '-', direction: null };

  const value = vaultSummary.last_month_return;
  const formatted = formatPercentChange(value, {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });
  const direction = value.isNegative() ? 'down' : 'up';

  return {
    value,
    formatted,
    direction,
  };
}

export function tvlView(vaultSummary: VaultSummary | null): {
  readonly value: BigNumber | null;
  readonly formatted: string;
} {
  if (vaultSummary == null) return { value: null, formatted: '-' };

  const value = calcVaultTvlUsd(vaultSummary);
  if (value == null) return { value: null, formatted: '-' };

  const fractionDigits = value.isGreaterThan(999999) ? 1 : 0;

  const formatted = formatUsd(value, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
    notation: 'compact',
  });

  return { value, formatted };
}

export function maxTvlView(vault: Vault | null): {
  readonly value: BigNumber | null;
  readonly formatted: string;
} {
  if (vault == null || isMaxInt64(vault.max_tvl)) {
    return { value: null, formatted: '-' };
  }

  const formatted = formatUsd(vault.max_tvl, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    notation: 'compact',
  });
  return { value: vault.max_tvl, formatted };
}

export function tradeActionView(
  vault: Vault | null,
  permissions: Permissions,
  walletView: WalletView,
) {
  return {
    isVisible: isVisibleView(),
    isDisabled: isDisabledView(),
  };

  function isVisibleView() {
    if (vault == null) return false;

    const isVaultActive = vault.status === 'ACTIVE';
    if (!isVaultActive) return false;

    if (!permissions.canManageVault(vault)) return false;

    return true;
  }

  function isDisabledView() {
    return !walletView.isSignedIn;
  }
}

export function depositActionView(
  vault: Vault | null,
  permissions: Permissions,
  walletView: WalletView,
) {
  return {
    isVisible: isVisibleView(),
    isDisabled: isDisabledView(),
  };

  function isVisibleView() {
    if (vault == null) return false;

    const isVaultActive = vault.status === 'ACTIVE';
    if (!isVaultActive) return false;

    if (!permissions.canInvestInVaults()) return false;

    return true;
  }

  function isDisabledView() {
    return !walletView.isSignedIn;
  }
}

export function withdrawActionView(
  vault: Vault | null,
  vaultAccountSummary: VaultAccountSummary | null,
  permissions: Permissions,
  walletView: WalletView,
  avgDepositTimeStore: AvgDepositTimeStore,
) {
  const isVisible = isVisibleView();
  const [isDisabled, isDisabledReason] = isDisabledView();

  return {
    isVisible,
    isDisabledReason,
    isDisabled,
  };

  function isVisibleView() {
    if (!walletView.isSignedIn) return false;

    if (!permissions.canInvestInVaults()) return false;

    if (vault == null) return false;

    if (vaultAccountSummary == null) return false;

    const hasTokens = hasWithdrawableAmount(vaultAccountSummary);
    if (!hasTokens) return false;

    return true;
  }

  function isDisabledView(): [false, reason: null] | [true, reason: string] {
    if (vault == null) {
      return [true, 'vault is null'];
    }

    if (avgDepositTimeStore.status === 'loading') {
      return [true, 'vault average deposit time is still loading'];
    }

    const { value: avgDepositTime } = avgDepositTimeStore;
    if (avgDepositTime == null) {
      return [false, null];
    }

    const isInLockupPeriod = isVaultInLockupPeriod(vault, {
      avgDepositTime: avgDepositTime.getTime(),
      referenceTime: Date.now(),
    });
    if (isInLockupPeriod) {
      return [true, 'vault is still in lockup period'];
    }

    return [false, null];
  }
}

export function settingsActionView(
  vault: Vault | null,
  permissions: Permissions,
  walletView: WalletView,
) {
  return {
    isVisible: isVisibleView(),
    isDisabled: isDisabledView(),
  };

  function isVisibleView() {
    if (vault == null) return false;

    const isVaultActive = vault.status === 'ACTIVE';
    if (!isVaultActive) return false;

    if (!permissions.canManageVault(vault)) return false;

    return true;
  }

  function isDisabledView() {
    return !walletView.isSignedIn;
  }
}

export function closeActionView(
  vault: Vault | null,
  permissions: Permissions,
  walletView: WalletView,
) {
  return {
    isVisible: isVisibleView(),
    isDisabled: isDisabledView(),
  };

  function isVisibleView() {
    if (!walletView.isSignedIn) return false;

    if (vault == null) return false;

    const isVaultActive = vault.status === 'ACTIVE';
    if (!isVaultActive) return false;

    if (!permissions.canManageVault(vault)) return false;

    return true;
  }

  function isDisabledView() {
    return false;
  }
}

export function numDepositorsView(vaultSummary: VaultSummary | null): {
  readonly value: number | null;
  readonly formatted: string;
} {
  if (vaultSummary == null) return { value: null, formatted: '-' };
  return {
    value: vaultSummary.num_depositors.toNumber(),
    formatted: vaultSummary.num_depositors.toFormat(),
  };
}

interface AprTooltipViewUtils {
  readonly t: TFunction;
}

export function aprTooltipView(utils: AprTooltipViewUtils): string {
  return utils.t('APR calculated as the Annualised 30-day Return');
}

export function isMultiStrategyView(vault: Vault | null): boolean {
  if (vault?.strategies == null) return false;
  if (vault.strategies.length === 0) return false;
  return true;
}

export function strategyNameView(
  strategyAddress: string,
  vault: Nullable<Vault>,
): string {
  if (vault == null) return '-';
  if (vault.strategies == null) return '-';

  const index = vault.strategies.findIndex(
    (s) => s.address === strategyAddress,
  );
  const strategy = vault.strategies[index];

  if (strategy == null) return '-';

  if (strategy.name === '') return `Strategy ${index + 1}`;

  return strategy.name;
}

export function strategiesView(vault: Vault | null): readonly {
  address: string;
  name: string;
}[] {
  if (vault?.strategies == null) return [];
  if (vault.strategies.length === 0) return [];
  const strategies = vault.strategies.map((strategy) => ({
    address: strategy.address,
    name: strategyNameView(strategy.address, vault),
  }));
  return strategies;
}

export function vaultOwnerAddressView(vault: Vault | null): string {
  if (vault == null) return '-';
  return vault.owner_account;
}

export function vaultOperatorAddressView(vault: Vault | null): string {
  if (vault == null) return '-';
  return vault.operator_account;
}

export function vaultNameView(vault: Vault | null): string {
  if (vault == null) return '-';
  return vault.name;
}

export function vaultAddressView(vault: Vault | null): string {
  if (vault == null) return '-';
  return vault.address;
}
