export const c000000 = '#000000';
export const cFFFFFF = '#FFFFFF';

// Interface colors
export const c0B0E12 = '#0B0E12';
export const c181C20 = '#181C20';
export const c302F38 = '#302F38';

// Core colors
export const cCC38FF = '#CC38FF'; // Purple
export const cFF005C = '#FF005C'; // Red
export const c00EE91 = '#00EE91'; // Green
export const cFFCB23 = '#FFCB23'; // Yellow
export const cFA9400 = '#FA9400'; // Orange

// Gradients only
export const cFDDD78 = '#FDDD78'; // Yellow
export const cFDD34C = '#FDD34C'; // Yellow
export const cDE7FFF = '#DE7FFF'; // Purple
export const c7A35CB = '#7A35CB'; // Purple

// Rank colors
export const cFDD735 = '#FDD735'; // Gold
export const cE7FFFE = '#E7FFFE'; // Silver
export const cFF7425 = '#FF7425'; // Bronze

export const c20ED8E = '#20ED8E'; // Green
export const cB9BBBD = '#B9BBBD'; // Light Gray
