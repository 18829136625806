import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';

interface HistoryEntry {
  id: string;
  created_at: Date;
}

const TOAST_HISTORY_LIMIT = 100;

const adapter = createEntityAdapter<HistoryEntry, HistoryEntry['id']>({
  selectId: (entry) => entry.id,
  sortComparer: (a, b) => b.created_at.getTime() - a.created_at.getTime(),
});
const selectors = adapter.getSelectors();
let state = adapter.getInitialState();

function isOverLimit(
  history: EntityState<HistoryEntry, HistoryEntry['id']>,
): boolean {
  const count = selectors.selectTotal(history);
  return count > TOAST_HISTORY_LIMIT;
}

function removeOldest(
  history: EntityState<HistoryEntry, HistoryEntry['id']>,
): EntityState<HistoryEntry, HistoryEntry['id']> {
  const oldestId = selectors.selectIds(history).at(-1);
  if (oldestId == null) return history;
  return adapter.removeOne(history, oldestId);
}

const addId = (id: HistoryEntry['id']) => {
  const newEntry = {
    id,
    created_at: new Date(),
  };
  state = adapter.setOne(state, newEntry);

  if (isOverLimit(state)) {
    state = removeOldest(state);
  }
};

const hasId = (id: string) => {
  return selectors.selectById(state, id) != null;
};

const toastHistory = { addId, hasId };

export default toastHistory;
