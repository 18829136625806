import { KeysToDo } from '#/features/localization/translations/en';

type TranslationProps = { [key: string]: string };

/** regular expression to identify {{variableName}} sub-strings */
const variableRegExp = /\{\{(.*?)\}\}/gu;
const toDoTranslation = {
  t: (key: KeysToDo, props: TranslationProps = {}) => {
    return key.replace(variableRegExp, (_, name: string) => props[name] ?? '-');
  },
};

/** A utility hook to keep track of strings to be translated */
export const useToDoTranslation = () => toDoTranslation;
