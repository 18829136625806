import { useTransferModalActions } from '#/features/transfer/modal-context';
import { useWalletView } from '#/features/wallet/wallet-context';

/**
 * Hook to be consumed by UI components to trigger transfer flow
 */
export function useTransferAction() {
  const transferModalActions = useTransferModalActions();
  const walletView = useWalletView();

  const isDisabled = !walletView.isSignedIn;

  const execute = () => {
    transferModalActions.open();
  };

  return { execute, isDisabled };
}
