import { OpenPosition, processPosition, RawPosition } from '#/api/positions';

import getQueryString from '#/utils/getQueryString';

import { AsyncResp, BaseReq, requestApi } from './fetch-api';

import type { Nullable } from '#/utils/types';

export type {
  OpenPosition as OpenVaultPosition,
  RawPosition as RawVaultPosition,
} from '#/api/positions';

interface VaultPositionsReq extends BaseReq {
  /** The address of the vault */
  readonly address: string;
  /** Optional strategy address */
  readonly strategyAddress?: Nullable<string>;
}

export interface RawVaultPositionsResp {
  readonly results: readonly RawPosition[];
}

export interface VaultPositionsResp {
  readonly results: readonly OpenPosition[];
}

export async function getVaultPositions(
  req: VaultPositionsReq,
): AsyncResp<VaultPositionsResp> {
  const { signal } = req;

  const queryString = getQueryString([
    ['address', req.address],
    ['strategy', req.strategyAddress],
  ]);

  const resp = await requestApi<RawVaultPositionsResp>({
    signal,
    method: 'GET',
    url: `/vaults/positions${queryString}`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: {
      results: resp.data.results
        .filter((position) => position.status === 'OPEN')
        .map(processPosition) as OpenPosition[],
    },
  };
}
