import { useEffect } from 'react';

import toast from '#/components/common/toast';

import { useAuthActions } from '#/features/auth/auth-context';
import { FeatureFlags } from '#/features/feature-flags';
import { useTranslation } from '#/features/localization/utils';
import { trackUserSignOut } from '#/features/logging/datadog/user-session-tracking';
import {
  useWalletActions,
  useWalletView,
} from '#/features/wallet/wallet-context';

import { UnauthorizedError } from '#/utils/errors';
import { sleep } from '#/utils/sleep';

import { userSignOutTopic, userUnauthorizedTopic } from './auth-topics';

const isL2OnlySessionEnabled = FeatureFlags.getBooleanValue(
  'l2-only-session-enabled',
  false,
);
export function useJwtTokenExpirationHandler() {
  const authActions = useAuthActions();
  const walletActions = useWalletActions();
  const walletView = useWalletView();
  const { t } = useTranslation();

  useEffect(() => {
    const abortController = new AbortController();
    const handleUserUnauthorized = async () => {
      if (isL2OnlySessionEnabled) return;
      // Keep trying to get a token until we get an explicit authorization
      // error. Otherwise either getting a token will succeed or it will fail
      // due to conditions that should generally be temporary like network
      // issues or server errors, in which cases it should resolve itself.
      while (true) {
        if (abortController.signal.aborted) return;
        const authRetryResult = await authActions.performAuth({
          signal: abortController.signal,
        });
        if (authRetryResult.ok) return; // signature is still valid and no need to un-authenticate
        if (authRetryResult.error instanceof UnauthorizedError) break; // proceed to un-authenticate
        authActions.resetToken();
        await sleep(5000);
      }

      trackUserSignOut('jwt_token_recovery_failed');
      userSignOutTopic.publish({});

      toast.warning(t('Session expired, please log in again.'), {
        toastId: 'auth-token-expired',
        autoClose: false,
      });
    };

    // NOTE: This could be called multiple times depending on the
    // number of unauthorized requests that are made after the JWT
    // token expiration. We only want to perform the auth once.
    const unsubscribe = userUnauthorizedTopic.subscribe(handleUserUnauthorized);

    return () => {
      abortController.abort();
      unsubscribe();
    };
  }, [authActions, t, walletActions, walletView]);
}
