import { createEntityAdapter } from '@reduxjs/toolkit';

import { sortDateAscending } from '#/utils/date';

import type { EntityState } from '@reduxjs/toolkit';
import type { SubAccount } from '#/api/account';
import type { LiveConnectionBaseState } from '#/utils/useLiveConnection';

export const adapter = createEntityAdapter<SubAccount, string>({
  selectId: (subAccount) => subAccount.account,
  sortComparer: (a, b) => sortDateAscending(a.created_at, b.created_at),
});

export interface SubAccountsState {
  readonly fetchStatus: LiveConnectionBaseState['status'];
  readonly subAccounts: EntityState<SubAccount, string>;
  readonly recoveryStatus:
    | 'not-started'
    | 'in-progress'
    | 'completed'
    | 'failed';
  readonly error: string;
}

export const INITIAL_STATE: SubAccountsState = {
  fetchStatus: 'disconnected',
  subAccounts: adapter.getInitialState(),
  recoveryStatus: 'not-started',
  error: '',
};
