import { useDepositToVaultModalActions } from '#/features/vaults/deposit/modal-context';

/**
 * Hook to be consumed by UI components to trigger Deposit To Vault flow
 */
export function useDepositToVaultAction() {
  const modalActions = useDepositToVaultModalActions();

  const execute = () => {
    // Prevents focus fighting with the modal
    setTimeout(() => {
      modalActions.open();
    }, 0);
  };

  return { execute };
}
