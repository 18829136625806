import BigNumber from 'bignumber.js';

import { AsyncResp, BaseReq, requestApi } from './fetch-api';

export type AssetKind =
  /** Perpetual Future */
  | 'PERP'
  /** Perpetual Option */
  | 'PERP_OPTION';
type MarketKind = 'cross' | 'isolated';
export type OptionType = 'CALL' | 'PUT';
export type MarketTag = 'MEME' | 'DEFI' | 'AI' | 'LAYER-2' | 'LAYER-1';

export interface RawMarket {
  readonly asset_kind: AssetKind;
  /** @example 'BTC-USD-PERP' */
  readonly symbol: string;
  /** @example 'BTC' */
  readonly base_currency: string;
  /** @example 'USD' */
  readonly quote_currency: string;
  /** @example 'USDC' */
  readonly settlement_currency: string;
  /** @example '0.0001' */
  readonly order_size_increment: string;
  /** @example '1' */
  readonly price_tick_size: string;
  /**
   * Minimum order amount in quote asset.
   * In BTC-USD-PERP for example, the `min_notional` is in USD.
   * @example '1000'
   */
  readonly min_notional: string;
  readonly delta1_cross_margin_params?: {
    readonly imf_base: string;
    readonly mmf_factor: string;
  };
  readonly option_cross_margin_params?: {
    readonly imf: {
      readonly long_itm: string;
      readonly short_itm: string;
      readonly short_otm: string;
      readonly short_put_cap: string;
      readonly premium_multiplier: string;
    };
    readonly mmf: {
      readonly long_itm: string;
      readonly short_itm: string;
      readonly short_otm: string;
      readonly short_put_cap: string;
      readonly premium_multiplier: string;
    };
  };
  /** Market open time in milliseconds */
  readonly open_at: number;
  readonly price_bands_width: string;
  /** Available on PERP_OPTION */
  readonly iv_bands_width?: string;
  readonly market_kind: MarketKind;
  readonly chain_details?: {
    readonly contract_address: string;
  };
  readonly option_type?: OptionType;
  readonly strike_price?: string;
  readonly funding_period_hours: number;
  readonly tags?: MarketTag[];
}

export interface Market
  extends Omit<
    RawMarket,
    | 'order_size_increment'
    | 'price_tick_size'
    | 'min_notional'
    | 'delta1_cross_margin_params'
    | 'open_at'
    | 'price_bands_width'
    | 'iv_bands_width'
    | 'strike_price'
    | 'option_cross_margin_params'
    | 'funding_period_hours'
    | 'tags'
  > {
  readonly order_size_increment: BigNumber;
  readonly price_tick_size: BigNumber;
  readonly min_notional: BigNumber;
  readonly delta1_cross_margin_params?: {
    readonly imf_base: BigNumber;
    readonly mmf_factor: BigNumber;
  };
  readonly option_cross_margin_params?: {
    readonly imf: {
      readonly long_itm: BigNumber;
      readonly short_itm: BigNumber;
      readonly short_otm: BigNumber;
      readonly short_put_cap: BigNumber;
      readonly premium_multiplier: BigNumber;
    };
    readonly mmf: {
      readonly long_itm: BigNumber;
      readonly short_itm: BigNumber;
      readonly short_otm: BigNumber;
      readonly short_put_cap: BigNumber;
      readonly premium_multiplier: BigNumber;
    };
  };
  readonly open_at: Date;
  readonly price_bands_width: BigNumber;
  readonly iv_bands_width: BigNumber | null;
  readonly strike_price: BigNumber | null;
  readonly funding_period_hours: BigNumber;
  readonly tags: MarketTag[];
}

export interface PerpetualMarket extends Market {}

export interface OptionMarket extends Market {}

interface GetMarketsRespRaw {
  readonly results: readonly RawMarket[];
}
export interface GetMarketsResp {
  readonly results: readonly Market[];
}

interface GetMarketsReq extends BaseReq {}

export async function getMarkets(
  req: GetMarketsReq,
): AsyncResp<GetMarketsResp> {
  const { signal } = req;

  const resp = await requestApi<GetMarketsRespRaw>({
    signal,
    method: 'GET',
    url: `/markets`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: {
      results: resp.data.results.map(processMarket),
    },
  };
}

export function processMarket(market: RawMarket): Market {
  let delta1_cross_margin_params: Market['delta1_cross_margin_params'];
  if (market.delta1_cross_margin_params != null) {
    delta1_cross_margin_params = {
      imf_base: new BigNumber(market.delta1_cross_margin_params.imf_base),
      mmf_factor: new BigNumber(market.delta1_cross_margin_params.mmf_factor),
    };
  }
  let option_cross_margin_params: Market['option_cross_margin_params'];
  if (market.option_cross_margin_params != null) {
    option_cross_margin_params = {
      imf: {
        long_itm: new BigNumber(market.option_cross_margin_params.imf.long_itm),
        short_itm: new BigNumber(
          market.option_cross_margin_params.imf.short_itm,
        ),
        short_otm: new BigNumber(
          market.option_cross_margin_params.imf.short_otm,
        ),
        short_put_cap: new BigNumber(
          market.option_cross_margin_params.imf.short_put_cap,
        ),
        premium_multiplier: new BigNumber(
          market.option_cross_margin_params.imf.premium_multiplier,
        ),
      },
      mmf: {
        long_itm: new BigNumber(market.option_cross_margin_params.mmf.long_itm),
        short_itm: new BigNumber(
          market.option_cross_margin_params.mmf.short_itm,
        ),
        short_otm: new BigNumber(
          market.option_cross_margin_params.mmf.short_otm,
        ),
        short_put_cap: new BigNumber(
          market.option_cross_margin_params.mmf.short_put_cap,
        ),
        premium_multiplier: new BigNumber(
          market.option_cross_margin_params.mmf.premium_multiplier,
        ),
      },
    };
  }

  return {
    ...market,
    tags: market.tags ?? [],
    order_size_increment: new BigNumber(market.order_size_increment),
    price_tick_size: new BigNumber(market.price_tick_size),
    min_notional: new BigNumber(market.min_notional),
    delta1_cross_margin_params,
    option_cross_margin_params,
    open_at: new Date(market.open_at),
    price_bands_width: new BigNumber(market.price_bands_width),
    iv_bands_width:
      market.iv_bands_width != null
        ? new BigNumber(market.iv_bands_width)
        : null,
    market_kind: market.market_kind,
    strike_price:
      market.strike_price != null ? new BigNumber(market.strike_price) : null,
    funding_period_hours: new BigNumber(market.funding_period_hours),
  };
}
