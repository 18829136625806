import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';

import { UnsignedOrderReq } from '#/api/orders';

import { useTradeActiveMarketStoreView } from '#/features/perpetuals/active-market-context';
import { useActiveMarketSummaryState } from '#/features/perpetuals/market-info/markets-summary-context';

import { useScaledOrderFormStore } from './form-store';
import * as Formula from './formula';

/**
 * Hook to prepare draft orders based on the current form state.
 * Used to enable live form validation as user types.
 * Debounced to avoid creating perf issues.
 */
export default function usePrepareDraftOrders() {
  const [state, setState] = useState<readonly UnsignedOrderReq[] | null>(null);
  // ESLint fails to check deps for debounced function, setState should be the only dependency
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetState = useCallback(debounce(setState, 400), [setState]);

  const { activeMarket } = useTradeActiveMarketStoreView();
  const activeMarketSummary = useActiveMarketSummaryState();
  const formStore = useScaledOrderFormStore();

  useEffect(() => {
    debouncedSetState(() => {
      if (activeMarket == null) return null;
      if (activeMarketSummary?.mark_price == null) return null;
      if (formStore.startPrice == null) return null;
      if (formStore.endPrice == null) return null;
      if (formStore.currency == null) return null;
      if (formStore.amount == null) return null;
      if (formStore.totalOrders == null) return null;
      if (formStore.sizeSkew == null) return null;

      const nextOrders = Formula.prepareOrders(
        activeMarket,
        activeMarketSummary.mark_price,
        formStore.side,
        formStore.currency,
        formStore.startPrice,
        formStore.endPrice,
        formStore.amount,
        formStore.totalOrders,
        formStore.sizeSkew,
        formStore.isPostOnly,
        formStore.timeInForce,
        formStore.isReduceOnly,
      );
      return nextOrders;
    });
  }, [
    debouncedSetState,
    activeMarket,
    activeMarketSummary,
    formStore.side,
    formStore.currency,
    formStore.startPrice,
    formStore.endPrice,
    formStore.amount,
    formStore.totalOrders,
    formStore.sizeSkew,
    formStore.isPostOnly,
    formStore.timeInForce,
    formStore.isReduceOnly,
  ]);

  return state;
}
