import { usePermissions } from '#/features/permissions';
import { useCreateVaultModalActions } from '#/features/vaults/create-vault/modal-context';

/**
 * Hook to be consumed by UI components to trigger Create a Vault flow
 */
export function useCreateVaultAction() {
  const modalActions = useCreateVaultModalActions();
  const { canCreateVaults } = usePermissions();

  const isDisabled = !canCreateVaults();

  const execute = () => {
    modalActions.open();
  };

  return { execute, isDisabled };
}
