import BigNumber from 'bignumber.js';

import { AsyncResp } from '#/api/fetch-api';

import { SystemConfigView } from '#/features/system/system-config-context';
import { ParaclearProvider } from '#/features/wallets/paraclear/provider';
import ParaclearWallet from '#/features/wallets/paraclear/wallet';

import { fromQuantums } from '#/utils/quantums';

type HexNumber = `0x${string}`;
type Token = 'USDC';
export interface BalanceChain {
  readonly token: Token;
  readonly size: BigNumber;
}

interface Req {
  readonly paraclearProvider: ParaclearProvider;
  readonly ownerAddress: string;
  readonly config: SystemConfigView;
  readonly token: Token;
  readonly contractAddress: string;
}

type RespRaw = [HexNumber?];
export type BalanceChainResp = BalanceChain;

/**
 * Get token asset balance from Paraclear-based contract for a given owner.
 * Fetches from pending block to allow users to use their balance as soon as
 * possible. This is in line with how the transfer-monitoring service works.
 */
export async function getBalanceChain(req: Req): AsyncResp<BalanceChainResp> {
  const { paraclearProvider, ownerAddress, config, token, contractAddress } =
    req;

  const response = await ParaclearWallet.callContract(
    paraclearProvider,
    {
      contractAddress,
      entrypoint: 'getTokenAssetBalance',
      calldata: [ownerAddress, config.getToken(token).l2TokenAddress],
    },
    'pending',
  );

  const [result] = response as RespRaw;
  if (result == null || BigNumber(result).isNaN()) {
    throw new Error(
      `Unexpected response reading token asset balance: result='${result}'`,
    );
  }

  return {
    ok: true,
    status: 200,
    data: processTokenBalance(result, config, token),
  };
}

function processTokenBalance(
  result: HexNumber,
  config: SystemConfigView,
  token: Token,
): BalanceChain {
  return {
    token,
    size: fromQuantums(result, config.paraclear.decimals),
  };
}
