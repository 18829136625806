import BigNumber from 'bignumber.js';
import * as Starknet from 'starknet';

import { AsyncResp } from '#/api/fetch-api';

import { SystemConfigView } from '#/features/system/system-config-context';
import { ParaclearProvider } from '#/features/wallets/paraclear/provider';
import ParaclearWallet from '#/features/wallets/paraclear/wallet';

import { fromQuantums } from '#/utils/quantums';

import type { Hex } from 'viem';

interface TotalAssetsRequest {
  readonly paraclearProvider: ParaclearProvider;
  readonly config: SystemConfigView;
  readonly vaultAddress: string;
}

type TotalAssetsRespRaw = [Hex, Hex];

interface TotalAssets {
  /** Amount of undelying assets (e.g. USDC) */
  readonly amount: BigNumber;
}

interface TotalAssetsResp extends TotalAssets {}

/**
 * Get amount of underlying assets (e.g. USDC)
 */
export async function getTotalAssetsChain(
  req: TotalAssetsRequest,
): AsyncResp<TotalAssetsResp, undefined> {
  const { paraclearProvider, config, vaultAddress } = req;

  const result = await ParaclearWallet.callContract(
    paraclearProvider,
    {
      contractAddress: vaultAddress,
      entrypoint: 'total_assets',
      calldata: [],
    },
    'pending',
  );

  try {
    assertTotalAssetsRespRaw(result);
  } catch (cause) {
    const error = cause as Error;

    return {
      ok: false,
      status: 400,
      error,
      message: error.message,
      data: undefined,
    };
  }

  return {
    ok: true,
    status: 200,
    data: processTotalAssets(result, config),
  };
}

function assertTotalAssetsRespRaw(
  data: unknown,
): asserts data is TotalAssetsRespRaw {
  if (
    data == null ||
    !Array.isArray(data) ||
    data.length !== 2 ||
    !data.every((x) => typeof x === 'string')
  ) {
    throw new Error(
      `Invalid vault token balance response: ${JSON.stringify(data)}`,
    );
  }
}

function processTotalAssets(
  data: TotalAssetsRespRaw,
  config: SystemConfigView,
): TotalAssetsResp {
  const amountChain = Starknet.uint256.uint256ToBN({
    low: data[0],
    high: data[1],
  });
  const amount = fromQuantums(
    amountChain.toString(),
    config.getToken('USDC').decimals,
  );

  return { amount };
}

interface MaxWithdrawChainReq {
  readonly paraclearProvider: ParaclearProvider;
  readonly config: SystemConfigView;
  readonly vaultAddress: string;
  readonly ownerAddress: string;
}

type MaxWithdrawChainRespRaw = [Hex, Hex];

interface MaxWithdrawChain {
  /** Amount of undelying assets (e.g. USDC) */
  readonly amount: BigNumber;
}

export interface MaxWithdrawChainResp extends MaxWithdrawChain {}

/**
 * Get withdrawable amount in USDC.
 * Returns 0 if lock up period is not over.
 */
export async function getMaxWithdrawChain(
  req: MaxWithdrawChainReq,
): AsyncResp<MaxWithdrawChainResp, undefined> {
  const { paraclearProvider, config, vaultAddress, ownerAddress } = req;

  const result = await ParaclearWallet.callContract(
    paraclearProvider,
    {
      contractAddress: vaultAddress,
      entrypoint: 'max_withdraw',
      calldata: [ownerAddress],
    },
    'pending',
  );

  try {
    assertMaxWithdrawRespRaw(result);
  } catch (cause) {
    const error = cause as Error;

    return {
      ok: false,
      status: 400,
      error,
      message: error.message,
      data: undefined,
    };
  }

  return {
    ok: true,
    status: 200,
    data: processMaxWithdraw(result, config),
  };
}

function assertMaxWithdrawRespRaw(
  data: unknown,
): asserts data is MaxWithdrawChainRespRaw {
  if (
    data == null ||
    !Array.isArray(data) ||
    data.length !== 2 ||
    !data.every((x) => typeof x === 'string')
  ) {
    throw new Error(
      `Invalid vault max withdraw response: ${JSON.stringify(data)}`,
    );
  }
}

function processMaxWithdraw(
  data: MaxWithdrawChainRespRaw,
  config: SystemConfigView,
): MaxWithdrawChainResp {
  const amountChain = Starknet.uint256.uint256ToBN({
    low: data[0],
    high: data[1],
  });
  const amount = fromQuantums(
    amountChain.toString(),
    config.getToken('USDC').decimals,
  );

  return { amount };
}

interface AvgDepositTimeChainReq {
  readonly paraclearProvider: ParaclearProvider;
  readonly vaultAddress: string;
  readonly accountAddress: string;
}

type AvgDepositTimeChainRespRaw = [Hex, Hex];

interface AvgDepositTimeChain {
  readonly avgDepositTime: Date;
}

export interface AvgDepositTimeChainResp extends AvgDepositTimeChain {}

export async function getAvgDepositTimeChain(
  req: AvgDepositTimeChainReq,
): AsyncResp<AvgDepositTimeChainResp, undefined> {
  const { paraclearProvider, vaultAddress, accountAddress } = req;

  const result = await ParaclearWallet.callContract(
    paraclearProvider,
    {
      contractAddress: vaultAddress,
      entrypoint: 'avg_deposit_time',
      calldata: [accountAddress],
    },
    'pending',
  );

  try {
    assertAvgDepositTimeRespRaw(result);
  } catch (cause) {
    const error = cause as Error;

    return {
      ok: false,
      status: 400,
      error,
      message: error.message,
      data: undefined,
    };
  }

  return {
    ok: true,
    status: 200,
    data: processAvgDepositTime(result),
  };
}

function assertAvgDepositTimeRespRaw(
  data: unknown,
): asserts data is AvgDepositTimeChainRespRaw {
  if (
    data == null ||
    !Array.isArray(data) ||
    data.length !== 2 ||
    !data.every((x) => typeof x === 'string')
  ) {
    throw new Error(
      `Invalid vault max withdraw response: ${JSON.stringify(data)}`,
    );
  }
}

function processAvgDepositTime(
  data: AvgDepositTimeChainRespRaw,
): AvgDepositTimeChainResp {
  const secondsSinceEpoch = Number(
    Starknet.uint256.uint256ToBN({
      low: data[0],
      high: data[1],
    }),
  );
  const msSinceEpoch = secondsSinceEpoch * 1000;

  return { avgDepositTime: new Date(msSinceEpoch) };
}
