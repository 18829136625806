import BigNumber from 'bignumber.js';

import { AsyncResp, requestMetabase } from './fetch-api';

type MetabaseFundingEntry = readonly [
  market: string,
  total24hVolume: number,
  avg8Hour: number,
  avg1Day: number,
  avg3Day: number,
  avg7Day: number,
  avg1Month: number,
  avg3Month: number,
];

export interface MarketFunding {
  readonly market: string;
  readonly total24hVolume: BigNumber;
  readonly avg8Hour: BigNumber;
  readonly avg1Day: BigNumber;
  readonly avg3Day: BigNumber;
  readonly avg7Day: BigNumber;
  readonly avg1Month: BigNumber;
  readonly avg3Month: BigNumber;
}

interface MetabaseFundingResponse {
  readonly data: {
    readonly rows: readonly MetabaseFundingEntry[];
  };
}

export async function listMarketFunding(): AsyncResp<MarketFunding[]> {
  const resp = await requestMetabase<MetabaseFundingResponse>({
    url: '/api/public/dashboard/0586d87e-bd4b-4abb-8521-0d1e06c16444/dashcard/7987/card/7140?parameters=%5B%5D',
    method: 'GET',
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: resp.data.data.rows.map(processFundingEntry),
  };
}

function processFundingEntry(
  fundingEntry: MetabaseFundingEntry,
): MarketFunding {
  return {
    market: fundingEntry[0],
    total24hVolume: BigNumber(fundingEntry[1]),
    avg8Hour: BigNumber(fundingEntry[2]),
    avg1Day: BigNumber(fundingEntry[3]),
    avg3Day: BigNumber(fundingEntry[4]),
    avg7Day: BigNumber(fundingEntry[5]),
    avg1Month: BigNumber(fundingEntry[6]),
    avg3Month: BigNumber(fundingEntry[7]),
  };
}
