import { logException } from '#/features/logging/logging';

export function getStorageItem<V>(key: string) {
  try {
    if (typeof window === 'undefined') return null;

    const item = window.localStorage.getItem(key);
    if (item == null) return null;

    return JSON.parse(item) as V;
  } catch (err) {
    const message = `Failed to get from localStorage by key='${key}'`;
    logException(new Error(message, { cause: err }));
    return null;
  }
}

export function setStorageItem<V>(key: string, value: V) {
  try {
    if (typeof window === 'undefined') return;
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (err) {
    const message = `Failed to set to localStorage by key='${key}'`;
    logException(new Error(message, { cause: err }));
  }
}

export function removeStorageItem(key: string) {
  try {
    if (typeof window === 'undefined') return;
    window.localStorage.removeItem(key);
  } catch (err) {
    const message = `Failed to remove from localStorage by key='${key}'`;
    logException(new Error(message, { cause: err }));
  }
}
