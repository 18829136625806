import { OpenOrder } from '#/api/orders';
import { Position } from '#/api/positions';

import { ActiveMarginAccountView } from '#/features/account/margin/active-account/active-margin-account-view';
import { WalletView } from '#/features/wallet/wallet-context';

export const MAIN_ACCOUNT_IDENTIFIER = 'MAIN_ACCOUNT';
export function getOnBehalfOfAccountFromPosition(
  walletView: WalletView,
  position: Position,
): string | null {
  if (walletView.paradexAddress == null) {
    return null;
  }
  if (walletView.paradexAddress === position.account) {
    return MAIN_ACCOUNT_IDENTIFIER;
  }
  return position.account;
}

export function getOnBehalfOfAccountFromOrder(
  walletView: WalletView,
  order: OpenOrder,
): string | null {
  if (walletView.paradexAddress == null) {
    return null;
  }
  if (walletView.paradexAddress === order.account) {
    return MAIN_ACCOUNT_IDENTIFIER;
  }
  return order.account;
}

export function getOnBehalfOfAccountFromActiveMarginAccount(
  walletView: WalletView,
  activeMarginAccountView: ActiveMarginAccountView,
): string | null {
  if (walletView.paradexAddress == null) {
    return null;
  }

  if (activeMarginAccountView.account == null) {
    return MAIN_ACCOUNT_IDENTIFIER;
  }
  if (walletView.paradexAddress === activeMarginAccountView.account) {
    return MAIN_ACCOUNT_IDENTIFIER;
  }
  return activeMarginAccountView.account;
}
