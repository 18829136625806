import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { logException } from '#/features/logging/logging';

export default function useLocalStorage<T>(
  key: string,
  initialValue: T,
): [T, Dispatch<SetStateAction<T>>] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() =>
    retrieveValue(key, initialValue),
  );

  useEffect(() => {
    setStoredValue(retrieveValue(key, initialValue));
  }, [initialValue, key]);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (value: T | ((val: T) => T)) => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        // Save state
        setStoredValue(valueToStore);
        // Save to local storage
        if (typeof window !== 'undefined') {
          window.localStorage.setItem(key, JSON.stringify(valueToStore));
        }
      } catch (error) {
        const message = `Failed to set to localStorage by '${key}'`;
        logException(new Error(message, { cause: error }));
      }
    },
    [key, storedValue],
  );

  return [storedValue, setValue];
}

function retrieveValue<T>(key: string, initialValue: T) {
  if (typeof window === 'undefined') {
    return initialValue;
  }
  try {
    const item = window.localStorage.getItem(key);

    if (item == null) {
      return initialValue;
    }

    return JSON.parse(item) as T;
  } catch (error) {
    const message = `Failed to get item from localStorage by '${key}'`;
    logException(new Error(message, { cause: error }));
    return initialValue;
  }
}
