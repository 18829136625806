import BigNumber from 'bignumber.js';

import getQueryString from '#/utils/getQueryString';

import { AsyncResp, BaseReq, requestApi } from './fetch-api';
import { Market } from './markets';
import { UnixTimeMs } from './types';

//#region WebSocket API
type OrderBookSide = 'BUY' | 'SELL';
type OrderBookUpdateType = 's' | 'd';
export interface RawOrderBookUpdate {
  /**
   * indicates `snapshot` or `delta` update
   * - `snapshot` means that existing Order Book should be fully replaced with {@link RawOrderBookUpdate.inserts}
   * - `delta` means that existing Order Book should be altered based on  `deletes`, `updates`, `inserts`
   */
  readonly update_type: OrderBookUpdateType;
  readonly market: Market['symbol'];
  readonly last_updated_at: UnixTimeMs;
  readonly seq_no: number;
  readonly deletes: RawOrderBookUpdateEntry[];
  readonly updates: RawOrderBookUpdateEntry[];
  readonly inserts: RawOrderBookUpdateEntry[];
}
interface RawOrderBookUpdateEntry {
  readonly price: string;
  readonly size: string;
  readonly side: OrderBookSide;
}

export interface OrderBookUpdate
  extends Omit<
    RawOrderBookUpdate,
    'last_updated_at' | 'deletes' | 'updates' | 'inserts'
  > {
  readonly last_updated_at: Date;
  readonly deletes: OrderBookUpdateEntry[];
  readonly updates: OrderBookUpdateEntry[];
  readonly inserts: OrderBookUpdateEntry[];
}

export interface OrderBookUpdateEntry
  extends Omit<RawOrderBookUpdateEntry, 'price' | 'size'> {
  readonly price: BigNumber;
  readonly size: BigNumber;
}

export interface OrderBookUpdateSnapshot extends OrderBookUpdate {
  readonly update_type: 's';
}

export interface OrderBookUpdateDelta extends OrderBookUpdate {
  readonly update_type: 'd';
}

export function processOrderBookUpdate(
  orderBookUpdate: RawOrderBookUpdate,
): OrderBookUpdate {
  return {
    ...orderBookUpdate,
    last_updated_at: new Date(orderBookUpdate.last_updated_at),
    deletes: orderBookUpdate.deletes.map(processOrderBookDeltaEntry),
    updates: orderBookUpdate.updates.map(processOrderBookDeltaEntry),
    inserts: orderBookUpdate.inserts.map(processOrderBookDeltaEntry),
  };
}

function processOrderBookDeltaEntry(
  entry: RawOrderBookUpdateEntry,
): OrderBookUpdateEntry {
  return {
    ...entry,
    price: BigNumber(entry.price),
    size: BigNumber(entry.size),
  };
}
//#endregion

//#region REST API
type RawPriceSize = [price: string, size: string];
export type PriceSizeTuple = [price: BigNumber, size: BigNumber];

export interface RawOrderBook {
  readonly asks: RawPriceSize[];
  readonly bids: RawPriceSize[];
}

export interface OrderBook extends Omit<RawOrderBook, 'asks' | 'bids'> {
  readonly asks: PriceSizeTuple[];
  readonly bids: PriceSizeTuple[];
}

export function processOrderBook(orderBook: RawOrderBook): OrderBook {
  const { asks, bids } = orderBook;

  return {
    asks: asks.map(processPriceSizeTuple),
    bids: bids.map(processPriceSizeTuple),
  };
}

function processPriceSizeTuple([price, size]: RawPriceSize): PriceSizeTuple {
  return [BigNumber(price), BigNumber(size)];
}

interface GetOrderBookReq extends BaseReq {
  readonly market: Market['symbol'];
  /** defines how deep on each direction (bids, asks) the response will be. */
  readonly depth?: number;
}

export type GetOrderBookResp = OrderBook;

export async function getOrderBook(
  req: GetOrderBookReq,
): AsyncResp<GetOrderBookResp> {
  const { signal, market, depth } = req;

  const query = getQueryString([['depth', depth]]);
  const resp = await requestApi<RawOrderBook>({
    signal,
    method: 'GET',
    url: `/orderbook/${market}${query}`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: processOrderBook(resp.data),
  };
}
//#endregion
