export const supportedLocales = [
  'en',
  'zhHans',
  'zhHant',
  'ja',
  'vi',
  'ko',
  'fr',
  'de',
  'pt',
  'es',
  'ru',
  'tr',
  'nl',
  'id',
  'uk',
  'pl',
] as const;

export type Locale = (typeof supportedLocales)[number];
export const resources = {
  de: { translation: async () => import('./translations/de') },
  en: { translation: async () => import('./translations/en') },
  es: { translation: async () => import('./translations/es') },
  fr: { translation: async () => import('./translations/fr') },
  id: { translation: async () => import('./translations/id') },
  ja: { translation: async () => import('./translations/ja') },
  ko: { translation: async () => import('./translations/ko') },
  nl: { translation: async () => import('./translations/nl') },
  pt: { translation: async () => import('./translations/pt') },
  ru: { translation: async () => import('./translations/ru') },
  tr: { translation: async () => import('./translations/tr') },
  vi: { translation: async () => import('./translations/vi') },
  zhHans: { translation: async () => import('./translations/zh_Hans') },
  zhHant: { translation: async () => import('./translations/zh_Hant') },
  uk: { translation: async () => import('./translations/uk') },
  pl: { translation: async () => import('./translations/pl') },
} as const;
