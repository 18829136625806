import { useNavigate } from 'react-router-dom';

import { useSwitchAccount } from '#/features/sub-accounts/account-switch';
import { useVaultStore } from '#/features/vaults/single/store';

import { TRADE_ROUTE } from '#/routes';

/**
 * Hook to be consumed by UI components to trigger Deposit To Vault flow
 */
export function useTradeAction() {
  const vaultStore = useVaultStore();
  const switchAccount = useSwitchAccount();
  const navigate = useNavigate();

  const execute = async () => {
    if (vaultStore.data?.operator_account == null) return;
    try {
      await switchAccount(vaultStore.data.operator_account as `0x${string}`);
      navigate(TRADE_ROUTE);
    } catch (cause) {
      const message = `Error switching to operator account`;
      const error = new Error(message, { cause });
      throw error;
    }
  };

  return { execute };
}
