import type { Dispatch, SetStateAction } from 'react';
import type { BalancesChainState } from '#/features/balance-chain/state';

export interface BalancesChainActions {
  readonly setContractAddress: (contractAddress: string) => void;
}

export function createActions(
  setState: Dispatch<SetStateAction<BalancesChainState>>,
): BalancesChainActions {
  return {
    setContractAddress: (contractAddress) =>
      setState((state) => ({ ...state, contractAddress })),
  };
}
