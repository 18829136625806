import { createEntityAdapter } from '@reduxjs/toolkit';

import type { EntityState } from '@reduxjs/toolkit';

export interface FavoriteMarket {
  readonly symbol: string;
  readonly timestamp: number;
}

export const adapter = createEntityAdapter<FavoriteMarket, string>({
  selectId: (favoriteMarket) => favoriteMarket.symbol,
  sortComparer: (a, b) => b.timestamp - a.timestamp,
});

export interface FavoriteMarketsState {
  readonly favoriteMarkets: EntityState<FavoriteMarket, string>;
}

export const INITIAL_STATE: FavoriteMarketsState = {
  favoriteMarkets: adapter.getInitialState(),
};
