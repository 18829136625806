import * as MarketFn from '#/features/market/functions';

import type { Market } from '#/api/markets';
import type { Position } from '#/api/positions';

export function sizeView(position: Position, market: Market): string {
  const absSize = position.size.abs();
  const formattedSize = MarketFn.formatSize(market, absSize);
  return `${formattedSize} ${market.base_currency}`;
}
