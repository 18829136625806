import BigNumber from 'bignumber.js';

import { msToDate } from '#/utils/date';
import getQueryString from '#/utils/getQueryString';

import {
  AsyncResp,
  BasePaginatedReq,
  PaginatedRespData,
  requestApi,
} from './fetch-api';
import { UnixTimeMs } from './types';

export type TransferKind =
  | 'DEPOSIT'
  | 'WITHDRAWAL'
  | 'AUTO_WITHDRAWAL'
  | 'UNWINDING'
  | 'VAULT_DEPOSIT'
  | 'VAULT_WITHDRAWAL';

export type TransferStatus = 'PENDING' | 'AVAILABLE' | 'COMPLETED' | 'FAILED';

export interface RawTransfer {
  readonly id: string;
  readonly account: string;
  readonly kind: TransferKind;
  readonly status: TransferStatus;
  readonly amount: string;
  readonly token: string;
  readonly created_at: UnixTimeMs;
  readonly last_updated_at: UnixTimeMs;
  /**
   * Transaction hash on Paradex.
   * Empty until the transaction happens.
   */
  readonly txn_hash: string;
  /**
   * Bridge used to make the transfer.
   * Empty string if the transfer is internal to Paradex.
   * WIP temporarily optional while API doesn't return it.
   */
  readonly bridge?: string;
  /**
   * Any errors that happened during the transfer.
   * Empty string if no error happened.
   * WIP temporarily optional while API doesn't return it.
   */
  readonly failure_reason?: string;
  /**
   * Chain where the external transaction happened.
   * For deposits to Paradex, this is the source chain.
   * For withdrawals from Paradex, this is the destination chain.
   * Empty until the external transaction happens.
   * 'PARADEX' if the transfer is internal to Paradex.
   * WIP temporarily optional while API doesn't return it.
   */
  readonly external_chain?: string;
  /**
   * Transaction hash on the {@link external_chain}
   * Empty string until the external transaction happens
   * or in case transfer is internal to Paradex.
   */
  readonly external_txn_hash: string;
  /**
   * For deposits, this is the address where the funds came from.
   * For withdrawals, this is the address where the funds go to.
   * Empty until the account is detected.
   * WIP temporarily optional while API doesn't return it.
   */
  readonly external_account?: string;

  /**
   * Counterparty address of deposit or withdrawal.
   */
  readonly counterparty: string;
  /**
   * Address of the vault.
   * Empty if the transfer is not related to a vault.
   */
  readonly vault_address: string;
  /**
   * Percentage of the unwinding. Applies to UNWINDING transfers only.
   * Empty if the transfer is not related to a vault.
   */
  readonly vault_unwind_completion_percentage: string;
}

export interface Transfer
  extends Omit<
    RawTransfer,
    | 'amount'
    | 'created_at'
    | 'last_updated_at'
    | 'failure_reason'
    | 'bridge'
    | 'external_chain'
    | 'external_account'
    | 'vault_address'
    | 'vault_unwind_completion_percentage'
  > {
  readonly amount: BigNumber;
  readonly created_at: Date;
  readonly last_updated_at: Date;
  readonly failure_reason: string;
  readonly bridge: string;
  readonly external_chain: string;
  readonly external_account: string;
  readonly vault_address: string | null;
  readonly vault_unwind_completion_percentage: BigNumber | null;
}

interface TransfersReq extends BasePaginatedReq {
  readonly state?: TransferStatus;
}

export interface RawTransfersResp extends PaginatedRespData<RawTransfer> {}

export interface TransfersResp extends PaginatedRespData<Transfer> {}

export async function getTransfers(
  req: TransfersReq,
): AsyncResp<TransfersResp> {
  const { state, signal, cursor, page_size } = req;

  const query = getQueryString([
    ['state', state],
    ['cursor', cursor],
    ['page_size', page_size],
  ]);

  const resp = await requestApi<RawTransfersResp>({
    signal,
    method: 'GET',
    url: `/transfers${query}`,
  });

  if (!resp.ok) {
    return resp;
  }

  return {
    ...resp,
    data: {
      ...resp.data,
      results: resp.data.results.map(processTransfer),
    },
  };
}

export function processTransfer(transfer: RawTransfer): Transfer {
  return {
    ...transfer,
    amount: new BigNumber(transfer.amount),
    created_at: msToDate(transfer.created_at),
    last_updated_at: msToDate(transfer.last_updated_at),
    failure_reason: transfer.failure_reason ?? '',
    bridge: transfer.bridge ?? '',
    external_chain: transfer.external_chain ?? '',
    external_account: transfer.external_account ?? '',
    vault_address:
      transfer.vault_address !== '' ? transfer.vault_address : null,
    vault_unwind_completion_percentage:
      transfer.vault_unwind_completion_percentage !== ''
        ? new BigNumber(transfer.vault_unwind_completion_percentage)
        : null,
  };
}
