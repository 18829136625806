import { Keys } from "./en";

const translations: Record<Keys, string> = {
  "Display trade history on chart": "Handelsgeschiedenis in grafiek weergeven",
  "Add markets to Favorites in the Markets Browser":
    "Markten toevoegen aan Favorieten in de Marktbrowser",
  "Log in": "Inloggen",
  "Export embedded wallet": "Ingebouwde portemonnee exporteren",
  "Email or Social": "E-mail of Sociaal",
  "Failed to load balances": "Laden van saldi mislukt",
  "No balances": "Geen saldi",
  "Verified Operator": "Verifieerde Operator",
  "Strategy": "Strategie",
  "Multi-Strategy": "Multi-Strategie",
  "Master Vault": "Hoofdkluis",
  "Master Vault Details": "Hoofdkluis Details",
  "Annualized": "Geannualiseerd",
  "Depositors": "Depositanten",
  "No trade history": "Geen handelsgeschiedenis",
  "Unwinding Progress": "Afwikkelingsvoortgang",
  "What are Vaults?": "Wat zijn Kluizen?",
  "APR calculated as the Annualised 30-day Return":
    "APR berekend als de jaarlijkse 30-daagse rendite",
  "APR": "APR",
  "Initializing": "Initialiseren",
  "Awaiting Deposit": "Wachtend op Storting",
  "Vault Age": "Kluis Leeftijd",
  "30D Return": "30D Rendement",
  "{{field}} must be greater than the minimum initial deposit of {{minInitialDeposit}}":
    "{{field}} moet groter zijn dan de minimale initiële storting van {{minInitialDeposit}}",
  "Minimum deposit": "Minimale storting",
  "Withdrawal amount exceeds available vault shares":
    "Uitstorting overschrijdt beschikbare kluisdelen",
  "Deposit would exceed maximum vault TVL of {{maxTVL}}":
    "Inleg zou het maximale TVL van de kluis van {{maxTVL}} overschrijden",
  "{{field}} is required": "{{field}} is vereist",
  "Lockup Remaining": "Resterende slotperiode",
  "{{days}}D": "{{days}}D",
  "<1>{{days}}</1> D": "<1>{{days}}</1> D",
  "<1>{{hours}}</1> H": "<1>{{hours}}</1> U",
  "<1>{{minutes}}</1> M": "<1>{{minutes}}</1> M",
  "Vault settings submitted": "Kluisinstellingen verzonden",
  "Vault settings updated": "Kluisinstellingen bijgewerkt",
  "Vault Settings": "Kluisinstellingen",
  "Vault not found": "Kluis niet gevonden",
  "The owner receives a {{profitShare}} profit share.":
    "De eigenaar ontvangt {{profitShare}} van de winst.",
  "This vault is entirely owned by the community.":
    "Deze kluis is volledig in eigendom van de gemeenschap.",
  "Withdraw from Vault submitted": "Opname uit Kluis verzonden",
  "Deposit to Vault submitted": "Storting in Kluis verzonden",
  "Unknown error. Try again.": "Onbekende fout. Probeer opnieuw.",
  "Vault Deposit": "Kluis Storting",
  "Vault Withdrawal": "Kluis Opname",
  "No Lockup": "Geen slotperiode",
  "Lockup period is not over yet":
    "Periode van slotperiode is nog niet afgelopen",
  "Protocol": "Protokol",
  "24H": "24U",
  "7D": "7D",
  "30D": "30D",
  "All": "Alles",
  "You have no investments in vaults": "U heeft geen investeringen in vaults",
  "Total Value": "Totale Waarde",
  "Investments Made": "Gedane Investeringen",
  "Operator": "Operator",
  "Manage Vault": "Kluis Beheren",
  "Switch to the operator account": "Overschakelen naar het operatoraccount",
  "Active": "Actieve",
  "My Vaults": "Mijn Kluizen",
  "Closed": "Gesloten",
  "Deposited": "Gestort",
  "Lifetime ROI": "ROI Totale",
  "Owners Equity": "Eigen Vermogen",
  "Top Trending": "Meest Populair",
  "Owner Share": "Eigenaar Aandeel",
  "Vault Details": "Kluis Details",
  "Contract": "Contract",
  "Withdraw from Vault": "Opnemen uit Kluis",
  "Withdraw from Vault successful": "Opname uit Kluis succesvol",
  "Failed to withdraw from Vault": "Opname uit Kluis mislukt",
  "Name": "Naam",
  "Deposit": "Storting",
  "Tokens": "Tokens",
  "Avg. Entry Price": "Gem. Instapprijs",
  "Current Price": "Huidige Prijs",
  "P&L": "Winst & Verlies",
  "My Vault Deposit": "Mijn Kluis Storting",
  "Vaults": "Kluizen",
  "Create": "Aanmaken",
  "Create My Vault": "Mijn Kluis Aanmaken",
  "Vault Name": "Kluis Naam",
  "Vault Description": "Kluis Beschrijving",
  "Token Symbol": "Token Symbool",
  "Deposit Amount": "Stortingsbedrag",
  "Vault description will be visible publicly and you will not be able to edit this later.":
    "De kluisbeschrijving zal openbaar zichtbaar zijn en u kunt dit later niet bewerken.",
  "Once created, deposit at least {{minDeposit}} USDC to activate the vault. At this moment, only the Max TVL can be changed later.":
    "Eenmaal gemaakt, stort minimaal {{minDeposit}} USDC om de kluis te activeren. Op dit moment kan alleen het maximale TVL later worden gewijzigd.",
  "Create Vault": "Kluis Aanmaken",
  "Profit Share": "Winstdeling",
  "Lockup Period (Days)": "Blokkeringsperiode (Dagen)",
  "Vault was successfully created and is being initialized":
    "Kluis is succesvol aangemaakt en wordt geïnitialiseerd",
  "Failed to create a vault": "Aanmaken van kluis mislukt",
  "Maximum TVL": "Maximale TVL",
  "No limit": "Geen limiet",
  "Vault Address": "Kluis Adres",
  "Deposit to Vault successful": "Storting in Kluis succesvol",
  "Failed to deposit to Vault": "Storting in Kluis mislukt",
  "Deposit to Vault": "Storten in Kluis",
  "Vault": "Kluis",
  "Owner": "Eigenaar",
  "Age": "Leeftijd",
  "Failed to load vaults": "Laden van kluizen mislukt",
  "No vaults available": "Geen kluizen beschikbaar",
  "Performance": "Prestatie",
  "Vault Value": "Kluis Waarde",
  "My Deposit": "Mijn Storting",
  "Total P&L": "Totale Winst & Verlies",
  "Protocol Vaults": "Protocol Kluizen",
  "User Vaults": "Gebruiker Kluizen",
  "No Data": "Geen Gegevens",
  "Last Month Return": "Rendement Vorige Maand",
  "TVL": "TVL",
  "Total Earnings": "Totale Inkomsten",
  "About": "Over",
  "Vault Stats": "Kluis Statistieken",
  "My Stats": "Mijn Statistieken",
  "Vault Contract": "Kluis Contract",
  "Description": "Beschrijving",
  "Max Drawdown": "Maximale Drawdown",
  "Volume": "Volume",
  "All-Time P&L": "Winst & Verlies Aller Tijden",
  "Days": "Dagen",
  "Lockup Period": "Blokkeringsperiode",
  "Search Vaults": "Kluizen Zoeken",
  "Blog": "Blog",
  "Block Explorer": "Blok Verkenner",
  "Close Vault": "Kluis Sluiten",
  "Close Vault successful": "Kluis Sluiten succesvol",
  "You must close all open positions to close your Vault. Closed vaults cannot be reopened.":
    "U moet alle open posities sluiten om uw Kluis te sluiten. Gesloten kluizen kunnen niet opnieuw worden geopend.",
  "Close My Vault": "Mijn Kluis Sluiten",
  "Failed to Close the Vault": "Sluiten van de Kluis mislukt",
  "Only the Main Account can manage the vault":
    "Alleen het Hoofdaccount kan de kluis beheren",
  "Allocate Collateral": "Zekerheden toewijzen",
  "Switch to Main Account": "Overschakelen naar Hoofdrekening",
  "Switch to Account": "Overschakelen naar Account",
  "You are currently trading in the {{market}} market.":
    "U handelt momenteel op de {{market}}-markt.",
  "To start trading on Isolated Market you must Allocate Collateral from your Main Account and switch to this Market Account.":
    "Om te beginnen met handelen op de Geïsoleerde Markt, moet u Zekerheden toewijzen vanuit uw Hoofdrekening en overschakelen naar deze Marktrekening.",
  "To trade in this market, switch to the Main Account first.":
    "Om op deze markt te handelen, schakelt u eerst over naar de Hoofdrekening.",
  "This account can only trade in the {{market}} market.":
    "Dit account kan alleen handelen op de {{market}}-markt.",
  "Isolated Market": "Geïsoleerde Markt",
  "This feature currently is still in Beta. Backup your Paradex Private Key for additional safety.":
    "Deze functie is momenteel nog in de bètafase. Maak een back-up van uw Paradex-private key voor extra veiligheid.",
  "Deposit failed": "Storting mislukt",
  "Deposit in progress": "Storting bezig",
  "External Account": "Extern account",
  "Tx Hashes": "Transactiehashes",
  "Continue to {{bridge}}": "Doorgaan naar {{bridge}}",
  "By clicking Continue to {{bridge}}, you will be sent to {{bridge}} website to bridge your funds out of Paradex.":
    "Door op Doorgaan naar {{bridge}} te klikken, wordt u naar de {{bridge}}-website gestuurd om uw fondsen van Paradex over te zetten.",
  "By clicking Continue to {{bridge}}, you will be sent to {{bridge}} website to bridge your funds to Paradex.":
    "Door op Doorgaan naar {{bridge}} te klikken, wordt u naar de {{bridge}}-website gestuurd om uw fondsen naar Paradex over te zetten.",
  "Refer": "Verwijzen",
  "Address": "Adres",
  "Date Joined": "Datum Toegetreden",
  "Referral Code": "Verwijzingscode",
  "Volume Traded": "Verhandeld Volume",
  "Bridge": "Brug",
  "Withdraw to": "Opnemen naar",
  "Deposit from": "Storten vanaf",
  "Position is already closed": "Positie is al gesloten",
  "Your Paradex L2 Address is NOT a Public Starknet Address. If you try to send funds to this address on Public Starknet via a cross chain bridge you risk losing your funds. <1>Read more</1>.":
    "Uw Paradex L2-adres is GEEN openbaar Starknet-adres. Als u probeert geld naar dit adres te sturen op Openbaar Starknet via een cross-chain-brug, loopt u het risico uw geld te verliezen. <1>Lees meer</1>.",
  "{{chain}} Address": "{{chain}} Adres",
  "Copy Paradex Private Key": "Kopieer Paradex Privésleutel",
  "Your account will be liquidated if Margin Ratio reaches 100%":
    "Uw account wordt geliquideerd als de Margeverhouding 100% bereikt",
  "Liquidation": "Liquidatie",
  "Fee": "Kosten",
  "Maker": "Maker",
  "Maker Score": "Maker Score",
  "Maker XP Share": "Maker XP Aandeel",
  "Fee XP Share": "Kosten XP Aandeel",
  "Fee XP": "Kosten XP",
  "Maker XP": "Maker XP",
  "Liquidation {{side}} Fill": "Liquidatie {{side}} Vulling",
  "Settings": "Instellingen",
  "Market Score": "Marktscore",
  "Season {{season}} (coming soon)": "Seizoen {{season}} (binnenkort)",
  "Confirm Buy": "Bevestig Koop",
  "Confirm Sell": "Bevestig Verkoop",
  "Other XP": "Andere XP",
  "Estimated Receivable Amount": "Geschat Ontvangbaar Bedrag",
  "Socialized Loss active. Click to learn more.":
    "Gesocialiseerd Verlies actief. Klik om meer te leren.",
  "Analytics": "Analyse",
  "Sign In to see your account XP stats":
    "Log in om uw account XP statistieken te zien",
  "<1>Clock Is Out Of Sync</1> The clock on your device is out of sync. This may cause issues with the application. Please make sure your device clock is set to be auto. For more details please <2>see documentation</2>":
    "<1>Klok Is Niet Gesynchroniseerd</1> De klok op uw apparaat is niet gesynchroniseerd. Dit kan problemen veroorzaken met de applicatie. Zorg ervoor dat de klok van uw apparaat is ingesteld op automatisch. Voor meer details zie de <2>documentatie</2>",
  "Failed to fetch max withdrawable amount":
    "Kan het maximale opneembare bedrag niet ophalen",
  "It is not possible to withdraw more than your Max Withdrawable Amount.":
    "Het is niet mogelijk om meer op te nemen dan uw maximale opneembare bedrag.",
  "Max Withdrawable Amount": "Maximaal opneembare bedrag",
  "XP Explainer": "XP Uitlegger",
  "Pool": "Niveau",
  "Quote Quality": "Koerskwaliteit",
  "Create Your Public Username": "Maak uw openbare gebruikersnaam",
  "Edit Your Public Username": "Bewerk uw openbare gebruikersnaam",
  "You have been logged out.": "U bent uitgelogd.",
  "Maker Volume Score": "Maker Volume Score",
  "Fee Score": "Kosten Score",
  "Score Share": "Score Aandeel",
  "Instrument Share": "Instrument Aandeel",
  "XP": "XP",
  "Total XP": "Totale XP",
  "24h Change": "24u Verandering",
  "Leaderboard": "Ranglijst",
  "Cancel": "Annuleren",
  "Portfolio": "Portefeuille",
  "Trade": "Handel",
  "Build": "Bouwen",
  "Referrals Explainer": "Verwijzingen Uitlegger",
  "How It Works": "Hoe het werkt",
  "My Referrals": "Mijn Verwijzingen",
  "Your Referral Code": "Uw verwijzingscode",
  "Affiliate Program": "Partnerprogramma",
  "Enrolled": "Ingeschreven",
  "Traders Referred": "Verwezen handelaren",
  "Referral Rewards": "Verwijzingsbeloningen",
  "Referral XP": "Verwijzing XP",
  "Invite Friends": "Nodig vrienden uit",
  "Share": "Delen",
  "Earn": "Verdienen",
  "Give": "Geven",
  "Share your code to invite friends to join Paradex":
    "Deel uw code om vrienden uit te nodigen om zich bij Paradex aan te sluiten",
  "<1>{{amount}}</1> Fees": "<1>{{amount}}</1> Kosten",
  "You receive {{amount}} of the referred user's fees":
    "U ontvangt {{amount}} van de kosten van de doorverwezen gebruiker",
  "<1>{{amount}}</1> XP": "<1>{{amount}}</1> XP",
  "You receive {{amount}} of the referred user's XP":
    "U ontvangt {{amount}} van de XP van de doorverwezen gebruiker",
  "<1>{{amount}}</1> Discount": "<1>{{amount}}</1> Korting",
  "Your friend receives {{amount}} discount on fees":
    "Uw vriend ontvangt {{amount}} korting op de kosten",
  "Create and share your custom referral code":
    "Maak en deel uw aangepaste verwijzingscode",
  "If you have a strong social media following (<1>{{followers}}+</1>), you may be eligible for our <2>VIP Affiliate Program</2> with higher commissions and XP.":
    "Als u een sterke sociale media-volger (<1>{{followers}}+</1>) hebt, kunt u mogelijk deelnemen aan onze <2>VIP Partnerprogramma</2> met hogere commissies en XP.",
  "You can track our <1>Affiliate Leaderboard</1> to see how you stack up.":
    "U kunt onze <1>Partnerlijst</1> volgen om te zien hoe u zich verhoudt.",
  "Apply": "Toepassen",
  "Log in to see your Referral History.":
    "Log in om uw verwijzingsgeschiedenis te zien.",
  "Welcome to Paradex": "Welkom bij Paradex",
  "Congrats! The referral code you used is now active! It means you can start enjoying a discount on your trading fees!":
    "Gefeliciteerd! De door u gebruikte verwijzingscode is nu actief! Dit betekent dat u kunt beginnen met het genieten van een korting op uw handelskosten!",
  "Congrats! The referral code <1>{{codeProvided}}</1> used is now active!":
    "Gefeliciteerd! De door u gebruikte verwijzingscode <1>{{codeProvided}}</1> is nu actief!",
  "Referral code not activated. The code can only be applied once during the initial onboarding.":
    "Verwijzingscode niet geactiveerd. De code kan slechts één keer worden toegepast tijdens de initiële onboarding.",
  "Referral code <1>{{codeProvided}}</1> not activated. You have already onboarded with <1>{{referredBy}}</1>.":
    "Verwijzingscode <1>{{codeProvided}}</1> niet geactiveerd. U heeft zich al aangemeld bij <1>{{referredBy}}</1>.",
  "Referral Code Applied": "Verwijzingscode Toegepast",
  "You will receive <1>{{userAmount}}</1> of referred user's <1>fees</1> <2>and</2> <1>{{userAmountPoints}} XP</1>. They will receive a <1>{{friendAmount}}</1> discount.":
    "U ontvangt <1>{{userAmount}}</1> van de kosten van de doorverwezen gebruiker <1>en</1> <1>{{userAmountPoints}} XP</1>. Zij ontvangen een korting van <1>{{friendAmount}}</1>.",
  "Community": "Gemeenschap",
  "Environments": "Omgevingen",
  "Language": "Taal",
  "System Status": "Systeemstatus",
  "Docs": "Documenten",
  "Code Samples": "Codevoorbeelden",
  "More": "Meer",
  "Stats": "Statistieken",
  "Join Discord": "Discord Aansluiten",
  "Notifications": "Meldingen",
  "All caught up!": "Helemaal bij!",
  "You have no new notifications": "Je hebt geen nieuwe meldingen",
  "Connect Wallet": "Verbind Wallet",
  "WalletConnect is taking too long to open. Please refresh the page and try again.":
    "WalletConnect duurt te lang om te openen. Vernieuw de pagina en probeer het opnieuw.",
  "Unexpected error opening WalletConnect modal. Please refresh the page and try again.":
    "Onverwachte fout bij het openen van het WalletConnect-modal. Vernieuw de pagina en probeer het opnieuw.",
  "Connect your Wallet": "Verbind je Wallet",
  "Link Wallet": "Link Wallet",
  "You will receive a signature request. Signing is free and will not send a transaction.":
    "Je ontvangt een handtekeningverzoek. Ondertekenen is gratis en zal geen transactie verzenden.",
  "Generate Paradex Chain wallet": "Paradex Chain-portemonnee genereren",
  "Generating Paradex Chain wallet":
    "Paradex Chain-portemonnee wordt gegenereerd",
  "Paradex Chain wallet generated": "Paradex Chain-portemonnee gegenereerd",
  "Verify that you own this wallet": "Verifiëren dat u deze portemonnee bezit",
  "Remember Me": "Onthoud Mij",
  "Only use 'Remember Me' if you are using a secure device you own. Selecting this option can expose your information to  others if you are accessing this wallet from a public device.":
    'Gebruik "Onthoud Mij" alleen als je een beveiligd apparaat gebruikt dat je bezit. Het selecteren van deze optie kan je informatie blootstellen aan anderen als je deze wallet vanaf een openbaar apparaat benadert.',
  "Continue": "Doorgaan",
  "Disconnect Wallet": "Wallet Ontkoppelen",
  "Disconnect": "Ontkoppelen",
  "Account": "Account",
  "Username": "Gebruikersnaam",
  "Edit": "Bewerken",
  "Your username is now hidden. It is not visible to other users.":
    "Uw gebruikersnaam is nu verborgen. Het is niet zichtbaar voor andere gebruikers.",
  "Your username will be visible to other users unless you choose to hide it. You can always change it later.":
    "Uw gebruikersnaam zal zichtbaar zijn voor andere gebruikers, tenzij u ervoor kiest om deze te verbergen. U kunt het altijd later wijzigen.",
  "Hide my Username": "Verberg mijn gebruikersnaam",
  "Save": "Opslaan",
  "Username is required": "Gebruikersnaam is vereist",
  "Username must be between 5 and 20 characters":
    "Gebruikersnaam moet tussen de 5 en 20 tekens bevatten",
  "Username can only contain letters and numbers":
    "Gebruikersnaam mag alleen letters en cijfers bevatten",
  "Wallets": "Wallets",
  "Paradex Block Explorer": "Paradex Block Explorer",
  "Log out": "Uitloggen",
  "No markets available": "Geen markten beschikbaar",
  "Last Price": "Laatste prijs",
  "Open Interest": "Open Interest",
  "24h Volume": "24u Volume",
  "Mark Price": "Marktprijs",
  "Spot Price": "Onderliggende Prijs",
  "Eight hour funding rate that is continuously accrued on positions and settled upon any trade. If the funding rate is positive, longs pay shorts. If negative, shorts pay longs.":
    "Acht uur financieringstarief dat continu wordt opgebouwd op posities en wordt afgerekend bij elke transactie. Als het financieringstarief positief is, betalen longs shorts. Als het negatief is, betalen shorts longs.",
  "8h Funding": "8u Financiering",
  "Limit": "Limiet",
  "Market": "Markt",
  "Stop Limit": "Stop Limiet",
  "Stop Market": "Stop Markt",
  "Scaled Order": "Geschaalde Order",
  "Trigger Price": "Trigger Prijs",
  "Input Price": "Invoerprijs",
  "Position Mark Price": "Positie Mark Prijs",
  "Amount": "Hoeveelheid",
  "Reduce Only": "Alleen Verminderen",
  "Reduce Only will increase position": "Reduce Only zal de positie vergroten",
  "Your order stays open until it is filled or you decide to cancel.":
    "Uw order blijft open tot deze is ingevuld of u besluit te annuleren.",
  "Your order gets filled right away, partially or fully, and whatever is left gets canceled instantly.":
    "Uw order wordt direct ingevuld, gedeeltelijk of volledig, en wat overblijft wordt direct geannuleerd.",
  "Conditional limit order that serves to add liquidity to the order book as a Maker order.":
    "Voorwaardelijke limietorder die dient om liquiditeit toe te voegen aan het orderboek als een Maker-order.",
  "Currently the fees are fixed and uniform across the platform. Maker {{makerFee}} / Taker {{takerFee}}":
    "Momenteel zijn de kosten vast en uniform over het platform. Maker {{makerFee}} / Taker {{takerFee}}",
  "Value": "Waarde",
  "Buy": "Kopen",
  "BUY": "KOOP",
  "LONG": "LANG",
  "Sell": "Verkopen",
  "SELL": "VERKOOP",
  "SHORT": "KORT",
  "Current Position": "Huidige positie",
  "Order Value": "Orderwaarde",
  "Account Value": "Accountwaarde",
  "The total value of your account at current mark prices":
    "De totale waarde van uw account tegen de huidige marktprijzen",
  "Unrealized P&L": "Onverwezenlijkte Winst & Verlies",
  "Free Collateral": "Vrij Onderpand",
  "Account Leverage": "Account Hefboom",
  "Margin": "Marge",
  "Margin Ratio": "Margeverhouding",
  "Initial Margin": "Initiële Marge",
  "Maintenance Margin": "Onderhoudsmarge",
  "Order Book": "Orderboek",
  "Trades": "Trades",
  "Price": "Prijs",
  "Size": "Grootte",
  "Total": "Totaal",
  "Time": "Tijd",
  "Spread": "Spread",
  "Positions": "Posities",
  "Open Orders": "Open Orders",
  "Trade History": "Handelsgeschiedenis",
  "Order History": "Ordergeschiedenis",
  "Funding Payments": "Financieringsbetalingen",
  "Side": "Kant",
  "Entry Price": "Instapprijs",
  "Liquidation Price": "Liquidatieprijs",
  "Unrealized Funding": "Onverwezenlijkte Financiering",
  "Close Position": "Positie Sluiten",
  "Market Close": "Marktsluiting",
  "Close Long": "Long sluiten",
  "Close Short": "Short sluiten",
  "Type": "Type",
  "Filled": "Gevuld",
  "Triggered": "Getriggerd",
  "Instruction": "Instructie",
  "Client Order Id": "Client Order Id",
  "Status": "Status",
  "NEW": "NIEUW",
  "OPEN": "OPEN",
  "UNTRIGGERED": "Niet geactiveerd",
  "CLOSED": "GESLOTEN",
  "Pending": "In afwachting",
  "Complete": "Voltooid",
  "Completed": "Voltooid",
  "Failed": "Mislukt",
  "Bust": "Failliet",
  "Value / Fee": "Waarde / Vergoeding",
  "Liquidity": "Liquiditeit",
  "Tx Hash": "Tx Hash",
  "Trade Id": "Handels-ID",
  "Remaining": "Resterend",
  "Cancel Reason": "Annuleer Reden",
  "User Canceled": "Gebruiker Geannuleerd",
  "Not Enough Margin": "Niet Genoeg Marge",
  "Empty Market": "Lege Markt",
  "Post Only": "Alleen plaatsen",
  "Post Only Would Cross": "Alleen plaatsen zou overschrijden",
  "Remaining IOC Cancel": "Resterende IOC Annuleren",
  "Unexpected Failure": "Onverwachte Fout",
  "Deleverage": "Deleverage",
  "In Liquidation": "In Liquidatie",
  "Self Trade": "Zelf Handelen",
  "Asset Unavailable": "Activa Niet Beschikbaar",
  "Asset Expired": "Activa Verlopen",
  "Order Type Invalid": "Order Type Ongeldig",
  "Price Not Available": "Prijs Niet Beschikbaar",
  "Expired": "Verlopen",
  "Exceeds Max Slippage": "Overschrijdt Maximale Prijsafwijking",
  "Timeout": "Time-out",
  "Order Exceeds Position Limit": "Order Overschrijdt Positie Limiet",
  "Order Id": "Order Id",
  "Payment": "Betaling",
  "Cancel All": "Alles Annuleren",
  "{{orderSize}} will be closed at Market price":
    "{{orderSize}} zal worden gesloten tegen de marktprijs",
  "By connecting a wallet, you agree to <1>Paradex Terms of Service</1> and represent and warrant to Paradex that you are not a <2>Restricted Person</2>.":
    "Door een wallet te verbinden, gaat u akkoord met de <1>Paradex Servicevoorwaarden</1> en verklaart en garandeert u aan Paradex dat u geen <2>Beperkte Persoon</2> bent.",
  "Wallet": "Wallet",
  "Deposit_verb": "Storten",
  "Deposit_noun": "Storting",
  "Withdraw": "Opnemen",
  "Withdrawal": "Opname",
  "Transfers": "Overdrachten",
  "Transfer": "Overdracht",
  "Collateral": "Onderpand",
  "Asset": "Activa",
  "Wallet Balance": "Wallet Saldo",
  "Actions": "Acties",
  "Action": "Actie",
  "External Tx Hash": "Externe Tx Hash",
  "Withdraw to Wallet": "Opnemen naar Wallet",
  "Deposit to Paradex": "Storten naar Paradex",
  "You have no balance in your Ethereum wallet. Deposit some {{asset}} and try again.":
    "U heeft geen saldo in uw Ethereum wallet. Stort wat {{asset}} en probeer opnieuw.",
  "It is not possible to deposit more than your current balance.":
    "Het is niet mogelijk om meer te storten dan uw huidige saldo.",
  "Available": "Beschikbaar",
  "Initiate a deposit from your Ethereum wallet to the Paradex bridge. These transactions":
    "Start een storting vanuit uw Ethereum wallet naar de Paradex brug. Deze transacties",
  "Initiate a deposit from your Ethereum wallet to the Paradex bridge. These transactions may take several minutes depending on network conditions. For more information check out our <1>docs</1>.":
    "Start een storting vanuit uw Ethereum wallet naar de Paradex brug. Deze transacties kunnen enkele minuten duren, afhankelijk van de netwerkcondities. Voor meer informatie bekijk onze <1>docs</1>.",
  "read more": "lees meer",
  "Initiate Deposit to Bridge": "Start Storting naar Brug",
  "Enable USDC on Paradex": "Activeer USDC op Paradex",
  "In order to deposit you must allow Paradex access to at least {{amount}}.":
    "Om te storten moet u Paradex toegang geven tot minstens {{amount}}.",
  "Enable USDC": "Activeer USDC",
  "Withdraw initiated": "Opname gestart",
  "Withdrawals are a two-step process. First initiate a withdrawal from Paradex":
    "Opnames zijn een tweestapsproces. Start eerst een opname van Paradex",
  "Withdrawals are a two-step process. First initiate a withdrawal from Paradex to the Paradex Bridge. This first step can take 5-16 hours (10 on average). Second, initiate your withdrawal from the Paradex Bridge to your Ethereum wallet via the Deposits / Withdrawals table. For more information check out our <1>docs</1>.":
    "Opnames zijn een tweestapsproces. Start eerst een opname van Paradex naar de Paradex brug. Deze eerste stap kan 5-16 uur duren (10 gemiddeld). Ten tweede, start uw opname van de Paradex brug naar uw Ethereum wallet via de Stortingen / Opnames tabel. Voor meer informatie bekijk onze <1>docs</1>.",
  "Initiate Withdrawal to Bridge": "Start Opname naar Brug",
  "You have no funds to withdraw. It is not possible to withdraw more than your free collateral.":
    "U heeft geen fondsen om op te nemen. Het is niet mogelijk om meer op te nemen dan uw vrije onderpand.",
  "It is not possible to withdraw more than your free collateral.":
    "Het is niet mogelijk om meer op te nemen dan uw vrije onderpand.",
  "You have no collateral": "U heeft geen onderpand",
  "You have no open positions": "U heeft geen open posities",
  "You have no open orders": "U heeft geen open orders",
  "You have no trades": "U heeft geen trades",
  "You have no orders": "U heeft geen orders",
  "You have no funding payments": "U heeft geen financieringsbetalingen",
  "You have no transfers": "U heeft geen overdrachten",
  "Account in liquidation": "Account in liquidatie",
  "Account is being liquidated. Any open positions will be reduced. Trading now is disabled.":
    "Account wordt geliquideerd. Alle open posities worden verminderd. Handelen is nu uitgeschakeld.",
  "Account liquidated": "Account geliquideerd",
  "Your account was liquidated on {{createdAt}}. Any open positions were reduced.":
    "Uw account is geliquideerd op {{createdAt}}. Alle open posities zijn verminderd.",
  "Deposit to Paradex successful": "Storting naar Paradex succesvol",
  "Deposit to Paradex failed": "Storting naar Paradex mislukt",
  "Unexpected error. Try again.": "Onverwachte fout. Probeer het opnieuw.",
  "Deposit initiated": "Storting gestart",
  "Deposit to bridge initiated": "Storting naar brug geïnitieerd",
  "Deposit to Paradex available": "Storting naar Paradex beschikbaar",
  "Go to Deposits": "Ga naar Stortingen",
  "Deposit to Paradex in progress": "Storting naar Paradex in uitvoering",
  "Transfer successful": "Overdracht geslaagd",
  "Limit {{side}} Order Fill": "Limiet {{side}} Order Vulling",
  "{{type}} {{side}} Order Submitted": "{{type}} {{side}} Order Ingediend",
  "{{type}} {{side}} Order Canceled": "{{type}} {{side}} Order Geannuleerd",
  "Reason": "Reden",
  "Market {{side}} Order Fill": "Markt {{side}} Order Vulling",
  "Trade Busted On-Chain": "Trade Gebroken Op-Keten",
  "Fill id": "Vul id",
  "Withdraw to wallet failed": "Opname naar wallet mislukt",
  "Withdrawal to bridge initiated": "Opname naar brug geïnitieerd",
  "Withdrawal error. Try again.": "Opnamefout. Probeer het opnieuw.",
  "Withdrawal to bridge in progress": "Opname naar brug in uitvoering",
  "Withdrawal to wallet initiated": "Opname naar wallet geïnitieerd",
  "Withdrawal to bridge failed": "Opname naar brug mislukt",
  "Transaction error. Try again.": "Transactiefout. Probeer het opnieuw.",
  "Withdrawal to wallet available": "Opname naar wallet beschikbaar",
  "Go to Withdrawals": "Ga naar Opnames",
  "Withdrawal to wallet successful": "Opname naar wallet succesvol",
  "Session expired, please log in again.": "Sessie verlopen, log in opnieuw.",
  "Failed to submit order": "Order indienen mislukt",
  "Your username preferences were updated!":
    "Uw gebruikersnaamvoorkeuren zijn bijgewerkt!",
  "Unexpected error updating username preferences":
    "Onverwachte fout bij het bijwerken van gebruikersnaamvoorkeuren",
  "This username already exists. Please choose a different username and try again.":
    "Deze gebruikersnaam bestaat al. Kies een andere gebruikersnaam en probeer het opnieuw.",
  "Validation Error": "Validatiefout",
  "Binding Error": "Binding Fout",
  "Internal Error": "Interne Fout",
  "Not Found": "Niet Gevonden",
  "Service Unavailable": "Service Niet Beschikbaar",
  "Invalid Request Parameter": "Ongeldige Verzoek Parameter",
  "Order Id Not Found": "Order Id Niet Gevonden",
  "Order Is Closed": "Order Is Gesloten",
  "Order Is Not Open Yet": "Order Is Nog Niet Open",
  "Client Order Id Not Found": "Client Order Id Niet Gevonden",
  "Duplicated Client Id": "Gedupliceerde Client Id",
  "Invalid Price Precision": "Ongeldige Prijs Precisie",
  "Invalid Token": "Ongeldig Token",
  "Invalid Ethereum Address": "Ongeldig Ethereum Adres",
  "Blocked Ethereum Address": "Geblokkeerd Ethereum Adres",
  "Invalid Ethereum Signature": "Ongeldige Ethereum Handtekening",
  "Invalid Starknet Address": "Ongeldig Starknet Adres",
  "Invalid Starknet Signature": "Ongeldige Starknet Handtekening",
  "Starknet Signature Verification Failed":
    "Starknet Handtekening Verificatie Mislukt",
  "Bad Starknet Request": "Slecht Starknet Verzoek",
  "Ethereum Signer Mismatch": "Ethereum Signer Komt Niet Overeen",
  "Ethereum Hash Mismatch": "Ethereum Hash Komt Niet Overeen",
  "Not Onboarded": "Niet Aan Boord",
  "Invalid Timestamp": "Ongeldige Tijdstempel",
  "Invalid Signature Expiration": "Ongeldige Handtekening Vervaldatum",
  "Account Not Found": "Account Niet Gevonden",
  "Invalid Order Signature": "Ongeldige Order Handtekening",
  "Public Key Invalid": "Openbare Sleutel Ongeldig",
  "Unauthorized Ethereum Address": "Ongeautoriseerd Ethereum Adres",
  "Ethereum Address Already Onboarded": "Ethereum Adres Al Aan Boord",
  "Market Not Found": "Markt Niet Gevonden",
  "Allowlist Entry Not Found": "Toegestane Lijst Invoer Niet Gevonden",
  "Username In Use": "Gebruikersnaam In Gebruik",
  "Service access restricted in your region":
    "Service toegang beperkt in uw regio",
  "No Access Allowed": "Geen Toegang Toegestaan",
  "You do not have access to the platform. Please join our Discord to get access.":
    "U heeft geen toegang tot het platform. Gelieve ons Discord te joinen om toegang te krijgen.",
  "{{actualChainName}} Wallet Connected": "Ethereum Wallet Verbonden",
  'Incorrect network selected "{{actualChainName}}". Please change the network to "{{requiredChainName}}" (id={{requiredChainId}}) in your wallet and retry.':
    'Incorrect netwerk geselecteerd "{{actualChainName}}". Gelieve het netwerk te veranderen naar "{{requiredChainName}}" (id={{requiredChainId}}) in uw wallet en probeer opnieuw.',
  "Choose Wallet": "Kies een Portemonnee",
  "Starknet Wallet": "Starknet Wallet",
  "Switch to {{env}}": "Schakel over naar {{env}}",
  "Search": "Zoeken",
  "League": "Competitie",
  "Switch Account": "Schakel Account",
  "Rank": "Rang",
  "24h XP": "24u XP",
  "XP Per Day": "XP Per Dag",
  "Season Volume": "Seizoen Volume",
  "Lifetime Volume": "Levenslang Volume",
  "Pre-Season": "Pre-Seizoen",
  "Earn More XP": "Verdien meer XP",
  "XP Boost": "XP Boost",
  "XP Drop": "XP Drop",
  "Trader": "Handelaar",
  "Total Share": "Totale Aandeel",
  "Maker Share": "Maker Aandeel",
  "Maker Volume Share": "Maker Volume Aandeel",
  "Fee Share": "Kosten Aandeel",
  "Fee Volume Share": "Kosten Volume Aandeel",
  "No data available": "Geen gegevens beschikbaar",
  "Refer & Earn": "Verwijs & Verdien",
  "Hours": "Uren",
  "Minutes": "Minuten",
  "Time/Duration": "Tijd/Duur",
  "Runtime": "Looptijd",
  "Frequency": "Frequentie",
  "Number of Orders": "Aantal bestellingen",
  "Runtime should not be empty": "Looptijd mag niet leeg zijn",
  "Runtime should be less than or equal to 24 hours":
    "Looptijd moet minder dan of gelijk aan 24 uur zijn",
  "Inactive": "Inactief",
  "Avg. Price": "Gemiddelde prijs",
  "The minimum required amount for {{numOrders}} orders is {{totalSize}} {{currency}}.":
    "Het minimaal vereiste bedrag voor {{numOrders}} bestellingen is {{totalSize}} {{currency}}.",
  "Take Profit / Stop Loss: Set take profit and stop loss price levels to automate trade actions. When the Mark Price will reach the price you set, it will send your order to the matching engine.":
    "Take Profit / Stop Loss: Stel winstneming- en stop-loss-prijsniveaus in om handelsacties te automatiseren. Wanneer de Markprijs het door u ingestelde niveau bereikt, wordt uw order naar de matching-engine gestuurd.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount with an estimated profit of <3 />.":
    "Wanneer de Mark Prijs <1 /> bereikt, zal het <2 /> order de geselecteerde hoeveelheid sluiten met een geschatte winst van <3 />.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount with an estimated loss of <3 />.":
    "Wanneer de Mark Prijs <1 /> bereikt, zal het <2 /> order de geselecteerde hoeveelheid sluiten met een geschat verlies van <3 />.",
  "Edit TP/SL For This Position": "Bewerk TP/SL voor deze positie",
  "Take Profit": "Take Profit",
  "Profit": "Winst",
  "Stop Loss": "Stop Loss",
  "Loss": "Verlies",
  "Confirm": "Bevestigen",
  "Take Profit Limit": "Take Profit Limiet",
  "Take Profit Market": "Take Profit Markt",
  "Stop Loss Limit": "Stop Loss Limiet",
  "Stop Loss Market": "Stop Loss Markt",
  "Funding": "Financiering",
  "Unwinding": "Afwikkeling",
  "Cancel TP/SL orders": "TP/SL-orders annuleren",
  "TP/SL orders submitted successfully.": "TP/SL-orders geannuleerd.",
  "Pre-Season + All Seasons": "Vorsaison + Alle Saisons",
  "Season {{season}}": "Saison {{season}}",
  "XP Season {{season}}": "Saison {{season}} XP",
  "Slippage": "Prijsafwijking",
  "Max Slippage": "Maximale prijsafwijking",
  "Max slippage defines the largest acceptable price deviation from the mark price.":
    "Maximale prijsafwijking definieert de grootste aanvaardbare prijsafwijking van de markprijs.",
  "During execution, the order can be canceled if the price exceeds the slippage limit or falls outside the Market <1>Price Band</1>.":
    "Tijdens de uitvoering kan de order worden geannuleerd als de prijs de slippage-limiet overschrijdt of buiten de Markt <1>Prijslimiet</1> valt.",
  "Update Max Slippage": "Maximale prijsafwijking bijwerken",
  "The Maximum Slippage allowed for {{symbol}} is {{price_band_width}}.":
    "De maximale prijsafwijking toegestaan voor {{symbol}} is {{price_band_width}}.",
  "Mark IV": "Mark IV",
  "Delta": "Delta",
  "Failed to load positions": "Laden van posities mislukt",
  "No open positions": "Geen open posities",
  "learn more": "meer informatie",
  "Learn More": "Meer Informatie",
  "Search Markets": "Zoek Markten",
  "Futures": "Futures",
  "Options": "Opties",
  "OI": "Open Interest",
  "My Profile": "Mijn Profiel",
  "Addresses": "Adressen",
  "Chart": "Chart",
  "ROI": "Rendement op investering",
  "Sharpe": "Sharpe-ratio",
  "Max Loss": "Maximaal verlies",
  "Total Volume": "Totaal Volume",
  "My NFTs": "Mijn NFTs",
  "My XP": "Mijn XP",
  "Balances": "Saldi",
  "Other": "Overig",
  "Paradex XP: Pre-Season": "Paradex XP: Vorsaison",
  "Paradex XP: Season {{season}}": "Paradex XP: Saison {{season}}",
  "Season {{season}} XP": "Saison {{season}} XP",
  "Refer your friends to earn 10%": "Verwijs vrienden en verdien 10%",
  "Refer Friends and Earn 10%": "Verwijs vrienden en verdien 10%",
  "Invest in Paradex Vault & Earn 3X Boost":
    "Investeer in Paradex Vault & Verdien 3X Boost",
  "Avg. Daily XP": "Gemiddelde Dagelijkse XP",
  "Lifetime XP": "Levenslang XP",
  "My Rank": "Mijn Rang",
  "What is XP?": "Wat is XP?",
  "XP History": "XP Geschiedenis",
  "Last Week XP": "Laatste Week XP",
  "TVL XP": "TVL XP",
  "OI XP": "OI XP",
  "Liquidation XP": "Liquidatie XP",
  "Vaults XP": "Kluizen XP",
  "Week": "Week",
  "Season": "Seizoen",
  "Last updated: {{timestamp}}": "Laatst bijgewerkt: {{timestamp}}",
  "Xperience Points (XP)": "Ervaringspunten (XP)",
  "Log in to see your XP balance": "Log in om uw XP-saldo te bekijken",
  "Log in to see where you stand": "Log in om te zien waar u staat",
  "Connect your X account to join Pro League":
    "Verbind uw X-account om aan de Pro League te joinen",
  "Avg. Fill Price": "Gemiddelde uitvoeringsprijs",
  "Spot": "Spot",
  "Derivatives": "Derivaten",
  "P&L Chart": "P&L Chart",
  "Account Value Chart": "Account Value Chart",
  "Funding History": "Financieringsgeschiedenis",
  "Manage Funds": "Beheer Fondsen",
  "Profile": "Profiel",
  "Community XP": "Community XP",
  "XP Share": "XP Share",
  "Next XP Drop in": "Volgende XP Drop over",
  "In Progress": "In Uitvoering",
  "{{field}} must be greater than {{value}}":
    "{{field}} moet groter zijn dan {{value}}",
  "{{field}} must be less than {{value}}":
    "{{field}} moet kleiner zijn dan {{value}}",
  "Enter a valid Take Profit or Stop Loss price":
    "Voer een geldige Take Profit- of Stop Loss-prijs in",
  "Take Profit and Stop Loss prices cannot be the same":
    "Take Profit- en Stop Loss-prijzen mogen niet hetzelfde zijn",
  "Breakeven Price": "Break-Even Prijs",
  "Automatic Withdrawal": "Automatisch Afhalen",
  "Estimated Gas Fee": "Geschatte Gaskosten",
  "Auto Withdrawal": "Automatisch Afhalen",
  "Withdrawing...": "Afhalen in uitvoering...",
  "Paradex Bridge withdrawals can take between 5-16 hours to complete":
    "Paradex Bridge-opnames kunnen tussen 5-16 uur duren om te voltooien",
  "Socialized Loss": "Gesocialiseerd Verlies",
  "Receivable Amount": "Te Ontvangen Bedrag",
  "Auto withdrawing...": "Automatisch afhalen in uitvoering...",
  "Manage Twitter Connection": "Twitter-verbinding beheren",
  "Unlock additional functionality.": "Ontgrendel extra functionaliteit.",
  "Please note this connection can't be used as a login method to Paradex.":
    "Houd er rekening mee dat deze verbinding niet kan worden gebruikt als inlogmethode voor Paradex.",
  "Toast Notifications": "Toast-notificaties",
  "Display toast notifications": "Toast-notificaties weergeven",
  "Average Entry Price": "Gemiddelde Invoerprijs",
  "Display average entry price line on chart":
    "Gemiddelde invoerprijslijn weergeven op grafiek",
  "Display liquidation price line on chart":
    "Liquidatieprijslijn weergeven op grafiek",
  "Display open order lines on chart": "Open orderlijnen weergeven op grafiek",
  "Failed to disconnect Twitter": "Twitter-verbinding verbroken",
  "Failed to authorize Twitter": "Twitter-autorisatie mislukt",
  "Twitter account linked successfully!":
    "Twitter-account verbonden met succes!",
  "Failed to link twitter account": "Twitter-account verbinden mislukt",
  "Fees": "Kosten",
  "Taker pays {{takerFee}} in fees. Maker receives {{makerRebate}} in rebates.":
    "Taker betaalt {{takerFee}} aan kosten. Maker ontvangt {{makerRebate}} aan kortingen.",
  "Manage Discord Connection": "Discord-verbinding beheren",
  "Failed to authorize Discord": "Discord-autorisatie mislukt",
  "Discord account linked successfully!":
    "Discord-account verbonden met succes!",
  "Failed to link Discord account": "Discord-account verbinden mislukt",
  "Failed to disconnect Discord": "Discord-verbinding verbroken",
  "Connect": "Verbinden",
  "Unrealized P&L from current vault token balance. This doesn't account for owner profit share or slippage from positions unwind.":
    "Niet-realiseerde P&L van het huidige vault-token-saldo. Dit telt niet mee voor de winstdeling van de eigenaar of de slippage van de unwind-posities.",
  "Show P&L Amount": "P&L-bedrag weergeven",
  "Receivable amount must be positive":
    "Te ontvangen bedrag moet positief zijn",
  "Wallet Address Change Detected": "Wijziging in Wallet-adres gedetecteerd",
  "You can proceed with connecting your Paradex account to this wallet address or continue using your current connection.":
    "U kunt doorgaan met het koppelen van uw Paradex-account aan dit wallet-adres of uw huidige verbinding blijven gebruiken.",
  "Connect To New Wallet": "Verbind met nieuwe wallet",
  "Keep Using Current Wallet": "Huidige wallet blijven gebruiken",
  "Connect Current Wallet": "Verbind huidige wallet",
  "The account is not connected. Please make sure that <1>{{walletAddress}}</1> is connected and is the active account in your <2>{{walletName}}</2> wallet.":
    "Het account is niet verbonden. Zorg ervoor dat <1>{{walletAddress}}</1> is verbonden en het actieve account is in uw <2>{{walletName}}</2> wallet.",
  "Reconnecting": "Opnieuw verbinden",
  "There is a request in progress. Please proceed in your wallet.":
    "Er is een verzoek in behandeling. Ga verder in uw wallet.",
  "Modify Order": "Order aanpassen",
  "Realized P&L": "Gerealiseerde P&L",
  "Close All Positions": "Alle posities sluiten",
  "Close All": "Alles sluiten",
  "Market Close All Positions": "Markt sluit alle posities",
  "Close {{percent}}% of All Positions": "Sluit {{percent}}% van alle posities",
  "Failed to close all positions": "Het sluiten van alle posities is mislukt",
  "Number of Positions": "Aantal posities",
  "Markets": "Markten",
  "Position Value (Closing)": "Positie Waarde (Sluiting)",
  "{{count}} positions will be rounded to the nearest order increment":
    "{{count}} posities worden afgerond naar het dichtstbijzijnde bestelincrement",
  "You will earn Bonus XP on your referral activity":
    "U verdient XP Bonus voor uw doorverwijzingsactiviteiten",
  "Token Price": "Token Prijs",
  "Vault Token Price Chart": "Vault Token Prijs Grafiek",
  "Market Close Orders Submitted": "Markt sluit alle bestellingen",
  "This profile is already linked to a different Paradex account. Disconnect socials from existing account and try again.":
    "Dit profiel is al gekoppeld aan een ander Paradex-account. Koppel de sociale netwerken van je huidige account los en probeer het opnieuw.",
  "XP Drops every Friday": "XP valt elke vrijdag",
  "No XP Data Available": "Geen XP-gegevens beschikbaar",
  "XP data is available only for the Main Account.":
    "XP-gegevens zijn alleen beschikbaar voor het hoofdaccount.",
  "Please switch to your Main Account to view your XP.":
    "Ga naar uw hoofdaccount om uw XP te bekijken.",
  "Referrals Not Available": "Referrals niet beschikbaar",
  "Referrals are available only for the Main Account.":
    "Referrals zijn alleen beschikbaar voor het hoofdaccount.",
  "Please switch to your Main Account to view your Referrals.":
    "Ga naar uw hoofdaccount om uw Referrals te bekijken.",
  "Deposit initiated via Layerswap": "Storting via Layerswap geïnitieerd",
  "Position History": "Positiegeschiedenis",
  "Avg. Close Price": "Gemiddelde Sluitprijs",
  "Max Size": "Maximale Grootte",
  "Closed Size": "Gesloten Grootte",
  "Opened": "Geopend",
  "You have no positions": "U heeft geen posities",
  "Destination Address": "Doeladres",
  "Withdrawal via Layerswap initiated": "Opname via Layerswap geïnitieerd",
  "Withdrawal via Layerswap failed": "Opname via Layerswap mislukt",
  "Market Share": "Markt Share",
  "Tier": "Tier",
  "Avg Maker Volume": "Gemiddeld Maker Volume",
  "Liquidity Score": "Liquiditeitsscore",
  "Reward Share": "Beloningen Share",
  "Enter amount": "Voer bedrag in",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount at <3 /> with an estimated profit of <4 />.":
    "Wanneer de Mark Prijs <1 /> bereikt, zal een <2 /> order worden geactiveerd om het geselecteerde bedrag te sluiten bij <3 /> met een geschatte winst van <4 />.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount at <3 /> with an estimated loss of <4 />.":
    "Wanneer de Mark Prijs <1 /> bereikt, zal een <2 /> order worden geactiveerd om het geselecteerde bedrag te sluiten bij <3 /> met een geschat verlies van <4 />.",
  "Limit Price": "Limietprijs",
  "Delete": "Verwijderen",
  "Use other bridges from the list above to withdraw funds much faster.":
    "Gebruik andere bridges uit de bovenstaande lijst om sneller geld op te nemen.",
  "Paradex Chain Monitor": "Paradex Chain Monitor",
  "OTM Amount": "OTM Bedrag",
  "Leverage": "Hefboom",
  "Strikes": "Strikes",
  "Strike": "Strike",
  "Change": "Wijziging",
  "Dist. to Spot": "Afst. tot Spot",
  "Bid": "Bied",
  "Mark": "Mark",
  "Ask": "Laat",
  "IV": "IV",
  "Dist %": "Afst %",
  "Call": "Call",
  "Put": "Put",
  "Mark Price / IV": "Mark Prijs / IV",
  "8h F-Funding": "8u F-Funding",
  "No Strikes Available": "Geen Strikes Beschikbaar",
  "Attributes": "Attributen",
  "Why no Expiries?": "Waarom geen Vervaldatums?",
  "{{multiplier}}x Boost": "{{multiplier}}x Boost",
  "Like perpetual futures, perpetual options have no expiration, offering continuous exposure to the underlying asset with built-in downside protection. This gives users the flexibility to hold their position indefinitely and decide the optimal time to close it based on their strategy":
    "Net als perpetual futures hebben perpetual opties geen vervaldatum, waardoor continue blootstelling aan het onderliggende actief wordt geboden met ingebouwde bescherming tegen neerwaarts risico. Dit geeft gebruikers de flexibiliteit om hun positie voor onbepaalde tijd aan te houden en het optimale moment te kiezen om deze te sluiten op basis van hun strategie",
  "Implied Volatility used to calculate the option's Mark Price":
    "Impliciete Volatiliteit gebruikt om de Mark Prijs van de optie te berekenen",
  "Distance between the Strike Price and the underlying Spot Price":
    "Afstand tussen de Strike Prijs en de onderliggende Spot Prijs",
  "Eight hour funding that is continuously accrued on positions and settled upon any trade. If the funding rate is positive, longs pay shorts. If negative, shorts pay longs":
    "Acht uur funding die continu wordt opgebouwd op posities en wordt afgerekend bij elke trade. Als de fundingrate positief is, betalen longs aan shorts. Als deze negatief is, betalen shorts aan longs",
  "The Leverage Ratio (Lambda) measures an option's effective leverage by indicating how much the option's value changes, in percentage terms, for a 1% change in the spot price of the underlying asset. For example, if an option has a Lambda of 50 and the spot price increases by 1%, the option's value would increase by 50%":
    "De Hefboomratio (Lambda) meet de effectieve hefboom van een optie door aan te geven hoeveel de waarde van de optie verandert, in percentages, voor een 1% verandering in de spotprijs van het onderliggende actief. Bijvoorbeeld, als een optie een Lambda van 50 heeft en de spotprijs stijgt met 1%, zou de waarde van de optie met 50% stijgen",
  "Option delta measures how much the price of an option is expected to change for a $1 change in the price of the underlying asset. For example, a delta of 0.5 means the option price will move $0.50 for every $1 move in the underlying":
    "Optie delta meet hoeveel de prijs van een optie naar verwachting zal veranderen voor een $1 verandering in de prijs van het onderliggende actief. Bijvoorbeeld, een delta van 0,5 betekent dat de optieprijs $0,50 zal bewegen voor elke $1 beweging in de onderliggende waarde",
  "Eight hour funding rate of the perpetual future on the same underlying asset":
    "Acht uur fundingrate van de perpetual future op hetzelfde onderliggende actief",
  "Having trouble? Try signing out and connecting again.":
    "Problemen? Probeer uit te loggen en opnieuw te verbinden.",
  "API Reference": "API Referentie",
  "XP Distribution occurs once per week on Friday at ~12 PM UTC":
    "XP Distributie vindt één keer per week plaats op vrijdag om ~12 uur UTC",
  "What are Perpetual Options?": "Wat zijn Perpetual Opties?",
  "Delta represents the increase in option price based on a $1 increase in the underlying spot price.\n\nFor example, a delta of 0.50 means the price of the option is expected to increase by $0.50 for a $1 increase in the underlying spot price.":
    "Delta vertegenwoordigt de stijging in optieprijs gebaseerd op een $1 stijging in de onderliggende spotprijs.\n\nBijvoorbeeld, een delta van 0,50 betekent dat de prijs van de optie naar verwachting met $0,50 zal stijgen voor een $1 stijging in de onderliggende spotprijs.",
  "Leverage(Lambda) represents the percentage change in option price for a 1% change in the underlying spot price.\n\nFor example, a lambda of 10 means the option price is expected to increase by 10% if the underlying spot price increases by 1%.":
    "Hefboom(Lambda) vertegenwoordigt de procentuele verandering in optieprijs voor een 1% verandering in de onderliggende spotprijs.\n\nBijvoorbeeld, een lambda van 10 betekent dat de optieprijs naar verwachting met 10% zal stijgen als de onderliggende spotprijs met 1% stijgt.",
  "Eight hour options funding rate that is continuously accrued on positions and settled upon any trade. The funding rate for perpetual options reflects both the cost of leverage and the cost of insurance against adverse price moves. \n\nThe funding period of perpetual options is 24 hours. This means that the option premium will be continuously paid out over a 24h period. 24h funding rate = 3 x 8h funding rate":
    "Acht uur opties fundingrate die continu wordt opgebouwd op posities en wordt afgerekend bij elke trade. De fundingrate voor perpetual opties weerspiegelt zowel de kosten van hefboom als de kosten van verzekering tegen ongunstige prijsbewegingen. \n\nDe fundingperiode van perpetual opties is 24 uur. Dit betekent dat de optiepremie continu wordt uitbetaald over een periode van 24 uur. 24u fundingrate = 3 x 8u fundingrate",
  "Risk": "Risico",
  "Vega": "Vega",
  "Gamma": "Gamma",
  "Estimated Funding P&L for the next 8 hours based on current funding rates":
    "Geschatte Funding W&V voor de volgende 8 uur gebaseerd op huidige fundingrates",
  "Vega represents the increase in option price based on a 1% increase in the implied volatility.\n\nFor example, a Vega of 5 means the price of the option is expected to increase by $5 for a 1% increase in the implied volatility.":
    "Vega vertegenwoordigt de stijging in optieprijs gebaseerd op een 1% stijging in de impliciete volatiliteit.\n\nBijvoorbeeld, een Vega van 5 betekent dat de prijs van de optie naar verwachting met $5 zal stijgen voor een 1% stijging in de impliciete volatiliteit.",
  "Adjust Leverage": "Hefboom Aanpassen",
  "Leverage updated successfully": "Hefboom succesvol bijgewerkt",
  "Failed to update leverage": "Hefboom bijwerken mislukt",
  "Adjust leverage parameters for selected market. Leverage will also increase on your open positions.":
    "Pas hefboomparameters aan voor geselecteerde markt. Hefboom zal ook toenemen op uw open posities.",
  "Please note that changing leverage will also apply for open positions and open orders on this market.":
    "Let op dat het wijzigen van hefboom ook van toepassing zal zijn op open posities en open orders in deze markt.",
  "Selecting higher leverage could result in Deleveraging where some of your open orders would be cancelled.":
    "Het selecteren van een hogere hefboom kan resulteren in Deleveraging waarbij sommige van uw open orders worden geannuleerd.",
  "disabled": "uitgeschakeld",
  "Margin Mode": "Margin Modus",
  "Manage your risk on individual positions by restricting the amount of margin allocated to each. If the margin ratio of an isolated position reaches 100%, the position will be liquidated. Margin can be added or removed to individual positions in this mode.":
    "Beheer uw risico op individuele posities door de hoeveelheid margin te beperken die aan elk wordt toegewezen. Als de margin ratio van een geïsoleerde positie 100% bereikt, wordt de positie geliquideerd. Margin kan worden toegevoegd of verwijderd van individuele posities in deze modus.",
  "Margin mode updated successfully": "Margin modus succesvol bijgewerkt",
  "Failed to update margin mode": "Margin modus bijwerken mislukt",
  "Favorites": "Favorieten",
  "Trending": "Trending",
  "Losers": "Verliezers",
  "Gainers": "Winnaars",
  "New Listing": "Nieuwe notering",
  "Developers": "Ontwikkelaars",
  "Developer Mode": "Ontwikkelaar Modus",
  "Enable developer mode": "Ontwikkelaar Modus activeren",
  "For external use": "Voor externe gebruik",
  "Warning: Changing transfer behavior":
    "Waarschuwing: Het gedrag van de overdracht wijzigen",
  "USDC will be transferred to account contract instead of Paradex. Amount won't be visible or available for use within Paradex, but can be used with external applications.":
    "USDC wordt naar het accountcontract overgebracht in plaats van Paradex. Het bedrag wordt niet zichtbaar of beschikbaar voor gebruik binnen Paradex, maar kan worden gebruikt met externe toepassingen.",
  "Affiliate Dashboard": "Affiliate Dashboard",
};

export default translations;
