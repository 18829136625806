import { datadogRum } from '@datadog/browser-rum';

import { logEvent } from '#/features/logging/logging';
import {
  STORAGE_KEY_SESSION_EXPIRES_AT,
  STORAGE_KEY_SESSION_STARTED_AT,
} from '#/features/storage/storage';

import { getStorageItem } from '#/utils/localStorage';

export type SignOutReason =
  | 'ws_keep_alive_unauthorized'
  | 'user_clicked_sign_out'
  | 'user_clicked_disconnect'
  | 'address_changed'
  | 'chain_changed'
  | 'auth_recovery_failed'
  | 'auth_recovery_connect_wallet_failed'
  | 'auth_recovery_address_changed'
  | 'auth_recovery_initialize_wallet_failed'
  | 'jwt_token_recovery_failed';

export function trackUserSignOut(
  reason: SignOutReason,
  logoutType: 'token-invalid' | 'full-logout' = 'full-logout',
) {
  const message = 'User signed out';
  const expiresAt = getStorageItem(STORAGE_KEY_SESSION_EXPIRES_AT);
  const startedAt = getStorageItem(STORAGE_KEY_SESSION_STARTED_AT);
  const timestamp = Date.now();
  const duration =
    startedAt != null ? timestamp - Number(startedAt) : undefined;
  const sessionExpiresAt =
    expiresAt != null && expiresAt !== '' && !Number.isNaN(Number(expiresAt))
      ? Number(expiresAt)
      : undefined;
  const details = {
    reason,
    logoutType,
    duration,
    timestamp,
    sessionExpiresAt,
  };
  logEvent(message, details);
  datadogRum.addAction(message, details);
}

type SignInStep =
  | 'click connect'
  | 'started'
  | 'success'
  | 'failed'
  | 'onboard modal'
  | 'wallets modal'
  | 'click onboard'
  | 'select wallet'
  | 'email or social modal';

export function trackUserSignInStep(step: SignInStep) {
  const message = `User sign in: ${step}`;
  const timestamp = Date.now();
  logEvent(message);
  datadogRum.addAction(message, { timestamp });
}

type AuthRecoveryStep =
  | 'started'
  | 'success'
  | 'failed'
  | 'click recover session';

export function trackAuthRecoveryStep(step: AuthRecoveryStep) {
  const message = `Auth recovery: ${step}`;
  const timestamp = Date.now();
  logEvent(message);
  datadogRum.addAction(message, { timestamp });
}

type AccountSwitchStep = 'started' | 'success' | 'failed';

export function trackAccountSwitchStep(step: AccountSwitchStep) {
  const message = `Account switch: ${step}`;
  const timestamp = Date.now();
  logEvent(message);
  datadogRum.addAction(message, { timestamp });
}
