// a hook that runs check of closed positions on isolated margin accounts
import{useEffect,useRef}from'react';import toast from"../../../../components/common/toast";import{useToDoTranslation}from"../../../localization/utilsToDo";import{logEvent,logException}from"../../../logging/logging";import{positionId,useOpenPositionsStore}from"../../../perpetuals/positions/open-positions-store-context";import{useOpenOrdersStore}from"../../../perpetuals/trade-overview/open-orders-context";import{getLastMarginRetrievalCheckStorageKey}from"../../../storage/storage";import{useSubAccountsStore}from"../../../sub-accounts/context";import{useSystemConfig}from"../../../system/system-config-context";import{executeInternalTransferTransaction}from"../../../transfer/execute-transfer-transaction";import{useParaclearProvider}from"../../../wallets/paraclear/provider";import{getStorageItem,setStorageItem}from"../../../../utils/localStorage";import{useAccountsStoreView}from"../../account-context";const TWO_MINUTE_MS=2*60*1000;const MIN_FREE_COLLATERAL=0.01;// and transfer the margin back to the main account
export const useAutomaticMarginRetrieval=()=>{const todo=useToDoTranslation();const subAccountsStore=useSubAccountsStore();const accountStoreView=useAccountsStoreView();const positionStoreView=useOpenPositionsStore();const systemConfig=useSystemConfig();const paraclearProvider=useParaclearProvider();const openOrdersStore=useOpenOrdersStore();// Store dependencies in a ref to access latest values without triggering effect recreations
const depsRef=useRef({subAccountsStore,accountStoreView,positionStoreView,systemConfig,paraclearProvider,openOrdersStore,todo});// Update ref when dependencies change
useEffect(()=>{depsRef.current={subAccountsStore,accountStoreView,positionStoreView,systemConfig,paraclearProvider,openOrdersStore,todo};},[subAccountsStore,accountStoreView,positionStoreView,systemConfig,paraclearProvider,openOrdersStore,todo]);useEffect(()=>{const checkAndTransferMargin=()=>{const{subAccountsStore:subAccountsStoreRef,accountStoreView:accountStoreViewRef,positionStoreView:positionStoreViewRef,systemConfig:systemConfigRef,paraclearProvider:paraclearProviderRef,openOrdersStore:openOrdersStoreRef,todo:todoRef}=depsRef.current;const now=Date.now();const lastCheck=getStorageItem(getLastMarginRetrievalCheckStorageKey());if(lastCheck!=null){const lastCheckTime=parseInt(lastCheck,10);// Only proceed if 1 minute has passed since the last check
if(now-lastCheckTime<TWO_MINUTE_MS){return;}}// Update the last check time
setStorageItem(getLastMarginRetrievalCheckStorageKey(),now.toString());// iterate through subaccounts that have isolated market
// to see if there's a remaining balance
// this to make sure we still check even if market has been delisted
const subAccounts=subAccountsStoreRef.subAccounts.getAll().filter(account=>account.isolated_market!=null&&account.isolated_market!=='');subAccounts.forEach(subAccount=>{const market=subAccount.isolated_market;const account=accountStoreViewRef.get(subAccount.account);if(account==null){return;}const position=positionStoreViewRef.get(positionId(market,subAccount.account));if(accountStoreViewRef.mainAccountAddress==null){return;}const openOrders=openOrdersStoreRef.getAll().filter(order=>order.market===market&&order.account===subAccount.account);// if there's no position but there's free collateral, transfer the margin back to the main account
if(position==null&&openOrders.length===0&&account.free_collateral.gt(MIN_FREE_COLLATERAL)){const contractAddress=systemConfigRef.paraclear.address;const usdcToken=systemConfigRef.getToken('USDC');const recipient=accountStoreViewRef.mainAccountAddress;const amount=account.free_collateral;executeInternalTransferTransaction(amount,systemConfigRef,contractAddress,usdcToken,account.account,recipient,paraclearProviderRef).then(()=>{toast.success(todoRef.t(`Margin has been transferred back to main account`));logEvent('margin_retrieval',{account:account.account,recipient,market,amount:amount.toString()});}).catch(error=>{logException(new Error('margin_retrieval_error'),{account:account.account,recipient,market,amount:account.free_collateral.toString(),error});});}});};// Run check on mount
checkAndTransferMargin();// Then run every minute, but the function will only execute the full check
const intervalId=setInterval(checkAndTransferMargin,TWO_MINUTE_MS);return()=>clearInterval(intervalId);},[]);// Empty dependency array to set up timer only once
};