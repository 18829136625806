import { perEnv } from '#/features/env';
import { FeatureFlags } from '#/features/feature-flags';

import getQueryString from '#/utils/getQueryString';

import type { BridgeOffsite } from '#/features/bridging/types';

export const rhinofi: BridgeOffsite = {
  config: {
    id: 'RHINOFI',
    name: 'rhino.fi',
    icon: 'rhinofi',
    url: "https://app.rhino.fi/bridge",
    integrationKind: 'offsite',
    depositMode: 'two-step',
    supportedChains: perEnv({
      prod: [
        { id: 'STARKNET', externalId: 'STARKNET' },
        { id: 'ARBITRUM', externalId: 'ARBITRUM' },
        { id: 'AVALANCHE', externalId: 'AVALANCHE' },
        { id: 'BASE', externalId: 'BASE' },
        { id: 'BSC', externalId: 'BINANCE' },
        { id: 'ETHEREUM', externalId: 'ETHEREUM' },
        { id: 'OPTIMISM', externalId: 'OPTIMISM' },
        { id: 'POLYGON', externalId: 'MATIC_POS' },
        { id: 'LINEA', externalId: 'LINEA' },
        { id: 'MODE', externalId: 'MODE' },
        { id: 'MANTLE', externalId: 'MANTLE' },
        { id: 'SCROLL', externalId: 'SCROLL' },
        { id: 'ZKSYNC', externalId: 'ZKSYNC' },
        { id: 'MANTA', externalId: 'MANTA' },
      ] as const,
      testnet: [{ id: 'ETHEREUM', externalId: 'ETHEREUM' }] as const,
      local: [],
      fallback: [],
    }),
    supportedAccountTypes: ['ethereum', 'starknet'],
    supportedWalletKinds: ['ethereum-external', 'starknet-external'],
    enabled: FeatureFlags.getOverridableBooleanValue('rhinofi-enabled', false),
  } as const,

  buildDepositUrl: (props: {
    readonly asset: string;
    readonly sourceChainId: string;
    readonly amount: string;
  }): string => {
    const queryString = getQueryString([
      ['token', props.asset],
      ['chain', props.sourceChainId],
      ['chainOut', "PARADEX"],
      ['amount', props.amount],
    ]);

    return `${"https://app.rhino.fi/bridge"}${queryString}`;
  },

  buildWithdrawUrl: (props: {
    readonly asset: string;
    readonly destinationChainId: string;
    readonly amount: string;
  }): string => {
    const queryString = getQueryString([
      ['token', props.asset],
      ['chain', "PARADEX"],
      ['chainOut', props.destinationChainId],
      ['amount', props.amount],
    ]);

    return `${"https://app.rhino.fi/bridge"}${queryString}`;
  },
};
