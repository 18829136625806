/* eslint-disable no-else-return */
import BigNumber from 'bignumber.js';
import { Maybe } from 'yup';

import { MarketSummary } from '#/api/markets-summary';
import { OpenOrder, OrderSide } from '#/api/orders';
import { OpenPosition } from '#/api/positions';

import { MarketsStoreView } from '#/features/perpetuals/market-info/markets-context';
import { MarketsSummaryStoreView } from '#/features/perpetuals/market-info/markets-summary-context';
import { OpenOrdersStoreView } from '#/features/perpetuals/trade-overview/open-orders-context';

/**
 * Adapted from
 * @see https://github.com/tradeparadigm/mono/blob/80398cf5aca2af0bbb7b581fe99445acc5073cd6/api/paradex/prototype/asset/synthetic_assets/synthetic_asset.py#L180
 */
export function calculate_open_loss(
  market: string,
  account: string,
  assetOpenPosition: Maybe<Pick<OpenPosition, 'market' | 'size' | 'side'>>,
  /** should include all of the 'OPEN', 'NEW', 'UNTRIGGERED' */
  openOrdersStore: OpenOrdersStoreView,
  marketsSummaryStore: MarketsSummaryStoreView,
  marketsStore: MarketsStoreView,
  profileMaxSlippage: BigNumber | null,
) {
  const orders = openOrdersStore.getAll();
  const ordersForMarket = orders.filter(
    (order) => order.market === market && order.account === account,
  );
  return BigNumber.max(
    calculate_open_loss_by_side(
      'BUY',
      assetOpenPosition,
      ordersForMarket,
      marketsSummaryStore,
      marketsStore,
      profileMaxSlippage,
    ),
    calculate_open_loss_by_side(
      'SELL',
      assetOpenPosition,
      ordersForMarket,
      marketsSummaryStore,
      marketsStore,
      profileMaxSlippage,
    ),
  );
}

/**
 * Adapted from
 * @see https://github.com/tradeparadigm/mono/blob/release/paradex-1.29.3/api/paradex/prototype/asset/synthetic_assets/synthetic_asset.py#L172-L178
 */
function calculate_open_loss_by_side(
  side: OrderSide,
  assetOpenPosition: Maybe<Pick<OpenPosition, 'market' | 'size' | 'side'>>,
  /** should include all of the 'OPEN', 'NEW', 'UNTRIGGERED' */
  orders: OpenOrder[],
  marketsSummaryStore: MarketsSummaryStoreView,
  marketsStore: MarketsStoreView,
  profileMaxSlippage: BigNumber | null,
): BigNumber {
  // Get the orders for the given side
  const oneSideOrders = orders.filter((order) => order.side === side);

  // calculate open loss of non-reduce-only orders
  const lossBySide = oneSideOrders
    .filter((order) => !order.flags.includes('REDUCE_ONLY'))
    .reduce(
      (sum, order) =>
        sum.plus(
          order_open_loss(
            order,
            marketsSummaryStore,
            marketsStore,
            profileMaxSlippage,
          ),
        ),
      BigNumber(0),
    );

  const isOppositeSide =
    assetOpenPosition != null &&
    ((side === 'SELL' && assetOpenPosition.side === 'LONG') ||
      (side === 'BUY' && assetOpenPosition.side === 'SHORT'));

  // calculate capped open loss of reduce only orders on the offsetting side
  if (isOppositeSide) {
    const reduceOnlyOrders = oneSideOrders.filter((order) =>
      order.flags.includes('REDUCE_ONLY'),
    );

    // Calculate and sort losses in descending order
    const sortedLosses = reduceOnlyOrders
      .map((order) => ({
        loss: order_open_loss(
          order,
          marketsSummaryStore,
          marketsStore,
          profileMaxSlippage,
        ),
        size: order.remaining_size,
      }))
      .sort((a, b) => b.loss.minus(a.loss).toNumber());

    // Sum losses up to position size
    let remainingPositionSize = assetOpenPosition.size;
    const reduceOnlyLoss = sortedLosses.reduce((sum, { loss, size }) => {
      // size cannot be 0 because it's used as a denominator
      if (remainingPositionSize.lte(0) || size.lte(0)) {
        return sum;
      }
      const sizeToConsider = BigNumber.min(size, remainingPositionSize);
      remainingPositionSize = remainingPositionSize.minus(sizeToConsider);
      return sum.plus(loss.times(sizeToConsider).div(size));
    }, BigNumber(0));

    return lossBySide.plus(reduceOnlyLoss);
  }

  return lossBySide;
}

/**
 * Adapted from
 * @see https://github.com/tradeparadigm/mono/blob/release/paradex-1.29.3/api/paradex/prototype/market/order.py#L178
 */
export function order_open_loss(
  order: OpenOrder,
  marketsSummaryStore: MarketsSummaryStoreView,
  marketsStore: MarketsStoreView,
  profileMaxSlippage: BigNumber | null,
): BigNumber {
  const marketSummary = marketsSummaryStore.getMarketSummary(order.market);
  const { price_bands_width: marketMaxSlippage = BigNumber(1) } =
    marketsStore.getMarket(order.market) ?? {};
  const maxSlippage =
    profileMaxSlippage == null || profileMaxSlippage.isZero()
      ? marketMaxSlippage
      : BigNumber.min(profileMaxSlippage, marketMaxSlippage);

  if (marketSummary == null) {
    throw new Error(
      `order_open_loss: 'marketSummary' not found for market='${order.market}'`,
    );
  }
  if (marketSummary.mark_price == null) {
    throw new Error(
      `order_open_loss: 'mark_price' is null for market='${marketSummary.symbol}'`,
    );
  }

  switch (order.type) {
    case 'MARKET':
      return marketOrderLoss(order, maxSlippage, marketSummary);
    case 'STOP_MARKET':
    case 'STOP_LOSS_MARKET':
    case 'TAKE_PROFIT_MARKET':
      return stopMarketOrderLoss(order, maxSlippage);
    case 'STOP_LIMIT':
    case 'STOP_LOSS_LIMIT':
    case 'TAKE_PROFIT_LIMIT':
      return stopLimitOrderLoss(order);
    case 'LIMIT':
      return limitOrderLoss(order, marketSummary);
    //no default
  }
}

export function stopLimitOrderLoss(
  order: Pick<OpenOrder, 'remaining_size' | 'side' | 'price' | 'trigger_price'>,
) {
  const priceDifference = order.price.minus(order.trigger_price);
  const orderSideSign = order.side === 'BUY' ? BigNumber(1) : BigNumber(-1);

  const loss = order.remaining_size.multipliedBy(
    BigNumber.max(orderSideSign.times(priceDifference), 0),
  );

  return loss;
}

export function stopMarketOrderLoss(
  order: Pick<OpenOrder, 'remaining_size' | 'side' | 'trigger_price'>,
  profileMaxSlippage: BigNumber,
) {
  return profileMaxSlippage
    .times(order.remaining_size)
    .times(order.trigger_price);
}

export function limitOrderLoss(
  order: Pick<OpenOrder, 'remaining_size' | 'side' | 'price'>,
  marketSummary: MarketSummary,
) {
  if (marketSummary.mark_price == null) {
    return BigNumber(0);
  }

  const oraclePrice = marketSummary.mark_price;
  const priceDifference = order.price.minus(oraclePrice);
  const orderSideSign = order.side === 'BUY' ? BigNumber(1) : BigNumber(-1);

  const loss = order.remaining_size.multipliedBy(
    BigNumber.max(orderSideSign.times(priceDifference), 0),
  );

  return loss;
}

export function marketOrderLoss(
  order: Pick<OpenOrder, 'remaining_size' | 'side'>,
  maxSlippage: BigNumber,
  marketSummary: MarketSummary,
) {
  if (marketSummary.mark_price == null) {
    return BigNumber(0);
  }

  return maxSlippage
    .times(order.remaining_size)
    .times(marketSummary.mark_price);
}
