import { Keys } from "./en";

const translations: Record<Keys, string> = {
  "Display trade history on chart": "Tampilkan riwayat transaksi di grafik",
  "Add markets to Favorites in the Markets Browser":
    "Tambah pasar ke Favorit di Pencarian Pasar",
  "Log in": "Masuk",
  "Export embedded wallet": "Ekspor dompet tersemat",
  "Email or Social": "Email atau Sosial",
  "Failed to load balances": "Gagal memuat saldo",
  "No balances": "Tidak ada saldo",
  "Verified Operator": "Operator Verifikasi",
  "Strategy": "Strategi",
  "Multi-Strategy": "Multi-Strategi",
  "Master Vault": "Vault Utama",
  "Master Vault Details": "Detail Vault Utama",
  "Annualized": "Diannualisasi",
  "Depositors": "Penyetor",
  "No trade history": "Tidak ada riwayat transaksi",
  "Unwinding Progress": "Proses Pembubaran",
  "What are Vaults?": "Apa itu Vault?",
  "APR calculated as the Annualised 30-day Return":
    "APR dihitung sebagai pengembalian tahunan 30 hari",
  "APR": "APR",
  "Initializing": "Menginisialisasi",
  "Awaiting Deposit": "Menunggu Deposit",
  "Vault Age": "Usia Vault",
  "30D Return": "Pengembalian 30H",
  "{{field}} must be greater than the minimum initial deposit of {{minInitialDeposit}}":
    "{{field}} harus lebih besar dari deposit awal minimum dari {{minInitialDeposit}}",
  "Minimum deposit": "Jumlah minimum deposit",
  "Withdrawal amount exceeds available vault shares":
    "Jumlah penarikan melebihi bagian yang tersedia dari vault",
  "Deposit would exceed maximum vault TVL of {{maxTVL}}":
    "Deposit akan melebihi TVL maksimum vault dari {{maxTVL}}",
  "{{field}} is required": "{{field}} wajib diisi",
  "Lockup Remaining": "Sisa Penutupan",
  "{{days}}D": "{{days}}H",
  "<1>{{days}}</1> D": "<1>{{days}}</1> H",
  "<1>{{hours}}</1> H": "<1>{{hours}}</1> J",
  "<1>{{minutes}}</1> M": "<1>{{minutes}}</1> M",
  "Vault settings submitted": "Pengaturan Vault dikirim",
  "Vault settings updated": "Pengaturan Vault diperbarui",
  "Vault Settings": "Pengaturan Vault",
  "Vault not found": "Vault tidak ditemukan",
  "The owner receives a {{profitShare}} profit share.":
    "Pemilik menerima {{profitShare}} dari bagian keuntungan.",
  "This vault is entirely owned by the community.":
    "Vault ini seluruhnya dimiliki oleh komunitas.",
  "Withdraw from Vault submitted": "Penarikan dari Vault dikirim",
  "Deposit to Vault submitted": "Deposit ke Vault dikirim",
  "Unknown error. Try again.": "Error tidak diketahui. Coba lagi.",
  "Vault Deposit": "Deposit Vault",
  "Vault Withdrawal": "Penarikan dari Vault",
  "No Lockup": "Tidak Ada Penutupan",
  "Lockup period is not over yet": "Periode penutupan belum selesai",
  "Protocol": "Protokol",
  "24H": "24J",
  "7D": "7H",
  "30D": "30H",
  "All": "Semua",
  "You have no investments in vaults": "Anda tidak memiliki investasi di vault",
  "Total Value": "Total Nilai",
  "Investments Made": "Investasi yang Dilakukan",
  "Operator": "Operator",
  "Manage Vault": "Kelola Vault",
  "Switch to the operator account": "Beralih ke akun operator",
  "Active": "Aktif",
  "My Vaults": "Brankas Saya",
  "Closed": "Ditutup",
  "Deposited": "Disimpan",
  "Lifetime ROI": "ROI Total",
  "Owners Equity": "Ekuitas Pemilik",
  "Top Trending": "Tren Teratas",
  "Owner Share": "Bagian Pemilik",
  "Vault Details": "Detail Vault",
  "Contract": "Kontrak",
  "Withdraw from Vault": "Tarik dari Vault",
  "Withdraw from Vault successful": "Penarikan dari Vault berhasil",
  "Failed to withdraw from Vault": "Gagal menarik dari Vault",
  "Name": "Nama",
  "Deposit": "Deposit",
  "Tokens": "Token",
  "Avg. Entry Price": "Harga Masuk Rata-rata",
  "Current Price": "Harga Saat Ini",
  "P&L": "Laba Rugi",
  "My Vault Deposit": "Deposit Vault Saya",
  "Vaults": "Vault",
  "Create": "Buat",
  "Create My Vault": "Buat Vault Saya",
  "Vault Name": "Nama Vault",
  "Vault Description": "Deskripsi Vault",
  "Token Symbol": "Simbol Token",
  "Deposit Amount": "Jumlah Deposit",
  "Vault description will be visible publicly and you will not be able to edit this later.":
    "Deskripsi vault akan terlihat publik dan Anda tidak akan dapat mengeditnya nanti.",
  "Once created, deposit at least {{minDeposit}} USDC to activate the vault. At this moment, only the Max TVL can be changed later.":
    "Setelah dibuat, deposit minimal {{minDeposit}} USDC untuk mengaktifkan vault. Pada saat ini, hanya TVL maksimum yang dapat diubah nanti.",
  "Create Vault": "Buat Vault",
  "Profit Share": "Pembagian Keuntungan",
  "Lockup Period (Days)": "Periode Kunci (Hari)",
  "Vault was successfully created and is being initialized":
    "Vault berhasil dibuat dan sedang diinisialisasi",
  "Failed to create a vault": "Gagal membuat vault",
  "Maximum TVL": "TVL Maksimum",
  "No limit": "Tanpa batas",
  "Vault Address": "Alamat Vault",
  "Deposit to Vault successful": "Deposit ke Vault berhasil",
  "Failed to deposit to Vault": "Gagal deposit ke Vault",
  "Deposit to Vault": "Deposit ke Vault",
  "Vault": "Vault",
  "Owner": "Pemilik",
  "Age": "Usia",
  "Failed to load vaults": "Gagal memuat vault",
  "No vaults available": "Tidak ada vault tersedia",
  "Performance": "Kinerja",
  "Vault Value": "Nilai Vault",
  "My Deposit": "Deposit Saya",
  "Total P&L": "Total Laba Rugi",
  "Protocol Vaults": "Vault Protokol",
  "User Vaults": "Vault Pengguna",
  "No Data": "Tidak Ada Data",
  "Last Month Return": "Pengembalian Bulan Lalu",
  "TVL": "TVL",
  "Total Earnings": "Total Pendapatan",
  "About": "Tentang",
  "Vault Stats": "Statistik Vault",
  "My Stats": "Statistik Saya",
  "Vault Contract": "Kontrak Vault",
  "Description": "Deskripsi",
  "Max Drawdown": "Drawdown Maksimum",
  "Volume": "Volume",
  "All-Time P&L": "Laba Rugi Sepanjang Masa",
  "Days": "Hari",
  "Lockup Period": "Periode Kunci",
  "Search Vaults": "Cari Vault",
  "Blog": "Blog",
  "Block Explorer": "Penjelajah Blok",
  "Close Vault": "Tutup Vault",
  "Close Vault successful": "Penutupan Vault berhasil",
  "You must close all open positions to close your Vault. Closed vaults cannot be reopened.":
    "Anda harus menutup semua posisi terbuka untuk menutup Vault Anda. Vault yang ditutup tidak dapat dibuka kembali.",
  "Close My Vault": "Tutup Vault Saya",
  "Failed to Close the Vault": "Gagal Menutup Vault",
  "Only the Main Account can manage the vault":
    "Hanya Akun Utama yang dapat mengelola vault",
  "Allocate Collateral": "Mengalokasikan Jaminan",
  "Switch to Main Account": "Beralih ke Akun Utama",
  "Switch to Account": "Beralih ke Akun",
  "You are currently trading in the {{market}} market.":
    "Anda saat ini berdagang di pasar {{market}}.",
  "To start trading on Isolated Market you must Allocate Collateral from your Main Account and switch to this Market Account.":
    "Untuk mulai berdagang di Pasar Terisolasi, Anda harus Mengalokasikan Jaminan dari Akun Utama Anda dan beralih ke Akun Pasar ini.",
  "To trade in this market, switch to the Main Account first.":
    "Untuk berdagang di pasar ini, beralihlah terlebih dahulu ke Akun Utama.",
  "This account can only trade in the {{market}} market.":
    "Akun ini hanya dapat berdagang di pasar {{market}}.",
  "Isolated Market": "Pasar Terisolasi",
  "This feature currently is still in Beta. Backup your Paradex Private Key for additional safety.":
    "Fitur ini saat ini masih dalam tahap Beta. Cadangkan Kunci Pribadi Paradex Anda untuk keamanan tambahan.",
  "Deposit failed": "Deposit gagal",
  "Deposit in progress": "Deposit sedang berlangsung",
  "External Account": "Akun Eksternal",
  "Tx Hashes": "Transaksi Hashes",
  "Continue to {{bridge}}": "Lanjutkan ke {{bridge}}",
  "By clicking Continue to {{bridge}}, you will be sent to {{bridge}} website to bridge your funds out of Paradex.":
    "Dengan mengklik Lanjutkan ke {{bridge}}, Anda akan diarahkan ke situs web {{bridge}} untuk mentransfer dana Anda keluar dari Paradex.",
  "By clicking Continue to {{bridge}}, you will be sent to {{bridge}} website to bridge your funds to Paradex.":
    "Dengan mengklik Lanjutkan ke {{bridge}}, Anda akan diarahkan ke situs web {{bridge}} untuk mentransfer dana Anda ke Paradex.",
  "Refer": "Rujuk",
  "Address": "Alamat",
  "Date Joined": "Tanggal Bergabung",
  "Referral Code": "Kode Referensi",
  "Volume Traded": "Volume Diperdagangkan",
  "Bridge": "Jembatan",
  "Withdraw to": "Tarik ke",
  "Deposit from": "Deposit dari",
  "Position is already closed": "Posisi sudah ditutup",
  "Your Paradex L2 Address is NOT a Public Starknet Address. If you try to send funds to this address on Public Starknet via a cross chain bridge you risk losing your funds. <1>Read more</1>.":
    "Alamat Paradex L2 Anda BUKAN Alamat Starknet Publik. Jika Anda mencoba mengirim dana ke alamat ini di Starknet Publik melalui jembatan lintas rantai, Anda berisiko kehilangan dana Anda. <1>Baca lebih lanjut</1>.",
  "{{chain}} Address": "Alamat {{chain}}",
  "Copy Paradex Private Key": "Salin Kunci Pribadi Paradex",
  "Your account will be liquidated if Margin Ratio reaches 100%":
    "Akun Anda akan dilikuidasi jika Rasio Margin mencapai 100%",
  "Liquidation": "Likuidasi",
  "Fee": "Biaya",
  "Maker": "Pembuat",
  "Maker Score": "Skor Pembuat",
  "Maker XP Share": "Pembagian XP Pembuat",
  "Fee XP Share": "Pembagian XP Biaya",
  "Fee XP": "XP Biaya",
  "Maker XP": "XP Pembuat",
  "Liquidation {{side}} Fill": "Isi Likuidasi {{side}}",
  "Settings": "Pengaturan",
  "Market Score": "Skor Pasar",
  "Season {{season}} (coming soon)": "Musim {{season}} (segera hadir)",
  "Confirm Buy": "Konfirmasi Beli",
  "Confirm Sell": "Konfirmasi Jual",
  "Other XP": "XP Lainnya",
  "Estimated Receivable Amount": "Jumlah yang Diperkirakan Diterima",
  "Socialized Loss active. Click to learn more.":
    "Kerugian Sosialisasi aktif. Klik untuk mempelajari lebih lanjut.",
  "Analytics": "Analitik",
  "Sign In to see your account XP stats":
    "Masuk untuk melihat statistik XP akun Anda",
  "<1>Clock Is Out Of Sync</1> The clock on your device is out of sync. This may cause issues with the application. Please make sure your device clock is set to be auto. For more details please <2>see documentation</2>":
    "Jam di perangkat Anda tidak sinkron. Ini dapat menyebabkan masalah dengan aplikasi. Pastikan jam perangkat Anda diatur ke otomatis. Untuk informasi lebih lanjut, silakan <2>lihat dokumentasi</2>",
  "Failed to fetch max withdrawable amount":
    "Gagal mengambil jumlah penarikan maksimum",
  "It is not possible to withdraw more than your Max Withdrawable Amount.":
    "Tidak dapat menarik lebih dari Jumlah Penarikan Maksimum Anda.",
  "Max Withdrawable Amount": "Jumlah Penarikan Maksimum",
  "XP Explainer": "Penjelas XP",
  "Pool": "Tingkatan",
  "Quote Quality": "Kualitas kutipan",
  "Create Your Public Username": "Buat Nama Pengguna Publik Anda",
  "Edit Your Public Username": "Edit Nama Pengguna Publik Anda",
  "You have been logged out.": "Anda telah keluar.",
  "Maker Volume Score": "Skor Volume Pembuat",
  "Fee Score": "Skor Biaya",
  "Score Share": "Pembagian Skor",
  "Instrument Share": "Pembagian Instrumen",
  "XP": "XP",
  "Total XP": "Total XP",
  "24h Change": "Perubahan 24h",
  "Leaderboard": "Papan peringkat",
  "Cancel": "Batal",
  "Portfolio": "Portofolio",
  "Trade": "Perdagangan",
  "Build": "Membangun",
  "Referrals Explainer": "Penjelas Referensi",
  "How It Works": "Cara Kerjanya",
  "My Referrals": "Referensi Saya",
  "Your Referral Code": "Kode Referensi Anda",
  "Affiliate Program": "Program Afiliasi",
  "Enrolled": "Terdaftar",
  "Traders Referred": "Trader yang Dirujuk",
  "Referral Rewards": "Hadiah Referensi",
  "Referral XP": "XP Referensi",
  "Invite Friends": "Undang Teman",
  "Share": "Bagikan",
  "Earn": "Dapatkan",
  "Give": "Berikan",
  "Share your code to invite friends to join Paradex":
    "Bagikan kode Anda untuk mengundang teman-teman bergabung dengan Paradex",
  "<1>{{amount}}</1> Fees": "<1>{{amount}}</1> Biaya",
  "You receive {{amount}} of the referred user's fees":
    "Anda menerima {{amount}} biaya pengguna yang direkomendasikan",
  "<1>{{amount}}</1> XP": "<1>{{amount}}</1> XP",
  "You receive {{amount}} of the referred user's XP":
    "Anda menerima {{amount}} XP pengguna yang direkomendasikan",
  "<1>{{amount}}</1> Discount": "Diskon <1>{{amount}}</1>",
  "Your friend receives {{amount}} discount on fees":
    "Teman Anda mendapatkan diskon {{amount}} pada biaya",
  "Create and share your custom referral code":
    "Buat dan bagikan kode referensi kustom Anda",
  "If you have a strong social media following (<1>{{followers}}+</1>), you may be eligible for our <2>VIP Affiliate Program</2> with higher commissions and XP.":
    "Jika Anda memiliki pengikut media sosial yang kuat (<1>{{followers}}+</1>), Anda mungkin memenuhi syarat untuk program afiliasi VIP kami dengan komisi dan XP lebih tinggi.",
  "You can track our <1>Affiliate Leaderboard</1> to see how you stack up.":
    "Anda dapat melihat <1>Papan Peringkat Afiliasi</1> kami untuk melihat posisi Anda.",
  "Apply": "Mendaftar",
  "Log in to see your Referral History.":
    "Masuk untuk melihat Riwayat Referensi Anda.",
  "Welcome to Paradex": "Selamat datang di Paradex",
  "Congrats! The referral code you used is now active! It means you can start enjoying a discount on your trading fees!":
    "Selamat! Kode referensi yang Anda gunakan sekarang aktif! Ini berarti Anda dapat mulai menikmati diskon pada biaya perdagangan Anda!",
  "Congrats! The referral code <1>{{codeProvided}}</1> used is now active!":
    "Selamat! Kode referensi <1>{{codeProvided}}</1> yang digunakan sekarang aktif!",
  "Referral code not activated. The code can only be applied once during the initial onboarding.":
    "Kode referensi tidak diaktifkan. Kode hanya dapat diterapkan sekali selama onboarding awal.",
  "Referral code <1>{{codeProvided}}</1> not activated. You have already onboarded with <1>{{referredBy}}</1>.":
    "Kode referensi <1>{{codeProvided}}</1> tidak diaktifkan. Anda sudah onboard dengan <1>{{referredBy}}</1>.",
  "Referral Code Applied": "Kode Referensi Diterapkan",
  "You will receive <1>{{userAmount}}</1> of referred user's <1>fees</1> <2>and</2> <1>{{userAmountPoints}} XP</1>. They will receive a <1>{{friendAmount}}</1> discount.":
    "Anda akan menerima <1>{{userAmount}}</1> biaya pengguna yang direkomendasikan <1>dan</1> <1>{{userAmountPoints}} XP</1>. Mereka akan menerima diskon <1>{{friendAmount}}</1>.",
  "Community": "Komunitas",
  "Environments": "Lingkungan",
  "Language": "Bahasa",
  "System Status": "Status Sistem",
  "Docs": "Dokumen",
  "Code Samples": "Contoh Kode",
  "More": "Lebih",
  "Stats": "Statistik",
  "Join Discord": "Gabung Discord",
  "Notifications": "Notifikasi",
  "All caught up!": "Semua sudah dikejar!",
  "You have no new notifications": "Anda tidak memiliki notifikasi baru",
  "Connect Wallet": "Hubungkan Dompet",
  "WalletConnect is taking too long to open. Please refresh the page and try again.":
    "WalletConnect memerlukan waktu terlalu lama untuk dibuka. Silakan refresh halaman dan coba lagi.",
  "Unexpected error opening WalletConnect modal. Please refresh the page and try again.":
    "Kesalahan tak terduga saat membuka modal WalletConnect. Silakan refresh halaman dan coba lagi.",
  "Connect your Wallet": "Hubungkan Dompet Anda",
  "Link Wallet": "Tautkan Dompet",
  "You will receive a signature request. Signing is free and will not send a transaction.":
    "Anda akan menerima permintaan tanda tangan. Menandatangani gratis dan tidak akan mengirim transaksi.",
  "Generate Paradex Chain wallet": "Buat dompet Paradex Chain",
  "Generating Paradex Chain wallet": "Membuat dompet Paradex Chain",
  "Paradex Chain wallet generated": "Dompet Paradex Chain dibuat",
  "Verify that you own this wallet":
    "Verifikasi bahwa Anda memiliki dompet ini",
  "Remember Me": "Ingat Saya",
  "Only use 'Remember Me' if you are using a secure device you own. Selecting this option can expose your information to  others if you are accessing this wallet from a public device.":
    'Hanya gunakan "Ingat Saya" jika Anda menggunakan perangkat yang aman milik Anda sendiri. Memilih opsi ini dapat membuka informasi Anda kepada orang lain jika Anda mengakses dompet ini dari perangkat publik.',
  "Continue": "Lanjutkan",
  "Disconnect Wallet": "Putuskan Dompet",
  "Disconnect": "Putuskan",
  "Account": "Akun",
  "Username": "Nama pengguna",
  "Edit": "Ubah",
  "Your username is now hidden. It is not visible to other users.":
    "Nama pengguna Anda sekarang disembunyikan. Ini tidak terlihat oleh pengguna lain.",
  "Your username will be visible to other users unless you choose to hide it. You can always change it later.":
    "Nama pengguna Anda akan terlihat oleh pengguna lain kecuali Anda memilih untuk menyembunyikannya. Anda selalu dapat mengubahnya nanti.",
  "Hide my Username": "Sembunyikan Nama Pengguna Saya",
  "Save": "Simpan",
  "Username is required": "Nama pengguna diperlukan",
  "Username must be between 5 and 20 characters":
    "Nama pengguna harus antara 5 dan 20 karakter",
  "Username can only contain letters and numbers":
    "Nama pengguna hanya dapat berisi huruf dan angka",
  "Wallets": "Dompet",
  "Paradex Block Explorer": "Penjelajah Blok Paradex",
  "Log out": "Keluar",
  "No markets available": "Tidak ada pasar yang tersedia",
  "Last Price": "Harga Terakhir",
  "Open Interest": "Minat Terbuka",
  "24h Volume": "Volume 24h",
  "Mark Price": "Harga Mark",
  "Spot Price": "Harga Spot",
  "Eight hour funding rate that is continuously accrued on positions and settled upon any trade. If the funding rate is positive, longs pay shorts. If negative, shorts pay longs.":
    "Biaya pendanaan delapan jam yang terus menerus terakumulasi pada posisi dan diselesaikan pada setiap perdagangan. Jika biaya pendanaan positif, long membayar short. Jika negatif, short membayar long.",
  "8h Funding": "Pendanaan 8 jam",
  "Limit": "Batas",
  "Market": "Pasar",
  "Stop Limit": "Stop Limit",
  "Stop Market": "Stop Pasar",
  "Scaled Order": "Pesanan Terukur",
  "Trigger Price": "Harga Pemicu",
  "Input Price": "Harga Masuk",
  "Position Mark Price": "Harga Mark Posisi",
  "Amount": "Jumlah",
  "Reduce Only": "Hanya Kurang",
  "Reduce Only will increase position": "Reduce Only akan meningkatkan posisi",
  "Your order stays open until it is filled or you decide to cancel.":
    "Pesanan Anda tetap terbuka sampai terisi atau Anda memutuskan untuk membatalkan.",
  "Your order gets filled right away, partially or fully, and whatever is left gets canceled instantly.":
    "Pesanan Anda langsung terisi, sebagian atau seluruhnya, dan apa pun yang tersisa langsung dibatalkan.",
  "Conditional limit order that serves to add liquidity to the order book as a Maker order.":
    "Pesanan batas bersyarat yang berfungsi untuk menambah likuiditas ke buku pesanan sebagai pesanan Maker.",
  "Currently the fees are fixed and uniform across the platform. Maker {{makerFee}} / Taker {{takerFee}}":
    "Saat ini biaya tetap dan seragam di seluruh platform. Pembuat {{makerFee}} / Pengambil {{takerFee}}",
  "Value": "Nilai",
  "Buy": "Beli",
  "BUY": "BELI",
  "LONG": "PANJANG",
  "Sell": "Jual",
  "SELL": "JUAL",
  "SHORT": "PENDEK",
  "Order Value": "Nilai pesanan",
  "Current Position": "Posisi saat ini",
  "Account Value": "Nilai Akun",
  "The total value of your account at current mark prices":
    "Total nilai akun Anda pada harga mark saat ini",
  "Unrealized P&L": "P&L yang Belum Terealisasi",
  "Free Collateral": "Kolateral Bebas",
  "Account Leverage": "Leverage Akun",
  "Margin": "Margin",
  "Margin Ratio": "Rasio margin",
  "Initial Margin": "Margin Awal",
  "Maintenance Margin": "Margin Pemeliharaan",
  "Order Book": "Buku Pesanan",
  "Trades": "Perdagangan",
  "Price": "Harga",
  "Size": "Ukuran",
  "Total": "Total",
  "Time": "Waktu",
  "Spread": "Spread",
  "Positions": "Posisi",
  "Open Orders": "Pesanan Terbuka",
  "Trade History": "Riwayat Perdagangan",
  "Order History": "Riwayat Pesanan",
  "Funding Payments": "Pembayaran Pendanaan",
  "Side": "Sisi",
  "Entry Price": "Harga Masuk",
  "Liquidation Price": "Harga Likuidasi",
  "Unrealized Funding": "Pendanaan yang Belum Terealisasi",
  "Close Position": "Tutup Posisi",
  "Market Close": "Penutupan Pasar",
  "Close Long": "Tutup Long",
  "Close Short": "Tutup Short",
  "Type": "Tipe",
  "Filled": "Terisi",
  "Triggered": "Terpicu",
  "Instruction": "Instruksi",
  "Client Order Id": "Id Pesanan Klien",
  "Status": "Status",
  "NEW": "BARU",
  "OPEN": "BUKA",
  "UNTRIGGERED": "Belum dipicu",
  "CLOSED": "TUTUP",
  "Pending": "Tertunda",
  "Complete": "Lengkap",
  "Completed": "Selesai",
  "Failed": "Gagal",
  "Bust": "Bangkrut",
  "Value / Fee": "Nilai / Biaya",
  "Liquidity": "Likuiditas",
  "Tx Hash": "Hash Transaksi",
  "Trade Id": "ID Transaksi",
  "Remaining": "Sisa",
  "Cancel Reason": "Alasan Pembatalan",
  "User Canceled": "Pengguna Membatalkan",
  "Not Enough Margin": "Margin Tidak Cukup",
  "Empty Market": "Pasar Kosong",
  "Post Only": "Hanya Posting",
  "Post Only Would Cross": "Hanya Posting akan melintasi",
  "Remaining IOC Cancel": "Sisa IOC Dibatalkan",
  "Unexpected Failure": "Kegagalan Tak Terduga",
  "Deleverage": "Pengurangan Leverage",
  "In Liquidation": "Dalam Likuidasi",
  "Self Trade": "Perdagangan Sendiri",
  "Asset Unavailable": "Aset Tidak Tersedia",
  "Asset Expired": "Aset Kadaluarsa",
  "Order Type Invalid": "Tipe Pesanan Tidak Valid",
  "Price Not Available": "Harga Tidak Tersedia",
  "Expired": "Kadaluarsa",
  "Exceeds Max Slippage": "Melebihi Maksimal Slippage",
  "Timeout": "Waktu Habis",
  "Order Exceeds Position Limit": "Pesanan Melebihi Batas Posisi",
  "Order Id": "Id Pesanan",
  "Payment": "Pembayaran",
  "Cancel All": "Batalkan Semua",
  "{{orderSize}} will be closed at Market price":
    "{{orderSize}} akan ditutup pada Harga Pasar",
  "By connecting a wallet, you agree to <1>Paradex Terms of Service</1> and represent and warrant to Paradex that you are not a <2>Restricted Person</2>.":
    "Dengan menghubungkan dompet, Anda menyetujui <1>Syarat Layanan Paradex</1> dan menjamin kepada Paradex bahwa Anda bukan <2>Orang yang Dibatasi</2>.",
  "Wallet": "Dompet",
  "Deposit_verb": "Menyetor",
  "Deposit_noun": "Setoran",
  "Withdraw": "Tarik",
  "Withdrawal": "Penarikan",
  "Transfers": "Transfer",
  "Transfer": "Transfer",
  "Collateral": "Jaminan",
  "Asset": "Aset",
  "Wallet Balance": "Saldo Dompet",
  "Actions": "Tindakan",
  "Action": "Tindakan",
  "External Tx Hash": "Hash Tx Eksternal",
  "Withdraw to Wallet": "Tarik ke Dompet",
  "Deposit to Paradex": "Setor ke Paradex",
  "You have no balance in your Ethereum wallet. Deposit some {{asset}} and try again.":
    "Anda tidak memiliki saldo di dompet Ethereum Anda. Setor beberapa {{asset}} dan coba lagi.",
  "It is not possible to deposit more than your current balance.":
    "Tidak mungkin menyetor lebih dari saldo Anda saat ini.",
  "Available": "Tersedia",
  "Initiate a deposit from your Ethereum wallet to the Paradex bridge. These transactions":
    "Mulai setoran dari dompet Ethereum Anda ke jembatan Paradex. Transaksi ini",
  "Initiate a deposit from your Ethereum wallet to the Paradex bridge. These transactions may take several minutes depending on network conditions. For more information check out our <1>docs</1>.":
    "Mulai setoran dari dompet Ethereum Anda ke jembatan Paradex. Transaksi ini mungkin memakan waktu beberapa menit tergantung pada kondisi jaringan. Untuk informasi lebih lanjut, periksa <1>dokumen</1> kami.",
  "read more": "baca lebih lanjut",
  "Initiate Deposit to Bridge": "Inisiasi Setoran ke Jembatan",
  "Enable USDC on Paradex": "Aktifkan USDC di Paradex",
  "In order to deposit you must allow Paradex access to at least {{amount}}.":
    "Untuk menyetor, Anda harus mengizinkan Paradex mengakses setidaknya {{amount}}.",
  "Enable USDC": "Aktifkan USDC",
  "Withdraw initiated": "Penarikan dimulai",
  "Withdrawals are a two-step process. First initiate a withdrawal from Paradex":
    "Penarikan adalah proses dua langkah. Pertama, inisiasi penarikan dari Paradex",
  "Withdrawals are a two-step process. First initiate a withdrawal from Paradex to the Paradex Bridge. This first step can take 5-16 hours (10 on average). Second, initiate your withdrawal from the Paradex Bridge to your Ethereum wallet via the Deposits / Withdrawals table. For more information check out our <1>docs</1>.":
    "Penarikan adalah proses dua langkah. Pertama, inisiasi penarikan dari Paradex ke jembatan Paradex. Langkah pertama ini bisa memakan waktu 5-16 jam (rata-rata 10). Kedua, inisiasi penarikan Anda dari jembatan Paradex ke dompet Ethereum Anda melalui tabel Setoran / Penarikan. Untuk informasi lebih lanjut, periksa <1>dokumen</1> kami.",
  "Initiate Withdrawal to Bridge": "Inisiasi Penarikan ke Jembatan",
  "You have no funds to withdraw. It is not possible to withdraw more than your free collateral.":
    "Anda tidak memiliki dana untuk ditarik. Tidak mungkin menarik lebih dari jaminan bebas Anda.",
  "It is not possible to withdraw more than your free collateral.":
    "Tidak mungkin menarik lebih dari jaminan bebas Anda.",
  "You have no collateral": "Anda tidak memiliki jaminan",
  "You have no open positions": "Anda tidak memiliki posisi terbuka",
  "You have no open orders": "Anda tidak memiliki pesanan terbuka",
  "You have no trades": "Anda tidak memiliki perdagangan",
  "You have no orders": "Anda tidak memiliki pesanan",
  "You have no funding payments": "Anda tidak memiliki pembayaran pendanaan",
  "You have no transfers": "Anda tidak memiliki transfer",
  "Account in liquidation": "Akun dalam likuidasi",
  "Account is being liquidated. Any open positions will be reduced. Trading now is disabled.":
    "Akun sedang dilikuidasi. Semua posisi terbuka akan dikurangi. Perdagangan sekarang dinonaktifkan.",
  "Account liquidated": "Akun telah dilikuidasi",
  "Your account was liquidated on {{createdAt}}. Any open positions were reduced.":
    "Akun Anda telah dilikuidasi pada {{createdAt}}. Semua posisi terbuka telah dikurangi.",
  "Deposit to Paradex successful": "Setoran ke Paradex berhasil",
  "Deposit to Paradex failed": "Setoran ke Paradex gagal",
  "Unexpected error. Try again.": "Kesalahan tak terduga. Coba lagi.",
  "Deposit initiated": "Setoran dimulai",
  "Deposit to bridge initiated": "Setoran ke jembatan dimulai",
  "Deposit to Paradex available": "Setoran ke Paradex tersedia",
  "Go to Deposits": "Pergi ke Setoran",
  "Deposit to Paradex in progress": "Setoran ke Paradex sedang berlangsung",
  "Transfer successful": "Transfer berhasil",
  "Limit {{side}} Order Fill": "Isi Pesanan Batas {{side}}",
  "{{type}} {{side}} Order Submitted": "Pesanan {{type}} {{side}} Dikirim",
  "{{type}} {{side}} Order Canceled": "Pesanan {{type}} {{side}} Dibatalkan",
  "Reason": "Alasan",
  "Market {{side}} Order Fill": "Isi Pesanan Pasar {{side}}",
  "Trade Busted On-Chain": "Perdagangan Batal di Rantai",
  "Fill id": "Isi id",
  "Withdraw to wallet failed": "Penarikan ke dompet gagal",
  "Withdrawal to bridge initiated": "Penarikan ke jembatan dimulai",
  "Withdrawal error. Try again.": "Kesalahan penarikan. Coba lagi.",
  "Withdrawal to bridge in progress":
    "Penarikan ke jembatan sedang berlangsung",
  "Withdrawal to wallet initiated": "Penarikan ke dompet dimulai",
  "Withdrawal to bridge failed": "Penarikan ke jembatan gagal",
  "Transaction error. Try again.": "Kesalahan transaksi. Coba lagi.",
  "Withdrawal to wallet available": "Penarikan ke dompet tersedia",
  "Go to Withdrawals": "Pergi ke Penarikan",
  "Withdrawal to wallet successful": "Penarikan ke dompet berhasil",
  "Session expired, please log in again.":
    "Sesi telah berakhir, silakan masuk lagi.",
  "Failed to submit order": "Gagal mengirim pesanan",
  "Your username preferences were updated!":
    "Preferensi nama pengguna Anda telah diperbarui!",
  "Unexpected error updating username preferences":
    "Kesalahan tak terduga saat memperbarui preferensi nama pengguna",
  "This username already exists. Please choose a different username and try again.":
    "Nama pengguna ini sudah ada. Silakan pilih nama pengguna yang berbeda dan coba lagi.",
  "Validation Error": "Kesalahan Validasi",
  "Binding Error": "Kesalahan Binding",
  "Internal Error": "Kesalahan Internal",
  "Not Found": "Tidak Ditemukan",
  "Service Unavailable": "Layanan Tidak Tersedia",
  "Invalid Request Parameter": "Parameter Permintaan Tidak Valid",
  "Order Id Not Found": "Id Pesanan Tidak Ditemukan",
  "Order Is Closed": "Pesanan Ditutup",
  "Order Is Not Open Yet": "Pesanan Belum Dibuka",
  "Client Order Id Not Found": "Id Pesanan Klien Tidak Ditemukan",
  "Duplicated Client Id": "Id Klien Duplikat",
  "Invalid Price Precision": "Presisi Harga Tidak Valid",
  "Invalid Token": "Token Tidak Valid",
  "Invalid Ethereum Address": "Alamat Ethereum Tidak Valid",
  "Blocked Ethereum Address": "Alamat Ethereum yang Diblokir",
  "Invalid Ethereum Signature": "Tanda Tangan Ethereum Tidak Valid",
  "Invalid Starknet Address": "Alamat Starknet Tidak Valid",
  "Invalid Starknet Signature": "Tanda Tangan Starknet Tidak Valid",
  "Starknet Signature Verification Failed":
    "Verifikasi Tanda Tangan Starknet Gagal",
  "Bad Starknet Request": "Permintaan Starknet Buruk",
  "Ethereum Signer Mismatch": "Penandatangan Ethereum Tidak Cocok",
  "Ethereum Hash Mismatch": "Ketidakcocokan Hash Ethereum",
  "Not Onboarded": "Belum Di onboard",
  "Invalid Timestamp": "Timestamp Tidak Valid",
  "Invalid Signature Expiration": "Kadaluarsa Tanda Tangan Tidak Valid",
  "Account Not Found": "Akun Tidak Ditemukan",
  "Invalid Order Signature": "Tanda Tangan Pesanan Tidak Valid",
  "Public Key Invalid": "Kunci Publik Tidak Valid",
  "Unauthorized Ethereum Address": "Alamat Ethereum Tidak Sah",
  "Ethereum Address Already Onboarded": "Alamat Ethereum Sudah Di onboard",
  "Market Not Found": "Pasar Tidak Ditemukan",
  "Allowlist Entry Not Found": "Entri Allowlist Tidak Ditemukan",
  "Username In Use": "Nama Pengguna Sudah Digunakan",
  "Service access restricted in your region":
    "Akses layanan dibatasi di wilayah Anda",
  "No Access Allowed": "Tidak Ada Akses Yang Diizinkan",
  "You do not have access to the platform. Please join our Discord to get access.":
    "Anda tidak memiliki akses ke platform. Silakan bergabung dengan Discord kami untuk mendapatkan akses.",
  "{{actualChainName}} Wallet Connected": "Dompet Ethereum Terhubung",
  'Incorrect network selected "{{actualChainName}}". Please change the network to "{{requiredChainName}}" (id={{requiredChainId}}) in your wallet and retry.':
    'Jaringan yang dipilih salah "{{actualChainName}}". Silakan ubah jaringan menjadi "{{requiredChainName}}" (id={{requiredChainId}}) di dompet Anda dan coba lagi.',
  "Choose Wallet": "Pilih Dompet",
  "Starknet Wallet": "Dompet Starknet",
  "Switch to {{env}}": "Beralih ke {{env}}",
  "Search": "Cari",
  "League": "Liga",
  "Switch Account": "Beralih Akun",
  "Rank": "Peringkat",
  "24h XP": "XP 24h",
  "XP Per Day": "XP Per Hari",
  "Season Volume": "Volume Musim",
  "Lifetime Volume": "Volume Seumur Hidup",
  "Pre-Season": "Pra-Musim",
  "Earn More XP": "Dapatkan lebih banyak XP",
  "XP Boost": "Peningkatan XP",
  "XP Drop": "XP Drop",
  "Trader": "Pedagang",
  "Total Share": "Total Bagian",
  "Maker Share": "Bagian Pembuat",
  "Maker Volume Share": "Bagian Volume Pembuat",
  "Fee Share": "Bagian Biaya",
  "Fee Volume Share": "Bagian Volume Biaya",
  "No data available": "Tidak ada data yang tersedia",
  "Refer & Earn": "Rujuk & Dapatkan",
  "Hours": "Jam",
  "Minutes": "Menit",
  "Time/Duration": "Waktu/Durasi",
  "Runtime": "Waktu berjalan",
  "Frequency": "Frekuensi",
  "Number of Orders": "Jumlah Pesanan",
  "Runtime should not be empty": "Waktu berjalan tidak boleh kosong",
  "Runtime should be less than or equal to 24 hours":
    "Waktu berjalan harus kurang dari atau sama dengan 24 jam",
  "Inactive": "Tidak aktif",
  "Avg. Price": "Harga rata-rata",
  "The minimum required amount for {{numOrders}} orders is {{totalSize}} {{currency}}.":
    "Jumlah minimum yang diperlukan untuk {{numOrders}} pesanan adalah {{totalSize}} {{currency}}.",
  "Take Profit / Stop Loss: Set take profit and stop loss price levels to automate trade actions. When the Mark Price will reach the price you set, it will send your order to the matching engine.":
    "Take Profit / Stop Loss: Atur tingkat harga take profit dan stop loss untuk mengotomatisasi tindakan perdagangan. Ketika Harga Mark mencapai harga yang Anda tetapkan, pesanan Anda akan dikirim ke mesin pencocokan.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount with an estimated profit of <3 />.":
    "Ketika Harga Mark mencapai <1 />, itu akan memicu pesanan <2 /> untuk menutup jumlah yang dipilih dengan keuntungan estimasi <3 />.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount with an estimated loss of <3 />.":
    "Ketika Harga Pasar mencapai <1 />, itu akan memicu pesanan <2 /> untuk menutup jumlah yang dipilih dengan kerugian estimasi <3 />.",
  "Edit TP/SL For This Position": "Edit TP/SL Untuk Posisi Ini",
  "Take Profit": "Take Profit",
  "Profit": "Keuntungan",
  "Stop Loss": "Stop Loss",
  "Loss": "Kerugian",
  "Confirm": "Konfirmasi",
  "Take Profit Limit": "Batas Take Profit",
  "Take Profit Market": "Take Profit Pasar",
  "Stop Loss Limit": "Batas Stop Loss",
  "Stop Loss Market": "Stop Loss Pasar",
  "Funding": "Pendanaan",
  "Unwinding": "Pembubaran",
  "Cancel TP/SL orders": "Batalkan pesanan TP/SL",
  "TP/SL orders submitted successfully.": "Pesanan TP/SL berhasil dikirim.",
  "Pre-Season + All Seasons": "Pra-Musim + Semua Musim",
  "Season {{season}}": "Musim {{season}}",
  "XP Season {{season}}": "XP Musim {{season}}",
  "Slippage": "Selisih harga",
  "Max Slippage": "Selisih harga maksimal",
  "Max slippage defines the largest acceptable price deviation from the mark price.":
    "Selisih harga maksimum menentukan deviasi harga terbesar yang dapat diterima dari harga penanda.",
  "During execution, the order can be canceled if the price exceeds the slippage limit or falls outside the Market <1>Price Band</1>.":
    "Selama eksekusi, pesanan dapat dibatalkan jika harga melebihi batas slippage atau berada di luar <1>Batas Harga Pasar</1>.",
  "Update Max Slippage": "Perbarui selisih harga maksimum",
  "The Maximum Slippage allowed for {{symbol}} is {{price_band_width}}.":
    "Selisih harga maksimum yang diizinkan untuk {{symbol}} adalah {{price_band_width}}.",
  "Mark IV": "Mark IV",
  "Delta": "Delta",
  "Failed to load positions": "Gagal memuat posisi",
  "No open positions": "Tidak ada posisi terbuka",
  "learn more": "pelajari lebih lanjut",
  "Learn More": "Pelajari Lebih Lanjut",
  "Search Markets": "Cari Pasar",
  "Futures": "Futures",
  "Options": "Opsi",
  "OI": "Bunga Terbuka",
  "Addresses": "Alamat",
  "My Profile": "Profil Saya",
  "Chart": "Grafik",
  "ROI": "ROI",
  "Sharpe": "Sharpe",
  "Max Loss": "Maksimal Kerugian",
  "Total Volume": "Jumlah Volume",
  "My NFTs": "NFT Saya",
  "My XP": "XP Saya",
  "Balances": "Saldo",
  "Other": "Lainnya",
  "Paradex XP: Pre-Season": "Paradex XP: Pra-Musim",
  "Paradex XP: Season {{season}}": "Paradex XP: Musim {{season}}",
  "Season {{season}} XP": "Musim {{season}} XP",
  "Refer your friends to earn 10%": "Rujuk teman Anda untuk mendapatkan 10%",
  "Refer Friends and Earn 10%": "Rujuk teman Anda dan dapatkan 10%",
  "Invest in Paradex Vault & Earn 3X Boost":
    "Investasikan di Paradex Vault & Dapatkan 3X Boost",
  "Avg. Daily XP": "XP rata-rata per hari",
  "Lifetime XP": "XP seumur hidup",
  "My Rank": "Peringkat Saya",
  "What is XP?": "Apa itu XP?",
  "XP History": "Riwayat XP",
  "Last Week XP": "XP Seminggu Lalu",
  "TVL XP": "XP TVL",
  "OI XP": "XP OI",
  "Liquidation XP": "XP Liquidasi",
  "Vaults XP": "XP Gudang",
  "Week": "Minggu",
  "Season": "Musim",
  "Last updated: {{timestamp}}": "Terakhir diperbarui: {{timestamp}}",
  "Xperience Points (XP)": "Poin Pengalaman (XP)",
  "Log in to see your XP balance": "Masuk untuk melihat saldo XP Anda",
  "Log in to see where you stand": "Masuk untuk melihat di mana Anda berdiri",
  "Connect your X account to join Pro League":
    "Hubungkan akun X Anda untuk bergabung dengan Liga Pro",
  "Avg. Fill Price": "Harga Eksekusi Rata-rata",
  "Spot": "Spot",
  "Derivatives": "Derivatif",
  "P&L Chart": "Grafik P&L",
  "Account Value Chart": "Grafik Nilai Akun",
  "Funding History": "Riwayat Pendanaan",
  "Manage Funds": "Kelola Dana",
  "Profile": "Profil",
  "Community XP": "XP Komunitas",
  "XP Share": "Bagian XP",
  "Next XP Drop in": "XP Drop berikutnya dalam",
  "In Progress": "Sedang Berlangsung",
  "{{field}} must be greater than {{value}}":
    "{{field}} harus lebih besar dari {{value}}",
  "{{field}} must be less than {{value}}":
    "{{field}} harus lebih kecil dari {{value}}",
  "Enter a valid Take Profit or Stop Loss price":
    "Masukkan harga Take Profit atau Stop Loss yang valid",
  "Take Profit and Stop Loss prices cannot be the same":
    "Harga Take Profit dan Stop Loss tidak boleh sama",
  "Breakeven Price": "Harga Titik Impas",
  "Automatic Withdrawal": "Penarikan Otomatis",
  "Estimated Gas Fee": "Biaya Gas Diestimasi",
  "Auto Withdrawal": "Penarikan Otomatis",
  "Withdrawing...": "Penarikan sedang berlangsung...",
  "Paradex Bridge withdrawals can take between 5-16 hours to complete":
    "Penarikan Paradex Bridge dapat memakan waktu antara 5-16 jam untuk selesai",
  "Socialized Loss": "Kerugian Sosialisasi",
  "Receivable Amount": "Jumlah Dapat Diterima",
  "Auto withdrawing...": "Penarikan otomatis sedang berlangsung...",
  "Manage Twitter Connection": "Kelola Koneksi Twitter",
  "Unlock additional functionality.": "Buka fungsionalitas tambahan.",
  "Please note this connection can't be used as a login method to Paradex.":
    "Harap dicatat bahwa koneksi ini tidak dapat digunakan sebagai metode login ke Paradex.",
  "Toast Notifications": "Notifikasi Toast",
  "Display toast notifications": "Tampilkan notifikasi Toast",
  "Average Entry Price": "Harga Rata-rata Masuk",
  "Display average entry price line on chart":
    "Tampilkan garis harga rata-rata masuk pada grafik",
  "Display liquidation price line on chart":
    "Tampilkan garis harga liquidasi pada grafik",
  "Display open order lines on chart":
    "Tampilkan garis pesanan terbuka pada grafik",
  "Failed to disconnect Twitter": "Gagal memutuskan koneksi Twitter",
  "Failed to authorize Twitter": "Gagal mengautorisasi Twitter",
  "Twitter account linked successfully!":
    "Akun Twitter terhubung dengan sukses!",
  "Failed to link twitter account": "Gagal menghubungkan akun Twitter",
  "Fees": "Biaya",
  "Taker pays {{takerFee}} in fees. Maker receives {{makerRebate}} in rebates.":
    "Taker membayar {{takerFee}} dalam biaya. Maker menerima {{makerRebate}} dalam rabat.",
  "Manage Discord Connection": "Kelola koneksi Discord",
  "Failed to authorize Discord": "Gagal mengautorisasi Discord",
  "Discord account linked successfully!":
    "Akun Discord terhubung dengan sukses!",
  "Failed to link Discord account": "Gagal menghubungkan akun Discord",
  "Failed to disconnect Discord": "Gagal memutuskan koneksi Discord",
  "Connect": "Sambungkan",
  "Unrealized P&L from current vault token balance. This doesn't account for owner profit share or slippage from positions unwind.":
    "P&L tidak realisasi dari saldo token vault saat ini. Ini tidak memperhitungkan keuntungan pemilik atau slippage dari unwind posisi.",
  "Show P&L Amount": "Tampilkan P&L",
  "Receivable amount must be positive":
    "Jumlah yang dapat diterima harus positif",
  "Wallet Address Change Detected": "Perubahan Alamat Dompet Terdeteksi",
  "You can proceed with connecting your Paradex account to this wallet address or continue using your current connection.":
    "Anda dapat melanjutkan untuk menghubungkan akun Paradex Anda ke alamat dompet ini atau tetap menggunakan koneksi Anda saat ini.",
  "Connect To New Wallet": "Hubungkan ke Dompet Baru",
  "Keep Using Current Wallet": "Tetap Menggunakan Dompet Saat Ini",
  "Connect Current Wallet": "Hubungkan Dompet Saat Ini",
  "The account is not connected. Please make sure that <1>{{walletAddress}}</1> is connected and is the active account in your <2>{{walletName}}</2> wallet.":
    "Akun tidak terhubung. Pastikan bahwa <1>{{walletAddress}}</1> terhubung dan merupakan akun aktif di dompet <2>{{walletName}}</2> Anda.",
  "Reconnecting": "Menyambung kembali",
  "There is a request in progress. Please proceed in your wallet.":
    "Ada permintaan yang sedang diproses. Silakan lanjutkan di dompet Anda.",
  "Modify Order": "Ubah Pesanan",
  "Realized P&L": "P&L Terealisasi",
  "Close All": "Tutup Semua",
  "Close All Positions": "Tutup Semua Posisi",
  "Market Close All Positions": "Pasar Tutup Semua Posisi",
  "Close {{percent}}% of All Positions": "Tutup {{percent}}% dari semua posisi",
  "Failed to close all positions": "Gagal menutup semua posisi",
  "Number of Positions": "Jumlah Posisi",
  "Markets": "Pasar",
  "Position Value (Closing)": "Nilai Posisi (Penutupan)",
  "{{count}} positions will be rounded to the nearest order increment":
    "{{count}} posisi akan dibulatkan ke increment pesanan terdekat",
  "You will earn Bonus XP on your referral activity":
    "Anda akan mendapatkan XP Bonus dari aktivitas referensi Anda",
  "Token Price": "Harga Token",
  "Vault Token Price Chart": "Grafik Harga Token Gudang",
  "Market Close Orders Submitted": "Pasar Tutup Semua Pesanan",
  "This profile is already linked to a different Paradex account. Disconnect socials from existing account and try again.":
    "Profil ini sudah terhubung dengan akun Paradex yang berbeda. Lepaskan koneksi sosial dari akun Anda saat ini dan coba lagi.",
  "XP Drops every Friday": "XP turun setiap hari Jumat",
  "No XP Data Available": "Tidak ada data XP yang tersedia",
  "XP data is available only for the Main Account.":
    "Data XP hanya tersedia untuk akun utama.",
  "Please switch to your Main Account to view your XP.":
    "Silakan beralih ke akun utama Anda untuk melihat XP Anda.",
  "Referrals Not Available": "Referrals tidak tersedia",
  "Referrals are available only for the Main Account.":
    "Referrals hanya tersedia untuk akun utama.",
  "Please switch to your Main Account to view your Referrals.":
    "Silakan beralih ke akun utama Anda untuk melihat Referrals Anda.",
  "Deposit initiated via Layerswap": "Setoran dimulai melalui Layerswap",
  "Position History": "Riwayat Posisi",
  "Avg. Close Price": "Harga Penutupan Rata-Rata",
  "Max Size": "Ukuran Maksimum",
  "Closed Size": "Ukuran Tertutup",
  "Opened": "Dibuka",
  "You have no positions": "Anda tidak memiliki posisi",
  "Destination Address": "Alamat Tujuan",
  "Withdrawal via Layerswap initiated": "Penarikan via Layerswap dimulai",
  "Withdrawal via Layerswap failed": "Penarikan via Layerswap gagal",
  "Market Share": "Bagian Pasar",
  "Tier": "Tingkat",
  "Avg Maker Volume": "Volume Maker Rata-rata",
  "Liquidity Score": "Skor Liquiditas",
  "Reward Share": "Bagian Reward",
  "Enter amount": "Masukkan jumlah",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount at <3 /> with an estimated profit of <4 />.":
    "Ketika Harga Pasar mencapai <1 />, akan memicu pesanan <2 /> untuk menutup jumlah yang dipilih di <3 /> dengan perkiraan keuntungan sebesar <4 />.",
  "When Mark Price reaches <1 />, it will trigger <2 /> order to close the selected amount at <3 /> with an estimated loss of <4 />.":
    "Ketika Harga Pasar mencapai <1 />, akan memicu pesanan <2 /> untuk menutup jumlah yang dipilih di <3 /> dengan perkiraan kerugian sebesar <4 />.",
  "Limit Price": "Harga Batas",
  "Delete": "Hapus",
  "Use other bridges from the list above to withdraw funds much faster.":
    "Gunakan jembatan lain dari daftar di atas untuk menarik dana lebih cepat.",
  "Paradex Chain Monitor": "Monitor Rantai Paradex",
  "OTM Amount": "Jumlah OTM",
  "Leverage": "Leverage",
  "Strikes": "Strikes",
  "Strike": "Strike",
  "Change": "Perubahan",
  "Dist. to Spot": "Jarak ke Spot",
  "Bid": "Bid",
  "Mark": "Mark",
  "Ask": "Ask",
  "IV": "IV",
  "Dist %": "Jarak %",
  "Call": "Call",
  "Put": "Put",
  "Mark Price / IV": "Harga Mark / IV",
  "8h F-Funding": "Pendanaan 8j F",
  "No Strikes Available": "Tidak Ada Strikes Tersedia",
  "Attributes": "Atribut",
  "Why no Expiries?": "Mengapa Tidak Ada Kadaluarsa?",
  "{{multiplier}}x Boost": "Boost {{multiplier}}x",
  "Like perpetual futures, perpetual options have no expiration, offering continuous exposure to the underlying asset with built-in downside protection. This gives users the flexibility to hold their position indefinitely and decide the optimal time to close it based on their strategy":
    "Seperti futures perpetual, opsi perpetual tidak memiliki kadaluarsa, menawarkan eksposur berkelanjutan terhadap aset dasar dengan perlindungan penurunan bawaan. Ini memberikan pengguna fleksibilitas untuk memegang posisi mereka tanpa batas waktu dan memutuskan waktu optimal untuk menutupnya berdasarkan strategi mereka",
  "Implied Volatility used to calculate the option's Mark Price":
    "Volatilitas Implisit digunakan untuk menghitung Harga Mark opsi",
  "Distance between the Strike Price and the underlying Spot Price":
    "Jarak antara Harga Strike dan Harga Spot dasar",
  "Eight hour funding that is continuously accrued on positions and settled upon any trade. If the funding rate is positive, longs pay shorts. If negative, shorts pay longs":
    "Pendanaan delapan jam yang terus terakumulasi pada posisi dan diselesaikan pada setiap perdagangan. Jika tingkat pendanaan positif, long membayar short. Jika negatif, short membayar long",
  "The Leverage Ratio (Lambda) measures an option's effective leverage by indicating how much the option's value changes, in percentage terms, for a 1% change in the spot price of the underlying asset. For example, if an option has a Lambda of 50 and the spot price increases by 1%, the option's value would increase by 50%":
    "Rasio Leverage (Lambda) mengukur leverage efektif opsi dengan menunjukkan seberapa besar perubahan nilai opsi, dalam persentase, untuk perubahan 1% dalam harga spot aset dasar. Misalnya, jika opsi memiliki Lambda 50 dan harga spot naik 1%, nilai opsi akan naik 50%",
  "Option delta measures how much the price of an option is expected to change for a $1 change in the price of the underlying asset. For example, a delta of 0.5 means the option price will move $0.50 for every $1 move in the underlying":
    "Delta opsi mengukur seberapa besar perubahan harga opsi yang diharapkan untuk perubahan $1 dalam harga aset dasar. Misalnya, delta 0,5 berarti harga opsi akan bergerak $0,50 untuk setiap pergerakan $1 dalam aset dasar",
  "Eight hour funding rate of the perpetual future on the same underlying asset":
    "Tingkat pendanaan delapan jam dari future perpetual pada aset dasar yang sama",
  "Having trouble? Try signing out and connecting again.":
    "Mengalami masalah? Coba keluar dan masuk kembali.",
  "API Reference": "Referensi API",
  "XP Distribution occurs once per week on Friday at ~12 PM UTC":
    "Distribusi XP terjadi sekali seminggu pada hari Jumat pukul ~12 PM UTC",
  "What are Perpetual Options?": "Apa itu Opsi Perpetual?",
  "Delta represents the increase in option price based on a $1 increase in the underlying spot price.\n\nFor example, a delta of 0.50 means the price of the option is expected to increase by $0.50 for a $1 increase in the underlying spot price.":
    "Delta mewakili kenaikan harga opsi berdasarkan kenaikan $1 dalam harga spot dasar.\n\nMisalnya, delta 0,50 berarti harga opsi diharapkan naik $0,50 untuk kenaikan $1 dalam harga spot dasar.",
  "Leverage(Lambda) represents the percentage change in option price for a 1% change in the underlying spot price.\n\nFor example, a lambda of 10 means the option price is expected to increase by 10% if the underlying spot price increases by 1%.":
    "Leverage(Lambda) mewakili perubahan persentase harga opsi untuk perubahan 1% dalam harga spot dasar.\n\nMisalnya, lambda 10 berarti harga opsi diharapkan naik 10% jika harga spot dasar naik 1%.",
  "Eight hour options funding rate that is continuously accrued on positions and settled upon any trade. The funding rate for perpetual options reflects both the cost of leverage and the cost of insurance against adverse price moves. \n\nThe funding period of perpetual options is 24 hours. This means that the option premium will be continuously paid out over a 24h period. 24h funding rate = 3 x 8h funding rate":
    "Tingkat pendanaan opsi delapan jam yang terus terakumulasi pada posisi dan diselesaikan pada setiap perdagangan. Tingkat pendanaan untuk opsi perpetual mencerminkan baik biaya leverage maupun biaya asuransi terhadap pergerakan harga yang merugikan. \n\nPeriode pendanaan opsi perpetual adalah 24 jam. Ini berarti premi opsi akan terus dibayarkan selama periode 24 jam. Tingkat pendanaan 24j = 3 x tingkat pendanaan 8j",
  "Risk": "Risiko",
  "Vega": "Vega",
  "Gamma": "Gamma",
  "Estimated Funding P&L for the next 8 hours based on current funding rates":
    "Perkiraan P&L Pendanaan untuk 8 jam ke depan berdasarkan tingkat pendanaan saat ini",
  "Vega represents the increase in option price based on a 1% increase in the implied volatility.\n\nFor example, a Vega of 5 means the price of the option is expected to increase by $5 for a 1% increase in the implied volatility.":
    "Vega mewakili kenaikan harga opsi berdasarkan kenaikan 1% dalam volatilitas implisit.\n\nMisalnya, Vega 5 berarti harga opsi diharapkan naik $5 untuk kenaikan 1% dalam volatilitas implisit.",
  "Adjust Leverage": "Sesuaikan Leverage",
  "Leverage updated successfully": "Leverage berhasil diperbarui",
  "Failed to update leverage": "Gagal memperbarui leverage",
  "Adjust leverage parameters for selected market. Leverage will also increase on your open positions.":
    "Sesuaikan parameter leverage untuk pasar yang dipilih. Leverage juga akan meningkat pada posisi terbuka Anda.",
  "Please note that changing leverage will also apply for open positions and open orders on this market.":
    "Harap perhatikan bahwa perubahan leverage juga akan berlaku untuk posisi terbuka dan pesanan terbuka di pasar ini.",
  "Selecting higher leverage could result in Deleveraging where some of your open orders would be cancelled.":
    "Memilih leverage yang lebih tinggi dapat mengakibatkan Deleveraging di mana beberapa pesanan terbuka Anda akan dibatalkan.",
  "disabled": "dinonaktifkan",
  "Margin Mode": "Mode Margin",
  "Manage your risk on individual positions by restricting the amount of margin allocated to each. If the margin ratio of an isolated position reaches 100%, the position will be liquidated. Margin can be added or removed to individual positions in this mode.":
    "Kelola risiko Anda pada posisi individual dengan membatasi jumlah margin yang dialokasikan untuk masing-masing. Jika rasio margin posisi terisolasi mencapai 100%, posisi akan dilikuidasi. Margin dapat ditambahkan atau dihapus dari posisi individual dalam mode ini.",
  "Margin mode updated successfully": "Mode margin berhasil diperbarui",
  "Failed to update margin mode": "Gagal memperbarui mode margin",
  "Favorites": "Favorit",
  "Trending": "Sedang Tren",
  "Losers": "Pecundang",
  "Gainers": "Pemenang",
  "New Listing": "Daftar Baru",
  "Developers": "Pengembang",
  "Developer Mode": "Mode Pengembang",
  "Enable developer mode": "Aktifkan mode pengembang",
  "For external use": "Untuk penggunaan eksternal",
  "Warning: Changing transfer behavior":
    "Peringatan: Mengubah perilaku transfer",
  "USDC will be transferred to account contract instead of Paradex. Amount won't be visible or available for use within Paradex, but can be used with external applications.":
    "USDC akan dikirim ke kontrak akun daripada Paradex. Jumlah tidak akan terlihat atau tersedia untuk digunakan dalam Paradex, tetapi dapat digunakan dengan aplikasi eksternal.",
  "Affiliate Dashboard": "Dasbor Afiliasi",
};

export default translations;
