import { UnixTime } from '#/api/types';

const DOMAIN_TYPES = {
  StarkNetDomain: [
    { name: 'name', type: 'felt' },
    { name: 'chainId', type: 'felt' },
    { name: 'version', type: 'felt' },
  ],
};

function buildParadexDomain(paradexChainId: string) {
  return {
    name: 'Paradex',
    chainId: paradexChainId,
    version: '1',
  };
}

export function buildOnboardingTypedData(paradexChainId: string) {
  const paradexDomain = buildParadexDomain(paradexChainId);
  return {
    domain: paradexDomain,
    primaryType: 'Constant',
    types: {
      ...DOMAIN_TYPES,
      Constant: [{ name: 'action', type: 'felt' }],
    },
    message: {
      action: 'Onboarding',
    },
  };
}

export interface AuthRequest extends Record<string, unknown> {
  method: string;
  path: string;
  body: string;
  timestamp: UnixTime;
  expiration: UnixTime;
}

export function buildAuthTypedData(
  message: Record<string, unknown>,
  paradexChainId: string,
) {
  const paradexDomain = buildParadexDomain(paradexChainId);
  return {
    domain: paradexDomain,
    primaryType: 'Request',
    types: {
      ...DOMAIN_TYPES,
      Request: [
        { name: 'method', type: 'felt' }, // string
        { name: 'path', type: 'felt' }, // string
        { name: 'body', type: 'felt' }, // string
        { name: 'timestamp', type: 'felt' }, // number
        { name: 'expiration', type: 'felt' }, // number
      ],
    },
    message,
  };
}

export function buildOrderTypeData(
  message: Record<string, unknown>,
  paradexChainId: string,
) {
  const paradexDomain = buildParadexDomain(paradexChainId);
  return {
    domain: paradexDomain,
    primaryType: 'Order',
    types: {
      ...DOMAIN_TYPES,
      Order: [
        { name: 'timestamp', type: 'felt' }, // UnixTimeMs; Acts as a nonce
        { name: 'market', type: 'felt' }, // 'BTC-USD-PERP'
        { name: 'side', type: 'felt' }, // '1': 'BUY'; '2': 'SELL'
        { name: 'orderType', type: 'felt' }, // 'LIMIT'; 'MARKET'; 'STOP_LIMIT'; 'STOP_MARKET'
        { name: 'size', type: 'felt' }, // Quantum value
        { name: 'price', type: 'felt' }, // Quantum value; '0' for Market order
      ],
    },
    message,
  };
}

export function buildModifyOrderTypeData(
  message: Record<string, unknown>,
  paradexChainId: string,
) {
  const paradexDomain = buildParadexDomain(paradexChainId);
  return {
    domain: paradexDomain,
    primaryType: 'ModifyOrder',
    types: {
      ...DOMAIN_TYPES,
      ModifyOrder: [
        { name: 'timestamp', type: 'felt' }, // UnixTimeMs; Acts as a nonce
        { name: 'market', type: 'felt' }, // 'BTC-USD-PERP'
        { name: 'side', type: 'felt' }, // '1': 'BUY'; '2': 'SELL'
        { name: 'orderType', type: 'felt' }, // 'LIMIT'; 'STOP_LIMIT'; 'STOP_LOSS_LIMIT', 'STOP_LOSS_LIMIT'
        { name: 'size', type: 'felt' }, // Quantum value
        { name: 'price', type: 'felt' }, // Quantum value
        { name: 'id', type: 'felt' }, // Order ID
      ],
    },
    message,
  };
}
